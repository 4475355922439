import queryString from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Loading from '^/common/loading';
import {
  DEVELOPMENT_LOGIN_PATH,
  getSSOAuthRedirectWithNextPath,
  USE_SSO_AUTH_SERVICE,
} from '^/login/single-sign-on';

export type LoginPageProps = RouteComponentProps;

class LoginPage extends React.PureComponent<LoginPageProps> {
  public componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    const redirect = queryParams.redirect ? String(queryParams.redirect) : '/';
    USE_SSO_AUTH_SERVICE
      ? window.location.replace(getSSOAuthRedirectWithNextPath(redirect))
      : window.location.replace(DEVELOPMENT_LOGIN_PATH);
  }

  public render() {
    return <Loading />;
  }
}

export { LoginPage as TestableLoginPage };
export default LoginPage;

import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import { FilterField, FilterList } from '^/filters/types';
import RenderInputField from '^/form-helpers/render-input-field';

// Props that come from the parent component.
interface OwnProps {
  searchFields?: ReadonlyArray<FilterField>;
}

export type ContactsPickerFormProps = OwnProps &
  InjectedFormProps<FilterList, OwnProps>;

class ContactsPickerForm extends React.PureComponent<ContactsPickerFormProps> {
  public render() {
    const { searchFields = [], handleSubmit, submitting } = this.props;

    if (searchFields.length < 1) {
      return null;
    }

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          {searchFields.map(filter => (
            <Field
              key={filter.name}
              name={filter.name}
              label={filter.label}
              component={RenderInputField}
              type="text"
            />
          ))}
          <AppButton type="submit" loading={submitting}>
            Search
          </AppButton>
        </FormGroup>
      </Form>
    );
  }
}

// Disconnected version used for testing
export { ContactsPickerForm as TestableContactsPickerForm };

export default reduxForm<FilterList, OwnProps>({
  form: 'contactsPickerForm',
})(ContactsPickerForm);

import { DropdownOptions } from '^/form-helpers/render-dropdown';

export interface FilterField {
  name: string;
  label?: string;
  type: FilterType;
  dropdownOptions?: DropdownOptions;
  disabled?: boolean;
}

export interface FilterList {
  [name: string]: string | undefined;
}

export enum FilterType {
  Text = 'text',
  Date = 'date',
  Dropdown = 'dropdown',
  Checkbox = 'checkbox',
}

export interface Postcode {
  postcode: string;
}

import { UserResponse } from '^/admin/users/types';
import { ContactResponse } from '^/contacts/types';

export enum ActivityListType {
  Upcoming = 'UPCOMING',
  Overdue = 'OVERDUE',
}

export interface Activity {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  due_date?: string | null;
  completed_next_action?: boolean;
  title: string;
  text?: string | null;
  readonly comments?: ReadonlyArray<Comment>;
  popup?: boolean;
  letter?: boolean;
  assigned_to?: string | null;
  associated_contact?: string | null;
  created_by?: string | null;
  readonly assigned_to_detail?: UserResponse | null;
  readonly associated_contact_name: string;
  readonly associated_contact_detail?: ContactResponse | null;
  readonly created_by_detail?: UserResponse | null;
  attachments?: string[];
}

export type ActivityResponse = Readonly<Required<Exclude<Activity, 'text'>>>;

export interface Comment {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  text: string;
  created_by: string;
  readonly created_by_detail?: UserResponse;
}

export type ActivityCommentResponse = Readonly<Required<Comment>>;

import { isPending } from '@dabapps/redux-requests';
import {
  Column,
  Container,
  Row,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import {
  formatDate,
  generateQueryString,
  getContactName,
} from '^/common/helper-functions';
import ItemButton from '^/common/item-button';
import PaginatedTable from '^/common/paginated-table';
import { getCodeContact } from '^/contacts/actions';
import { Ledger } from '^/contacts/ledger/types';
import { FilterList } from '^/filters/types';
import { GET_LEDGER_ENTRIES, getLedgerEntries } from '^/ledger/actions';
import PageContent from '^/page-container/page-content';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import LedgerFilterForm from './filter-form';

export type LedgerListPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

class LedgerListPage extends React.PureComponent<LedgerListPageProps> {
  public componentDidMount() {
    if (this.props.location.search) {
      this.changePage(1);
    }
  }

  public componentDidUpdate(prevProps: LedgerListPageProps) {
    const { codeContact, location } = this.props;

    if (codeContact !== prevProps.codeContact) {
      this.changePage(1);
    }

    if (location.search !== prevProps.location.search) {
      this.changePage(1);
    }
  }

  public render() {
    const { loading, location, ledgerEntries } = this.props;

    const filters = queryString.parse(location.search);

    return (
      <PageContent>
        <Container>
          <Row>
            <Column xs={12} lg={3}>
              <div className="filter-section">
                <LedgerFilterForm
                  loading={loading}
                  initialValues={filters}
                  enableReinitialize
                  onSubmit={this.handleFilterSubmit}
                  onClear={this.handleFilterClear}
                />
              </div>
            </Column>
            <Column xs={12} lg={9}>
              <PaginatedTable
                changePage={this.changePage}
                paginatedData={ledgerEntries}
                loading={loading}
                primary
                title="Search results"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Entry date</TableCell>
                    <TableCell>TX ID</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell className="align-right">Goods</TableCell>
                    <TableCell className="align-right">VAT</TableCell>
                    <TableCell className="align-right">Gross</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getCurrentPage(ledgerEntries).map(entry => (
                    <TableRow key={entry.id}>
                      <TableCell>{formatDate(entry.entry_date)}</TableCell>
                      <TableCell>{entry.tx_id}</TableCell>
                      <TableCell>{entry.type}</TableCell>
                      <TableCell>
                        <Link to={`/contacts/${entry.contact_2}`}>
                          {getContactName(entry.contact_2_detail)}
                        </Link>
                      </TableCell>
                      <TableCell className="align-right">
                        {formatFeeAmount(entry.amount)}
                      </TableCell>
                      <TableCell className="align-right">
                        {formatFeeAmount(entry.vat_amount)}
                      </TableCell>
                      <TableCell className="align-right">
                        {formatFeeAmount(entry.amount + entry.vat_amount)}
                      </TableCell>
                      <TableCell className="align-right">
                        <ItemButton
                          item={entry}
                          onClick={this.handleDetailButtonClicked}
                          small
                        >
                          Details
                        </ItemButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </PaginatedTable>
            </Column>
          </Row>
        </Container>
      </PageContent>
    );
  }

  public handleFilterSubmit = (filters: FilterList) => {
    const query = generateQueryString(filters);

    this.props.history.push(`${this.props.match.url}${query}`);
  };

  public handleFilterClear = () => {
    this.props.history.push(`${this.props.match.url}`);
  };

  public handleDetailButtonClicked = (entry: Ledger) => {
    this.props.history.push(
      `/contacts/${entry.contact_2}/ledger?tx_id=${entry.tx_id}`
    );
  };

  private changePage = async (page: number, pageSize?: number) => {
    const filters = queryString.parse(this.props.location.search);

    if (this.props.codeContact) {
      this.props.getLedgerEntries(
        { ...filters, contact_1: this.props.codeContact.id },
        page,
        pageSize
      );
    } else {
      this.props.getCodeContact();
    }
  };
}

// Disconnected version used for testing
export { LedgerListPage as TestableLedgerListPage };

export const mapState = (state: StoreState) => ({
  ledgerEntries: state.ledgerEntries,
  codeContact: state.codeContact,
  loading: isPending(state.responses, GET_LEDGER_ENTRIES),
});

const mapDispatch = {
  getLedgerEntries,
  getCodeContact,
};

const connector = connect(mapState, mapDispatch);

export default connector(LedgerListPage);

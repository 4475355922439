import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { AsyncActionSet, getErrorData } from '@dabapps/redux-requests';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { StoreState } from '^/types';

interface OwnProps {
  actions?: ReadonlyArray<AsyncActionSet>;
  fields?: ReadonlyArray<string>;
  error?: string | ReadonlyArray<string>;
  warning?: string | ReadonlyArray<string>;
  showStatusErrors?: boolean;
  centered?: boolean;
}

// State props that come from redux.
interface StateProps {
  newError: boolean;
}

export type ErrorRendererProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class ErrorRenderer extends React.PureComponent<ErrorRendererProps> {
  public componentDidUpdate(prevProps: ErrorRendererProps) {
    const { responses, actions = [], newError } = this.props;

    if (!newError && responses) {
      actions.forEach(action => {
        if (
          !prevProps.responses ||
          responses[action.REQUEST] !== prevProps.responses[action.REQUEST]
        ) {
          this.props.setProps({ newError: true });
        }
      });
    }
  }

  public render() {
    const {
      responses,
      actions = [],
      fields = [],
      error: formErrors = [],
      warning: formWarnings = [],
      showStatusErrors,
      newError,
    } = this.props;

    const flattenArray = (arr: string[]): string[] => {
      return arr.reduce<string[]>((acc, curr) => {
        return Array.isArray(curr)
          ? [...acc, ...flattenArray(curr)]
          : [...acc, curr];
      }, []);
    };

    const errors =
      typeof formErrors === 'string'
        ? [formErrors]
        : flattenArray(formErrors.filter(error => error && error.length >= 1));
    const warnings =
      typeof formWarnings === 'string'
        ? [formWarnings]
        : formWarnings.filter(warning => warning && warning.length >= 1);

    if (newError && responses) {
      const responseErrors = actions.reduce((actionMemo: string[], action) => {
        const error = getErrorData(responses, action);

        if (error && error.response && error.response.data) {
          const errorData = error.response.data;

          const actionErrors = actionMemo.concat(
            fields.reduce((fieldMemo, field) => {
              if (errorData && errorData[field]) {
                return fieldMemo.concat(errorData[field]);
              }

              return fieldMemo;
            }, [])
          );

          const statusError = this.getStatusError(
            error.response.status,
            errorData.detail
          );

          if (showStatusErrors && statusError) {
            return actionErrors.concat([statusError]);
          } else {
            return actionErrors;
          }
        }

        return actionMemo;
      }, []);

      responseErrors.forEach(error => {
        if (!errors.includes(error)) {
          errors.push(error);
        }
      });
    }

    if (errors.length <= 0 && warnings.length <= 0) {
      return null;
    }

    return (
      <div
        className={classNames('errors', {
          ['text-center']: this.props.centered,
        })}
      >
        {errors.map(error => (
          <p className="error" key={error}>
            {error}
          </p>
        ))}
        {warnings.map(error => (
          <p className="warning" key={error}>
            {warnings}
          </p>
        ))}
      </div>
    );
  }

  private getStatusError(statusCode: number, detail: string): string | null {
    if (detail) {
      return detail;
    }

    switch (statusCode) {
      case 400:
        return null;
      case 401:
        return 'You need to be logged in to see this data.';
      case 403:
        return "You aren't allowed to view this data.";
      case 404:
      case 405:
        return "Couldn't find the data you were looking for, please try again later.";
      case 500:
      case 502:
      case 503:
      case 504:
        return 'Sorry! The server is temporarily down, please try again later.';
      default:
        return 'Something went wrong! Please refresh and try again';
    }
  }
}

export const getInitialProps = () => ({ newError: false });

export { ErrorRenderer as TestableErrorRenderer };

export const mapState = (state: StoreState) => ({
  responses: state.responses,
});

const connector = connect(mapState, {});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(ErrorRenderer)
);

import classnames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';

interface OwnProps {
  disabled?: boolean;
  className?: string;
  label?: string;
  placeholder?: string;
  short?: boolean;
  shorter?: boolean;
  shortest?: boolean;
}

export type RenderFormFieldProps = OwnProps & WrappedFieldProps;

class RenderFormField extends React.PureComponent<RenderFormFieldProps> {
  public render() {
    const {
      className,
      label,
      meta: { touched, error, warning, dirty, pristine },
      short,
      shorter,
      shortest,
    } = this.props;

    return (
      <div
        className={classnames([
          'form-field',
          className,
          {
            short,
            shorter,
            shortest,
            dirty,
            pristine,
            error: Boolean(touched && error),
          },
        ])}
      >
        <div className="form-field-label">
          {label && <label>{label}</label>}
        </div>
        {this.props.children}
        <div className="form-field-errors">
          {touched && <ErrorRenderer error={[error, warning]} />}
        </div>
      </div>
    );
  }
}

export { RenderFormField as TestableRenderFormField };

export default RenderFormField;

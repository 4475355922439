import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import {
  PlanSubscription,
  SubscriptionContact,
} from '^/plans/subscriptions/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const PLAN_SUBSCRIPTIONS_ENDPOINT = '/api/subscriptions';
export const SUBSCRIPTION_CONTACT_ENDPOINT = '/api/subscription-contacts';
export const BILLING_CONTAINER_SUBSCRIPTION_CONTACT_ENDPOINT =
  '/api/memberships/billing-container-members';

export const GET_PLAN_SUBSCRIPTION = makeAsyncActionSet(
  'GET_PLAN_SUBSCRIPTION'
);
export function getPlanSubscription(subscriptionId: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_PLAN_SUBSCRIPTION,
      `${PLAN_SUBSCRIPTIONS_ENDPOINT}/${subscriptionId}/`,
      'GET'
    )(dispatch);
}

export const GET_PLAN_SUBSCRIPTIONS = makeAsyncActionSet(
  'GET_PLAN_SUBSCRIPTIONS'
);
export function getPlanSubscriptions(
  contact: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString = generateQueryString({
    contact,
    page,
    page_size: pageSize,
  });

  return (dispatch: Dispatch) => {
    request(
      GET_PLAN_SUBSCRIPTIONS,
      `${PLAN_SUBSCRIPTIONS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { contact, page, pageSize } }
    )(dispatch);
  };
}

export const GET_SUBSCRIPTION_CONTACTS = makeAsyncActionSet(
  'GET_SUBSCRIPTION_CONTACTS'
);
export function getSubscriptionContacts(
  subscription: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString = generateQueryString({
    subscription,
    page,
    page_size: pageSize,
  });

  return (dispatch: Dispatch) => {
    request(
      GET_SUBSCRIPTION_CONTACTS,
      `${SUBSCRIPTION_CONTACT_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { subscription, page, pageSize } }
    )(dispatch);
  };
}

export const GET_BILLING_CONTAINER_SUBSCRIPTION_CONTACTS = makeAsyncActionSet(
  'GET_BILLING_CONTAINER_SUBSCRIPTION_CONTACTS'
);
export function getBillingContainerSubscriptionContacts(
  billingContainerSub: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString = generateQueryString({
    billing_container_sub: billingContainerSub,
    page,
    page_size: pageSize,
  });

  return (dispatch: Dispatch) => {
    request(
      GET_BILLING_CONTAINER_SUBSCRIPTION_CONTACTS,
      `${BILLING_CONTAINER_SUBSCRIPTION_CONTACT_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { billingContainerSub, page, pageSize } }
    )(dispatch);
  };
}

export const CREATE_SUBSCRIPTION_CONTACT = makeAsyncActionSet(
  'CREATE_SUBSCRIPTION_CONTACT'
);
export function createSubscriptionContact(
  subscription: string,
  contact: string
) {
  return (dispatch: Dispatch) =>
    request(
      CREATE_SUBSCRIPTION_CONTACT,
      `${SUBSCRIPTION_CONTACT_ENDPOINT}/`,
      'POST',
      { subscription, contact },
      { shouldRethrow: rethrowOnAnyError, metaData: { subscription } }
    )(dispatch)
      .then(response => {
        getSubscriptionContacts(subscription)(dispatch);
        getPlanSubscription(contact)(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

export const PATCH_SUBSCRIPTION_CONTACT = makeAsyncActionSet(
  'PATCH_SUBSCRIPTION_CONTACT'
);
export function patchSubscriptionContact(
  subscriptionContact: Partial<SubscriptionContact>,
  subscriptionContactId: string,
  subscription: string,
  contact: string
) {
  return (dispatch: Dispatch) =>
    request(
      PATCH_SUBSCRIPTION_CONTACT,
      `${SUBSCRIPTION_CONTACT_ENDPOINT}/${subscriptionContactId}/`,
      'PATCH',
      subscriptionContact,
      {
        shouldRethrow: rethrowOnAnyError,
        metaData: { subscription: subscriptionContact.subscription },
      }
    )(dispatch)
      .then(response => {
        getSubscriptionContacts(subscription)(dispatch);
        getPlanSubscription(contact)(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

export const CREATE_PLAN_SUBSCRIPTION = makeAsyncActionSet(
  'CREATE_PLAN_SUBSCRIPTION'
);
export function createPlanSubscription(subscription: PlanSubscription) {
  return (dispatch: Dispatch) =>
    request(
      CREATE_PLAN_SUBSCRIPTION,
      `${PLAN_SUBSCRIPTIONS_ENDPOINT}/`,
      'POST',
      subscription,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch)
      .then(response => {
        subscription.contacts.forEach(contact =>
          getPlanSubscriptions(contact.contact)(dispatch)
        );

        return response;
      })
      .catch(throwSubmissionError);
}

export const SAVE_PLAN_SUBSCRIPTION = makeAsyncActionSet(
  'SAVE_PLAN_SUBSCRIPTION'
);
export function savePlanSubscription(
  id: string,
  subscription: PlanSubscription
) {
  return (dispatch: Dispatch) =>
    request(
      SAVE_PLAN_SUBSCRIPTION,
      `${PLAN_SUBSCRIPTIONS_ENDPOINT}/${id}/`,
      'PATCH',
      subscription,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch)
      .then(response => {
        subscription.contacts.forEach(contact =>
          getPlanSubscriptions(contact.contact)(dispatch)
        );

        return response;
      })
      .catch(throwSubmissionError);
}

export const DELETE_PLAN_SUBSCRIPTION = makeAsyncActionSet(
  'DELETE_PLAN_SUBSCRIPTION'
);
export function deletePlanSubscription(subId: string) {
  return (dispatch: Dispatch) => {
    return request(
      DELETE_PLAN_SUBSCRIPTION,
      `${PLAN_SUBSCRIPTIONS_ENDPOINT}/${subId}/`,
      'DELETE',
      undefined,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch);
  };
}

export const GET_SUBSCRIPTIONS_BY_PLAN = makeAsyncActionSet(
  'GET_SUBSCRIPTIONS_BY_PLAN'
);
export function getSubscriptionsByPlan(
  plan: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString = generateQueryString({
    plan,
    page,
    page_size: pageSize,
  });

  return (dispatch: Dispatch) =>
    request(
      GET_SUBSCRIPTIONS_BY_PLAN,
      `${PLAN_SUBSCRIPTIONS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { plan, page, pageSize } }
    )(dispatch);
}

export const subscriptionActions = [
  GET_PLAN_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_BY_PLAN,
];

import { AnyAction } from 'redux';

import { Uuid } from '^/common/types';
import { ContactResponse } from '^/contacts/types';
import { Dashboard } from '^/iplan/dashboard/types';
import { PlanResponse } from '^/plans/types';
import { GET_DASHBOARD, SELECT_ACTIVE_PRACTICE } from './actions';
import {
  GET_PRACTICE_CLINICIANS,
  GET_PRACTICE_PLANS,
} from './patients/new-patient/actions';

export interface IIPlanReducer {
  selectedPracticeId?: Uuid;
  newPatientForm?: {
    plans?: Array<Readonly<Required<PlanResponse>>>;
    clinicians?: Array<Readonly<Required<ContactResponse>>>;
  };
  dashboard?: Dashboard;
}

export function iplan(
  state: IIPlanReducer | null = null,
  action: AnyAction
): IIPlanReducer | null {
  switch (action.type) {
    case SELECT_ACTIVE_PRACTICE:
      return {
        ...state,
        selectedPracticeId: action.payload.practiceId,
      };
    case GET_PRACTICE_PLANS.SUCCESS:
      return {
        ...state,
        newPatientForm: {
          ...state?.newPatientForm,
          plans: action.payload.data.results,
        },
      };
    case GET_PRACTICE_CLINICIANS.SUCCESS:
      return {
        ...state,
        newPatientForm: {
          ...state?.newPatientForm,
          clinicians: action.payload.data,
        },
      };
    case GET_DASHBOARD.SUCCESS:
      return {
        ...state,
        dashboard: action.payload.data,
      };
    default:
      return state;
  }
}

import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps, Switch } from 'react-router';

import AllowRoute from '^/common/allow-route';
import PageSection from '^/common/page-section/page-section';
import PageContent from '^/page-container/page-content';
import newPatientPage from './new-patient/new-patient-page';
import practicePatientLanding from './practice-patient-landing';

class AddPatientsRoutesPage extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <PageContent>
        <Container>
          <PageSection>
            <Switch>
              <AllowRoute
                exact
                path={`${match.path}/`}
                component={practicePatientLanding}
              />
              <AllowRoute
                exact
                path="/new-patient"
                component={newPatientPage}
              />
              <AllowRoute path="/logout" />
            </Switch>
          </PageSection>
        </Container>
      </PageContent>
    );
  }
}

export { AddPatientsRoutesPage as TestablePatientsRoutesPage };

export default AddPatientsRoutesPage;

import { Collapse } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import BankInstruction from '^/iplan/patients/new-patient/form/bank-instruction';
import Confirmation from '^/iplan/patients/new-patient/form/confirmation';
import DentalPlanAgreement from '^/iplan/patients/new-patient/form/dental-plan-agreement';
import { Stepper } from '^/iplan/patients/new-patient/stepper';
import { StoreState } from '^/types';

export type PatientConfirmationSectionProps = ConnectedProps<typeof connector>;

const PatientConfirmationSection = ({
  isSectionOneValid,
  isStartDateEntered,
}: PatientConfirmationSectionProps) => {
  const [isContactDetailsPanelOpen, setIsContactDetailsPanelOpen] = useState(
    false
  );
  const onClickToggleCollapse = () =>
    setIsContactDetailsPanelOpen(!isContactDetailsPanelOpen);

  return (
    <PageSection className="new-patient-title">
      <div className="collapse-header" onClick={onClickToggleCollapse}>
        <h4>Confirmation</h4>
        <FontAwesomeIcon
          icon={isContactDetailsPanelOpen ? 'chevron-up' : 'chevron-down'}
        />
      </div>
      <Collapse
        open={isContactDetailsPanelOpen}
        fadeOut
        fadeColor="#FFF"
        fadeHeight={50}
        animationDuration={200}
      >
        {isSectionOneValid && isStartDateEntered && (
          <PageSection
            className="new-patient-confirmation"
            heading="Direct Debit Mandate"
          >
            <DentalPlanAgreement />
            <BankInstruction />
            <Confirmation />
          </PageSection>
        )}

        {!isSectionOneValid && (
          <PageSection heading="Please make sure all fields in the first section are completed." />
        )}
        {!isStartDateEntered && isSectionOneValid && (
          <PageSection heading="Please make sure you have selected a First Payment Date for the Plan." />
        )}
        <Stepper stepOneActive stepTwoActive stepThreeActive />
      </Collapse>
    </PageSection>
  );
};

export const mapState = (state: StoreState) => {
  const errors: any = getFormSyncErrors('iplanNewPatientForm')(state);
  const isSectionOneValid =
    !errors.title &&
    !errors.first_name &&
    !errors.last_name &&
    !errors.dob &&
    !errors.email &&
    !errors.mobile &&
    !errors.postcode &&
    !errors.account_name &&
    !errors.account_number &&
    !errors.sort_code &&
    !errors.direct_debit_declaration;
  const isStartDateEntered = !errors.start_date;
  return {
    isSectionOneValid,
    isStartDateEntered,
  };
};

export { PatientConfirmationSection as TestablePatientConfirmationSection };

const connector = connect(mapState, {});

export default connector(PatientConfirmationSection);

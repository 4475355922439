import React from 'react';
import { Switch } from 'react-router-dom';

import AllowRoute from '^/common/allow-route';
import AddPatientsPage from '^/iplan/patients/add-patients-routes-page';
import PageContainer from '../iplan/page-container/headless-index';

export const IPlanPatientRoutes = () => {
  return (
    <PageContainer>
      <Switch>
        <AllowRoute path="/" component={AddPatientsPage} />
      </Switch>
    </PageContainer>
  );
};

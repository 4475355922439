import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { AsyncActionSet } from '@dabapps/redux-requests';
import React from 'react';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import SidebarCard, { SidebarCardSection } from '^/common/sidebar-card';

interface SetPropsProps {
  confirm: boolean;
}

interface OwnProps {
  heading: string;
  actionLabel: string;
  confirmationText?: string;
  title?: string;
  onConfirm?: (...args: unknown[]) => void;
  actions?: ReadonlyArray<AsyncActionSet>;
}

export type ConfirmationSidebarCardProps = OwnProps &
  SetPropsInterface<SetPropsProps>;

class ConfirmationSideBarCard extends React.PureComponent<
  ConfirmationSidebarCardProps
> {
  public render() {
    const {
      confirm,
      onConfirm,
      actions,
      heading,
      actionLabel,
      title = 'Delete Subscription',
      confirmationText = `Are you sure you want to ${actionLabel}`,
    } = this.props;
    return (
      <SidebarCard transparent heading={title}>
        {!confirm ? (
          <SidebarCardSection>
            {this.props.children}
            <div className="buttons">
              <AppButton destructive onClick={this.toggleConfirm}>
                {heading}
              </AppButton>
            </div>
          </SidebarCardSection>
        ) : (
          <SidebarCardSection>
            <p>{confirmationText}</p>
            <ErrorRenderer actions={actions} fields={['non_field_errors']} />
            <div className="buttons">
              <AppButton onClick={this.toggleConfirm}>Cancel</AppButton>
              <AppButton destructive onClick={onConfirm}>
                {actionLabel}
              </AppButton>
            </div>
          </SidebarCardSection>
        )}
      </SidebarCard>
    );
  }

  public toggleConfirm = () => {
    this.props.setProps({ confirm: !this.props.confirm });
  };
}

// Disconnected version used for testing
export { ConfirmationSideBarCard as TestableConfirmationSideBarCard };

export const getInitialProps = () => ({
  confirm: false,
});

export default withSetProps<SetPropsProps, OwnProps>(getInitialProps)(
  ConfirmationSideBarCard
);

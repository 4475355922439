import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import { convertCacheToArray } from '^/common/recently-viewed/helper-functions';
import { clearContacts, GET_CONTACTS, getContacts } from '^/contacts/actions';
import ContactsList from '^/contacts/list';
import ContactsPickerForm from '^/contacts/picker-form';
import { ContactResponse, ContactStatus, ContactType } from '^/contacts/types';
import { FilterField, FilterList, FilterType } from '^/filters/types';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  filters?: FilterList;
  hideContacts?: ReadonlyArray<string>;
  hideRecents?: boolean;
  searchFields?: ReadonlyArray<FilterField>;
  showCategories?: boolean;
  hideDob?: boolean;
  contactType?: ContactType;
  onContactSelected(contact: ContactResponse): void;
}

// State props that come from redux.
interface StateProps {
  search?: FilterList;
}

export type ContactsPickerProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class ContactsPicker extends React.PureComponent<ContactsPickerProps> {
  public componentDidMount(): void {
    const { hideRecents, filters, contactType } = this.props;
    if (hideRecents) {
      this.props.getContacts(
        {
          ...filters,
          show_archived: 'False',
          ...(contactType ? { type: contactType } : {}),
        },
        undefined,
        1,
        100
      );
    }
  }

  public componentWillUnmount() {
    this.props.clearContacts();
  }

  public render() {
    const {
      hideContacts = [],
      recentContacts,
      search,
      searchFields = [
        { name: 'search', label: 'Name', type: FilterType.Text },
        { name: 'postcode', label: 'Postcode', type: FilterType.Text },
      ],
      searchedContacts,
      filters,
      hideRecents,
      loading,
      showCategories,
      hideDob,
    } = this.props;

    const contacts =
      search || hideRecents
        ? getAllRetrievedPages(searchedContacts)
        : this.filterContacts(
            recentContacts.filter(
              contact => contact.status !== ContactStatus.Archived
            ),
            filters
          );

    const filteredContacts = contacts.filter(
      (contact: ContactResponse) => !hideContacts.includes(contact.id)
    );

    return (
      <div>
        <div className="modal-body-section">
          <ContactsPickerForm
            searchFields={searchFields}
            initialValues={filters}
            onSubmit={this.onSearch}
          />
        </div>
        <div
          className={classnames('modal-body-section scrollable', {
            loading: loading.contacts,
          })}
        >
          <h4>
            {search || hideRecents ? 'Search results' : 'Recent contacts'}
          </h4>
          <ContactsList
            contacts={filteredContacts}
            hideDob={hideDob}
            showCategories={showCategories}
            onContactSelected={this.props.onContactSelected}
          />
          {filteredContacts.length < 1 && (
            <div className="empty-table-state">No contacts</div>
          )}
        </div>
      </div>
    );
  }

  public onSearch = (search: FilterList, dispatch: Dispatch) => {
    this.props.setProps({ search });

    const { filters, contactType } = this.props;
    const combinedFilters = {
      ...filters,
      ...search,
      ...(contactType ? { type: contactType } : {}),
    };
    return getContacts(combinedFilters, undefined, 1, 100)(dispatch);
  };

  private filterContacts(
    contacts: ReadonlyArray<ContactResponse>,
    filters?: FilterList
  ): ReadonlyArray<ContactResponse> {
    if (!filters) {
      return contacts;
    }

    return contacts.filter(contact => {
      if (filters.type) {
        const types = filters.type.split(',');
        return types.includes(contact.type);
      }

      return true;
    });
  }
}

// Disconnected version used for testing
export { ContactsPicker as TestableContactsPicker };

export const getInitialProps = (): StateProps => ({});

export const mapState = (state: StoreState) => ({
  searchedContacts: state.contacts,
  recentContacts: convertCacheToArray(state.contactsCache),
  loading: {
    contacts: anyPending(state.responses, [GET_CONTACTS]),
  },
});

const connector = connect(mapState, { getContacts, clearContacts });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(ContactsPicker)
);

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_PLAN_TEMPLATE_CODE = 'other';

export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const CODE_COMPANY_ID = '99999999';

export const DEBOUNCE_MS = 500;

export const INTERVAL_MS = 5000;

export const CHANGE_PASSWORD_URL = `${window.OPEN_ID_BASE_URL}Account/Profile?ReturnUrl=${window.SITE_URL}`;

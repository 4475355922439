import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import PageContent from '^/page-container/page-content';
import { CREATE_PRODUCT, createProduct } from './actions';
import ProductForm from './form';
import { Product } from './types';

export type ProductsCreatePageProps = RouteComponentProps;

/**
 * Page for creating new products.
 */
class ProductsCreatePage extends React.PureComponent<ProductsCreatePageProps> {
  public render() {
    return (
      <PageContent>
        <HeaderBar title="Products" transparent />
        <Container>
          <main>
            <PageSection heading="New Product">
              <ProductForm
                form="create-product-form"
                actions={[CREATE_PRODUCT]}
                onSubmit={this.onSave}
                onCancel={this.onCancel}
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  /**
   * Fires off the action that will save this product.
   * @param {Product} product - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public onSave = async (product: Product, dispatch: Dispatch) => {
    await createProduct(product)(dispatch);
    this.props.history.push('/admin/products');
  };

  /**
   * Cancels the submission of this form. Goes back to the previous page if there is one, or to /products if there is no history.
   */
  public onCancel = () => {
    this.props.history.push('/admin/products');
  };
}

/** Disconnected version of the component that is used for testing. */
export { ProductsCreatePage as TestableProductsCreatePage };

export default ProductsCreatePage;

import { AnyAction } from 'redux';

import {
  DELETE_PAYMENT_DETAILS,
  GET_LINKED_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_BY_CONTACT,
  VALIDATE_PAYMENT_DETAILS,
} from '^/payment-details/actions';
import { PaymentDetailsResponse } from '^/payment-details/types';
import {
  CREATE_RELATIONSHIP,
  DELETE_RELATIONSHIP,
} from '^/relationships/actions';
import { ByContact, StoreState } from '^/types';

export function paymentDetails(
  state: {
    [contactId: string]: PaymentDetailsResponse | undefined;
  } = {},
  action: AnyAction
): { [contactId: string]: PaymentDetailsResponse | undefined } {
  switch (action.type) {
    case GET_PAYMENT_DETAILS_BY_CONTACT.SUCCESS:
      if (action.payload.data.results.length === 0) {
        return state;
      }

      return {
        [action.payload.data.results[0].contact]:
          action.payload.data.results[0],
      };
    case GET_PAYMENT_DETAILS.SUCCESS:
      return {
        [action.payload.data.contact]: action.payload.data,
      };
    case DELETE_PAYMENT_DETAILS.SUCCESS:
      return {
        [action.payload.data.contact]: undefined,
      };
    default:
      return state;
  }
}

export function paymentDetailsCache(
  state: StoreState['paymentDetailsCache'] = {},
  action: AnyAction
): StoreState['paymentDetailsCache'] {
  switch (action.type) {
    case GET_PAYMENT_DETAILS_BY_CONTACT.SUCCESS:
      if (action.payload.data.results.length < 1) {
        return state;
      }

      const resultsById = action.payload.data.results.reduce(
        (
          results: StoreState['paymentDetailsCache'],
          value: PaymentDetailsResponse
        ) => ({ ...results, [value.id]: value }),
        {}
      );

      return {
        ...state,
        ...resultsById,
      };
    case GET_LINKED_PAYMENT_DETAILS.SUCCESS:
    case GET_PAYMENT_DETAILS.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: action.payload.data,
      };
    default:
      return state;
  }
}

export const linkedPaymentDetails = (
  state: ByContact<PaymentDetailsResponse> = {},
  action: AnyAction
): ByContact<PaymentDetailsResponse> => {
  switch (action.type) {
    case GET_LINKED_PAYMENT_DETAILS.SUCCESS:
      return { ...state, [action.meta.for]: action.payload.data };
    case CREATE_RELATIONSHIP.SUCCESS:
    case DELETE_RELATIONSHIP.SUCCESS:
      return {};
    default:
      return state;
  }
};

export const loqateValidation = (
  state: StoreState['loqateValidation'] = {},
  action: AnyAction
) => {
  switch (action.type) {
    case VALIDATE_PAYMENT_DETAILS.SUCCESS:
      return {
        ...state,
        [`${action.meta.accountNumber}+${action.meta.sortCode}`]: action.payload
          .data.Items[0],
      };
    default:
      return state;
  }
};

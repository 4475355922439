import { anyPending } from '@dabapps/redux-requests';
import Container from '@dabapps/roe/dist/js/components/grid/container';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import {
  GET_MEMBERSHIP,
  getMembership,
  saveMembership,
} from '^/admin/memberships/actions';
import MembershipsForm from '^/admin/memberships/form';
import { ErrorPage } from '^/common/error-page';
import HeaderBar from '^/common/header-bar';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import { Membership } from '^/memberships/types';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

export type MembershipsEditPageProps = RouteComponentProps<{ id: string }> &
  ConnectedProps<typeof connector>;

class MembershipsEditPage extends React.PureComponent<
  MembershipsEditPageProps
> {
  public componentDidMount(): void {
    const {
      membership,
      match: { params },
    } = this.props;

    if (cachedItemHasExpired(membership)) {
      this.props.getMembership(params.id);
    }
  }

  public render() {
    const { membership, loading } = this.props;

    if (!membership) {
      if (loading) {
        return (
          <PageContent>
            <HeaderBar title="Membership" transparent loading />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Membership not found" />;
    }

    return (
      <PageContent>
        <HeaderBar
          title={membership.code}
          subtitle={membership.description}
          transparent
        />
        <Container>
          <main>
            <PageSection>
              <MembershipsForm
                initialValues={membership}
                onSubmit={this.onSave}
                onCancel={this.onCancel}
                isEditPage
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  public onSave = (membership: Membership, dispatch: Dispatch) =>
    saveMembership(
      this.props.match.params.id,
      membership
    )(dispatch).then(() => {
      this.props.history.push(`/admin/memberships`);
    });

  public onCancel = () => this.props.history.goBack();
}

// Disconnected version used for testing
export { MembershipsEditPage as TestableMembershipsEditPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ id: string }>
) => ({
  membership: getItemFromCache(props.match.params.id, state.membershipCache),
  loading: anyPending(state.responses, [GET_MEMBERSHIP]),
});

const connector = connect(mapState, { getMembership });

export default connector(MembershipsEditPage);

import { TableCell, TableRow } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, FieldArrayMetaProps, formValueSelector } from 'redux-form';

import { formatDate } from '^/common/helper-functions';
import { calculateLedgerUnbalance, round } from '^/contacts/ledger/helpers';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import RenderCurrencyField from './render-currency-field';

// Props that come from the parent component.
interface OwnProps {
  item: string;
  meta: FieldArrayMetaProps;
}

export type RenderAllocationsRowProps = OwnProps &
  ConnectedProps<typeof connector>;

class RenderAllocationsRow extends React.PureComponent<
  RenderAllocationsRowProps
> {
  public render() {
    const { item, ledger_to, allocation } = this.props;

    if (!ledger_to) {
      return <TableRow />;
    }

    const total = round(ledger_to.amount + ledger_to.vat_amount);

    const outstanding = round(calculateLedgerUnbalance(ledger_to));

    const allocated = round(allocation.amount);

    const newOutstanding = round(outstanding - allocated);

    return (
      <TableRow>
        <TableCell>{formatDate(ledger_to.entry_date)}</TableCell>
        <TableCell>{ledger_to.type}</TableCell>
        <TableCell>{ledger_to.tx_id}</TableCell>
        <TableCell className="align-right">{formatFeeAmount(total)}</TableCell>
        <TableCell className="align-right">
          {formatFeeAmount(outstanding)}
        </TableCell>
        <TableCell className="align-right">
          <Field name={`${item}.amount`} component={RenderCurrencyField} />
        </TableCell>
        <TableCell
          className={classNames('align-right', {
            error: allocated > outstanding,
            warning: allocated < outstanding,
          })}
        >
          {formatFeeAmount(newOutstanding)}
        </TableCell>
      </TableRow>
    );
  }
}

// Disconnected version used for testing
export { RenderAllocationsRow as TestableRenderAllocationsRow };

export const mapState = (state: StoreState, props: OwnProps) => {
  const allocation = formValueSelector(props.meta.form)(state, props.item);

  return {
    allocation,
    ledger_to: getItemFromCache(
      allocation?.ledger_to,
      state.ledgerEntriesByDetail
    ),
  };
};

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export default connector(RenderAllocationsRow);

import Container from '@dabapps/roe/dist/js/components/grid/container';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { createMembership } from '^/admin/memberships/actions';
import MembershipsForm from '^/admin/memberships/form';
import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import { Membership } from '^/memberships/types';
import PageContent from '^/page-container/page-content';

export type MembershipCreatePageProps = RouteComponentProps;

class MembershipsCreatePage extends React.PureComponent<
  MembershipCreatePageProps
> {
  public render() {
    return (
      <PageContent>
        <HeaderBar title="Create Membership" transparent />
        <Container>
          <main>
            <PageSection>
              <MembershipsForm
                onSubmit={this.onSave}
                onCancel={this.onCancel}
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  public onSave = (membership: Membership, dispatch: Dispatch) => {
    let newMembership;

    // Reset defaults if user toggles to a billing container membership, else use form values.
    if (membership.is_billing_container) {
      newMembership = {
        code: membership.code,
        description: membership.description,
        is_billing_container: true,
        fee: { vat_code: 1 },
        joining_fee: { vat_code: 1 },
        variable_pricing: false,
        associated_bank: membership.associated_bank,
        plan_length_months: membership.plan_length_months,
      };
    } else {
      newMembership = membership;
    }

    return createMembership(newMembership)(dispatch).then(() => {
      this.props.history.replace(`/admin/memberships`);
    });
  };

  public onCancel = () => this.props.history.goBack();
}

export { MembershipsCreatePage as TestableMembershipsCreatePage };

export default MembershipsCreatePage;

import { anyPending, isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import { getContactName } from '^/common/helper-functions';
import PageSubSection from '^/common/page-section/page-sub-section';
import { ContactResponse } from '^/contacts/types';
import { paymentDetailActions } from '^/payment-details/actions';
import PaymentDetailsCard from '^/payment-details/payment-details-card';
import { GET_CONTACT_RELATIONSHIPS } from '^/relationships/actions';
import RelationshipsDetailCard from '^/relationships/detail-card';
import {
  filterByType,
  firstRelationshipOfType,
} from '^/relationships/helper-functions';
import RelationshipsListCard from '^/relationships/list-card';
import { RelationshipType } from '^/relationships/types';
import { CachedItem, StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  contact?: CachedItem<ContactResponse> | ContactResponse;
  disabled?: boolean;
  hidePayees?: boolean;
  isHeadOffice?: boolean;
  allowAdd?: boolean;
  linkContactName?: boolean;
}

export type PaymentDetailsSectionProps = OwnProps &
  ConnectedProps<typeof connector>;

class PaymentDetailsSection extends React.PureComponent<
  PaymentDetailsSectionProps
> {
  public render() {
    const {
      contact,
      loading,
      relationships,
      disabled,
      hidePayees,
      allowAdd,
      linkContactName,
    } = this.props;

    if (!contact) {
      return;
    }

    const contactName = getContactName(contact);
    const hasPayer = firstRelationshipOfType(
      getAllRetrievedPages(relationships).filter(
        relationship => relationship.contact === contact.id
      ),
      RelationshipType.PayeePayer
    );

    const hasPayees =
      filterByType(
        getAllRetrievedPages(relationships).filter(
          relationship => relationship.related_contact === contact.id
        ),
        [RelationshipType.PayeePayer]
      ).length > 0;

    const hasBoth = hasPayees && hasPayer;
    return (
      <div className="flex-column">
        {(!hasPayees || hasBoth) && (
          <RelationshipsDetailCard
            allowAdd={allowAdd}
            heading="Payer"
            contactId={contact.id}
            label="Payer"
            showCategories
            linkContactName={linkContactName}
            types={[RelationshipType.PayeePayer]}
            disabled={disabled}
          />
        )}
        {(!hasPayer || hasBoth) && !hidePayees && (
          <RelationshipsListCard
            allowAdd={allowAdd}
            heading="Payees"
            contactId={contact.id}
            filters={{ type: this.props.isHeadOffice ? 'Practice' : 'Patient' }}
            label="Payee"
            types={[RelationshipType.PayeePayer]}
            disabled={disabled}
            linkContactName={linkContactName}
            paginated
          />
        )}
        {(!hasPayer || hasBoth) && (
          <Allow
            roles={[
              UserRole.AdminLevel,
              UserRole.FinanceLevel,
              UserRole.OfficeLevel,
              UserRole.SalesLevel,
            ]}
          >
            <PageSubSection
              heading="Payment Details"
              loading={loading.paymentDetails}
            >
              <PaymentDetailsCard
                contactName={contactName}
                contactId={contact.id}
                contactCrmId={contact.crm_id}
                disabled={disabled}
              />
            </PageSubSection>
          </Allow>
        )}
      </div>
    );
  }
}

// Disconnected version used for testing
export { PaymentDetailsSection as TestablePaymentDetailsSection };

export const mapState = (state: StoreState, props: OwnProps) => {
  const contactRelationships = props.contact
    ? state.contactRelationships[props.contact.id]
    : null;

  return {
    relationships:
      contactRelationships && contactRelationships[RelationshipType.PayeePayer]
        ? contactRelationships[RelationshipType.PayeePayer]
        : null,
    loading: {
      relationships: isPending(
        state.responses,
        GET_CONTACT_RELATIONSHIPS,
        RelationshipType.PayeePayer
      ),
      paymentDetails: anyPending(state.responses, paymentDetailActions),
    },
  };
};

const connector = connect(mapState, {});

export default connector(PaymentDetailsSection);

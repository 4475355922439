import { Table, TableBody, TableCell, TableHead, TableRow } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { formValueSelector, WrappedFieldArrayProps } from 'redux-form';

import { round, sumAllocationAmounts } from '^/contacts/ledger/helpers';
import { LedgerAllocation } from '^/contacts/ledger/types';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import RenderAllocationsRow from './render-allocations-row';

// Props that come from the parent component.
export interface RenderAllocationsOwnProps {
  limit: number;
}

export type RenderAllocationsProps = RenderAllocationsOwnProps &
  WrappedFieldArrayProps<LedgerAllocation> &
  ConnectedProps<typeof connector>;

class RenderAllocations extends React.PureComponent<RenderAllocationsProps> {
  public render() {
    const { fields, limit, meta, allocations } = this.props;

    const available = round(limit);
    const allocated = round(sumAllocationAmounts(allocations));

    return (
      <Table fill>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell title="Transaction ID">TX ID</TableCell>
            <TableCell className="align-right">Total</TableCell>
            <TableCell className="align-right">Outstanding</TableCell>
            <TableCell className="align-right">Allocate</TableCell>
            <TableCell className="align-right">New outstanding</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item: string) => {
            return <RenderAllocationsRow key={item} item={item} meta={meta} />;
          })}
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} className={classNames('align-right')}>
              {limit ? (
                <p>
                  Available to allocate:{' '}
                  <strong>{formatFeeAmount(available)}</strong>
                </p>
              ) : null}
            </TableCell>
            <TableCell
              colSpan={2}
              className={classNames('align-right', {
                warning: allocated < available,
                error: allocated > available,
              })}
            >
              <p>
                Total allocated: <strong>{formatFeeAmount(allocated)}</strong>
              </p>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

// Disconnected version used for testing
export { RenderAllocations as TestableRenderAllocations };

export const mapState = (
  state: StoreState,
  props: WrappedFieldArrayProps<LedgerAllocation>
) => ({
  allocations: formValueSelector(props.meta.form)(state, props.fields.name),
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export default connector(RenderAllocations);

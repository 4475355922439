import { TableCell, TableHeader } from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

import {
  formatAddress,
  formatPostcode,
  getPrimaryAddress,
  truncate,
} from '^/address/helpers';
import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import { getContactName } from '^/common/helper-functions';
import ItemRow from '^/common/paginated-table/item-row';
import { ContactResponse } from '^/contacts/types';

export interface PracticeRowProps {
  practiceResponse: ContactResponse;
  push: typeof push;
}

export class PracticeRow extends React.PureComponent<PracticeRowProps> {
  public render() {
    const { practiceResponse } = this.props;
    const practice = practiceResponse.company;
    const primaryAddress = getPrimaryAddress(practiceResponse);

    if (!practice) {
      return null;
    }

    return (
      <ItemRow item={practiceResponse} onClick={this.navigateToPractice}>
        <TableHeader>{getContactName(practiceResponse)}</TableHeader>
        <Allow
          roles={[
            UserRole.AdminLevel,
            UserRole.FinanceLevel,
            UserRole.OfficeLevel,
          ]}
        >
          <TableCell>
            <AppButton small onClick={this.navigateToPracticePatients}>
              view patients
            </AppButton>
          </TableCell>
        </Allow>
        <TableCell>{practiceResponse.crm_id}</TableCell>
        <TableCell>
          {primaryAddress ? truncate(formatAddress(primaryAddress), 35) : '-'}
        </TableCell>
        <TableCell>
          {primaryAddress ? formatPostcode(primaryAddress.postcode) : '-'}
        </TableCell>
      </ItemRow>
    );
  }

  public navigateToPractice = (contact: ContactResponse) =>
    this.props.push(`/practices/${contact.id}`);

  public navigateToPracticePatients = (event?: { stopPropagation(): void }) => {
    if (event) {
      event.stopPropagation();
    }

    this.props.push(`/practices/${this.props.practiceResponse.id}/patients`);
  };
}

export { PracticeRow as TestablePracticeRow };

export default connect(undefined, { push })(PracticeRow);

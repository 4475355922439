import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { CONTACTS_ENDPOINT } from '^/contacts/actions';
import {
  createRelationship,
  deleteRelationshipsOfType,
} from '^/relationships/actions';
import { RelationshipType } from '^/relationships/types';

export const GET_PRACTICE_FOR_PATIENT = makeAsyncActionSet(
  'GET_PRACTICE_FOR_PATIENT'
);
export function getPracticeForPatient(patientId: string) {
  return (dispatch: Dispatch) => {
    request(
      GET_PRACTICE_FOR_PATIENT,
      `${CONTACTS_ENDPOINT}/?related_contact=${patientId}&relationship=${RelationshipType.PatientPractice}&page_size=1`,
      'GET',
      undefined,
      { metaData: { patientId, page: 1, pageSize: 1 } }
    )(dispatch);
  };
}

export const GET_DENTISTS_BY_PRACTICE = makeAsyncActionSet(
  'GET_DENTISTS_BY_PRACTICE'
);
export function getDentistsByPractice(practiceId: string) {
  return (dispatch: Dispatch) => {
    request(
      GET_DENTISTS_BY_PRACTICE,
      `${CONTACTS_ENDPOINT}/?related_contact=${practiceId}&relationship=${RelationshipType.PerformerPractice}&page_size=100`,
      'GET',
      undefined,
      { metaData: { practiceId, page: 1, pageSize: 100 } }
    )(dispatch);
  };
}

export const CHANGE_PRACTICE = makeAsyncActionSet('CHANGE_PRACTICE');
export function changePractice(
  patientId: string,
  newPracticeId: string,
  newDentistId?: string
) {
  return (dispatch: Dispatch) =>
    createRelationship(
      patientId,
      newPracticeId,
      RelationshipType.PatientPractice
    )(dispatch).then(() => {
      if (!newDentistId) {
        deleteRelationshipsOfType(
          patientId,
          RelationshipType.PatientPerformer
        )(dispatch).catch(() => null);
        return Promise.resolve();
      }

      return createRelationship(
        patientId,
        newDentistId,
        RelationshipType.PatientPerformer
      )(dispatch);
    });
}

export const patientActions = [
  GET_PRACTICE_FOR_PATIENT,
  GET_DENTISTS_BY_PRACTICE,
];

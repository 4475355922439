import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { clearContacts, GET_CONTACTS } from '^/contacts/actions';
import { FilterList } from '^/filters/types';
import PracticeSelect from '^/form-helpers/practice-select';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderToggleSwitch from '^/form-helpers/render-toggle-switch';
import { ContactType, StoreState } from '^/types';

export type MembershipFields = ReadonlyArray<{
  label: string;
  name: string;
}>;

// Props that come from the parent component.
interface OwnProps {
  membershipFields: MembershipFields;
}

export type LettersFilterFormProps = OwnProps &
  InjectedFormProps<FilterList, OwnProps> &
  ConnectedProps<typeof connector>;

export enum FilterTypes {
  Practices = 'Practices',
  Patients = 'Patients',
}

class LettersFilterForm extends React.PureComponent<LettersFilterFormProps> {
  public componentDidUpdate(prevProps: LettersFilterFormProps) {
    if (this.props.filterToggle !== prevProps.filterToggle) {
      const selectedToggle = this.props.filterToggle;
      this.handleClear();
      this.props.change('type', selectedToggle);
    }
  }

  public render() {
    const {
      handleSubmit,
      initialized,
      dirty,
      submitting,
      membershipFields,
      filterToggle,
      error,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="form-header">
          <h4>Filters</h4>
          <AppButton
            id="clear-button"
            link
            disabled={!initialized && !dirty}
            loading={submitting}
            onClick={this.handleClear}
          >
            CLEAR
          </AppButton>
        </div>
        <FormGroup>
          <Field
            component={RenderToggleSwitch}
            options={[
              { label: FilterTypes.Practices, value: ContactType.Practice },
              {
                label: FilterTypes.Patients,
                value: ContactType.Patient,
              },
            ]}
            label="Filter by:"
            name="type"
          />
        </FormGroup>
        {filterToggle === ContactType.Practice && (
          <FormGroup className="checkbox-list">
            <h6>Membership</h6>
            {membershipFields.map(option => (
              <Field
                key={`membership-field-${option.name}`}
                component={RenderCheckBox}
                label={option.label}
                name={option.name}
              />
            ))}
          </FormGroup>
        )}
        {filterToggle === ContactType.Patient && (
          <>
            <FormGroup>
              <Field
                name="related_contact"
                label="Practice"
                component={PracticeSelect}
              />
              <div className="form-header">
                <h6>Type</h6>
              </div>
              <Field component={RenderCheckBox} label="Payer" name="is_payer" />
              <Field
                component={RenderCheckBox}
                label="Over 18"
                name="is_over_18"
              />
            </FormGroup>
          </>
        )}
        {error && (
          <FormGroup>
            <div className="form-field">
              <ErrorRenderer
                actions={[GET_CONTACTS]}
                error={[error]}
                showStatusErrors
              />
            </div>
          </FormGroup>
        )}
        <div className="form-buttons">
          <AppButton type="submit" loading={submitting}>
            Search
          </AppButton>
        </div>
      </Form>
    );
  }

  public handleClear = () => {
    this.props.reset();
    this.props.clearContacts();
  };
}

// Disconnected version used for testing
export { LettersFilterForm as TestableLettersFilterForm };

const selector = formValueSelector('lettersFilterForm');

export const mapState = (state: StoreState) => ({
  filterToggle: selector(state, 'type'),
});

const connector = connect(mapState, { clearContacts });

export default reduxForm<FilterList, OwnProps>({
  form: 'lettersFilterForm',
  initialValues: { type: ContactType.Practice },
})(connector(LettersFilterForm));

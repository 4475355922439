import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { GET_COLLECTIONS } from '^/collections/actions';
import { Collection } from '^/collections/types';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import Loading from '^/common/loading';
import { generateCollectionDropdownOptions } from '^/contacts/ledger/helpers';
import RenderDropdown from '^/form-helpers/render-dropdown';
import { PaginatedArray, StoreState } from '^/types';

interface OwnProps {
  collections: PaginatedArray<Readonly<Required<Collection>>>;
}

const selector = formValueSelector('collectionPickerForm');

export const mapState = (state: StoreState) => ({
  collection: selector(state, 'collection'),
  loading: isPending(state.responses, GET_COLLECTIONS),
});

const connector = connect(mapState);

export type CollectionPickerFormProps = InjectedFormProps<
  { collection: string },
  OwnProps
> &
  OwnProps &
  ConnectedProps<typeof connector>;

export class CollectionPickerForm extends React.PureComponent<
  CollectionPickerFormProps
> {
  public render() {
    const { handleSubmit, loading, collections, collection } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="modal-body-section">
          {loading ? (
            <Loading />
          ) : (
            <Field
              label="Collection"
              name="collection"
              component={RenderDropdown}
              type="text"
              options={generateCollectionDropdownOptions(collections)}
            />
          )}
        </div>
        <ErrorRenderer
          fields={['non_field_errors']}
          actions={[GET_COLLECTIONS]}
          showStatusErrors
        />
        <div className="modal-body-section form-buttons">
          <AppButton type="submit" disabled={!collection} loading={loading}>
            Next
          </AppButton>
        </div>
      </Form>
    );
  }
}

// Disconnected version used for testing
export { CollectionPickerForm as TestableCollectionPickerForm };

export default reduxForm<{ collection: string }, OwnProps>({
  form: 'collectionPickerForm',
})(connector(CollectionPickerForm));

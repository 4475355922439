import { Address, AddressResponse } from '^/address/types';
import {
  CompanyProfile,
  CompanyProfileResponse,
} from '^/contacts/companies/types';
import { PersonProfile, PersonProfileResponse } from '^/contacts/persons/types';
import { PlanSubscriptionResponse } from '^/plans/subscriptions/types';
import { RelationshipResponse } from '^/relationships/types';

export interface Contact {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  readonly crm_id?: string;
  readonly name?: string;
  readonly salutation?: string;
  type: ContactType;
  status?: ContactStatus;
  mailing_address?: string | null;
  category?: CategoryType | null;
  readonly calculated_balance?: number;
  readonly total_unallocated?: number;
  readonly subscription_status?: ContactSubscriptionStatus;
  person?: PersonProfile | null;
  company?: CompanyProfile | null;
  addresses?: ReadonlyArray<Address>;
}

export type ContactResponse = Readonly<{
  id: string;
  created: string;
  modified: string;
  crm_id: string;
  name: string;
  salutation: string;
  type: ContactType;
  status: ContactStatus;
  mailing_address: string | null;
  category: CategoryType | null;
  calculated_balance?: number; // Not included in a list response
  total_unallocated?: number; // Not included in a list response
  subscription_status?: ContactSubscriptionStatus; // Not included in a list response
  person: PersonProfileResponse | null;
  company: CompanyProfileResponse | null;
  addresses: ReadonlyArray<AddressResponse>;
  clinician: string;
}>;

export interface UnverifiedPatientResponse extends ContactResponse {
  clinician_relationship: RelationshipResponse | null;
  subscription: PlanSubscriptionResponse;
  additional_patients: ReadonlyArray<{
    title: string;
    first_name: string;
    last_name: string;
    dob: string;
  }>;
}

export type FileResponse = Readonly<{
  id: string;
  created: string;
  file_name: string;
  uploaded_by_name: string;
}>;

export enum ContactStatus {
  Live = 'LIVE',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
}

export enum ContactSubscriptionStatus {
  Lapsed = 'Lapsed',
  Overdue = 'Overdue',
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ContactType {
  Patient = 'Patient',
  Practice = 'Practice',
  Company = 'Company',
  Individual = 'Individual',
}

export enum CategoryType {
  AestheticPractitioner = 'AEP',
  BusinessContact = 'BCO',
  ClinicalDentalTechnician = 'CDT',
  ExternalConsultant = 'CON',
  Customer = 'CUS',
  Dentist = 'DEN',
  DentalHealthEducator = 'DHE',
  DentalNurse = 'DNU',
  MedicalDoctor = 'DOC',
  DentalTherapist = 'DTH',
  Hygienist = 'HYG',
  HeadOffice = 'HEO',
  Lead = 'LEA',
  Manager = 'MAN',
  NonMemberPayer = 'NMP',
  Patient = 'PAT',
  PracticeManager = 'PMA',
  DentalPractice = 'PRAC',
  ProfessionalBody = 'PROF',
  MedicalPractice = 'MPRAC',
  StateRegisteredNurse = 'SRN',
  TeamMember = 'TEA',
}

export interface Category {
  type: CategoryType;
  name: string;
  description?: string;
  contactTypes: ReadonlyArray<ContactType>;
}

export const Categories: ReadonlyArray<Category> = [
  {
    type: CategoryType.Patient,
    name: 'Patient',
    contactTypes: [ContactType.Patient],
  },
  {
    type: CategoryType.Dentist,
    name: 'Dentist',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.DentalPractice,
    name: 'Dental Practice',
    contactTypes: [ContactType.Practice],
  },
  {
    type: CategoryType.PracticeManager,
    name: 'Practice Manager',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.DentalNurse,
    name: 'Dental Nurse',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.Lead,
    name: 'Lead',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.TeamMember,
    name: 'Team Member',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.NonMemberPayer,
    name: 'Non-member Payer',
    contactTypes: [ContactType.Company, ContactType.Individual],
  },
  {
    type: CategoryType.AestheticPractitioner,
    name: 'Aesthetic Practitioner',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.BusinessContact,
    name: 'Business Contact',
    contactTypes: [ContactType.Individual, ContactType.Company],
  },
  {
    type: CategoryType.ClinicalDentalTechnician,
    name: 'Clinical Dental Technician',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.ExternalConsultant,
    name: 'External Consultant',
    contactTypes: [ContactType.Individual, ContactType.Company],
  },
  {
    type: CategoryType.Customer,
    name: 'Customer',
    contactTypes: [ContactType.Individual, ContactType.Company],
  },
  {
    type: CategoryType.DentalHealthEducator,
    name: 'Dental Health Educator',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.MedicalDoctor,
    name: 'Medical Doctor',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.DentalTherapist,
    name: 'Dental Therapist',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.Hygienist,
    name: 'Hygienist',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.HeadOffice,
    name: 'Head Office',
    contactTypes: [ContactType.Company, ContactType.Individual],
  },
  {
    type: CategoryType.Manager,
    name: 'Manager',
    contactTypes: [ContactType.Individual],
  },
  {
    type: CategoryType.ProfessionalBody,
    name: 'Professional Body',
    contactTypes: [ContactType.Company],
  },
  {
    type: CategoryType.MedicalPractice,
    name: 'Medical Practice',
    contactTypes: [ContactType.Practice],
  },
  {
    type: CategoryType.StateRegisteredNurse,
    name: 'State Registered Nurse',
    contactTypes: [ContactType.Individual],
  },
];

export function findCategory(
  categoryType?: CategoryType | null
): Category | undefined {
  if (!categoryType) {
    return undefined;
  }

  return Categories.find(category => category.type === categoryType);
}

export function getCategoryName(
  categoryType?: CategoryType | null,
  defaultValue: string = '-'
): string {
  const category = findCategory(categoryType);

  return category ? `${category.name}` : defaultValue;
}

export function getContactCategory(
  contact?: Contact | null,
  defaultValue: string = '-'
): string {
  if (!contact) {
    return defaultValue;
  }

  return String(contact.category);
}

export function getContactCategoryName(
  contact?: Contact | null,
  defaultValue: string = '-'
): string {
  if (!contact) {
    return defaultValue;
  }

  return getCategoryName(contact.category, defaultValue);
}

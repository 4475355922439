import { Container } from '@dabapps/roe';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormErrors, SubmissionError } from 'redux-form';

import { UserResponse, UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import ConfirmationModal from '^/common/confirmation-modal';
import HeaderBar from '^/common/header-bar';
import { generateParamsSummary } from '^/common/helper-functions';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import { closeModal, openModal } from '^/modals/actions';
import PageContent from '^/page-container/page-content';
import {
  EXPORTS_ENDPOINT,
  getBulkReportInProgress,
  requestBulkReport,
  requestReport,
} from '^/routines/actions';
import CSVReportForm, {
  CSVReportFormOptions,
} from '^/routines/csv-report-form';
import RequestReportForm from '^/routines/report-form';
import ReportList from '^/routines/report-list';
import { StoreState } from '^/types';
import {
  CSV_REPORT_TYPE_REQUIRE_DATE,
  CSV_REPORT_TYPE_REQUIRE_MONTH,
} from './constants';
import {
  BulkReportParams,
  Report,
  ReportCategory,
  ReportParam,
  ReportType,
} from './types';

interface Props {
  loggedInUser: UserResponse | null;
  openModal: any;
  closeModal: any;
}

class ReportsRoutinesPage extends React.PureComponent<Props> {
  public render() {
    return (
      <PageContent>
        <HeaderBar title="Reports" transparent />
        <Container>
          <PageSection thin>
            <PageSubSection
              heading="Run a report"
              className="routines-reports"
              vertical
            >
              <CSVReportForm onSubmit={this.handleCSVReportSubmit} />
            </PageSubSection>
            <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
              <PageSubSection heading="Practice Report" vertical>
                <p>
                  Exports a PDF report containing a collection of sub-reports
                  for the selected practice.
                </p>
                <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                  <RequestReportForm
                    form={'generatePracticeReport'}
                    initialValues={{
                      report_type: ReportType.PracticeReport,
                      report_category: ReportCategory.Generic,
                      params: {
                        month: moment()
                          .day(1)
                          .format('YYYY-MM-DD'),
                        practice: null,
                        bulk_report: false,
                      },
                    }}
                    params={[
                      ReportParam.Month,
                      ReportParam.Practice,
                      ReportParam.BulkReport,
                    ]}
                    requiredParams={[ReportParam.Practice]}
                    onSubmit={this.requestReport}
                  />
                </Allow>
                <ReportList
                  type={ReportType.PracticeReport}
                  category={ReportCategory.Generic}
                  noApplyButtons
                  hideExceptions
                />
              </PageSubSection>
              <PageSubSection
                heading="Generate current patients and income report"
                vertical
              >
                <p>Generates the Current Patients and Income report.</p>
                <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                  <RequestReportForm
                    form={'generateCurrentPatientsAndIncomeReport'}
                    initialValues={{
                      report_type: ReportType.CurrentPatientsAndIncomeReport,
                      report_category: ReportCategory.Plan,
                    }}
                    params={[
                      ReportParam.RunFrom,
                      ReportParam.RunTo,
                      ReportParam.Practice,
                      ReportParam.BulkReport,
                    ]}
                    requiredParams={[ReportParam.RunFrom, ReportParam.RunTo]}
                    onSubmit={this.requestReport}
                  />
                </Allow>
                <ReportList
                  noApplyButtons
                  type={ReportType.CurrentPatientsAndIncomeReport}
                  category={ReportCategory.Plan}
                />
              </PageSubSection>
              <PageSubSection heading="Generate debtor report" vertical>
                <p>Generates the Debtor Report for all practices</p>
                <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                  <RequestReportForm
                    form={'generateDebtorReport'}
                    initialValues={{
                      report_type: ReportType.DebtorReport,
                      report_category: ReportCategory.Generic,
                    }}
                    onSubmit={this.requestReport}
                  />
                </Allow>
                <ReportList
                  noApplyButtons
                  type={ReportType.DebtorReport}
                  category={ReportCategory.Generic}
                />
              </PageSubSection>
              <PageSubSection heading="SAGE Invoices and Credits" vertical>
                <p>
                  Exports all transactions in a format that can be read by SAGE.
                </p>
                <p>
                  Clicking 'Apply' marks all exported transactions as exported,
                  these will not show in the next export.
                </p>
                <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                  <RequestReportForm
                    form={'generateSageInvoicesCredits'}
                    initialValues={{
                      report_type: ReportType.SAGEInvoicesCredits,
                      report_category: ReportCategory.Generic,
                      params: {
                        [ReportParam.RunTo]: moment().format('YYYY-MM-DD'),
                      },
                    }}
                    params={[ReportParam.RunFrom, ReportParam.RunTo]}
                    onSubmit={this.requestReport}
                  />
                </Allow>
                <ReportList
                  type={ReportType.SAGEInvoicesCredits}
                  category={ReportCategory.Generic}
                />
              </PageSubSection>
              <PageSubSection heading="SAGE Receipts and Refunds" vertical>
                <p>
                  Exports all transactions in a format that can be read by SAGE.
                </p>
                <p>
                  Clicking 'Apply' marks all exported transactions as exported,
                  these will not show in the next export.
                </p>
                <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                  <RequestReportForm
                    form={'generateSageReceiptsRefunds'}
                    initialValues={{
                      report_type: ReportType.SAGEReceiptsRefunds,
                      report_category: ReportCategory.Generic,
                      params: {
                        [ReportParam.RunTo]: moment().format('YYYY-MM-DD'),
                      },
                    }}
                    params={[ReportParam.RunFrom, ReportParam.RunTo]}
                    onSubmit={this.requestReport}
                  />
                </Allow>
                <ReportList
                  type={ReportType.SAGEReceiptsRefunds}
                  category={ReportCategory.Generic}
                />
              </PageSubSection>
            </Allow>
          </PageSection>
        </Container>
      </PageContent>
    );
  }

  public requestReport = (report: Report, dispatch: Dispatch) => {
    const params = report.params || {};
    const requireDatesFor = [
      ReportType.SAGEInvoicesCredits,
      ReportType.SAGEReceiptsRefunds,
      ReportType.CurrentPatientsAndIncomeReport,
    ];

    if (
      requireDatesFor.includes(report.report_type) &&
      (!params[ReportParam.RunFrom] || !params[ReportParam.RunTo])
    ) {
      const errors: FormErrors<Report, object> = {
        params: {
          [ReportParam.RunFrom]: !params[ReportParam.RunFrom]
            ? 'Required'
            : undefined,
          [ReportParam.RunTo]: !params[ReportParam.RunTo]
            ? 'Required'
            : undefined,
        },
      };

      throw new SubmissionError(errors);
    }

    if (params.bulk_report) {
      getBulkReportInProgress(report.report_type)(dispatch).then(
        (response: void | AxiosResponse<any>) => {
          if (response && response.status === 200) {
            this.openCancelModal(response.data);
          } else {
            this.openConfirmationModal(report, dispatch);
          }
        }
      );
    } else {
      return requestReport(report)(dispatch);
    }
  };

  public handleCSVReportSubmit = ({
    reportType,
    practiceFilter,
    start_date,
    end_date,
    month,
  }: CSVReportFormOptions) => {
    const requiresDate = CSV_REPORT_TYPE_REQUIRE_DATE.includes(
      reportType || ''
    );
    const requiresMonth = CSV_REPORT_TYPE_REQUIRE_MONTH.includes(
      reportType || ''
    );

    const dates = requiresDate ? { start_date, end_date } : undefined;
    const monthParam = requiresMonth ? { month } : undefined;

    const query = queryString.stringify({
      practice: practiceFilter,
      ...dates,
      ...monthParam,
    });

    const url = `${EXPORTS_ENDPOINT}/${reportType}/${query ? `?${query}` : ''}`;
    window.open(url);
  };

  public openCancelModal(data: {
    created_by: string;
    created_by_full_name: string;
    params: BulkReportParams;
    report_type: string;
  }) {
    const { loggedInUser } = this.props;
    const loggedInUserIsRunningReport =
      loggedInUser && loggedInUser.id === data.created_by;

    this.props.openModal(
      <ConfirmationModal
        heading={`You can't run another bulk report for ${data.report_type} today.`}
        message={`${
          loggedInUserIsRunningReport
            ? 'You are'
            : data.created_by_full_name + ' is'
        } already running this report for ${generateParamsSummary(
          data.params
        )}. Please try again tomorrow${
          !loggedInUserIsRunningReport
            ? ' or reach out to ' +
              data.created_by_full_name +
              ' for a download link of the bulk report, which they will receive in their inbox tomorrow.'
            : '.'
        }`}
        closeModal={this.props.closeModal}
      />
    );
  }

  public openConfirmationModal(report: Report, dispatch: Dispatch) {
    const requestBulkReportAction = () => requestBulkReport(report)(dispatch);
    this.props.openModal(
      <ConfirmationModal
        heading={`Are you sure you want to run a bulk report for ${report.report_type}?`}
        message={
          <>
            <p>
              A report will be generated for each Practice that was set to be
              included in the bulk report on the Practice details page.
            </p>
            <p>
              Please note that the reports generation will happen overnight -
              check your email in the morning for a link to download the reports
              in bulk.
            </p>
          </>
        }
        action={requestBulkReportAction}
        closeModal={this.props.closeModal}
        actionLabel="Run Bulk Report"
      />
    );
  }
}

export { ReportsRoutinesPage as TestableReportsRoutinesPage };

export const mapState = (state: StoreState) => ({
  loggedInUser: state.loggedInUser,
});

export default connect(mapState, { openModal, closeModal })(
  ReportsRoutinesPage
);

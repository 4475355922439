import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderVatCodePicker from '^/form-helpers/render-vat-code-picker';
import { Insurance } from '^/insurance/types';

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  errorFields?: ReadonlyArray<string>;
  /** Function to run on clicking cancel button */
  onCancel?(): void;
}

export type InspracFormProps = OwnProps &
  InjectedFormProps<Insurance, OwnProps>;

class InspracForm extends React.Component<InspracFormProps> {
  public render() {
    const { actions = [], errorFields = [], handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            label="VAT code (default)"
            name="monthly_fee.vat_code"
            component={RenderVatCodePicker}
            disabled
          />
          <Field
            label="Nominal code"
            name="monthly_fee.nominal_code"
            component={RenderInputField}
            disabled
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          showStatusErrors
        />
      </Form>
    );
  }
}

export { InspracForm as TestableInspracForm };

export default reduxForm<Insurance, OwnProps>({
  form: 'InspracForm',
})(InspracForm);

import moment from 'moment';

import { ContactResponse } from '^/contacts/types';
import { CachedItem, DetailedCache } from '^/types';

export const DEFAULT_EXPIRY_DURATION = moment.duration(5, 'minutes');

export function getItemFromCache<T extends object>(
  id?: string | null,
  cache?: DetailedCache<T> | null
): CachedItem<T> | undefined {
  if (!id) {
    return undefined;
  }

  return cache ? cache[id] : undefined;
}

export function cachedItemHasExpired<T extends object>(
  cachedItem?: CachedItem<T> | null,
  expiry: moment.Duration = DEFAULT_EXPIRY_DURATION
): boolean {
  if (!cachedItem || !cachedItem.retrieved) {
    return true;
  }

  return moment(cachedItem.retrieved).isBefore(moment().subtract(expiry));
}

export function contactMissingDetail(
  cachedContact?: CachedItem<ContactResponse>
) {
  return (
    !cachedContact ||
    cachedItemHasExpired(cachedContact) ||
    !cachedContact.calculated_balance ||
    !cachedContact.total_unallocated ||
    !cachedContact.subscription_status
  );
}

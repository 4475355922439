import { FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { getContactAddresses } from '^/address/actions';
import AppButton from '^/common/app-button';
import SidebarCard from '^/common/sidebar-card';
import { LEDGER_ENDPOINT } from '^/contacts/ledger/actions';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown, {
  DropdownOptions,
} from '^/form-helpers/render-dropdown';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

export interface OwnProps {
  contactId: string;
}

export interface FormValues {
  printFromDate?: Date;
  printToDate?: Date;
}

export type PrintLedgerRangeProps = InjectedFormProps<FormValues, OwnProps> &
  ConnectedProps<typeof connector> &
  OwnProps;

const selector = formValueSelector('ledgerPrintRangeForm');

class PrintLedgerRange extends React.PureComponent<PrintLedgerRangeProps> {
  public componentDidMount() {
    this.props.getContactAddresses(this.props.contactId);
  }

  public render() {
    const {
      submitting,
      selectedPrintFromDate,
      selectedPrintToDate,
      selectedAddressId,
      contactAddresses,
      contactId,
    } = this.props;

    const printFromDate = selectedPrintFromDate
      ? `start_date=${moment(selectedPrintFromDate).toISOString()}&`
      : '';
    const printToDate = selectedPrintToDate
      ? `end_date=${moment(selectedPrintToDate)
          .add(1, 'days')
          .startOf('day')
          .toISOString()}&`
      : '';
    const printUrl = `${LEDGER_ENDPOINT}/?contact_1=${contactId}&${printFromDate}${printToDate}address=${selectedAddressId}&download=true`;

    const addressOptions: DropdownOptions = [
      { value: '', label: 'Please choose...' },
      ...getAllRetrievedPages(contactAddresses[contactId]).map(option => ({
        label: `${option.label} (${option.street_address})`,
        value: option.id,
      })),
    ];

    return (
      <SidebarCard
        className="print-ledger-range"
        heading="Print Transaction List"
        transparent
      >
        <div className="sidebar-card-section">
          <FormGroup>
            <Field
              label="From"
              placeholder="eg. 01 01 2019"
              name="printFromDate"
              component={RenderDateField}
            />
            <Field
              label="To"
              placeholder="eg. 08 01 2019"
              name="printToDate"
              component={RenderDateField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              label="Address"
              name="address"
              component={RenderDropdown}
              options={addressOptions}
            />
          </FormGroup>
          <div className="form-buttons">
            <AppButton
              url={printUrl}
              type="submit"
              loading={submitting}
              disabled={!selectedAddressId}
            >
              Print transactions
            </AppButton>
          </div>
        </div>
      </SidebarCard>
    );
  }
}

// Disconnected version used for testing
export { PrintLedgerRange as TestablePrintLedgerRange };

export const mapState = (state: StoreState) => ({
  selectedPrintFromDate: selector(state, 'printFromDate'),
  selectedPrintToDate: selector(state, 'printToDate'),
  selectedAddressId: selector(state, 'address'),
  contactAddresses: state.contactAddresses,
});

const connector = connect(mapState, { getContactAddresses });

export default reduxForm<FormValues, OwnProps>({
  form: 'ledgerPrintRangeForm',
})(connector(PrintLedgerRange));

import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { CREATE_ACTIVITY, createActivity } from '^/activities/actions';
import ActivityForm from '^/activities/form';
import { Activity } from '^/activities/types';
import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';

export type ActivityCreatePageProps = RouteComponentProps;

class ActivityCreatePage extends React.PureComponent<ActivityCreatePageProps> {
  public render() {
    return (
      <PageContent>
        <BreadcrumbBar
          items={[['Activities', '/activities'], 'New activity']}
        />
        <HeaderBar title="New activity" transparent />
        <Container className="new-activity-container">
          <main>
            <PageSection heading="Activity Details">
              <ActivityForm
                actions={[CREATE_ACTIVITY]}
                onSubmit={this.handleSave}
                onCancel={this.props.history.goBack}
                hidePopupCheckbox
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  /**
   * Fires off the action that will save this activity.
   * @param {Activity} activity - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public handleSave = (activity: Activity, dispatch: Dispatch) => {
    return createActivity(activity)(dispatch).then(response => {
      if (response) {
        const createdActivity = response.data;
        return this.props.history.push(`/activities/${createdActivity.id}`);
      }
    });
  };
}

// Disconnected version used for testing
export { ActivityCreatePage as TestableActivityCreatePage };

export default ActivityCreatePage;

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { Template } from '^/admin/templates/types';
import { generateQueryString } from '^/common/helper-functions';
import { generateSorts } from '^/contacts/actions';
import { FilterList } from '^/filters/types';
import { SortList } from '^/sorts/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const TEMPLATES_ENDPOINT = '/api/templates';

export const GET_TEMPLATES = makeAsyncActionSet('GET_TEMPLATES');
export function getTemplates(
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  sorting?: SortList,
  filters?: FilterList
) {
  const queryString: string = generateQueryString({
    ...filters,
    ordering: generateSorts(sorting),
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  return (dispatch: Dispatch) =>
    request(
      GET_TEMPLATES,
      `${TEMPLATES_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize } }
    )(dispatch);
}

export const GET_TEMPLATE = makeAsyncActionSet('GET_TEMPLATE');
export function getTemplate(templateId: string) {
  return (dispatch: Dispatch) => {
    request(
      GET_TEMPLATE,
      `${TEMPLATES_ENDPOINT}/${templateId}/`,
      'GET',
      undefined,
      {
        metaData: { templateId },
      }
    )(dispatch);
  };
}

export const CREATE_TEMPLATE = makeAsyncActionSet('CREATE_TEMPLATE');
export function createTemplate(template: Partial<Template>) {
  return (dispatch: Dispatch) =>
    request(CREATE_TEMPLATE, `${TEMPLATES_ENDPOINT}/`, 'POST', template, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch)
      .then(response => {
        getTemplates()(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

export const SAVE_TEMPLATE = makeAsyncActionSet('SAVE_TEMPLATE');
export function saveTemplate(id: string, template: Partial<Template>) {
  return (dispatch: Dispatch) =>
    request(SAVE_TEMPLATE, `${TEMPLATES_ENDPOINT}/${id}/`, 'PUT', template, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch)
      .then(response => {
        getTemplates()(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';
import RenderDateField from '^/form-helpers/render-date-field';
import { StoreState } from '^/types';

export interface OwnProps {
  actions?: ReadonlyArray<AsyncActionSet>;
  generalErrorFields?: ReadonlyArray<string>;
}

export interface StateProps {
  form: string;
}

type FormProps = InjectedFormProps<{}, OwnProps>;

export type MembershipStatisticsFormFormProps = FormProps &
  StateProps &
  OwnProps;

class MembershipStatisticsForm extends React.Component<
  MembershipStatisticsFormFormProps
> {
  public render() {
    const { handleSubmit, generalErrorFields = [], actions } = this.props;

    return (
      <Form onSubmit={handleSubmit} className="dashboard-stats-form">
        <FormGroup>
          <FormGroup className="dashboard-stats-form-from">
            <label>Show for dates:</label>
            <Field
              component={RenderDateField}
              clearable={false}
              name="start_date"
              short
            />
          </FormGroup>

          <FormGroup className="dashboard-stats-form-to">
            <label>to</label>
            <Field
              component={RenderDateField}
              clearable={false}
              name="end_date"
              short
            />
          </FormGroup>
        </FormGroup>

        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...generalErrorFields]}
          showStatusErrors
        />
      </Form>
    );
  }
}

export { MembershipStatisticsForm as TestableMembershipStatisticsForm };

export const mapState = ({}, props: FormProps & OwnProps): StateProps => ({
  form: props.form,
});

const ConnectedMembershipStatusForm = connect<
  StateProps,
  {},
  FormProps & OwnProps,
  StoreState
>(mapState)(MembershipStatisticsForm);

export default reduxForm<{}, OwnProps>({})(ConnectedMembershipStatusForm);

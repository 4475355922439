import React from 'react';

import GlobalModalRenderer from '^/modals/global-modal-renderer';
import HeaderBar from '../common/header-bar';
import Footer from '../footer';
import NavigationBar from '../navigation';

const PageContainer = ({ children }: { children?: React.ReactNode }) => (
  <div className="page iplan">
    <HeaderBar />
    <NavigationBar />
    {children}
    <GlobalModalRenderer />
    <Footer />
  </div>
);

export default PageContainer;

import { anyPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { ErrorPage } from '^/common/error-page';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import { contactActions } from '^/contacts/actions';
import { getContact } from '^/contacts/actions';
import ContactHeaderBar from '^/contacts/header-bar';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import PracticeDetails from './details';

export type PracticesDetailPageProps = ConnectedProps<typeof connector>;

class PracticesDetailPage extends React.PureComponent<
  PracticesDetailPageProps
> {
  public componentDidMount(): void {
    const { contactId } = this.props;
    if (contactId) {
      this.props.getContact(contactId, { isPractice: true });
    }
  }

  public componentDidUpdate(prevProps: PracticesDetailPageProps) {
    const { contactId } = this.props;

    if (contactId !== prevProps.contactId && contactId) {
      this.props.getContact(contactId, { isPractice: true });
    }
  }

  public render() {
    const { loading, practice } = this.props;
    if (!practice) {
      if (!practice && loading.details) {
        return (
          <PageContent>
            <BreadcrumbBar
              items={[['Practice Details', '/practice-details'], 'Loading...']}
            />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Practice not found" />;
    }
    return (
      <PageContent>
        <BreadcrumbBar
          items={[
            ['Practice Details', '/practice-details'],
            getContactName(practice),
          ]}
        />
        <ContactHeaderBar contact={practice} loading={loading.details} />
        <Container>
          <PracticeDetails />
        </Container>
      </PageContent>
    );
  }
}

export { PracticesDetailPage as TestablePracticesDetailPage };

export const mapState = (state: StoreState) => ({
  practice:
    state.iplan &&
    getItemFromCache(state.iplan.selectedPracticeId, state.contactsCache),
  contactId: state.iplan?.selectedPracticeId,
  loading: {
    details: anyPending(state.responses, contactActions),
  },
});

const connector = connect(mapState, { getContact });

export default connector(PracticesDetailPage);

import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/src/types';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';
import { isSingularReactSelectOption } from '^/utils/commons';

export interface ReactSelectOption {
  value: string;
  label: string;
}

interface OwnProps {
  placeholder?: string;
  options: ReadonlyArray<ReactSelectOption>;
  selectedOption: ReactSelectOption | null;
  isLoading?: boolean;
  label?: string;
  isClearable?: boolean;
  className?: string;
  getOptions?(search?: string): void;
}

export type RenderReactSelectProps = OwnProps & WrappedFieldProps;

class RenderReactSelect extends React.PureComponent<RenderReactSelectProps> {
  public render() {
    const {
      input,
      placeholder,
      options,
      selectedOption,
      isLoading,
      label,
      isClearable,
      className,
      meta: { touched, error },
    } = this.props;

    return (
      <div className={classNames('form-field', className)}>
        <div className="form-field-label">
          {label && <label>{label}</label>}
        </div>
        <Select
          {...input}
          value={selectedOption}
          className="react-select"
          classNamePrefix="select"
          options={options}
          onChange={this.onChange}
          onBlur={this.onBlur}
          isLoading={isLoading}
          name="practice"
          placeholder={placeholder}
          onInputChange={this.getOptions}
          isClearable={isClearable}
        />
        <div className="form-field-errors">
          {touched && <ErrorRenderer error={[error]} />}
        </div>
      </div>
    );
  }

  public getOptions = (search: string) => {
    if (this.props.getOptions && search) {
      this.props.getOptions(search);
    }
  };

  public onChange = (option: ValueType<ReactSelectOption>) => {
    if (option && isSingularReactSelectOption(option)) {
      this.props.input.onChange(option.value);
    } else {
      this.props.input.onChange(null);
    }
  };

  public onBlur = () => this.props.input.onBlur(this.props.input.value);
}

// Disconnected version used for testing
export { RenderReactSelect as TestableRenderReactSelect };

export default RenderReactSelect;

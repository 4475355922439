import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { FilterList } from '^/filters/types';
import RenderInputField from '^/form-helpers/render-input-field';

interface OwnProps {
  actions?: ReadonlyArray<AsyncActionSet>;
  loading?: boolean;
  errorFields?: ReadonlyArray<string>;
  placeholder?: string;
  onClear?(): void;
}

export type PracticeUsersFilterFormProps = OwnProps &
  InjectedFormProps<FilterList, OwnProps>;

export class PracticeUsersFilterForm extends React.Component<
  PracticeUsersFilterFormProps
> {
  public render() {
    const {
      actions = [],
      errorFields = [],
      handleSubmit,
      submitting,
      loading,
      placeholder,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            name="search"
            component={RenderInputField}
            type="text"
            placeholder={placeholder}
          />
          <ErrorRenderer
            actions={actions}
            fields={['non_field_errors', ...errorFields]}
            showStatusErrors
          />
          <AppButton loading={submitting || loading} type="submit" primary>
            Search
          </AppButton>
        </FormGroup>
      </Form>
    );
  }
}

export { PracticeUsersFilterForm as TestablePracticeUsersFilterForm };

export default reduxForm<FilterList, OwnProps>({
  form: 'PracticeUsersFilterForm',
})(PracticeUsersFilterForm);

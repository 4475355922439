import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

export const CONTACTS_ENDPOINT = '/api/iplan/contacts';
export const IPLAN_DASHBOARD_ENDPOINT = '/api/iplan/dashboard/';
export const PLANS_ENDPOINT = '/api/iplan/plans/';

export const SELECT_ACTIVE_PRACTICE = 'SELECT_ACTIVE_PRACTICE';
export function selectActivePractice(practiceId: string) {
  return {
    type: SELECT_ACTIVE_PRACTICE,
    payload: {
      practiceId,
    },
  };
}

export const GET_DASHBOARD = makeAsyncActionSet('GET_DASHBOARD');
export function getDashboard(practiceId: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_DASHBOARD,
      `${IPLAN_DASHBOARD_ENDPOINT}?practice=${practiceId}`,
      'GET'
    )(dispatch);
}

export const GET_UNVERIFIED_CONTACT = makeAsyncActionSet(
  'GET_UNVERIFIED_CONTACT'
);
export function getUnverifiedPatientAsContact(id: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_UNVERIFIED_CONTACT,
      `${CONTACTS_ENDPOINT}/patients/unverified/${id}/`,
      'GET',
      undefined,
      { metaData: { id } }
    )(dispatch);
}

export const VERIFY_PATIENT = makeAsyncActionSet('VERIFY_PATIENT');
export function verifyPatient(id: string) {
  return (dispatch: Dispatch) =>
    request(
      VERIFY_PATIENT,
      `${CONTACTS_ENDPOINT}/patients/unverified/${id}/verify/`,
      'POST',
      undefined,
      { metaData: { id } }
    )(dispatch);
}

export const GET_PLAN = makeAsyncActionSet('GET_PLAN');
export function getPlan(planId: string) {
  return (dispatch: Dispatch) =>
    request(GET_PLAN, `${PLANS_ENDPOINT}/${planId}/`, 'GET', undefined, {
      metaData: { planId },
    })(dispatch);
}

import { ContactResponse } from '^/contacts/types';

export interface Relationship {
  readonly id?: string;
  contact: string;
  readonly contact_detail?: ContactResponse;
  related_contact: string;
  readonly related_contact_detail?: ContactResponse;
  relationship: RelationshipType;
  reverse_relationship?: string;
}

export type RelationshipResponse = Readonly<{
  id: string;
  contact: string;
  contact_detail: ContactResponse;
  related_contact: string;
  related_contact_detail: ContactResponse;
  relationship: RelationshipType;
  reverse_relationship: string;
}>;

export enum RelationshipType {
  CompanyContact = 'CompanyContact',
  CompanyProvider = 'CompanyProvider',
  PerformerPractice = 'PerformerPractice',
  TeamMemberCompany = 'TeamMemberCompany',
  LinkedCompany = 'LinkedCompany',
  LinkedPractice = 'LinkedPractice',
  PatientPerformer = 'PatientPerformer',
  PatientPractice = 'PatientPractice',
  PayeePayer = 'PayeePayer',
}

import { TableCell, TableRow } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { change, Field, FieldArrayMetaProps } from 'redux-form';

import AppButton from '^/common/app-button';
import { getContactName, parseNumberField } from '^/common/helper-functions';
import { required, requiredZeroPermitted } from '^/common/validation';
import { getContact } from '^/contacts/actions';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import { LedgerItemsContactOption } from '^/form-helpers/render-ledger-items';
import RenderVatCodePicker from '^/form-helpers/render-vat-code-picker';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { cachedItemHasExpired } from '^/utils/cache-helpers';
import {
  calculateGoods,
  calculateGross,
  calculateVat,
  itemShouldBeInTotal,
} from '../../helpers';
import { LedgerItem, LedgerItemType } from '../../types';

const LEDGER_ITEM_OPTIONS: ReadonlyArray<string> = [
  LedgerItemType.SubscriptionFee,
  LedgerItemType.AdminFee,
  LedgerItemType.JoiningFee,
  LedgerItemType.INSPRAC,
  LedgerItemType.Other,
  LedgerItemType.Promotion,
  LedgerItemType.FamilyDiscount,
];

// Props that come from the parent component.
interface OwnProps {
  item: string;
  index: number;
  removeLedgerItem: (index: number) => void;
  contacts?: ReadonlyArray<LedgerItemsContactOption>;
  ledgerItem: LedgerItem;
  feeToNominalMapping: { [feeType: string]: string | undefined };
  readonly?: boolean;
  meta: FieldArrayMetaProps;
  isBillingContainerSubscription?: boolean;
  ledgerItemMembershipOptions?: string[];
  ledgerItemPracticeOptions?: string[];
}

export type LedgerItemFormRowProps = OwnProps &
  ConnectedProps<typeof connector>;

class LedgerItemFormRow extends React.PureComponent<LedgerItemFormRowProps> {
  public componentDidMount() {
    if (this.props.contacts) {
      this.props.contacts.forEach(subContact => {
        if (
          subContact.contact &&
          this.props.contactsCache &&
          cachedItemHasExpired(this.props.contactsCache[subContact.contact])
        ) {
          this.props.getContact(subContact.contact);
        }
      });
    }

    this.calculateAmounts();
  }

  public componentDidUpdate(prevProps: LedgerItemFormRowProps) {
    const { ledgerItem } = this.props;

    if (
      prevProps.ledgerItem.amount !== ledgerItem.amount ||
      prevProps.ledgerItem.gross_amount !== ledgerItem.gross_amount ||
      prevProps.ledgerItem.vat_inclusive !== ledgerItem.vat_inclusive ||
      prevProps.ledgerItem.vat !== ledgerItem.vat
    ) {
      this.calculateAmounts();
    }
  }

  public calculateAmounts = () => {
    const {
      item,
      ledgerItem,
      meta: { form },
    } = this.props;

    const goods = calculateGoods(ledgerItem);
    const gross = calculateGross(ledgerItem);
    const vat = calculateVat(ledgerItem);

    if (!ledgerItem.vat_inclusive && gross !== ledgerItem.gross_amount) {
      // VAT exclusive so we need to calculate the gross amount, goods is editable
      this.props.change(form, `${item}.gross_amount`, gross);
    } else if (ledgerItem.vat_inclusive && goods !== ledgerItem.amount) {
      // VAT inclusive so we need to calculate the goods amount, gross is editable
      this.props.change(form, `${item}.amount`, goods);
    }

    if (vat !== ledgerItem.vat_amount) {
      this.props.change(form, `${item}.vat_amount`, vat);
    }
  };

  public render() {
    const {
      item,
      ledgerItem,
      readonly,
      feeToNominalMapping,
      isBillingContainerSubscription = false,
      ledgerItemMembershipOptions,
    } = this.props;

    const ledgerOptionsValues = isBillingContainerSubscription
      ? ledgerItemMembershipOptions!
      : LEDGER_ITEM_OPTIONS;

    const ledgerItemOptions = [
      { label: 'Please choose…', value: '' },
      ...ledgerOptionsValues.map(o => ({
        label: o,
        value: o,
      })),
    ];

    const ledgerItemDisplayValue = isBillingContainerSubscription
      ? ledgerItem.item_display
      : ledgerItem.item;

    if (
      typeof ledgerItemDisplayValue === 'string' &&
      !ledgerOptionsValues.includes(ledgerItemDisplayValue)
    ) {
      ledgerItemOptions.push({
        label: ledgerItemDisplayValue,
        value: ledgerItemDisplayValue,
      });
    }

    return (
      <TableRow className={classNames('ledger-item-form-row')}>
        <TableCell>
          <Field
            name={
              isBillingContainerSubscription
                ? `${item}.item_display`
                : `${item}.item`
            }
            component={RenderDropdown}
            options={ledgerItemOptions}
            validate={[required]}
            disabled={
              (isBillingContainerSubscription &&
                ledgerItem.item_display !== LedgerItemType.Other) ||
              readonly
            }
          />
        </TableCell>
        <TableCell>
          <Field
            name={
              isBillingContainerSubscription
                ? `${item}.practice`
                : `${item}.contact`
            }
            component={RenderDropdown}
            options={[
              { label: 'Please choose..', value: '' },
              ...this.contactDropdownOptions(),
            ]}
            validate={[required]}
            onChange={
              isBillingContainerSubscription
                ? // @ts-ignore
                  this.props.setContactIDFromPractice
                : undefined
            }
            disabled={
              (isBillingContainerSubscription && ledgerItem.practice !== '') ||
              readonly
            }
          />
        </TableCell>
        <TableCell>
          {feeToNominalMapping[ledgerItem.item!] ? (
            <p>{feeToNominalMapping[ledgerItem.item!]}</p>
          ) : (
            <Field
              name={`${item}.nominal_code`}
              type="text"
              component={RenderInputField}
              shortest
              disabled={readonly}
            />
          )}
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.vat`}
            type="text"
            component={RenderVatCodePicker}
            validate={[requiredZeroPermitted]}
            defaultNone
            disabled={readonly}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.vat_inclusive`}
            component={RenderCheckBox}
            disabled={readonly}
          />
        </TableCell>
        <TableCell
          className={classNames('align-right', {
            disabled: !itemShouldBeInTotal(ledgerItem),
          })}
        >
          {ledgerItem.vat_inclusive ? (
            <p>{formatFeeAmount(ledgerItem.amount)}</p>
          ) : (
            <Field
              name={`${item}.amount`}
              component={RenderCurrencyField}
              validate={[requiredZeroPermitted]}
              parse={parseNumberField}
              disabled={readonly}
            />
          )}
        </TableCell>
        <TableCell
          className={classNames('align-right', {
            disabled: !itemShouldBeInTotal(ledgerItem),
          })}
        >
          <p>{formatFeeAmount(ledgerItem.vat_amount)}</p>
        </TableCell>
        <TableCell className="align-right">
          {ledgerItem.vat_inclusive ? (
            <Field
              name={`${item}.gross_amount`}
              component={RenderCurrencyField}
              validate={[requiredZeroPermitted]}
              parse={parseNumberField}
              disabled={readonly}
            />
          ) : (
            <p>{formatFeeAmount(ledgerItem.gross_amount)}</p>
          )}
        </TableCell>
        <TableCell className="align-right">
          <AppButton
            small
            type="button"
            onClick={this.removeLedgerItem}
            transparent
            disabled={readonly}
          >
            <FontAwesomeIcon icon="trash" />
          </AppButton>
        </TableCell>
      </TableRow>
    );
  }

  public removeLedgerItem = () => {
    this.props.removeLedgerItem(this.props.index);
  };

  public contactDropdownOptions = () => {
    if (this.props.isBillingContainerSubscription) {
      const { ledgerItemPracticeOptions } = this.props;

      const uniquePracticeContacts = ledgerItemPracticeOptions!.map(item => ({
        label: item,
        value: item,
      }));

      return uniquePracticeContacts;
    }

    const { contacts: subscriptionContacts } = this.props;
    const uniqueContacts = subscriptionContacts
      ? Array.from(new Set(subscriptionContacts.map(subC => subC.contact)))
      : [];

    return uniqueContacts.map(contact => {
      const active = subscriptionContacts?.some(
        subC => subC.contact === contact && !subC.lapsed
      );
      return {
        label: `${this.props.contactsCache &&
          getContactName(this.props.contactsCache[contact])}${
          !active ? ' (lapsed)' : ''
        }`,
        value: contact,
      };
    });
  };
}

// Disconnected version used for testing
export { LedgerItemFormRow as TestableLedgerItemFormRow };

export const mapState = ({ contactsCache }: StoreState) => ({
  contactsCache,
});

const mapDispatch = {
  getContact,
  change,
};

const connector = connect(mapState, mapDispatch);

export default connector(LedgerItemFormRow);

import { AnyAction } from 'redux';

import { createCachedItem } from '^/__helpers__/pagination-test-helpers';
import { LOGOUT } from '^/login/actions';
import { DetailedCache, PaginatedArray } from '^/types';
import {
  paginatedReducer,
  updateItemInPaginatedArray,
} from '^/utils/pagination-helpers';
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  SAVE_PRODUCT,
} from './actions';
import { ProductResponse } from './types';

export function products(
  state: PaginatedArray<ProductResponse> | null = null,
  action: AnyAction
): PaginatedArray<ProductResponse> | null {
  switch (action.type) {
    case GET_PRODUCTS.SUCCESS:
      return paginatedReducer(state, action);
    case GET_PRODUCT.SUCCESS:
    case SAVE_PRODUCT.SUCCESS:
      return updateItemInPaginatedArray(state, action.payload.data);
    case CREATE_PRODUCT.SUCCESS:
      if (state === null) {
        return null;
      }

      return {
        ...state,
        pages: {
          ...state.pages,
          [state.page]: [action.payload.data, ...state.pages[state.page]],
        },
        count: state.count + 1,
      };
    case DELETE_PRODUCT.SUCCESS:
      if (state === null) {
        return null;
      }

      return {
        ...state,
        pages: {
          ...state.pages,
          [state.page]: state.pages[state.page].filter(
            product => product.id !== action.meta.productId
          ),
        },
        count: state.count - 1,
      };
    case LOGOUT.SUCCESS:
      return null;
    default:
      return state;
  }
}

export function productCache(
  state: DetailedCache<ProductResponse> = {},
  action: AnyAction
): DetailedCache<ProductResponse> {
  switch (action.type) {
    case GET_PRODUCT.SUCCESS:
    case CREATE_PRODUCT.SUCCESS:
    case SAVE_PRODUCT.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: createCachedItem(action.payload.data),
      };
    default:
      return state;
  }
}

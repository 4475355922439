import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import { generateSorts } from '^/contacts/actions';
import { FilterList } from '^/filters/types';
import { Membership } from '^/memberships/types';
import { SortList } from '^/sorts/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const MEMBERSHIPS_ENDPOINT = '/api/memberships';

export const GET_MEMBERSHIPS = makeAsyncActionSet('GET_MEMBERSHIPS');
export const getMemberships = (
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  sorting?: SortList,
  filters?: FilterList
) => {
  const queryString: string = generateQueryString({
    ...filters,
    ordering: generateSorts(sorting),
    page,
    page_size: pageSize,
  });

  return (dispatch: Dispatch) =>
    request(
      GET_MEMBERSHIPS,
      `${MEMBERSHIPS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize } }
    )(dispatch);
};

export const GET_MEMBERSHIP = makeAsyncActionSet('GET_MEMBERSHIP');
export function getMembership(id: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_MEMBERSHIP,
      `${MEMBERSHIPS_ENDPOINT}/${id}/`,
      'GET',
      undefined,
      { metaData: { id } }
    )(dispatch);
}

export const CREATE_MEMBERSHIP = makeAsyncActionSet('CREATE_MEMBERSHIP');
export function createMembership(membership: Membership) {
  return (dispatch: Dispatch) =>
    request(CREATE_MEMBERSHIP, `${MEMBERSHIPS_ENDPOINT}/`, 'POST', membership, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch)
      .then(response => {
        getMemberships()(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

export const SAVE_MEMBERSHIP = makeAsyncActionSet('SAVE_MEMBERSHIP');
export function saveMembership(id: string, membership: Membership) {
  return (dispatch: Dispatch) =>
    request(
      SAVE_MEMBERSHIP,
      `${MEMBERSHIPS_ENDPOINT}/${id}/`,
      'PUT',
      membership,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch)
      .then(response => {
        getMemberships()(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

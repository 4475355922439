import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import AppButton from '^/common/app-button';
import HeaderBar from '^/common/header-bar';
import PageContent from '^/page-container/page-content';
import ProductsTable from './table';

class ProductsListPage extends React.PureComponent<RouteComponentProps> {
  public render() {
    return (
      <PageContent>
        <HeaderBar
          title="Products"
          primaryActions={[
            <Link key="new-product" to={`${this.props.match.url}/create`}>
              <AppButton key="new" leftIcon="plus" primary>
                New Product
              </AppButton>
            </Link>,
          ]}
          transparent
        />
        <Container>
          <ProductsTable />
        </Container>
      </PageContent>
    );
  }
}

export { ProductsListPage as TestableProductsListPage };

export default ProductsListPage;

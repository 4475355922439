import { TableCell, TableRow } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { getUserName } from '^/admin/users/helpers';
import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ConfirmationModal from '^/common/confirmation-modal';
import { formatDateCalendar } from '^/common/helper-functions';
import { closeModal, openModal } from '^/modals/actions';
import { applyReport } from './actions';
import { ReportParam, ReportResponse, ReportStatus } from './types';

export const DEFAULT_TIMEOUT_DURATION = moment.duration(3, 'hour');

// Props that come from the parent component.
interface OwnProps {
  report: ReportResponse;
  reportButtonText?: string;
  noApplyButtons?: boolean;
  hideExceptions?: boolean;
  params?: ReadonlyArray<ReportParam>;
}

export type ReportRowProps = OwnProps & ConnectedProps<typeof connector>;

class ReportRow extends React.PureComponent<ReportRowProps> {
  public render() {
    const {
      report,
      noApplyButtons,
      reportButtonText,
      hideExceptions,
    } = this.props;

    return (
      <TableRow
        key={report.id}
        title={`${report.status}: ${report.status_detail ||
          'No additional detail'}`}
      >
        <TableCell>{getUserName(report.created_by_detail, 'System')}</TableCell>
        <TableCell>{formatDateCalendar(report.created)}</TableCell>
        <TableCell>{report.description}</TableCell>
        <TableCell>
          <AppButton
            link
            disabled={!report.report}
            url={`/api/reports/${report.id}/report/`}
          >
            {reportButtonText}
          </AppButton>
        </TableCell>
        {!hideExceptions && (
          <TableCell>
            <AppButton
              link
              disabled={!report.errors}
              url={`/api/reports/${report.id}/errors/`}
            >
              Exceptions
            </AppButton>
          </TableCell>
        )}
        <TableCell>
          {!noApplyButtons && !report.apply_file ? (
            <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
              <AppButton
                small
                disabled={
                  !report.report ||
                  report.status === ReportStatus.Applying ||
                  report.status === ReportStatus.AppliedWithErrors ||
                  report.status === ReportStatus.ApplyFailed
                }
                onClick={this.openConfirmationModal}
              >
                Apply
              </AppButton>
            </Allow>
          ) : (
            (report.status === ReportStatus.Applied ||
              report.status === ReportStatus.AppliedWithErrors) &&
            report.apply_file && (
              <AppButton
                link
                disabled={!report.apply_file}
                url={`/api/reports/${report.id}/apply-file/`}
              >
                Download
              </AppButton>
            )
          )}
        </TableCell>
        <TableCell className="align-right">
          {report.apply_errors ? (
            <a href={`/api/reports/${report.id}/apply-errors/`}>
              Applied with errors
            </a>
          ) : (
            this.getReportStatus()
          )}
        </TableCell>
        <TableCell>{this.renderStatusIcon()}</TableCell>
      </TableRow>
    );
  }

  public openConfirmationModal = () => {
    this.props.openModal(
      <ConfirmationModal
        message={`This will apply ${this.props.report.report_type}.`}
        actionLabel="Apply"
        action={this.handleApplyReport}
        closeModal={this.props.closeModal}
      />
    );
  };

  public handleApplyReport = () => this.props.applyReport(this.props.report);

  public renderStatusIcon = () => {
    const status = this.getReportStatus();

    switch (status) {
      case ReportStatus.Queued:
        return <FontAwesomeIcon className="primary" icon="circle-notch" spin />;

      case ReportStatus.ErrorInRequest:
        return (
          <FontAwesomeIcon className="spinner error" icon="times-circle" />
        );

      case ReportStatus.Preparing:
      case ReportStatus.Applying:
        return <FontAwesomeIcon className="secondary" icon="cog" spin />;

      case ReportStatus.Uploading:
        return <FontAwesomeIcon className="tertiary" icon="cog" spin />;

      case ReportStatus.Completed:
      case ReportStatus.Applied:
        return <FontAwesomeIcon className="success" icon="check-circle" />;

      case ReportStatus.CompletedWithErrors:
      case ReportStatus.AppliedWithErrors:
        return (
          <FontAwesomeIcon className="warning" icon="exclamation-circle" />
        );

      case ReportStatus.Failed:
      case ReportStatus.ApplyFailed:
        return <FontAwesomeIcon className="error" icon="times-circle" />;

      default:
        return <FontAwesomeIcon icon="times-circle" />;
    }
  };

  public getReportStatus = (
    timeout: moment.Duration = DEFAULT_TIMEOUT_DURATION
  ): ReportStatus => {
    const { report } = this.props;

    if (
      report.status === ReportStatus.Queued ||
      report.status === ReportStatus.Preparing ||
      report.status === ReportStatus.Applying
    ) {
      const modified = moment(report.modified, moment.ISO_8601);

      if (modified.isBefore(moment().subtract(timeout))) {
        return ReportStatus.Stalled;
      }
    }

    return report.status;
  };
}

// Disconnected version used for testing
export { ReportRow as TestableReportRow };

const connector = connect(undefined, {
  applyReport,
  openModal,
  closeModal,
});

export default connector(ReportRow);

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests/dist/js';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  GET_ACTIVITIES,
  getActivities,
  getContactActivities,
} from '^/activities/actions';
import { ActivityResponse } from '^/activities/types';
import { getUserName } from '^/admin/users/helpers';
import { formatDate, toggleSortFor } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import { FilterList } from '^/filters/types';
import TableHeaderSort from '^/sorts/table-header-sort';
import { SortList } from '^/sorts/types';
import { StoreState } from '^/types';
import { API_DATE_FORMAT, DEFAULT_PAGE_SIZE } from '^/utils/constants';
import { getCurrentPage } from '^/utils/pagination-helpers';

interface OwnProps {
  filters: FilterList;
  selectedActivityId?: string;
  associatedContactId?: string;
  assignedUserId?: string | null;
  selectActivity(activity: ActivityResponse): void;
}

// State props that come from redux.
interface StateProps {
  appliedSorts: SortList;
}

export type ActivitiesTableProps = SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector> &
  OwnProps;

class ActivitiesTable extends React.PureComponent<ActivitiesTableProps> {
  public componentDidMount() {
    this.refreshActivities(1, 10);
  }

  public componentDidUpdate(prevProps: ActivitiesTableProps) {
    const { activities } = this.props;

    if (
      JSON.stringify(this.props.appliedSorts) !==
        JSON.stringify(prevProps.appliedSorts) ||
      this.props.filters !== prevProps.filters ||
      this.props.assignedUserId !== prevProps.assignedUserId
    ) {
      this.refreshActivities();
    }

    if (
      activities &&
      prevProps.activities &&
      JSON.stringify(activities.filters) !==
        JSON.stringify(prevProps.activities.filters)
    ) {
      this.props.setProps({ appliedSorts: {} });
    }
  }

  public render() {
    const {
      activities,
      loading,
      appliedSorts,
      setProps,
      selectActivity,
      selectedActivityId,
      assignedUserId,
    } = this.props;

    return (
      <PaginatedTable
        changePage={this.changePage}
        paginatedData={activities}
        loading={loading}
        showPaginationBar
      >
        <TableHead>
          <TableRow>
            <TableHeaderSort
              sortOrder={appliedSorts.due_date}
              onClick={toggleSortFor.bind(
                this,
                'due_date',
                appliedSorts,
                setProps
              )}
            >
              Due Date
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.associated_contact__combined_name}
              onClick={toggleSortFor.bind(
                this,
                'associated_contact__combined_name',
                appliedSorts,
                setProps
              )}
            >
              Name
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.associated_contact__crm_id}
              onClick={toggleSortFor.bind(
                this,
                'associated_contact__crm_id',
                appliedSorts,
                setProps
              )}
            >
              Contact ID
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.title}
              onClick={toggleSortFor.bind(
                this,
                'title',
                appliedSorts,
                setProps
              )}
            >
              Title
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.created_by__first_name}
              onClick={toggleSortFor.bind(
                this,
                'created_by__first_name',
                appliedSorts,
                setProps
              )}
            >
              Created by
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.created}
              onClick={toggleSortFor.bind(
                this,
                'created',
                appliedSorts,
                setProps
              )}
            >
              Created
            </TableHeaderSort>
            {!assignedUserId && <TableHeader>Assigned to</TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(activities).map(activity => (
            <TableRow
              className={classnames('selectable', {
                ['selected']: selectedActivityId === activity.id,
              })}
              key={activity.id}
              onClick={selectActivity.bind(this, activity)}
            >
              {activity.completed_next_action ? (
                <TableCell className="complete">COMPLETED</TableCell>
              ) : (
                <TableCell
                  className={classnames({ overdue: this.isOverdue(activity) })}
                >
                  {formatDate(activity.due_date)}
                </TableCell>
              )}
              <TableCell>{activity.associated_contact_name || '-'}</TableCell>
              <TableCell>
                {activity.associated_contact_detail?.crm_id || '-'}
              </TableCell>
              <TableCell>{activity.title}</TableCell>
              <TableCell>{getUserName(activity.created_by_detail)}</TableCell>
              <TableCell>{formatDate(activity.created)}</TableCell>
              {!assignedUserId && (
                <TableCell>
                  {activity.assigned_to_detail &&
                    getUserName(activity.assigned_to_detail)}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public refreshActivities = (page?: number, pageSize?: number) => {
    const {
      associatedContactId,
      activities,
      appliedSorts,
      filters,
      assignedUserId,
    } = this.props;

    pageSize = pageSize
      ? pageSize
      : activities
      ? activities.pageSize
      : DEFAULT_PAGE_SIZE;

    associatedContactId
      ? this.props.getContactActivities(
          associatedContactId,
          page,
          pageSize,
          filters,
          appliedSorts
        )
      : this.props.getActivities(
          page,
          pageSize,
          { ...filters, letter: 'False' },
          appliedSorts,
          assignedUserId ? assignedUserId : undefined
        );
  };

  public changePage = (page: number, pageSize?: number) => {
    this.refreshActivities(page, pageSize);
  };

  private isOverdue(activity: ActivityResponse) {
    return (
      activity.due_date &&
      moment(activity.due_date, API_DATE_FORMAT).isBefore(moment())
    );
  }
}

export { ActivitiesTable as TestableActivitiesTable };

export const getInitialProps = () => ({
  appliedSorts: {},
});

export const mapState = (state: StoreState, props: OwnProps) => ({
  activities: props.associatedContactId
    ? state.contactActivities[props.associatedContactId]
    : state.activities,
  loading: anyPending(state.responses, [GET_ACTIVITIES]),
});

const connector = connect(mapState, {
  getActivities,
  getContactActivities,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(ActivitiesTable)
);

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { FilterList } from '^/filters/types';
import RenderInputField from '^/form-helpers/render-input-field';

interface OwnProps {
  actions?: ReadonlyArray<AsyncActionSet>;
  loading?: boolean;
  errorFields?: ReadonlyArray<string>;
  onClear?(): void;
}

export type LedgerFilterFormProps = OwnProps &
  InjectedFormProps<FilterList, OwnProps>;

class LedgerFilterForm extends React.Component<LedgerFilterFormProps> {
  public render() {
    const {
      actions = [],
      errorFields = [],
      handleSubmit,
      onClear,
      submitting,
      loading,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="form-header">
          <h4>Filters</h4>
          {onClear && (
            <AppButton link loading={loading || submitting} onClick={onClear}>
              CLEAR
            </AppButton>
          )}
        </div>
        <FormGroup>
          <Field
            label="Transaction ID"
            name="tx_id"
            component={RenderInputField}
            type="number"
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          showStatusErrors
        />
        <div className="form-buttons">
          <AppButton loading={submitting || loading} type="submit">
            Search
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { LedgerFilterForm as TestableLedgerFilterForm };

export default reduxForm<FilterList, OwnProps>({
  form: 'LedgerFilterForm',
  initialValues: {},
})(LedgerFilterForm);

import { AnyAction } from 'redux';

import { GET_TEMPLATES } from '^/admin/templates/actions';
import { TemplateResponse } from '^/admin/templates/types';
import { PaginatedArray } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

export function templates(
  state: PaginatedArray<TemplateResponse> | null = null,
  action: AnyAction
): PaginatedArray<TemplateResponse> | null {
  switch (action.type) {
    case GET_TEMPLATES.SUCCESS:
      return paginatedReducer(state, action);
    default:
      return state;
  }
}

import { SpacedGroup } from '@dabapps/roe';
import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { Loading } from '^/common/loading';

export interface PageSectionProps {
  heading?: ReactNode;
  subHeading?: ReactNode;
  subHeadingClass?: string;
  loading?: boolean;
  horizontal?: boolean;
  thin?: boolean;
  flex?: boolean;
  className?: string;
}

class PageSection extends React.PureComponent<PageSectionProps> {
  public render() {
    const {
      heading,
      subHeading,
      subHeadingClass,
      loading,
      children,
      horizontal,
      thin,
      className,
      flex = true,
    } = this.props;

    return (
      <div
        className={classnames('page-section', className, {
          horizontal,
          thin,
          flex,
        })}
      >
        {(heading || subHeading) && (
          <div className="page-section-header">
            <h4>{heading}</h4>
            {subHeading && <h4 className={subHeadingClass}>{subHeading}</h4>}
          </div>
        )}
        {loading && <Loading />}
        <div
          className={classnames({
            'page-section-content': true,
            'display-none': loading,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
}

export const PageSectionFooter: React.SFC = ({ children }) => (
  <div className="page-section-footer">
    <SpacedGroup>{children}</SpacedGroup>
  </div>
);

export default PageSection;

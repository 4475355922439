import classnames from 'classnames';
import React from 'react';

import AttachmentListItem from '^/attachments/list-item';

// Props that come from the parent component.
interface OwnProps {
  attachments?: ReadonlyArray<string>;
  onRemove?(attachmentId: string): void;
}

export type AttachmentListProps = OwnProps;

class AttachmentList extends React.PureComponent<AttachmentListProps> {
  public render() {
    const { attachments } = this.props;

    if (!attachments) {
      return null;
    }

    return (
      <div
        className={classnames('attachment-list', {
          vertical: this.props.onRemove,
        })}
      >
        {attachments.map((attachment: string) => (
          <AttachmentListItem
            key={attachment}
            attachmentId={attachment}
            onRemove={this.props.onRemove}
          />
        ))}
      </div>
    );
  }
}

// Disconnected version used for testing
export { AttachmentList as TestableAttachmentList };

export default AttachmentList;

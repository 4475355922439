import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import React from 'react';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import SidebarCard, { SidebarCardSection } from '^/common/sidebar-card';
import { SAVE_PLAN } from '../actions';
import { PlanResponse } from '../types';

// Props that come from the parent component.
interface OwnProps {
  plan: PlanResponse;
  archiveable: boolean | undefined;
  onClickArchive: () => void;
  onClickUnarchive: () => void;
  onClickDelete: () => void;
}

// State props that come from redux.
interface StateProps {
  confirmDelete: boolean;
}

export type ArchivePlanCardProps = OwnProps & SetPropsInterface<StateProps>;

class ArchivePlanCard extends React.PureComponent<ArchivePlanCardProps> {
  public render() {
    const {
      archiveable,
      plan,
      confirmDelete,
      onClickUnarchive,
      onClickDelete,
    } = this.props;

    return (
      <>
        <SidebarCard
          heading={plan.status === 'LIVE' ? 'Archive plan' : 'Plan archived'}
          transparent
          highlight={plan.status !== 'LIVE'}
        >
          <Allow roles={[UserRole.SalesLevel]}>
            <p>You do not have permission to edit this plan.</p>
          </Allow>
          {plan.status === 'LIVE' ? (
            <SidebarCardSection>
              <p>
                A plan can only be <strong>archived</strong> when there are{' '}
                <strong>no active subscriptions</strong>.
              </p>
              <ErrorRenderer
                actions={[SAVE_PLAN]}
                fields={['non_field_errors']}
              />
              <Allow
                roles={[
                  UserRole.AdminLevel,
                  UserRole.FinanceLevel,
                  UserRole.OfficeLevel,
                ]}
              >
                <div className="buttons">
                  <AppButton
                    disabled={archiveable === false}
                    onClick={this.props.onClickArchive}
                    destructive
                  >
                    Archive Plan
                  </AppButton>
                </div>
              </Allow>
            </SidebarCardSection>
          ) : (
            <Allow
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
            >
              <SidebarCardSection>
                <p>
                  Resume a plan for a practice, making it available for patients
                  to subscribe to.
                </p>
                <div className="buttons">
                  <AppButton
                    disabled={archiveable === false}
                    onClick={onClickUnarchive}
                    primary
                  >
                    Unarchive Plan
                  </AppButton>
                </div>
              </SidebarCardSection>
            </Allow>
          )}
        </SidebarCard>
        {plan.status !== 'LIVE' && (
          <Allow
            roles={[
              UserRole.AdminLevel,
              UserRole.FinanceLevel,
              UserRole.OfficeLevel,
            ]}
          >
            <SidebarCard transparent heading="Delete Plan">
              {!confirmDelete ? (
                <SidebarCardSection>
                  <p>
                    Deleting a plan will remove it from the system{' '}
                    <strong>permanently</strong>. It will no longer be usable
                    and <strong>cannot be restored</strong>.
                  </p>
                  <div className="buttons">
                    <AppButton destructive onClick={this.toggleConfirmDelete}>
                      Delete Plan
                    </AppButton>
                  </div>
                </SidebarCardSection>
              ) : (
                <SidebarCardSection>
                  <p>Are you sure you want to delete this plan?</p>
                  <div className="buttons">
                    <AppButton onClick={this.toggleConfirmDelete}>
                      Cancel
                    </AppButton>
                    <AppButton destructive onClick={onClickDelete}>
                      Delete
                    </AppButton>
                  </div>
                </SidebarCardSection>
              )}
            </SidebarCard>
          </Allow>
        )}
      </>
    );
  }

  public toggleConfirmDelete = () =>
    this.props.setProps({ confirmDelete: !this.props.confirmDelete });
}

// Disconnected version used for testing
export { ArchivePlanCard as TestableArchivePlanCard };

export const getInitialProps = () => ({
  confirmDelete: false,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  ArchivePlanCard
);

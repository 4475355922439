import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderVatCodePicker from '^/form-helpers/render-vat-code-picker';
import { Template } from '../templates/types';

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  errorFields?: ReadonlyArray<string>;
  /** Function to run on clicking cancel button */
  onCancel?(): void;
}

export type InspatFormProps = OwnProps & InjectedFormProps<Template, OwnProps>;

class InspatForm extends React.Component<InspatFormProps> {
  public render() {
    const {
      actions = [],
      errorFields = [],
      handleSubmit,
      onCancel,
      pristine,
      submitting,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            label="Amount"
            name="fee.amount"
            component={RenderCurrencyField}
            stretch
          />
          <Field
            label="VAT code"
            name="fee.vat_code"
            component={RenderVatCodePicker}
          />
          <Field
            label="Nominal code"
            name="fee.nominal_code"
            component={RenderInputField}
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          showStatusErrors
        />
        <div className="form-buttons">
          {onCancel && (
            <AppButton disabled={submitting} onClick={onCancel} type="button">
              Cancel
            </AppButton>
          )}
          <AppButton
            disabled={pristine}
            loading={submitting}
            primary
            type="submit"
          >
            Save
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { InspatForm as TestableInspatForm };

export default reduxForm<Template, OwnProps>({
  form: 'InspatForm',
  initialValues: {},
})(InspatForm);

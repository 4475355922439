import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import RenderSingleUploadField from '^/form-helpers/render-single-file-upload';
import { StoreState } from '^/types';
import { IMPORT_PATIENTS } from '../../actions';

const FORM_NAME = 'ImportPatientsForm';

export interface ImportPatientsValues {
  file: string;
}

export type ImportPatientsFormProps = InjectedFormProps<ImportPatientsValues> &
  ConnectedProps<typeof connector>;

class ImportPatientsForm extends React.PureComponent<ImportPatientsFormProps> {
  public render() {
    const { handleSubmit, submitting, loading, file } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="file"
          label="Patients CSV"
          component={RenderSingleUploadField}
        />
        <ErrorRenderer
          actions={[IMPORT_PATIENTS]}
          fields={['file', 'non_field_errors']}
          showStatusErrors
        />

        <div className="margin-top-large">
          <AppButton
            type="submit"
            loading={submitting || loading}
            className="primary"
            disabled={!file}
          >
            Import Patients
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { ImportPatientsForm as TestableImportPatientsForm };

const selector = formValueSelector(FORM_NAME);

export const mapState = (state: StoreState) => ({
  file: selector(state, 'file'),
  loading: isPending(state.responses, IMPORT_PATIENTS),
});

const connector = connect(mapState, {});

export default reduxForm<ImportPatientsValues>({
  form: FORM_NAME,
})(connector(ImportPatientsForm));

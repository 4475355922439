import moment from 'moment';
import { AnyAction } from 'redux';

import {
  CachedItem,
  isPaginatedActionMeta,
  isPaginatedResponse,
  PaginatedArray,
} from '^/types';

export const DEFAULT_EXPIRY_DURATION = moment.duration(5, 'minutes');

export function hasData<T>(
  paginatedData: PaginatedArray<T> | null | undefined
): boolean {
  return (
    paginatedData !== null &&
    paginatedData !== undefined &&
    paginatedData.count > 0
  );
}

export function hasMultiplePages<T>(
  paginatedData: PaginatedArray<T> | null | undefined
): boolean {
  return (
    paginatedData !== null &&
    paginatedData !== undefined &&
    paginatedData.count > paginatedData.pageSize
  );
}

export function getCurrentPage<T>(
  paginatedData: PaginatedArray<T> | null | undefined
): ReadonlyArray<T> {
  return paginatedData !== null && paginatedData !== undefined
    ? paginatedData.pages[paginatedData.page]
    : [];
}

export function getAllRetrievedPages<T>(
  paginatedData: PaginatedArray<T> | null | undefined
): ReadonlyArray<T> {
  if (!paginatedData) {
    return [];
  }

  return Object.values(paginatedData.pages).reduce(
    (previousValue, currentValue) => previousValue.concat(currentValue),
    []
  );
}

export function allPagesReceived<T>(
  paginatedData: PaginatedArray<T> | null | undefined
): boolean {
  if (!paginatedData) {
    return false;
  }

  return getAllRetrievedPages(paginatedData).length >= paginatedData.count;
}

export function validPages<T>(
  state: PaginatedArray<T> | null | undefined,
  action: AnyAction
): { [page: string]: ReadonlyArray<T> } {
  return state !== null &&
    state !== undefined &&
    action.payload.data.count === state.count &&
    action.payload.data.results.length <= state.pageSize
    ? state.pages
    : {};
}

export function calculateTotalPages(totalCount: number, pageSize: number) {
  if (pageSize < 1) {
    return 0;
  }

  return Math.ceil(totalCount / pageSize);
}

export const paginatedArrayHasExpired = <T>(
  paginatedArray?: PaginatedArray<T> | null,
  requiredPageSize?: number,
  expiry: moment.Duration = DEFAULT_EXPIRY_DURATION
): boolean => {
  if (!paginatedArray || !paginatedArray.retrieved || paginatedArray.page > 1) {
    return true;
  }

  if (requiredPageSize && paginatedArray.pageSize < requiredPageSize) {
    return true;
  }

  return moment(paginatedArray.retrieved).isBefore(moment().subtract(expiry));
};

export const paginatedReducer = <T>(
  state: PaginatedArray<T> | null = null,
  action: AnyAction
): PaginatedArray<T> | null => {
  if (!isPaginatedResponse(action.payload)) {
    throw new Error(
      'Invalid API payload. Expected a paginated response with count and results.'
    );
  }

  if (!isPaginatedActionMeta(action.meta)) {
    throw new Error(
      'Invalid action meta data. Expected page and pageSize to be present.'
    );
  }

  return {
    count: action.payload.data.count,
    page: action.meta.page,
    filters: action.meta.filters,
    pageSize: action.meta.pageSize,
    retrieved: moment(),
    pages: {
      ...validPages(state, action),
      [action.meta.page]: action.payload.data.results,
    },
  };
};

export function createCachedItem<T extends object>(
  item: T,
  date: moment.Moment = moment()
): CachedItem<T> {
  return Object.assign({ retrieved: date }, item);
}

export function updateItemInPaginatedArray<T>(
  paginatedArray: PaginatedArray<{ id: string } & T> | null,
  item: { id: string } & T
): PaginatedArray<T> | null {
  if (
    !item.id ||
    paginatedArray === null ||
    paginatedArray.pages[paginatedArray.page].length < 1
  ) {
    return paginatedArray;
  }

  const currentPage = paginatedArray.pages[paginatedArray.page];
  const itemIndex = currentPage.findIndex(val => val && val.id === item.id);

  if (itemIndex < 0) {
    return paginatedArray;
  }

  return {
    ...paginatedArray,
    pages: {
      ...paginatedArray.pages,
      [paginatedArray.page]: [
        ...currentPage.slice(0, itemIndex),
        item,
        ...currentPage.slice(itemIndex + 1),
      ],
    },
  };
}

import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import RelatedActivities from '^/activities/related-activities';
import { addressActions } from '^/address/actions';
import Addresses from '^/address/addresses-component';
import Collections from '^/collections/collections';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import Sidebar from '^/common/sidebar';
import { GET_CONTACT, SAVE_CONTACT } from '^/contacts/actions';
import PaymentDetailsSection from '^/contacts/payment-details-section';
import PersonsDetailsCard from '^/contacts/persons/details-card';
import { ContactStatus, ContactType } from '^/contacts/types';
import SubscriptionsMembershipAddButton from '^/memberships/subscriptions/add-button';
import MembershipSubscriptionsTable from '^/memberships/subscriptions/table';
import { paymentDetailActions } from '^/payment-details/actions';
import { subscriptionActions } from '^/plans/subscriptions/actions';
import { relationshipActions } from '^/relationships/actions';
import RelationshipsListCard from '^/relationships/list-card';
import { RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

export type IndividualsDetailsTabProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

/**
 * Displays all the individuals details using editable cards.
 */
class IndividualsDetailsTab extends React.PureComponent<
  IndividualsDetailsTabProps
> {
  public render() {
    const {
      individual,
      match: {
        params: { contactId },
      },
      loading,
    } = this.props;

    const disabled = individual?.status === ContactStatus.Archived;
    return (
      <Row>
        <Column xs={12} md={7} lg={8}>
          <PageSection>
            <PageSubSection heading="Contact details" loading={loading.details}>
              <PersonsDetailsCard personId={contactId} disabled={disabled} />
            </PageSubSection>
            <PageSubSection heading="Addresses" loading={loading.addresses}>
              <Addresses contactId={contactId} disabled={disabled} />
            </PageSubSection>
            <RelationshipsListCard
              heading="Works at"
              contactId={contactId}
              filters={{
                type: [ContactType.Practice, ContactType.Company].join(','),
              }}
              showBothSides
              label="Practice/Employer"
              types={[
                RelationshipType.PerformerPractice,
                RelationshipType.TeamMemberCompany,
              ]}
              createInReverse
              disabled={disabled}
            />
            <PaymentDetailsSection contact={individual} disabled={disabled} />
          </PageSection>
          <PageSection heading="Memberships" loading={loading.memberships}>
            <MembershipSubscriptionsTable contactId={contactId} />
            <div className="page-section-buttons">
              <SubscriptionsMembershipAddButton
                contactId={contactId}
                disabled={disabled}
                isCompany
              />
            </div>
          </PageSection>
          <Collections contactId={contactId} disabled={disabled} />
        </Column>
        <Sidebar>
          <RelatedActivities contactId={contactId} disabled={disabled} />
        </Sidebar>
      </Row>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { IndividualsDetailsTab as TestableIndividualsDetailsTab };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  individual: getItemFromCache(
    props.match.params.contactId,
    state.contactsCache
  ),
  loading: {
    addresses: anyPending(state.responses, addressActions),
    details: anyPending(state.responses, [GET_CONTACT, SAVE_CONTACT]),
    memberships: anyPending(state.responses, subscriptionActions),
    paymentDetails: anyPending(state.responses, paymentDetailActions),
    relationships: anyPending(state.responses, relationshipActions),
  },
});

const connector = connect(mapState, {});

export default connector(IndividualsDetailsTab);

import { AnyAction } from 'redux';

import {
  CREATE_PLAN_SUBSCRIPTION,
  GET_BILLING_CONTAINER_SUBSCRIPTION_CONTACTS,
  GET_PLAN_SUBSCRIPTION,
  GET_PLAN_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_CONTACTS,
  GET_SUBSCRIPTIONS_BY_PLAN,
  SAVE_PLAN_SUBSCRIPTION,
} from '^/plans/subscriptions/actions';
import { PlanSubscriptionResponse } from '^/plans/subscriptions/types';
import { ByContact, DetailedCache, PaginatedArray, StoreState } from '^/types';
import { createCachedItem, paginatedReducer } from '^/utils/pagination-helpers';

export function planSubscriptions(
  state: ByContact<PaginatedArray<PlanSubscriptionResponse>> = {},
  action: AnyAction
): ByContact<PaginatedArray<PlanSubscriptionResponse>> {
  switch (action.type) {
    case GET_PLAN_SUBSCRIPTIONS.SUCCESS:
      const key = action.meta.contact ? action.meta.contact : 'all';

      return {
        ...state,
        [key]: paginatedReducer(state[key], action),
      };
    default:
      return state;
  }
}

export function planSubscriptionCache(
  state: DetailedCache<PlanSubscriptionResponse> = {},
  action: AnyAction
): DetailedCache<PlanSubscriptionResponse> {
  switch (action.type) {
    case GET_PLAN_SUBSCRIPTION.SUCCESS:
    case CREATE_PLAN_SUBSCRIPTION.SUCCESS:
    case SAVE_PLAN_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: createCachedItem(action.payload.data),
      };
    default:
      return state;
  }
}

export function archiveablePlans(
  state: { [planId: string]: boolean } = {},
  action: AnyAction
): { [planId: string]: boolean } {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_BY_PLAN.SUCCESS:
      return {
        ...state,
        [action.meta.planId]: action.payload.data.count === 0,
      };
    default:
      return state;
  }
}

export function subscriptionContacts(
  state: StoreState['subscriptionContacts'] = {},
  action: AnyAction
): StoreState['subscriptionContacts'] {
  switch (action.type) {
    case GET_SUBSCRIPTION_CONTACTS.SUCCESS:
      return {
        ...state,
        [action.meta.subscription]: action.payload.data.results,
      };
    default:
      return state;
  }
}

export function paginatedSubscriptionContacts(
  state: StoreState['paginatedSubscriptionContacts'] = {},
  action: AnyAction
): StoreState['paginatedSubscriptionContacts'] {
  switch (action.type) {
    case GET_SUBSCRIPTION_CONTACTS.SUCCESS:
      return {
        ...state,
        [action.meta.subscription]: paginatedReducer(
          state[action.meta.subscription],
          action
        ),
      };
    case GET_BILLING_CONTAINER_SUBSCRIPTION_CONTACTS.SUCCESS:
      return {
        ...state,
        [action.meta.billingContainerSub]: paginatedReducer(
          state[action.meta.billingContainerSub],
          action
        ),
      };
    default:
      return state;
  }
}

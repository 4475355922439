import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { parseNumberField } from '^/common/helper-functions';
import { required } from '^/common/validation';
import ContactSelect from '^/form-helpers/contact-select';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import { BACSSubmissionEntry } from '../types';

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  errorFields?: ReadonlyArray<string>;
}

const TRANSACTION_CODE_DROPDOWN_OPTIONS = [
  { value: '01', label: '01 First collection' },
  { value: '17', label: '17 Regular collection' },
  { value: '18', label: '18 Re-presented collection' },
  { value: '19', label: '19 Final collection' },
];

export type AddContactFormProps = OwnProps &
  InjectedFormProps<BACSSubmissionEntry, OwnProps>;

class AddContactForm extends React.Component<AddContactFormProps> {
  public render() {
    const {
      actions = [],
      errorFields = [],
      handleSubmit,
      submitting,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="form-header">
          <h4>Add a contact</h4>
        </div>
        <FormGroup>
          <Field
            label="Contact"
            name="contact"
            component={ContactSelect}
            validate={required}
          />
          <Field
            label="Amount"
            name="amount"
            component={RenderCurrencyField}
            type="number"
            validate={required}
            parse={parseNumberField}
            stretch
          />
          <Field
            label="Transaction code"
            name="code"
            component={RenderDropdown}
            options={TRANSACTION_CODE_DROPDOWN_OPTIONS}
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          showStatusErrors
        />
        <div className="form-buttons">
          <AppButton
            loading={submitting}
            primary
            type="submit"
            rightIcon={faPlus}
          >
            Add to batch
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { AddContactForm as TestableAddContactForm };

export default reduxForm<BACSSubmissionEntry, OwnProps>({
  form: 'AddContactForm',
  initialValues: { code: '17' },
})(AddContactForm);

import { AnyAction } from 'redux';

import { Uuid } from '^/common/types';
import {
  PUBLIC_GET_LINKED_PRACTICES,
  PUBLIC_GET_PRACTICE_DETAILS,
  PUBLIC_GET_PRESET_CLINICIAN_DETAILS,
  PUBLIC_GET_PRESET_PLAN_DETAILS,
  PUBLIC_SELECT_ACTIVE_PRACTICE,
  PUBLIC_SET_PRESET_SIGNUP,
} from './actions';
import {
  PUBLIC_GET_PRACTICE_CLINICIANS,
  PUBLIC_GET_PRACTICE_PLANS,
  PUSH_RECAPTCHA_TOKEN_TO_STORE,
} from './patient-signup/actions';
import {
  PublicContact,
  PublicContactList,
  PublicPlan,
  PublicPlanList,
  PublicPracticeDetail,
  PublicPracticeList,
} from './types';

export interface PublicPatientReducer {
  linkedPractices?: PublicPracticeList;
  presetClinician?: PublicContact;
  presetPlan?: PublicPlan;
  presetSignUp?: boolean;
  practiceDetails?: PublicPracticeDetail;
  recaptchaToken?: string | null;
  selectedPublicId?: Uuid;
  signupForm?: {
    plans?: PublicPlanList;
    clinicians?: PublicContactList;
  };
}

export function publicPatient(
  state: PublicPatientReducer | null = null,
  action: AnyAction
): PublicPatientReducer | null {
  switch (action.type) {
    case PUBLIC_GET_PRESET_CLINICIAN_DETAILS.SUCCESS:
      return {
        ...state,
        presetClinician: action.payload.data,
      };
    case PUBLIC_GET_PRESET_PLAN_DETAILS.SUCCESS:
      return {
        ...state,
        presetPlan: action.payload.data,
      };
    case PUBLIC_SELECT_ACTIVE_PRACTICE:
      return {
        ...state,
        selectedPublicId: action.payload.public_id,
      };
    case PUBLIC_SET_PRESET_SIGNUP:
      return {
        ...state,
        presetSignUp: action.payload.presetSignUp,
      };
    case PUBLIC_GET_PRACTICE_DETAILS.SUCCESS:
      return {
        ...state,
        practiceDetails: action.payload.data,
      };
    case PUBLIC_GET_LINKED_PRACTICES.SUCCESS:
      return {
        ...state,
        linkedPractices: action.payload.data,
      };
    case PUBLIC_GET_PRACTICE_PLANS.SUCCESS:
      return {
        ...state,
        signupForm: {
          ...state?.signupForm,
          plans: action.payload.data,
        },
      };
    case PUBLIC_GET_PRACTICE_CLINICIANS.SUCCESS:
      return {
        ...state,
        signupForm: {
          ...state?.signupForm,
          clinicians: action.payload.data,
        },
      };
    case PUSH_RECAPTCHA_TOKEN_TO_STORE:
      return {
        ...state,
        recaptchaToken: action.payload,
      };
    default:
      return state;
  }
}

import { AsyncActionSet } from '@dabapps/redux-requests';
import React from 'react';
import {
  FieldArray,
  Form,
  FormErrors,
  FormWarnings,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import RenderAllocations, {
  RenderAllocationsOwnProps,
} from '^/form-helpers/render-allocations';
import { formatFeeAmount } from '^/plans/helpers';
import {
  calculateLedgerUnbalance,
  round,
  sumAllocationAmounts,
} from '../../helpers';
import { LedgerAllocation, LedgerResponse } from '../../types';

export interface AllocationFormValues {
  allocations: ReadonlyArray<LedgerAllocation>;
  entry: LedgerResponse;
}

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  errorFields?: ReadonlyArray<string>;
  hideButtons?: boolean;
  /** Function to run on clicking cancel button */
  onCancel?(): void;
}

export type CreateAllocationsFormProps = OwnProps &
  InjectedFormProps<AllocationFormValues, OwnProps>;

class CreateAllocationsForm extends React.Component<
  CreateAllocationsFormProps
> {
  public render() {
    const {
      actions = [],
      errorFields = [],
      handleSubmit,
      initialValues,
      hideButtons,
      error,
      warning,
      onCancel,
      submitting,
    } = this.props;

    if (!initialValues.entry) {
      return (
        <p className="error">
          You must select an entry to allocate first. Please go back and select
          a ledger entry.
        </p>
      );
    }

    const availableToAllocate = calculateLedgerUnbalance(initialValues.entry);

    return (
      <Form onSubmit={handleSubmit}>
        <FieldArray<RenderAllocationsOwnProps>
          name="allocations"
          component={RenderAllocations}
          limit={round(availableToAllocate)}
        />
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          error={error}
          warning={warning}
          showStatusErrors
        />
        {!hideButtons && (
          <div className="form-buttons">
            {onCancel && (
              <AppButton disabled={submitting} onClick={onCancel} type="button">
                Cancel
              </AppButton>
            )}
            <AppButton loading={submitting} primary type="submit">
              Save
            </AppButton>
          </div>
        )}
      </Form>
    );
  }
}

export { CreateAllocationsForm as TestableCreateAllocationsForm };

export function validate(
  values: Partial<AllocationFormValues>
): FormErrors<AllocationFormValues> {
  if (!values.entry) {
    return {};
  }

  if (!values.allocations) {
    return { _error: 'You must allocate some values.' };
  }

  const errors: FormErrors<AllocationFormValues> = {};

  const allocated = sumAllocationAmounts(values.allocations);
  const availableToAllocate = calculateLedgerUnbalance(values.entry);

  if (round(allocated) > round(availableToAllocate)) {
    errors._error = `Over allocated - reduce allocations by ${formatFeeAmount(
      allocated - availableToAllocate
    )}.`;
  }

  return errors;
}

export function warn(
  values: Partial<AllocationFormValues>
): FormWarnings<AllocationFormValues> & { _warning?: string } {
  if (!values.entry) {
    return {};
  }

  if (!values.allocations) {
    return {};
  }

  const warnings: FormWarnings<AllocationFormValues> & {
    _warning?: string;
  } = {};

  const allocated = sumAllocationAmounts(values.allocations);
  const availableToAllocate = calculateLedgerUnbalance(values.entry);

  if (round(allocated) < round(availableToAllocate)) {
    warnings._warning = `Under allocated - you can still allocate up to ${formatFeeAmount(
      availableToAllocate - allocated
    )}`;
  }

  return warnings;
}

export default reduxForm<AllocationFormValues, OwnProps>({
  form: 'CreateAllocationsForm',
  initialValues: {},
  validate,
  warn,
})(CreateAllocationsForm);

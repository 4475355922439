import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';
import * as _ from 'underscore';

import { getPrimaryPostcode } from '^/address/helpers';
import { getContactName } from '^/common/helper-functions';
import {
  GET_CONTACT,
  GET_CONTACTS,
  getContact,
  getContacts,
} from '^/contacts/actions';
import { ContactResponse } from '^/contacts/types';
import RenderReactSelect from '^/form-helpers/render-react-select';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import { DEBOUNCE_MS } from '^/utils/constants';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

interface OwnProps {
  label?: string;
}

export type ContactSelectProps = OwnProps &
  ConnectedProps<typeof connector> &
  WrappedFieldProps;

class ContactSelect extends React.PureComponent<ContactSelectProps> {
  constructor(props: ContactSelectProps) {
    super(props);
    this.requestContacts = _.debounce(this.requestContacts, DEBOUNCE_MS);
  }

  public componentDidUpdate(prevProps: ContactSelectProps) {
    const { input } = this.props;

    if (input.value !== prevProps.input.value) {
      this.props.getContact(input.value);
    }
  }

  public render() {
    const {
      contacts: contacts,
      getContactOptions: getContactOptions,
      selectedContact,
      getContact: getSelectedContact,
      loading: loading,
      ...rest
    } = this.props;

    const options = this.formatContactOptions();
    const selectedOption =
      options.find(option => option.value === this.props.input.value) || null;

    return (
      <>
        <RenderReactSelect
          {...rest}
          isLoading={loading.contacts}
          placeholder="Find a contact"
          getOptions={this.requestContacts}
          options={this.formatContactOptions()}
          selectedOption={selectedOption}
          isClearable
        />
        {selectedOption && (
          <ul className="contact-card">
            <li>Postcode: {getPrimaryPostcode(selectedOption.contact)} </li>
            <li>
              Outstanding amount:{' '}
              {loading.contact
                ? '…'
                : selectedContact
                ? formatFeeAmount(
                    selectedContact.calculated_balance,
                    '£',
                    '-',
                    true
                  )
                : '-'}
            </li>
          </ul>
        )}
      </>
    );
  }

  public requestContacts = (search?: string) => {
    this.props.getContactOptions({ search });
  };

  public formatContactOptions = () =>
    _.sortBy(
      getAllRetrievedPages(this.props.contacts).map(
        (contact: ContactResponse) => ({
          value: contact.id,
          label: `${contact.crm_id} ${getContactName(contact)}`,
          contact,
        })
      ),
      'label'
    );
}

// Disconnected version used for testing
export { ContactSelect as TestableContactSelect };

export const mapState = (state: StoreState, props: WrappedFieldProps) => ({
  contacts: state.contacts,
  selectedContact: getItemFromCache(props.input.value, state.contactsCache),
  loading: {
    contacts: isPending(state.responses, GET_CONTACTS),
    contact: isPending(state.responses, GET_CONTACT),
  },
});

const connector = connect(mapState, {
  getContactOptions: getContacts,
  getContact,
});

export default connector(ContactSelect);

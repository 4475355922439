import classNames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import RenderFormField, { RenderFormFieldProps } from './render-form-field';

interface OwnProps {
  disabled?: boolean;
  placeholder?: string;
  stretch?: boolean;
}

export type RenderCurrencyFieldProps = OwnProps &
  RenderFormFieldProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class RenderCurrencyField extends React.PureComponent<
  RenderCurrencyFieldProps
> {
  public render() {
    const { input, placeholder, disabled, stretch } = this.props;

    const value = this.props.meta.dirty
      ? this.props.input.value
      : parseFloat(this.props.input.value).toFixed(2);

    return (
      <RenderFormField {...this.props}>
        <div className={classNames('input-currency-field', { stretch })}>
          <span>£</span>
          <input
            {...input}
            disabled={disabled}
            placeholder={placeholder}
            type="number"
            step={0.01}
            onBlur={this.handleBlur}
            value={value}
          />
        </div>
      </RenderFormField>
    );
  }

  public handleBlur = (e: { currentTarget: { value: string } }) => {
    if (e.currentTarget.value) {
      this.props.input.onChange(parseFloat(e.currentTarget.value).toFixed(2));
    }
  };
}

export default RenderCurrencyField;

import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import ConfirmationModal from '^/common/confirmation-modal';
import { closeModal, openModal } from '^/modals/actions';
import { validatePaymentDetails } from '^/payment-details/actions';
import { PUBLIC_REDIRECT_URLS } from '^/public/constants';
import { StoreState } from '^/types';
import { PatientSignupFormValues } from '../types';
import { createNewPatient, pushRecaptchaTokenToStore } from './actions';
import PatientSignupForm from './patient-signup-form';

export type PublicPatientSignupPageProps = ConnectedProps<typeof connector>;

const PublicPatientSignupPage: React.FC<PublicPatientSignupPageProps> = ({
  selectedPublicId,
  validatePaymentDetails: validateBankDetails,
  openModal: innerOpenModal,
  pushRecaptchaTokenToStore: innerPushRecaptchaTokenToStore,
  closeModal: innerCloseModal,
  submit: innerSubmit,
  push: innerPush,
  presetClinician: clinician,
  presetPlan: plan,
  presetSignUp,
}) => {
  const reCaptchaRef: React.Ref<ReCAPTCHA> = React.createRef();

  if (!selectedPublicId) {
    innerPush(PUBLIC_REDIRECT_URLS.ERROR);
  }

  const handleSubmit = useCallback(
    async (data: Partial<PatientSignupFormValues>, dispatch: Dispatch) => {
      data.additional_patients?.forEach(patient => {
        patient.public_id = data.public_id;
        patient.street_address = data.street_address || '';
        patient.city = data.city;
        patient.county = data.county;
        patient.country = data.country;
        patient.postcode = data.postcode || '';
      });

      if (Boolean(data.account_number && data.sort_code)) {
        validateBankDetails(
          data.account_number || '',
          data.sort_code || ''
        ).then(async response => {
          const validation = response && response.data.Items[0];
          if (validation.IsCorrect && validation.IsDirectDebitCapable) {
            await createNewPatient(data)(dispatch);
            innerPush(PUBLIC_REDIRECT_URLS.SUCCESS);
          }
        });
      }
    },
    [innerPush]
  );

  const remoteSubmit = useCallback(
    () => innerSubmit('publicPatientSignupForm'),
    []
  );

  const handleConfirm = async () => {
    reCaptchaRef.current?.reset();

    const token = await reCaptchaRef.current?.executeAsync();

    innerPushRecaptchaTokenToStore(token as string);

    innerOpenModal(
      <ConfirmationModal
        heading=""
        action={remoteSubmit}
        actionLabel="Submit"
        message="Are you sure you want to submit?"
        closeModal={innerCloseModal}
      />
    );
  };

  const onReCAPTCHATokenExpire = () => {
    reCaptchaRef.current?.reset();
    reCaptchaRef.current?.execute();
  };

  return (
    <>
      <PatientSignupForm
        initialValues={{
          public_id: selectedPublicId,
          ...(presetSignUp ? { clinician, plan } : {}),
        }}
        onSubmit={handleSubmit}
        onConfirm={handleConfirm}
      />
      <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey={window.RECAPTCHA_PUBLIC_API_KEY}
        onExpired={onReCAPTCHATokenExpire}
        size="invisible"
      />
    </>
  );
};

export { PublicPatientSignupPage as TestablePublicPatientSignupPage };

const connector = connect(
  (state: StoreState) => ({
    presetClinician: state.publicPatient?.presetClinician?.id,
    presetPlan: state.publicPatient?.presetPlan?.id,
    presetSignUp: state.publicPatient?.presetSignUp,
    selectedPublicId: state.publicPatient?.selectedPublicId,
  }),
  {
    openModal,
    closeModal,
    createNewPatient,
    push,
    submit,
    validatePaymentDetails,
    pushRecaptchaTokenToStore,
  }
);

export default connector(PublicPatientSignupPage);

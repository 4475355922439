import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import {
  GET_ACTIVITY,
  getActivities,
  getActivity,
  getContactActivities,
  SAVE_ACTIVITY,
  saveActivity,
} from '^/activities/actions';
import ActivityForm from '^/activities/form';
import ActivityListItem from '^/activities/list-item';
import { Activity } from '^/activities/types';
import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import SidebarCard from '^/common/sidebar-card';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

interface OwnProps {
  activityId: string;
  showRelatedContact?: boolean;
  assignedUserId?: string | null;
}

interface StateProps {
  editing?: boolean;
}

export type ActivityDetailCardProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class ActivityDetailCard extends React.PureComponent<ActivityDetailCardProps> {
  public componentDidMount(): void {
    const { activityId, activity } = this.props;

    if (cachedItemHasExpired(activity)) {
      this.props.getActivity(activityId);
    }
  }

  public componentDidUpdate(prevProps: ActivityDetailCardProps): void {
    const { activityId, activity, loading } = this.props;

    if (
      !loading.activity &&
      activityId !== prevProps.activityId &&
      cachedItemHasExpired(activity)
    ) {
      this.props.setProps({ editing: false });
      this.props.getActivity(activityId);
    } else if (prevProps.activityId !== activityId) {
      this.props.setProps({ editing: false });
    }
  }

  public render() {
    const { activity, loading, editing, showRelatedContact } = this.props;

    if (!activity) {
      if (loading.activity) {
        return (
          <SidebarCard
            loading
            buttons={
              <AppButton small disabled>
                Edit
              </AppButton>
            }
          >
            <Loading />
          </SidebarCard>
        );
      }

      return null;
    }

    if (editing) {
      return (
        <SidebarCard loading={loading.activity} heading={activity.title}>
          <ActivityForm
            initialValues={activity}
            onSubmit={this.onSave}
            onCancel={this.toggleEditMode}
            actions={[SAVE_ACTIVITY]}
          />
        </SidebarCard>
      );
    }

    return (
      <SidebarCard
        loading={loading.activity}
        heading={activity.title}
        buttons={
          <AppButton small onClick={this.toggleEditMode}>
            Edit
          </AppButton>
        }
      >
        <ActivityListItem
          activity={activity}
          showRelatedContact={showRelatedContact}
          hideTitle
        />
      </SidebarCard>
    );
  }

  public toggleEditMode = () =>
    this.props.setProps({ editing: !this.props.editing });

  public onSave = (activity: Activity, dispatch: Dispatch) => {
    const { activityId, activities, assignedUserId } = this.props;

    return saveActivity(
      activityId,
      activity
    )(dispatch).then(() => {
      this.toggleEditMode();

      if (activity.associated_contact) {
        getContactActivities(activity.associated_contact)(dispatch);
      }

      getActivities(
        activities ? activities.page : undefined,
        activities ? activities.pageSize : undefined,
        activities ? activities.filters : undefined,
        undefined,
        assignedUserId ? assignedUserId : undefined
      )(dispatch);

      if (activity.id) {
        getActivity(activity.id)(dispatch);
        window.location.reload();
      }
    });
  };
}

// Disconnected version used for testing
export { ActivityDetailCard as TestableActivityDetailCard };

export const getInitialProps = () => ({});

export const mapState = (state: StoreState, props: OwnProps) => ({
  activity: getItemFromCache(props.activityId, state.activityCache),
  activities: state.activities,
  loading: { activity: isPending(state.responses, GET_ACTIVITY) },
});

const connector = connect(mapState, { getActivity });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(ActivityDetailCard)
);

import React from 'react';

import GlobalModalRenderer from '^/modals/global-modal-renderer';
import Footer from '../footer';

const PageContainer = ({ children }: { children?: React.ReactNode }) => (
  <div className="page iplan">
    {children}
    <GlobalModalRenderer />
    <Footer />
  </div>
);

export default PageContainer;

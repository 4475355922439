import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Card from '^/common/card';
import PageContent from '^/page-container/page-content';

export type LoginSetupSSOProps = RouteComponentProps;

class LoginSetupSSO extends React.PureComponent<LoginSetupSSOProps> {
  public render() {
    return (
      <div className="login-container">
        <PageContent>
          <Card className="login-page">
            <div className="logo">
              <img src="/static/agilio-iplan-logo.svg" />
            </div>
            <div>
              <h4>Setup your Single-Sign-On account.</h4>
              <p>
                Agilio iPlan login and authentication is through a secure
                single-sign-on service. Contact the Agilio team to find out more
                or get an account set up.
              </p>
            </div>
          </Card>
        </PageContent>
      </div>
    );
  }
}

export { LoginSetupSSO as TestableLoginSetupSSO };
export default LoginSetupSSO;

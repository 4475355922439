import { anyPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import UsersTable from '^/admin/users/table';
import AppButton from '^/common/app-button';
import FilterBar from '^/common/filter-bar';
import HeaderBar from '^/common/header-bar';
import { FilterList } from '^/filters/types';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { GET_USERS } from './actions';

export type UsersListPageProps = ConnectedProps<typeof connector>;

const AGILIO_USER_CREATE_ROUTE = '/admin/users/create';

export class UsersListPage extends React.PureComponent<UsersListPageProps> {
  public render() {
    const { filter, url, loading } = this.props;

    return (
      <PageContent>
        <HeaderBar
          title="CODE Users"
          primaryActions={[
            <AppButton
              onClick={this.onCreateNewUser}
              key="new"
              leftIcon="plus"
              primary
            >
              New User
            </AppButton>,
          ]}
          transparent
        />

        <Container>
          <FilterBar
            filter={filter}
            url={url}
            filters={USER_FILTERS}
            loading={loading}
          />
          <UsersTable filters={USER_FILTERS[filter]} />
        </Container>
      </PageContent>
    );
  }

  public onCreateNewUser = () => {
    this.props.push(AGILIO_USER_CREATE_ROUTE);
  };
}

export { UsersListPage as TestableUsersListPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId?: string; activityId?: string }>
) => {
  const queryParams = queryString.parse(props.location.search);

  return {
    filter: queryParams.filter ? String(queryParams.filter) : 'All',
    loading: anyPending(state.responses, [GET_USERS]),
    url: props.match.url,
  };
};

const connector = connect(mapState, { push });

export default connector(UsersListPage);

export const USER_FILTERS: { [filter: string]: FilterList } = {
  All: {},
  Active: { status: 'Active' },
  Archived: { status: 'Archived' },
  Locked: { status: 'Locked' },
};

import { VatCode, VatRate, VatRates } from './types';

export function formatVatRate(vatRate?: number | VatRate | null): string {
  if (typeof vatRate === 'number') {
    vatRate = getVatRate(vatRate);
  }

  if (!vatRate) {
    return 'Unknown VAT rate';
  }

  return `${vatRate.code}: ${vatRate.description}`;
}

export function getVatRate(
  code?: VatCode | null | undefined | number | string
): VatRate | null {
  if (!code) {
    return null;
  }

  code = typeof code === 'string' ? parseInt(code, 10) : code;

  const vatRate = VatRates[code];

  return vatRate || null;
}

export function getVatRatePercentage(
  code?: VatCode | null | undefined | number | string
): number | null {
  const vatRate = getVatRate(code);

  return vatRate ? vatRate.rate : null;
}

export function calculateVatFromGross(
  gross: number,
  code?: VatCode | null | undefined | number | string
): number {
  const rate = getVatRatePercentage(code);

  if (!rate) {
    return 0;
  }

  return roundPence(gross - gross / (1 + rate));
}

export function calculateGoodsFromGross(
  gross: number,
  code?: VatCode | null | undefined | number | string
): number {
  const vat = calculateVatFromGross(gross, code);

  return roundPence(gross - vat);
}

export function calculateVatFromGoods(
  goods: number,
  code?: VatCode | null | undefined | number | string
): number {
  const rate = getVatRatePercentage(code);

  if (!rate) {
    return 0;
  }

  return roundPence(goods * rate);
}

export function calculateGrossFromGoods(
  goods: number,
  code?: VatCode | null | undefined | number | string
): number {
  const vat = calculateVatFromGoods(goods, code);

  return roundPence(goods + vat);
}

export function roundPence(amount: number = 0): number {
  return Number(amount.toFixed(2));
}

import { AnyAction } from 'redux';

import {
  GET_CONTACT_PLANS,
  GET_DISCOUNTS,
  GET_FEES_BY_AGE,
} from '^/plans/actions';
import { DiscountResponse, FeesByAgeResponse } from '^/plans/types';
import { PaginatedArray, StoreState } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

export function contactPlans(
  state: StoreState['contactPlans'] = {},
  action: AnyAction
): StoreState['contactPlans'] {
  switch (action.type) {
    case GET_CONTACT_PLANS.SUCCESS:
      return {
        ...state,
        [action.meta.contactId]: paginatedReducer(
          state[action.meta.contactId],
          action
        ),
      };
    default:
      return state;
  }
}

export function discounts(
  state: { [planId: string]: PaginatedArray<DiscountResponse> } = {},
  action: AnyAction
): { [planId: string]: PaginatedArray<DiscountResponse> } {
  switch (action.type) {
    case GET_DISCOUNTS.SUCCESS:
      return {
        ...state,
        [action.meta.planId]: paginatedReducer(
          state[action.meta.planId],
          action
        ),
      };
    default:
      return state;
  }
}

export function feesByAge(
  state: { [planId: string]: PaginatedArray<FeesByAgeResponse> } = {},
  action: AnyAction
): { [planId: string]: PaginatedArray<FeesByAgeResponse> } {
  switch (action.type) {
    case GET_FEES_BY_AGE.SUCCESS:
      return {
        ...state,
        [action.meta.planId]: paginatedReducer(
          state[action.meta.planId],
          action
        ),
      };
    default:
      return state;
  }
}

import moment from 'moment';

export const required = (value: string) => (value ? undefined : 'Required');

export const isChecked = (value: boolean) => (value ? undefined : 'Required');

export const requiredZeroPermitted = (value: string | number) =>
  value || value === '0' || value === 0 ? undefined : 'Required';

export const requiredZeroOrMore = (value: string | number) => {
  return !isNaN(Number(value)) && Number(value) >= 0
    ? undefined
    : 'Must be positive or zero';
};

export const isBackdateValid = (value: string | number, allValues: any) => {
  if (!allValues.backdate) {
    return undefined;
  }
  return !isNaN(Number(value)) && Number(value) >= 0
    ? undefined
    : 'Must be positive or zero';
};

export const futureDate = (value: string) => {
  if (value && moment(value).isBefore(moment())) {
    return 'Must be in the future';
  }

  return undefined;
};

export const futureDateInclusive = (value: string) => {
  if (value && moment(value).isBefore(moment().startOf('day'))) {
    return 'Must not be in the past';
  }

  return undefined;
};

export const pastDate = (value: string) => {
  if (!(value && moment(value).isBefore(moment()))) {
    return 'Must not be in the future';
  }

  return undefined;
};

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import ContactsDetailPage from './detail-page';
import ContactsListPage from './list-page';

class ContactsRoutes extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.path}/:contactId`}
          component={ContactsDetailPage}
        />
        <Route component={ContactsListPage} />
      </Switch>
    );
  }
}

export default ContactsRoutes;

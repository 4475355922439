import { anyPending } from '@dabapps/redux-requests';
import { Container, Tab, Tabs } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import ActivitiesListComponent from '^/activities/browser';
import ContactPopups from '^/activities/popups';
import AppButton from '^/common/app-button';
import { ErrorPage } from '^/common/error-page';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import { contactActions, getContact } from '^/contacts/actions';
import ArchiveContact from '^/contacts/archive-contact';
import CompaniesDetailsTab from '^/contacts/companies/companies/tabs/details-tab';
import ContactHeaderBar from '^/contacts/header-bar';
import LedgerTab from '^/contacts/ledger/ledger-tab';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { contactMissingDetail, getItemFromCache } from '^/utils/cache-helpers';

/** Retrieves the company from the contactsCache if available and sets the loading prop. */
export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{
    contactId: string;
  }>
) => ({
  company: getItemFromCache(props.match.params.contactId, state.contactsCache),
  loading: {
    details: anyPending(state.responses, contactActions),
  },
});

const connector = connect(mapState, { getContact });

export type CompaniesDetailPageProps = RouteComponentProps<{
  contactId: string;
}> &
  ConnectedProps<typeof connector>;

/**
 * Displays all company details. Uses tabs to show and hide information.
 */
class CompaniesDetailPage extends React.PureComponent<
  CompaniesDetailPageProps
> {
  public componentDidMount() {
    const {
      company,
      loading,
      match: { params },
    } = this.props;

    if (!loading.details && contactMissingDetail(company)) {
      this.props.getContact(params.contactId);
    }
  }

  public render() {
    const {
      company,
      loading,
      match: {
        params: { contactId },
      },
    } = this.props;

    if (!company) {
      if (loading.details) {
        return (
          <PageContent>
            <BreadcrumbBar items={[['Records', '/contacts'], 'Loading...']} />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Company not found" />;
    }

    return (
      <PageContent>
        <BreadcrumbBar
          items={[['Records', '/contacts'], getContactName(company)]}
        />
        <ContactHeaderBar
          contact={company}
          secondaryActions={[
            <AppButton
              key="export-activities"
              url={`/api/activities/csv/?associated_contact=${company.id}`}
              small
            >
              Export Company Activities
            </AppButton>,
            <AppButton
              key="export-practice"
              url={`/api/letters/export-contact/${company.id}/`}
              small
            >
              Export Company for Letters
            </AppButton>,
            <ArchiveContact key="archive" contact={company} />,
          ]}
          loading={loading.details}
        />
        <Container>
          <Tabs>
            <Tab>
              <NavLink to={`${this.props.match.url}/details`}>Details</NavLink>
            </Tab>
            <Tab>
              <NavLink to={`${this.props.match.url}/ledger`}>Ledger</NavLink>
            </Tab>
            <Tab>
              <NavLink to={`${this.props.match.url}/activities`}>
                Record Activities
              </NavLink>
            </Tab>
          </Tabs>

          <Switch>
            <Route
              path={`${this.props.match.path}/details`}
              component={CompaniesDetailsTab}
            />
            <Route
              path={`${this.props.match.path}/ledger`}
              component={LedgerTab}
            />
            <Route
              path={`${this.props.match.path}/activities/:activityId`}
              component={ActivitiesListComponent}
            />
            <Route
              path={`${this.props.match.path}/activities`}
              component={ActivitiesListComponent}
            />
            <Route component={this.redirect} />
          </Switch>
        </Container>

        <ContactPopups contactId={contactId} />
      </PageContent>
    );
  }

  public redirect = () => <Redirect to={`${this.props.match.url}/details`} />;
}

/** Disconnected version of the component that is used for testing. */
export { CompaniesDetailPage as TestableCompaniesDetailPage };

export default connector(CompaniesDetailPage);

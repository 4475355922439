import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { RenderFormFieldProps } from '^/form-helpers/render-form-field';

export type ColorPickerProps = RenderFormFieldProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class ColorPicker extends React.Component<ColorPickerProps> {
  constructor(props: ColorPickerProps) {
    super(props);

    let value = this.props.value;
    if (!this.props.value) {
      value = this.props.defaultValue ?? '#ffffff';
    }
    this.props.input.onChange(value);
  }

  public onColorChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.input.onChange(e.target.value);

  public render() {
    const {
      defaultValue,
      input,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div className="color-picker">
        <input
          type="color"
          className="color-picker-color"
          value={input.value || defaultValue}
          onChange={this.onColorChangeEventHandler}
        />

        <input
          type="text"
          className="color-picker-value"
          placeholder="#ffffff"
          value={input.value || defaultValue}
          name={input.name}
          disabled
        />

        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }
}

export default ColorPicker;

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { AxiosResponse } from 'axios';
import { AnyAction, Dispatch } from 'redux';

import { shouldRethrow } from '^/address/actions';
import { User } from '^/admin/users/types';
import { generateQueryString } from '^/common/helper-functions';
import { generateSorts } from '^/contacts/actions';
import { FilterList } from '^/filters/types';
import { ACCOUNTS_ENDPOINT } from '^/login/actions';
import { SortList } from '^/sorts/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const AGILIO_USERS_ENDPOINT = `${ACCOUNTS_ENDPOINT}/agilio-users`;
export const PRACTICE_USERS_ENDPOINT = `${ACCOUNTS_ENDPOINT}/practice-users`;
export const RESEND_INVITE_ENDPOINT = `${ACCOUNTS_ENDPOINT}/users/resend-invite`;

export const GET_USERS = makeAsyncActionSet('GET_USERS');
export function getUsers(
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  sorting?: SortList,
  getAllPages: boolean = false,
  filters: FilterList = {}
): (dispatch: Dispatch<AnyAction>) => Promise<void | AxiosResponse<any>> {
  const queryString: string = generateQueryString({
    ordering: generateSorts(sorting),
    page,
    page_size: pageSize,
    ...filters,
  });

  return (dispatch: Dispatch) =>
    request<any>(
      GET_USERS,
      `${AGILIO_USERS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      {
        metaData: { page, pageSize },
      }
    )(dispatch).then(response => {
      if (getAllPages && response && response.data.next) {
        return getUsers(page + 1, pageSize, sorting, getAllPages)(dispatch);
      }

      return response;
    });
}

export const GET_PRACTICE_USERS = makeAsyncActionSet('GET_PRACTICE_USERS');
export function getPracticeUsers(
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  sorting?: SortList,
  getAllPages: boolean = false,
  filters: FilterList = {}
): (dispatch: Dispatch<AnyAction>) => Promise<void | AxiosResponse<any>> {
  const queryString: string = generateQueryString({
    ordering: generateSorts(sorting),
    page,
    page_size: pageSize,
    ...filters,
  });

  return (dispatch: Dispatch) =>
    request<any>(
      GET_PRACTICE_USERS,
      `${PRACTICE_USERS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      {
        metaData: { page, pageSize },
      }
    )(dispatch).then(response => {
      if (getAllPages && response && response.data.next) {
        return getPracticeUsers(
          page + 1,
          pageSize,
          sorting,
          getAllPages
        )(dispatch);
      }

      return response;
    });
}

export const GET_USER = makeAsyncActionSet('GET_USER');
export function getUser(id: string) {
  return (dispatch: Dispatch) =>
    request(GET_USER, `${AGILIO_USERS_ENDPOINT}/${id}/`, 'GET')(dispatch);
}

export const GET_PRACTICE_USER = makeAsyncActionSet('GET_PRACTICE_USER');
export function getPracticeUser(id: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_PRACTICE_USER,
      `${PRACTICE_USERS_ENDPOINT}/${id}/`,
      'GET'
    )(dispatch);
}

export const RESEND_INVITE = makeAsyncActionSet('RESEND_INVITE');
export function resendInvite(id: string) {
  return (dispatch: Dispatch) =>
    request(
      RESEND_INVITE,
      `${RESEND_INVITE_ENDPOINT}/${id}/`,
      'POST',
      {},
      { shouldRethrow }
    )(dispatch).then(response => response);
}

export const CREATE_USER = makeAsyncActionSet('CREATE_USER');
export function createUser(user: User) {
  return (dispatch: Dispatch) =>
    request(CREATE_USER, `${AGILIO_USERS_ENDPOINT}/`, 'POST', user, {
      shouldRethrow,
    })(dispatch).catch(throwSubmissionError);
}

export const SAVE_USER = makeAsyncActionSet('SAVE_USER');
export function saveUser(id: string, user: User) {
  return (dispatch: Dispatch) =>
    request(SAVE_USER, `${AGILIO_USERS_ENDPOINT}/${id}/`, 'PUT', user, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch)
      .then(() => getUser(id)(dispatch))
      .catch(throwSubmissionError);
}

export const CREATE_PRACTICE_USER = makeAsyncActionSet('CREATE_PRACTICE_USER');
export function createPracticeUser(user: User) {
  return (dispatch: Dispatch) =>
    request(CREATE_PRACTICE_USER, `${PRACTICE_USERS_ENDPOINT}/`, 'POST', user, {
      shouldRethrow,
    })(dispatch).catch(throwSubmissionError);
}

export const SAVE_PRACTICE_USER = makeAsyncActionSet('SAVE_PRACTICE_USER');
export function savePracticeUser(id: string, user: User) {
  return (dispatch: Dispatch) =>
    request(
      SAVE_PRACTICE_USER,
      `${PRACTICE_USERS_ENDPOINT}/${id}/`,
      'PUT',
      user,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch)
      .then(() => getPracticeUser(id)(dispatch))
      .catch(throwSubmissionError);
}

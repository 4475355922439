import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { getAttachment } from '^/attachments/actions';
import { AttachmentStatus } from '^/attachments/types';
import AppButton from '^/common/app-button';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

// Props that come from the parent component.
interface OwnProps {
  attachmentId: string;
  onRemove?(attachmentId: string): void;
}

export type AttachmentListItemProps = OwnProps &
  ConnectedProps<typeof connector>;

class AttachmentListItem extends React.PureComponent<AttachmentListItemProps> {
  public componentDidMount(): void {
    const { attachment, attachmentId } = this.props;

    if (cachedItemHasExpired(attachment, moment.duration(1, 'minutes'))) {
      this.props.getAttachment(attachmentId);
    }
  }

  public render() {
    const { attachmentId, attachment } = this.props;

    return (
      <div className="attachment-list-item">
        <AppButton
          link
          url={`/api/attachments/${attachmentId}/download`}
          loading={!attachment}
          disabled={
            !attachment || attachment.status !== AttachmentStatus.Available
          }
        >
          {this.getIcon(attachment ? attachment.status : undefined)}{' '}
          {attachment && attachment.file_name}
        </AppButton>
        {this.props.onRemove && (
          <AppButton onClick={this.handleRemove} destructive link>
            remove
          </AppButton>
        )}
      </div>
    );
  }

  public getIcon(status?: AttachmentStatus) {
    switch (status) {
      case AttachmentStatus.Available:
        return <FontAwesomeIcon icon="paperclip" />;
      case AttachmentStatus.Uploading:
        return <FontAwesomeIcon icon="cog" spin />;
      case undefined:
        return <FontAwesomeIcon icon="circle-notch" spin />;
      case AttachmentStatus.Unavailable:
      default:
        return <FontAwesomeIcon icon="times-circle" />;
    }
  }

  public handleRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.attachmentId);
    }
  };
}

// Disconnected version used for testing
export { AttachmentListItem as TestableAttachmentListItem };

export const mapState = (state: StoreState, props: OwnProps) => ({
  attachment: getItemFromCache(props.attachmentId, state.attachmentsCache),
});

const connector = connect(mapState, { getAttachment });

export default connector(AttachmentListItem);

import { Column, Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { reset } from 'redux-form';

import AddContactForm from '^/ledger/bacs/add-contact-form';
import PageContent from '^/page-container/page-content';
import { REQUEST_REPORT, requestReport } from '^/routines/actions';
import ReportList from '^/routines/report-list';
import { Report, ReportCategory, ReportType } from '^/routines/types';
import { StoreState } from '^/types';
import { addContactToBACS, clearBACS } from '../actions';
import { BACSSubmissionEntry } from '../types';
import PendingBacsForm, { PendingBACSEntry } from './pending-bacs-form';
import PendingBacsTable from './pending-bacs-table';

export type ManualBACSSubmissionProps = ConnectedProps<typeof connector>;

class ManualBACSSubmission extends React.PureComponent<
  ManualBACSSubmissionProps
> {
  public render() {
    const { pendingBACSSubmission } = this.props;

    return (
      <PageContent>
        <Container>
          <Column xs={12} lg={3}>
            <div className="filter-section">
              <AddContactForm onSubmit={this.handleAddContact} />
            </div>
          </Column>
          <Column xs={12} lg={9}>
            <PendingBacsTable heading="1. Review contacts" />
            <PendingBacsForm
              heading="2. Select bank and generate file"
              onSubmit={this.handleGenerateBACS}
              errorFields={['report_type', 'start_date', 'end_date']}
              actions={[REQUEST_REPORT]}
              disabled={pendingBACSSubmission.length < 1}
            />
            <ReportList
              title="3. Wait for BACS file to process"
              type={ReportType.ManualBacsRun}
              category={ReportCategory.Generic}
              noApplyButtons
            />
          </Column>
        </Container>
      </PageContent>
    );
  }

  public handleAddContact = (row: BACSSubmissionEntry) => {
    this.props.addContactToBACS(row);
    this.props.reset('AddContactForm');
  };

  public handleGenerateBACS = async (pendingBACSEntry: PendingBACSEntry) => {
    this.props.reset('AddContactForm');

    const report: Report = {
      report_type: ReportType.ManualBacsRun,
      report_category: ReportCategory.Generic,
      params: {
        entry_date: pendingBACSEntry.entry_date,
        bank: pendingBACSEntry.bank,
      },
      request_data: this.props.pendingBACSSubmission,
    };

    try {
      await this.props.requestReport(report);
      this.props.clearBACS();
    } catch (e) {
      e.errors._error = 'Unable to request the BACS file.';
      throw e;
    }
  };
}

// Disconnected version used for testing
export { ManualBACSSubmission as TestableManualBACSSubmission };

export const mapState = (state: StoreState) => ({
  pendingBACSSubmission: state.pendingBACSSubmission,
});

const mapDispatch = {
  addContactToBACS,
  requestReport,
  clearBACS,
  reset,
};

const connector = connect(mapState, mapDispatch);

export default connector(ManualBACSSubmission);

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import ActivityCreatePage from './create-page';
import ActivitiesListPage from './list-page';

class ActivitiesRoutes extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/create`} component={ActivityCreatePage} />
        <Route component={ActivitiesListPage} />
      </Switch>
    );
  }
}

export default ActivitiesRoutes;

import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { getMemberships } from '^/admin/memberships/actions';
import AppButton from '^/common/app-button';
import { FilterList } from '^/filters/types';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import { MembershipResponse } from '^/memberships/types';
import { getPlanName } from '^/plans/helpers';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  onClear?(): void;
}

export type PracticesFilterFormProps = OwnProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<FilterList, OwnProps>;

class PracticesFilterForm extends React.PureComponent<
  PracticesFilterFormProps
> {
  public componentDidMount() {
    if (paginatedArrayHasExpired(this.props.memberships, 100)) {
      this.props.getMemberships(1, 100);
    }
  }

  public render() {
    const { handleSubmit, initialized, dirty, submitting } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="form-header">
          <h4>Filters</h4>
          <AppButton
            id="clear-button"
            link
            disabled={!initialized && !dirty}
            loading={submitting}
            onClick={this.handleClear}
          >
            CLEAR
          </AppButton>
        </div>
        <CSSTransitionGroup
          component={FormGroup}
          transitionName="shrink"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          <Field
            label="Company name"
            name="company_name"
            component={RenderInputField}
          />
          <Field
            label="Contact ID"
            name="crm_id"
            component={RenderInputField}
          />
          <Field
            label="Postcode"
            name="postcode"
            component={RenderInputField}
          />
          <Field
            label="Type of membership"
            name="membership"
            component={RenderDropdown}
            options={this.getMembershipDropdownOptions()}
          />
          <Field
            label="Bacs Reference"
            name="bacs_reference"
            component={RenderInputField}
          />
          <Field
            label="Show archived"
            name="show_archived"
            component={RenderCheckBox}
          />
        </CSSTransitionGroup>
        <div className="form-buttons">
          <AppButton type="submit" loading={submitting}>
            Search
          </AppButton>
        </div>
      </Form>
    );
  }

  public handleClear = () => {
    this.props.reset();

    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  public getMembershipDropdownOptions = () => {
    const membershipOptions = getAllRetrievedPages(this.props.memberships).map(
      (membership: MembershipResponse) => ({
        label: getPlanName(membership),
        value: membership.id,
      })
    );

    return [{ label: '', value: '' }, ...membershipOptions];
  };
}

// Disconnected version used for testing
export { PracticesFilterForm as TestablePracticesFilterForm };

export const mapState = (state: StoreState) => ({
  memberships: state.memberships,
});

const connector = connect(mapState, { getMemberships });

export default reduxForm<FilterList, OwnProps>({
  form: 'practicesFilterForm',
})(connector(PracticesFilterForm));

import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';
import * as _ from 'underscore';

import { getContactName } from '^/common/helper-functions';
import {
  GET_PRACTICES,
  getPractices,
} from '^/contacts/companies/practices/actions';
import { ContactResponse } from '^/contacts/types';
import RenderReactSelect from '^/form-helpers/render-react-select';
import { StoreState } from '^/types';
import { DEBOUNCE_MS } from '^/utils/constants';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

interface OwnProps {
  label?: string;
}

export type PracticeSelectProps = OwnProps &
  ConnectedProps<typeof connector> &
  WrappedFieldProps;

class PracticeSelect extends React.PureComponent<PracticeSelectProps> {
  constructor(props: PracticeSelectProps) {
    super(props);
    this.getPracticeOptions = _.debounce(this.getPracticeOptions, DEBOUNCE_MS);
  }

  public render() {
    // Don't pass practices and getPractices props to RenderReactSelect
    const { practices, getPracticeOptions, ...rest } = this.props;
    const options = this.formatPracticeOptions();
    const selectedOption =
      options.find(option => option.value === this.props.input.value) || null;
    return (
      <RenderReactSelect
        {...rest}
        className="practice-select"
        placeholder="Search for practice"
        getOptions={this.getPracticeOptions}
        options={this.formatPracticeOptions()}
        selectedOption={selectedOption}
        isClearable
      />
    );
  }

  public getPracticeOptions = (search?: string) => {
    this.props.getPracticeOptions({ search });
  };

  public formatPracticeOptions = () =>
    _.sortBy(
      getAllRetrievedPages(this.props.practices).map(
        (practice: ContactResponse) => {
          const postcode = practice.addresses.length
            ? practice.addresses.map(address => address.postcode).join(', ')
            : 'no address';

          return {
            value: practice.id,
            label: `${getContactName(practice)} (${postcode})`,
          };
        }
      ),
      'label'
    );
}

// Disconnected version used for testing
export { PracticeSelect as TestablePracticeSelect };

export const mapState = (state: StoreState) => ({
  practices: state.practices,
  isLoading: isPending(state.responses, GET_PRACTICES),
});

const connector = connect(mapState, {
  getPracticeOptions: getPractices,
});

export default connector(PracticeSelect);

import { isPending } from '@dabapps/redux-requests/dist/js';
import { Column, Container, Row } from '@dabapps/roe';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { formValueSelector, isDirty } from 'redux-form';

import { GET_ACTIVITIES, getActivities } from '^/activities/actions';
import ActivityList from '^/activities/activity-list';
import { ActivityListType } from '^/activities/types';
import { UserRole } from '^/admin/users/types';
import AppButton from '^/common/app-button';
import HeaderBar from '^/common/header-bar';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import {
  GET_MEMBERSHIPS_SUMMARY,
  getMembershipsSummary,
} from '^/dashboard/actions';
import MembershipStatistics from '^/dashboard/membership-statistics';
import MembershipStatisticsForm from '^/dashboard/membership-statistics-form';
import { MembershipStats } from '^/dashboard/types';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { API_DATE_FORMAT } from '^/utils/constants';

const MEMBERSHIPS_SUMMARY_FORM_NAME: string = 'memberships-summary-form';

const INITIAL_FORM_VALUES = {
  start_date: moment()
    .subtract(1, 'months')
    .format(API_DATE_FORMAT),
  end_date: moment().format(API_DATE_FORMAT),
};

const formMembershipSummarySelector = formValueSelector(
  MEMBERSHIPS_SUMMARY_FORM_NAME
);

export const mapState = (state: StoreState) => ({
  isAdmin:
    state.loggedInUser !== null &&
    state.loggedInUser.role === UserRole.AdminLevel,
  membershipSummaryFormValues: {
    start_date: formMembershipSummarySelector(state, 'start_date'),
    end_date: formMembershipSummarySelector(state, 'end_date'),
  },

  isMembershipsSummaryDirty: isDirty(MEMBERSHIPS_SUMMARY_FORM_NAME)(state),
  overdueActivities: state.dashboardActivityLists[ActivityListType.Overdue],
  upcomingActivities: state.dashboardActivityLists[ActivityListType.Upcoming],
  loading: {
    membershipsSummary: isPending(state.responses, GET_MEMBERSHIPS_SUMMARY),
    upcomingActivities: isPending(
      state.responses,
      GET_ACTIVITIES,
      ActivityListType.Upcoming
    ),
    overdueActivities: isPending(
      state.responses,
      GET_ACTIVITIES,
      ActivityListType.Overdue
    ),
  },
  loggedInUser: state.loggedInUser,
  membershipsSummary: state.membershipsSummary,
});

const connector = connect(mapState, {
  getMembershipsSummary,
  getActivities,
});

export type DashboardPageProps = ConnectedProps<typeof connector>;

class DashboardPage extends React.PureComponent<DashboardPageProps> {
  public componentDidMount() {
    const { loading, loggedInUser } = this.props;

    this.props.getMembershipsSummary(1, 100, undefined, INITIAL_FORM_VALUES);

    if (loggedInUser) {
      if (!loading.upcomingActivities) {
        this.props.getActivities(
          1,
          3,
          {
            start_date: moment()
              .startOf('day')
              .format(API_DATE_FORMAT),
          },
          undefined,
          loggedInUser.id,
          ActivityListType.Upcoming
        );
      }

      if (!loading.overdueActivities) {
        this.props.getActivities(
          1,
          3,
          { completed_next_action: 'False' },
          { due_date: 'ASC' },
          loggedInUser.id,
          ActivityListType.Overdue
        );
      }
    }
  }

  public componentDidUpdate(prevProps: DashboardPageProps) {
    if (
      (this.props.membershipSummaryFormValues.start_date !==
        prevProps.membershipSummaryFormValues.start_date ||
        this.props.membershipSummaryFormValues.end_date !==
          prevProps.membershipSummaryFormValues.end_date) &&
      this.props.isMembershipsSummaryDirty
    ) {
      this.onMembershipsSummarySubmit();
    }
  }

  public render() {
    const {
      isAdmin,
      overdueActivities,
      upcomingActivities,
      membershipsSummary,
      loading,
    } = this.props;

    return (
      <PageContent page="dashboard">
        <HeaderBar title="Dashboard" transparent />
        <Container>
          <main>
            <PageSection>
              {isAdmin && (
                <PageSubSection
                  className="dashboard-sub-section"
                  vertical
                  clean
                >
                  <Row>
                    <Column xs={12} md={6}>
                      <h4>Memberships</h4>
                    </Column>

                    <Column xs={12} md={6}>
                      <MembershipStatisticsForm
                        onSubmit={this.onMembershipsSummarySubmit}
                        actions={[GET_MEMBERSHIPS_SUMMARY]}
                        form={MEMBERSHIPS_SUMMARY_FORM_NAME}
                        initialValues={INITIAL_FORM_VALUES}
                      />
                    </Column>
                  </Row>

                  <Row
                    className={classnames(
                      'flex-grid',
                      'membership-statistics-wrapper',
                      { loading: loading.membershipsSummary }
                    )}
                  >
                    {membershipsSummary && Boolean(membershipsSummary.total) && (
                      <MembershipStatistics
                        membership={{
                          name: 'All',
                          total: membershipsSummary.total,
                        }}
                      />
                    )}

                    {membershipsSummary &&
                    Boolean(membershipsSummary.memberships.length) ? (
                      membershipsSummary.memberships
                        .slice(0, 3)
                        .map((membership: MembershipStats, index: number) => (
                          <MembershipStatistics
                            key={index}
                            membership={membership}
                          />
                        ))
                    ) : loading.membershipsSummary ? (
                      <Loading />
                    ) : (
                      <div className="empty-state">No data</div>
                    )}
                  </Row>
                </PageSubSection>
              )}
            </PageSection>

            <PageSection
              heading="My Activities"
              flex={false}
              className="my-activities"
            >
              <Row>
                <Column xs={12} md={6}>
                  <PageSubSection
                    heading="Upcoming activities"
                    vertical
                    loading={loading.upcomingActivities}
                  >
                    {!upcomingActivities && loading.upcomingActivities && (
                      <Loading />
                    )}
                    {upcomingActivities && (
                      <ActivityList
                        activities={upcomingActivities}
                        showRelatedContact
                        linkToDetail
                      />
                    )}
                    <div className="buttons">
                      <Link to={`/activities`}>
                        <AppButton small>View all activities</AppButton>
                      </Link>
                    </div>
                  </PageSubSection>
                </Column>
                <Column xs={12} md={6}>
                  <PageSubSection
                    heading="Most overdue activities"
                    vertical
                    loading={loading.overdueActivities}
                  >
                    {!overdueActivities && loading.overdueActivities && (
                      <Loading />
                    )}
                    {overdueActivities && (
                      <ActivityList
                        activities={overdueActivities}
                        showRelatedContact
                        linkToDetail
                      />
                    )}
                    <div className="buttons">
                      <Link to={`/activities?filter=Overdue`}>
                        <AppButton small>View overdue activities</AppButton>
                      </Link>
                    </div>
                  </PageSubSection>
                </Column>
              </Row>
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  public onMembershipsSummarySubmit = () => {
    const { membershipSummaryFormValues } = this.props;
    this.props.getMembershipsSummary(
      1,
      100,
      undefined,
      membershipSummaryFormValues
    );
  };
}

export { DashboardPage as TestableDashboardPage };

export default connector(DashboardPage);

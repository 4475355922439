import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { parseNumberField } from '^/common/helper-functions';
import { required, requiredZeroPermitted } from '^/common/validation';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderVatCodePicker from '^/form-helpers/render-vat-code-picker';
import { StatusTypes } from '^/payment-details/types';
import { Product } from './types';

/** Props that are passed into this component by the parent. */
interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  generalErrorFields?: ReadonlyArray<string>;
  /** Prefix for each label, e.g. '<prefix> Name'. Defaults to 'Company'. */
  labelPrefix?: string;
  /** If present a cancel button will be shown that will fire this prop. */
  onCancel?(): void;
}

/** All company form props combined. */
export type ProductFormProps = OwnProps & InjectedFormProps<Product, OwnProps>;

/**
 * Form that is used for both companies and practices. Can be us9ed in both a create and edit context. The prop 'onSubmit' is called when the save button is pressed.
 */
class ProductForm extends React.Component<ProductFormProps> {
  public render() {
    const {
      actions,
      generalErrorFields = ['non_field_errors'],
      handleSubmit,
      onCancel,
      pristine,
      submitting,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            component={RenderInputField}
            label="Product Code"
            name="product_code"
            type="text"
            validate={[required]}
          />
          <Field
            component={RenderInputField}
            label="Description"
            name="description"
            type="text"
            validate={[required]}
            autocomplete="off"
          />
          <Field
            component={RenderInputField}
            label="Nominal Code"
            name="nominal_code"
            type="text"
            validate={[required]}
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={RenderCurrencyField}
            label="Member Price"
            name="member_price"
            type="number"
            validate={[requiredZeroPermitted]}
            parse={parseNumberField}
            stretch
          />
          <Field
            component={RenderCurrencyField}
            label="Non Member Price"
            name="non_member_price"
            type="number"
            validate={[requiredZeroPermitted]}
            parse={parseNumberField}
            stretch
          />
          <Field
            component={RenderCurrencyField}
            label="Delivery"
            name="delivery"
            type="number"
            parse={parseNumberField}
            stretch
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={RenderVatCodePicker}
            label="Vat Code"
            name="vat"
            type="number"
            validate={[required]}
          />
          <Field
            component={RenderDropdown}
            label="Status"
            name="status"
            type="string"
            validate={[required]}
            options={[
              { label: 'Please choose', value: '' },
              { label: StatusTypes.Active, value: StatusTypes.Active },
              { label: StatusTypes.Inactive, value: StatusTypes.Inactive },
            ]}
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={generalErrorFields}
          showStatusErrors
        />
        <div className="form-buttons">
          <AppButton type="button" onClick={onCancel} disabled={submitting}>
            Cancel
          </AppButton>
          <AppButton
            type="submit"
            disabled={pristine}
            loading={submitting}
            primary
          >
            Save
          </AppButton>
        </div>
      </Form>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { ProductForm as TestableProductForm };

export default reduxForm<Product, OwnProps>({
  form: 'productForm',
})(ProductForm);

import { AnyAction } from 'redux';

import {
  CREATE_PRACTICE_INSURANCE,
  DELETE_PRACTICE_INSURANCE,
  GET_PRACTICE_INSURANCE,
  SAVE_PRACTICE_INSURANCE,
} from '^/insurance/actions';
import { InsuranceResponse } from '^/insurance/types';
import { ByContact } from '^/types';

export function practiceInsurance(
  state: ByContact<InsuranceResponse> = {},
  action: AnyAction
): ByContact<InsuranceResponse> {
  switch (action.type) {
    case GET_PRACTICE_INSURANCE.SUCCESS:
      if (action.payload.data.results.length < 1) {
        return state;
      }
      return {
        ...state,
        [action.payload.data.results[0].practice]:
          action.payload.data.results[0],
      };
    case CREATE_PRACTICE_INSURANCE.SUCCESS:
    case SAVE_PRACTICE_INSURANCE.SUCCESS:
      return {
        ...state,
        [action.payload.data.practice]: action.payload.data,
      };
    case DELETE_PRACTICE_INSURANCE.SUCCESS:
      return {
        ...state,
        [action.meta.practiceId]: undefined,
      };
    default:
      return state;
  }
}

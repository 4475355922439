import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { Highlight, SpacedGroup } from '@dabapps/roe';
import React from 'react';

import AppButton from './app-button';

interface OwnProps {
  content?: string;
  disabled?: boolean;
  onConfirm(): void;
}

interface SetPropsProps {
  confirm: boolean;
}

export type ConfirmButtonProps = OwnProps & SetPropsInterface<SetPropsProps>;

export const getInitialProps = () => ({
  confirm: false,
});

export class ConfirmButton extends React.PureComponent<ConfirmButtonProps> {
  public render() {
    const { confirm, content = 'Remove', onConfirm, disabled } = this.props;

    return (
      <Highlight backgroundColor="white" open={confirm}>
        {!confirm ? (
          <AppButton
            key="deleteButton"
            link
            onClick={this.toggleConfirm}
            id="delete-button-toggle"
            disabled={disabled}
          >
            {content}
          </AppButton>
        ) : (
          <SpacedGroup>
            <AppButton
              link
              onClick={this.toggleConfirm}
              id="delete-button-cancel"
            >
              Cancel
            </AppButton>
            <AppButton
              key="deleteButton"
              link
              destructive
              onClick={onConfirm}
              id="delete-button-confirm"
            >
              Confirm {content}
            </AppButton>
          </SpacedGroup>
        )}
      </Highlight>
    );
  }

  public toggleConfirm = () => {
    this.props.setProps({ confirm: !this.props.confirm });
  };
}

export default withSetProps<SetPropsProps, OwnProps>(getInitialProps)(
  ConfirmButton
);

import { anyPending } from '@dabapps/redux-requests';
import { Container, Tab, Tabs } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import ActivitiesListComponent from '^/activities/browser';
import ContactPopups from '^/activities/popups';
import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AllowRoute from '^/common/allow-route';
import AppButton from '^/common/app-button';
import { ErrorPage } from '^/common/error-page';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import { contactActions, getContact } from '^/contacts/actions';
import ArchiveContact from '^/contacts/archive-contact';
import PracticeDetailsTab from '^/contacts/companies/practices/tabs/details-tab';
import ContactFilesTab from '^/contacts/companies/practices/tabs/files-tab';
import ContactPatientsTab from '^/contacts/companies/practices/tabs/patients-tab';
import ContactHeaderBar from '^/contacts/header-bar';
import LedgerTab from '^/contacts/ledger/ledger-tab';
import { ContactStatus } from '^/contacts/types';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { contactMissingDetail, getItemFromCache } from '^/utils/cache-helpers';
import FormCustomisationOptionsTab from './tabs/form-customisation-options-tab';

export type PracticesDetailPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

class PracticesDetailPage extends React.PureComponent<
  PracticesDetailPageProps
> {
  public componentDidMount() {
    const {
      practice,
      loading,
      match: { params },
    } = this.props;

    if (!loading.details && contactMissingDetail(practice)) {
      this.props.getContact(params.contactId);
    }
  }

  public render() {
    const {
      loading,
      practice,
      showFilesTab,
      showFormSetup,
      match: {
        params: { contactId },
      },
    } = this.props;

    if (!practice) {
      if (loading.details) {
        return (
          <PageContent>
            <BreadcrumbBar
              items={[['Practices', '/practices'], 'Loading...']}
            />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Practice not found" />;
    }

    return (
      <PageContent>
        <BreadcrumbBar
          items={[['Practices', '/practices'], getContactName(practice)]}
        />
        <ContactHeaderBar
          contact={practice}
          primaryActions={
            <Allow
              roles={[
                UserRole.AdminLevel,
                UserRole.OfficeLevel,
                UserRole.FinanceLevel,
              ]}
            >
              <AppButton
                key="add-patient"
                primary
                leftIcon="plus"
                disabled={practice.status === ContactStatus.Archived}
                url={`/patients/create/?practiceId=${practice.id}`}
              >
                Add Patient
              </AppButton>
            </Allow>
          }
          secondaryActions={[
            <AppButton
              key="export-activities"
              url={`/api/activities/csv/?associated_contact=${practice.id}`}
              small
            >
              Export Practice Activities
            </AppButton>,
            <AppButton
              key="export-practice"
              url={`/api/letters/export-contact/${practice.id}/`}
              small
            >
              Export Practice for Letters
            </AppButton>,
            <ArchiveContact key="archive" contact={practice} />,
          ]}
          loading={loading.details}
        />
        <Container>
          <Tabs>
            <Tab>
              <NavLink to={`${this.props.match.url}/details`}>Details</NavLink>
            </Tab>
            <Allow
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
            >
              <Tab>
                <NavLink to={`${this.props.match.url}/patients`}>
                  Patients
                </NavLink>
              </Tab>
            </Allow>
            <Tab>
              <NavLink to={`${this.props.match.url}/ledger`}>Ledger</NavLink>
            </Tab>
            <Tab>
              <NavLink to={`${this.props.match.url}/activities`}>
                Record Activities
              </NavLink>
            </Tab>
            {showFilesTab && (
              <Tab>
                <NavLink to={`${this.props.match.url}/files`}>Files</NavLink>
              </Tab>
            )}
            {showFormSetup && (
              <Tab>
                <NavLink to={`${this.props.match.url}/form-setup`}>
                  Form Setup
                </NavLink>
              </Tab>
            )}
          </Tabs>

          <Switch>
            <Route
              path={`${this.props.match.path}/details`}
              component={PracticeDetailsTab}
            />
            <AllowRoute
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
              redirect={`${this.props.match.url}/details`}
              path={`${this.props.match.path}/patients`}
              component={ContactPatientsTab}
            />
            <Route
              path={`${this.props.match.path}/ledger`}
              component={LedgerTab}
            />
            <Route
              path={`${this.props.match.path}/activities/:activityId`}
              component={ActivitiesListComponent}
            />
            <Route
              path={`${this.props.match.path}/activities`}
              component={ActivitiesListComponent}
            />
            <AllowRoute
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.PracticeAdminLevel,
                UserRole.PracticeUserLevel,
              ]}
              redirect={`${this.props.match.url}/details`}
              path={`${this.props.match.path}/files`}
              component={ContactFilesTab}
            />
            <AllowRoute
              roles={[UserRole.AdminLevel, UserRole.PracticeAdminLevel]}
              path={`${this.props.match.path}/form-setup`}
              component={FormCustomisationOptionsTab}
            />
            <Route component={this.redirect} />
          </Switch>
        </Container>

        <ContactPopups contactId={contactId} />
      </PageContent>
    );
  }

  public redirect = () => <Redirect to={`${this.props.match.url}/details`} />;
}

/** Disconnected version of the component that is used for testing. */
export { PracticesDetailPage as TestablePracticesDetailPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  showFilesTab:
    state.loggedInUser?.role === UserRole.AdminLevel ||
    state.loggedInUser?.role === UserRole.FinanceLevel ||
    state.loggedInUser?.role === UserRole.PracticeAdminLevel,
  showFormSetup: state.loggedInUser?.role === UserRole.AdminLevel,
  practice: getItemFromCache(props.match.params.contactId, state.contactsCache),
  loading: {
    details: anyPending(state.responses, contactActions),
  },
});

const connector = connect(mapState, { getContact });

export default connector(PracticesDetailPage);

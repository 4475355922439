import React from 'react';
import { Switch } from 'react-router-dom';

import AllowRoute from '^/common/allow-route';
import DashboardPage from '^/iplan/dashboard/dashboard-page';
import PageContainer from '^/iplan/page-container';
import PatientsPage from '^/iplan/patients/patients-routes-page';
import PlanDetailsPage from '^/iplan/plans/plan-details-page';
import PracticeDetailsPage from '^/iplan/practice-details/practice-details-page';
import ReportsPage from '^/iplan/reports/reports-page';
import { uuidRegex } from '^/utils/commons';

export const IPlanRoutes = () => {
  return (
    <PageContainer>
      <Switch>
        <AllowRoute exact path="/" component={DashboardPage} />
        <AllowRoute path="/patients" component={PatientsPage} />
        <AllowRoute exact path="/reports" component={ReportsPage} />
        <AllowRoute
          exact
          path="/practice-details"
          component={PracticeDetailsPage}
        />
        <AllowRoute
          exact
          path={`/plans/:id(${uuidRegex})`}
          component={PlanDetailsPage}
        />
      </Switch>
    </PageContainer>
  );
};

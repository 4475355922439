import { AnyAction } from 'redux';

import { PaginatedArray } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';
import { GET_NOMINAL_CODES } from './actions';
import { NominalCodeResponse } from './types';

export function nominalCodes(
  state: PaginatedArray<NominalCodeResponse> | null = null,
  action: AnyAction
): PaginatedArray<NominalCodeResponse> | null {
  switch (action.type) {
    case GET_NOMINAL_CODES.SUCCESS:
      return paginatedReducer(state, action);
    default:
      return state;
  }
}

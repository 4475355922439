import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { Link } from 'react-router-dom';

import { getPrimaryPostcode, truncate } from '^/address/helpers';
import AppButton from '^/common/app-button';
import { getContactName, getPersonDob } from '^/common/helper-functions';
import {
  ContactResponse,
  getContactCategory,
  getContactCategoryName,
} from '^/contacts/types';

// Props that come from the parent component.
interface OwnProps {
  contacts: ReadonlyArray<ContactResponse>;
  showCategories?: boolean;
  hideDob?: boolean;
  onContactSelected?: (contact: ContactResponse) => void;
  onRemove?: (contact: ContactResponse) => void;
}

export type ContactsListProps = OwnProps;

class ContactsList extends React.PureComponent<ContactsListProps> {
  public render() {
    const {
      contacts,
      hideDob,
      showCategories,
      onContactSelected,
      onRemove,
    } = this.props;

    return (
      <Table fill>
        <TableHead>
          <TableRow>
            {onContactSelected && <TableHeader />}
            <TableHeader>Contact name</TableHeader>
            {showCategories && <TableHeader>Cat.</TableHeader>}
            {!hideDob && <TableHeader>DOB</TableHeader>}
            <TableHeader>CRM ID</TableHeader>
            <TableHeader>Postcode</TableHeader>
            {onRemove && <TableHeader />}
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact: ContactResponse) => (
            <TableRow key={contact.id}>
              {onContactSelected && (
                <TableCell className="align-left">
                  <AppButton
                    onClick={onContactSelected.bind(this, contact)}
                    primary
                    small
                  >
                    Select
                  </AppButton>
                </TableCell>
              )}
              <TableHeader title={getContactName(contact, true)}>
                <Link to={`/contacts/${contact.id}`}>
                  {truncate(getContactName(contact, false), 20)}
                </Link>
              </TableHeader>
              {showCategories && (
                <TableCell title={getContactCategoryName(contact)}>
                  {getContactCategory(contact)}
                </TableCell>
              )}
              {!hideDob && <TableCell>{getPersonDob(contact)}</TableCell>}
              <TableCell>{contact.crm_id}</TableCell>
              <TableCell>{getPrimaryPostcode(contact)}</TableCell>
              {onRemove && (
                <TableCell className="align-left">
                  <AppButton
                    onClick={onRemove.bind(this, contact)}
                    destructive
                    small
                  >
                    Remove
                  </AppButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default ContactsList;

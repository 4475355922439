import { anyPending, isPending } from '@dabapps/redux-requests';
import { Column, Container, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { GET_MEMBERSHIP, getMembership } from '^/admin/memberships/actions';
import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ConfirmationSideBarCard from '^/common/confirmation-sidebar-card';
import { ErrorPage } from '^/common/error-page';
import HeaderBar, {
  HeaderBarStatus,
  HeaderBarStatusColour,
} from '^/common/header-bar';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import Sidebar from '^/common/sidebar';
import SidebarCard, { SidebarCardSection } from '^/common/sidebar-card';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import { ContactType } from '^/contacts/types';
import MembershipSubscriptionForm from '^/memberships/subscriptions/form';
import BreadcrumbBar, { BreadcrumbBarItem } from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import {
  DELETE_PLAN_SUBSCRIPTION,
  deletePlanSubscription,
} from '^/plans/subscriptions/actions';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import { getMembershipName } from '../helpers';
import {
  GET_MEMBERSHIP_SUBSCRIPTION,
  getMembershipSubscription,
  SAVE_MEMBERSHIP_SUBSCRIPTION,
  saveMembershipSubscription,
} from './actions';
import { MembershipSubscription } from './types';

export type MembershipSubscriptionsEditPageProps = ConnectedProps<
  typeof connector
> &
  RouteComponentProps<{ id: string; contactId?: string }>;

class MembershipSubscriptionsEditPage extends React.PureComponent<
  MembershipSubscriptionsEditPageProps
> {
  public componentDidMount() {
    if (this.props.match.params.id) {
      this.props.getMembershipSubscription(this.props.match.params.id);
    }
  }

  public componentDidUpdate(prevProps: MembershipSubscriptionsEditPageProps) {
    const { subscription } = this.props;

    if (subscription && subscription !== prevProps.subscription) {
      subscription.contacts.forEach(contactSub =>
        this.props.getContact(contactSub.contact)
      );

      if (subscription.contacts[0].id) {
        this.props.getContact(subscription.contacts[0].id);
      }

      this.props.getMembership(subscription.plan!);
    }
  }

  public render() {
    const { subscription, contact, membership, loading } = this.props;

    if (!subscription || !contact || !membership) {
      if (loading.subscription || loading.contact || loading.membership) {
        return (
          <PageContent>
            <BreadcrumbBar items={this.getBreadcrumbItems()} />
            <HeaderBar
              title="Edit Membership Subscription"
              transparent
              loading
            />
            <Loading />
          </PageContent>
        );
      }

      return (
        <ErrorPage
          heading={`${!subscription ? 'Subscription' : 'Contact'} not found`}
        />
      );
    }

    const isHeadOffice =
      contact.type === ContactType.Company && membership.is_billing_container;

    return (
      <PageContent>
        <BreadcrumbBar items={this.getBreadcrumbItems()} />
        <HeaderBar
          title="Edit Membership Subscription"
          subtitle={getMembershipName(membership, true)}
          secondaryActions={[
            <AppButton
              key="export"
              url={`/api/letters/subscription-welcome/${subscription.id}/`}
              small
            >
              Export welcome letter
            </AppButton>,
          ]}
          transparent
          status={this.getHeadbarStatus(subscription)}
        >
          <span>
            You are editing a {isHeadOffice ? 'Head Office' : ''} subscription
            to{' '}
            <Link to={`/admin/memberships/${membership.id}`}>
              {getMembershipName(membership, true)}
            </Link>{' '}
            for contact {getContactName(contact)}.
          </span>
        </HeaderBar>
        <Container>
          <Row>
            <Column xs={12} md={7} lg={8}>
              <PageSection>
                <MembershipSubscriptionForm
                  actions={[SAVE_MEMBERSHIP_SUBSCRIPTION]}
                  initialValues={{
                    ...subscription,
                    lapse: Boolean(subscription.lapsed_date),
                  }}
                  membership={membership}
                  contact={contact}
                  onSubmit={this.handleSave}
                  onCancel={this.props.history.goBack}
                  disabledRef
                />
              </PageSection>
            </Column>
            <Sidebar>
              {!subscription.suspended ? (
                <Allow
                  roles={[
                    UserRole.AdminLevel,
                    UserRole.FinanceLevel,
                    UserRole.OfficeLevel,
                  ]}
                >
                  <SidebarCard heading="Suspend Subscription" transparent>
                    <SidebarCardSection>
                      <p>
                        Suspending this subscription
                        <strong> will pause all payments </strong> from this
                        subscriber.
                      </p>
                      <div className="buttons">
                        <AppButton
                          onClick={this.suspendSubscription}
                          destructive
                        >
                          Suspend Subscription
                        </AppButton>
                      </div>
                    </SidebarCardSection>
                  </SidebarCard>
                </Allow>
              ) : (
                <SidebarCard
                  heading="Subscription Suspended"
                  transparent
                  highlight
                >
                  <SidebarCardSection>
                    <Allow
                      roles={[
                        UserRole.AdminLevel,
                        UserRole.FinanceLevel,
                        UserRole.OfficeLevel,
                      ]}
                    >
                      <p>
                        Reactivate this subscription
                        <strong> to resume all payments </strong> from this
                        subscriber.
                      </p>
                      <div className="buttons">
                        <AppButton onClick={this.unsuspendSubscription} primary>
                          Activate Subscription
                        </AppButton>
                      </div>
                    </Allow>
                    <Allow roles={[UserRole.SalesLevel]}>
                      <p>
                        You do not have permission to edit this subscription.
                      </p>
                    </Allow>
                  </SidebarCardSection>
                </SidebarCard>
              )}
              <ConfirmationSideBarCard
                heading={'Delete Subscription'}
                onConfirm={this.deleteSubscription}
                confirmationText={
                  'Are you sure you want to delete this subscription?'
                }
                actionLabel={'Delete Subscription'}
                actions={[DELETE_PLAN_SUBSCRIPTION]}
              >
                <p>
                  Deleting a subscription will remove it from the system{' '}
                  <strong>permanently</strong>. It will no longer be usable and{' '}
                  <strong>cannot be restored</strong>.
                </p>
              </ConfirmationSideBarCard>
            </Sidebar>
          </Row>
        </Container>
      </PageContent>
    );
  }

  public getHeadbarStatus(
    subscription: MembershipSubscription
  ): HeaderBarStatus {
    if (subscription.lapsed) {
      return {
        colour: HeaderBarStatusColour.Red,
        description: 'Subscription is lapsed',
      };
    }

    if (subscription.lapsed_date) {
      return {
        colour: HeaderBarStatusColour.Yellow,
        description: `Subscription is due to lapse on ${subscription.lapsed_date}`,
      };
    }

    return {
      colour: HeaderBarStatusColour.Green,
      description: 'Subscription is active',
    };
  }

  public handleSave = (
    subscription: MembershipSubscription,
    dispatch: Dispatch
  ) =>
    saveMembershipSubscription(this.props.match.params.id, {
      ...subscription,
      suspended: undefined,
    })(dispatch).then(this.props.history.goBack);

  public suspendSubscription = () => {
    if (!this.props.subscription) {
      return;
    }
    const sub: MembershipSubscription = {
      ...this.props.subscription,
      suspended: true,
    };

    this.props.saveMembershipSubscription(this.props.match.params.id, sub);
  };

  public unsuspendSubscription = () => {
    if (!this.props.subscription) {
      return;
    }

    const sub: MembershipSubscription = {
      ...this.props.subscription,
      suspended: false,
    };

    this.props.saveMembershipSubscription(this.props.match.params.id, sub);
  };

  public deleteSubscription = async () => {
    if (!this.props.subscription) {
      return;
    }

    await this.props.deletePlanSubscription(this.props.subscription.id);
    this.props.history.goBack();
  };

  private getBreadcrumbItems = (): ReadonlyArray<
    BreadcrumbBarItem | string | [string, string]
  > => {
    const { contact, membership, loading } = this.props;

    return [
      !contact || contact.type !== ContactType.Practice
        ? {
            label: 'Records',
            url: '/records',
            loading: loading.subscription || loading.contact,
          }
        : {
            label: 'Practices',
            url: '/practices',
            loading: loading.subscription || loading.contact,
          },
      {
        label: getContactName(contact),
        url: contact ? `/contacts/${contact.id}` : undefined,
        loading: loading.subscription || loading.contact,
      },
      getMembershipName(membership, true),
    ];
  };
}

// Disconnected version used for testing
export { MembershipSubscriptionsEditPage as TestableMembershipSubscriptionsEditPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ id: string; contactId?: string }>
) => {
  const subscription = getItemFromCache(
    props.match.params.id,
    state.membershipSubscriptionCache
  );

  return {
    subscription,
    contact:
      subscription && subscription.contacts.length > 0
        ? getItemFromCache(
            props.match.params.contactId
              ? props.match.params.contactId
              : subscription.contacts[0].contact,
            state.contactsCache
          )
        : undefined,
    membership: subscription
      ? getItemFromCache(subscription.plan, state.membershipCache)
      : undefined,
    loading: {
      subscription: anyPending(state.responses, [GET_MEMBERSHIP_SUBSCRIPTION]),
      contact: anyPending(state.responses, [GET_CONTACT]),
      membership: isPending(state.responses, GET_MEMBERSHIP),
    },
  };
};

const connector = connect(mapState, {
  getMembershipSubscription,
  getContact,
  getMembership,
  saveMembershipSubscription,
  deletePlanSubscription,
});

export default connector(MembershipSubscriptionsEditPage);

import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  FieldArray,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import { StoreState } from '^/types';
import RenderSelectedMemberships from './render-selected-memberships';
import { MembershipSubscriptionInjectedProps } from './subscriptions/types';

interface OwnProps {
  contactId: string;
  heading?: string;
  onCloseModal(): void;
}

export type MembershipPickerModalProps = OwnProps &
  InjectedFormProps<MembershipSubscriptionInjectedProps, OwnProps> &
  ConnectedProps<typeof connector>;

const MembershipPickerModal: React.FC<MembershipPickerModalProps> = ({
  contactId,
  onCloseModal,
  handleSubmit,
  memberships,
  heading = 'Add Membership',
}) => {
  return (
    <Modal onClickOutside={onCloseModal}>
      <ModalHeader>
        <h2>{heading}</h2>
        <AppButton transparent onClick={onCloseModal}>
          <FontAwesomeIcon icon="times" />
        </AppButton>
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="modal-body-section scrollable">
            <h4>Fees</h4>
            <Form onSubmit={handleSubmit}>
              <FieldArray
                name="memberships"
                component={RenderSelectedMemberships}
                contactId={contactId}
              />
              <div className="form-buttons align-right">
                {memberships && memberships.length && (
                  <AppButton primary type="submit">
                    {`Create ${
                      memberships.length > 1
                        ? memberships.length + ' memberships'
                        : 'membership'
                    }`}
                  </AppButton>
                )}
              </div>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export { MembershipPickerModal as TestableMembershipPickerModal };

export const mapState = (state: StoreState) => {
  const selector = formValueSelector('addPracticeToMembershipForm');
  return {
    initialValues: { memberships: [] },
    memberships: selector(state, 'memberships'),
  };
};

const connector = connect(mapState, {});

export default reduxForm<MembershipSubscriptionInjectedProps, OwnProps>({
  form: 'addPracticeToMembershipForm',
})(connector(MembershipPickerModal));

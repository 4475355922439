import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { Address, AddressLabels } from '^/address/types';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderPostcodeField from '^/form-helpers/render-postcode-field';

interface OwnProps {
  onCancel(): void;
}

export type AddressFormProps = OwnProps & InjectedFormProps<Address, OwnProps>;

class AddressForm extends React.PureComponent<AddressFormProps> {
  public render() {
    const { handleSubmit, initialValues } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            label="Label"
            name="label"
            component={RenderDropdown}
            type="text"
            options={[
              { label: 'please choose...', value: '' },
              { label: AddressLabels.Business, value: AddressLabels.Business },
              {
                label: AddressLabels.HeadOffice,
                value: AddressLabels.HeadOffice,
              },
              { label: AddressLabels.Home, value: AddressLabels.Home },
              { label: AddressLabels.Practice, value: AddressLabels.Practice },
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="Postcode"
            name="postcode"
            component={RenderPostcodeField}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="Street address"
            name="street_address"
            component={RenderInputField}
            type="text"
          />
          <Field
            label="City"
            name="city"
            component={RenderInputField}
            type="text"
            short
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="County"
            name="county"
            component={RenderInputField}
            type="text"
          />
          <Field
            label="Country"
            name="country"
            component={RenderInputField}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="Phone number"
            name="phone_1"
            component={RenderInputField}
          />
          <Field
            label="Secondary phone number"
            name="phone_2"
            component={RenderInputField}
          />
        </FormGroup>
        <ErrorRenderer fields={['non_field_errors']} showStatusErrors />
        <div className="form-buttons">
          <AppButton type="button" onClick={this.props.onCancel}>
            Cancel
          </AppButton>
          <AppButton type="submit" primary>
            {initialValues.id ? 'Save address' : 'Add address'}
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { AddressForm as TestableAddressForm };

export default reduxForm<Address, OwnProps>({
  form: 'addressForm',
})(AddressForm);

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Line } from 'react-chartjs-2';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import { Uuid } from '^/common/types';
import {
  ColorLookup,
  Dashboard,
  TotalPatients as TypeTotalPatients,
} from '../types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ChartDataLabels
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
    },
    legend: {
      labels: {
        usePointStyle: true,
      },
      position: 'bottom',
      display: true,
    },
  },
};

const getLabels = (data: TypeTotalPatients) => {
  return data.total.map(item =>
    new Date(item.month).toLocaleString('default', { month: 'short' })
  );
};

const getDatasets = (data: Dashboard['total_patients']) => {
  const colorLookup: ColorLookup = {
    0: '#00B68A',
    1: '#37D5D7',
    2: '#F15930',
    3: '#ffffe5',
    5: '#f7fcb9',
    6: '#d9f0a3',
    7: '#addd8e',
    8: '#78c679',
    9: '#41ab5d',
    10: '#238443',
  };
  const datasets = data.plans.map((item, index) => {
    return {
      label: item.name,
      data: item.values.map(v => v.value),
      borderColor: colorLookup[index],
      backgroundColor: colorLookup[index],
    };
  });
  datasets.splice(0, 0, {
    label: 'Total',
    data: data.total.map(t => t.value),
    borderColor: '#FFA500',
    backgroundColor: '#FFA500',
  });
  return datasets;
};

export interface TotalPatientsProps {
  data?: TypeTotalPatients;
  loading?: boolean;
  practiceId?: Uuid;
}

class TotalPatients extends React.PureComponent<TotalPatientsProps> {
  public render() {
    const { data, loading, practiceId } = { ...this.props };
    if (loading) {
      return <Loading />;
    } else if (data) {
      const chartData = {
        labels: getLabels(data),
        datasets: getDatasets(data),
      };
      return (
        <>
          <h4>Total Patients</h4>
          <span className="subtitle">
            (Total count of unique active patients)
          </span>
          <div className="chart-container chart-container-first-row">
            <Line options={options as any} data={chartData} />
          </div>
          <AppButton
            className="iplan-app-button"
            url={`/api/iplan/dashboard/exports/total-patients?practice=${practiceId}`}
          >
            {`Download data >>`}
          </AppButton>
        </>
      );
    }
    return null;
  }
}

export { TotalPatients as TestableTotalPatients };

export default TotalPatients;

import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';

import { ErrorPage } from '^/common/error-page';
import { Loading } from '^/common/loading';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import { ContactType } from '^/contacts/types';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { contactMissingDetail, getItemFromCache } from '^/utils/cache-helpers';

export type ContactsDetailPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{
    contactId: string;
  }>;

class ContactsDetailPage extends React.PureComponent<ContactsDetailPageProps> {
  public componentDidMount() {
    const {
      loading,
      contact,
      match: { params },
    } = this.props;

    if (!loading && contactMissingDetail(contact)) {
      this.props.getContact(params.contactId);
    }
  }

  public render() {
    const { contact, loading, location } = this.props;

    if (loading) {
      return (
        <PageContent>
          <BreadcrumbBar
            items={[
              { label: 'Records', url: '/contacts' },
              { label: 'Loading...', loading: true },
            ]}
          />
          <Loading />
        </PageContent>
      );
    }

    if (!contact) {
      return <ErrorPage />;
    }

    return (
      <PageContent>
        <BreadcrumbBar
          items={[
            { label: 'Records', url: '/contacts' },
            { label: contact.type },
          ]}
        />
        {this.getRedirect(contact.type, location.pathname + location.search)}
      </PageContent>
    );
  }

  private getRedirect(type: ContactType, url: string) {
    switch (type) {
      case ContactType.Patient:
        return <Redirect to={url.replace('contacts', 'patients')} />;
      case ContactType.Practice:
        return <Redirect to={url.replace('contacts', 'practices')} />;
      case ContactType.Company:
        return <Redirect to={url.replace('contacts', 'companies')} />;
      case ContactType.Individual:
      default:
        return <Redirect to={url.replace('contacts', 'individuals')} />;
    }
  }
}

export { ContactsDetailPage as TestableContactsDetailPage };

export const mapState = (
  store: StoreState,
  props: RouteComponentProps<{
    contactId: string;
  }>
) => ({
  loading: anyPending(store.responses, [GET_CONTACT]),
  contact: getItemFromCache(props.match.params.contactId, store.contactsCache),
});

const connector = connect(mapState, {
  getContact,
});

export default connector(ContactsDetailPage);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import { generateSorts } from '^/contacts/actions';
import { FilterList } from '^/filters/types';
import { SortList } from '^/sorts/types';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const MEMBERSHIPS_SUMMARY_ENDPOINT = '/api/memberships/summary';

export const GET_MEMBERSHIPS_SUMMARY = makeAsyncActionSet(
  'GET_MEMBERSHIPS_SUMMARY'
);

export const getMembershipsSummary = (
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  sorting?: SortList,
  filters?: FilterList
) => {
  const queryString: string = generateQueryString({
    ...filters,
    ordering: generateSorts(sorting),
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  return (dispatch: Dispatch) =>
    request(
      GET_MEMBERSHIPS_SUMMARY,
      `${MEMBERSHIPS_SUMMARY_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize } }
    )(dispatch);
};

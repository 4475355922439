import { isPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';
import PageSection from '^/common/page-section/page-section';
import { isBackdateValid, required } from '^/common/validation';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropDown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import { StoreState } from '^/types';
import { GET_PRACTICE_PLANS } from '../actions';

export type PlanProps = ConnectedProps<typeof connector>;

export interface PlanState {
  selectedDate: Date | null;
}

class Plan extends React.Component<PlanProps, PlanState> {
  private initialDateValue: Date;

  constructor(props: PlanProps) {
    super(props);

    const todaysDate = moment();
    const hasReachedThreshold = todaysDate.toDate().getDate() >= 20;
    const initialDateValue = hasReachedThreshold
      ? todaysDate
          .add(2, 'M')
          .startOf('M')
          .toDate()
      : todaysDate.toDate();

    this.initialDateValue = initialDateValue;
  }

  public getOptions = () => {
    const { plans } = this.props;
    const options = plans?.map(p => ({
      label: p.description,
      value: p.id,
    }));
    if (options) {
      return [{ label: 'please choose...', value: '' }].concat(options);
    }
  };

  public render() {
    const { loading, plans } = this.props;
    return (
      <PageSection className="new-patient-section" heading="Plan Details">
        {!loading && plans && (
          <FormGroup>
            <Field
              label="Plan Name"
              name="plan"
              component={RenderDropDown}
              options={!loading && this.getOptions()}
              type="text"
            />
          </FormGroup>
        )}
        <FormGroup>
          <Field
            lockDate
            className="required"
            showMonthYearPicker
            label="First Payment Date"
            name="start_date"
            component={RenderDateField}
            validate={required}
            type="date"
            pickerProps={{
              minDate: this.initialDateValue,
            }}
          />
        </FormGroup>
        <FormGroup className="text-caption-light margin-top-small margin-bottom-small">
          If todays date is on or beyond the 20th of the current month, please
          choose your start date as two months ahead e.g Today is the 26th of
          March, so the earliest month you should select as the start date is
          May.
        </FormGroup>
        <FormGroup>
          <Field label="Backdate" name="backdate" component={RenderCheckBox} />
          <Field
            component={RenderInputField}
            label="Backdate (Months)"
            name="backdate_months"
            type="number"
            validate={[isBackdateValid]}
          />
        </FormGroup>
        <ErrorRenderer fields={['non_field_errors']} showStatusErrors />
      </PageSection>
    );
  }
}

export { Plan as TestablePlan };

export const mapState = (state: StoreState) => ({
  plans: state.iplan?.newPatientForm?.plans,
  loading: isPending(state.responses, GET_PRACTICE_PLANS),
});

const connector = connect(mapState);

export default connector(Plan);

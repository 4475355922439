import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { isPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { UPLOAD_ATTACHMENT, uploadAttachment } from '^/attachments/actions';
import AttachmentList from '^/attachments/list';
import { StoreState } from '^/types';

// Props that come from the parent component.
interface OwnProps {
  label?: string;
  contactId?: string;
  showAttachments?: boolean;
  allowedFileTypes?: string;
  onAttachmentUploaded?: () => void;
}

// State props that come from redux.
interface StateProps {
  removedAttachments: ReadonlyArray<string>;
  session: string;
}

export type RenderUploadFieldProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector> &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class RenderUploadField extends React.PureComponent<RenderUploadFieldProps> {
  public componentDidUpdate(prevProps: RenderUploadFieldProps) {
    const { attachmentsBySession, removedAttachments } = this.props;

    if (
      attachmentsBySession !== prevProps.attachmentsBySession ||
      removedAttachments !== prevProps.removedAttachments
    ) {
      this.refreshAttachments();
    }
  }

  public render() {
    const {
      label,
      loading,
      showAttachments = true,
      allowedFileTypes,
    } = this.props;

    return (
      <div className="form-field render-file-upload">
        <div className="form-field-label">
          {label && <label>{label}</label>}
        </div>
        {showAttachments && (
          <AttachmentList
            attachments={this.props.input.value}
            onRemove={this.handleRemove}
          />
        )}
        <label
          htmlFor="file-upload"
          className={classnames('add-file', { loading: loading.uploading })}
        >
          Add File...
        </label>
        <input
          accept={allowedFileTypes}
          id="file-upload"
          type="file"
          onChange={this.handleChange}
          disabled={loading.uploading}
        />
      </div>
    );
  }

  public handleRemove = (attachmentId: string) => {
    const { removedAttachments } = this.props;

    this.props.setProps({
      removedAttachments: [...removedAttachments, attachmentId],
    });
  };

  public handleChange = async (event: {
    target: { files: FileList | null };
  }) => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    const file = event.target.files[0];
    try {
      await this.props.uploadAttachment(
        file,
        this.props.session,
        this.props.contactId
      );
      if (this.props.onAttachmentUploaded) {
        this.props.onAttachmentUploaded();
      }
    } catch (e) {
      const errorDetail =
        e.errors && e.errors.file ? `${e} - ${e.errors.file}` : e;
      alert(
        'Failed to upload this file. Please try again later. ' + errorDetail
      );
    }
  };

  private refreshAttachments = () => {
    const {
      attachmentsBySession,
      meta: { initial = [] },
      removedAttachments,
      session,
    } = this.props;
    const attachments = attachmentsBySession[session] || {};

    this.props.input.onChange(
      [...initial, ...Object.keys(attachments)].filter(
        id => !removedAttachments.includes(id)
      )
    );
  };
}

// Disconnected version used for testing
export { RenderUploadField as TestableRenderUploadField };

export const getInitialProps = () => ({
  removedAttachments: [],
  session: moment().toISOString(),
});

export const mapState = (state: StoreState) => ({
  attachmentsBySession: state.attachmentsBySession,
  loading: {
    uploading: isPending(state.responses, UPLOAD_ATTACHMENT),
  },
});

const connector = connect(mapState, { uploadAttachment });

export default withSetProps<
  StateProps,
  OwnProps & React.InputHTMLAttributes<HTMLInputElement> & WrappedFieldProps
>(getInitialProps)(connector(RenderUploadField));

import { SimpleRecord } from '@dabapps/simple-records';

export interface Address {
  readonly id?: string;
  street_address: string;
  city?: string;
  county?: string;
  country?: string;
  postcode: string;
  label: string;
  phone_1?: string;
  phone_2?: string;
  fax?: string;
  contact: string;
  readonly contact_mailing_address?: string;
}

export type AddressResponse = Readonly<{
  id: string;
  street_address: string;
  city: string;
  county: string;
  country: string;
  postcode: string;
  label: string;
  phone_1: string;
  phone_2: string;
  fax: string;
  contact: string;
  readonly contact_mailing_address?: string;
}>;

export enum AddressLabels {
  Business = 'Business',
  HeadOffice = 'Head Office',
  Home = 'Home',
  Practice = 'Practice',
}

export const AddressResponseRecord = SimpleRecord<AddressResponse>({
  id: '',
  street_address: '',
  city: '',
  county: '',
  country: '',
  postcode: '',
  label: '',
  phone_1: '',
  phone_2: '',
  fax: '',
  contact: '',
});

export type LoqateFindResponse = Readonly<{
  Id: string; // e.g. GB|RM|A|52509479; This can be an address Id or a container Id for further results.
  Type?: string; // e.g. Address; If the Type is 'Address' then the Id can be passed to the Retrieve service. Any other Id should be passed as the Container to a further Find request to get more results.
  Text: string; // e.g. High Street; The name of the result.
  Highlight?: string; // e.g. 0-2,6-4;0-3; A list of number ranges identifying the matched characters in the Text and Description.
  Description: string; // e.g. London
}>;

export const LoqateFindResponseRecord = SimpleRecord<LoqateFindResponse>({
  Id: '',
  Text: '',
  Description: '',
});

export type LoqateRetrieveResponse = Readonly<{
  Id?: string; // The unique Id of the address.
  DomesticId?: string; // The unique Id of the address as it appears in its respective dataset.
  Language?: string; // The language of the address.
  LanguageAlternatives?: string; // Alternative languages for the address.
  Department?: string; // Department name.
  Company?: string; // Company name.
  SubBuilding?: string; // The name of the sub building (e.g. flat, unit).
  BuildingNumber?: string; // The number of building.
  BuildingName?: string; // The associated name of the building.
  SecondaryStreet?: string; // The secondary thoroughfare name. Usually a small street off the primary.
  Street?: string; // The main thoroughfare name.
  Block?: string; // The block details for the address.
  Neighbourhood?: string; // The neighbourhood the address is in.
  District?: string; // District name.
  City: string; // City name.
  Line1: string; // The first line of the formatted address.
  Line2?: string; // The second line of the formatted address.
  Line3?: string; // The third line of the formatted address.
  Line4?: string; // The fourth line of the formatted address.
  Line5?: string; // The fifth line of the formatted address.
  AdminAreaName?: string; // The name of the administrative area for the address.
  AdminAreaCode?: string; // The code of the administrative area for the address.
  Province: string; // Province / county.
  ProvinceName?: string; // Province / county.
  ProvinceCode?: string; // Associated province code.
  PostalCode: string; // Postal code.
  CountryName: string; // Name of the country the address is in.
  CountryIso2?: string; // 2 character ISO code for the country.
  CountryIso3?: string; // 3 character ISO code for the country.
  CountryIsoNumber?: number; // ISO numeric code for the country.
  SortingNumber1?: string;
  SortingNumber2?: string;
  Barcode?: string; // The barcode for the addres - needs to be used with an appropriate barcode font.
  POBoxNumber?: string; // PO Box number
  Label?: string; // The full address formatted as a label.
  Type?: string; // The type of address if known.
  DataLevel?: string; // The data level if known - depends on the data set the address originates from.
  Field1?: string; // The data from the field requested to appear here, otherwise blank. Similar for all FieldX responses.
  Field2?: string;
  Field3?: string;
  Field4?: string;
  Field5?: string;
  Field6?: string;
  Field7?: string;
  Field8?: string;
  Field9?: string;
  Field10?: string;
  Field11?: string;
  Field12?: string;
  Field13?: string;
  Field14?: string;
  Field15?: string;
  Field16?: string;
  Field17?: string;
  Field18?: string;
  Field19?: string;
  Field20?: string;
}>;

export const LoqateRetrieveResponseRecord = SimpleRecord<
  LoqateRetrieveResponse
>({
  City: '',
  Line1: '',
  Province: '',
  PostalCode: '',
  CountryName: '',
});

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { FormGroup, Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import ContactsPicker from '^/contacts/picker';
import { ContactResponse, ContactType } from '^/contacts/types';
import { FilterField, FilterList } from '^/filters/types';
import {
  CREATE_RELATIONSHIP,
  createRelationship,
} from '^/relationships/actions';
import { RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';

// Props that come from the parent component.
interface OwnProps {
  hideContacts?: ReadonlyArray<string>;
  filters?: FilterList;
  hideRecents?: boolean;
  hideDob?: boolean;
  label?: string;
  roles?: ReadonlyArray<string>;
  searchFields?: ReadonlyArray<FilterField>;
  showCategories?: boolean;
  types: { 0: RelationshipType } & ReadonlyArray<RelationshipType>;
  contactType?: ContactType;
  onContactSelected(
    contact: ContactResponse,
    type: RelationshipType,
    role?: string
  ): void;
  onCloseModal(): void;
}

// State props that come from redux.
interface StateProps {
  selectedRole?: string;
  selectedType?: RelationshipType;
}

export type RelationshipsAddModalProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class RelationshipsAddModal extends React.PureComponent<
  RelationshipsAddModalProps
> {
  public render() {
    const {
      hideContacts,
      label,
      loading,
      roles = [],
      selectedRole,
      selectedType = this.props.types[0],
      types,
    } = this.props;

    return (
      <Modal onClickOutside={this.props.onCloseModal}>
        <ModalHeader>
          <h2>{`Add ${label || selectedType} Relationship`}</h2>
          <AppButton transparent onClick={this.props.onCloseModal}>
            <FontAwesomeIcon icon="times" />
          </AppButton>
        </ModalHeader>
        <ModalBody className={classnames({ loading })}>
          {(roles.length > 1 || types.length > 1) && (
            <div className="modal-body-section">
              <FormGroup>
                {types.length > 1 && (
                  <div className="form-field">
                    <label>Contact Relationship</label>
                    <select
                      value={selectedType}
                      onChange={this.updateSelectedType}
                    >
                      {types.map((type, index) => (
                        <option key={index} value={type}>
                          {this.formatType(type)}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {roles.length > 1 && (
                  <div className="form-field">
                    <label>Contact Role</label>
                    <select
                      value={selectedRole}
                      onChange={this.updateSelectedRole}
                    >
                      <option value="">Please choose...</option>
                      {roles.map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </FormGroup>
            </div>
          )}
          <ContactsPicker
            {...this.props}
            onContactSelected={this.onContactSelected}
            hideContacts={hideContacts}
          />
          <ErrorRenderer
            fields={['non_field_errors']}
            actions={[CREATE_RELATIONSHIP]}
            showStatusErrors
          />
        </ModalBody>
      </Modal>
    );
  }

  public formatType = (type: RelationshipType) => {
    switch (type) {
      case RelationshipType.PerformerPractice:
        return 'Clinician (can be assigned to patients)';
      case RelationshipType.TeamMemberCompany:
        return 'Employee';
      default:
        return type;
    }
  };

  public updateSelectedType = (event: { target: { value: string } }) => {
    const relType = event.target.value as RelationshipType;

    this.props.setProps({
      selectedType: relType,
    });
  };

  public updateSelectedRole = (event: { target: { value: string } }) => {
    this.props.setProps({
      selectedRole: event.target.value,
    });
  };

  public onContactSelected = (contact: ContactResponse) => {
    const { selectedType = this.props.types[0], selectedRole } = this.props;

    if (selectedType) {
      this.props.onContactSelected(contact, selectedType, selectedRole);
    }
  };
}

// Disconnected version used for testing
export { RelationshipsAddModal as TestableRelationshipsAddModal };

export const getInitialProps = (): StateProps => ({});

export const mapState = (state: StoreState) => ({
  loading: anyPending(state.responses, [CREATE_RELATIONSHIP]),
});

const connector = connect(mapState, { createRelationship });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(RelationshipsAddModal)
);

import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import Loading from '^/common/loading';
import { GET_CODE_CONTACT, getCodeContact } from '^/contacts/actions';
import {
  CREATE_PRACTICE_INSURANCE,
  createPracticeInsurance,
  GET_PRACTICE_INSURANCE,
  getPracticeInsurance,
  SAVE_PRACTICE_INSURANCE,
  savePracticeInsurance,
} from '^/insurance/actions';
import { Insurance } from '^/insurance/types';
import { StoreState } from '^/types';
import { cachedItemHasExpired } from '^/utils/cache-helpers';
import InspracForm from './insprac-form';

const INSPRAC_DEFAULTS: Partial<Insurance> = {
  monthly_fee: { vat_code: 1, nominal_code: '4506' },
  insurance_tax: 6,
};

export type InspracConfigProps = ConnectedProps<typeof connector>;

class InspracConfig extends React.PureComponent<InspracConfigProps> {
  public componentDidMount() {
    const { codeContact, inspracConfig } = this.props;

    if (cachedItemHasExpired(codeContact)) {
      this.props.getCodeContact();
    } else if (codeContact && cachedItemHasExpired(inspracConfig)) {
      this.props.getPracticeInsurance(codeContact.id);
    }
  }

  public componentDidUpdate(prevProps: InspracConfigProps) {
    const { codeContact } = this.props;

    if (codeContact && codeContact !== prevProps.codeContact) {
      this.props.getPracticeInsurance(codeContact.id);
    }
  }

  public render() {
    const { codeContact, inspracConfig, loading } = this.props;

    if (loading.contact || loading.insurance) {
      return (
        <>
          <h4>INSPRAC Cover</h4>
          <Loading />
        </>
      );
    }

    if (!codeContact) {
      return (
        <>
          <h4>INSPRAC Cover</h4>
          <p className="error">
            Couldn't find the CODE contact and therefore cannot find or create
            INSPRAC configuration.
          </p>
        </>
      );
    }

    return (
      <>
        <h4>INSPRAC Cover</h4>
        <InspracForm
          actions={[CREATE_PRACTICE_INSURANCE, SAVE_PRACTICE_INSURANCE]}
          initialValues={inspracConfig || INSPRAC_DEFAULTS}
          onSubmit={this.handleSave}
        />
      </>
    );
  }

  public handleSave = (insurance: Insurance, dispatch: Dispatch) => {
    const { codeContact } = this.props;

    if (insurance.id) {
      return savePracticeInsurance(insurance.id, insurance)(dispatch);
    }

    if (!codeContact) {
      throw new SubmissionError({
        non_field_errors: 'Missing CODE contact. Form submitted too early.',
      });
    }

    return createPracticeInsurance({
      ...insurance,
      practice: codeContact.id,
    })(dispatch);
  };
}

// Disconnected version used for testing
export { InspracConfig as TestableInspracConfig };

export const mapState = (state: StoreState) => ({
  codeContact: state.codeContact,
  inspracConfig: state.inspracConfig,
  loading: {
    contact: anyPending(state.responses, [GET_CODE_CONTACT]),
    insurance: anyPending(state.responses, [
      GET_PRACTICE_INSURANCE,
      CREATE_PRACTICE_INSURANCE,
      SAVE_PRACTICE_INSURANCE,
    ]),
    saving: anyPending(state.responses, [
      CREATE_PRACTICE_INSURANCE,
      SAVE_PRACTICE_INSURANCE,
    ]),
  },
});

const connector = connect(mapState, {
  getCodeContact,
  getPracticeInsurance,
});

export default connector(InspracConfig);

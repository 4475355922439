import { Footer as RoeFooter } from '@dabapps/roe';
import * as React from 'react';

const Footer = () => {
  return (
    <RoeFooter>
      <p>Agilio iPlan 2023</p>
      <a href={window.TCS_PDF} target="_blank">
        Terms & Conditions
      </a>
    </RoeFooter>
  );
};

export default Footer;

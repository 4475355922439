import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { GET_TEMPLATES, getTemplates } from '^/admin/templates/actions';
import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';
import { getPlanName } from './helpers';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  disabled?: boolean;
}

// State props that come from redux.
interface StateProps {
  selectedTemplate?: string;
}

export type PlanAddButtonProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class PlanAddButton extends React.PureComponent<PlanAddButtonProps> {
  public componentDidMount(): void {
    const { loading, templates: memberships } = this.props;

    if (!loading && paginatedArrayHasExpired(memberships, 100)) {
      this.props.getTemplates(1, 100);
    }
  }

  public render() {
    const { templates, selectedTemplate, loading, disabled } = this.props;

    return (
      <Allow
        roles={[
          UserRole.AdminLevel,
          UserRole.FinanceLevel,
          UserRole.OfficeLevel,
        ]}
      >
        <div
          className={classnames('subscriptions-membership-add-button', {
            ['no-selection']: !selectedTemplate,
          })}
        >
          <div className="form-field short">
            <label>Plan template</label>
            <select value={selectedTemplate} onChange={this.onTemplateChange}>
              <option value="" disabled>
                {loading ? 'loading...' : 'please choose...'}
              </option>
              {getAllRetrievedPages(templates).map(template => (
                <option key={template.id} value={template.id}>
                  {getPlanName(template)}
                </option>
              ))}
            </select>
          </div>
          <AppButton
            disabled={!selectedTemplate || disabled}
            loading={loading}
            onClick={this.onClickAdd}
          >
            Add patient plan
          </AppButton>
        </div>
      </Allow>
    );
  }

  public onTemplateChange = (event: { target: { value: string } }) => {
    this.props.setProps({ selectedTemplate: event.target.value });
  };

  public onClickAdd = () => {
    const { contactId, selectedTemplate } = this.props;

    this.props.push(
      `/plans/create?contact=${contactId}&template=${selectedTemplate}`
    );
  };
}

// Disconnected version used for testing
export { PlanAddButton as TestablePlanAddButton };

export const getInitialProps = () => ({
  selectedTemplate: '',
});

export const mapState = (state: StoreState) => ({
  templates: state.templates,
  loading: anyPending(state.responses, [GET_TEMPLATES]),
});

const connector = connect(mapState, { getTemplates, push });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(PlanAddButton)
);

import { TableCell, TableHeader, TableRow } from '@dabapps/roe';
import React from 'react';

export interface SectionDetailProps {
  label: string;
  asRow?: boolean;
}

export const SectionDetail: React.SFC<SectionDetailProps> = ({
  label,
  children,
  asRow,
}) => {
  if (asRow) {
    return (
      <TableRow>
        <TableHeader>{label}</TableHeader>
        <TableCell>{children ? children : '-'}</TableCell>
      </TableRow>
    );
  }
  return (
    <div className="section-detail">
      <label>{label}</label>
      <div>{children}</div>
    </div>
  );
};

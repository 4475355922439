import { Collapse } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import PageSection from '^/common/page-section/page-section';
import NewPatientAddressForm from '../form/address';
import NewPatientClinicianForm from '../form/clinician';
import NewPatientContactDetailsForm from '../form/contact';
import Declaration from '../form/declaration';
import NewPatientPaymentDetailsForm from '../form/payment';
import NewPatientPlanForm from '../form/plan';
import { Stepper } from '../stepper';

const PatientDetailsSection = () => {
  const [isContactDetailsPanelOpen, setIsContactDetailsPanelOpen] = useState(
    true
  );
  const onClickToggleCollapse = () =>
    setIsContactDetailsPanelOpen(!isContactDetailsPanelOpen);

  return (
    <PageSection className="new-patient-title" heading="Create New Patient">
      <div className="collapse-header" onClick={onClickToggleCollapse}>
        <h4>Primary Patient & Plan Details</h4>
        <FontAwesomeIcon
          icon={isContactDetailsPanelOpen ? 'chevron-up' : 'chevron-down'}
        />
      </div>
      <Collapse
        open={isContactDetailsPanelOpen}
        fadeOut
        fadeColor="#FFF"
        fadeHeight={50}
        animationDuration={200}
      >
        <NewPatientContactDetailsForm />
        <NewPatientAddressForm />
        <NewPatientPaymentDetailsForm />
        <NewPatientClinicianForm />
        <NewPatientPlanForm />
        <Declaration />
        <Stepper stepOneActive />
      </Collapse>
    </PageSection>
  );
};

export default PatientDetailsSection;

import moment from 'moment';
import { AnyAction } from 'redux';

import {
  GET_PRACTICE_USER,
  GET_PRACTICE_USERS,
  GET_USER,
  GET_USERS,
} from '^/admin/users/actions';
import { UserResponse } from '^/admin/users/types';
import { DetailedCache, PaginatedArray } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

export function users(
  state: PaginatedArray<UserResponse> | null = null,
  action: AnyAction
): PaginatedArray<UserResponse> | null {
  switch (action.type) {
    case GET_USERS.SUCCESS:
      return paginatedReducer(state, action);
    default:
      return state;
  }
}

export function usersCache(
  state: DetailedCache<UserResponse> = {},
  action: AnyAction
): DetailedCache<UserResponse> {
  switch (action.type) {
    case GET_USER.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
          retrieved: moment(),
        },
      };
    default:
      return state;
  }
}

export function practiceUsersCache(
  state: DetailedCache<UserResponse> = {},
  action: AnyAction
): DetailedCache<UserResponse> {
  switch (action.type) {
    case GET_PRACTICE_USER.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
          retrieved: moment(),
        },
      };
    default:
      return state;
  }
}

export function practiceUsers(
  state: PaginatedArray<UserResponse> | null = null,
  action: AnyAction
): PaginatedArray<UserResponse> | null {
  switch (action.type) {
    case GET_PRACTICE_USERS.SUCCESS:
      return paginatedReducer(state, action);
    default:
      return state;
  }
}

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const NOMINAL_CODES_ENDPOINT = '/api/nominal-codes';

export const GET_NOMINAL_CODES = makeAsyncActionSet('GET_NOMINAL_CODES');
export function getNominalCodes(
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString = generateQueryString({
    page,
    page_size: pageSize,
  });

  return (dispatch: Dispatch) => {
    request(
      GET_NOMINAL_CODES,
      `${NOMINAL_CODES_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize } }
    )(dispatch);
  };
}

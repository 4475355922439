import { AnyAction } from 'redux';

import {
  GET_BULK_REPORT_DETAIL,
  GET_REPORTS_BY_TYPE,
} from '^/routines/actions';
import { BulkReport, ReportCategory } from '^/routines/types';
import { StoreState } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

const initialState: StoreState['reportsByType'] = {
  [ReportCategory.Generic]: {},
  [ReportCategory.Plan]: {},
  [ReportCategory.Membership]: {},
};

export function reportsByType(
  state: StoreState['reportsByType'] = initialState,
  action: AnyAction
): StoreState['reportsByType'] {
  switch (action.type) {
    case GET_REPORTS_BY_TYPE.SUCCESS:
      return {
        ...state,
        [action.meta.category]: {
          ...state[action.meta.category],
          [action.meta.type]: paginatedReducer(
            state[action.meta.category][action.meta.type],
            action
          ),
        },
      };
    default:
      return state;
  }
}

export function bulkReport(
  state: BulkReport | null = null,
  action: AnyAction
): BulkReport | null {
  switch (action.type) {
    case GET_BULK_REPORT_DETAIL.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import SidebarCard from '^/common/sidebar-card';
import { entriesAreValidForType } from '^/contacts/ledger/helpers';
import CreateCreditNoteModal from '^/contacts/ledger/modals/create-credit-note-modal';
import CreateInvoiceModal from '^/contacts/ledger/modals/create-invoice-modal';
import CreateLedgerEntryModal from '^/contacts/ledger/modals/create-ledger-entry-modal';
import CreateRejectModal from '^/contacts/ledger/modals/create-reject-modal';
import ProductInvoiceModal from '^/contacts/ledger/modals/product-invoice-modal';
import { LedgerEntryType, LedgerResponse } from '^/contacts/ledger/types';
import { closeModal, openModal } from '^/modals/actions';
import AllocationModal from './modals/allocation-modal';

// Props that come from the parent component.
interface OwnProps {
  selectedEntries: ReadonlyArray<LedgerResponse>;
  contactId: string;
  isHeadOffice?: boolean;
}

export type LedgerActionsCardProps = OwnProps &
  ConnectedProps<typeof connector>;

class LedgerActionsCard extends React.PureComponent<LedgerActionsCardProps> {
  public render() {
    const { selectedEntries } = this.props;
    return (
      <SidebarCard heading="Actions" transparent>
        <div className="sidebar-card-section flex-row form-buttons">
          <Allow
            roles={[
              UserRole.AdminLevel,
              UserRole.FinanceLevel,
              UserRole.OfficeLevel,
            ]}
          >
            <AppButton
              disabled={
                !entriesAreValidForType(selectedEntries, 'Product Invoice')
              }
              onClick={this.openProductModal}
              small
            >
              Generate Product Invoice
            </AppButton>
          </Allow>
        </div>
        <div className="sidebar-card-section flex-row form-buttons">
          <Allow
            roles={[
              UserRole.AdminLevel,
              UserRole.FinanceLevel,
              UserRole.OfficeLevel,
            ]}
          >
            <AppButton
              disabled={
                !entriesAreValidForType(
                  selectedEntries,
                  LedgerEntryType.Invoice
                )
              }
              onClick={this.openInvoiceModal}
              small
            >
              Generate Invoice
            </AppButton>
          </Allow>
          <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
            <AppButton
              disabled={!entriesAreValidForType(selectedEntries, 'Allocation')}
              onClick={this.openAllocateModal}
              small
            >
              Allocate
            </AppButton>
          </Allow>
        </div>
        <div className="sidebar-card-section flex-row form-buttons">
          <Allow
            roles={[
              UserRole.AdminLevel,
              UserRole.FinanceLevel,
              UserRole.OfficeLevel,
            ]}
          >
            <AppButton
              disabled={
                !entriesAreValidForType(
                  selectedEntries,
                  LedgerEntryType.RecordReceipt
                )
              }
              onClick={this.openReceiptModal}
              small
            >
              Record Receipt
            </AppButton>
          </Allow>
          <Allow
            roles={[
              UserRole.AdminLevel,
              UserRole.FinanceLevel,
              UserRole.OfficeLevel,
            ]}
          >
            <AppButton
              disabled={
                !entriesAreValidForType(
                  selectedEntries,
                  LedgerEntryType.CreditNote
                )
              }
              onClick={this.openCreditNoteModal}
              small
            >
              Generate Credit Note
            </AppButton>
          </Allow>
        </div>
        <div className="sidebar-card-section flex-row form-buttons">
          <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
            <AppButton
              disabled={
                !entriesAreValidForType(
                  selectedEntries,
                  LedgerEntryType.RecordReject
                )
              }
              onClick={this.openRejectModal}
              small
            >
              Record Reject
            </AppButton>
          </Allow>
          <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
            <AppButton onClick={this.openRefundModal} small>
              Record Refund
            </AppButton>
          </Allow>
        </div>
      </SidebarCard>
    );
  }

  public openProductModal = () => {
    const { contactId } = this.props;

    this.props.openModal(<ProductInvoiceModal contactId={contactId} />);
  };

  public openInvoiceModal = () => {
    const { contactId, isHeadOffice = false } = this.props;
    this.props.openModal(
      <CreateInvoiceModal contactId={contactId} isHeadOffice={isHeadOffice} />
    );
  };

  public openAllocateModal = () => {
    const { contactId, selectedEntries } = this.props;

    this.props.openModal(
      <AllocationModal contactId={contactId} entry={selectedEntries[0]} />
    );
  };

  public openReceiptModal = () => {
    const { contactId, selectedEntries } = this.props;

    this.props.openModal(
      <CreateLedgerEntryModal
        contactId={contactId}
        type={LedgerEntryType.RecordReceipt}
        allocateToTypes={[LedgerEntryType.Invoice]}
        initialSelectedEntries={selectedEntries}
      />
    );
  };

  public openCreditNoteModal = () => {
    const { contactId, selectedEntries } = this.props;

    this.props.openModal(
      <CreateCreditNoteModal
        contactId={contactId}
        initialSelectedEntries={selectedEntries}
      />
    );
  };

  public openRefundModal = () => {
    const { contactId, selectedEntries } = this.props;

    this.props.openModal(
      <CreateLedgerEntryModal
        contactId={contactId}
        type={LedgerEntryType.RecordRefund}
        allocateToTypes={[
          LedgerEntryType.CreditNote,
          LedgerEntryType.RecordReceipt,
        ]}
        initialSelectedEntries={selectedEntries}
      />
    );
  };

  public openRejectModal = () => {
    const { contactId, selectedEntries } = this.props;

    this.props.openModal(
      <CreateRejectModal
        selectedEntries={selectedEntries}
        contactId={contactId}
        entryType={LedgerEntryType.RecordReject}
      />
    );
  };
}

// Disconnected version used for testing
export { LedgerActionsCard as TestableLedgerActionsCard };

const mapDispatch = {
  closeModal,
  openModal,
};

const connector = connect(undefined, mapDispatch);

export default connector(LedgerActionsCard);

import { AnyAction } from 'redux';

import {
  CREATE_MEMBERSHIP,
  GET_MEMBERSHIP,
  GET_MEMBERSHIPS,
  SAVE_MEMBERSHIP,
} from '^/admin/memberships/actions';
import { MembershipResponse } from '^/memberships/types';
import { DetailedCache, PaginatedArray } from '^/types';
import { createCachedItem, paginatedReducer } from '^/utils/pagination-helpers';

export function memberships(
  state: PaginatedArray<MembershipResponse> | null = null,
  action: AnyAction
): PaginatedArray<MembershipResponse> | null {
  switch (action.type) {
    case GET_MEMBERSHIPS.SUCCESS:
      return paginatedReducer(state, action);
    default:
      return state;
  }
}

export function membershipCache(
  state: DetailedCache<MembershipResponse> = {},
  action: AnyAction
): DetailedCache<MembershipResponse> {
  switch (action.type) {
    case GET_MEMBERSHIP.SUCCESS:
    case CREATE_MEMBERSHIP.SUCCESS:
    case SAVE_MEMBERSHIP.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: createCachedItem(action.payload.data),
      };
    default:
      return state;
  }
}

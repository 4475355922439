import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { ModalBody } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import { getCollections } from '^/collections/actions';
import CollectionForm from '^/collections/collection-form';
import { Collection } from '^/collections/types';
import { Loading } from '^/common/loading';
import CollectionPickerForm from '^/contacts/ledger/modals/forms/collection-picker-form';
import { closeModal, openModal } from '^/modals/actions';
import SimpleModal from '^/modals/simple-modal';
import { getSubscriptionName } from '^/plans/helpers';
import { GET_PLAN_SUBSCRIPTIONS } from '^/plans/subscriptions/actions';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';
import { createInvoice, getContactLedger } from '../actions';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  isHeadOffice: boolean;
}

interface StateProps {
  selectedSubscriptionId: string;
  mode: ModalMode;
  invoiceDetails: Collection | undefined;
}

export type CreateInvoiceModalProps = OwnProps &
  ConnectedProps<typeof connector> &
  SetPropsInterface<StateProps>;

export enum ModalMode {
  Select = 'SELECT',
  Generate = 'GENERATE',
}

class CreateInvoiceModal extends React.PureComponent<CreateInvoiceModalProps> {
  public componentDidMount() {
    this.props.getCollections(
      this.props.contactId,
      1,
      100,
      this.props.isHeadOffice
    );
  }

  public render() {
    const { mode, loading, invoiceDetails, collections } = this.props;

    if (!collections || loading) {
      return (
        <SimpleModal heading={this.renderTitle()}>
          <Loading />
        </SimpleModal>
      );
    }

    return (
      <SimpleModal heading={this.renderTitle()} size="large">
        {mode === ModalMode.Select ? (
          <ModalBody>
            <CollectionPickerForm
              collections={collections}
              onSubmit={this.onSubmitSubscriptionChoice}
            />
          </ModalBody>
        ) : (
          mode === ModalMode.Generate && (
            <ModalBody>
              {invoiceDetails && (
                <CollectionForm
                  invoiceMode
                  initialValues={{
                    ...invoiceDetails,
                    entry_date: new Date().toISOString(),
                  }}
                  onSubmit={this.onGenerateInvoice}
                  onClickBack={this.handleBackClicked}
                  subscriptionId={invoiceDetails.subscription}
                />
              )}
            </ModalBody>
          )
        )}
      </SimpleModal>
    );
  }

  public handleBackClicked = () =>
    this.props.setProps({ mode: ModalMode.Select });

  public renderTitle = () => {
    const { invoiceDetails, subscriptions } = this.props;
    const subscriptionDetail = invoiceDetails?.subscription_detail;

    if (subscriptionDetail && subscriptions) {
      const subscription = subscriptions.pages[1].find(
        sub => sub.id === subscriptionDetail.id
      );

      return `Generate invoice for ${getSubscriptionName(subscription)}`;
    }

    return 'Generate Invoice';
  };

  public onGenerateInvoice = (collection: Collection, dispatch: Dispatch) =>
    createInvoice(
      collection.id,
      collection.entry_date
    )(dispatch).then(response => {
      if (response && response.status === 201) {
        getContactLedger(this.props.contactId, {})(dispatch);
        this.props.closeModal();
      }
    });

  public onSubmitSubscriptionChoice = (col: { collection: string }) => {
    const { collections } = this.props;
    const collection = getAllRetrievedPages(collections).find(
      c => c.id === col.collection
    );
    if (collection) {
      this.props.setProps({ invoiceDetails: collection });
      this.props.setProps({ mode: ModalMode.Generate });
    }
  };

  public setSelectMode = () => {
    this.props.setProps({ mode: ModalMode.Select });
  };

  public setGenerateMode = () => {
    this.props.setProps({ mode: ModalMode.Generate });
  };
}

export const getInitialProps = (): StateProps => ({
  mode: ModalMode.Select,
  selectedSubscriptionId: '',
  invoiceDetails: undefined,
});

// Disconnected version used for testing
export { CreateInvoiceModal as TestableCreateInvoiceModal };

export const mapState = (state: StoreState, props: OwnProps) => ({
  loading: anyPending(state.responses, [GET_PLAN_SUBSCRIPTIONS]),
  subscriptions: state.planSubscriptions[props.contactId],
  collections: state.collections[props.contactId],
});

const connector = connect(mapState, {
  getCollections,
  openModal,
  closeModal,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(CreateInvoiceModal)
);

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import PracticesCreatePage from './create-page';
import PracticesDetailPage from './detail-page';
import PracticesListPage from './list-page';

class PracticesRoutes extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/create`} component={PracticesCreatePage} />
        <Route
          path={`${match.path}/:contactId`}
          component={PracticesDetailPage}
        />
        <Route component={PracticesListPage} />
      </Switch>
    );
  }
}

export default PracticesRoutes;

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import PageSubSection from '^/common/page-section/page-sub-section';
import { required } from '^/common/validation';
import { PAYMENT_METHOD_OPTIONS, PaymentMethod } from '^/contacts/ledger/types';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderCorrespondenceDropdown from '^/form-helpers/render-correspondence-dropdown';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderToggleSwitch from '^/form-helpers/render-toggle-switch';
import PaymentDetailsPicker from '^/payment-details/picker';
import { LapsedReason, PlanSubscription } from '^/plans/subscriptions/types';
import { Plan } from '^/plans/types';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import { formatFeeAmount, getPlanName } from '../helpers';
import SubscriptionContactsTable from './subscription-contacts/subscription-contacts-table';
/** Props that are passed into this component by the parent. */
interface OwnProps {
  plan: Plan;
  subscriptionId?: string;
  contacts?: ReadonlyArray<string>;
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  generalErrorFields?: ReadonlyArray<string>;
  /** Contact clicked through from */
  prevContact: string;
  /** Function to run on clicking cancel button */
  onCancel(): void;
}

const selector = formValueSelector('planSubscriptionForm');

export const mapState = (state: StoreState) => ({
  showLapseOptions: selector(state, 'lapse'),
  overrideFeesAtRenewal: selector(state, 'override_fees_at_renewal') || false,
  payer: selector(state, 'payer'),
  paymentMethod: selector(state, 'payment_method'),
  paymentDetailsId: selector(state, 'payment_details'),
  start_date: selector(state, 'start_date'),
  last_renewal_date: selector(state, 'last_renewal_date'),
  renewal_date: selector(state, 'renewal_date'),
  sub_length_months: selector(state, 'sub_length_months'),
  contactsCache: state.contactsCache,
  paymentDetailsCache: state.paymentDetailsCache,
  infinite: selector(state, 'infinite'),
});

const connector = connect(mapState);

/** All subscriptions form props combined. */
export type SubscriptionsFormProps = OwnProps &
  InjectedFormProps<PlanSubscription & { lapse: boolean }, OwnProps> &
  ConnectedProps<typeof connector>;

/**
 * Form that is used for both subscriptions and practices. Can be used in both a create and edit context. The prop 'onSubmit' is called when the save button is pressed.
 */
class PlanSubscriptionForm extends React.Component<SubscriptionsFormProps> {
  public componentDidMount() {
    const { sub_length_months } = this.props;

    if (sub_length_months >= 9999) {
      this.props.change('infinite', true);
    }
  }

  public componentDidUpdate(prevProps: SubscriptionsFormProps) {
    const { showLapseOptions, infinite } = this.props;

    if (
      showLapseOptions !== prevProps.showLapseOptions &&
      showLapseOptions === false
    ) {
      this.props.change('lapsed', false);
      this.props.change('lapsed_date', null);
      this.props.change('lapsed_reason', '');
    }

    if (infinite !== prevProps.infinite) {
      this.props.change('sub_length_months', infinite ? 9999 : 1);
    }

    if (
      this.props.last_renewal_date !== prevProps.last_renewal_date ||
      this.props.sub_length_months !== prevProps.sub_length_months
    ) {
      this.props.change('renewal_date', this.getRenewalDate());
    }
  }

  public render() {
    const {
      handleSubmit,
      submitting,
      plan,
      contacts,
      actions,
      generalErrorFields = [],
      initialValues,
      overrideFeesAtRenewal,
      showLapseOptions,
      onCancel,
      paymentMethod,
      contactsCache,
      payer,
      infinite,
    } = this.props;

    const feesEditable = plan.variable_pricing || overrideFeesAtRenewal;
    const selectedPayer = getItemFromCache(payer, contactsCache);

    return (
      <Form onSubmit={handleSubmit}>
        <PageSubSection vertical heading={this.generateHeading()}>
          <FormGroup
            className={classNames('spacious', { bordered: showLapseOptions })}
          >
            <Field
              component={RenderInputField}
              name="reference"
              label="Subscription reference"
              placeholder={getPlanName(plan)}
            />
            <Field
              component={RenderDateField}
              name="start_date"
              label="Start date"
              short
            />
            <Field
              component={RenderCheckBox}
              name="lapse"
              label="Lapse this subscription"
              vertical
            />
          </FormGroup>
          {showLapseOptions && (
            <>
              <p className="spacious">
                Please enter a <strong>date</strong> and <strong>reason</strong>{' '}
                for the lapse:
              </p>
              <FormGroup className="spacious">
                <Field
                  component={RenderDateField}
                  name="lapsed_date"
                  label="Lapse date"
                  short
                />
                <Field
                  component={RenderDropdown}
                  name="lapsed_reason"
                  label="Lapse reason"
                  options={[
                    {
                      label: 'Please choose...',
                      value: '',
                    },
                    {
                      label: 'Cancelled without notice',
                      value: LapsedReason.CancelledWithoutNotice,
                    },
                    {
                      label: 'Cancelled by practice',
                      value: LapsedReason.CancelledByPractice,
                    },
                    {
                      label: 'Cancelled with notice',
                      value: LapsedReason.CancelledWithNotice,
                    },
                    {
                      label: 'Deceased',
                      value: LapsedReason.Deceased,
                    },
                    {
                      label: 'Plan Complete',
                      value: LapsedReason.PlanComplete,
                    },
                    {
                      label: 'Lapsed for Adjustment',
                      value: LapsedReason.LapsedForAdjustment,
                    },
                    {
                      label: 'Non-starter, money refunded',
                      value: LapsedReason.NotStartedRefunded,
                    },
                    {
                      label: 'Non-starter, direct debit failed',
                      value: LapsedReason.NotStartedFailed,
                    },
                    {
                      label: 'Plan discount no longer appropriate',
                      value: LapsedReason.DiscountCancelled,
                    },
                    {
                      label: 'Non-starter, changed minds',
                      value: LapsedReason.NonStarter,
                    },
                    {
                      label: 'Payment stopped, cheque bounced',
                      value: LapsedReason.PaymentStoppedChqBounced,
                    },
                    {
                      label: 'Payment stopped, direct debit cancelled',
                      value: LapsedReason.PaymentStoppedDDCancelled,
                    },
                    {
                      label: 'Not renewed',
                      value: LapsedReason.NotRenewed,
                    },
                  ]}
                />
              </FormGroup>
            </>
          )}
        </PageSubSection>
        {initialValues.id && (
          <SubscriptionContactsTable
            subscriptionId={initialValues.id}
            disabled={showLapseOptions}
            payer_id={payer}
          />
        )}
        <PageSubSection vertical heading="Fees">
          {!plan.template_overrides_plan && (
            <FormGroup className="spacious bordered">
              <Field
                component={RenderCheckBox}
                name="override_fees_at_renewal"
                label="Override standard fees at invoice"
                type="checkbox"
                disabled={plan.variable_pricing}
              />
            </FormGroup>
          )}
          <FormGroup className="spacious bordered">
            {feesEditable ? (
              <Field
                component={RenderCurrencyField}
                name="fee.amount"
                label="Subscription fee"
                shorter
                type="number"
                stretch
              />
            ) : (
              <div className="form-field shorter">
                <label>Subscription fee</label>
                <p>
                  {formatFeeAmount(
                    initialValues.calculated_fees
                      ? initialValues.calculated_fees.fee
                      : initialValues.fee
                  )}
                </p>
              </div>
            )}
            {feesEditable ? (
              <Field
                component={RenderCurrencyField}
                name="joining_fee.amount"
                shorter
                type="number"
                label="Joining fee"
                stretch
              />
            ) : (
              <div className="form-field shorter">
                <label>Joining fee</label>
                <p>
                  {formatFeeAmount(
                    initialValues.calculated_fees
                      ? initialValues.calculated_fees.joining_fee
                      : initialValues.joining_fee
                  )}
                </p>
              </div>
            )}
            <div className="form-field shorter">
              <label>Admin fee</label>
              <p>
                {formatFeeAmount(
                  initialValues.calculated_fees
                    ? initialValues.calculated_fees.admin_fee
                    : initialValues.admin_fee
                )}
              </p>
            </div>
          </FormGroup>
          <FormGroup className="spacious">
            <Field
              component={RenderInputField}
              name="sub_length_months"
              label="Subscription period"
              type="number"
              shorter
              disabled={infinite}
            />
            <Field
              component={RenderCheckBox}
              name="infinite"
              label="Infinite"
              short
              vertical
            />
            <Field
              component={RenderInputField}
              name="payment_interval_months"
              label="Invoice frequency"
              type="number"
              shorter
            />
          </FormGroup>
        </PageSubSection>
        <PageSubSection vertical heading="Renewal">
          <FormGroup className="spacious">
            <Field
              component={RenderDateField}
              name="last_renewal_date"
              label="Last renewal"
              shorter
            />
            <Field
              component={RenderDateField}
              name="renewal_date"
              label="Next renewal"
              shorter
            />
            <Field
              component={RenderCheckBox}
              name="auto_renew"
              label="Auto renew at expiry?"
              type="checkbox"
              vertical
            />
          </FormGroup>
        </PageSubSection>
        <PageSubSection vertical heading="Payer Details">
          <FormGroup className="spacious bordered">
            <Field
              component={RenderToggleSwitch}
              name="payment_method"
              label="Payment method"
              defaultValue={PaymentMethod.DirectDebit}
              options={PAYMENT_METHOD_OPTIONS}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={PaymentDetailsPicker}
              name="payer"
              contacts={contacts}
              validate={[required]}
              showAccount={
                paymentMethod === PaymentMethod.DirectDebit ||
                paymentMethod === PaymentMethod.StandingOrder
              }
            />
          </FormGroup>
        </PageSubSection>
        {selectedPayer && (
          <PageSubSection vertical heading="Correspondence">
            <FormGroup className="correspondence-selector">
              <Field
                label="Reminder to"
                component={RenderCorrespondenceDropdown}
                name="reminder_to"
                contacts={[selectedPayer]}
              />
              <Field
                label="Invoice to"
                component={RenderCorrespondenceDropdown}
                name="invoice_to"
                contacts={[selectedPayer]}
              />
              <Field
                label="Invoice made out to"
                component={RenderCorrespondenceDropdown}
                name="invoice_made_out_to"
                contacts={[selectedPayer]}
              />
            </FormGroup>
          </PageSubSection>
        )}
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...generalErrorFields]}
          showStatusErrors
        />
        <Allow
          roles={[
            UserRole.AdminLevel,
            UserRole.FinanceLevel,
            UserRole.OfficeLevel,
          ]}
        >
          <div className="form-buttons">
            <AppButton onClick={onCancel} disabled={submitting}>
              Cancel
            </AppButton>
            <AppButton primary type="submit" loading={submitting}>
              {initialValues && initialValues.id
                ? 'Verify Changes & Save'
                : 'Create Subscription'}
            </AppButton>
          </div>
        </Allow>
      </Form>
    );
  }

  private generateHeading = () => {
    const { initialValues, plan } = this.props;

    const title = plan && plan.code ? plan.code : initialValues.reference;
    const overdue =
      initialValues.overdue_amount === 0
        ? ''
        : `OVERDUE £${initialValues.overdue_amount}`;

    return `${title} - ${overdue}`;
  };

  private getRenewalDate = () => {
    if (!this.props.sub_length_months || !this.props.last_renewal_date) {
      return '';
    }

    const subLengthMonths = Number(this.props.sub_length_months);
    const lastRenewalDate = moment(this.props.last_renewal_date, 'YYYY-MM-DD');

    if (
      isNaN(subLengthMonths) ||
      subLengthMonths < 1 ||
      !lastRenewalDate.isValid() ||
      Number(subLengthMonths) >= 9999
    ) {
      return '';
    }

    return lastRenewalDate.add(subLengthMonths, 'months').format('YYYY-MM-DD');
  };
}

/** Disconnected version of the component that is used for testing. */
export { PlanSubscriptionForm as TestablePlanSubscriptionForm };

const connected = connector(PlanSubscriptionForm);

export const validate = (values: PlanSubscription & { lapse?: boolean }) => {
  const errors: { [field: string]: string } = {};

  if (values.lapse) {
    if (!values.lapsed_date) {
      errors.lapsed_date = 'Required';
    }

    if (!values.lapsed_reason) {
      errors.lapsed_reason = 'Required';
    }
  }

  return errors;
};

const form = reduxForm<PlanSubscription & { lapse: boolean }, OwnProps>({
  form: 'planSubscriptionForm',
  validate,
})(connected);

export default form;

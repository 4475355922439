import classnames from 'classnames';
import React from 'react';

export interface SidebarCardProps {
  heading?: string;
  buttons?: React.ReactNode;
  loading?: boolean;
  transparent?: boolean;
  childRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  highlight?: boolean;
}

class SidebarCard extends React.PureComponent<SidebarCardProps> {
  public render() {
    const {
      heading,
      buttons,
      children,
      highlight,
      loading,
      transparent,
      childRef,
      className,
    } = this.props;

    return (
      <div
        ref={childRef}
        className={classnames('sidebar-card', className, {
          loading,
          transparent,
          highlight,
        })}
      >
        {(heading || buttons) && (
          <div className="sidebar-card-header">
            {heading && <h4>{heading}</h4>}
            {buttons && (
              <div className="sidebar-card-header-buttons">{buttons}</div>
            )}
          </div>
        )}
        <div className="sidebar-card-detail">{children}</div>
      </div>
    );
  }
}

export const SidebarCardSection: React.SFC = props => (
  <div className="sidebar-card-section">{props.children}</div>
);

export { SidebarCard as TestableSidebardCard };

export default SidebarCard;

import { Container } from '@dabapps/roe';
import React from 'react';

import SettingsButton from '^/login/settings-button';
import { config } from '^/navigation/';
import AppSwitcher from '^/navigation/app-switcher';
import PracticeSelector from './practice-selector';

const { USE_APP_SWITCHER } = window;

const HeaderBar = () => {
  return (
    <header className="header-bar">
      <Container>
        <div className="header-bar-content-wrapper">
          <div className="header-bar-title-wrapper">
            {USE_APP_SWITCHER && (
              <div className="app-switcher app-switcher-iplan">
                <AppSwitcher config={config} />
              </div>
            )}
            <span className="header-bar-titles">
              <img className="logo" src="/static/agilio-iplan-logo.svg" />
            </span>
            <div className="header-bar-rightside-content">
              <PracticeSelector />
              <SettingsButton displayUserName />
            </div>
          </div>
          <div className="header-bar-content" />
        </div>
      </Container>
    </header>
  );
};

export default HeaderBar;

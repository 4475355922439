import { Collapse } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Error {
  message: string;
  code?: string;
}

export interface Props {
  errors: ReadonlyArray<
    | {
        [key: string]: ReadonlyArray<Error> | Error;
      }
    | {}
  >;
}

interface State {
  [index: number]: boolean;
}

export class TaskErrors extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.state = Object.assign(
      {},
      ...props.errors.map((_, index) => ({ [index]: index < 1 }))
    );
  }

  public render() {
    const { errors } = this.props;
    return (
      <div>
        {errors.map((row, index) => {
          if (Object.keys(row).length > 0) {
            return (
              <div key={index}>
                <div
                  className="collapse-header"
                  onClick={this.toggleCollapse}
                  data-error-index={index}
                >
                  <h4>
                    {errors.length > 1 ? (
                      <span>Row: {index + 2}</span>
                    ) : (
                      <span>Errors</span>
                    )}
                  </h4>
                  <FontAwesomeIcon
                    icon={this.state[index] ? 'chevron-up' : 'chevron-down'}
                  />
                </div>
                <Collapse
                  open={this.state[index]}
                  fadeOut
                  fadeHeight={50}
                  animationDuration={200}
                >
                  {Object.entries(row).map(([field, fieldErrors]) => (
                    <div className="errors" key={`${index}: ${field}`}>
                      <p>
                        <b>{field}</b>
                      </p>
                      {Array.isArray(fieldErrors)
                        ? fieldErrors.map(this.renderError)
                        : this.renderError(fieldErrors as Error)}
                    </div>
                  ))}
                </Collapse>
                {index !== errors.length - 1 && <hr />}
              </div>
            );
          } else {
            return <div key={index} />;
          }
        })}
      </div>
    );
  }

  private toggleCollapse(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const index = parseInt(event.currentTarget.dataset.errorIndex || '', 10);
    this.setState({
      ...this.state,
      [index]: !this.state[index],
    });
  }

  private renderError(error: Error, index?: number) {
    return (
      <div key={index || ''}>
        <p className="error">{error.message}</p>
      </div>
    );
  }
}

export default TaskErrors;

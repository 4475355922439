import { Column } from '@dabapps/roe';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

const SIDEBAR_SIZE = {
  xs: 12,
  md: 5,
  lg: 4,
};

class Sidebar extends React.PureComponent {
  public render() {
    return (
      <CSSTransitionGroup
        className="sidebar"
        component={Column}
        {...SIDEBAR_SIZE}
        transitionName="shrink"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {this.props.children}
      </CSSTransitionGroup>
    );
  }
}

// Disconnected version used for testing
export { Sidebar as TestableSidebar };

export default Sidebar;

import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { GET_CONTACT_LEDGER } from '^/contacts/ledger/actions';
import { LedgerEntryType } from '^/contacts/ledger/types';
import { FilterList } from '^/filters/types';
import { RenderCheckBox } from '^/form-helpers/render-checkbox';
import { RenderDateField } from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';

const actions = [GET_CONTACT_LEDGER];

interface OwnProps {
  loading?: boolean;
  onClear(): void;
}

export type LedgerFilterBarProps = OwnProps &
  InjectedFormProps<FilterList, OwnProps>;

export class LedgerFilterBar extends React.Component<LedgerFilterBarProps> {
  public render() {
    const { handleSubmit, loading, submitting, error } = this.props;

    return (
      <Form onSubmit={handleSubmit} className="ledger-date-picker-bar">
        <FormGroup>
          <Field
            label="TX_ID"
            name="tx_id"
            component={RenderInputField}
            type="number"
            vertical
            placeholder="12345678"
            shortest
          />
          <Field
            label="Start date"
            placeholder="01 01 2019"
            name="startDate"
            component={RenderDateField}
            shortest
          />
          <Field
            label="End date"
            placeholder="08 01 2019"
            name="endDate"
            component={RenderDateField}
            shortest
          />
          <Field
            label="Type"
            name="type"
            component={RenderDropdown}
            type="text"
            short
            options={[
              { label: 'Please Choose...', value: '' },
              {
                label: LedgerEntryType.RecordReceipt,
                value: LedgerEntryType.RecordReceipt,
              },
              {
                label: LedgerEntryType.RecordReject,
                value: LedgerEntryType.RecordReject,
              },
              {
                label: LedgerEntryType.Invoice,
                value: LedgerEntryType.Invoice,
              },
              {
                label: LedgerEntryType.CreditNote,
                value: LedgerEntryType.CreditNote,
              },
              {
                label: LedgerEntryType.RecordRefund,
                value: LedgerEntryType.RecordRefund,
              },
            ]}
          />
          <Field
            label="Outstanding"
            name="outstanding"
            component={RenderCheckBox}
            type="checkbox"
            vertical
          />
          <Field
            label="Unallocated"
            name="unallocated"
            component={RenderCheckBox}
            type="checkbox"
            vertical
          />
        </FormGroup>
        <ErrorRenderer
          error={[error]}
          actions={actions}
          fields={['non_field_errors']}
        />
        <div className="form-buttons">
          <AppButton
            type="button"
            onClick={this.clearFilters}
            disabled={loading}
          >
            Clear
          </AppButton>
          <AppButton primary type="submit" loading={loading || submitting}>
            Filter
          </AppButton>
        </div>
      </Form>
    );
  }

  public clearFilters = () => {
    this.props.reset();
    this.props.onClear();
  };
}

export default reduxForm<FilterList, OwnProps>({
  form: 'ledgerFilterForm',
})(LedgerFilterBar);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';
import { Collection } from './types';

export const COLLECTIONS_ENDPOINT = '/api/collections';

export const GET_COLLECTIONS = makeAsyncActionSet('GET_COLLECTIONS');
export function getCollections(
  contact: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  headOffice: boolean = false
) {
  return (dispatch: Dispatch) => {
    return request(
      GET_COLLECTIONS,
      `${COLLECTIONS_ENDPOINT}/?contact=${contact}&page=${page}&page_size=${pageSize}&head_office=${headOffice}`,
      'GET',
      undefined,
      { metaData: { contact, page, pageSize } }
    )(dispatch);
  };
}

export const GET_COLLECTION = makeAsyncActionSet('GET_COLLECTION');
export const getCollection = (id: string) => (dispatch: Dispatch) =>
  request(GET_COLLECTION, `${COLLECTIONS_ENDPOINT}/${id}/`, 'GET')(dispatch);

export const CREATE_COLLECTION = makeAsyncActionSet('CREATE_COLLECTION');
export const createCollection = (collection: Collection) => (
  dispatch: Dispatch
) =>
  request(CREATE_COLLECTION, `${COLLECTIONS_ENDPOINT}/`, 'POST', collection, {
    shouldRethrow: rethrowOnAnyError,
  })(dispatch).catch(throwSubmissionError);

export const SAVE_COLLECTION = makeAsyncActionSet('SAVE_COLLECTION');
export function saveCollection(collection: Collection) {
  return (dispatch: Dispatch) =>
    request(
      SAVE_COLLECTION,
      `${COLLECTIONS_ENDPOINT}/${collection.id}/`,
      'PATCH',
      collection,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch)
      .then(response => {
        getCollections(collection.contact)(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

export const DELETE_COLLECTION = makeAsyncActionSet('DELETE_COLLECTION');
export function deleteCollection(collection: Collection) {
  return (dispatch: Dispatch) =>
    request(
      DELETE_COLLECTION,
      `${COLLECTIONS_ENDPOINT}/${collection.id}/`,
      'DELETE',
      undefined,
      {
        tag: collection.id,
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch)
      .then(response => {
        getCollections(collection.contact)(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

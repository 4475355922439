import { anyPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { WrappedFieldProps } from 'redux-form';

import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import {
  GET_CONTACT,
  GET_PAYER_FOR_CONTACT,
  getContact,
  getPayerForContact,
} from '^/contacts/actions';
import { ContactResponse } from '^/contacts/types';
import { DropdownOptions } from '^/form-helpers/render-dropdown';
import RenderFormField from '^/form-helpers/render-form-field';
import {
  GET_LINKED_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_BY_CONTACT,
  getPaymentDetailsByContact,
} from '^/payment-details/actions';
import { formatSortCode } from '^/payment-details/helpers';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

// Props that come from the parent component.
interface OwnProps {
  contacts?: ReadonlyArray<string>;
  showAccount?: boolean;
  disabled?: boolean;
}

export type PaymentDetailsPickerProps = OwnProps &
  ConnectedProps<typeof connector> &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class PaymentDetailsPicker extends React.PureComponent<
  PaymentDetailsPickerProps
> {
  public componentDidMount() {
    const { meta, contactsCache, contacts = [] } = this.props;

    [...contacts, meta.initial].filter(Boolean).forEach(id => {
      if (contactsCache && cachedItemHasExpired(contactsCache[id])) {
        this.props.getContact(id);
      }

      this.props.getPayerForContact(id);
    });

    if (meta.initial) {
      this.props.getPaymentDetailsByContact(meta.initial);
    }
  }

  public componentDidUpdate(prevProps: PaymentDetailsPickerProps) {
    const {
      input: { value },
      contactsCache,
      contacts = [],
    } = this.props;

    if (contacts !== prevProps.contacts) {
      contacts.forEach(id => {
        if (contactsCache && cachedItemHasExpired(contactsCache[id])) {
          this.props.getContact(id);
        }

        this.props.getPayerForContact(id);
      });
    }

    if (
      value &&
      value !== prevProps.value &&
      contactsCache &&
      cachedItemHasExpired(contactsCache[value])
    ) {
      this.props.getContact(value);
    }
  }

  public render() {
    const { input, meta, loading, showAccount, disabled = false } = this.props;

    const options: DropdownOptions = this.getOptions();
    const currentPaymentDetails = this.getCurrentPaymentDetails();

    if (options.length < 1 && loading) {
      return <Loading />;
    }

    return (
      <div className="payment-details-picker">
        {currentPaymentDetails && (
          <div className="details">
            <p>
              Current payer:{' '}
              <Link to={`/contacts/${meta.initial}`}>
                {getContactName(currentPaymentDetails.contact_detail)}
              </Link>
            </p>
            {showAccount && (
              <p>
                Account no:{' '}
                {this.formatAccountNumber(currentPaymentDetails.account_number)}
              </p>
            )}
            {showAccount && (
              <p>
                Sort code: {formatSortCode(currentPaymentDetails.sort_code)}
              </p>
            )}
          </div>
        )}
        <FormGroup className="spacious">
          <p>
            Select a <b>{meta.initial ? 'replacement' : 'new'}</b> payer for
            this subscription.{' '}
            <span className="subtle">
              Only payers are available to select in the dropdown.
            </span>
          </p>
          <RenderFormField
            {...this.props}
            className={classnames({
              'no-selection': !input.value,
            })}
          >
            {options.length >= 1 ? (
              <>
                <select {...input} disabled={loading || disabled}>
                  <option value="">Select a payer...</option>
                  {options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <div className="error">
                No valid payers found. Check that the contact(s) for this
                subscription have at least one payer with payment details.
              </div>
            )}
          </RenderFormField>
        </FormGroup>
      </div>
    );
  }

  private getOptions = (): DropdownOptions => {
    const { contacts = [], contactPayer, contactsCache } = this.props;

    const contactPayers = contacts.map(id => ({
      contactId: id,
      contact: getItemFromCache(id, contactsCache),
      payer: getItemFromCache(id, contactPayer),
    }));

    const payeeGroups = contactPayers.reduce(
      (x: { [payer: string]: ContactResponse[] }, val) => {
        const payer = val.payer || val.contact;

        if (payer && val.contact) {
          x[payer.id]
            ? x[payer.id].push(val.contact)
            : (x[payer.id] = [payer, val.contact]);
        }

        return x;
      },
      {}
    );

    return Object.keys(payeeGroups).map((value: string) => {
      const payer = payeeGroups[value].find(c => c.id === value);
      const payees = payeeGroups[value].filter(c => c.id !== value);

      return {
        value,
        label: `${getContactName(payer)}${
          payees.length > 0
            ? ` (payer for ${payees.map(p => getContactName(p)).join(', ')})`
            : ''
        }`,
      };
    });
  };

  private formatAccountNumber(accountNumber: string) {
    if (accountNumber.length !== 8) {
      return 'Invalid';
    }

    return `******${accountNumber.slice(-2)}`;
  }

  private getCurrentPaymentDetails = () => {
    const { meta, paymentDetails } = this.props;

    return paymentDetails[meta.initial];
  };
}

// Disconnected version used for testing
export { PaymentDetailsPicker as TestablePaymentDetailsPicker };

export const mapState = (state: StoreState) => ({
  contactsCache: state.contactsCache,
  contactPayer: state.contactPayer,
  paymentDetails: state.paymentDetails,
  loading: anyPending(state.responses, [
    GET_LINKED_PAYMENT_DETAILS,
    GET_PAYER_FOR_CONTACT,
    GET_CONTACT,
    GET_PAYMENT_DETAILS_BY_CONTACT,
  ]),
});

const connector = connect(mapState, {
  getContact,
  getPayerForContact,
  getPaymentDetailsByContact,
});

export default connector(PaymentDetailsPicker);

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import { isContactType } from '^/common/helper-functions';
import {
  LIST_SIZE,
  recentlyViewedContacts,
} from '^/common/recently-viewed/helper-functions';
import PracticeRow from '^/contacts/companies/practices/row';
import { ContactType, StoreState } from '^/types';

export type RecentlyViewedPracticesTableProps = ConnectedProps<
  typeof connector
>;

export class RecentlyViewedPracticesTable extends React.PureComponent<
  RecentlyViewedPracticesTableProps
> {
  public render() {
    const filteredCache = this.filteredCache();

    return (
      <div className="recently-viewed-table">
        <h4>Recently Viewed</h4>
        <Table fill scrollable>
          <TableHead>
            <TableRow>
              <TableHeader>Practice</TableHeader>
              <Allow
                roles={[
                  UserRole.AdminLevel,
                  UserRole.FinanceLevel,
                  UserRole.OfficeLevel,
                ]}
              >
                <TableHeader>Patients</TableHeader>
              </Allow>
              <TableHeader>Practice ID</TableHeader>
              <TableHeader>Address</TableHeader>
              <TableHeader>Postcode</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCache.map(practice => (
              <PracticeRow key={practice.id} practiceResponse={practice} />
            ))}
          </TableBody>
        </Table>

        {filteredCache.length > 0 || (
          <div className="empty-table-state">No practices</div>
        )}
      </div>
    );
  }

  private filteredCache = () =>
    recentlyViewedContacts(this.props.contactsCache)
      .filter(contact => isContactType(contact, ContactType.Practice))
      .slice(0, LIST_SIZE);
}

export const mapState = ({ contactsCache }: StoreState) => ({
  contactsCache,
});

const connector = connect(mapState, {});

export default connector(RecentlyViewedPracticesTable);

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { TableBody, TableHead, TableRow } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { toggleSortFor } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import { FilterList } from '^/filters/types';
import TableHeaderSort from '^/sorts/table-header-sort';
import { SortList } from '^/sorts/types';
import { StoreState } from '^/types';
import {
  getCurrentPage,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';
import { GET_PRODUCTS, getProducts } from './actions';
import ProductRow from './row';

interface StateProps {
  appliedSorts: SortList;
}

export type ProductsTableProps = SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class ProductsTable extends React.PureComponent<ProductsTableProps> {
  public componentDidMount() {
    const { loading, products } = this.props;

    if (!loading && paginatedArrayHasExpired(products)) {
      this.props.getProducts();
    }
  }

  public componentDidUpdate(prevProps: ProductsTableProps) {
    const { products, appliedSorts } = this.props;

    if (appliedSorts !== prevProps.appliedSorts) {
      this.props.getProducts(
        products ? products.filters : undefined,
        appliedSorts
      );
    }
  }

  public render() {
    const { products, loading, appliedSorts, setProps } = this.props;

    return (
      <PaginatedTable
        paginatedData={products}
        changePage={this.changePage}
        loading={loading}
        primary
      >
        <TableHead>
          <TableRow>
            <TableHeaderSort
              sortOrder={appliedSorts.product_code}
              onClick={toggleSortFor.bind(
                this,
                'product_code',
                appliedSorts,
                setProps
              )}
            >
              Product Code
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.description}
              onClick={toggleSortFor.bind(
                this,
                'description',
                appliedSorts,
                setProps
              )}
            >
              Description
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.nominal_code}
              onClick={toggleSortFor.bind(
                this,
                'nominal_code',
                appliedSorts,
                setProps
              )}
            >
              Nominal Code
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.member_price}
              onClick={toggleSortFor.bind(
                this,
                'member_price',
                appliedSorts,
                setProps
              )}
            >
              Member Price
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.delivery}
              onClick={toggleSortFor.bind(
                this,
                'delivery',
                appliedSorts,
                setProps
              )}
            >
              Delivery
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.vat}
              onClick={toggleSortFor.bind(this, 'vat', appliedSorts, setProps)}
            >
              Vat Code
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.status}
              onClick={toggleSortFor.bind(
                this,
                'status',
                appliedSorts,
                setProps
              )}
            >
              Status
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.created}
              onClick={toggleSortFor.bind(
                this,
                'created',
                appliedSorts,
                setProps
              )}
            >
              Created
            </TableHeaderSort>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(products).map(product => (
            <ProductRow key={product.id} productResponse={product} />
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public changePage = (page: number, pageSize?: number, filters?: FilterList) =>
    this.props.getProducts(filters, this.props.appliedSorts, page, pageSize);
}

export { ProductsTable as TestableProductsTable };

export const getInitialProps = () => ({
  appliedSorts: {},
});

export const mapState = (state: StoreState) => ({
  products: state.products,
  loading: anyPending(state.responses, [GET_PRODUCTS]),
});

const connector = connect(mapState, { getProducts });

export default withSetProps<StateProps>(getInitialProps)(
  connector(ProductsTable)
);

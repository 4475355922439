import { SimpleRecord } from '@dabapps/simple-records';

export interface CompanyProfile {
  readonly id?: string;
  name: string;
  website?: string;
  email?: string;
  readonly contact?: string;
  readonly communication_method?: CompanyProfileCommunicationMethod;
  bacs_prefix?: string;
  primary_colour?: string;
  secondary_colour?: string;
  custom_text_form?: string;
  custom_text_landing?: string;
  unique_practice_url?: string;
  logo_url?: string;
  portal_active?: boolean;
  include_in_bulk_report?: boolean;
}

export type CompanyProfileResponse = Readonly<{
  id: string;
  public_id: string;
  name: string;
  website: string;
  email: string;
  contact: string;
  communication_method: CompanyProfileCommunicationMethod;
  bacs_prefix?: string;
  primary_colour?: string;
  secondary_colour?: string;
  custom_text_form?: string;
  custom_text_landing?: string;
  unique_practice_url?: string;
  logo_url?: string;
}>;

export enum CompanyProfileCommunicationMethod {
  SMS = 'SMS',
  Email = 'EMAIL',
  Post = 'POST',
}

export const CompanyProfileResponseRecord = SimpleRecord<
  CompanyProfileResponse
>({
  id: '',
  public_id: '',
  name: '',
  website: '',
  email: '',
  contact: '',
  communication_method: CompanyProfileCommunicationMethod.Post,
});

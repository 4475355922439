import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests/dist/js';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import PaginatedTable from '^/common/paginated-table';
import {
  createDiscount,
  GET_DISCOUNTS,
  getDiscounts,
  SAVE_DISCOUNT,
  saveDiscount,
} from '^/plans/actions';
import FamilyDiscountsForm from '^/plans/edit/family-discounts-form';
import { Discount, DiscountResponse } from '^/plans/types';
import { StoreState } from '^/types';
import {
  getCurrentPage,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

interface OwnProps {
  planId: string;
}

interface StateProps {
  /** Mode of the card, if undefined show list, else show edit form. */
  discountToEdit?: DiscountResponse | null;
}

/** All the props combined. */
export type FamilyDiscountsTableProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class FamilyDiscountsTable extends React.PureComponent<
  FamilyDiscountsTableProps
> {
  public componentDidMount() {
    if (
      !this.props.loading &&
      paginatedArrayHasExpired(this.props.discounts[this.props.planId])
    ) {
      this.props.getDiscounts(this.props.planId);
    }
  }

  public render() {
    const { discountToEdit, loading, discounts, planId } = this.props;

    if (discountToEdit === undefined) {
      return (
        <div>
          <PaginatedTable
            changePage={this.changePage}
            paginatedData={discounts[planId]}
            loading={loading}
          >
            <TableHead>
              <TableRow>
                <TableHeader>Patients</TableHeader>
                <TableHeader>Discount</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {getCurrentPage(discounts[planId]).map(discount => (
                <TableRow key={discount.id}>
                  <TableCell>{`${discount.group_quantity} Patients`}</TableCell>
                  <TableCell>{discount.discount}%</TableCell>
                  <Allow
                    roles={[
                      UserRole.AdminLevel,
                      UserRole.FinanceLevel,
                      UserRole.OfficeLevel,
                    ]}
                  >
                    <TableCell>
                      <AppButton
                        onClick={this.showEditMode.bind(this, discount)}
                        link
                      >
                        edit
                      </AppButton>
                    </TableCell>
                  </Allow>
                </TableRow>
              ))}
            </TableBody>
          </PaginatedTable>
          <Allow
            roles={[
              UserRole.AdminLevel,
              UserRole.FinanceLevel,
              UserRole.OfficeLevel,
            ]}
          >
            <div className="form-buttons">
              <AppButton onClick={this.showEditMode.bind(this, null)}>
                Add
              </AppButton>
            </div>
          </Allow>
        </div>
      );
    }

    return (
      <FamilyDiscountsForm
        actions={[SAVE_DISCOUNT]}
        initialValues={discountToEdit || { plan: planId }}
        onSubmit={this.onSave}
        onCancel={this.showListMode}
      />
    );
  }

  /**
   * Switches this card into edit mode.
   */
  public showEditMode = (discountToEdit: DiscountResponse | null) =>
    this.props.setProps({ discountToEdit });

  /**
   * Switches this card into list mode.
   */
  public showListMode = () =>
    this.props.setProps({ discountToEdit: undefined });

  public changePage = (page: number, pageSize?: number) =>
    this.props.getDiscounts(this.props.planId, page, pageSize);

  public onSave = (discount: Discount, dispatch: Dispatch) => {
    if (!discount.id) {
      return createDiscount(discount)(dispatch).then(this.showListMode);
    } else {
      return saveDiscount(
        discount.id,
        discount
      )(dispatch).then(this.showListMode);
    }
  };
}

/** Sets the initial mode to List. */
export const getInitialProps = () => ({
  discountToEdit: undefined,
});

/** Disconnected version of the plan for testing */
export { FamilyDiscountsTable as TestableFamilyDiscountsTable };

export const mapState = (store: StoreState) => ({
  discounts: store.discounts,
  loading: anyPending(store.responses, [GET_DISCOUNTS]),
});

const connector = connect(mapState, { getDiscounts });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(FamilyDiscountsTable)
);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';
import { MembershipSubscription } from './types';

export const MEMBERSHIP_SUBSCRIPTIONS_ENDPOINT = '/api/subscriptions';

export const GET_MEMBERSHIP_SUBSCRIPTION = makeAsyncActionSet(
  'GET_MEMBERSHIP_SUBSCRIPTION'
);
export function getMembershipSubscription(id: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_MEMBERSHIP_SUBSCRIPTION,
      `${MEMBERSHIP_SUBSCRIPTIONS_ENDPOINT}/${id}/`,
      'GET'
    )(dispatch);
}

export const GET_MEMBERSHIP_SUBSCRIPTIONS = makeAsyncActionSet(
  'GET_MEMBERSHIP_SUBSCRIPTIONS'
);
export function getMembershipSubscriptions(
  contact?: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  excludeBillingContainer: boolean = false
) {
  const queryString = generateQueryString({
    contact,
    page,
    page_size: pageSize,
    exclude_billing_container: excludeBillingContainer,
  });

  return (dispatch: Dispatch) =>
    request(
      GET_MEMBERSHIP_SUBSCRIPTIONS,
      `${MEMBERSHIP_SUBSCRIPTIONS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { contact, page, pageSize, excludeBillingContainer } }
    )(dispatch);
}

export const CREATE_MEMBERSHIP_SUBSCRIPTION = makeAsyncActionSet(
  'CREATE_MEMBERSHIP_SUBSCRIPTION'
);
export function createMembershipSubscription(
  subscription: MembershipSubscription
) {
  return (dispatch: Dispatch) =>
    request(
      CREATE_MEMBERSHIP_SUBSCRIPTION,
      `${MEMBERSHIP_SUBSCRIPTIONS_ENDPOINT}/`,
      'POST',
      subscription,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch)
      .then(response => {
        if (subscription.billing_container_subscription) {
          getMembershipSubscriptions(subscription.contact)(dispatch);
        } else {
          subscription.contacts.forEach(contact => {
            return getMembershipSubscriptions(contact.contact)(dispatch);
          });
        }

        return response;
      })
      .catch(throwSubmissionError);
}

export const SAVE_MEMBERSHIP_SUBSCRIPTION = makeAsyncActionSet(
  'SAVE_MEMBERSHIP_SUBSCRIPTION'
);
export function saveMembershipSubscription(
  id: string,
  subscription: MembershipSubscription
) {
  return (dispatch: Dispatch) =>
    request(
      SAVE_MEMBERSHIP_SUBSCRIPTION,
      `${MEMBERSHIP_SUBSCRIPTIONS_ENDPOINT}/${id}/`,
      'PATCH',
      subscription,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch)
      .then(response => {
        subscription.contacts.forEach(subscriptionContact =>
          getMembershipSubscriptions(subscriptionContact.contact)(dispatch)
        );

        return response;
      })
      .catch(throwSubmissionError);
}

import { anyPending, isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import PageSubSection from '^/common/page-section/page-sub-section';
import { ContactResponse } from '^/contacts/types';
import { FilterField, FilterList } from '^/filters/types';
import { closeModal, openModal } from '^/modals/actions';
import {
  createRelationship,
  deleteRelationship,
  GET_CONTACT_RELATIONSHIPS,
  getContactRelationships,
  relationshipActions,
} from '^/relationships/actions';
import RelationshipsAddModal from '^/relationships/add-modal';
import {
  filterByType,
  getRelationshipsFromStore,
} from '^/relationships/helper-functions';
import RelationshipsList from '^/relationships/list';
import { RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  filters?: FilterList;
  label?: string;
  roles?: ReadonlyArray<string>;
  searchFields?: ReadonlyArray<FilterField>;
  showBothSides?: boolean;
  showCategories?: boolean;
  types: { 0: RelationshipType } & ReadonlyArray<RelationshipType>;
  heading?: string;
  detailed?: boolean;
  createInReverse?: boolean;
  disabled?: boolean;
  paginated?: boolean;
  allowAdd?: boolean;
  linkContactName?: boolean;
}

export type RelationshipsListCardProps = OwnProps &
  ConnectedProps<typeof connector>;

class RelationshipsListCard extends React.PureComponent<
  RelationshipsListCardProps
> {
  public componentDidMount(): void {
    const { contactId, relationships, loading } = this.props;

    if (!loading.relationships && paginatedArrayHasExpired(relationships)) {
      this.props.getContactRelationships(contactId, this.props.types);
    }
  }

  public render() {
    const {
      contactId,
      relationships,
      label,
      types,
      showBothSides,
      loading,
      heading,
      detailed = false,
      disabled,
      paginated,
      allowAdd = true,
      linkContactName,
    } = this.props;

    const filteredRelationships = filterByType(
      getAllRetrievedPages(relationships).filter(
        relationship =>
          showBothSides || relationship.related_contact === contactId
      ),
      types
    );

    return (
      <PageSubSection
        heading={heading}
        loading={loading.relationships || loading.updating}
      >
        <div className="relationships list-card">
          <RelationshipsList
            relationships={filteredRelationships}
            contactId={contactId}
            emptyStateMessage={`No ${label || types.join(' or ')}`}
            onRemoveRelationship={this.removeRelationship}
            detailed={detailed}
            disabled={disabled}
            showBothSides={showBothSides}
            paginated={paginated}
            types={types}
            linkContactName={linkContactName}
          />
          {allowAdd && (
            <Allow
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
              or={!types.includes(RelationshipType.TeamMemberCompany)}
            >
              <div className="page-subsection-buttons">
                <AppButton onClick={this.showModal} small disabled={disabled}>
                  Add
                </AppButton>
              </div>
            </Allow>
          )}
        </div>
      </PageSubSection>
    );
  }

  public showModal = () =>
    this.props.openModal(
      <RelationshipsAddModal
        {...this.props}
        hideContacts={[this.props.contactId]}
        types={this.props.types}
        showCategories
        onCloseModal={this.props.closeModal}
        onContactSelected={this.addRelationship}
      />
    );

  public addRelationship = async (
    relatedContact: ContactResponse,
    relationshipType: RelationshipType
  ) => {
    const { contactId, createInReverse = false } = this.props;

    try {
      await this.props.createRelationship(
        createInReverse ? contactId : relatedContact.id,
        createInReverse ? relatedContact.id : contactId,
        relationshipType
      );

      await this.props.getContactRelationships(
        this.props.contactId,
        this.props.types
      );

      this.props.closeModal();
    } catch {
      return;
    }
  };

  public removeRelationship = async (relationship: string) => {
    await this.props.deleteRelationship(relationship);

    await this.props.getContactRelationships(
      this.props.contactId,
      this.props.types
    );
  };
}

// Disconnected version used for testing
export { RelationshipsListCard as TestableRelationshipsListCard };

export const mapState = (state: StoreState, props: OwnProps) => {
  const type = props.types.join(',');

  return {
    relationships: getRelationshipsFromStore(
      state,
      props.contactId,
      type,
      null
    ),
    loading: {
      relationships: isPending(
        state.responses,
        GET_CONTACT_RELATIONSHIPS,
        type
      ),
      updating: anyPending(state.responses, relationshipActions),
    },
  };
};

const connector = connect(mapState, {
  getContactRelationships,
  createRelationship,
  deleteRelationship,
  openModal,
  closeModal,
});

export default connector(RelationshipsListCard);

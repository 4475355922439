import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';

import { SectionDetail } from '^/common/section-detail';

export interface SectionDetailListProps {
  details?: ReadonlyArray<{ label: string; content: string }> | null;
  emptyStateMessage?: string;
  asRows?: boolean;
}

class SectionDetailList extends React.PureComponent<SectionDetailListProps> {
  public render() {
    const { details = [], emptyStateMessage, children, asRows } = this.props;

    if ((!details || details.length <= 0) && !children) {
      return (
        <div className="empty-state">{emptyStateMessage || 'No details.'}</div>
      );
    }

    if (asRows) {
      return (
        <div className="section-detail-group">
          <Table fill>
            {details &&
              details.map(detail => (
                <TableRow key={detail.content + detail.label}>
                  <TableHeader>{detail.label}</TableHeader>
                  <TableCell>{detail.content}</TableCell>
                </TableRow>
              ))}
            <TableBody>{children}</TableBody>
          </Table>
        </div>
      );
    }

    return (
      <div className="flex-row section-detail-group">
        {details &&
          details.map(detail => (
            <SectionDetail key={detail.label} label={detail.label}>
              {detail.content}
            </SectionDetail>
          ))}
        {children}
      </div>
    );
  }
}

export default SectionDetailList;

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { Attachment, AttachmentStatus } from '^/attachments/types';
import { generateQueryString } from '^/common/helper-functions';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';

export const ATTACHMENT_ENDPOINT = '/api/attachments';
export const PRACTICE_ATTACHMENT_ENDPOINT = '/api/attachments/practice/';

export const GET_ATTACHMENTS = makeAsyncActionSet('GET_ATTACHMENTS');
export function getAttachments(page: number = 1, pageSize: number = 10) {
  return (dispatch: Dispatch) =>
    request(
      GET_ATTACHMENTS,
      `${ATTACHMENT_ENDPOINT}/${generateQueryString({
        page,
        page_size: pageSize,
      })}`,
      'GET'
    )(dispatch);
}

export const UPLOAD_ATTACHMENT = makeAsyncActionSet('UPLOAD_ATTACHMENT');
export function uploadAttachment(
  file: File,
  session: string = '',
  contactId?: string
) {
  let url = '';
  if (contactId) {
    url = `${PRACTICE_ATTACHMENT_ENDPOINT}/upload/?file_name=${file.name}&practice=${contactId}`;
  } else {
    url = `${ATTACHMENT_ENDPOINT}/upload/?file_name=${file.name}`;
  }
  return (dispatch: Dispatch) =>
    request(UPLOAD_ATTACHMENT, url, 'PUT', file, {
      shouldRethrow: rethrowOnAnyError,
      headers: {
        'Content-Type': file.type,
      },
      metaData: {
        session,
        file_name: file.name,
        file_type: file.type,
      },
    })(dispatch)
      .then(response => {
        if (!response) {
          throw new SubmissionError({ non_field_errors: 'No response!' });
        }

        const responseURL = response.request.responseURL;
        const urlParts = responseURL.split('/');
        const id = urlParts[urlParts.length - 2];

        return saveAttachment(
          id,
          { status: AttachmentStatus.Available },
          session,
          contactId
        )(dispatch);
      })
      .catch(throwSubmissionError);
}

export const GET_ATTACHMENT = makeAsyncActionSet('GET_ATTACHMENT');
export function getAttachment(
  id: string,
  session: string = '',
  contactId?: string
) {
  return (dispatch: Dispatch) =>
    request<any>(
      GET_ATTACHMENT,
      contactId
        ? `${ATTACHMENT_ENDPOINT}/practice/${id}/`
        : `${ATTACHMENT_ENDPOINT}/${id}/`,
      'GET',
      undefined,
      {
        metaData: { session },
      }
    )(dispatch);
}

export const SAVE_ATTACHMENT = makeAsyncActionSet('SAVE_ATTACHMENT');
export function saveAttachment(
  id: string,
  attachment: Partial<Attachment>,
  session: string = '',
  contactId?: string
) {
  return (dispatch: Dispatch) =>
    request(
      SAVE_ATTACHMENT,
      contactId
        ? `${ATTACHMENT_ENDPOINT}/practice/${id}/`
        : `${ATTACHMENT_ENDPOINT}/${id}/`,
      'PATCH',
      attachment,
      {
        shouldRethrow: rethrowOnAnyError,
        metaData: { session },
      }
    )(dispatch)
      .then(() => getAttachment(id, session, contactId)(dispatch))
      .catch(throwSubmissionError);
}

import { Bank } from '^/contacts/ledger/types';
import { Contact, ContactResponse } from '^/contacts/types';

export interface PaymentDetails {
  readonly id: string;
  account_number: string;
  sort_code: string;
  account_name: string;
  contact: string;
  readonly contact_detail: Contact;
  bank_status: BankStatusTypes;
  reference?: string;
  associated_bank: Bank;
  first_collection_date?: Date;
}

export type PaymentDetailsResponse = Readonly<{
  id: string;
  account_number: string;
  sort_code: string;
  account_name: string;
  contact: string;
  contact_detail: ContactResponse;
  bank_status: BankStatusTypes;
  reference: string;
  associated_bank: Bank;
  first_collection_date?: Date;
}>;

export enum BankStatusTypes {
  SetupNewInstruction = 'SETUP_NEW_INSTRUCTION',
  CancelInstruction = 'CANCEL_INSTRUCTION',
  FirstCollection = 'FIRST_COLLECTION',
  RegularCollection = 'REGULAR_COLLECTION',
  RePresentedCollection = 'RE-PRESENTED COLLECTION',
  FinalCollection = 'FINAL_COLLECTION',
  NoDD = 'NO_DD',
}

export enum StatusTypes {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type LoqateValidationResponse = Readonly<{
  Bank: string; // 'Barclays Bank plc'
  BankBIC: string; // 'BARCGB21'
  Branch: string; // 'Southwark'
  BranchBIC: string; // '0LJ'
  CHAPSSupported: boolean;
  ContactAddressLine1: string;
  ContactAddressLine2: string;
  ContactFax: string;
  ContactPhone: string; // '0345 7345345'
  ContactPostTown: string; // 'Leicester'
  ContactPostcode: string; // 'LE87 2BB'
  CorrectedAccountNumber: string; // '12345678'
  CorrectedSortCode: string; // '123456'
  FasterPaymentsSupported: boolean;
  IBAN: string;
  IsCorrect: boolean;
  IsDirectDebitCapable: boolean;
  StatusInformation: 'OK';
}>;

export function isLoquateValidationResponse(
  x: unknown
): x is LoqateValidationResponse {
  return (
    x &&
    (x as Partial<LoqateValidationResponse>).StatusInformation !== undefined
  );
}

export type LoqateValidationError = Readonly<{
  Cause: string; // 'The key you are using to access the service was not found.'
  Description: string; // 'Unknown key'
  Error: string; // '2'
  Resolution: string; // 'Please check that the key is correct. It should be in the form AA11-AA11-AA11-AA11.'
}>;

export function isLoqateValidationError(
  x: unknown
): x is LoqateValidationError {
  return x && (x as Partial<LoqateValidationError>).Error !== undefined;
}

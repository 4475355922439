import { Container } from '@dabapps/roe';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import FilesTable from '^/contacts/companies/practices/files-table';
import PageContent from '^/page-container/page-content';
import { EXPORTS_ENDPOINT } from '^/routines/actions';
import {
  CSV_REPORT_TYPE_REQUIRE_DATE,
  CSV_REPORT_TYPE_REQUIRE_MONTH,
} from '^/routines/constants';
import CSVReportForm, {
  CSVReportFormOptions,
} from '^/routines/csv-report-form';
import { StoreState } from '^/types';

export type ReportsPageProps = ConnectedProps<typeof connector>;
class Reports extends React.PureComponent<ReportsPageProps> {
  public render() {
    return (
      <PageContent>
        <HeaderBar
          title={`Reports - ${this.props.selectedPracticeName}`}
          transparent
        />
        <Container>
          <PageSection thin>
            <PageSubSection heading="Generate a report" vertical>
              <p>
                Exports a PDF report containing a collection of sub-reports for
                the selected practice.
              </p>
              {this.props.selectedPractice && (
                <CSVReportForm
                  initialValues={{
                    practiceFilter: this.props.selectedPractice,
                  }}
                  onSubmit={this.handleCSVReportSubmit}
                />
              )}
            </PageSubSection>
            <PageSubSection heading="Your Monthly Reports" vertical>
              <p>A PDF report for the selected practice.</p>
              {this.props.selectedPractice && (
                <FilesTable contactId={this.props.selectedPractice} />
              )}
            </PageSubSection>
          </PageSection>
        </Container>
      </PageContent>
    );
  }

  public handleCSVReportSubmit = ({
    reportType,
    practiceFilter,
    month,
  }: CSVReportFormOptions) => {
    const requiresDate = CSV_REPORT_TYPE_REQUIRE_DATE.includes(
      reportType || ''
    );
    const requiresMonth = CSV_REPORT_TYPE_REQUIRE_MONTH.includes(
      reportType || ''
    );

    const dates = requiresDate
      ? {
          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
        }
      : undefined;
    const monthParam = requiresMonth ? { month } : undefined;

    const query = queryString.stringify({
      practice: practiceFilter || this.props.selectedPractice,
      ...dates,
      ...monthParam,
    });

    const url = `${EXPORTS_ENDPOINT}/${reportType}/${query ? `?${query}` : ''}`;
    window.open(url);
  };
}

export { Reports as TestableReportsPage };

export const mapState = (state: StoreState) => ({
  selectedPractice: state.iplan?.selectedPracticeId,
  selectedPracticeName: state.loggedInUser?.practices.find(
    p => p.id === state.iplan?.selectedPracticeId
  )?.name,
});

const connector = connect(mapState, {});

export default connector(Reports);

import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AppButton from '^/common/app-button';
import { StoreState } from '^/types';
import { DELETE_COLLECTION, deleteCollection } from './actions';
import { CollectionResponse } from './types';

// Props that come from the parent component.
interface OwnProps {
  collection: CollectionResponse;
}

export type DeleteCollectionButtonProps = OwnProps &
  ConnectedProps<typeof connector>;

class DeleteCollectionButton extends React.PureComponent<
  DeleteCollectionButtonProps
> {
  public render() {
    const { loading } = this.props;

    return (
      <AppButton
        onClick={this.handleDelete}
        icon={'trash'}
        loading={loading}
        small
        destructive
      />
    );
  }

  public handleDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    this.props.deleteCollection(this.props.collection);
  };
}

// Disconnected version used for testing
export { DeleteCollectionButton as TestableDeleteCollectionButton };

export const mapState = (state: StoreState, props: OwnProps) => ({
  loading: isPending(state.responses, DELETE_COLLECTION, props.collection.id),
});

const mapDispatch = {
  deleteCollection,
};

const connector = connect(mapState, mapDispatch);

export default connector(DeleteCollectionButton);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { generateQueryString } from '^/common/helper-functions';
import { FilterList } from '^/filters/types';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';
import { BACSSubmissionEntry } from './types';

export const GET_LEDGER_ENTRIES = makeAsyncActionSet('GET_LEDGER_ENTRIES');
export function getLedgerEntries(
  filters: FilterList,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const query = generateQueryString({ page, page_size: pageSize, ...filters });

  return (dispatch: Dispatch) =>
    request(
      GET_LEDGER_ENTRIES,
      `/api/ledger-entries${query}`,
      'GET',
      undefined,
      {
        metaData: { page, pageSize, filters },
      }
    )(dispatch);
}

export const CLEAR_LEDGER_ENTRIES = 'CLEAR_LEDGER_ENTRIES';
export function clearLedgerEntries() {
  return {
    type: CLEAR_LEDGER_ENTRIES,
  };
}

export const ADD_CONTACT_TO_BACS = 'ADD_CONTACT_TO_BACS';
export function addContactToBACS(row: BACSSubmissionEntry) {
  if (!row.contact || !row.amount || !row.code) {
    throw new SubmissionError({
      non_field_errors: 'Expected a contact, amount and code!',
    });
  }

  return {
    type: ADD_CONTACT_TO_BACS,
    payload: row,
  };
}

export const REMOVE_CONTACT_FROM_BACS = 'REMOVE_CONTACT_FROM_BACS';
export function removeContactFromBACS(row: BACSSubmissionEntry) {
  return {
    type: REMOVE_CONTACT_FROM_BACS,
    payload: row,
  };
}

export const CLEAR_BACS = 'CLEAR_BACS';
export function clearBACS() {
  return {
    type: CLEAR_BACS,
  };
}

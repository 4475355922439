import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { ModalBody } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AppButton from '^/common/app-button';
import { getContactName } from '^/common/helper-functions';
import { toggleContactStatus } from '^/contacts/actions';
import SimpleModal from '^/modals/simple-modal';
import { ContactResponse } from './types';

// Props that come from the parent component.
interface OwnProps {
  contact: ContactResponse;
  action: 'Archive' | 'Unarchive';
  closeModal(): void;
}

// State props that come from redux.
interface StateProps {
  errors?: string[];
}

export type ArchiveContactModalProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class ArchiveContactModal extends React.PureComponent<
  ArchiveContactModalProps
> {
  public render() {
    const { contact, action, errors = [] } = this.props;

    return (
      <SimpleModal
        heading={`Archive ${getContactName(contact)}`}
        onClose={this.props.closeModal}
      >
        <ModalBody>
          <div className="modal-body-section">
            <p>Are you sure you want to {action.toLowerCase()} this contact?</p>

            {errors.map(error => (
              <p key={error} className="error">
                {error}
              </p>
            ))}
          </div>
          <div className="modal-body-section form-buttons">
            <AppButton onClick={this.props.closeModal}>Cancel</AppButton>
            <AppButton onClick={this.archiveContact} destructive>
              {action}
            </AppButton>
          </div>
        </ModalBody>
      </SimpleModal>
    );
  }

  public archiveContact = async () => {
    try {
      await this.props.toggleContactStatus(this.props.contact);

      this.props.closeModal();
    } catch (e) {
      this.props.setProps({
        errors: e.errors.archive_validation_errors || 'Something went wrong',
      });
    }
  };
}

// Disconnected version used for testing
export { ArchiveContactModal as TestableArchiveContactModal };

export const getInitialProps = () => ({
  errors: undefined,
});

const mapDispatch = {
  toggleContactStatus,
};

const connector = connect(undefined, mapDispatch);

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(ArchiveContactModal)
);

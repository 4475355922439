import React from 'react';

import AppButton from '^/common/app-button';
import { ToggleButtonGroup } from '^/common/toggle-button-group';
import { FilterList } from '^/filters/types';
import { SortList } from '^/sorts/types';
import { PaginatedArray } from '^/types';
import { calculateTotalPages } from '^/utils/pagination-helpers';

export interface OwnProps<T> {
  paginatedData?: PaginatedArray<T> | null;
  sorts?: SortList;
  loading?: boolean;
  changePage(page: number, pageSize?: number, filters?: FilterList): void;
}

export const PAGE_SIZE_OPTIONS = ['10', '100'];

export type Props<T> = OwnProps<T>;

export class PaginationBar<T> extends React.PureComponent<Props<T>> {
  public render() {
    const { paginatedData, loading } = this.props;

    const { count, page, pageSize } = paginatedData
      ? paginatedData
      : { count: 0, page: 1, pageSize: 10 };

    const firstVal = count > 0 ? (page - 1) * pageSize + 1 : 0;
    const lastVal = Math.min(firstVal + pageSize - 1, count);

    const detailsString = `Showing ${firstVal}-${lastVal} of ${count} records`;
    const pageSizeOptions = [
      ...new Set([pageSize.toString(), ...PAGE_SIZE_OPTIONS]),
    ]
      .filter(value => !isNaN(+value))
      .sort(this.sortPageSizeOptions);

    return (
      <div className="pagination-bar">
        <div className="pagination-bar-page-count">
          <span>View</span>
          <ToggleButtonGroup
            options={pageSizeOptions}
            selectedOption={pageSizeOptions.findIndex(
              value => Number(value) === pageSize
            )}
            optionSelected={this.pageSizeSelected}
            disabled={loading}
          />
          <span>per page</span>
        </div>

        <div className="pagination-bar-page-selector">
          <AppButton
            id="first-button"
            onClick={this.getFirstPage}
            disabled={page <= 1 || loading}
            leftIcon="angle-double-left"
          >
            First
          </AppButton>
          <AppButton
            id="previous-button"
            onClick={this.getPreviousPage}
            disabled={page <= 1 || loading}
            leftIcon="angle-left"
          >
            Previous
          </AppButton>
          <span>{detailsString}</span>
          <AppButton
            id="next-button"
            onClick={this.getNextPage}
            disabled={page >= calculateTotalPages(count, pageSize) || loading}
            rightIcon="angle-right"
          >
            Next
          </AppButton>
          <AppButton
            id="last-button"
            onClick={this.getLastPage}
            disabled={page >= calculateTotalPages(count, pageSize) || loading}
            rightIcon="angle-double-right"
          >
            Last
          </AppButton>
        </div>
      </div>
    );
  }

  public getFirstPage = () => {
    if (this.props.paginatedData) {
      this.props.changePage(
        1,
        this.props.paginatedData.pageSize,
        this.props.paginatedData.filters
      );
    }
  };

  public getPreviousPage = () => {
    if (this.props.paginatedData) {
      this.props.changePage(
        this.props.paginatedData.page - 1,
        this.props.paginatedData.pageSize,
        this.props.paginatedData.filters
      );
    }
  };

  public getNextPage = () => {
    if (this.props.paginatedData) {
      this.props.changePage(
        this.props.paginatedData.page + 1,
        this.props.paginatedData.pageSize,
        this.props.paginatedData.filters
      );
    }
  };

  public getLastPage = () => {
    if (this.props.paginatedData) {
      const lastPage = calculateTotalPages(
        this.props.paginatedData.count,
        this.props.paginatedData.pageSize
      );
      this.props.changePage(
        lastPage,
        this.props.paginatedData.pageSize,
        this.props.paginatedData.filters
      );
    }
  };

  public sortPageSizeOptions = (a: string, b: string): number => +a - +b;

  public pageSizeSelected = (option: string) => {
    if (this.props.paginatedData) {
      if (!isNaN(+option)) {
        this.props.changePage(1, +option, this.props.paginatedData.filters);
      }
    }
  };
}

export default PaginationBar;

import { Container } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

export interface BreadcrumbBarItem {
  label: string;
  url?: string;
  loading?: boolean;
}

export type BreadcrumbItems = ReadonlyArray<
  BreadcrumbBarItem | string | [string, string]
>;

interface OwnProps {
  items?: BreadcrumbItems;
}

export type BreadcrumbBarProps = OwnProps;

class BreadcrumbBar extends React.PureComponent<BreadcrumbBarProps> {
  public render() {
    const { items } = this.props;

    if (!items || items.length <= 0) {
      return null;
    }

    const breadcrumbs = items.map(this.parseBreadcrumbItem);

    return (
      <div className="breadcrumb-bar">
        <Container>
          <div className="breadcrumb-bar-items">
            {breadcrumbs.map((item, index) => (
              <div
                key={index}
                className={classnames('breadcrumb-bar-item', {
                  loading: item.loading,
                })}
              >
                <div className="breadcrumb-bar-item-label">
                  {item.url ? (
                    <Link to={item.url}>{item.label}</Link>
                  ) : (
                    <span>{item.label}</span>
                  )}
                </div>

                <FontAwesomeIcon icon="angle-right" />
              </div>
            ))}
          </div>
        </Container>
      </div>
    );
  }

  private parseBreadcrumbItem = (
    item: BreadcrumbBarItem | string | [string, string]
  ): BreadcrumbBarItem => {
    if (this.isItemArray(item)) {
      return { label: item[0], url: item[1] };
    }

    if (typeof item === 'string') {
      return { label: item };
    }

    return item;
  };

  private isItemArray(
    item: BreadcrumbBarItem | string | [string, string]
  ): item is [string, string] {
    return item instanceof Array && item.length === 2;
  }
}

export default BreadcrumbBar;

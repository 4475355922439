import classnames from 'classnames';
import React from 'react';

// Props that come from the parent component.
interface OwnProps {
  loading?: boolean;
  clear?: true;
  className?: string;
}

export type CardProps = OwnProps;

class Card extends React.PureComponent<CardProps> {
  public render() {
    const { loading, clear, className } = this.props;

    return (
      <div className={classnames('card', className, { loading, clear })}>
        {this.props.children}
      </div>
    );
  }
}

// Disconnected version used for testing
export { Card as TestableCard };

export default Card;

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { parseNumberField } from '^/common/helper-functions';
import { futureDateInclusive, required } from '^/common/validation';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import { RenderDateField } from '^/form-helpers/render-date-field';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderToggleSwitch from '^/form-helpers/render-toggle-switch';
import { getPracticeInsurance } from '^/insurance/actions';
import { Plan } from '^/plans/types';
import { StoreState } from '^/types';
import { DEFAULT_PLAN_TEMPLATE_CODE } from '^/utils/constants';

/** Props that are passed into this component by the parent. */
interface OwnProps {
  practice: string;
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  generalErrorFields?: ReadonlyArray<string>;
  onCancel?(): void;
}

const selector = formValueSelector('planDetailsForm');

export const mapState = (state: StoreState, props: OwnProps) => ({
  increasedPaymentAmount: selector(state, 'fee.new_amount'),
  subscriptionFeeIncreased: Boolean(
    selector(state, 'fee.new_amount') ||
      selector(state, 'fee.new_amount_applies')
  ),
  adminFeeIncreased: Boolean(
    selector(state, 'admin_fee.new_amount') ||
      selector(state, 'admin_fee.new_amount_applies')
  ),
  practiceInsurance: state.practiceInsurance[props.practice],
  infinite: selector(state, 'infinite'),
  plan_length_months: selector(state, 'plan_length_months'),
});

const connector = connect(mapState, {
  getPracticeInsurance,
});

/** All the props combined. */
export type PlanDetailsFormProps = OwnProps &
  InjectedFormProps<Plan, OwnProps> &
  ConnectedProps<typeof connector>;

/** Form that is used for Plan Details. Can be extended to be used in both create and edit
 * situations
 */
class PlanDetailsForm extends React.PureComponent<PlanDetailsFormProps> {
  public componentDidMount() {
    const { plan_length_months } = this.props;

    if (plan_length_months >= 9999) {
      this.props.change('infinite', true);
    }
  }

  public componentDidUpdate(prevProps: PlanDetailsFormProps) {
    const { increasedPaymentAmount, infinite } = this.props;
    if (
      increasedPaymentAmount !== prevProps.increasedPaymentAmount &&
      !increasedPaymentAmount
    ) {
      this.props.change('fee.new_amount_applies', null);
    }

    if (infinite !== prevProps.infinite) {
      this.props.change('plan_length_months', infinite ? 9999 : 1);
    }
  }

  public render() {
    const {
      handleSubmit,
      submitting,
      initialValues,
      actions,
      generalErrorFields = [],
      subscriptionFeeIncreased,
      adminFeeIncreased,
      practiceInsurance,
      practice,
      infinite,
    } = this.props;

    if (!practiceInsurance) {
      this.props.getPracticeInsurance(practice);
    }

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup className="spacious bordered">
          <Field
            component={RenderInputField}
            label="Code"
            name="code"
            short
            validate={[required]}
            disabled
          />
          <Field
            component={RenderInputField}
            label="Name"
            name="description"
            placeholder={initialValues.description}
            disabled={initialValues.template_overrides_plan}
          />
          {!initialValues.template_overrides_plan && (
            <Field
              component={RenderToggleSwitch}
              label="INSPRAC"
              name="insprac"
              disabled={!practiceInsurance}
              short
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
          )}
        </FormGroup>
        <FormGroup className="spacious bordered">
          <Field
            component={RenderInputField}
            label="iPlan Nickname"
            name="nickname"
            placeholder={initialValues.nickname}
            disabled={initialValues.template_overrides_plan}
          />
        </FormGroup>
        {!initialValues.template_overrides_plan && (
          <FormGroup className="spacious bordered">
            <Field
              label="Subscription fee"
              component={RenderCurrencyField}
              name="fee.amount"
              shorter
              type="number"
              stretch
            />
            <Field
              label="Joining fee"
              component={RenderCurrencyField}
              name="joining_fee.amount"
              shorter
              type="number"
              stretch
            />
            <Field
              label="Admin fee"
              component={RenderCurrencyField}
              name="admin_fee.amount"
              shorter
              type="number"
              stretch
            />
          </FormGroup>
        )}
        {!initialValues.template_overrides_plan && (
          <>
            <FormGroup className="spacious bordered">
              <Field
                label="New subscription fee"
                component={RenderCurrencyField}
                name="fee.new_amount"
                shorter
                type="number"
                validate={subscriptionFeeIncreased && required}
                parse={parseNumberField}
                stretch
              />

              <Field
                label="New subscription fee applies"
                component={RenderDateField}
                name="fee.new_amount_applies"
                type="date"
                validate={
                  subscriptionFeeIncreased
                    ? [futureDateInclusive, required]
                    : [futureDateInclusive]
                }
                clearable={false}
              />
            </FormGroup>
            <FormGroup className="spacious bordered">
              <Field
                label="New Admin fee"
                component={RenderCurrencyField}
                name="admin_fee.new_amount"
                shorter
                type="number"
                validate={adminFeeIncreased && required}
                parse={parseNumberField}
                stretch
              />

              <Field
                label="New admin fee applies"
                component={RenderDateField}
                name="admin_fee.new_amount_applies"
                type="date"
                validate={
                  adminFeeIncreased
                    ? [futureDateInclusive, required]
                    : [futureDateInclusive]
                }
                clearable={false}
              />
            </FormGroup>
          </>
        )}
        {!initialValues.template_overrides_plan && (
          <FormGroup>
            <Field
              component={RenderInputField}
              label="Subscription period (months)"
              name="plan_length_months"
              type="number"
              short
              disabled={infinite}
            />
            <Field
              component={RenderCheckBox}
              name="infinite"
              label="Infinite"
              short
              vertical
            />
            <Field
              component={RenderToggleSwitch}
              label="Pricing"
              name="variable_pricing"
              disabled={
                (initialValues && initialValues.id) ||
                initialValues.code !== DEFAULT_PLAN_TEMPLATE_CODE
              }
              short
              options={[
                { label: 'Variable', value: true },
                { label: 'Fixed', value: false },
              ]}
            />
            <Field
              component={RenderToggleSwitch}
              label="Age restriction"
              name="age_restriction"
              short
              options={[
                { label: 'Adult', value: 'Adult' },
                { label: 'Child', value: 'Child' },
                { label: 'Mixed', value: 'Mixed' },
              ]}
            />
          </FormGroup>
        )}
        <FormGroup className="spacious bordered">
          <Field
            component={RenderToggleSwitch}
            label="Available on New Patient Sign up forms?"
            name="available_for_signup"
            short
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        </FormGroup>

        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...generalErrorFields]}
          showStatusErrors
        />
        <Allow
          roles={[
            UserRole.AdminLevel,
            UserRole.FinanceLevel,
            UserRole.OfficeLevel,
          ]}
        >
          <div className="form-buttons">
            {this.props.onCancel && (
              <AppButton type="button" onClick={this.props.onCancel}>
                Cancel
              </AppButton>
            )}
            <AppButton type="submit" loading={submitting} primary>
              {initialValues.id ? 'Save Changes' : 'Create Plan'}
            </AppButton>
          </div>
        </Allow>
      </Form>
    );
  }
}

/** Disconnected version of the plan for testing */
export { PlanDetailsForm as TestablePlanDetailsForm };

export default reduxForm<Plan, OwnProps>({
  form: 'planDetailsForm',
})(connector(PlanDetailsForm));

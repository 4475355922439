import { anyPending } from '@dabapps/redux-requests';
import { Column, Container, Row } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ConfirmationSideBarCard from '^/common/confirmation-sidebar-card';
import { ErrorPage } from '^/common/error-page';
import HeaderBar, {
  HeaderBarStatus,
  HeaderBarStatusColour,
} from '^/common/header-bar';
import { getContactName } from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import Sidebar from '^/common/sidebar';
import SidebarCard, { SidebarCardSection } from '^/common/sidebar-card';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import {
  GET_PRACTICE_FOR_PATIENT,
  getPracticeForPatient,
} from '^/contacts/persons/patients/actions';
import BreadcrumbBar, { BreadcrumbBarItem } from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { GET_PLAN, getPlan } from '^/plans/actions';
import { getPlanName } from '^/plans/helpers';
import {
  DELETE_PLAN_SUBSCRIPTION,
  deletePlanSubscription,
  GET_PLAN_SUBSCRIPTION,
  getPlanSubscription,
  SAVE_PLAN_SUBSCRIPTION,
  savePlanSubscription,
} from '^/plans/subscriptions/actions';
import PlanSubscriptionForm from '^/plans/subscriptions/form';
import {
  PlanSubscription,
  PlanSubscriptionResponse,
} from '^/plans/subscriptions/types';
import PlanSummaryCard from '^/plans/summary-card';
import { CachedItem, StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

export type SubscriptionEditPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ id: string }>;

class SubscriptionEditPage extends React.PureComponent<
  SubscriptionEditPageProps
> {
  public componentDidMount(): void {
    const {
      subscription,
      match: { params },
    } = this.props;

    if (params.id && cachedItemHasExpired(subscription)) {
      this.props.getPlanSubscription(params.id);
    }
  }

  public componentDidUpdate(prevProps: SubscriptionEditPageProps) {
    const { subscription, patient } = this.props;

    if (subscription && subscription !== prevProps.subscription) {
      subscription.contacts.forEach(contact =>
        this.props.getContact(contact.contact)
      );
      this.props.getPlan(subscription.plan);
    }

    if (patient && patient !== prevProps.patient) {
      this.props.getPracticeForPatient(patient.id);
    }
  }

  public render() {
    const {
      subscription,
      subscriptionContacts,
      plan,
      patient,
      practice,
      loading,
    } = this.props;

    if (!subscription || !plan) {
      if (loading.subscription || loading.plan || loading.patient) {
        return (
          <PageContent>
            <BreadcrumbBar items={this.getBreadcrumbItems()} />
            <HeaderBar title="Edit Plan Subscription" transparent loading />
            <Loading />
          </PageContent>
        );
      }

      return (
        <ErrorPage
          heading={`${
            !subscription ? 'Subscription' : 'Patient/Plan'
          } not found`}
        />
      );
    }

    if (subscription.plan_detail && subscription.plan_detail.is_membership) {
      return <Redirect to={`/membership-subscriptions/${subscription.id}`} />;
    }

    return (
      <PageContent>
        <BreadcrumbBar items={this.getBreadcrumbItems()} />
        <HeaderBar
          title="Edit Plan Subscription"
          subtitle={getPlanName(plan)}
          secondaryActions={[
            <AppButton
              key="export"
              url={`/api/letters/subscription-welcome/${subscription.id}/`}
              small
            >
              Export welcome letter
            </AppButton>,
          ]}
          transparent
          loading={loading.plan || loading.patient || loading.practice}
          status={this.getHeadbarStatus(subscription)}
        >
          <span>
            You are editing a subscription to{' '}
            <Link to={`/plans/${plan.id}`}>{getPlanName(plan)}</Link> at
            practice {getContactName(practice)} for patient{' '}
            {getContactName(patient)}
            {subscriptionContacts.length > 1 &&
              ` and ${subscriptionContacts.length - 1} others`}
            .
          </span>
        </HeaderBar>
        <Container>
          <Row>
            <Column xs={12} md={7} lg={8}>
              <PageSection>
                <PlanSubscriptionForm
                  actions={[SAVE_PLAN_SUBSCRIPTION]}
                  plan={plan}
                  contacts={subscriptionContacts.map(
                    subContact => subContact.contact
                  )}
                  initialValues={{
                    ...subscription,
                    lapse: Boolean(subscription.lapsed_date),
                  }}
                  onSubmit={this.handleSave}
                  onCancel={this.props.history.goBack}
                  prevContact={patient ? patient.id : ''}
                />
              </PageSection>
            </Column>
            <Sidebar>
              {plan && <PlanSummaryCard plan={plan} />}
              {!subscription.suspended ? (
                <Allow
                  roles={[
                    UserRole.AdminLevel,
                    UserRole.FinanceLevel,
                    UserRole.OfficeLevel,
                  ]}
                >
                  <SidebarCard heading="Suspend Subscription" transparent>
                    <SidebarCardSection>
                      <p>
                        Suspending this subscription
                        <strong> will pause all payments </strong> from this
                        subscriber.
                      </p>
                      <div className="buttons">
                        <AppButton
                          onClick={this.suspendSubscription}
                          destructive
                        >
                          Suspend Subscription
                        </AppButton>
                      </div>
                    </SidebarCardSection>
                  </SidebarCard>
                </Allow>
              ) : (
                <SidebarCard
                  heading="Subscription Suspended"
                  transparent
                  highlight
                >
                  <SidebarCardSection>
                    <Allow
                      roles={[
                        UserRole.AdminLevel,
                        UserRole.FinanceLevel,
                        UserRole.OfficeLevel,
                      ]}
                    >
                      <p>
                        Reactivate this subscription
                        <strong> to resume all payments </strong> from this
                        subscriber.
                      </p>
                      <div className="buttons">
                        <AppButton onClick={this.unsuspendSubscription} primary>
                          Activate Subscription
                        </AppButton>
                      </div>
                    </Allow>
                    <Allow roles={[UserRole.SalesLevel]}>
                      <p>
                        You do not have permission to edit this subscription.
                      </p>
                    </Allow>
                  </SidebarCardSection>
                </SidebarCard>
              )}
              <ConfirmationSideBarCard
                heading={'Delete Subscription'}
                onConfirm={this.deleteSubscription}
                confirmationText={
                  'Are you sure you want to delete this subscription?'
                }
                actionLabel={'Delete Subscription'}
                actions={[DELETE_PLAN_SUBSCRIPTION]}
              >
                <p>
                  Deleting a subscription will remove it from the system{' '}
                  <strong>permanently</strong>. It will no longer be usable and{' '}
                  <strong>cannot be restored</strong>.
                </p>
              </ConfirmationSideBarCard>
            </Sidebar>
          </Row>
        </Container>
      </PageContent>
    );
  }

  public getHeadbarStatus(subscription: PlanSubscription): HeaderBarStatus {
    if (subscription.lapsed) {
      return {
        colour: HeaderBarStatusColour.Red,
        description: 'Subscription is lapsed',
      };
    }

    if (subscription.lapsed_date) {
      return {
        colour: HeaderBarStatusColour.Yellow,
        description: `Subscription is due to lapse on ${subscription.lapsed_date}`,
      };
    }

    return {
      colour: HeaderBarStatusColour.Green,
      description: 'Subscription is active',
    };
  }

  public handleSave = (subscription: PlanSubscription, dispatch: Dispatch) =>
    savePlanSubscription(this.props.match.params.id, {
      ...subscription,
      suspended: undefined,
    })(dispatch).then(() => {
      if (this.props.patient) {
        return this.props.history.push(`/contacts/${this.props.patient.id}`);
      }

      this.props.history.goBack();
    });

  public suspendSubscription = () => {
    if (!this.props.subscription) {
      return;
    }
    const sub: PlanSubscription = {
      ...this.props.subscription,
      suspended: true,
    };

    this.props.savePlanSubscription(this.props.match.params.id, sub);
  };

  public unsuspendSubscription = () => {
    if (!this.props.subscription) {
      return;
    }

    const sub: PlanSubscription = {
      ...this.props.subscription,
      suspended: false,
    };

    this.props.savePlanSubscription(this.props.match.params.id, sub);
  };

  public deleteSubscription = async () => {
    if (!this.props.subscription) {
      return;
    }

    await this.props.deletePlanSubscription(this.props.subscription.id);
    this.props.history.goBack();
  };

  public getBreadcrumbItems = (): ReadonlyArray<
    BreadcrumbBarItem | [string, string] | string
  > => {
    const { patient, practice, plan, loading } = this.props;

    return [
      ['Practices', '/practices'],
      {
        label: getContactName(practice),
        url: practice ? `/practices/${practice.id}` : undefined,
        loading: loading.subscription || loading.practice,
      },
      {
        label: getContactName(patient),
        url: patient ? `/contacts/${patient.id}` : undefined,
        loading: loading.subscription || loading.patient,
      },
      {
        label: getPlanName(plan),
        loading: loading.plan,
      },
    ];
  };
}

function getPatient(
  patientId: string | undefined,
  subscription: CachedItem<PlanSubscriptionResponse> | undefined,
  state: StoreState
) {
  if (patientId) {
    return getItemFromCache(patientId, state.contactsCache);
  } else if (subscription && subscription.contacts.length > 0) {
    return getItemFromCache(
      subscription.contacts[0].contact,
      state.contactsCache
    );
  } else {
    return undefined;
  }
}

// Disconnected version used for testing
export { SubscriptionEditPage as TestableSubscriptionEditPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ id: string }>
) => {
  const subscription = getItemFromCache(
    props.match.params.id,
    state.planSubscriptionCache
  );

  const plan = subscription
    ? getItemFromCache(subscription.plan, state.planCache)
    : undefined;

  const queryParams = queryString.parse(props.location.search);

  const patientId = queryParams.patient
    ? String(queryParams.patient)
    : undefined;

  return {
    subscription,
    subscriptionContacts:
      state.subscriptionContacts[props.match.params.id] || [],
    patient: getPatient(patientId, subscription, state),
    plan,
    practice: plan
      ? getItemFromCache(plan.practice, state.contactsCache)
      : undefined,
    loading: {
      subscription: anyPending(state.responses, [GET_PLAN_SUBSCRIPTION]),
      plan: anyPending(state.responses, [GET_PLAN]),
      patient: anyPending(state.responses, [GET_CONTACT]),
      practice: anyPending(state.responses, [GET_PRACTICE_FOR_PATIENT]),
    },
  };
};

const connector = connect(mapState, {
  getPlanSubscription,
  getPlan,
  getContact,
  getPracticeForPatient,
  savePlanSubscription,
  deletePlanSubscription,
});

export default connector(SubscriptionEditPage);

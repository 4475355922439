import { Modal, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { closeModal } from '^/modals/actions';
import AppButton from '../common/app-button';

// Props that come from the parent component.
export interface SimpleModalOwnProps {
  heading?: string;
  scrollable?: boolean;
  size?: 'small' | 'large' | 'fill';
  children?: ReactNode;
  onClose?(): void;
}

export type SimpleModalProps = SimpleModalOwnProps &
  ConnectedProps<typeof connector>;

class SimpleModal extends React.PureComponent<SimpleModalProps> {
  public render() {
    const { heading, scrollable, size } = this.props;

    return (
      <Modal
        onClickOutside={this.handleClose}
        scrollable={scrollable}
        small={size === 'small'}
        large={size === 'large'}
        fill={size === 'fill'}
      >
        {heading && (
          <ModalHeader>
            <h2>{heading}</h2>
            <AppButton transparent onClick={this.handleClose}>
              <FontAwesomeIcon icon="times" />
            </AppButton>
          </ModalHeader>
        )}
        {this.props.children}
      </Modal>
    );
  }

  public handleClose = () => {
    this.props.onClose ? this.props.onClose() : this.props.closeModal();
  };
}

// Disconnected version used for testing
export { SimpleModal as TestableSimpleModal };

const mapDispatch = {
  closeModal,
};

const connector = connect(undefined, mapDispatch);

export default connector(SimpleModal);

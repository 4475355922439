import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { GET_TEMPLATES, getTemplates } from '^/admin/templates/actions';
import { TemplateResponse } from '^/admin/templates/types';
import { formatDate, toggleSortFor } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import TableHeaderSort from '^/sorts/table-header-sort';
import { SortList } from '^/sorts/types';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';

interface StateProps {
  appliedSorts: SortList;
}

export type TemplatesTableProps = SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class TemplatesTable extends React.PureComponent<TemplatesTableProps> {
  public componentDidMount() {
    this.props.getTemplates();
  }

  public componentDidUpdate(prevProps: TemplatesTableProps) {
    if (this.props.appliedSorts !== prevProps.appliedSorts) {
      this.props.getTemplates(
        1,
        this.props.templates ? this.props.templates.pageSize : undefined,
        this.props.appliedSorts
      );
    }
  }

  public render() {
    const { templates, appliedSorts, loading, setProps } = this.props;

    return (
      <PaginatedTable
        paginatedData={templates}
        changePage={this.changePage}
        loading={loading}
      >
        <TableHead>
          <TableRow>
            <TableHeaderSort
              sortOrder={appliedSorts.code}
              onClick={toggleSortFor.bind(this, 'code', appliedSorts, setProps)}
            >
              Code
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.description}
              onClick={toggleSortFor.bind(
                this,
                'description',
                appliedSorts,
                setProps
              )}
            >
              Name
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.created}
              onClick={toggleSortFor.bind(
                this,
                'created',
                appliedSorts,
                setProps
              )}
            >
              Created
            </TableHeaderSort>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(templates).map(template => (
            <ItemRow
              key={template.id}
              item={template}
              onClick={this.handleClick}
            >
              <TableHeader>{template.code}</TableHeader>
              <TableCell>{template.description}</TableCell>
              <TableCell>{formatDate(template.created)}</TableCell>
            </ItemRow>
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public handleClick = (template: TemplateResponse) =>
    this.props.push(`/admin/templates/${template.id}`);

  public changePage = (page: number, pageSize?: number) =>
    this.props.getTemplates(page, pageSize);
}

export { TemplatesTable as TestableTemplatesTable };

export const getInitialProps = () => ({
  appliedSorts: {},
});

export const mapState = (state: StoreState) => ({
  templates: state.templates,
  loading: anyPending(state.responses, [GET_TEMPLATES]),
});

const connector = connect(mapState, {
  getTemplates,
  push,
});

export default withSetProps<StateProps>(getInitialProps)(
  connector(TemplatesTable)
);

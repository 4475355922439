import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import {
  changePractice,
  GET_DENTISTS_BY_PRACTICE,
  getDentistsByPractice,
} from '^/contacts/persons/patients/actions';
import DentistPickerForm from '^/contacts/persons/patients/practice-picker/dentist-picker';
import ContactsPicker from '^/contacts/picker';
import { ContactResponse } from '^/contacts/types';
import { relationshipActions } from '^/relationships/actions';
import { ContactType, StoreState } from '^/types';

interface OwnProps {
  /** ID of patient to add relationships to */
  patientId: string;
  /** Called when the modal wants to close itself. Parent should close the modal. */
  onCloseModal(): void;
}

interface StateProps {
  /** Practice selected to change patient to */
  selectedPractice?: string;
  /** ID of Dentist selected to change patient to */
  selectedDentist?: string;
}

export type PracticePickerModalProps = OwnProps &
  ConnectedProps<typeof connector> &
  SetPropsInterface<StateProps>;

export class PracticePickerModal extends React.Component<
  PracticePickerModalProps
> {
  public render() {
    const {
      dentists: dentistsByPractice,
      loading,
      onCloseModal,
      patientId,
      selectedPractice,
    } = this.props;

    const practiceDentists = selectedPractice
      ? dentistsByPractice[selectedPractice]
      : [];

    return (
      <Modal onClickOutside={onCloseModal}>
        <ModalHeader>
          <h2>{`Change Practice`}</h2>
          <AppButton transparent onClick={this.props.onCloseModal}>
            <FontAwesomeIcon icon="times" />
          </AppButton>
        </ModalHeader>
        <ModalBody className={classnames({ loading: loading.dentists })}>
          {!selectedPractice && (
            <ContactsPicker
              onContactSelected={this.practiceSelected}
              filters={{ type: ContactType.Practice }}
              hideContacts={[patientId]}
            />
          )}
          {selectedPractice && practiceDentists && (
            <div>
              <div className="modal-body-section">
                {loading.dentists ? (
                  <Loading />
                ) : (
                  <DentistPickerForm
                    dentists={practiceDentists}
                    onSubmit={this.dentistSelected}
                  />
                )}
              </div>
              <div className="modal-body-section form-buttons">
                <AppButton onClick={this.props.onCloseModal}>Cancel</AppButton>
                <AppButton
                  onClick={this.selectDentist}
                  loading={loading.relationships}
                  primary
                >
                  Change Practice
                </AppButton>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }

  public practiceSelected = (contact: ContactResponse) => {
    this.props.getDentistsByPractice(contact.id);
    this.props.setProps({
      selectedPractice: contact.id,
    });
  };

  public selectDentist = () => {
    this.props.submit('dentistPickerForm');
  };

  public dentistSelected = (
    selectedDentist: { dentist: string },
    dispatch: Dispatch
  ) => {
    if (this.props.selectedPractice) {
      return changePractice(
        this.props.patientId,
        this.props.selectedPractice,
        selectedDentist.dentist
      )(dispatch).then(() => {
        this.props.onCloseModal();
      });
    }
  };
}

export { PracticePickerModal as TestablePracticePickerModal };

export const getInitialProps = () => ({});

export const mapState = (state: StoreState) => ({
  dentists: state.dentistsByPractice,
  loading: {
    dentists: anyPending(state.responses, [GET_DENTISTS_BY_PRACTICE]),
    relationships: anyPending(state.responses, relationshipActions),
  },
});

const connector = connect(mapState, {
  changePractice,
  getDentistsByPractice,
  submit,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(PracticePickerModal)
);

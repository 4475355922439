import classnames from 'classnames';
import React from 'react';

export interface PageSubSectionProps {
  className?: string;
  heading?: string;
  loading?: boolean;
  vertical?: boolean;
  clean?: boolean;
  hide?: boolean;
  actions?: React.ReactNode | null;
}

class PageSubSection extends React.PureComponent<PageSubSectionProps> {
  public render() {
    const {
      children,
      loading,
      heading = '',
      vertical,
      clean,
      hide,
      className,
      actions,
    } = this.props;

    return (
      <div
        className={classnames([
          'page-sub-section',
          this.convertHeadingToClass(heading),
          { loading, vertical, clean, hide },
          className,
        ])}
      >
        {(heading || actions) && (
          <div className="page-sub-section-header">
            <h4>{heading}</h4>
            {actions}
          </div>
        )}
        <div className="page-sub-section-content">{children}</div>
      </div>
    );
  }

  private convertHeadingToClass = (heading?: string) =>
    heading ? heading.toLowerCase().replace(/ /g, '-') : '';
}

const PageSubSectionBody = (props: { children: React.ReactNode }) => (
  <div className="body">{props.children}</div>
);

export { PageSubSectionBody };

export default PageSubSection;

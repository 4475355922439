import { anyPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import ConfirmationModal from '^/common/confirmation-modal';
import { ErrorPage } from '^/common/error-page';
import HeaderBar from '^/common/header-bar';
import {
  generateContactWarnings,
  getContactName,
} from '^/common/helper-functions';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import {
  contactActions,
  CREATE_CONTACT,
  createContact,
  getContact,
  getContacts,
} from '^/contacts/actions';
import PersonsForm from '^/contacts/persons/form';
import {
  CategoryType,
  Contact,
  ContactResponse,
  ContactType,
} from '^/contacts/types';
import { closeModal, openModal } from '^/modals/actions';
import BreadcrumbBar, { BreadcrumbBarItem } from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { createRelationship } from '^/relationships/actions';
import { RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

export type PatientsCreatePageProps = RouteComponentProps &
  ConnectedProps<typeof connector>;

/**
 * Page for creating new patients.
 */
class PatientsCreatePage extends React.PureComponent<PatientsCreatePageProps> {
  public componentDidMount() {
    const { practiceId, practice } = this.props;

    if (practiceId && cachedItemHasExpired(practice)) {
      this.props.getContact(practiceId);
    }
  }

  public render() {
    const { practiceId, practice, loading } = this.props;

    if (practiceId && !practice) {
      if (loading.practice) {
        return (
          <PageContent>
            <BreadcrumbBar
              items={[['Practices', '/practices'], 'Loading...', 'Loading...']}
            />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Practice not found" />;
    }

    return (
      <PageContent>
        <BreadcrumbBar items={this.getBreadcrumbItems()} />
        <HeaderBar
          title="New patient record"
          transparent
          primaryActions={[this.getPracticeLink(practice)]}
        />
        <Container>
          <main>
            <PageSection heading="Personal Details">
              <PersonsForm
                actions={[CREATE_CONTACT]}
                initialValues={{
                  type: ContactType.Patient,
                  category: CategoryType.Patient,
                }}
                onSubmit={this.onSave}
                onCancel={this.onCancel}
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  /**
   * Fires off the action that will save this patient. Defaults to type 'Patient'.
   * @param {Contact} patient - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public onSave = async (patient: Contact, dispatch: Dispatch) => {
    const { practiceId } = this.props;

    const email: string | undefined = patient.person
      ? patient.person.email
      : patient.company
      ? patient.company.email
      : undefined;
    const lastName: string | undefined = patient.person
      ? patient.person.last_name
      : patient.company
      ? patient.company.name
      : undefined;
    const firstName: string | undefined = patient.person
      ? patient.person.first_name
      : undefined;
    const dob: string | undefined = patient.person
      ? patient.person.dob
      : undefined;

    const emailCount = !email
      ? 0
      : await getContacts({ email })(dispatch).then(
          contactsByEmailResponse =>
            contactsByEmailResponse &&
            contactsByEmailResponse.data &&
            contactsByEmailResponse.data.count
        );

    const surnameCount = await getContacts({
      last_name: lastName,
      first_name: firstName,
      dob,
    })(dispatch).then(
      contactsByEmailResponse =>
        contactsByEmailResponse &&
        contactsByEmailResponse.data &&
        contactsByEmailResponse.data.count
    );
    const warnings = generateContactWarnings(surnameCount, emailCount);

    if (warnings.length > 0) {
      this.props.openModal(
        <ConfirmationModal
          heading="Are you sure you want to create this contact?"
          message={warnings.join(' ')}
          action={this.createPatient.bind(
            this,
            patient,
            practiceId,
            dispatch,
            true
          )}
          closeModal={this.props.closeModal}
          actionLabel="Create"
        />
      );
    } else {
      this.createPatient(patient, practiceId, dispatch);
    }
  };

  public createPatient = (
    patient: Contact,
    practiceId: string | undefined,
    dispatch: Dispatch,
    close?: boolean
  ) =>
    createContact({ ...patient, type: ContactType.Patient })(dispatch).then(
      response => {
        if (response) {
          const contact: ContactResponse = response.data;

          if (practiceId) {
            return createRelationship(
              contact.id,
              practiceId,
              RelationshipType.PatientPractice
            )(dispatch).then(() => {
              if (close) {
                this.props.closeModal();
              }
              return this.props.history.push(`/patients/${contact.id}`);
            });
          }
          if (close) {
            this.props.closeModal();
          }
          return this.props.history.push(`/patients/${contact.id}`);
        }
      }
    );

  /**
   * Cancels the submission of this form. Goes back to the previous page if there is one, or to /practices if there is no history.
   */
  public onCancel = () => {
    if (this.props.history.length > 2) {
      this.props.history.goBack();
    } else {
      this.props.history.replace('/practices');
    }
  };

  public getPracticeLink(practice?: ContactResponse) {
    if (!practice) {
      return null;
    }

    return (
      <Link key="patient-practice" to={`/practices/${practice.id}`}>
        <h3>{getContactName(practice)}</h3>
      </Link>
    );
  }

  public getBreadcrumbItems = (): ReadonlyArray<
    BreadcrumbBarItem | [string, string] | string
  > => {
    const { loading, practice, practiceId } = this.props;

    if (practiceId) {
      return [
        ['Practices', '/practices'],
        {
          label: getContactName(
            practice,
            undefined,
            loading.practice ? 'Loading...' : 'Unknown Practice'
          ),
          url: practice ? `/practices/${practiceId}` : undefined,
          loading: loading.practice,
        },
        'New patient record',
      ];
    }

    return [['Records', '/contacts'], 'New patient record'];
  };
}

/** Disconnected version of the component that is used for testing. */
export { PatientsCreatePage as TestablePatientsCreatePage };

export const mapState = (state: StoreState, props: RouteComponentProps) => {
  const queryParams = queryString.parse(props.location.search);

  const practiceId = queryParams.practiceId
    ? String(queryParams.practiceId)
    : undefined;

  return {
    practiceId,
    practice: getItemFromCache(practiceId, state.contactsCache),
    loading: {
      practice: anyPending(state.responses, contactActions),
    },
  };
};

const connector = connect(mapState, {
  getContact,
  openModal,
  closeModal,
});

export default connector(PatientsCreatePage);

import { CachedItem, DetailedCache } from '^/types';

export const LIST_SIZE = 10;

function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export function compareRetrieved<T extends object>(
  a: CachedItem<T>,
  b: CachedItem<T>
): number {
  if (a.retrieved === b.retrieved) {
    return 0;
  }

  return a.retrieved < b.retrieved ? 1 : -1;
}

export function convertCacheToArray<T extends object>(
  cache: DetailedCache<T> | null
): Array<CachedItem<T>> {
  if (!cache) {
    return [];
  }

  return Object.values(cache).filter(notUndefined);
}

export function recentlyViewedContacts<T extends object>(
  cache: DetailedCache<T> | null
): ReadonlyArray<T> {
  return convertCacheToArray(cache).sort(compareRetrieved);
}

import { anyPending, isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import PageSubSection from '^/common/page-section/page-sub-section';
import { ContactResponse } from '^/contacts/types';
import { FilterField, FilterList } from '^/filters/types';
import { closeModal, openModal } from '^/modals/actions';
import {
  createRelationship,
  deleteRelationship,
  GET_CONTACT_RELATIONSHIPS,
  getContactRelationships,
  relationshipActions,
} from '^/relationships/actions';
import RelationshipsAddModal from '^/relationships/add-modal';
import RelationshipsDetail from '^/relationships/detail';
import {
  filterByType,
  firstRelationshipOfType,
  getRelationshipsFromStore,
} from '^/relationships/helper-functions';
import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  filters?: FilterList;
  hideRecents?: boolean;
  label?: string;
  roles?: ReadonlyArray<string>;
  searchFields?: ReadonlyArray<FilterField>;
  showCategories?: boolean;
  types: [RelationshipType];
  preventRemove?: boolean;
  heading?: string;
  disabled?: boolean;
  allowAdd?: boolean;
  linkContactName?: boolean;
}

export type RelationshipsDetailCardProps = OwnProps &
  ConnectedProps<typeof connector>;

class RelationshipsDetailCard extends React.PureComponent<
  RelationshipsDetailCardProps
> {
  public componentDidMount(): void {
    const { contactId, relationships, loading } = this.props;

    if (!loading.relationships && paginatedArrayHasExpired(relationships)) {
      this.props.getContactRelationships(contactId, this.props.types);
    }
  }

  public render() {
    const {
      label,
      loading,
      relationships,
      types,
      contactId,
      preventRemove,
      heading,
      disabled,
      linkContactName,
      allowAdd = true,
    } = this.props;

    const firstRelationship: RelationshipResponse | null = firstRelationshipOfType(
      getAllRetrievedPages(relationships).filter(
        relationship => relationship.contact === contactId
      ),
      types[0]
    );

    if (loading.relationships) {
      return (
        <PageSubSection heading={heading} loading>
          <Loading />
        </PageSubSection>
      );
    }

    return (
      <PageSubSection
        heading={heading}
        loading={loading.updating || loading.relationships}
      >
        <div className="relationships detail-card">
          <RelationshipsDetail
            relationship={firstRelationship}
            linkContactName={linkContactName}
            emptyStateMessage={`No ${label || types.join(' or ')}`}
          />
          <div className="page-subsection-buttons">
            {allowAdd && (
              <AppButton onClick={this.showModal} small disabled={disabled}>
                {firstRelationship ? 'Edit' : 'Add'}
              </AppButton>
            )}
            {!preventRemove && firstRelationship && (
              <AppButton
                onClick={this.deleteExistingRelationships.bind(this, types[0])}
                small
                disabled={disabled}
              >
                Remove
              </AppButton>
            )}
          </div>
        </div>
      </PageSubSection>
    );
  }

  public showModal = () =>
    this.props.openModal(
      <RelationshipsAddModal
        {...this.props}
        hideContacts={[this.props.contactId]}
        onCloseModal={this.props.closeModal}
        onContactSelected={this.addRelationship}
      />
    );

  public deleteExistingRelationships = async (
    selectedType: RelationshipType
  ) => {
    const { relationships } = this.props;

    const existingRelationships: ReadonlyArray<RelationshipResponse> = filterByType(
      getAllRetrievedPages(relationships),
      [selectedType]
    );

    await existingRelationships.map(relationship =>
      this.props.deleteRelationship(relationship.id)
    );

    await this.props.getContactRelationships(
      this.props.contactId,
      this.props.types
    );
  };

  public addRelationship = async (
    selectedContact: ContactResponse,
    selectedType: RelationshipType,
    selectedRole?: string
  ) => {
    await this.deleteExistingRelationships(selectedType);
    await this.props.createRelationship(
      this.props.contactId,
      selectedContact.id,
      selectedType,
      selectedRole
    );

    await this.props.getContactRelationships(
      this.props.contactId,
      this.props.types
    );

    this.props.closeModal();
  };
}

// Disconnected version used for testing
export { RelationshipsDetailCard as TestableRelationshipsDetailCard };

export const mapState = (state: StoreState, props: OwnProps) => {
  const type = props.types.join(',');

  return {
    relationships: getRelationshipsFromStore(
      state,
      props.contactId,
      type,
      null
    ),
    loading: {
      relationships: isPending(
        state.responses,
        GET_CONTACT_RELATIONSHIPS,
        type
      ),
      updating: anyPending(state.responses, relationshipActions),
    },
  };
};

const connector = connect(mapState, {
  getContactRelationships,
  createRelationship,
  deleteRelationship,
  openModal,
  closeModal,
});

export default connector(RelationshipsDetailCard);

import { anyPending } from '@dabapps/redux-requests';
import { TableBody, TableHead, TableHeader, TableRow } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import PaginatedTable from '^/common/paginated-table';
import { GET_FILES, getFiles } from '^/contacts/companies/practices/actions';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import FileRow from './file-row';

interface OwnProps {
  contactId: string;
  userRole?: UserRole;
}

export type FilesTableProps = OwnProps & ConnectedProps<typeof connector>;

class FilesTable extends React.PureComponent<FilesTableProps> {
  public componentDidMount() {
    this.props.getFiles(this.props.contactId);
  }

  public render() {
    const { loading, files, userRole } = this.props;

    return (
      <PaginatedTable
        noRecordsFoundMessage="No Files Uploaded"
        paginatedData={files}
        changePage={this.changePage}
        loading={loading}
        primary
      >
        <TableHead>
          <TableRow>
            <TableHeader>Uploaded By</TableHeader>
            <TableHeader>Date</TableHeader>
            <TableHeader>File Name</TableHeader>
            {userRole !== UserRole.PracticeAdminLevel && <TableHeader />}
            <TableHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(files).map(file => (
            <FileRow key={file.id} fileResponse={file} />
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public changePage = (page: number, pageSize?: number) =>
    this.props.getFiles(this.props.contactId, page, pageSize);
}

export { FilesTable as TestableFilesTable };

export const mapState = (state: StoreState) => ({
  files: state.files,
  loading: anyPending(state.responses, [GET_FILES]),
  userRole: state.loggedInUser?.role,
});

const connector = connect(mapState, { getFiles });

export default connector(FilesTable);

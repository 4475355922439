import { TableCell, TableRow } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import AppButton from '^/common/app-button';
import { deleteFile } from '^/contacts/companies/practices/actions';
import { FileResponse } from '^/contacts/types';
import { StoreState } from '^/types';

export interface FileRowOwnProps {
  fileResponse: FileResponse;
}

export type FileRowProps = FileRowOwnProps & ConnectedProps<typeof connector>;
export class FileRow extends React.PureComponent<FileRowProps> {
  public render() {
    const { fileResponse, userRole } = this.props;
    const onRemove = () => {
      this.props.deleteFile(fileResponse);
    };
    return (
      <TableRow>
        <TableCell>{fileResponse.uploaded_by_name}</TableCell>
        <TableCell>
          {moment(fileResponse.created).format('DD-MM-YYYY')}
        </TableCell>
        <TableCell className="practice-file-name">
          {fileResponse.file_name}
        </TableCell>
        <TableCell className="align-left">
          <AppButton
            url={`/api/attachments/practice/${fileResponse.id}/download/`}
            small
          >
            Download
          </AppButton>
        </TableCell>
        {userRole !== UserRole.PracticeAdminLevel && (
          <TableCell className="align-left">
            <AppButton onClick={onRemove} destructive small>
              Remove
            </AppButton>
          </TableCell>
        )}
      </TableRow>
    );
  }
}

export { FileRow as TestableFileRow };

export const mapState = (state: StoreState) => ({
  userRole: state.loggedInUser?.role,
});

const connector = connect(mapState, { deleteFile });

export default connector(FileRow);

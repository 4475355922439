import { AsyncActionSet } from '@dabapps/redux-requests/dist/js';
import {
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { Template } from '^/admin/templates/types';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import PageSubSection from '^/common/page-section/page-sub-section';
import { required } from '^/common/validation';
import { Bank, BANK_OPTIONS } from '^/contacts/ledger/types';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderToggleSwitch from '^/form-helpers/render-toggle-switch';
import RenderVatCodePicker from '^/form-helpers/render-vat-code-picker';

interface OwnProps {
  actions?: ReadonlyArray<AsyncActionSet>;
  disabled?: boolean;
  onCancel?(): void;
}

export type TemplatesFormProps = OwnProps &
  InjectedFormProps<Template, OwnProps>;

class TemplatesForm extends React.Component<TemplatesFormProps> {
  public render() {
    const {
      handleSubmit,
      submitting,
      initialValues,
      pristine,
      actions,
      onCancel,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <PageSubSection heading="Template details" vertical>
          <FormGroup>
            <Field
              component={RenderInputField}
              label="Code"
              name="code"
              short
              disabled={initialValues && initialValues.id}
              validate={[required]}
            />
            <Field
              component={RenderInputField}
              label="Name"
              name="description"
            />
          </FormGroup>
        </PageSubSection>
        <PageSubSection heading="Fees" vertical>
          <Table className="no-borders" fill>
            <TableHead>
              <TableRow>
                <TableHeader />
                {initialValues.template_overrides_plan && (
                  <TableHeader>Amount</TableHeader>
                )}
                <TableHeader>Nominal code</TableHeader>
                <TableHeader>VAT code</TableHeader>
                <TableHeader>VAT inc.</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="align-right">Joining fee</TableCell>
                {initialValues.template_overrides_plan && (
                  <TableCell>
                    <Field
                      component={RenderCurrencyField}
                      name="joining_fee.amount"
                      type="number"
                    />
                  </TableCell>
                )}
                <TableCell>
                  <Field
                    component={RenderInputField}
                    name="joining_fee.nominal_code"
                    type="text"
                    validate={required}
                  />
                </TableCell>
                <TableCell>
                  <Field
                    component={RenderVatCodePicker}
                    name="joining_fee.vat_code"
                  />
                </TableCell>
                <TableCell>
                  <Field
                    component={RenderCheckBox}
                    name="joining_fee.vat_inclusive"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="align-right">Admin fee</TableCell>
                {initialValues.template_overrides_plan && (
                  <TableCell>
                    <Field
                      component={RenderCurrencyField}
                      name="admin_fee.amount"
                    />
                  </TableCell>
                )}
                <TableCell>
                  <Field
                    component={RenderInputField}
                    name="admin_fee.nominal_code"
                    type="text"
                    validate={required}
                  />
                </TableCell>
                <TableCell>
                  <Field
                    component={RenderVatCodePicker}
                    name="admin_fee.vat_code"
                  />
                </TableCell>
                <TableCell>
                  <Field
                    component={RenderCheckBox}
                    name="admin_fee.vat_inclusive"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="align-right">Subscription fee</TableCell>
                {initialValues.template_overrides_plan && (
                  <TableCell>
                    <Field component={RenderCurrencyField} name="fee.amount" />
                  </TableCell>
                )}
                <TableCell>
                  <Field
                    component={RenderInputField}
                    name="fee.nominal_code"
                    type="text"
                    validate={required}
                  />
                </TableCell>
                <TableCell>
                  <Field component={RenderVatCodePicker} name="fee.vat_code" />
                </TableCell>
                <TableCell>
                  <Field component={RenderCheckBox} name="fee.vat_inclusive" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className="form-field-footer">
            <Field
              component={RenderToggleSwitch}
              options={BANK_OPTIONS}
              label="Bank"
              name="associated_bank"
              short
            />
            <Field
              component={RenderToggleSwitch}
              label="Pricing"
              name="variable_pricing"
              disabled={initialValues && initialValues.id}
              short
              options={[
                { label: 'Variable', value: true },
                { label: 'Fixed', value: false },
              ]}
            />
          </div>
        </PageSubSection>
        <ErrorRenderer actions={actions} showStatusErrors />
        <div className="form-buttons">
          {onCancel && (
            <AppButton type="button" onClick={onCancel} disabled={submitting}>
              Cancel
            </AppButton>
          )}
          <AppButton
            primary
            type="submit"
            disabled={pristine}
            loading={submitting}
          >
            Save
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { TemplatesForm as TestableTemplatesForm };

export default reduxForm<Template, OwnProps>({
  form: 'templatesForm',
  initialValues: {
    variable_pricing: false,
    associated_bank: Bank.CodePlan,
  },
})(TemplatesForm);

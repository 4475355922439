import { Container, Nav, NavBar, NavItem } from '@dabapps/roe';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import ManualBACSSubmission from './bacs/manual-bacs-submission';
import LedgerListPage from './search/list-page';

export type AdminRoutesProps = RouteComponentProps;

class LedgerRoutes extends React.PureComponent<AdminRoutesProps> {
  public render() {
    const { match } = this.props;

    return (
      <>
        <NavBar className="secondary">
          <Container>
            <Nav>
              <NavItem>
                <NavLink to={`${match.url}/search`}>Ledger search</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${match.url}/bacs`}>
                  Manual BACS submission
                </NavLink>
              </NavItem>
            </Nav>
          </Container>
        </NavBar>

        <Switch>
          <Route path={`${match.path}/search`} component={LedgerListPage} />
          <Route path={`${match.path}/bacs`} component={ManualBACSSubmission} />
          <Route component={this.redirect} />
        </Switch>
      </>
    );
  }

  public redirect = () => <Redirect to={`${this.props.match.url}/search`} />;
}

// Disconnected version used for testing
export { LedgerRoutes as TestableLedgerRoutes };

export default LedgerRoutes;

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import {
  Container,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { formValueSelector } from 'redux-form';

import { GET_PRACTICE_USERS, getPracticeUsers } from '^/admin/users/actions';
import { USER_FILTERS } from '^/admin/users/list-page';
import ResendInviteModal from '^/admin/users/resend-invite-modal';
import { UserResponse, UserStatus } from '^/admin/users/types';
import AppButton from '^/common/app-button';
import FilterBar from '^/common/filter-bar';
import HeaderBar from '^/common/header-bar';
import {
  formatDate,
  generateQueryString,
  toggleSortFor,
} from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import { FilterList } from '^/filters/types';
import { openModal } from '^/modals/actions';
import PageContent from '^/page-container/page-content';
import TableHeaderSort from '^/sorts/table-header-sort';
import { SortList } from '^/sorts/types';
import { StoreState } from '^/types';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';
import {
  getCurrentPage,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';
import PracticeUsersFilterForm from './practice-users-filter-form';

interface StateProps {
  appliedSorts: SortList;
}

interface OwnProps {
  filters: FilterList;
}

export type PracticeUsersListPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps &
  SetPropsInterface<StateProps> &
  OwnProps;

const PRACTICE_USERS_LIST = '/practice-users';
const PRACTICE_USERS_CREATE = '/practice-users/create';
const selector = formValueSelector('PracticeUsersFilterForm');

export class PracticeUsersListPage extends React.PureComponent<
  PracticeUsersListPageProps
> {
  constructor(props: PracticeUsersListPageProps) {
    super(props);
    this.getUsers = this.getUsers.bind(this);
  }

  public componentDidMount() {
    const { loading, users } = this.props;
    if (!loading && paginatedArrayHasExpired(users)) {
      this.changePage(1);
    }
  }

  public componentDidUpdate(prevProps: PracticeUsersListPageProps) {
    const { location, appliedSorts } = this.props;

    if (
      location.search !== prevProps.location.search ||
      appliedSorts !== prevProps.appliedSorts
    ) {
      this.changePage(1);
    }
  }

  public getUsers(
    page: number = 1,
    pageSize: number = DEFAULT_PAGE_SIZE,
    sorting?: SortList,
    getAllPages: boolean = false,
    filters: FilterList = {}
  ) {
    const searchParams = queryString.parse(location.search);
    const searchQuery = this.props.searchValue
      ? this.props.searchValue
      : searchParams.search
      ? searchParams.search
      : undefined;
    const filtersWithSearchQuery = Object.assign({}, filters, {
      search: searchQuery,
    });

    this.props.getPracticeUsers(
      page,
      pageSize,
      sorting,
      getAllPages,
      filtersWithSearchQuery
    );
  }

  public handleFilterSubmit = (filters: FilterList) => {
    const query = generateQueryString(filters);

    this.props.push(`${this.props.url}${query}`);
  };

  public render() {
    const { filter, url, loading, users, appliedSorts, setProps } = this.props;

    const queryParams = queryString.parse(location.search);

    return (
      <PageContent>
        <HeaderBar
          title="iPlan Users"
          primaryActions={[
            <PracticeUsersFilterForm
              loading={loading}
              onSubmit={this.handleFilterSubmit}
              initialValues={{
                search: queryParams.search,
              }}
              placeholder="Search by name or email"
              key="practice-user-filter-form"
              enableReinitialize
            />,
            <AppButton
              onClick={this.onCreateNewUser}
              key="new"
              leftIcon="plus"
              primary
            >
              New User
            </AppButton>,
          ]}
          transparent
        />
        <Container>
          <FilterBar
            filter={filter}
            url={url}
            filters={USER_FILTERS}
            loading={loading}
          />
          <PaginatedTable
            paginatedData={users}
            changePage={this.getUsers}
            loading={loading}
            showPaginationBar
          >
            <TableHead>
              <TableRow>
                <TableHeaderSort
                  sortOrder={appliedSorts.first_name}
                  onClick={toggleSortFor.bind(
                    this,
                    'first_name',
                    appliedSorts,
                    setProps
                  )}
                >
                  First Name
                </TableHeaderSort>
                <TableHeaderSort
                  sortOrder={appliedSorts.last_name}
                  onClick={toggleSortFor.bind(
                    this,
                    'last_name',
                    appliedSorts,
                    setProps
                  )}
                >
                  Surname
                </TableHeaderSort>
                <TableHeaderSort
                  sortOrder={appliedSorts.role}
                  onClick={toggleSortFor.bind(
                    this,
                    'role',
                    appliedSorts,
                    setProps
                  )}
                >
                  Role
                </TableHeaderSort>
                <TableHeaderSort
                  sortOrder={appliedSorts.email}
                  onClick={toggleSortFor.bind(
                    this,
                    'email',
                    appliedSorts,
                    setProps
                  )}
                >
                  Email
                </TableHeaderSort>
                <TableHeaderSort
                  sortOrder={appliedSorts.last_login}
                  onClick={toggleSortFor.bind(
                    this,
                    'last_login',
                    appliedSorts,
                    setProps
                  )}
                >
                  Last logged in
                </TableHeaderSort>
                <TableHeaderSort
                  sortOrder={appliedSorts.created}
                  onClick={toggleSortFor.bind(
                    this,
                    'created',
                    appliedSorts,
                    setProps
                  )}
                >
                  Created
                </TableHeaderSort>
                <TableHeader>Status</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {getCurrentPage(users).map(user => {
                return (
                  <ItemRow key={user.id} item={user} onClick={this.handleClick}>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{formatDate(user.last_login)}</TableCell>
                    <TableCell>{formatDate(user.created)}</TableCell>
                    <TableCell>
                      {user.status === UserStatus.Invited ? (
                        <AppButton
                          small
                          onClick={this.resendUserInvite.bind(this, user)}
                        >
                          Resend
                        </AppButton>
                      ) : (
                        user.status
                      )}
                    </TableCell>
                  </ItemRow>
                );
              })}
            </TableBody>
          </PaginatedTable>
        </Container>
      </PageContent>
    );
  }

  public getQueryParams = () =>
    generateQueryString(queryString.parse(location.search));

  public handleClick = (user: UserResponse) =>
    this.props.push(
      `${PRACTICE_USERS_LIST}/${user.id}${this.getQueryParams()}`
    );

  public resendUserInvite = (
    user: UserResponse,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.openModal(<ResendInviteModal userId={user.id} />);
  };

  public onCreateNewUser = () => {
    this.props.push(`${PRACTICE_USERS_CREATE}/${this.getQueryParams()}`);
  };

  private changePage = async (page: number = 1) => {
    const { appliedSorts, filter, filters, users } = this.props;

    this.getUsers(
      page,
      users ? users.pageSize : undefined,
      appliedSorts,
      undefined,
      {
        ...filters,
        status: filter !== 'All' ? filter : undefined,
      }
    );
  };
}

export { PracticeUsersListPage as TestablePracticeUsersListPage };

export const getInitialProps = () => ({
  appliedSorts: {},
});

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId?: string; activityId?: string }>
) => {
  const queryParams = queryString.parse(props.location.search);

  return {
    filter: queryParams.filter ? String(queryParams.filter) : 'All',
    users: state.practiceUsers,
    loading: anyPending(state.responses, [GET_PRACTICE_USERS]),
    url: props.match.url,
    searchValue: selector(state, 'search'),
  };
};

const mapDispatch = {
  push,
  getPracticeUsers,
  openModal,
};

const connector = connect(mapState, mapDispatch);

export default withSetProps<StateProps, PracticeUsersListPageProps>(
  getInitialProps
)(connector(PracticeUsersListPage));

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import {
  CREATE_ACTIVITY,
  createActivity,
  GET_CONTACT_ACTIVITIES,
  getContactActivities,
  SAVE_ACTIVITY,
  saveActivity,
} from '^/activities/actions';
import ActivityList from '^/activities/activity-list';
import ActivityForm from '^/activities/form';
import { Activity, ActivityResponse } from '^/activities/types';
import AppButton from '^/common/app-button';
import { Loading } from '^/common/loading';
import SidebarCard from '^/common/sidebar-card';
import { StoreState } from '^/types';

export enum Mode {
  List = 'LIST',
  Edit = 'EDIT',
  Add = 'ADD',
}

interface OwnProps {
  /** Contact ID to retrieve and show details for. */
  contactId: string;
  disabled?: boolean;
}

interface StateProps {
  /** Mode of the card, can be Edit or List. */
  mode: Mode;
  activityToEdit?: ActivityResponse;
}

export type RelatedActivitiesProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class RelatedActivities extends React.PureComponent<RelatedActivitiesProps> {
  public cardRef: React.RefObject<HTMLDivElement>;

  public constructor(props: RelatedActivitiesProps) {
    super(props);
    this.cardRef = React.createRef();
  }

  public componentDidMount() {
    const { contactId } = this.props;

    this.props.getContactActivities(contactId, 1, 10, {
      completed_next_action: 'False',
    });
  }

  public render() {
    const { mode, contactActivities, loading, disabled } = this.props;

    const contactsActivities = contactActivities[this.props.contactId];
    if (!contactsActivities) {
      return loading ? (
        <SidebarCard
          childRef={this.cardRef}
          heading={'Recent Activities/Notes'}
          buttons={
            <AppButton small disabled>
              Add
            </AppButton>
          }
        >
          <Loading />
        </SidebarCard>
      ) : (
        <div className="empty-state">No activites found.</div>
      );
    }

    switch (mode) {
      case Mode.Edit:
        return (
          <SidebarCard
            childRef={this.cardRef}
            heading={'Edit Activity'}
            loading={loading}
          >
            <ActivityForm
              onSubmit={this.onSave}
              onCancel={this.showListMode}
              initialValues={this.props.activityToEdit}
              actions={[SAVE_ACTIVITY]}
            />
          </SidebarCard>
        );
      case Mode.Add:
        return (
          <SidebarCard
            childRef={this.cardRef}
            heading={'Create Activity'}
            loading={loading}
          >
            <ActivityForm
              onSubmit={this.onSaveNew}
              onCancel={this.showListMode}
              actions={[CREATE_ACTIVITY]}
            />
          </SidebarCard>
        );
      case Mode.List:
      default:
        return (
          <SidebarCard
            childRef={this.cardRef}
            heading={'Recent Activities/Notes'}
            loading={loading}
            buttons={
              <AppButton onClick={this.showAddMode} small disabled={disabled}>
                Add
              </AppButton>
            }
          >
            <ActivityList
              onEditActivity={this.onEditActivity}
              activities={contactsActivities}
              disabled={disabled}
            />
          </SidebarCard>
        );
    }
  }

  public onSave = (activity: Activity, dispatch: Dispatch) => {
    const { activityToEdit } = this.props;

    if (activityToEdit) {
      return saveActivity(
        activityToEdit.id,
        activity
      )(dispatch).then(() => {
        this.showListMode();
        getContactActivities(this.props.contactId, 1, 10, {
          completed_next_action: 'False',
        })(dispatch);
        window.location.reload();
      });
    }
  };

  public onEditActivity = (activity: ActivityResponse) => {
    this.props.setProps({ activityToEdit: activity });
    this.showEditMode();
  };

  public showEditMode = () => {
    this.props.setProps({ mode: Mode.Edit });
    this.scrollToCard();
  };

  public showListMode = () => this.props.setProps({ mode: Mode.List });

  public showAddMode = () => {
    this.props.setProps({ mode: Mode.Add });
    this.scrollToCard();
  };

  public scrollToCard = () => {
    if (this.cardRef && this.cardRef.current) {
      const position = this.cardRef.current.getBoundingClientRect();

      if (position.top < 0) {
        this.cardRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  public onSaveNew = (activity: Activity, dispatch: Dispatch) => {
    activity.associated_contact = this.props.contactId;

    return createActivity(activity)(dispatch).then(() => {
      this.props.setProps({ mode: Mode.List });
      getContactActivities(this.props.contactId, 1, 10, {
        completed_next_action: 'False',
      })(dispatch);
    });
  };
}

export { RelatedActivities as TestableRelatedActivities };

export const getInitialProps = () => ({
  mode: Mode.List,
  activityToEdit: undefined,
});

export const mapState = (state: StoreState) => ({
  loading: isPending(state.responses, GET_CONTACT_ACTIVITIES),
  contactActivities: state.contactActivities,
});

const connector = connect(mapState, { getContactActivities });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(RelatedActivities)
);

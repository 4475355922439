import { Container } from '@dabapps/roe';
import React from 'react';

import PageSection from '^/common/page-section/page-section';
import PageContent from '^/page-container/page-content';
import Reports from './reports';

class ReportsPage extends React.PureComponent {
  public render() {
    return (
      <PageContent page="reports iplan">
        <Container>
          <PageSection>
            <Reports />
          </PageSection>
        </Container>
      </PageContent>
    );
  }
}

export { ReportsPage as TestableDashboardPage };

export default ReportsPage;

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

import { formatFeeAmount } from '^/plans/helpers';
import { LedgerItemResponse } from './types';

// Props that come from the parent component.
interface OwnProps {
  ledgerItems: ReadonlyArray<LedgerItemResponse>;
}

// State props that come from redux.
interface StateProps {
  open: boolean;
}

export type LedgerItemsSummaryProps = OwnProps & SetPropsInterface<StateProps>;

class LedgerItemsSummary extends React.PureComponent<LedgerItemsSummaryProps> {
  public render() {
    const { ledgerItems, open } = this.props;

    if (ledgerItems.length < 1) {
      return null;
    }

    return (
      <div className="ledger-allocation-summary">
        <div
          className={classNames('collapse-header', { open })}
          onClick={this.toggleOpen}
        >
          <h4>Items</h4>
          <FontAwesomeIcon icon="caret-down" />
        </div>
        <Collapse open={open}>
          <Table fill scrollable={false} fixed>
            <TableHead>
              <TableRow>
                <TableCell width={120}>Name</TableCell>
                <TableCell title="Nominal code">Nominal code</TableCell>
                <TableCell title="Amount" className="align-right">
                  Amount
                </TableCell>
                <TableCell className="align-right">VAT</TableCell>
                <TableCell title="VAT code" className="align-right">
                  VAT Code
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ledgerItems.map((ledgerItem: LedgerItemResponse) => (
                <TableRow key={ledgerItem.id}>
                  <TableHeader title={ledgerItem.item_display}>
                    {ledgerItem.item_display}
                  </TableHeader>
                  <TableCell
                    title={`${ledgerItem.nominal_code || '-'}`}
                  >{`${ledgerItem.nominal_code || '-'}`}</TableCell>
                  <TableCell
                    title={formatFeeAmount(ledgerItem.amount)}
                    className="align-right"
                  >
                    {formatFeeAmount(ledgerItem.amount)}
                  </TableCell>
                  <TableCell
                    title={formatFeeAmount(ledgerItem.vat_amount)}
                    className="align-right"
                  >
                    {formatFeeAmount(ledgerItem.vat_amount)}
                  </TableCell>
                  <TableCell className="align-right">
                    {ledgerItem.vat || ledgerItem.vat === 0
                      ? `${ledgerItem.vat}`
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </div>
    );
  }

  public toggleOpen = () => this.props.setProps({ open: !this.props.open });
}

// Disconnected version used for testing
export { LedgerItemsSummary as TestableLedgerItemsSummary };

export const getInitialProps = () => ({ open: false });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  LedgerItemsSummary
);

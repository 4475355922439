import classnames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';
import { formatSortCode } from '^/payment-details/helpers';

interface OwnProps {
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  short?: boolean;
  shorter?: boolean;
  shortest?: boolean;
}

export type RenderSortCodeProps = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

export default class RenderSortCode extends React.PureComponent<
  RenderSortCodeProps
> {
  public render() {
    const {
      input,
      label,
      type,
      meta: { touched, error, warning, dirty, pristine },
      placeholder,
      disabled,
      short,
      shorter,
      shortest,
      className,
    } = this.props;

    return (
      <div
        id={`form-field-${input.name}`}
        className={classnames([
          'form-field',
          className,
          { 'form-field-short': short },
          {
            short,
            shorter,
            shortest,
            dirty,
            pristine,
            error: Boolean(touched && error),
          },
        ])}
      >
        <div className="form-field-label">
          {label && <label>{label}</label>}
        </div>
        <input
          disabled={disabled}
          value={formatSortCode(input.value)}
          onChange={this.handleChange}
          placeholder={placeholder}
          type={type}
        />
        <div className="form-field-errors">
          {touched && <ErrorRenderer error={[error, warning]} />}
        </div>
      </div>
    );
  }

  public handleChange = (event: { target: { value: string } }) => {
    const stripped = event.target.value.replace(/-/g, '');

    if (/^[0-9]{6}$/.test(stripped)) {
      return this.props.input.onChange(stripped);
    }

    this.props.input.onChange(event.target.value);
  };
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllowRoute from '^/common/allow-route';
import DashboardPage from '^/dashboard/dashboard-page';
import BulkReportsPage from '^/routines/bulk-reports-page';
import PracticeUsersRoutes from '^/users';
import { uuidRegex } from '^/utils/commons';
import ActivitiesRoutes from '../activities';
import AdminRoutes from '../admin';
import LettersPage from '../admin/letters';
import { UserRole } from '../admin/users/types';
import ContactsRoutes from '../contacts';
import CompaniesRoutes from '../contacts/companies/companies';
import PracticesRoutes from '../contacts/companies/practices';
import IndividualsRoutes from '../contacts/persons/individuals';
import PatientsRoutes from '../contacts/persons/patients';
import NotFoundPage from '../errors/not-found-page';
import LedgerRoutes from '../ledger';
import MembershipSubscriptionsRoutes from '../memberships/subscriptions';
import PageContainer from '../page-container';
import PlansRoutes from '../plans';
import SubscriptionsRoutes from '../plans/subscriptions';
import RoutinesRoutes from '../routines';

export const CMMRoutes = () => {
  return (
    <PageContainer>
      <Switch>
        <AllowRoute exact path="/" component={DashboardPage} />
        <AllowRoute
          roles={[UserRole.AdminLevel]}
          path="/admin"
          component={AdminRoutes}
        />
        <AllowRoute
          roles={[UserRole.AdminLevel]}
          path="/practice-users"
          component={PracticeUsersRoutes}
        />
        <AllowRoute
          roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}
          path={`/bulk-reports/:id(${uuidRegex})`}
          component={BulkReportsPage}
        />
        <Route path="/letters" component={LettersPage} />
        <AllowRoute path="/routines" component={RoutinesRoutes} />
        <AllowRoute path="/contacts" component={ContactsRoutes} />
        <AllowRoute path="/companies" component={CompaniesRoutes} />
        <AllowRoute path="/individuals" component={IndividualsRoutes} />
        <AllowRoute
          roles={[
            UserRole.AdminLevel,
            UserRole.FinanceLevel,
            UserRole.OfficeLevel,
          ]}
          path="/patients"
          component={PatientsRoutes}
        />
        <AllowRoute path="/practices" component={PracticesRoutes} />
        <AllowRoute path="/ledger" component={LedgerRoutes} />
        <AllowRoute path="/activities" component={ActivitiesRoutes} />
        <AllowRoute path="/subscriptions" component={SubscriptionsRoutes} />
        <AllowRoute
          path="/membership-subscriptions"
          component={MembershipSubscriptionsRoutes}
        />
        <AllowRoute path="/plans" component={PlansRoutes} />
        <Route component={NotFoundPage} />
      </Switch>
    </PageContainer>
  );
};

import { Row } from '@dabapps/roe';
import React from 'react';

const PublicErrorPage: React.FC = () => {
  return (
    <Row className="patient-signup-error">
      <div className="patient-signup-error-container">
        <h1 className="title">Oops!</h1>
        <p>
          We're sorry, there appears to be an issue loading the page you
          requested.
        </p>
        <p>
          If you are trying to sign up for a new plan, please contact your
          practice and ask them for the correct link to access the application
          form.
        </p>
      </div>
    </Row>
  );
};

export default PublicErrorPage;

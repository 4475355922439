import { AnyAction } from 'redux';

import * as taskActions from '^/tasks/actions';
import { StoreState } from '^/types';

export const pollTaskTimeouts = (
  state: StoreState['pollTaskTimeouts'] = {},
  action: AnyAction
) => {
  switch (action.type) {
    case taskActions.STORE_POLL_TIMEOUT:
      state[action.payload.taskId] = action.payload.timeout;
      return state;

    case taskActions.CLEAR_ALL_TASK_TIMEOUTS:
      Object.keys(state).map(taskId => window.clearTimeout(state[taskId]));
      return {};

    default:
      return state;
  }
};

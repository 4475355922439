import { Footer as RoeFooter } from '@dabapps/roe';
import * as React from 'react';

export class Footer extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <RoeFooter>
        <p>iPlan Membership Management 2020</p>
      </RoeFooter>
    );
  }
}

export default Footer;

import { PlanSubscriptionResponse } from './types';

export function isSubscriptionActive(
  subscription: PlanSubscriptionResponse,
  contact?: string
) {
  if (subscription.suspended || subscription.lapsed) {
    return false;
  } else if (!contact) {
    return true;
  } else if (subscription.plan_detail?.is_membership) {
    return true;
  }

  return subscription.contacts.some(
    subContact => subContact.contact === contact && !subContact.lapsed
  );
}

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import { generateQueryString } from '^/common/helper-functions';
import Sidebar from '^/common/sidebar';
import {
  GET_CONTACT_LEDGER,
  getContactLedger,
} from '^/contacts/ledger/actions';
import { selectEntry } from '^/contacts/ledger/helpers';
import LedgerActionsCard from '^/contacts/ledger/ledger-actions-card';
import LedgerDetailCard from '^/contacts/ledger/ledger-detail-card';
import LedgerFilterBar from '^/contacts/ledger/ledger-filter-bar';
import LedgerTable from '^/contacts/ledger/ledger-table';
import PrintLedgerRange from '^/contacts/ledger/print-ledger-range';
import { LedgerFilters, LedgerResponse } from '^/contacts/ledger/types';
import { FilterList } from '^/filters/types';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';
import { CategoryType } from '../types';

interface StateProps {
  /** An array of ledger entries */
  selectedLedgerEntries: ReadonlyArray<LedgerResponse>;
  /** Address id for printing ledger list */
  selectedAddressId: string;
  /** Filters for ledger */
  ledgerFilters: LedgerFilters;
}

/** All of the ledger tab props combined */
export type LedgerTabProps = SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

class LedgerTab extends React.PureComponent<LedgerTabProps> {
  public componentDidMount() {
    this.changePage(1);
  }

  public componentDidUpdate(prevProps: LedgerTabProps) {
    const {
      location,
      match: {
        params: { contactId },
      },
    } = this.props;

    if (location.search !== prevProps.location.search) {
      this.changePage(1);
    }

    if (prevProps.contactLedger !== this.props.contactLedger) {
      const entries = getAllRetrievedPages(this.props.contactLedger[contactId]);

      if (entries.length === 1) {
        this.props.setProps({ selectedLedgerEntries: entries });
      } else {
        this.props.setProps({ selectedLedgerEntries: [] });
      }
    }
  }

  public render() {
    const {
      loading,
      contact,
      contactLedger,
      selectedLedgerEntries,
      location,
      match: {
        params: { contactId },
      },
    } = this.props;

    const balance = contact ? contact.calculated_balance : null;
    const unallocated = contact ? contact.total_unallocated : null;

    const filters = queryString.parse(location.search);

    return (
      <Row>
        <Column xs={12} md={7} lg={8}>
          <Row className="ledger-balance">
            <Column xs={4}>
              <b>TRANSACTIONS</b>
            </Column>
            <Column xs={4} className="text-align-right">
              TOTAL UNALLOCATED: <b>{formatFeeAmount(unallocated)}</b>
            </Column>
            <Column xs={4} className="text-align-right">
              CURRENT BALANCE: <b>{formatFeeAmount(balance, '£', '-', true)}</b>
            </Column>
          </Row>
          <LedgerFilterBar
            initialValues={filters}
            enableReinitialize
            loading={loading}
            onSubmit={this.handleFilterSubmit}
            onClear={this.handleFilterClear}
          />
          <LedgerTable
            ledgerEntries={contactLedger[contactId]}
            onClickRow={this.selectTableEntry}
            loading={loading}
            changePage={this.changePage}
            selectedLedgerEntries={selectedLedgerEntries}
          />
        </Column>
        <Sidebar>
          {selectedLedgerEntries.length > 0 && (
            <LedgerDetailCard
              key="ledger-detail-card"
              heading="Transaction Detail"
              contactId={contactId}
              ledgerEntries={selectedLedgerEntries}
              clearSelectedLedgerEntries={this.clearSelectedLedgerEntries}
              selectLedgerPrintAddress={this.selectLedgerPrintAddress}
              selectedAddressId={this.props.selectedAddressId}
            />
          )}
          <Allow
            roles={[
              UserRole.AdminLevel,
              UserRole.OfficeLevel,
              UserRole.FinanceLevel,
            ]}
          >
            <LedgerActionsCard
              key="ledger-actions-card"
              selectedEntries={selectedLedgerEntries}
              contactId={contactId}
              isHeadOffice={
                this.props.contact?.category === CategoryType.HeadOffice
              }
            />
          </Allow>
          {selectedLedgerEntries.length < 1 && (
            <PrintLedgerRange key="print-ledger-range" contactId={contactId} />
          )}
        </Sidebar>
      </Row>
    );
  }

  public selectLedgerPrintAddress = (selectedAddressId: string) => {
    this.props.setProps({ selectedAddressId });
  };

  public clearSelectedLedgerEntries = () => {
    this.props.setProps({
      selectedLedgerEntries: [],
      selectedAddressId: '',
    });
  };

  public clearFilters = () => {
    this.props.setProps({ ledgerFilters: {} });
  };

  public changePage = (page: number, pageSize?: number) => {
    const filters = queryString.parse(this.props.location.search);

    this.props.getContactLedger(
      this.props.match.params.contactId,
      filters,
      page,
      pageSize
    );
  };

  public selectTableEntry = (togglableEntry: LedgerResponse) =>
    selectEntry(
      togglableEntry,
      this.props.selectedLedgerEntries,
      this.props.setProps,
      'selectedLedgerEntries'
    );

  public handleFilterSubmit = (filters: FilterList) => {
    const query = generateQueryString(filters);

    this.props.history.push(`${this.props.match.url}${query}`);
  };

  public handleFilterClear = () => {
    this.props.history.push(`${this.props.match.url}`);
  };
}

export { LedgerTab as TestableLedgerTab };

/** Sets the initial ledger entry to be empty */
export const getInitialProps = (): StateProps => ({
  selectedLedgerEntries: [],
  selectedAddressId: '',
  ledgerFilters: {},
});

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  contactLedger: state.contactLedger,
  contact: getItemFromCache(props.match.params.contactId, state.contactsCache),
  loading: anyPending(state.responses, [GET_CONTACT_LEDGER]),
});

const connector = connect(mapState, { getContactLedger });

export default withSetProps<
  StateProps,
  RouteComponentProps<{ contactId: string }>
>(getInitialProps)(connector(LedgerTab));

import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import ConfirmationModal from '^/common/confirmation-modal';
import HeaderBar from '^/common/header-bar';
import { generateContactWarnings } from '^/common/helper-functions';
import PageSection from '^/common/page-section/page-section';
import { CREATE_CONTACT, createContact, getContacts } from '^/contacts/actions';
import PersonsForm from '^/contacts/persons/form';
import { Contact, ContactResponse, ContactType } from '^/contacts/types';
import { closeModal, openModal } from '^/modals/actions';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';

const connector = connect(undefined, { openModal, closeModal });

export type IndividualsCreatePageProps = RouteComponentProps &
  ConnectedProps<typeof connector>;

/**
 * Page for creating new individuals.
 */
class IndividualsCreatePage extends React.PureComponent<
  IndividualsCreatePageProps
> {
  public render() {
    return (
      <PageContent>
        <BreadcrumbBar
          items={[
            { label: 'Records', url: '/contacts' },
            { label: 'New individual record' },
          ]}
        />
        <HeaderBar title="New individual record" transparent />
        <Container>
          <main>
            <PageSection heading="Personal Details">
              <PersonsForm
                actions={[CREATE_CONTACT]}
                initialValues={{ type: ContactType.Individual }}
                onSubmit={this.onSave}
                onCancel={this.onCancel}
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  /**
   * Fires off the action that will save this individual.
   * @param {Contact} individual - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public onSave = async (individual: Contact, dispatch: Dispatch) => {
    const email: string | undefined = individual.person
      ? individual.person.email
      : individual.company
      ? individual.company.email
      : undefined;
    const lastName: string | undefined = individual.person
      ? individual.person.last_name
      : individual.company
      ? individual.company.name
      : undefined;
    const firstName: string | undefined = individual.person
      ? individual.person.first_name
      : undefined;
    const dob: string | undefined = individual.person
      ? individual.person.dob
      : undefined;

    const emailCount = !email
      ? 0
      : await getContacts({ email })(dispatch).then(
          contactsByEmailResponse =>
            contactsByEmailResponse &&
            contactsByEmailResponse.data &&
            contactsByEmailResponse.data.count
        );

    const surnameCount = await getContacts({
      last_name: lastName,
      first_name: firstName,
      dob,
    })(dispatch).then(
      contactsByEmailResponse =>
        contactsByEmailResponse &&
        contactsByEmailResponse.data &&
        contactsByEmailResponse.data.count
    );

    const warnings = generateContactWarnings(surnameCount, emailCount);

    if (warnings.length > 0) {
      this.props.openModal(
        <ConfirmationModal
          heading="Are you sure you want to create this contact?"
          message={warnings.join(' ')}
          action={this.createIndividual.bind(this, individual, dispatch, true)}
          closeModal={this.props.closeModal}
          actionLabel="Create"
        />
      );
    } else {
      this.createIndividual(individual, dispatch);
    }
  };

  public createIndividual = (
    individual: Contact,
    dispatch: Dispatch,
    close?: boolean
  ) =>
    createContact({ ...individual })(dispatch).then(response => {
      if (response) {
        if (close) {
          this.props.closeModal();
        }

        const contact: ContactResponse = response.data;
        return this.props.history.push(`/individuals/${contact.id}`);
      }
    });

  /**
   * Cancels the submission of this form. Goes back to the previous page if there is one, or to /practices if there is no history.
   */
  public onCancel = () => {
    if (this.props.history.length > 2) {
      this.props.history.goBack();
    } else {
      this.props.history.replace('/contacts');
    }
  };
}

/** Disconnected version of the component that is used for testing. */
export { IndividualsCreatePage as TestableIndividualsCreatePage };

export default connector(IndividualsCreatePage);

import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { getUserName } from '^/admin/users/helpers';
import { GET_LOGGED_IN_USER, getLoggedInUser, logout } from '^/login/actions';
import { StoreState } from '^/types';
import { CHANGE_PASSWORD_URL } from '^/utils/constants';

interface OwnProps {
  displayUserName?: boolean;
}

export type SettingsButtonProps = OwnProps & ConnectedProps<typeof connector>;

class SettingsButton extends React.PureComponent<SettingsButtonProps> {
  public componentDidMount() {
    if (
      !this.props.loggedInUser ||
      this.props.loggedInUser.email === 'AnonymousUser'
    ) {
      this.props.getLoggedInUser();
    }
  }

  public render() {
    const { loggedInUser, loading, displayUserName } = this.props;

    return (
      <div className="settings-dropdown">
        <a className="settings-dropdown-button">
          {displayUserName ? (
            <span>{getUserName(loggedInUser, '...')}</span>
          ) : (
            <span>My Account</span>
          )}
          <img className={loading ? 'fa-spin' : ''} src="/static/cog.svg" />
        </a>
        <ul className="settings-dropdown-content">
          {!displayUserName && (
            <li>
              <span>{getUserName(loggedInUser, '...')}</span>
            </li>
          )}
          <li>
            <a href={CHANGE_PASSWORD_URL}>Change password</a>
          </li>
          <li>
            <a href="#" onClick={this.props.logout}>
              Log out
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export { SettingsButton as TestableSettingsButton };

export const mapState = (state: StoreState) => ({
  loggedInUser: state.loggedInUser,
  path: state.router.location.pathname,
  loading: isPending(state.responses, GET_LOGGED_IN_USER),
});

const connector = connect(mapState, {
  logout,
  getLoggedInUser,
});

export default connector(SettingsButton);

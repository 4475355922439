import moment from 'moment';
import { AnyAction } from 'redux';

import {
  GET_ACTIVITIES,
  GET_ACTIVITY,
  GET_CONTACT_ACTIVITIES,
  GET_CONTACT_POPUPS,
} from '^/activities/actions';
import { ActivityResponse } from '^/activities/types';
import {
  ByActivityListName,
  ByContact,
  DetailedCache,
  PaginatedArray,
} from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

export function dashboardActivityLists(
  state: ByActivityListName<PaginatedArray<ActivityResponse>> = {},
  action: AnyAction
): ByActivityListName<PaginatedArray<ActivityResponse>> {
  switch (action.type) {
    case GET_ACTIVITIES.SUCCESS:
      return {
        ...state,
        [action.meta.tag]: paginatedReducer(state[action.meta.tag], action),
      };
    default:
      return state;
  }
}

export function activities(
  state: PaginatedArray<ActivityResponse> | null = null,
  action: AnyAction
): PaginatedArray<ActivityResponse> | null {
  switch (action.type) {
    case GET_ACTIVITIES.SUCCESS:
      return paginatedReducer(state, action);
    default:
      return state;
  }
}

export function activityCache(
  state: DetailedCache<ActivityResponse> = {},
  action: AnyAction
): DetailedCache<ActivityResponse> {
  switch (action.type) {
    case GET_ACTIVITY.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
          retrieved: moment(),
        },
      };
    default:
      return state;
  }
}

export function contactPopups(
  state: ByContact<PaginatedArray<ActivityResponse>> = {},
  action: AnyAction
): ByContact<PaginatedArray<ActivityResponse>> {
  switch (action.type) {
    case GET_CONTACT_POPUPS.SUCCESS:
      return {
        ...state,
        [action.meta.contactId]: paginatedReducer(
          state[action.meta.contactId],
          action
        ),
      };
    default:
      return state;
  }
}

export function contactActivities(
  state: ByContact<PaginatedArray<ActivityResponse>> = {},
  action: AnyAction
): ByContact<PaginatedArray<ActivityResponse>> {
  switch (action.type) {
    case GET_CONTACT_ACTIVITIES.SUCCESS:
      return {
        ...state,
        [action.meta.contactId]: paginatedReducer(
          state[action.meta.contactId],
          action
        ),
      };
    default:
      return state;
  }
}

import { isPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { required } from '^/common/validation';
import RenderDropdown from '^/form-helpers/render-dropdown';
import { StoreState } from '^/types';
import { PUBLIC_GET_PRACTICE_PLANS } from '../actions';

export type ClinicianProps = ConnectedProps<typeof connector>;

const Clinician = ({
  clinicians,
  loading,
  presetSignUp,
  presetClinician,
}: ClinicianProps) => {
  const getOptions = () => {
    if (presetSignUp && presetClinician) {
      return [
        {
          label: presetClinician.name,
          value: presetClinician.id,
        },
      ];
    } else {
      const options = clinicians?.map(p => ({
        label: p.name,
        value: p.id,
      }));
      if (options) {
        return [{ label: 'please choose...', value: '' }].concat(options);
      }
    }
  };

  return (
    <PageSection
      className="patient-signup-section"
      heading="Clinician Details"
      subHeading="This should be your primary clinician. You will have the ability to
      select different Clinicians if you are adding additional patients"
      subHeadingClass="text-caption-light"
    >
      {!loading && clinicians && (
        <FormGroup>
          <Field
            label="Name"
            className="required"
            name="clinician"
            component={RenderDropdown}
            options={!loading && getOptions()}
            type="text"
            validate={[required]}
          />
        </FormGroup>
      )}
    </PageSection>
  );
};

export { Clinician as TestableClinician };

export const mapState = (state: StoreState) => ({
  clinicians: state.publicPatient?.signupForm?.clinicians,
  loading: isPending(state.responses, PUBLIC_GET_PRACTICE_PLANS),
  presetClinician: state.publicPatient?.presetClinician,
  presetSignUp: state.publicPatient?.presetSignUp,
});

const connector = connect(mapState);

export default connector(Clinician);

import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import AppButton from '^/common/app-button';
import ContactsPicker from '^/contacts/picker';
import { ContactResponse } from '^/contacts/types';
import { FilterField, FilterList } from '^/filters/types';

// Props that come from the parent component.
interface OwnProps {
  filters?: FilterList;
  heading?: string;
  hideContacts?: ReadonlyArray<string>;
  hideRecents?: boolean;
  searchFields?: ReadonlyArray<FilterField>;
  showCategories?: boolean;
  onCloseModal(): void;
  onContactSelected(contact: ContactResponse): void;
}

export type ContactPickerModalProps = OwnProps;

class ContactPickerModal extends React.PureComponent<ContactPickerModalProps> {
  public render() {
    const { hideContacts, heading = 'Pick a contact' } = this.props;

    return (
      <Modal onClickOutside={this.props.onCloseModal}>
        <ModalHeader>
          <h2>{heading}</h2>
          <AppButton transparent onClick={this.props.onCloseModal}>
            <FontAwesomeIcon icon="times" />
          </AppButton>
        </ModalHeader>
        {this.props.children}
        <ModalBody>
          <ContactsPicker
            {...this.props}
            onContactSelected={this.props.onContactSelected}
            hideContacts={hideContacts}
          />
        </ModalBody>
      </Modal>
    );
  }
}

// Disconnected version used for testing
export { ContactPickerModal as TestableContactPickerModal };

export default ContactPickerModal;

import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import { UserRole } from '^/admin/users/types';
import CollectionForm from '^/collections/collection-form';
import CollectionsTable from '^/collections/collections-table';
import CreateCollectionForm from '^/collections/create-collection-form';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import PageSection from '^/common/page-section/page-section';
import {
  REQUEST_INVOICE_SUMMARY,
  requestInvoiceSummary,
} from '^/contacts/ledger/actions';
import { closeModal, openModal } from '^/modals/actions';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { createCollection, getCollections } from './actions';
import { Collection } from './types';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  disabled?: boolean;
  isCompany?: boolean;
  isHeadOffice?: boolean;
}

export type CollectionsProps = OwnProps & ConnectedProps<typeof connector>;

class Collections extends React.PureComponent<CollectionsProps> {
  public render() {
    const { nextCollectionTotal, isCompany } = this.props;

    return (
      <PageSection
        heading="Collections"
        subHeading={`Next collection total: ${formatFeeAmount(
          nextCollectionTotal
        )}`}
      >
        <CollectionsTable
          contact={this.props.contactId}
          isHeadOffice={this.props.isHeadOffice}
        />
        <Allow
          roles={[
            UserRole.AdminLevel,
            UserRole.FinanceLevel,
            UserRole.OfficeLevel,
          ]}
        >
          <div className="page-section-buttons">
            <CreateCollectionForm
              onSubmit={this.handleCreateCollection}
              contactId={this.props.contactId}
              actions={[REQUEST_INVOICE_SUMMARY]}
              disabled={this.props.disabled}
              errorFields={['ledger_items']}
              isCompany={isCompany}
            />
          </div>
        </Allow>
      </PageSection>
    );
  }

  public handleSubmitCollection = (
    collection: Collection,
    dispatch: Dispatch
  ) => {
    return createCollection(collection)(dispatch).then(response => {
      if (response && response.status < 400) {
        this.props.closeModal();
        getCollections(
          this.props.contactId,
          undefined,
          undefined,
          this.props.isHeadOffice
        )(dispatch);
      }
    });
  };

  public handleCreateCollection = (
    sub: { subscription: string },
    dispatch: Dispatch
  ) => {
    return requestInvoiceSummary(sub)(dispatch).then(response => {
      this.props.openModal(
        <Modal
          key="collection-modal"
          onClickOutside={this.props.closeModal}
          large
        >
          <ModalHeader>
            <h2>Create Collection</h2>
            <AppButton transparent onClick={this.props.closeModal}>
              <FontAwesomeIcon icon="times" />
            </AppButton>
          </ModalHeader>
          <ModalBody>
            <CollectionForm
              initialValues={
                response && { ...response.data, entry_date: new Date() }
              }
              onSubmit={this.handleSubmitCollection}
              onClickBack={this.props.closeModal}
              subscriptionId={response && response.data.subscription}
            />
          </ModalBody>
        </Modal>
      );
    });
  };
}

// Disconnected version used for testing
export { Collections as TestableCollections };

export function mapState(state: StoreState, props: OwnProps) {
  return {
    nextCollectionTotal: state.nextCollectionTotals[props.contactId],
  };
}

const connector = connect(mapState, { closeModal, openModal });

export default connector(Collections);

import { Address, LoqateRetrieveResponse } from '^/address/types';
import { Contact, ContactResponse } from '^/contacts/types';

const POSTCODE_REGEX = /[A-Z][A-HK-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|[G][I][R] ?0[A]{2}/;

export const truncate = (text: string, maxLength: number): string =>
  text.length > maxLength ? `${text.substr(0, maxLength - 1)}…` : text;

export const validPostcode = (input: string = ''): boolean => {
  return POSTCODE_REGEX.test(input.toUpperCase());
};

export const formatPostcode = (input: string = ''): string => {
  if (!validPostcode(input)) {
    return input;
  }

  input = input.replace(' ', '');

  const outwardCode = input.substring(0, input.length - 3);
  const inwardCode = input.slice(-3);
  return `${outwardCode} ${inwardCode}`.toUpperCase();
};

export const formatAddress = (address?: Address | null): string => {
  if (!address) {
    return '-';
  }

  return [
    address.street_address,
    address.city,
    address.county && address.county.length > 0 && address.county,
    address.country && address.country.length > 0 && address.country,
    formatPostcode(address.postcode),
  ]
    .filter(Boolean)
    .join(', ');
};

export function sortContactAddresses(
  contact: Contact | null | undefined
): ReadonlyArray<Address> {
  if (!contact || !contact.addresses || contact.addresses.length <= 0) {
    return [];
  }

  return contact.addresses
    .slice(0)
    .sort((addressA: Address, addressB: Address): number => {
      const labels = ['primary', 'main', 'home'];

      for (const x in labels) {
        if (
          addressA.id === contact.mailing_address ||
          addressA.label.toLowerCase() === labels[x]
        ) {
          return -1;
        } else if (
          addressB.id === contact.mailing_address ||
          addressB.label.toLowerCase() === labels[x]
        ) {
          return 1;
        }
      }

      return 0;
    });
}

export const getPrimaryAddress = (
  contact: Contact | null | undefined
): Address | null => {
  if (!contact || !contact.addresses) {
    return null;
  }

  const sortedAddresses = sortContactAddresses(contact);

  return sortedAddresses.length > 0 ? sortContactAddresses(contact)[0] : null;
};

export const getPrimaryPostcode = (
  contact: ContactResponse,
  defaultValue: string = '-'
): string => {
  const primaryAddress = getPrimaryAddress(contact);

  return primaryAddress
    ? formatPostcode(primaryAddress.postcode)
    : defaultValue;
};

export function getAddressPhoneNumber(address?: Address): string | null {
  if (!address) {
    return null;
  }

  if (address.phone_1) {
    return address.phone_1;
  }

  if (address.phone_2) {
    return address.phone_2;
  }

  return null;
}

export const convertLoqateAddress = (
  address: LoqateRetrieveResponse
): Partial<Address> => {
  return {
    street_address: [
      address.Company,
      address.Line1,
      address.Line2,
      address.Line3,
      address.Line4,
      address.Line5,
    ]
      .filter(Boolean)
      .join(', '),
    city: address.City,
    county: address.Province,
    country: address.CountryName,
    postcode: address.PostalCode,
  };
};

import { Collapse } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { FieldArray } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { PageSubSectionBody } from '^/common/page-section/page-sub-section';
import AddNewPatient from '../form/add-patient';
import { Stepper } from '../stepper';

const PatientAddSection = () => {
  const [isContactDetailsPanelOpen, setIsContactDetailsPanelOpen] = useState(
    false
  );
  const onClickToggleCollapse = () =>
    setIsContactDetailsPanelOpen(!isContactDetailsPanelOpen);

  return (
    <PageSection className="patient-signup-title">
      <div
        className={classNames('collapse-header')}
        onClick={onClickToggleCollapse}
      >
        <h4>Additional Patients</h4>
        <FontAwesomeIcon
          icon={isContactDetailsPanelOpen ? 'chevron-up' : 'chevron-down'}
        />
      </div>
      <Collapse
        open={isContactDetailsPanelOpen}
        fadeOut
        fadeColor="#FFF"
        fadeHeight={50}
        animationDuration={200}
      >
        <PageSection>
          <PageSubSectionBody>
            <FieldArray<any>
              name="additional_patients"
              component={AddNewPatient}
            />
          </PageSubSectionBody>
          <Stepper stepOneActive stepTwoActive />
        </PageSection>
      </Collapse>
    </PageSection>
  );
};

export default PatientAddSection;

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import { StoreState } from '^/types';

// Props that come from the parent component.
interface OwnProps {
  roles?: ReadonlyArray<UserRole>;
  or?: boolean;
  children?: React.ReactNode;
}

export type AllowProps = OwnProps & ConnectedProps<typeof connector>;

class Allow extends React.PureComponent<AllowProps> {
  public render() {
    const { roles = [], loggedInUser, or } = this.props;

    const allowed = or || (loggedInUser && roles.includes(loggedInUser.role));

    if (!allowed) {
      return null;
    }

    return this.props.children;
  }
}

// Disconnected version used for testing
export { Allow as TestableAllow };

export const mapState = (state: StoreState) => ({
  loggedInUser: state.loggedInUser,
});

const connector = connect(mapState, {});

export default connector(Allow);

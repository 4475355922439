import { Column, Row } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { formatAddress } from '^/address/helpers';
import { AddressResponse } from '^/address/types';
import ErrorRenderer from '^/common/error-renderer';
import { getContactName } from '^/common/helper-functions';
import { ContactResponse } from '^/contacts/types';

interface Option {
  contact: ContactResponse;
  address: AddressResponse;
}

interface OwnProps {
  /** This field should be disabled, stops the dropdown from working and greys it out. */
  disabled?: boolean;
  /** Label for this field, shown above the dropdown. */
  label?: string;
  /** This field should be short - adds a class that sets a max width. */
  short?: boolean;
  shorter?: boolean;
  shortest?: boolean;
  contacts: ReadonlyArray<ContactResponse | undefined | null>;
}

export type RenderCorrespondenceDropdownProps = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class RenderCorrespondenceDropdown extends React.PureComponent<
  RenderCorrespondenceDropdownProps
> {
  public render() {
    const {
      input,
      label,
      short,
      shorter,
      shortest,
      meta: { touched, error, warning, dirty, pristine },
      disabled,
      defaultValue,
      contacts,
    } = this.props;

    const options = this.getOptions(contacts);
    const selectedOption = options.find(
      option => option.address.id === input.value
    );

    return (
      <div
        className={classnames([
          'form-field',
          {
            short,
            shorter,
            shortest,
            dirty,
            pristine,
            error: Boolean(touched && error),
            'no-selection': !input || !input.value,
          },
        ])}
      >
        <Row className="correspondence-group">
          <Column xs={6} md={2}>
            {label && <label>{label}</label>}
          </Column>
          <Column xs={6} md={3}>
            <select defaultValue={defaultValue} {...input} disabled={disabled}>
              <option value="">Please choose...</option>
              {options.map((option, index) => (
                <option key={index} value={option.address.id}>
                  {`${getContactName(option.contact)} (${
                    option.address.label
                  })`}
                </option>
              ))}
            </select>
          </Column>
          <Column xs={12} md={6}>
            {formatAddress(selectedOption && selectedOption.address)}
          </Column>
        </Row>
        <div className="form-field-errors">
          {touched && <ErrorRenderer error={[error, warning]} />}
        </div>
      </div>
    );
  }

  private getOptions(
    contacts: ReadonlyArray<ContactResponse | undefined | null>
  ): ReadonlyArray<Option> {
    return contacts.reduce(
      (accumulator, contact) =>
        contact
          ? accumulator.concat(
              contact.addresses.map(address => ({
                contact,
                address,
              }))
            )
          : accumulator,
      [] as ReadonlyArray<Option>
    );
  }
}

export { RenderCorrespondenceDropdown as TestableRenderCorrespondenceDropdown };

export default RenderCorrespondenceDropdown;

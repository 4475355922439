import { AxiosError } from 'axios';
import { SubmissionError } from 'redux-form';

export const rethrowOnUserError = (error: AxiosError): boolean =>
  error.response !== undefined &&
  400 <= error.response.status &&
  error.response.status < 500;

export const rethrowOnServerError = (error: AxiosError): boolean =>
  error.response !== undefined && 500 <= error.response.status;

export const rethrowOnAnyError = (error: AxiosError): boolean =>
  error.response !== undefined && 400 <= error.response.status;

export const alwaysRethrow = (): boolean => true;

export const throwSubmissionError = (response: AxiosError) => {
  const errors = response.response?.data || {};

  throw new SubmissionError({
    ...errors,
    _error: errors.non_field_errors,
  });
};

import { BankStatusTypes } from '^/payment-details/types';

export const BANK_STATUS: { [type: string]: string } = {
  [BankStatusTypes.SetupNewInstruction]: 'Setup New Instruction (0N)',
  [BankStatusTypes.CancelInstruction]: 'Cancel Instruction (0C)',
  [BankStatusTypes.FirstCollection]: 'First Collection (01)',
  [BankStatusTypes.RegularCollection]: 'Regular Collection (17)',
  [BankStatusTypes.RePresentedCollection]: 'Re-presented Collection (18)',
  [BankStatusTypes.FinalCollection]: 'Final Collection (19)',
  [BankStatusTypes.NoDD]: 'No Direct Debit (99)',
};

export const BANK_STATUS_OPTIONS = [
  {
    label: BANK_STATUS[BankStatusTypes.SetupNewInstruction],
    value: BankStatusTypes.SetupNewInstruction,
  },
  {
    label: BANK_STATUS[BankStatusTypes.CancelInstruction],
    value: BankStatusTypes.CancelInstruction,
  },
  {
    label: BANK_STATUS[BankStatusTypes.FirstCollection],
    value: BankStatusTypes.FirstCollection,
  },
  {
    label: BANK_STATUS[BankStatusTypes.RegularCollection],
    value: BankStatusTypes.RegularCollection,
  },
  {
    label: BANK_STATUS[BankStatusTypes.RePresentedCollection],
    value: BankStatusTypes.RePresentedCollection,
  },
  {
    label: BANK_STATUS[BankStatusTypes.FinalCollection],
    value: BankStatusTypes.FinalCollection,
  },
  {
    label: BANK_STATUS[BankStatusTypes.NoDD],
    value: BankStatusTypes.NoDD,
  },
];

export function formatSortCode(input: string): string {
  const stripped = input.replace(/-/g, '');

  if (/^[0-9]{6}$/.test(stripped)) {
    return [
      stripped.substring(0, 2),
      stripped.substring(2, 4),
      stripped.substring(4, 6),
    ].join('-');
  }

  return input;
}

import React from 'react';

import Footer from '^/footer';
import GlobalModalRenderer from '^/modals/global-modal-renderer';
import NavigationBar from '^/navigation';

class PageContainer extends React.Component {
  public render() {
    return (
      <div className="page">
        <NavigationBar />
        {this.props.children}
        <GlobalModalRenderer />
        <Footer />
      </div>
    );
  }
}

export default PageContainer;

import { AnyAction } from 'redux';

import {
  ADD_CONTACT_TO_BACS,
  CLEAR_BACS,
  CLEAR_LEDGER_ENTRIES,
  GET_LEDGER_ENTRIES,
  REMOVE_CONTACT_FROM_BACS,
} from '^/ledger/actions';
import { StoreState } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

export function ledgerEntries(
  state: StoreState['ledgerEntries'] = null,
  action: AnyAction
): StoreState['ledgerEntries'] {
  switch (action.type) {
    case GET_LEDGER_ENTRIES.SUCCESS:
      return paginatedReducer(state, action);
    case CLEAR_LEDGER_ENTRIES:
      return null;
    default:
      return state;
  }
}

export function pendingBACSSubmission(
  state: StoreState['pendingBACSSubmission'] = [],
  action: AnyAction
): StoreState['pendingBACSSubmission'] {
  switch (action.type) {
    case ADD_CONTACT_TO_BACS:
      return [action.payload, ...state];
    case REMOVE_CONTACT_FROM_BACS:
      const entry = action.payload;

      return state.filter(
        row =>
          !(
            row.contact === entry.contact &&
            row.code === entry.code &&
            row.amount === entry.amount
          )
      );
    case CLEAR_BACS:
      return [];
    default:
      return state;
  }
}

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import AppButton from '^/common/app-button';
import {
  getContactCommsMethod,
  getContactDob,
  getContactName,
} from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import { SectionDetail } from '^/common/section-detail';
import SectionDetailList from '^/common/section-detail-list';
import { contactActions, saveContact } from '^/contacts/actions';
import PersonsForm from '^/contacts/persons/form';
import { Contact, getContactCategoryName } from '^/contacts/types';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

export enum Mode {
  /** Shows a list of properties. */
  List = 'LIST',
  /** Shows an edit form and allows the user to update the person. */
  Edit = 'EDIT',
}

interface OwnProps {
  /** Person ID to retrieve and show details for. */
  personId: string;
  disabled?: boolean;
}

interface StateProps {
  /** Mode of the card, can be Edit or List. */
  mode: Mode;
}

export type PersonsDetailsCardProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

export class PersonsDetailsCard extends React.PureComponent<
  PersonsDetailsCardProps
> {
  public render() {
    const { mode, contact, loading, disabled } = this.props;

    if (!contact || !contact.person) {
      return loading ? (
        <Loading />
      ) : (
        <div className="empty-state">Not found</div>
      );
    }

    switch (mode) {
      case Mode.Edit:
        return (
          <PersonsForm
            initialValues={contact}
            onSubmit={this.onSave}
            onCancel={this.showListMode}
          />
        );
      case Mode.List:
      default:
        return (
          <div className="flex-row">
            <SectionDetailList emptyStateMessage="No details">
              <SectionDetail label="Name">
                {getContactName(contact)}
              </SectionDetail>
              <SectionDetail label="Date of Birth">
                {getContactDob(contact)}
              </SectionDetail>
              {contact.person.mobile && (
                <SectionDetail label="Mobile">
                  {contact.person.mobile}
                </SectionDetail>
              )}
              {contact.person.email && (
                <SectionDetail label="Email">
                  <a href={`mailto:${contact.person.email}`}>
                    {contact.person.email}
                  </a>
                </SectionDetail>
              )}
              <SectionDetail label="Contact Preferences">
                {getContactCommsMethod(contact)}
              </SectionDetail>
              {contact.category && (
                <SectionDetail label="Category">
                  {getContactCategoryName(contact)}
                </SectionDetail>
              )}
            </SectionDetailList>
            <div className="page-subsection-buttons">
              <AppButton onClick={this.showEditMode} small disabled={disabled}>
                {Mode.Edit}
              </AppButton>
            </div>
          </div>
        );
    }
  }

  /**
   * Switches this card into edit mode.
   */
  public showEditMode = () => this.props.setProps({ mode: Mode.Edit });

  /**
   * Switches this card into list mode.
   */
  public showListMode = () => this.props.setProps({ mode: Mode.List });

  /**
   * Fires off the action that will save this person. Sets the card to list mode if successful.
   * @param {Contact} contact - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public onSave = (contact: Contact, dispatch: Dispatch) => {
    return saveContact(
      this.props.personId,
      contact
    )(dispatch).then(() => {
      this.props.setProps({ mode: Mode.List });
    });
  };
}

export { PersonsDetailsCard as TestablePersonsDetailsCard };

export const getInitialProps = () => ({
  mode: Mode.List,
});

export const mapState = (state: StoreState, props: OwnProps) => ({
  contact: getItemFromCache(props.personId, state.contactsCache),
  loading: anyPending(state.responses, contactActions),
});

const connector = connect(mapState, {});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(PersonsDetailsCard)
);

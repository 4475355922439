import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Card from '^/common/card';
import PageContent from '^/page-container/page-content';

export type LoginFailedProps = RouteComponentProps;

class LoginFailed extends React.PureComponent<LoginFailedProps> {
  public render() {
    return (
      <PageContent>
        <Card className="login-page">
          <div className="logo">
            <img src="/static/agilio-iplan-logo.svg" />
          </div>
          <div>
            <h4>We are unable to log you in.</h4>
            <p>
              We're sorry but we are currently unable to log you in. Please
              contact a member of the Agilio team if this issue persists.
            </p>
            <p>
              Alternatively <a href="/login">click here</a> to try and login
              again.
            </p>
          </div>
        </Card>
      </PageContent>
    );
  }
}

export { LoginFailed as TestableLoginFailed };
export default LoginFailed;

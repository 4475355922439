import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import PracticeSelector from '^/iplan/common/practice-selector';
import { GET_LOGGED_IN_USER, logout } from '^/login/actions';
import { loggedInUser } from '^/middleware/check-for-logout';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { CHANGE_PASSWORD_URL } from '^/utils/constants';

export type PatientsListPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps;
class PatientsListPage extends React.PureComponent<PatientsListPageProps> {
  public render() {
    const { logout: logoutUser } = this.props;

    return this.props.loading ? (
      <Loading />
    ) : (
      <PageContent page="practice-patient-landing">
        <div className="card">
          <div className="logo-container">
            <img className="logo" src="/static/agilio-iplan-logo.svg" />
          </div>
          <text className="add-patient-text">Create new patient</text>
          <div>
            <text className="practice-selector-text">Select practice</text>
          </div>
          <div className="practice-selector">
            <PracticeSelector />
          </div>

          <AppButton className="add-patient-button" onClick={this.onAddPatient}>
            <text className="plus-sign">+</text>{' '}
            <text className="add-patient-button-text">Add Patient</text>
          </AppButton>
        </div>

        <div className="user-name">
          Logged in as {loggedInUser?.first_name} {loggedInUser?.last_name}
        </div>
        <div className="side-by-side-container">
          <div className="change-password-button">
            <a className="change-password-button" href={CHANGE_PASSWORD_URL}>
              Change password
            </a>
          </div>
          <div className="logout-button">
            <a className="logout-button" onClick={logoutUser}>
              Logout
            </a>
          </div>
        </div>
      </PageContent>
    );
  }

  private onAddPatient = () => this.props.history.push('/new-patient');
}

export { PatientsListPage as TestablePatientsListPage };
export const mapState = (state: StoreState) => ({
  userRole: state.loggedInUser?.role,
  selectedPractice: state.iplan?.selectedPracticeId,
  loggedInUser: state.loggedInUser,
  path: state.router.location.pathname,
  loading: isPending(state.responses, GET_LOGGED_IN_USER),
});

const connector = connect(mapState, { logout });

export default connector(PatientsListPage);

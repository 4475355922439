import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import Container from '@dabapps/roe/dist/js/components/grid/container';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import {
  DELETE_PRODUCT,
  deleteProduct,
  GET_PRODUCT,
  GET_PRODUCTS,
  getProduct,
  saveProduct,
} from '^/admin/products/actions';
import ProductsForm from '^/admin/products/form';
import { Product } from '^/admin/products/types';
import AppButton from '^/common/app-button';
import ConfirmationModal from '^/common/confirmation-modal';
import { ErrorPage } from '^/common/error-page';
import ErrorRenderer from '^/common/error-renderer';
import HeaderBar from '^/common/header-bar';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import Sidebar from '^/common/sidebar';
import SidebarCard, { SidebarCardSection } from '^/common/sidebar-card';
import { closeModal, openModal } from '^/modals/actions';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

export type ProductsEditPageProps = RouteComponentProps<{ id: string }> &
  ConnectedProps<typeof connector>;

class ProductsEditPage extends React.PureComponent<ProductsEditPageProps> {
  public componentDidMount(): void {
    const {
      product,
      match: { params },
    } = this.props;

    if (cachedItemHasExpired(product)) {
      this.props.getProduct(params.id);
    }
  }

  public render() {
    const { product, loading } = this.props;

    if (!product) {
      if (loading) {
        return (
          <PageContent>
            <HeaderBar title="Product" transparent loading />
            <Loading />
          </PageContent>
        );
      }
      return <ErrorPage heading="Product not found" />;
    }

    return (
      <PageContent>
        <HeaderBar
          title={product.description}
          subtitle={product.product_code}
          transparent
        />
        <Container>
          <Row>
            <Column xs={12} md={7} lg={8}>
              <PageSection>
                <ProductsForm
                  form="edit-product-form"
                  initialValues={product}
                  onSubmit={this.onSave}
                  onCancel={this.onCancel}
                />
              </PageSection>
            </Column>
            <Sidebar>
              <SidebarCard heading="Delete product" transparent>
                <SidebarCardSection>
                  <p>
                    Deleting this product will make it unavailable for any
                    further invoicing
                  </p>
                  <div className="buttons">
                    <AppButton onClick={this.openDeleteModal} destructive>
                      Delete Product
                    </AppButton>
                  </div>
                  <ErrorRenderer
                    actions={[DELETE_PRODUCT]}
                    fields={['non_field_errors']}
                    showStatusErrors
                  />
                </SidebarCardSection>
              </SidebarCard>
            </Sidebar>
          </Row>
        </Container>
      </PageContent>
    );
  }

  public deleteProduct = () =>
    this.props.product?.id &&
    this.props
      .deleteProduct(this.props.product.id)
      .then(
        response =>
          response &&
          response.status === 204 &&
          this.props.history.replace('/admin/products')
      );

  public openDeleteModal = () =>
    this.props.openModal(
      <ConfirmationModal
        heading="Are you sure?"
        message={`This will delete the product '${this.props.product?.description}'. You will no longer be able to invoice for this product.`}
        action={this.deleteProduct}
        closeModal={this.props.closeModal}
        actionLabel="Delete"
      />
    );

  public onSave = (product: Product, dispatch: Dispatch) =>
    saveProduct(
      this.props.match.params.id,
      product
    )(dispatch).then(() => {
      this.props.history.push(`/admin/products`);
    });

  /**
   * Cancels the submission of this form. Goes back to the previous page if there is one, or to /products if there is no history.
   */
  public onCancel = () => {
    this.props.history.push('/admin/products');
  };
}

// Disconnected version used for testing
export { ProductsEditPage as TestableProductsEditPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ id: string }>
) => ({
  product: getItemFromCache(props.match.params.id, state.productCache),
  loading: anyPending(state.responses, [
    GET_PRODUCTS,
    GET_PRODUCT,
    DELETE_PRODUCT,
  ]),
});

const connector = connect(mapState, {
  getProduct,
  deleteProduct,
  openModal,
  closeModal,
});

export default connector(ProductsEditPage);

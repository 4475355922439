import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router';
import { Link } from 'react-router-dom';

import { UserRole } from '^/admin/users/types';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import HeaderBar from './header-bar';

export const AUTHENTICATED_ROLES = [
  UserRole.AdminLevel,
  UserRole.FinanceLevel,
  UserRole.OfficeLevel,
  UserRole.SalesLevel,
  UserRole.PracticeAdminLevel,
  UserRole.PracticeUserLevel,
  UserRole.PracticePatientLevel,
];

// Props that come from the parent component.
interface OwnProps {
  roles?: ReadonlyArray<UserRole>;
  redirect?: string;
}

export type AllowRouteProps = OwnProps &
  RouteProps &
  ConnectedProps<typeof connector>;

class AllowRoute extends React.PureComponent<AllowRouteProps> {
  public render() {
    const { roles = AUTHENTICATED_ROLES, loggedInUser } = this.props;

    const allowed = loggedInUser && roles.includes(loggedInUser.role);

    if (allowed) {
      return <Route {...this.props} />;
    }

    return <Route {...this.props} component={this.permissionError} />;
  }

  public permissionError = () => {
    const { redirect } = this.props;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <PageContent>
        <HeaderBar title="Permission error" transparent>
          <span>
            You do not have permission to view this page. Please contact an
            administrator or <Link to="/">go back</Link>.
          </span>
        </HeaderBar>
      </PageContent>
    );
  };
}

// Disconnected version used for testing
export { AllowRoute as TestableAllowRoute };

export const mapState = (state: StoreState) => ({
  loggedInUser: state.loggedInUser,
});

const connector = connect(mapState, {});

export default connector(AllowRoute);

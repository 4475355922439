import { anyPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { GET_PRODUCTS, getProducts } from '^/admin/products/actions';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { required } from '^/common/validation';
import { ProductInvoice } from '^/contacts/ledger/types';
import RenderDateTimeField from '^/form-helpers/render-date-time-field';
import RenderProductInvoiceItems from '^/form-helpers/render-product-invoice-items';
import RenderTextArea from '^/form-helpers/render-text-area';
import { StoreState } from '^/types';

interface OwnProps {
  onClickBack: () => void;
}

export type ProductInvoiceFormProps = InjectedFormProps<
  ProductInvoice,
  OwnProps
> &
  OwnProps &
  ConnectedProps<typeof connector>;

export class ProductInvoiceForm extends React.PureComponent<
  ProductInvoiceFormProps
> {
  public componentDidMount(): void {
    this.props.getProducts();
  }

  public render() {
    const { handleSubmit, onClickBack, error, warning, loading } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="modal-body-section">
          <FieldArray<{}>
            name="ledger_items"
            component={RenderProductInvoiceItems}
          />
          <FormGroup>
            <Field
              label="Entry date"
              name="entry_date"
              component={RenderDateTimeField}
              validate={required}
            />
            <Field
              label="Comments"
              name="comments"
              type="text"
              component={RenderTextArea}
              stretch
            />
          </FormGroup>
          <ErrorRenderer
            actions={[GET_PRODUCTS]}
            fields={['non_field_errors', 'amount', 'vat_amount']}
            showStatusErrors
            error={[error, warning]}
          />
        </div>
        <div className="modal-body-section form-buttons">
          <AppButton onClick={onClickBack}>Back</AppButton>
          <AppButton type="submit" primary loading={loading}>
            Generate product invoice
          </AppButton>
        </div>
      </Form>
    );
  }
}

// Disconnected version used for testing
export { ProductInvoiceForm as TestableProductInvoiceForm };

export const mapState = (state: StoreState) => ({
  products: state.products,
  loading: anyPending(state.responses, [GET_PRODUCTS]),
});

const connector = connect(mapState, { getProducts });

export default reduxForm<ProductInvoice, OwnProps>({
  form: 'generateProductInvoiceForm',
})(connector(ProductInvoiceForm));

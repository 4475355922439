import { isPending } from '@dabapps/redux-requests';
import { TableBody, TableCell, TableHead, TableRow } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import AppButton from '^/common/app-button';
import ConfirmationModal from '^/common/confirmation-modal';
import Loading from '^/common/loading';
import PageSubSection from '^/common/page-section/page-sub-section';
import PaginatedTable from '^/common/paginated-table';
import ContactPickerModal from '^/contacts/picker-modal';
import { ContactResponse } from '^/contacts/types';
import MembershipPickerModal from '^/memberships/picker-modal';
import { createMembershipSubscription } from '^/memberships/subscriptions/actions';
import {
  MembershipSubscription,
  MembershipSubscriptionInjectedProps,
} from '^/memberships/subscriptions/types';
import { closeModal, openModal } from '^/modals/actions';
import {
  createSubscriptionContact,
  GET_BILLING_CONTAINER_SUBSCRIPTION_CONTACTS,
  GET_SUBSCRIPTION_CONTACTS,
  getBillingContainerSubscriptionContacts,
  getSubscriptionContacts,
} from '^/plans/subscriptions/actions';
import { RelationshipType } from '^/relationships/types';
import { ContactType, StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import SubscriptionContactsRow from './subscription-contacts-row';

interface OwnProps {
  subscriptionId: string;
  payer_id: string;
  start_date?: moment.Moment;
  disabled?: boolean;
  isBillingContainerSubscription?: boolean;
  contactId?: string;
  initialValues?: Partial<MembershipSubscription>;
}

export type SubscriptionContactsTableProps = OwnProps &
  ConnectedProps<typeof connector>;

class SubscriptionContactsTable extends React.PureComponent<
  SubscriptionContactsTableProps
> {
  public componentDidMount() {
    this.refreshSubscriptionContacts(1, 10);
  }

  public refreshSubscriptionContacts = (page?: number, pageSize?: number) => {
    const { subscriptionId, subscriptionContacts } = this.props;

    pageSize = pageSize ? pageSize : subscriptionContacts.pageSize;
    if (this.props.isBillingContainerSubscription) {
      this.props.getBillingContainerSubscriptionContacts(
        subscriptionId,
        page,
        pageSize
      );
    } else {
      this.props.getSubscriptionContacts(subscriptionId, page, pageSize);
    }
  };

  public changePage = (page: number, pageSize?: number) => {
    this.refreshSubscriptionContacts(page, pageSize);
  };

  public render() {
    const {
      subscriptionContacts,
      disabled,
      loading,
      isBillingContainerSubscription,
      initialValues,
    } = this.props;

    if (loading || !subscriptionContacts) {
      return <Loading />;
    }

    return (
      <PageSubSection
        vertical
        heading={
          isBillingContainerSubscription
            ? 'Memberships'
            : 'Subscription contacts'
        }
        actions={
          <AppButton
            className="align-right"
            onClick={this.handleAddClicked}
            disabled={disabled}
            loading={loading}
            small
          >
            Add
          </AppButton>
        }
      >
        <PaginatedTable
          changePage={this.changePage}
          paginatedData={subscriptionContacts}
          loading={loading}
          showPaginationBar
        >
          <TableHead>
            <TableRow>
              <TableCell>
                {isBillingContainerSubscription ? 'Practice' : 'Name'}
              </TableCell>
              <TableCell
                className={isBillingContainerSubscription ? '' : 'align-right'}
                title="Date added"
              >
                Date added
              </TableCell>
              {isBillingContainerSubscription ? (
                <>
                  <TableCell title="Membership">Membership</TableCell>
                  <TableCell title="Status">Status</TableCell>
                </>
              ) : (
                <>
                  <TableCell title="Lapsed">Lapsed</TableCell>
                  <TableCell className="align-right" title="Lapse date">
                    Lapse date
                  </TableCell>
                  <TableCell title="Lapse reason">Lapse reason</TableCell>
                  <TableCell colSpan={2}>Actions</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getCurrentPage(subscriptionContacts).map(subContact => (
              <SubscriptionContactsRow
                key={subContact.id}
                subscriptionContact={subContact}
                disabled={disabled}
                isBillingContainerSubscription={isBillingContainerSubscription}
                isParentBillingContainerSubscription={Boolean(
                  initialValues?.billing_container_subscription
                )}
              />
            ))}
          </TableBody>
        </PaginatedTable>
      </PageSubSection>
    );
  }

  public handleAddClicked = () => {
    const {
      subscriptionContacts,
      payer_id,
      isBillingContainerSubscription = false,
    } = this.props;

    const activeContacts = getCurrentPage(subscriptionContacts).map(contact =>
      !contact.lapsed
        ? isBillingContainerSubscription
          ? contact.id
          : contact.contact
        : ''
    );

    this.props.openModal(
      <ContactPickerModal
        onCloseModal={this.props.closeModal}
        onContactSelected={this.handleContactPicked}
        filters={{
          // TODO: type here needs to be parameterised
          type: isBillingContainerSubscription
            ? ContactType.Practice
            : ContactType.Patient,
          related_contact: payer_id,
          relationship: RelationshipType.PayeePayer,
          include_related_contact: 'true',
        }}
        hideContacts={activeContacts}
        hideRecents
      />
    );
  };

  public handleCreateMemberships(
    memberships: MembershipSubscription[],
    dispatch: Dispatch,
    contactId: string,
    subscriptionId: string
  ) {
    const amendedMemberships = memberships.map(subscription => {
      const linkedSubscription = {
        ...subscription,
        contact: contactId,
        billing_container_subscription: subscriptionId,
        payer: this.props.payer_id,
        start_date: moment(this.props.start_date).format('YYYY-MM-DD'),
        plan: subscription.id,
        override_fees_at_renewal: true,
        sub_length_months: this.props.initialValues?.plan_length_months,
        associated_bank: this.props.initialValues?.associated_bank,
        promotional_price: this.props.initialValues?.promotional_price,
        promotional_price_months: this.props.initialValues
          ?.promotional_price_months,
        promotional_price_start_date: this.props.initialValues
          ?.promotional_price_start_date,
        reminder_to: this.props.initialValues?.reminder_to,
        renewal_date: this.props.initialValues?.renewal_date,
        payment_method: this.props.initialValues?.payment_method,
        auto_renew: this.props.initialValues?.auto_renew!,
        invoice_made_out_to: this.props.initialValues?.invoice_made_out_to,
        invoice_to: this.props.initialValues?.invoice_to,
        next_payment_due: this.props.initialValues?.next_payment_due,
        last_renewal_date: this.props.initialValues?.last_renewal_date,
      };
      return linkedSubscription;
    });

    const createMembershipSubscriptionsAll = Promise.all(
      amendedMemberships.map(membership =>
        createMembershipSubscription(membership)(dispatch)
      )
    );

    createMembershipSubscriptionsAll
      .then(() => {
        window.location.reload();
      })
      .catch(e => {
        closeModal();
        openModal(
          <ConfirmationModal
            heading="Error creating subscription membership"
            message={e.errors.non_field_errors || e.message}
            closeModal={closeModal}
          />
        );
      });
  }

  public handleContactPicked = async (contact: ContactResponse) => {
    try {
      if (this.props.isBillingContainerSubscription) {
        this.props.closeModal();
        this.props.openModal(
          <MembershipPickerModal
            contactId={contact.id}
            onCloseModal={this.props.closeModal}
            onSubmit={
              // tslint:disable-next-line jsx-no-lambda
              (
                { memberships }: MembershipSubscriptionInjectedProps,
                dispatch
              ) =>
                this.handleCreateMemberships(
                  memberships,
                  dispatch,
                  contact.id,
                  this.props.subscriptionId
                )
            }
          />
        );
      } else {
        await this.props.createSubscriptionContact(
          this.props.subscriptionId,
          contact.id
        );
        this.props.closeModal();
      }
    } catch (e) {
      this.props.openModal(
        <ConfirmationModal
          heading="Error creating subscription contact"
          message={e.errors.non_field_errors || e.message}
          closeModal={this.props.closeModal}
        />
      );
    }
  };
}

// Disconnected version used for testing
export { SubscriptionContactsTable as TestableSubscriptionContactsTable };

export const mapState = (state: StoreState, props: OwnProps) => {
  const subscriptionType = props.isBillingContainerSubscription
    ? GET_BILLING_CONTAINER_SUBSCRIPTION_CONTACTS
    : GET_SUBSCRIPTION_CONTACTS;
  return {
    contactsCache: state.contactsCache,
    subscriptionContacts:
      state.paginatedSubscriptionContacts[props.subscriptionId] || undefined,
    loading: isPending(state.responses, subscriptionType),
  };
};

const connector = connect(mapState, {
  openModal,
  closeModal,
  createSubscriptionContact,
  getSubscriptionContacts,
  getBillingContainerSubscriptionContacts,
});

export default connector(SubscriptionContactsTable);

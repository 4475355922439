import { anyPending } from '@dabapps/redux-requests';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { formatDate } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import {
  formatFeeAmount,
  getPlanName,
  getSubscriptionName,
} from '^/plans/helpers';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import {
  GET_MEMBERSHIP_SUBSCRIPTIONS,
  getMembershipSubscriptions,
} from './actions';
import { MembershipSubscriptionResponse } from './types';

interface OwnProps {
  contactId: string;
  isCompany?: boolean;
}

export type MembershipSubscriptionsTableProps = OwnProps &
  ConnectedProps<typeof connector>;

class MembershipSubscriptionsTable extends React.Component<
  MembershipSubscriptionsTableProps
> {
  public componentDidMount() {
    const { loading, contactId } = this.props;

    if (!loading) {
      this.props.getMembershipSubscriptions(
        contactId,
        undefined,
        undefined,
        false
      );
    }
  }

  public render() {
    const subscriptions = this.props.membershipSubscriptions[
      this.props.contactId
    ];

    return (
      <PaginatedTable
        paginatedData={subscriptions}
        changePage={this.changePage}
        loading={this.props.loading}
      >
        <TableHead>
          <TableRow>
            <TableHeader>Reference</TableHeader>
            <TableCell className="align-right">Fee</TableCell>
            <TableCell>Term</TableCell>
            <TableCell>Next Renewal</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(subscriptions).map(subscription => (
            <ItemRow
              key={subscription.id}
              item={subscription}
              onClick={this.handleClick}
            >
              <TableHeader title={getPlanName(subscription.plan_detail)}>
                {getSubscriptionName(subscription, true)}
              </TableHeader>
              <TableCell className="align-right">
                {subscription.calculated_fees &&
                  formatFeeAmount(subscription.calculated_fees.fee)}
              </TableCell>
              <TableCell>
                {subscription.sub_length_months > 1 ||
                subscription.sub_length_months === 0
                  ? `${subscription.sub_length_months} months`
                  : `${subscription.sub_length_months} month`}
              </TableCell>
              <TableCell>{formatDate(subscription.renewal_date)}</TableCell>
              <TableCell
                className={classNames({
                  error: subscription.suspended || subscription.lapsed,
                })}
              >
                {subscription.suspended
                  ? 'Suspended'
                  : subscription.lapsed
                  ? 'Lapsed'
                  : 'Active'}
              </TableCell>
            </ItemRow>
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public handleClick = (subscription: MembershipSubscriptionResponse) => {
    // if contact is company make sure we go to the correct membership subscription
    this.props.push(
      `/membership-subscriptions/${subscription.id}${
        this.props.isCompany ? '/' + this.props.contactId : ''
      }`
    );
  };

  public changePage = (page: number, pageSize: number) => {
    const excludeBillingContainerMemberships = !this.props.isCompany;
    this.props.getMembershipSubscriptions(
      this.props.contactId,
      page,
      pageSize,
      excludeBillingContainerMemberships
    );
  };
}

export { MembershipSubscriptionsTable as TestableMembershipSubscriptionsTable };

export const mapState = (state: StoreState) => ({
  membershipSubscriptions: state.membershipSubscriptions,
  loading: anyPending(state.responses, [GET_MEMBERSHIP_SUBSCRIPTIONS]),
});

const connector = connect(mapState, {
  getMembershipSubscriptions,
  push,
});

export default connector(MembershipSubscriptionsTable);

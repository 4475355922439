import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { isPending } from '@dabapps/redux-requests';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { submit } from 'redux-form';

import {
  CREATE_LEDGER_ENTRY,
  createLedgerEntry,
  getContactLedger,
} from '^/contacts/ledger/actions';
import {
  calculateLedgerUnbalance,
  mapEntriesToAllocations,
} from '^/contacts/ledger/helpers';
import LedgerEntryPicker from '^/contacts/ledger/ledger-entry-picker';
import {
  Bank,
  Ledger,
  LEDGER_ENTRY_TYPES,
  LedgerEntryType,
  LedgerResponse,
  PaymentMethod,
} from '^/contacts/ledger/types';
import { Contact } from '^/contacts/types';
import { closeModal } from '^/modals/actions';
import WizardModal, {
  WizardModalError,
  WizardModalStage,
} from '^/modals/wizard-modal';
import { ContactType, StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import CreateLedgerEntryForm from './forms/create-ledger-entry-form';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  type: LedgerEntryType;
  allocateToTypes: ReadonlyArray<LedgerEntryType>;
  initialSelectedEntries: ReadonlyArray<LedgerResponse>;
}

interface StateProps {
  stage: Stage;
  selectedEntries: ReadonlyArray<LedgerResponse>;
}

export type CreateLedgerEntryModalProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

export enum Stage {
  Select,
  Allocate,
}

class CreateLedgerEntryModal extends React.PureComponent<
  CreateLedgerEntryModalProps
> {
  public render() {
    const { stage: stage, type } = this.props;

    return (
      <WizardModal heading={`Create ${LEDGER_ENTRY_TYPES[type]}`}>
        {this.getStage(stage)}
      </WizardModal>
    );
  }

  public getStage = (stage: Stage) => {
    const {
      loading,
      selectedEntries,
      type,
      allocateToTypes,
      contactId,
    } = this.props;

    const friendlyType = LEDGER_ENTRY_TYPES[type].toLowerCase();
    switch (stage) {
      case Stage.Select:
        return (
          <>
            <WizardModalStage
              heading={`Select entries to allocate this ${friendlyType} to (Step 1 of 2)`}
              next={this.nextStage}
              nextText={
                selectedEntries.length < 1
                  ? 'Skip'
                  : selectedEntries.length === 1
                  ? 'Continue'
                  : `Continue with ${selectedEntries.length} selected entries`
              }
            >
              <LedgerEntryPicker
                filters={{
                  contact_1: contactId,
                  type: allocateToTypes.join(','),
                  outstanding:
                    type !== LedgerEntryType.RecordRefund ? 'True' : undefined,
                }}
                selected={selectedEntries}
                onSelectionChanged={this.handleSelectionChanged}
              />
            </WizardModalStage>
          </>
        );
      case Stage.Allocate:
        return (
          <WizardModalStage
            heading={`Create a new ${friendlyType} ${
              selectedEntries.length ? 'with' : 'without'
            } allocations (Step 2 of 2)`}
            back={this.prevStage}
            next={this.nextStage}
            nextText={`Create ${friendlyType}`}
            loading={loading.createLedgerEntry}
          >
            <CreateLedgerEntryForm
              initialValues={{
                type,
                contact_1: contactId,
                entry_date: moment().toISOString(),
                detail_detail: {
                  bank:
                    getItemFromCache<Contact>(
                      this.props.contactId,
                      this.props.contactsCache
                    )?.type === ContactType.Practice
                      ? Bank.CodePlan
                      : Bank.ClientAccount,
                },
                allocations: mapEntriesToAllocations(selectedEntries),
                amount: this.sumEntryOutstandingAmounts(selectedEntries),
                vat_amount: 0,
                payment_method:
                  type === LedgerEntryType.RecordReceipt
                    ? PaymentMethod.DirectDebit
                    : undefined,
              }}
              onSubmit={this.handleSubmitEntry}
              hideButtons
            />
          </WizardModalStage>
        );
      default:
        return <WizardModalError />;
    }
  };

  public nextStage = () => {
    switch (this.props.stage) {
      case Stage.Select:
        this.props.setProps({ stage: Stage.Allocate });
        break;
      case Stage.Allocate:
        this.props.submit('CreateLedgerEntryForm');
        break;
      default:
        break;
    }
  };

  public prevStage = () => {
    switch (this.props.stage) {
      case Stage.Select:
        this.props.closeModal();
        break;
      case Stage.Allocate:
        this.props.setProps({ stage: Stage.Select });
        break;
      default:
        break;
    }
  };

  public sumEntryOutstandingAmounts = (
    entries: ReadonlyArray<LedgerResponse>
  ): number => {
    return entries.reduce(
      (total: number, entry: LedgerResponse) =>
        total + calculateLedgerUnbalance(entry),
      0
    );
  };

  public handleSubmitEntry = async (entry: Ledger) => {
    const response = await this.props.createLedgerEntry(entry);

    this.props.getContactLedger(this.props.contactId);

    if (response) {
      this.props.closeModal();
    }
  };

  public handleSelectionChanged = (
    selectedEntries: ReadonlyArray<LedgerResponse>
  ) => this.props.setProps({ selectedEntries });
}

// Disconnected version used for testing
export { CreateLedgerEntryModal as TestableCreateLedgerEntryModal };

export const getInitialProps = (props: OwnProps): StateProps => ({
  stage: Stage.Select,
  selectedEntries: props.initialSelectedEntries,
});

export const mapState = (state: StoreState) => ({
  loading: {
    createLedgerEntry: isPending(state.responses, CREATE_LEDGER_ENTRY),
  },
  contactsCache: state.contactsCache,
});

const connector = connect(mapState, {
  getContactLedger,
  createLedgerEntry,
  closeModal,
  submit,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(CreateLedgerEntryModal)
);

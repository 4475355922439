import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { GET_MEMBERSHIPS, getMemberships } from '^/admin/memberships/actions';
import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import { getPlanName } from '^/plans/helpers';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';
import { MembershipResponse } from '../types';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  disabled?: boolean;
  isCompany?: boolean;
  isHeadOfficeExcluded?: boolean;
  setSelectedMemberships?: (value: MembershipResponse) => void;
}

// State props that come from redux.
interface StateProps {
  selectedMembership?: string;
}

export type SubscriptionsMembershipAddButtonProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class SubscriptionsMembershipAddButton extends React.PureComponent<
  SubscriptionsMembershipAddButtonProps
> {
  public componentDidMount(): void {
    const { loading, memberships } = this.props;

    if (!loading && paginatedArrayHasExpired(memberships, 100)) {
      this.props.getMemberships(1, 100);
    }
  }

  public render() {
    const {
      memberships,
      selectedMembership,
      loading,
      disabled,
      isCompany = false,
      isHeadOfficeExcluded = false,
    } = this.props;

    const retrievedPages = getAllRetrievedPages(memberships);
    const filteredMemberships = !isHeadOfficeExcluded
      ? retrievedPages
      : retrievedPages.filter(page => page.is_billing_container === false);

    return (
      <Allow
        roles={[
          UserRole.AdminLevel,
          UserRole.FinanceLevel,
          UserRole.OfficeLevel,
        ]}
      >
        <div
          className={classnames('subscriptions-membership-add-button', {
            ['no-selection']: !selectedMembership,
          })}
        >
          <div className="form-field short">
            <label>Membership</label>
            <select
              value={selectedMembership}
              onChange={this.onMembershipChange}
            >
              <option value="" disabled>
                {loading ? 'loading...' : 'please choose...'}
              </option>
              {filteredMemberships.map(membership => (
                <option key={membership.id} value={membership.id}>
                  {getPlanName(membership)}
                </option>
              ))}
            </select>
          </div>
          <AppButton
            disabled={!selectedMembership || disabled}
            loading={loading}
            onClick={
              // tslint:disable-next-line jsx-no-lambda
              () => this.onClickAdd(isCompany, retrievedPages)
            }
          >
            Add Membership Subscription
          </AppButton>
        </div>
      </Allow>
    );
  }

  public onMembershipChange = (event: { target: { value: string } }) => {
    this.props.setProps({ selectedMembership: event.target.value });
  };

  public onClickAdd = (
    isCompany?: boolean,
    retrievedPages?: Readonly<MembershipResponse[]>
  ) => {
    const {
      contactId,
      selectedMembership,
      setSelectedMemberships,
    } = this.props;

    if (isCompany && selectedMembership && setSelectedMemberships) {
      const [filteredMembership] = retrievedPages!.filter(
        ({ id }) => id === selectedMembership
      );
      setSelectedMemberships(filteredMembership);
    } else {
      this.props.push(
        `/membership-subscriptions/create?contact=${contactId}&membership=${selectedMembership}`
      );
    }
  };
}

// Disconnected version used for testing
export { SubscriptionsMembershipAddButton as TestableSubscriptionsMembershipAddButton };

export const getInitialProps = () => ({
  selectedMembership: '',
});

export const mapState = (state: StoreState) => ({
  memberships: state.memberships,
  loading: anyPending(state.responses, [GET_MEMBERSHIPS]),
});

const connector = connect(mapState, { getMemberships, push });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(SubscriptionsMembershipAddButton)
);

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import classnames from 'classnames';
import React from 'react';

import AppButton from '^/common/app-button';

interface OwnProps {
  text: string;
  truncatedLength: number;
}

interface StateProps {
  expanded: boolean;
}

export type TruncatedTextProps = OwnProps & SetPropsInterface<StateProps>;

class TruncatedText extends React.Component<TruncatedTextProps> {
  public render() {
    const { text, truncatedLength, expanded } = this.props;
    const shouldTruncate = text && text.length > truncatedLength;

    return (
      <div
        className={classnames('expandable-text', {
          expanded: !shouldTruncate || expanded,
        })}
      >
        <p>
          {shouldTruncate && !expanded
            ? `${text.substr(0, truncatedLength)}…`
            : text}
        </p>
        {shouldTruncate && (
          <AppButton leftIcon="angle-down" link onClick={this.toggleExpandText}>
            {expanded ? 'Show less' : 'Show more'}
          </AppButton>
        )}
      </div>
    );
  }

  public toggleExpandText = () =>
    this.props.setProps({ expanded: !this.props.expanded });
}

/** Disconnected version of the component that is used for testing. */
export { TruncatedText as TestableTruncatedText };

export const getInitialProps = () => ({
  expanded: false,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  TruncatedText
);

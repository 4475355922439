import { Column, Container, Row } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import DropdownButton from '^/common/dropdown-button';
import HeaderBar from '^/common/header-bar';
import { generateQueryString } from '^/common/helper-functions';
import RecentlyViewedContactsTable from '^/common/recently-viewed/recently-viewed-contacts';
import { clearContacts, getContacts } from '^/contacts/actions';
import ContactsFilterForm from '^/contacts/filter-form';
import ContactsTable from '^/contacts/table';
import { FilterList } from '^/filters/types';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';

export type ContactsListPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

class ContactsListPage extends React.PureComponent<ContactsListPageProps> {
  public componentDidMount() {
    const { location } = this.props;

    if (location.search) {
      const filters = queryString.parse(location.search);

      this.props.getContacts(filters);
    }
  }

  public componentDidUpdate(prevProps: ContactsListPageProps) {
    const { location } = this.props;

    const filters = queryString.parse(location.search);

    if (
      location.search !== '' &&
      location.search !== prevProps.location.search
    ) {
      this.props.getContacts(filters);
    }

    if (!location.search) {
      this.props.clearContacts();
    }
  }

  public render() {
    const { contacts, location } = this.props;

    const filters = queryString.parse(location.search);

    return (
      <PageContent>
        <HeaderBar
          title="Records"
          primaryActions={[
            <DropdownButton
              key="new-contact"
              label="New Record"
              subLabel="You can add patients from within a practice"
              options={[
                { label: 'Practice', linkTo: '/practices/create' },
                { label: 'Company', linkTo: '/companies/create' },
                { label: 'Individual', linkTo: '/individuals/create' },
              ]}
            />,
          ]}
        />
        <Container>
          <Row>
            <Column xs={12} lg={3}>
              <div className="filter-section">
                <ContactsFilterForm
                  initialValues={filters}
                  onSubmit={this.handleFilterSubmit}
                  onClear={this.handleFilterClear}
                  enableReinitialize
                />
              </div>
            </Column>
            <Column xs={12} lg={9}>
              {contacts ? <ContactsTable /> : <RecentlyViewedContactsTable />}
            </Column>
          </Row>
        </Container>
      </PageContent>
    );
  }

  public handleFilterSubmit = (filters: FilterList) => {
    const query = generateQueryString(filters);

    this.props.history.push(`${this.props.match.url}${query}`);
  };

  public handleFilterClear = () => {
    this.props.history.push(`${this.props.match.url}`);
  };
}

export { ContactsListPage as TestableContactsListPage };

export const mapState = (state: StoreState) => ({
  contacts: state.contacts,
});

const connector = connect(mapState, { clearContacts, getContacts });

export default connector(ContactsListPage);

import { anyPending } from '@dabapps/redux-requests';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { isRoleAgilioType } from '^/admin/users/helpers';
import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import { GET_CONTACT_PLANS, getContactPlans } from '^/plans/actions';
import { PlanResponse } from '^/plans/types';
import { StoreState } from '^/types';
import {
  getCurrentPage,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';
import { formatFeeAmount } from './helpers';

interface OwnProps {
  contactId: string;
}

export type Props = OwnProps & ConnectedProps<typeof connector>;

export class PlansTable extends React.Component<Props> {
  public componentDidMount() {
    const { contactPlans, contactId } = this.props;
    if (paginatedArrayHasExpired(contactPlans[contactId], 100)) {
      this.props.getContactPlans(contactId);
    }
  }

  public render() {
    const plans = this.props.contactPlans[this.props.contactId];

    return (
      <PaginatedTable
        paginatedData={plans}
        changePage={this.changePage}
        loading={this.props.loading}
      >
        <TableHead>
          <TableRow>
            <TableHeader>Code</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableCell className="align-right">Joining Fee</TableCell>
            <TableCell className="align-right">Plan Fee</TableCell>
            {isRoleAgilioType(this.props.userRole) && (
              <>
                <TableCell className="align-right">Admin Fee</TableCell>
                <TableHeader>INSPRAC</TableHeader>
              </>
            )}
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(plans).map(plan => (
            <ItemRow key={plan.id} item={plan} onClick={this.handleClick}>
              <TableHeader title={plan.description}>{plan.code}</TableHeader>
              <TableHeader title={plan.description}>
                {plan.description}
              </TableHeader>
              <TableCell className="align-right">
                {formatFeeAmount(plan.joining_fee)}
              </TableCell>
              <TableCell className="align-right">
                {formatFeeAmount(plan.fee)}
              </TableCell>
              {isRoleAgilioType(this.props.userRole) && (
                <>
                  <TableCell className="align-right">
                    {formatFeeAmount(plan.admin_fee)}
                  </TableCell>
                  <TableCell>{plan.insprac ? 'Yes' : 'No'}</TableCell>
                </>
              )}
              <TableCell>{plan.status}</TableCell>
            </ItemRow>
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public handleClick = (plan: PlanResponse) => {
    this.props.push(`/plans/${plan.id}`);
  };

  public changePage = (page: number, pageSize: number) => {
    this.props.getContactPlans(this.props.contactId, page, pageSize);
  };
}

export { PlansTable as TestablePlansTable };

export const mapState = (state: StoreState) => ({
  userRole: state.loggedInUser?.role,
  contactPlans: state.contactPlans,
  loading: anyPending(state.responses, [GET_CONTACT_PLANS]),
});

const connector = connect(mapState, { getContactPlans, push });

export default connector(PlansTable);

import { Row } from '@dabapps/roe';
import React from 'react';

const PublicPatientSignupPageSuccess: React.FC = () => {
  return (
    <Row className="patient-signup-success">
      <div className="patient-signup-success-container">
        <h1 className="title">Success!</h1>
        <p>Your Plan information has been submitted successfully.</p>
        <p>
          CODEplan Ltd. will notify you of your Direct Debit via post no later
          than 5 working days before the first collection. If you have any
          queries regarding the Direct Debit please contact CODEplan Ltd on 0330
          165 9713 or email us at{' '}
          <a href="mailto:iplan@agiliosoftware.com">iplan@agiliosoftware.com</a>
        </p>
      </div>
    </Row>
  );
};

export default PublicPatientSignupPageSuccess;

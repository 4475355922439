import { AsyncActionSet } from '@dabapps/redux-requests/dist/js';
import {
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import PageSubSection from '^/common/page-section/page-sub-section';
import { futureDateInclusive, required } from '^/common/validation';
import { Bank, BANK_OPTIONS } from '^/contacts/ledger/types';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import { RenderDateField } from '^/form-helpers/render-date-field';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderToggleSwitch from '^/form-helpers/render-toggle-switch';
import RenderVatCodePicker from '^/form-helpers/render-vat-code-picker';
import { Membership } from '^/memberships/types';
import { StoreState } from '^/types';
import { CREATE_MEMBERSHIP } from './actions';

interface OwnProps {
  actions?: ReadonlyArray<AsyncActionSet>;
  is_billing_container?: boolean;
  isEditPage?: boolean;
  onCancel?(): void;
}

export type MembershipsFormProps = OwnProps &
  InjectedFormProps<Membership, OwnProps>;

class MembershipsForm extends React.Component<MembershipsFormProps> {
  public render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      initialValues,
      actions = [CREATE_MEMBERSHIP],
      onCancel,
      is_billing_container,
      isEditPage = false,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <PageSubSection heading="Membership details" vertical>
          <FormGroup>
            <Field
              component={RenderInputField}
              label="Code"
              name="code"
              short
              type="text"
              disabled={initialValues && initialValues.id}
              validate={[required]}
            />
            <Field
              component={RenderInputField}
              label="Name"
              name="description"
              type="text"
            />

            <Field
              component={RenderCheckBox}
              className="billing-container-membership"
              label={'Combined membership?'}
              name="is_billing_container"
              type="checkbox"
              disabled={isEditPage}
            />
          </FormGroup>
        </PageSubSection>

        <PageSubSection heading="Fees" vertical>
          <Table className="no-borders" fill>
            {!is_billing_container && (
              <TableHead>
                <TableRow>
                  <TableHeader />
                  <TableHeader>Amount</TableHeader>
                  <TableHeader>Nominal code</TableHeader>
                  <TableHeader>VAT code</TableHeader>
                  <TableHeader>VAT inc.</TableHeader>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {!is_billing_container && (
                <>
                  <TableRow>
                    <TableCell className="align-right">Joining fee</TableCell>
                    <TableCell>
                      <Field
                        component={RenderCurrencyField}
                        name="joining_fee.amount"
                        type="number"
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderInputField}
                        name="joining_fee.nominal_code"
                        type="text"
                        validate={required}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderVatCodePicker}
                        name="joining_fee.vat_code"
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderCheckBox}
                        name="joining_fee.vat_inclusive"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="align-right">
                      Subscription fee
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderCurrencyField}
                        name="fee.amount"
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderInputField}
                        name="fee.nominal_code"
                        type="text"
                        validate={required}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderVatCodePicker}
                        name="fee.vat_code"
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderCheckBox}
                        name="fee.vat_inclusive"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="align-right">
                      New subscription fee
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderCurrencyField}
                        name="fee.new_amount"
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderInputField}
                        name="fee.new_nominal_code"
                        type="text"
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={RenderVatCodePicker}
                        name="fee.new_vat_code"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} className="align-right">
                      Date new subscription fee applies
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Field
                        component={RenderDateField}
                        name="fee.new_amount_applies"
                        validate={futureDateInclusive}
                        type="date"
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                <TableCell colSpan={2} className={'align-right'}>
                  Membership length (months)
                </TableCell>
                <TableCell colSpan={2}>
                  <Field
                    component={RenderInputField}
                    name="plan_length_months"
                    type="number"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <div className="form-field-footer">
            <Field
              component={RenderToggleSwitch}
              options={BANK_OPTIONS}
              short
              label="Bank"
              name="associated_bank"
            />
            {!is_billing_container && (
              <Field
                component={RenderToggleSwitch}
                label="Pricing"
                name="variable_pricing"
                disabled={initialValues && initialValues.id}
                short
                options={[
                  { label: 'Variable', value: true },
                  { label: 'Fixed', value: false },
                ]}
              />
            )}
          </div>
        </PageSubSection>

        <ErrorRenderer actions={actions} showStatusErrors />
        <div className="form-buttons">
          {onCancel && (
            <AppButton type="button" onClick={onCancel} disabled={submitting}>
              Cancel
            </AppButton>
          )}
          <AppButton
            primary
            type="submit"
            disabled={pristine}
            loading={submitting}
          >
            Save
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { MembershipsForm as TestableMembershipsForm };

const selector = formValueSelector('membershipsForm');

export const mapState = (state: StoreState) => ({
  is_billing_container: selector(state, 'is_billing_container'),
});

const connector = connect(mapState, {});

export default reduxForm<Membership, OwnProps>({
  form: 'membershipsForm',
  initialValues: {
    is_billing_container: false,
    fee: { vat_code: 1 },
    joining_fee: { vat_code: 1 },
    variable_pricing: false,
    associated_bank: Bank.CodePlan,
  },
})(connector(MembershipsForm));

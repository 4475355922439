import classnames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';

export type DropdownOptions = ReadonlyArray<{
  /** This string what is shown to the user in the dropdown menu. */
  label: string;
  /** The value that is set in the form, should match the API. */
  value: string;
}>;

interface OwnProps {
  /** This field should be disabled, stops the dropdown from working and greys it out. */
  disabled?: boolean;
  /** Label for this field, shown above the dropdown. */
  label?: string;
  /** Array of options that you want to be displayed. */
  options: DropdownOptions;
  /** This field should be short - adds a class that sets a max width. */
  short?: boolean;
  shorter?: boolean;
  shortest?: boolean;
}

export type RenderDropdownProps = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class RenderDropdown extends React.PureComponent<RenderDropdownProps> {
  public render() {
    const {
      input,
      label,
      short,
      shorter,
      shortest,
      meta: { touched, error, warning, dirty, pristine },
      options,
      disabled,
      defaultValue,
      className,
    } = this.props;

    return (
      <div
        className={classnames([
          'form-field',
          className,
          {
            short,
            shorter,
            shortest,
            dirty,
            pristine,
            error: Boolean(touched && error),
            'no-selection': !input || !input.value,
          },
        ])}
      >
        <div className="form-field-label">
          {label && <label>{label}</label>}
        </div>
        <select defaultValue={defaultValue} {...input} disabled={disabled}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="form-field-errors">
          {touched && <ErrorRenderer error={[error, warning]} />}
        </div>
      </div>
    );
  }
}

export default RenderDropdown;

import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { VatRates } from '^/fees/types';
import { formatVatRate } from '^/fees/utils';
import RenderFormField from './render-form-field';

const HIDDEN_VAT_CODES = [3];

interface OwnProps {
  label?: string;
  disabled?: boolean;
  isBillingContainerSubscription?: boolean;
  defaultNone?: boolean;
}

export type RenderVatCodePickerProps = OwnProps &
  React.SelectHTMLAttributes<HTMLSelectElement> &
  WrappedFieldProps;

class RenderVatCodePicker extends React.PureComponent<
  RenderVatCodePickerProps
> {
  public componentDidMount() {
    if (
      this.props.isBillingContainerSubscription ||
      (!this.props.input.value &&
        this.props.input.value !== 0 &&
        !this.props.disabled &&
        !this.props.defaultNone)
    ) {
      this.props.input.onChange(1);
    }
  }

  public render() {
    const { input, disabled } = this.props;

    return (
      <RenderFormField {...this.props}>
        <select {...input} disabled={disabled}>
          <option value="" />
          {this.getVatRates().map(vatRate => (
            <option key={vatRate.code} value={vatRate.code}>
              {formatVatRate(vatRate)}
            </option>
          ))}
        </select>
      </RenderFormField>
    );
  }

  public getVatRates = () =>
    Object.values(VatRates).filter(
      rate =>
        rate.code === this.props.meta.initial ||
        !HIDDEN_VAT_CODES.includes(rate.code)
    );
}

export { RenderVatCodePicker as TestableRenderVatCodePicker };

export default RenderVatCodePicker;

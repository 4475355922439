import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import AppButton from './app-button';

interface OwnProps {
  label: string;
  subLabel?: string;
  options: ReadonlyArray<{
    label: string;
    linkTo?: string;
    onPress?: () => void;
    disabled?: boolean;
  }>;
}

interface StateProps {
  expanded?: boolean;
}

export type Props = OwnProps & SetPropsInterface<StateProps>;

export class DropdownButton extends React.PureComponent<Props> {
  public render() {
    const { label, subLabel, expanded, options } = this.props;

    return (
      <div className={classnames({ 'button-dropdown': true, expanded })}>
        <AppButton onClick={this.toggleExpanded} rightIcon="caret-down">
          {label}
        </AppButton>
        {expanded && (
          <div className="dropdown">
            <ul>
              {options.map(option => {
                const listItem = (
                  <li
                    className={classnames({ disabled: option.disabled })}
                    key={option.label}
                    onClick={!option.disabled ? option.onPress : undefined}
                  >
                    {option.label}
                  </li>
                );

                return !option.disabled && option.linkTo ? (
                  <Link key={option.label} to={option.linkTo}>
                    {listItem}
                  </Link>
                ) : (
                  listItem
                );
              })}
            </ul>
            {subLabel && (
              <div className="dropdown-button-sublabel">{subLabel}</div>
            )}
          </div>
        )}
      </div>
    );
  }

  public toggleExpanded = () => {
    this.props.setProps({
      expanded: !this.props.expanded,
    });
  };
}

export const getInitialProps = () => ({
  expanded: false,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  DropdownButton
);

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React, { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  LIST_SIZE,
  recentlyViewedContacts,
} from '^/common/recently-viewed/helper-functions';
import ContactRow from '^/contacts/row';
import { ContactResponse } from '^/contacts/types';
import { StoreState } from '^/types';

export type RecentlyViewedContactsTableProps = ConnectedProps<
  typeof connector
> & {
  showCategory?: boolean;
  showClinician?: boolean;
  actions?: ReactNode | ReactNode[];
  onSelect?: (contact: ContactResponse) => void;
};
export class RecentlyViewedContactsTable extends React.PureComponent<
  RecentlyViewedContactsTableProps
> {
  public render() {
    const filteredCache = this.filteredCache();
    const { showCategory, showClinician } = this.props;
    return (
      <div className="recently-viewed-table">
        <div className="header">
          <h4>Recently Viewed</h4>
          {this.props.actions}
        </div>
        <Table fill scrollable>
          <TableHead>
            <TableRow>
              <TableHeader>First Name</TableHeader>
              <TableHeader>Surname</TableHeader>
              {showCategory && <TableHeader>Category</TableHeader>}
              <TableHeader>Contact ID</TableHeader>
              <TableHeader>DOB</TableHeader>
              <TableHeader>Postcode</TableHeader>
              <TableHeader>Status</TableHeader>
              {showClinician && <TableHeader>Clinician</TableHeader>}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCache.map(contact => (
              <ContactRow
                key={contact.id}
                contactResponse={contact}
                onSelect={this.props.onSelect}
                showCategory={this.props.showCategory}
                showClinician={this.props.showClinician}
              />
            ))}
          </TableBody>
        </Table>

        {filteredCache.length > 0 || (
          <div className="empty-table-state">No contacts</div>
        )}
      </div>
    );
  }

  private filteredCache = () =>
    recentlyViewedContacts(this.props.contactsCache).slice(0, LIST_SIZE);
}

export const mapState = ({ contactsCache }: StoreState) => {
  return {
    contactsCache,
  };
};

const connector = connect(mapState, {});

export default connector(RecentlyViewedContactsTable);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { shouldRethrow } from '^/address/actions';
import { PaymentDetails } from '^/payment-details/types';
import { wrapThunkAction } from '^/utils/thunk-action-wrap';

export const PAYMENT_DETAILS_ENDPOINT = '/api/payment-details';
const API_KEY = window.LOQATE_API_KEY;

export const generateRequestURL = (accountNumber: string, sortCode: string) =>
  `https://api.addressy.com/BankAccountValidation/Interactive/Validate/v2/json3.ws?Key=${API_KEY}&AccountNumber=${accountNumber}&SortCode=${sortCode}`;

export const VALIDATE_PAYMENT_DETAILS = makeAsyncActionSet(
  'VALIDATE_PAYMENT_DETAILS'
);
export function validatePaymentDetails(
  accountNumber: string,
  sortCode: string
) {
  return (dispatch: Dispatch) => {
    return request<any>(
      VALIDATE_PAYMENT_DETAILS,
      generateRequestURL(accountNumber, sortCode),
      'GET',
      undefined,
      { metaData: { accountNumber, sortCode } }
    )(dispatch).catch(() => null);
  };
}

export const GET_PAYMENT_DETAILS_BY_CONTACT = makeAsyncActionSet(
  'GET_PAYMENT_DETAILS_BY_CONTACT'
);
export function getPaymentDetailsByContact(contactId: string) {
  return (dispatch: Dispatch) => {
    return request(
      GET_PAYMENT_DETAILS_BY_CONTACT,
      `${PAYMENT_DETAILS_ENDPOINT}/?contact=${contactId}`,
      'GET',
      undefined
    )(dispatch).catch(() => null);
  };
}

export const GET_PAYMENT_DETAILS = makeAsyncActionSet('GET_PAYMENT_DETAILS');
export function getPaymentDetails(paymentDetailsId: string) {
  return (dispatch: Dispatch) => {
    return request(
      GET_PAYMENT_DETAILS,
      `${PAYMENT_DETAILS_ENDPOINT}/${paymentDetailsId}/`,
      'GET',
      undefined
    )(dispatch).catch(() => null);
  };
}

export const GET_LINKED_PAYMENT_DETAILS = makeAsyncActionSet(
  'GET_LINKED_PAYMENT_DETAILS'
);
export const getLinkedPaymentDetails = (contactId: string) => (
  dispatch: Dispatch
) =>
  request(
    GET_LINKED_PAYMENT_DETAILS,
    `${PAYMENT_DETAILS_ENDPOINT}/?for=${contactId}`,
    'GET',
    undefined,
    { metaData: { for: contactId } }
  )(dispatch);

export const thunkWrappedCreatePaymentDetails = wrapThunkAction(
  createPaymentDetails
);
export const CREATE_PAYMENT_DETAILS = makeAsyncActionSet(
  'CREATE_PAYMENT_DETAILS'
);
export function createPaymentDetails(
  contactId: string,
  paymentDetails: PaymentDetails
) {
  return (dispatch: Dispatch) => {
    return request(
      CREATE_PAYMENT_DETAILS,
      `${PAYMENT_DETAILS_ENDPOINT}/`,
      'POST',
      {
        ...paymentDetails,
        contact: contactId,
      },
      { shouldRethrow }
    )(dispatch)
      .then(() => {
        getPaymentDetailsByContact(contactId)(dispatch);
      })
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

export const thunkWrappedEditPaymentDetails = wrapThunkAction(
  editPaymentDetails
);
export const EDIT_PAYMENT_DETAILS = makeAsyncActionSet('EDIT_PAYMENT_DETAILS');
export function editPaymentDetails(
  paymentDetailsId: string,
  paymentDetails: PaymentDetails
) {
  return (dispatch: Dispatch) => {
    return request(
      EDIT_PAYMENT_DETAILS,
      `${PAYMENT_DETAILS_ENDPOINT}/${paymentDetailsId}/`,
      'PATCH',
      paymentDetails,
      { shouldRethrow }
    )(dispatch)
      .then(response => {
        getPaymentDetails(paymentDetailsId)(dispatch);
        return response;
      })
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

export const thunkWrappedDeletePaymentDetails = wrapThunkAction(
  deletePaymentDetails
);

export const DELETE_PAYMENT_DETAILS = makeAsyncActionSet(
  'DELETE_PAYMENT_DETAILS'
);
export function deletePaymentDetails(paymentDetailsId: string) {
  return (dispatch: Dispatch) => {
    return request(
      DELETE_PAYMENT_DETAILS,
      `${PAYMENT_DETAILS_ENDPOINT}/${paymentDetailsId}/`,
      'DELETE',
      undefined,
      { shouldRethrow }
    )(dispatch).catch(() => null);
  };
}

export const paymentDetailActions = [
  EDIT_PAYMENT_DETAILS,
  CREATE_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_BY_CONTACT,
];

import classnames from 'classnames';
import queryString from 'query-string';
import React from 'react';
import { Link } from 'react-router-dom';

import { FilterList } from '^/filters/types';

interface OwnProps {
  filters: { [filter: string]: FilterList };
  filter: string;
  url: string;
  loading?: boolean;
}

export type FilterBarProps = OwnProps;

class FilterBar extends React.PureComponent<FilterBarProps> {
  public render() {
    const { filters, filter, loading, url } = this.props;

    const searchParams = queryString.parse(location.search);
    const searchQuery = searchParams.search
      ? `&search=${encodeURI(searchParams.search)}`
      : '';

    return (
      <div className={classnames('paginated-table-filters', { loading })}>
        <span>Showing:</span>
        <ul>
          {Object.keys(filters).map((option, index) => (
            <li key={index}>
              <Link
                to={`${url}?filter=${option}${searchQuery}`}
                className={classnames({ selected: option === filter })}
              >
                {option === filter && (
                  <span className="display-none">Selected: </span>
                )}
                {option}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

// Disconnected version used for testing
export { FilterBar as TestableFilterBar };

export default FilterBar;

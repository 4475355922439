import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

interface AppConfig {
  icon: string;
  url: string;
  readmore: string;
}

type Apps = 'iComply' | 'iLearn' | 'iService' | 'iTeam' | 'iShop';

type Config = {
  icon: string;
  agilio: {
    icon: string;
  };
} & {
  [app in Apps]: AppConfig;
};

interface OwnProps {
  config: Config;
}

export type AppSwitcherProps = OwnProps;

interface OwnState {
  isOpen?: boolean;
}

class AppSwitcher extends React.Component<OwnProps, OwnState> {
  constructor(props: OwnProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  public displaySubMenu() {
    const { config } = this.props;

    return (
      <div className="app-switcher-submenu">
        <div className="app-switcher-submenu-items">
          <div className="app-switcher-submenu-item-header">
            <div className="app-switcher-submenu-item-header-logo">
              <img src={config.agilio.icon} alt="agilio logo" />
            </div>
            <div className="app-switcher-submenu-item-text">Agilio Apps</div>
          </div>
          <div className="app-switcher-submenu-item">
            <a
              target="_blank"
              href={config.iComply.url}
              className="app-switcher-submenu-item-primary"
            >
              <div className="app-switcher-submenu-item-logo">
                <img src={config.iComply.icon} alt="iComply logo" />
              </div>
              <div className="app-switcher-submenu-item-text">
                compliance software and support for CQC success
              </div>
            </a>
            <a
              target="_blank"
              href={config.iComply.readmore}
              className="app-switcher-submenu-item-learnmore"
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              <div className="app-switcher-submenu-item-learnmore-text">
                Learn more
              </div>
            </a>
          </div>
          <div className="app-switcher-submenu-item">
            <a
              target="_blank"
              href={config.iTeam.url}
              className="app-switcher-submenu-item-primary"
            >
              <div className="app-switcher-submenu-item-logo">
                <img src={config.iTeam.icon} alt="iTeam logo" />
              </div>
              <div className="app-switcher-submenu-item-text">
                your virtual HR department
              </div>
            </a>
            <a
              target="_blank"
              href={config.iTeam.readmore}
              className="app-switcher-submenu-item-learnmore"
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              <div className="app-switcher-submenu-item-learnmore-text">
                Learn more
              </div>
            </a>
          </div>
          <div className="app-switcher-submenu-item">
            <a
              target="_blank"
              href={config.iLearn.url}
              className="app-switcher-submenu-item-primary"
            >
              <div className="app-switcher-submenu-item-logo">
                <img src={config.iLearn.icon} alt="iLearn logo" />
              </div>
              <div className="app-switcher-submenu-item-text">
                all your CPD in one place
              </div>
            </a>
            <a
              target="_blank"
              href={config.iLearn.readmore}
              className="app-switcher-submenu-item-learnmore"
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              <div className="app-switcher-submenu-item-learnmore-text">
                Learn more
              </div>
            </a>
          </div>
          <div className="app-switcher-submenu-item">
            <a
              target="_blank"
              href={config.iService.url}
              className="app-switcher-submenu-item-primary"
            >
              <div className="app-switcher-submenu-item-logo">
                <img src={config.iService.icon} alt="iService logo" />
              </div>
              <div className="app-switcher-submenu-item-text">
                manage your dental assets with ease
              </div>
            </a>
            <a
              target="_blank"
              href={config.iService.readmore}
              className="app-switcher-submenu-item-learnmore"
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              <div className="app-switcher-submenu-item-learnmore-text">
                Learn more
              </div>
            </a>
          </div>
          <div className="app-switcher-submenu-item">
            <a
              target="_blank"
              href={config.iShop.url}
              className="app-switcher-submenu-item-primary"
            >
              <div className="app-switcher-submenu-item-logo">
                <img src={config.iShop.icon} alt="iShop logo" />
              </div>
              <div className="app-switcher-submenu-item-text">
                for your compliance logbooks and consumables
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }

  public render() {
    const { isOpen } = this.state;
    const { USE_APP_SWITCHER } = window;
    const { config } = this.props;

    const toggleVisiblity = () =>
      this.setState({
        isOpen: !isOpen,
      });

    return (
      USE_APP_SWITCHER && (
        <div
          className={classNames('app-switcher-container', {
            'is-open': isOpen,
          })}
        >
          <button className="app-switcher-button" onClick={toggleVisiblity}>
            <img
              className="app-switcher-button-icon"
              src={config.icon}
              alt="icon for switching between apps"
            />
          </button>

          {this.displaySubMenu()}
        </div>
      )
    );
  }
}

export default AppSwitcher;

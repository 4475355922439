import React from 'react';

class AnimatedTick extends React.PureComponent {
  public render() {
    return (
      <span className="animated-tick">
        <svg viewBox="0 0 36 36">
          <path
            className="circle"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            transform="rotate(75, 18, 18)"
          />
          <path
            className="tick"
            d="m3.063615,19.054611l10.56037,10.568181l17.248714,-17.373705"
            transform="translate(4, -5)"
          />
        </svg>
      </span>
    );
  }
}

export default AnimatedTick;

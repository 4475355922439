import { TableCell, TableHeader, TableRow } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import AppButton from '^/common/app-button';
import { formatDate, getContactName } from '^/common/helper-functions';
import { getContact } from '^/contacts/actions';
import { closeModal, openModal } from '^/modals/actions';
import { SubscriptionContactResponse } from '^/plans/subscriptions/types';
import { StoreState } from '^/types';
import { cachedItemHasExpired } from '^/utils/cache-helpers';
import { patchSubscriptionContact } from '../actions';
import EditModal from './edit-modal';
import LapseModal from './lapse-modal';

interface OwnProps {
  subscriptionContact: SubscriptionContactResponse;
  disabled?: boolean;
  isBillingContainerSubscription?: boolean;
  isParentBillingContainerSubscription?: boolean;
}

export type SubscriptionContactsRowProps = OwnProps &
  ConnectedProps<typeof connector>;

class SubscriptionContactsRow extends React.PureComponent<
  SubscriptionContactsRowProps
> {
  public componentDidMount() {
    const {
      contact,
      subscriptionContact,
      isBillingContainerSubscription,
    } = this.props;

    if (cachedItemHasExpired(contact)) {
      this.props.getContact(
        isBillingContainerSubscription
          ? subscriptionContact.contact_id
          : subscriptionContact.contact
      );
    }
  }

  public render() {
    const {
      subscriptionContact,
      contact,
      disabled,
      isBillingContainerSubscription,
    } = this.props;

    return (
      <TableRow key={subscriptionContact.id}>
        <TableHeader>
          <Link
            to={
              isBillingContainerSubscription
                ? `/practices/${subscriptionContact.contact_id}`
                : `/contacts/${subscriptionContact.contact}`
            }
          >
            {isBillingContainerSubscription
              ? subscriptionContact.practice
              : getContactName(contact)}
          </Link>
        </TableHeader>
        <TableCell
          className={isBillingContainerSubscription ? '' : 'align-right'}
        >
          {formatDate(
            isBillingContainerSubscription
              ? subscriptionContact.created
              : subscriptionContact.start_date
          )}
        </TableCell>
        {!isBillingContainerSubscription && (
          <>
            <TableCell>{subscriptionContact.lapsed ? 'Y' : 'N'}</TableCell>
            <TableCell className="align-right">
              {formatDate(subscriptionContact.lapsed_date)}
            </TableCell>
            <TableCell>{subscriptionContact.lapsed_reason}</TableCell>
            <TableCell>
              {subscriptionContact.lapsed_date ? (
                <AppButton
                  disabled={disabled || subscriptionContact.lapsed}
                  onClick={this.clearLapsedMembership}
                  destructive
                  small
                >
                  Clear
                </AppButton>
              ) : (
                <AppButton
                  disabled={disabled || subscriptionContact.lapsed}
                  onClick={this.openLapseModal}
                  destructive
                  small
                >
                  Lapse
                </AppButton>
              )}
            </TableCell>
          </>
        )}
        {isBillingContainerSubscription && (
          <>
            <TableCell>{subscriptionContact.membership}</TableCell>
            <TableCell>
              {subscriptionContact.lapsed ? 'LAPSED' : 'LIVE'}
            </TableCell>
          </>
        )}
        <TableCell
          className={isBillingContainerSubscription ? 'align-right' : ''}
        >
          <AppButton
            onClick={this.openEditModal}
            disabled={disabled || subscriptionContact.lapsed}
            primary
            small
          >
            Edit
          </AppButton>
        </TableCell>
      </TableRow>
    );
  }

  public openEditModal = () => {
    const {
      subscriptionContact,
      contact,
      isBillingContainerSubscription,
    } = this.props;
    this.props.openModal(
      <EditModal
        subscriptionContact={subscriptionContact}
        onCloseModal={this.props.closeModal}
        heading={`Subscription - ${
          isBillingContainerSubscription
            ? subscriptionContact.practice
            : getContactName(contact)
        }`}
      />
    );
  };

  public openLapseModal = () => {
    const {
      subscriptionContact,
      contact,
      isParentBillingContainerSubscription = false,
    } = this.props;
    this.props.openModal(
      <LapseModal
        subscriptionContact={subscriptionContact}
        onCloseModal={this.props.closeModal}
        heading={`Lapse Subscription - ${getContactName(contact)}`}
        isParentBillingContainerSubscription={
          isParentBillingContainerSubscription
        }
      />
    );
  };

  public clearLapsedMembership = () =>
    this.props.patchSubscriptionContact(
      {
        lapsed_date: null,
        lapsed_reason: '',
      },
      this.props.subscriptionContact.id,
      this.props.subscriptionContact.subscription,
      this.props.subscriptionContact.contact
    );
}

export { SubscriptionContactsRow as TestableSubscriptionContactsRow };

export const mapState = (state: StoreState, props: OwnProps) => ({
  contact:
    state.contactsCache &&
    state.contactsCache[props.subscriptionContact.contact],
});

const connector = connect(mapState, {
  openModal,
  closeModal,
  getContact,
  patchSubscriptionContact,
});

export default connector(SubscriptionContactsRow);

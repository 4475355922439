import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { isChecked } from '^/common/validation';
import RenderCheckBox from '^/form-helpers/render-checkbox';

const Confirmation = () => {
  return (
    <PageSection>
      <span className=" confirm-account-holder">
        <FormGroup>
          <Field
            name="direct_debit_confirmation"
            label="I confirm the above details are correct and I wish to enter into the Direct Debit Agreement with CODEplan Ltd."
            component={RenderCheckBox}
            validate={[isChecked]}
          />
        </FormGroup>
        <FormGroup>
          <Field
            name="agreed_to_tcs"
            label={
              <span>
                For the full dental plan terms and conditions, they are
                available via our website{' '}
                <a href={window.TCS_URL} target="_blank">
                  HERE
                </a>
                . Please tick to confirm you have read and agree to the T&Cs.
              </span>
            }
            component={RenderCheckBox}
            validate={[isChecked]}
          />
        </FormGroup>
        <FormGroup>
          <Field
            name="communication_method_is_email"
            label="Agree for us to contact you via email for policy changes etc."
            component={RenderCheckBox}
          />
        </FormGroup>
      </span>
    </PageSection>
  );
};

export { Confirmation as TestableConfirmation };

export default Confirmation;

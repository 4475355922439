import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import ProductsCreatePage from './create-page';
import ProductsEditPage from './edit-page';
import ProductsListPage from './list-page';

class AdminProducts extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/create`} component={ProductsCreatePage} />
        <Route path={`${match.path}/:id`} component={ProductsEditPage} />
        <Route component={ProductsListPage} />
      </Switch>
    );
  }
}

export default AdminProducts;

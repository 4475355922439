import { anyPending } from '@dabapps/redux-requests';
import { Container, Tab, Tabs } from '@dabapps/roe';
import { AxiosResponse } from 'axios';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import AppButton from '^/common/app-button';
import { ErrorPage } from '^/common/error-page';
import { getContactName } from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import ContactHeaderBar from '^/contacts/header-bar';
import UnverifiedPatientDetailsTab from '^/contacts/persons/patients/tabs/unverified-details-tab';
import {
  GET_UNVERIFIED_CONTACT,
  getUnverifiedPatientAsContact,
  verifyPatient,
} from '^/iplan/actions';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

export type UnverifiedPatientsDetailPageProps = ConnectedProps<
  typeof connector
> &
  RouteComponentProps<{ contactId: string }>;

class UnverifiedPatientsDetailPage extends React.PureComponent<
  UnverifiedPatientsDetailPageProps
> {
  public componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.props.getUnverifiedPatientAsContact(params.contactId);
  }

  public render() {
    const { patient, loading } = this.props;

    if (!patient) {
      if (loading) {
        return (
          <PageContent>
            <BreadcrumbBar
              items={[['Practices', '/practices'], 'Loading...', 'Loading...']}
            />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Patient not found" />;
    }

    return (
      <PageContent>
        <BreadcrumbBar
          items={[
            ['Records', '/contacts'],
            `${getContactName(patient)} (Unverified Patient)`,
          ]}
        />
        <ContactHeaderBar
          contact={patient}
          primaryActions={[
            <AppButton key="verify" onClick={this.verifyPatient} primary>
              Verify
            </AppButton>,
          ]}
          loading={loading}
          hideSubtitle
        />

        <Container>
          <Tabs>
            <Tab>
              <NavLink to={this.props.match.url}>Details</NavLink>
            </Tab>
          </Tabs>

          <Switch>
            <Route
              path={`${this.props.match.path}`}
              component={UnverifiedPatientDetailsTab}
            />
          </Switch>
        </Container>
      </PageContent>
    );
  }

  public verifyPatient = () => {
    const { patient, history } = this.props;
    this.props
      .verifyPatient(patient!.id)
      .then(
        (response: AxiosResponse | void) =>
          response && history.push(`/contacts/${response.data.id}`)
      );
  };
}

export { UnverifiedPatientsDetailPage as TestableUnverifiedPatientsDetailPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  patient: getItemFromCache(
    props.match.params.contactId,
    state.unverifiedPatientsCache
  ),
  loading: anyPending(state.responses, [GET_UNVERIFIED_CONTACT]),
});

const connector = connect(mapState, {
  getUnverifiedPatientAsContact,
  verifyPatient,
});

export default connector(UnverifiedPatientsDetailPage);

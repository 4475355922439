import { Table } from '@dabapps/roe';
import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { Loading } from '^/common/loading';
import { PaginationBar } from '^/common/pagination-bar';
import { PaginatedArray } from '^/types';
import { hasData, hasMultiplePages } from '^/utils/pagination-helpers';

interface OwnProps<T> {
  paginatedData?: PaginatedArray<T> | null;
  loading: boolean;
  primary?: boolean;
  showPaginationBar?: boolean;
  title?: string;
  noRecordsFoundMessage?: string;
  condensed?: boolean;
  actions?: ReactNode | ReactNode[];
  changePage(page: number, pageSize?: number): void;
}

export type PaginatedTableProps<T> = OwnProps<T>;

class PaginatedTable<T> extends React.PureComponent<PaginatedTableProps<T>> {
  public render() {
    const {
      paginatedData,
      loading,
      primary,
      showPaginationBar,
      title,
      condensed,
      actions,
      noRecordsFoundMessage,
    } = this.props;

    return (
      <div className={classnames(['paginated-table', { loading, primary }])}>
        {(title || actions) && (
          <div className="header">
            {title && <h4>{title}</h4>}
            {actions}
          </div>
        )}
        <Table fill className={classnames([{ condensed }])}>
          {this.props.children}
        </Table>

        {!hasData(paginatedData) && loading && <Loading />}

        {!hasData(paginatedData) && !loading && (
          <div className="empty-state">{`${noRecordsFoundMessage ||
            'No records found'}`}</div>
        )}

        {(showPaginationBar ||
          (showPaginationBar === undefined &&
            hasMultiplePages(paginatedData))) && (
          <PaginationBar
            paginatedData={paginatedData}
            changePage={this.props.changePage}
            loading={loading}
          />
        )}
      </div>
    );
  }
}

export default PaginatedTable;

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import { Uuid } from '^/common/types';
import { Dashboard } from '../types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
export interface PatientsGivenNoticeProps {
  data?: Dashboard['patient_movements'];
  loading?: boolean;
  practiceId?: Uuid;
}

const getLabels = (data: Dashboard['patient_movements']): string[] => {
  return data.map(item =>
    new Date(item.month).toLocaleString('default', { month: 'short' })
  );
};

class PatientMovements extends React.PureComponent<PatientsGivenNoticeProps> {
  public render() {
    const { data, loading, practiceId } = { ...this.props };
    const options: any = {
      responsive: true,
      maintainAspectRatio: false,
      categoryPercentage: 0.5,
      barPercentage: 0.6,
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
          position: 'bottom',
          display: true,
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          font: {
            weight: 'bold',
          },
        },
      },
    };
    if (data) {
      options.scales = {
        y: {
          suggestedMax:
            Math.max(
              ...data!.map(o => o.incoming),
              ...data!.map(o => o.outgoing)
            ) + 10,
        },
      };
    }
    if (loading) {
      return <Loading />;
    } else if (data) {
      const chartData = {
        labels: getLabels(data),
        datasets: [
          {
            label: 'Outgoing',
            data: [data[0].outgoing, data[1].outgoing, data[2].outgoing],
            backgroundColor: ['#FFA500', '#FFA500', '#FFA500'],
          },
          {
            label: 'Incoming',
            data: [data[0].incoming, data[1].incoming, data[2].incoming],
            backgroundColor: ['#00B68A', '#00B68A', '#00B68A'],
          },
        ],
      };
      return (
        <>
          <h4>Patient Movements</h4>
          <div className="chart-container chart-container-first-row">
            <Bar options={options as any} data={chartData} />
          </div>
          <AppButton
            className="iplan-app-button"
            url={`/api/iplan/dashboard/exports/patient-movements?practice=${practiceId}`}
          >
            {`Download data >>`}
          </AppButton>
        </>
      );
    }
    return null;
  }
}

export { PatientMovements as TestablePatientMovements };

export default PatientMovements;

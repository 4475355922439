import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { AnyAction } from 'redux';

import * as practiceActions from '^/contacts/companies/practices/actions';
import * as taskActions from '^/tasks/actions';
import { taskInProgress } from '^/tasks/utils';
import { INTERVAL_MS } from '^/utils/constants';

export const pollTasks: Middleware = store => (next: Dispatch) => (
  action: AnyAction
) => {
  switch (action.type) {
    case practiceActions.IMPORT_PATIENTS.SUCCESS:
      const {
        data: { task_id: taskId },
      } = action.payload;
      pollAgainAfterTimeout(store, taskId);
      break;

    case taskActions.POLL_TASK.SUCCESS:
      const {
        data: { id },
      } = action.payload;
      if (taskInProgress(action.payload)) {
        pollAgainAfterTimeout(store, id);
      }
      break;

    default:
      break;
  }

  return next(action);
};

const pollAgainAfterTimeout = (store: MiddlewareAPI, taskId: string) => {
  return store.dispatch(
    taskActions.storePollTimeout(
      taskId,
      window.setTimeout(
        () => store.dispatch(taskActions.pollTask(taskId) as any),
        INTERVAL_MS
      )
    )
  );
};

export default pollTasks;

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import moment from 'moment';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import { getContact } from '^/contacts/actions';
import {
  Ledger,
  LedgerAllocation,
  LedgerEntryAllocationData,
  LedgerFilters,
  RejectData as BasicEntryData,
} from '^/contacts/ledger/types';
import { QueryParams } from '^/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';
import { ProductInvoice } from './types';

export const LEDGER_ENDPOINT = '/api/ledger-entries';
export const TRANSACTIONS_ENDPOINT = '/api/transactions';
export const COLLECTIONS_ENDPOINT = '/api/collections';
export const PRODUCT_INVOICE_ENDPOINT = '/api/ledger-entries/product-invoice/';

export const GET_MODAL_CONTACT_LEDGER = makeAsyncActionSet(
  'GET_MODAL_CONTACT_LEDGER'
);
export const GET_CONTACT_LEDGER = makeAsyncActionSet('GET_CONTACT_LEDGER');
export function getContactLedger(
  contactId: string,
  filters: LedgerFilters = {},
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  actionSet = GET_CONTACT_LEDGER
) {
  const queryParameters: { [param: string]: string | undefined } = {
    page: page.toString(),
    page_size: pageSize.toString(),
    contact_1: contactId.toString(),
    ...(filters.startDate && {
      start_date: moment(filters.startDate)
        .startOf('day')
        .toISOString(),
    }),
    ...(filters.endDate && {
      end_date: moment(filters.endDate)
        .endOf('day')
        .toISOString(),
    }),
    ...(filters.type && { type: filters.type }),
    ...(filters.outstanding && { outstanding: 'True' }),
    ...(filters.unallocated && { unallocated: 'True' }),
    ...(filters.tx_id && { tx_id: filters.tx_id }),
  };

  const queryString: string = generateQueryString(queryParameters);
  return (dispatch: Dispatch) => {
    request(actionSet, `${LEDGER_ENDPOINT}/${queryString}`, 'GET', undefined, {
      metaData: { contactId, page, pageSize },
    })(dispatch).then(() => getContact(contactId)(dispatch));
  };
}

export const GET_CONTACT_LEDGER_ENTRIES = makeAsyncActionSet(
  'GET_CONTACT_LEDGER_ENTRIES'
);
export function getContactLedgerEntries(
  filters: QueryParams = {},
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  filters = { ...filters, page, page_size: pageSize };

  return (dispatch: Dispatch) =>
    request(
      GET_CONTACT_LEDGER_ENTRIES,
      `${LEDGER_ENDPOINT}/${generateQueryString(filters)}`,
      'GET',
      undefined,
      { metaData: { filters, page, pageSize } }
    )(dispatch);
}

export const REQUEST_INVOICE_SUMMARY = makeAsyncActionSet(
  'REQUEST_INVOICE_SUMMARY'
);
export const requestInvoiceSummary = (payload: { subscription: string }) => (
  dispatch: Dispatch
) =>
  request<any>(
    REQUEST_INVOICE_SUMMARY,
    `${TRANSACTIONS_ENDPOINT}/collection/`,
    'POST',
    payload,
    {
      shouldRethrow: rethrowOnAnyError,
    }
  )(dispatch).catch(throwSubmissionError);

export const CREATE_PRODUCT_INVOICE = makeAsyncActionSet(
  'CREATE_PRODUCT_INVOICE'
);
export const createProductInvoice = (invoice: ProductInvoice) => (
  dispatch: Dispatch
) =>
  request(
    CREATE_PRODUCT_INVOICE,
    `${PRODUCT_INVOICE_ENDPOINT}`,
    `POST`,
    invoice
  )(dispatch);

export const CREATE_INVOICE = makeAsyncActionSet('CREATE_INVOICE');
export const createInvoice = (collection: string, entryDate: string) => (
  dispatch: Dispatch
) =>
  request(
    CREATE_INVOICE,
    `${COLLECTIONS_ENDPOINT}/invoice-collection/`,
    'POST',
    {
      collection,
      entry_date: entryDate,
    }
  )(dispatch);

export const CREATE_LEDGER_ENTRY = makeAsyncActionSet('CREATE_LEDGER_ENTRY');
export const createLedgerEntry = (entry: Ledger) => (dispatch: Dispatch) =>
  request<any>(CREATE_LEDGER_ENTRY, `${LEDGER_ENDPOINT}/`, 'POST', entry, {
    shouldRethrow: rethrowOnAnyError,
  })(dispatch).catch(throwSubmissionError);

export const CREATE_BASIC_LEDGER_ENTRY = makeAsyncActionSet(
  'CREATE_BASIC_LEDGER_ENTRY'
);
export const createBasicLedgerEntry = (entry: BasicEntryData) => (
  dispatch: Dispatch
) =>
  request(CREATE_INVOICE, `${LEDGER_ENDPOINT}/`, 'POST', entry, {
    shouldRethrow: rethrowOnAnyError,
  })(dispatch).catch(throwSubmissionError);

export const CREATE_ALLOCATED_LEDGER_ENTRY = makeAsyncActionSet(
  'CREATE_ALLOCATED_LEDGER_ENTRY'
);
export const createAllocatedLedgerEntry = (
  allocatedLedgerEntry: LedgerEntryAllocationData
) => (dispatch: Dispatch) =>
  request(
    CREATE_ALLOCATED_LEDGER_ENTRY,
    `${LEDGER_ENDPOINT}/`,
    'POST',
    allocatedLedgerEntry,
    {
      shouldRethrow: rethrowOnAnyError,
    }
  )(dispatch).then(response => {
    if (response && response.status === 200) {
      getContactLedger(allocatedLedgerEntry.contact_1);
    }
    return response;
  });

export const UPDATE_LEDGER_ENTRY_ALLOCATION = 'UPDATE_LEDGER_ENTRY_ALLOCATION';
export const updateLedgerEntryAllocation = (
  requestData: LedgerEntryAllocationData | null
) => ({
  type: UPDATE_LEDGER_ENTRY_ALLOCATION,
  payload: requestData,
});

export const CREATE_ALLOCATIONS = makeAsyncActionSet('CREATE_ALLOCATIONS');
export const createAllocations = (
  allocations: ReadonlyArray<LedgerAllocation>
) => (dispatch: Dispatch) => {
  return request(
    CREATE_ALLOCATIONS,
    `${LEDGER_ENDPOINT}/allocations/`,
    'POST',
    allocations,
    {
      shouldRethrow: rethrowOnAnyError,
    }
  )(dispatch).catch(throwSubmissionError);
};

export const GET_ALLOCATIONS = makeAsyncActionSet('GET_ALLOCATIONS');
export const getAllocations = (collection: string) => (dispatch: Dispatch) => {
  return request(
    GET_ALLOCATIONS,
    `${LEDGER_ENDPOINT}/allocations/?ledger_from=${collection}&ledger_to=${collection}&pageSize=100`,
    'GET',
    undefined,
    {
      metaData: { collection },
      shouldRethrow: rethrowOnAnyError,
    }
  )(dispatch);
};

export const ledgerActions = [
  GET_CONTACT_LEDGER,
  REQUEST_INVOICE_SUMMARY,
  CREATE_INVOICE,
  CREATE_BASIC_LEDGER_ENTRY,
  CREATE_ALLOCATED_LEDGER_ENTRY,
  GET_MODAL_CONTACT_LEDGER,
];

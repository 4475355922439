import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { addressActions } from '^/address/actions';
import AddressList from '^/address/address-list';
import { formatDate } from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import { contactActions, getContact } from '^/contacts/actions';
import { Mode, PersonsDetailsCard } from '^/contacts/persons/details-card';
import { patientActions } from '^/contacts/persons/patients/actions';
import { ContactResponse } from '^/contacts/types';
import { paymentDetailActions } from '^/payment-details/actions';
import { subscriptionActions } from '^/plans/subscriptions/actions';
import { PlanSubscriptionsTable } from '^/plans/subscriptions/table';
import { relationshipActions } from '^/relationships/actions';
import RelationshipsDetail from '^/relationships/detail';
import { CachedItem, StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

export type PatientsDetailsTabProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

export const noop = (() => {
  return;
}) as any;

class UnverifiedPatientDetailsTab extends React.PureComponent<
  PatientsDetailsTabProps
> {
  public componentDidMount() {
    const {
      patient,
      match: {
        params: { contactId },
      },
      loading,
    } = this.props;

    if (!loading.details && cachedItemHasExpired(patient)) {
      this.props.getContact(contactId);
    }
  }

  public render() {
    const {
      patient,
      match: {
        params: { contactId },
      },
      loading,
    } = this.props;

    if (!patient && loading.details) {
      return <Loading />;
    }

    return (
      patient && (
        <Row>
          <Column xs={12} md={7} lg={8}>
            <PageSection>
              <PageSubSection
                heading="Contact details"
                loading={loading.details}
              >
                <PersonsDetailsCard
                  contact={(patient as unknown) as CachedItem<ContactResponse>}
                  disabled
                  personId={contactId}
                  loading={false}
                  setProps={noop}
                  dispatch={noop}
                  mode={Mode.List}
                />
              </PageSubSection>
              <PageSubSection heading="Addresses" loading={loading.addresses}>
                <div className="flex-row">
                  <AddressList
                    addresses={patient.addresses}
                    editAddress={noop}
                    deleteAddress={noop}
                    setMailingAddress={noop}
                    disabled
                  />
                </div>
              </PageSubSection>

              <PageSubSection heading="Clinician">
                <div className="relationships detail-card">
                  <RelationshipsDetail
                    relationship={patient.clinician_relationship}
                    emptyStateMessage="No Clinician"
                  />
                </div>
              </PageSubSection>

              <PageSubSection heading="Payees">
                <div className="relationships list-card">
                  <table>
                    <tbody>
                      {patient.additional_patients?.map(
                        (additionalPatient, index) => {
                          return (
                            <tr key={index}>
                              <th>{`${additionalPatient.title} ${additionalPatient.first_name} ${additionalPatient.last_name}`}</th>
                              <td>
                                Date of Birth:{' '}
                                {formatDate(additionalPatient.dob)}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </PageSubSection>
            </PageSection>
            <PageSection heading="Dental Plan/Insurance">
              <PlanSubscriptionsTable
                subscriptions={{
                  count: 1,
                  page: 0,
                  pageSize: 1,
                  pages: {
                    0: [patient.subscription],
                  },
                }}
                contactId=""
                loading={false}
                getPlanSubscriptions={noop}
                push={noop}
                getLoggedInUser={noop}
                loggedInUser={noop}
              />
            </PageSection>
          </Column>
        </Row>
      )
    );
  }
}

export { UnverifiedPatientDetailsTab as TestableUnverifiedPatientDetailsTab };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  patient: getItemFromCache(
    props.match.params.contactId,
    state.unverifiedPatientsCache
  ),
  linkedPractice: getItemFromCache(
    state.patientPracticeMapping[props.match.params.contactId],
    state.contactsCache
  ),
  loading: {
    details: anyPending(state.responses, contactActions),
    addresses: anyPending(state.responses, addressActions),
    relationships: anyPending(state.responses, relationshipActions),
    linkedPractice: anyPending(state.responses, patientActions),
    subscriptions: anyPending(state.responses, subscriptionActions),
    paymentDetails: anyPending(state.responses, paymentDetailActions),
  },
});

const connector = connect(mapState, { getContact });

export default connector(UnverifiedPatientDetailsTab);

import { AnyAction } from 'redux';

import {
  CREATE_PRACTICE_INSURANCE,
  GET_PRACTICE_INSURANCE,
  SAVE_PRACTICE_INSURANCE,
} from '^/insurance/actions';
import { InsuranceResponse } from '^/insurance/types';
import { StoreState } from '^/types';
import { CODE_COMPANY_ID } from '^/utils/constants';
import { createCachedItem } from '^/utils/pagination-helpers';
import {
  CREATE_TEMPLATE,
  GET_TEMPLATE,
  GET_TEMPLATES,
  SAVE_TEMPLATE,
} from '../templates/actions';
import { Template } from '../templates/types';
import { InspatCode } from './inspat-config';

export function inspatConfig(
  state: StoreState['inspatConfig'] = {},
  action: AnyAction
): StoreState['inspatConfig'] {
  switch (action.type) {
    case GET_TEMPLATES.SUCCESS:
      const inspatTemplate = action.payload.data.results.find(
        (t: Template) =>
          t.code === InspatCode.INSPAT || t.code === InspatCode.NOVATINSPAT
      );

      if (inspatTemplate) {
        return {
          ...state,
          [inspatTemplate.code]: createCachedItem(inspatTemplate),
        };
      }

      return state;
    case GET_TEMPLATE.SUCCESS:
    case CREATE_TEMPLATE.SUCCESS:
    case SAVE_TEMPLATE.SUCCESS:
      if (
        action.payload.data.code === InspatCode.INSPAT ||
        action.payload.data.code === InspatCode.NOVATINSPAT
      ) {
        return {
          ...state,
          [action.payload.data.code]: createCachedItem(action.payload.data),
        };
      }

      return state;
    default:
      return state;
  }
}

export function inspracConfig(
  state: StoreState['inspracConfig'] = null,
  action: AnyAction
): StoreState['inspracConfig'] {
  switch (action.type) {
    case GET_PRACTICE_INSURANCE.SUCCESS:
      const inspracTemplate = action.payload.data.results.find(
        (i: InsuranceResponse) => i.practice_detail.crm_id === CODE_COMPANY_ID
      );

      if (inspracTemplate) {
        return createCachedItem(inspracTemplate);
      }

      return state;
    case CREATE_PRACTICE_INSURANCE.SUCCESS:
    case SAVE_PRACTICE_INSURANCE.SUCCESS:
      if (action.payload.data.practice_detail.crm_id === CODE_COMPANY_ID) {
        return createCachedItem(action.payload.data);
      }

      return state;
    default:
      return state;
  }
}

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import UsersCreatePage from './create-page';
import UsersEditPage from './edit-page';
import UsersListPage from './list-page';

class AdminUsers extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/create`} component={UsersCreatePage} />
        <Route path={`${match.path}/:id`} component={UsersEditPage} />
        <Route component={UsersListPage} />
      </Switch>
    );
  }
}

export default AdminUsers;

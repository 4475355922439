import React from 'react';
import { Link } from 'react-router-dom';

import {
  getContactCommsMethod,
  getContactEmail,
  getContactName,
  getContactPhone,
} from '^/common/helper-functions';
import { SectionDetail } from '^/common/section-detail';
import SectionDetailList from '^/common/section-detail-list';
import { getContactCategoryName } from '^/contacts/types';
import { RelationshipResponse } from '^/relationships/types';

// Props that come from the parent component.
interface OwnProps {
  relationship: RelationshipResponse | null;
  emptyStateMessage?: string;
  linkContactName?: boolean;
}

export type RelationshipsDetailProps = OwnProps;

class RelationshipsDetail extends React.PureComponent<
  RelationshipsDetailProps
> {
  public render() {
    const {
      relationship,
      emptyStateMessage = 'No relationship',
      linkContactName = true,
    } = this.props;

    if (!relationship) {
      return <div className="empty-state">{emptyStateMessage}</div>;
    }

    const contactName = getContactName(relationship.related_contact_detail);

    return (
      <SectionDetailList>
        <SectionDetail label="Contact Name">
          {linkContactName ? (
            <Link to={`/contacts/${relationship.related_contact}`}>
              {contactName}
            </Link>
          ) : (
            contactName
          )}
        </SectionDetail>
        <SectionDetail label="Contact Phone">
          {getContactPhone(relationship.related_contact_detail)}
        </SectionDetail>
        <SectionDetail label="Contact Email">
          {getContactEmail(relationship.related_contact_detail)}
        </SectionDetail>
        {relationship.reverse_relationship && (
          <SectionDetail label="Contact Role">
            {relationship.reverse_relationship}
          </SectionDetail>
        )}
        <SectionDetail label="Contact Preferences">
          {getContactCommsMethod(relationship.related_contact_detail)}
        </SectionDetail>
        {relationship.related_contact_detail.category && (
          <SectionDetail label="Category">
            {getContactCategoryName(relationship.related_contact_detail)}
          </SectionDetail>
        )}
      </SectionDetailList>
    );
  }
}

// Disconnected version used for testing
export { RelationshipsDetail as TestableRelationshipsDetail };

export default RelationshipsDetail;

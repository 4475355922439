import { PaymentMethod } from '^/contacts/ledger/types';
import { Fee } from '^/fees/types';
import { SubscriptionContact } from '^/plans/subscriptions/types';
import { Membership } from '../types';

export interface MembershipSubscription {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  status?: MembershipSubscriptionStatus;
  reference: string;
  start_date: string;
  sub_length_months?: number;
  plan: string | undefined;
  readonly plan_detail?: Membership | null;
  contacts: ReadonlyArray<SubscriptionContact>;
  payment_method?: PaymentMethod;
  payment_details?: string | null;
  lapsed?: boolean;
  lapsed_reason?: LapsedReason | null;
  lapsed_date?: string | null;
  payment_interval_months?: number;
  fee?: Fee;
  joining_fee?: Fee;
  readonly calculated_fees?: {
    fee?: Fee;
    joining_fee?: Fee;
    admin_fee?: Fee | null;
  };
  override_fees_at_renewal?: boolean;
  readonly renewal_date?: string;
  readonly last_renewal_date?: string | null;
  auto_renew: boolean;
  promotional_price?: number;
  promotional_price_months?: number;
  reminder_to?: string | null;
  invoice_to?: string | null;
  invoice_made_out_to?: string | null;
  inspat_insurance?: boolean;
  inspat_amount?: number;
  insprac_insurance?: boolean;
  insprac_amount?: number;
  suspended?: boolean;
  readonly outstanding_amount?: number;
  readonly last_invoice_date?: string | null;
  readonly next_payment_due?: string;
  readonly overdue_amount?: number;
  contact?: string;
  plan_length_months?: number;
  billing_container_subscription?: string;
  associated_bank: string | undefined;
  promotional_price_start_date?: string | undefined;
}

export type MembershipSubscriptionResponse = Readonly<
  Required<MembershipSubscription>
>;

export interface MembershipSubscriptionInjectedProps {
  memberships: MembershipSubscription[];
}

export enum MembershipSubscriptionStatus {
  Live = 'LIVE',
  Archived = 'ARCHIVED',
}

export enum LapsedReason {
  Cancelled = 'CAN',
  CeasedtoTrade = 'CTT',
  Deceased = 'DEC',
  FeeIncrease = 'FEE',
  TransferredToICAPP = 'ICA',
  Lapsed = 'LAP',
  LapsedForAdjustment = 'LPL',
  NotProceeding = 'NPR',
  NotRenewed = 'REN',
  Retired = 'RET',
  SettlementAgreed = 'SET',
  SoldPractice = 'SOL',
}

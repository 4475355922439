import classnames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';

interface OwnProps {
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  short?: boolean;
  shorter?: boolean;
  shortest?: boolean;
  stretch?: boolean;
  large?: boolean;
}

export type Props = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

export class RenderTextArea extends React.PureComponent<Props> {
  public render() {
    const {
      input,
      label,
      meta: { touched, error, warning, dirty, pristine },
      placeholder,
      disabled,
      short,
      shorter,
      shortest,
      stretch,
      large,
    } = this.props;

    return (
      <div
        id={`form-field-${input.name}`}
        className={classnames([
          'form-field',
          { 'form-field-short': short },
          {
            short,
            shorter,
            shortest,
            stretch,
            dirty,
            pristine,
            error: Boolean(error),
          },
        ])}
      >
        {label && <label>{label}</label>}
        <textarea
          className={classnames({ large })}
          disabled={disabled}
          {...input}
          placeholder={placeholder}
        />
        {touched && <ErrorRenderer error={[error, warning]} />}
      </div>
    );
  }
}

export default RenderTextArea;

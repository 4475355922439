import { ArcElement, Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import { Uuid } from '^/common/types';
import { Dashboard } from '../types';

Chart.register(ArcElement, ChartDataLabels);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      labels: {
        usePointStyle: true,
      },
      display: true,
      position: 'bottom',
    },
    datalabels: {
      anchor: 'end',
      align: 'top',
      font: {
        weight: 'bold',
      },
    },
  },
};

const getLabels = (data: Dashboard['patients_per_dentist']) => {
  return data.map(item => item.name);
};

const getValues = (data: Dashboard['patients_per_dentist']) => {
  return data.map(item => item.value);
};

export interface PatientsPerDentistProps {
  data?: Dashboard['patients_per_dentist'];
  loading?: boolean;
  practiceId?: Uuid;
}

class PatientsPerDentist extends React.PureComponent<PatientsPerDentistProps> {
  public render() {
    const { data, loading, practiceId } = { ...this.props };
    if (loading) {
      return <Loading />;
    } else if (data) {
      const chartData = {
        labels: getLabels(data),
        datasets: [
          {
            data: getValues(data),
            backgroundColor: [
              '#FFA500',
              '#F15930',
              '#00B68A',
              '#ffffe5',
              '#f7fcb9',
              '#d9f0a3',
              '#addd8e',
              '#78c679',
              '#41ab5d',
              '#238443',
            ],
          },
        ],
      };
      return (
        <>
          <h4>Patients Per Dentist</h4>
          <div className="chart-container">
            <Doughnut options={options as any} data={chartData} />
          </div>
          <AppButton
            className="iplan-app-button"
            url={`/api/iplan/dashboard/exports/patients-per-dentist?practice=${practiceId}`}
          >
            {`Download data >>`}
          </AppButton>
        </>
      );
    }
    return null;
  }
}

export { PatientsPerDentist as TestablePatientsPerDentist };

export default PatientsPerDentist;

import { Container, Nav, NavBar, NavItem } from '@dabapps/roe';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import AdminBank from './bank';
import AdminInsurance from './insurance';
import AdminMemberships from './memberships';
import AdminProducts from './products';
import AdminTemplates from './templates';
import AdminUsers from './users';

export type AdminRoutesProps = RouteComponentProps;

class AdminRoutes extends React.PureComponent<AdminRoutesProps> {
  public render() {
    const { match } = this.props;

    return (
      <>
        <NavBar className="secondary">
          <Container>
            <Nav>
              <NavItem>
                <NavLink to={`${match.url}/users`}>CODE Users</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${match.url}/insurance`}>Cover</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${match.url}/products`}>Products</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${match.url}/memberships`}>Memberships</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${match.url}/templates`}>Plans</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to={`${match.url}/bank`}>CODE Bank Details</NavLink>
              </NavItem>
            </Nav>
          </Container>
        </NavBar>

        <Switch>
          <Route path={`${match.path}/users`} component={AdminUsers} />
          <Route path={`${match.path}/insurance`} component={AdminInsurance} />
          <Route path={`${match.path}/products`} component={AdminProducts} />
          <Route
            path={`${match.path}/memberships`}
            component={AdminMemberships}
          />
          <Route path={`${match.path}/templates`} component={AdminTemplates} />
          <Route path={`${match.path}/bank`} component={AdminBank} />
          <Route component={this.redirect} />
        </Switch>
      </>
    );
  }

  public redirect = () => <Redirect to={`${this.props.match.url}/users`} />;
}

export default AdminRoutes;

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { formatDate } from '^/common/helper-functions';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';
import { getContactLedgerEntries } from './actions';
import { AllocationResponse, LedgerResponse } from './types';

export enum Direction {
  To = 'to',
  From = 'from',
}

// Props that come from the parent component.
interface OwnProps {
  allocations: ReadonlyArray<AllocationResponse>;
  ledgerEntry: LedgerResponse;
  direction: Direction;
}

// State props that come from redux.
interface StateProps {
  open: boolean;
}

export type LedgerAllocationSummaryProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class LedgerAllocationSummary extends React.PureComponent<
  LedgerAllocationSummaryProps
> {
  public componentDidMount() {
    this.refreshAllocations();
  }

  public componentDidUpdate(prevProps: LedgerAllocationSummaryProps) {
    if (this.props.allocations !== prevProps.allocations) {
      this.refreshAllocations();
    }
  }

  public render() {
    const {
      allocations,
      direction,
      ledgerEntry,
      ledgerEntriesByDetail,
      open,
    } = this.props;

    if (allocations.length < 1) {
      return null;
    }

    return (
      <div className="ledger-allocation-summary">
        <div
          className={classNames('collapse-header', { open })}
          onClick={this.toggleOpen}
        >
          <h4 title={`Allocations ${direction} selected entry`}>Allocations</h4>
          <FontAwesomeIcon icon="caret-down" />
        </div>
        <Collapse open={open}>
          <Table fill>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell title="Transaction ID">TX ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Reversed</TableCell>
                <TableCell className="align-right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allocations.map((allocation: AllocationResponse) => {
                const ledgerDetail =
                  ledgerEntry.detail_detail.id === allocation.ledger_from
                    ? allocation.ledger_to_detail
                    : allocation.ledger_from_detail;

                const ledger = getItemFromCache(
                  ledgerDetail.id,
                  ledgerEntriesByDetail
                );

                return (
                  <TableRow key={allocation.id} title={allocation.comments}>
                    <TableCell>
                      {ledger ? ledger.type : ledgerDetail.entry_type}
                    </TableCell>
                    <TableCell>
                      {ledger ? ledger.tx_id : ledgerDetail.tx_id}
                    </TableCell>
                    <TableCell>{formatDate(allocation.created)}</TableCell>
                    <TableCell className="align-center">
                      {allocation.is_reversed ? 'Y' : ''}
                    </TableCell>
                    <TableCell className="align-right">
                      {formatFeeAmount(allocation.amount)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Collapse>
      </div>
    );
  }

  public toggleOpen = () => this.props.setProps({ open: !this.props.open });

  private refreshAllocations = () => {
    const { allocations, ledgerEntry, ledgerEntriesByDetail } = this.props;

    allocations.forEach((allocation: AllocationResponse) => {
      const ledgerId =
        ledgerEntry.detail_detail.id === allocation.ledger_from
          ? allocation.ledger_to
          : allocation.ledger_from;

      if (cachedItemHasExpired(ledgerEntriesByDetail[ledgerId])) {
        this.props.getLedgerEntries({ detail: ledgerId });
      }
    });
  };
}

// Disconnected version used for testing
export { LedgerAllocationSummary as TestableLedgerAllocationSummary };

export const getInitialProps = () => ({ open: false });

export const mapState = (state: StoreState) => ({
  ledgerEntriesByDetail: state.ledgerEntriesByDetail,
});

const connector = connect(mapState, {
  getLedgerEntries: getContactLedgerEntries,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(LedgerAllocationSummary)
);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { rethrowOnAnyError } from '^/utils/action-helpers';

export const RELATIONSHIPS_ENDPOINT = '/api/relationships';
export const RELATIONSHIPS_TRANSFER_ENDPOINT = '/api/relationship-transfer';

export const GET_CONTACT_RELATIONSHIPS = makeAsyncActionSet(
  'GET_CONTACT_RELATIONSHIPS'
);
export function getContactRelationships(
  contact: string,
  types: ReadonlyArray<RelationshipType>,
  practice?: string,
  page: number = 1,
  pageSize: number = 100,
  actionSet = GET_CONTACT_RELATIONSHIPS
) {
  return (dispatch: Dispatch) => {
    const queryString: string = generateQueryString({
      contact,
      practice,
      types: types.join(','),
      page,
      page_size: pageSize,
    });

    return request<any>(
      actionSet,
      `${RELATIONSHIPS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      {
        metaData: {
          contact,
          practice,
          type: types.join(','),
          page,
          pageSize,
        },
        tag: types.join(','),
      }
    )(dispatch);
  };
}

export const GET_ISOLATED_CONTACT_RELATIONSHIPS = makeAsyncActionSet(
  'GET_ISOLATED_CONTACT_RELATIONSHIPS'
);
export function getIsolatedContactRelationships(
  contact: string,
  types: ReadonlyArray<RelationshipType>,
  practice?: string,
  page: number = 1,
  pageSize: number = 100
) {
  return getContactRelationships(
    contact,
    types,
    practice,
    page,
    pageSize,
    GET_ISOLATED_CONTACT_RELATIONSHIPS
  );
}

/**
 * Creates a new relationship.
 * @param {RelationshipForApi} relationship - The new relationship
 */
export const CREATE_RELATIONSHIP = makeAsyncActionSet('CREATE_RELATIONSHIP');
export function createRelationship(
  contact: string,
  relatedContact: string,
  relationship: RelationshipType,
  reverseRelationship: string = ''
) {
  return (dispatch: Dispatch) => {
    return request(
      CREATE_RELATIONSHIP,
      `${RELATIONSHIPS_ENDPOINT}/`,
      'POST',
      {
        contact,
        related_contact: relatedContact,
        relationship,
        reverse_relationship: reverseRelationship,
      },
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch).then(response => {
      getContactRelationships(contact, [relationship])(dispatch);
      getContactRelationships(relatedContact, [relationship])(dispatch);
      return response;
    });
  };
}

export const DELETE_RELATIONSHIP = makeAsyncActionSet('DELETE_RELATIONSHIP');
export function deleteRelationship(relationshipId: string) {
  return (dispatch: Dispatch) => {
    return request(
      DELETE_RELATIONSHIP,
      `${RELATIONSHIPS_ENDPOINT}/${relationshipId}/`,
      'DELETE',
      undefined,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch);
  };
}

/**
 * Removes all relationships particular RelationshipType from a contact.
 *
 * @param {string} contact - Contact to remove relationships from
 * @param {RelationshipType} type - The type of relationship to remove
 */
export const deleteRelationshipsOfType = (
  contact: string,
  type: RelationshipType
) => (dispatch: Dispatch) =>
  getContactRelationships(contact, [type])(dispatch).then(response => {
    if (!response || !response.data || !Array.isArray(response.data.results)) {
      return Promise.reject();
    }

    const actions = response.data.results.map(
      (relationship: RelationshipResponse) =>
        deleteRelationship(relationship.id)(dispatch)
    );

    return Promise.all(actions);
  });

export const relationshipActions = [
  DELETE_RELATIONSHIP,
  CREATE_RELATIONSHIP,
  GET_CONTACT_RELATIONSHIPS,
];

/** ActionSet for transferRelationship. */
export const TRANSFER_RELATIONSHIP = makeAsyncActionSet(
  'TRANSFER_RELATIONSHIP'
);

/**
 * Updates the existing PatientPerformer relationships for each patient of the 'originalContact'
 * Updates an existing contact. Sends a POST request to /api/relationship-transfer.
 * @param {string} originalContact - The contact id to be replaced
 * @param {string} newContact - The new contact id to replace with
 * @param {RelationshipType} relationship - The relationship type to determine which relationship entries to update.
 */
export const transferRelationship = (
  originalContact: string,
  newContact: string,
  practice: string,
  relationship: RelationshipType
) => (dispatch: Dispatch) => {
  return request(
    TRANSFER_RELATIONSHIP,
    `${RELATIONSHIPS_TRANSFER_ENDPOINT}/`,
    'POST',
    {
      original_contact: originalContact,
      new_contact: newContact,
      practice,
      relationship,
    }
  )(dispatch);
};

import { ModalBody, ModalHeader } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import {
  createBasicLedgerEntry,
  getContactLedger,
} from '^/contacts/ledger/actions';
import {
  Ledger,
  LedgerEntryType,
  LedgerResponse,
  RejectData,
} from '^/contacts/ledger/types';
import { closeModal } from '^/modals/actions';
import SimpleModal from '^/modals/simple-modal';
import CreateLedgerEntryForm from './forms/create-ledger-entry-form';

// Props that come from the parent component.
interface OwnProps {
  selectedEntries: ReadonlyArray<LedgerResponse>;
  contactId: string;
  entryType: LedgerEntryType;
}

export type CreateRejectModalProps = OwnProps &
  ConnectedProps<typeof connector>;

class CreateRejectModal extends React.PureComponent<CreateRejectModalProps> {
  public render() {
    const { selectedEntries } = this.props;

    if (selectedEntries.length !== 1) {
      return (
        <SimpleModal heading={'Create reject'}>
          <ModalHeader>You must select 1 entry (only)</ModalHeader>
        </SimpleModal>
      );
    }

    return (
      <SimpleModal heading={'Create reject'}>
        <ModalBody>
          <div className="modal-body-section">
            <CreateLedgerEntryForm
              initialValues={{
                ...selectedEntries[0],
                entry_date: moment().toISOString(),
              }}
              onSubmit={this.handleLedgerEntrySubmit}
              saveButtonText={'Create reject'}
            />
          </div>
        </ModalBody>
      </SimpleModal>
    );
  }

  public handleLedgerEntrySubmit = async (
    entry: Ledger,
    dispatch: Dispatch
  ) => {
    const { contactId, selectedEntries, entryType } = this.props;

    if (selectedEntries.length !== 1) {
      throw new SubmissionError({
        _error: 'Cannot allocate to more than one entry',
      });
    }

    if (
      entry.amount >
      selectedEntries[0].amount + selectedEntries[0].vat_amount
    ) {
      throw new SubmissionError({
        amount: 'Cannot reject more than the receipt amount',
      });
    }

    const reject: RejectData = {
      type: entryType,
      contact_1: contactId,
      amount: entry.amount.toString(),
      vat_amount: 0,
      entry_date: entry.entry_date,
      allocations: [
        {
          ledger_from: selectedEntries[0].detail,
          amount: entry.amount,
        },
      ],
      ...(entry.detail && {
        detail_detail: {
          comments: entry.detail_detail.comments,
          bank: entry.detail_detail.bank,
        },
      }),
    };

    const response = await createBasicLedgerEntry(reject)(dispatch);

    if (response && response.status === 201) {
      getContactLedger(contactId)(dispatch);
      this.props.closeModal();
    }
  };
}

// Disconnected version used for testing
export { CreateRejectModal as TestableCreateRejectModal };

const connector = connect(undefined, {
  closeModal,
});

export default connector(CreateRejectModal);

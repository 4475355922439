import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import InsurancePage from './insurance-page';

class AdminInsurance extends React.PureComponent<RouteComponentProps> {
  public render() {
    return (
      <Switch>
        <Route component={InsurancePage} />
      </Switch>
    );
  }
}

export default AdminInsurance;

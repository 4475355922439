import { anyPending } from '@dabapps/redux-requests';
import { TableBody } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';

import PaginatedTable from '^/common/paginated-table';
import {
  APPLY_REPORT,
  applyReport,
  GET_REPORTS_BY_TYPE,
  getReportsByType,
} from '^/routines/actions';
import { ReportCategory, ReportType } from '^/routines/types';
import { StoreState } from '^/types';
import { INTERVAL_MS } from '^/utils/constants';
import { getCurrentPage } from '^/utils/pagination-helpers';
import ReportRow from './report-row';

// Props that come from the parent component.
interface OwnProps {
  type: ReportType;
  refreshInterval?: number;
  noApplyButtons?: boolean;
  title?: string;
  reportButtonText?: string;
  hideExceptions?: boolean;
  category: ReportCategory;
}

export type RoutinesReportListProps = OwnProps &
  ConnectedProps<typeof connector>;

class RoutinesReportList extends React.PureComponent<RoutinesReportListProps> {
  public interval?: number;

  public componentDidMount(): void {
    const { type, category, refreshInterval = INTERVAL_MS } = this.props;

    this.props.getReportsByType(type, category, 1, 5);

    if (refreshInterval > 0) {
      this.interval = window.setInterval(this.refresh, refreshInterval);
    }
  }

  public componentWillUnmount(): void {
    window.clearInterval(this.interval);
  }

  public render() {
    const {
      reports,
      noApplyButtons,
      hideExceptions,
      title,
      reportButtonText = 'Report',
    } = this.props;

    if (!reports || reports.count < 1) {
      return null;
    }

    return (
      <PaginatedTable
        title={title}
        paginatedData={reports}
        loading={false}
        changePage={this.changePage}
        showPaginationBar
      >
        <CSSTransitionGroup
          transitionName="table-row-animation"
          component={TableBody}
          transitionEnterTimeout={1000}
          transitionLeave={false}
        >
          {getCurrentPage(reports).map((report, index) => (
            <ReportRow
              key={`report-row-${report.id}`}
              report={report}
              reportButtonText={reportButtonText}
              hideExceptions={hideExceptions}
              noApplyButtons={noApplyButtons || index !== 0}
            />
          ))}
        </CSSTransitionGroup>
      </PaginatedTable>
    );
  }

  public changePage = (page: number = 1, pageSize?: number) => {
    this.props.getReportsByType(
      this.props.type,
      this.props.category,
      page,
      pageSize
    );
  };

  public refresh = () => {
    const { type, reports } = this.props;

    if (reports) {
      this.props.getReportsByType(
        type,
        this.props.category,
        reports.page,
        reports.pageSize
      );
    }
  };
}

// Disconnected version used for testing
export { RoutinesReportList as TestableRoutinesReportList };

export const mapState = (state: StoreState, props: OwnProps) => ({
  reports: state.reportsByType[props.category]
    ? state.reportsByType[props.category][props.type]
    : undefined,
  loading: {
    reports: anyPending(state.responses, [GET_REPORTS_BY_TYPE, APPLY_REPORT]),
  },
});

const connector = connect(mapState, { getReportsByType, applyReport });

export default connector(RoutinesReportList);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { rethrowOnAnyError } from '^/utils/action-helpers';
import { PUBLIC_ENDPOINTS } from '../actions';
import { PatientSignupFormValues } from '../types';

export const PUBLIC_GET_PRACTICE_CLINICIANS = makeAsyncActionSet(
  'PUBLIC_GET_PRACTICE_CLINICIANS'
);
export function getPracticeClinicians(publicId: string) {
  return (dispatch: Dispatch) =>
    request(
      PUBLIC_GET_PRACTICE_CLINICIANS,
      PUBLIC_ENDPOINTS.CLINICIANS(publicId),
      'GET'
    )(dispatch);
}

export const PUBLIC_GET_PRACTICE_PLANS = makeAsyncActionSet(
  'PUBLIC_GET_PRACTICE_PLANS'
);
export function getPracticePlans(publicId: string) {
  return (dispatch: Dispatch) =>
    request(
      PUBLIC_GET_PRACTICE_PLANS,
      PUBLIC_ENDPOINTS.PLANS(publicId),
      'GET'
    )(dispatch);
}

export const PUBLIC_CREATE_NEW_PATIENT = makeAsyncActionSet(
  'PUBLIC_CREATE_NEW_PATIENT'
);
export function createNewPatient(data: Partial<PatientSignupFormValues>) {
  return (dispatch: Dispatch) => {
    return request(
      PUBLIC_CREATE_NEW_PATIENT,
      PUBLIC_ENDPOINTS.CREATE_NEW_PATIENT(),
      'POST',
      data,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch).catch(errors => {
      throw new SubmissionError(errors.response.data);
    });
  };
}

export const PUSH_RECAPTCHA_TOKEN_TO_STORE = 'PUSH_RECAPTCHA_TOKEN_TO_STORE';
export function pushRecaptchaTokenToStore(token: string | null) {
  return {
    type: PUSH_RECAPTCHA_TOKEN_TO_STORE,
    payload: token,
  };
}

import { setPropsReducer } from '@dabapps/react-set-props';
import { responsesReducer } from '@dabapps/redux-requests';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { AnyAction, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import * as activitiesReducers from '^/activities/reducers';
import { SELECT_ADDRESS } from '^/address/actions';
import { convertLoqateAddress } from '^/address/helpers';
import * as addressReducers from '^/address/reducers';
import * as nominalCodesReducers from '^/admin/bank/reducers';
import * as adminInsuranceReducers from '^/admin/insurance/reducers';
import * as adminMembershipsReducers from '^/admin/memberships/reducers';
import * as productReducers from '^/admin/products/reducers';
import * as templateReducers from '^/admin/templates/reducers';
import * as usersReducers from '^/admin/users/reducers';
import * as attachmentsReducers from '^/attachments/reducers';
import * as collectionsReducers from '^/collections/reducers';
import * as practicesReducers from '^/contacts/companies/practices/reducers';
import * as contactLedgerReducers from '^/contacts/ledger/reducers';
import * as patientReducers from '^/contacts/persons/patients/reducers';
import * as contactsReducers from '^/contacts/reducers';
import * as dashboardReducers from '^/dashboard/reducers';
import * as insuranceReducers from '^/insurance/reducers';
import * as iplanReducers from '^/iplan/reducers';
import * as ledgerReducers from '^/ledger/reducers';
import { LOGOUT } from '^/login/actions';
import * as loginReducers from '^/login/reducers';
import * as membershipSubscriptionsReducers from '^/memberships/subscriptions/reducers';
import * as modalReducers from '^/modals/reducers';
import {
  CREATE_PAYMENT_DETAILS,
  DELETE_PAYMENT_DETAILS,
  EDIT_PAYMENT_DETAILS,
} from '^/payment-details/actions';
import * as paymentDetailReducers from '^/payment-details/reducers';
import * as editPlanReducers from '^/plans/edit/reducers';
import * as plansReducers from '^/plans/reducers';
import * as subscriptionsReducers from '^/plans/subscriptions/reducers';
import * as publicPatientReducers from '^/public/reducers';
import * as relationshipsReducers from '^/relationships/reducers';
import * as routinesReducers from '^/routines/reducers';
import * as taskReducers from '^/tasks/reducers';
import { StoreState } from '^/types';

export const history = createBrowserHistory();

const appReducer = combineReducers<StoreState>({
  ...activitiesReducers,
  ...addressReducers,
  ...adminInsuranceReducers,
  ...adminMembershipsReducers,
  ...attachmentsReducers,
  ...collectionsReducers,
  ...contactLedgerReducers,
  ...contactsReducers,
  ...dashboardReducers,
  ...editPlanReducers,
  ...insuranceReducers,
  ...iplanReducers,
  ...ledgerReducers,
  ...loginReducers,
  ...membershipSubscriptionsReducers,
  ...modalReducers,
  ...nominalCodesReducers,
  ...patientReducers,
  ...paymentDetailReducers,
  ...plansReducers,
  ...practicesReducers,
  ...productReducers,
  ...publicPatientReducers,
  ...relationshipsReducers,
  ...routinesReducers,
  ...subscriptionsReducers,
  ...templateReducers,
  ...usersReducers,
  ...taskReducers,
  setPropsReducer,
  router: connectRouter(history),
  form: formReducer.plugin({
    iplanNewPatientForm: (state, action) => {
      if (state) {
        switch (action.type) {
          case SELECT_ADDRESS.SUCCESS:
            if (action.payload.data.Items[0]) {
              const loqateAddress = action.payload.data.Items[0];
              return {
                ...state,
                values: {
                  ...state.values,
                  ...convertLoqateAddress(loqateAddress),
                },
                fields: undefined,
                submitErrors: undefined,
              };
            }
            return state;
          default:
            return state;
        }
      }
    },
    publicPatientSignupForm: (state, action) => {
      if (state) {
        switch (action.type) {
          case SELECT_ADDRESS.SUCCESS:
            if (action.payload.data.Items[0]) {
              const loqateAddress = action.payload.data.Items[0];
              return {
                ...state,
                values: {
                  ...state.values,
                  ...convertLoqateAddress(loqateAddress),
                },
                fields: undefined,
                submitErrors: undefined,
              };
            }
            return state;
          default:
            return state;
        }
      }
    },
    addressForm: (state, action) => {
      if (state) {
        switch (action.type) {
          case SELECT_ADDRESS.SUCCESS:
            if (action.payload.data.Items[0]) {
              const loqateAddress = action.payload.data.Items[0];
              return {
                ...state,
                values: {
                  ...state.values,
                  ...convertLoqateAddress(loqateAddress),
                },
                fields: undefined,
                submitErrors: undefined,
              };
            }
            return state;
          default:
            return state;
        }
      }
    },
    paymentDetailsForm: (state, action) => {
      switch (action.type) {
        case CREATE_PAYMENT_DETAILS.SUCCESS:
        case EDIT_PAYMENT_DETAILS.SUCCESS:
        case DELETE_PAYMENT_DETAILS.SUCCESS:
          return {
            ...state,
            values: undefined,
            fields: undefined,
          };
        case CREATE_PAYMENT_DETAILS.FAILURE:
        case EDIT_PAYMENT_DETAILS.FAILURE:
          return { ...state, submitErrors: action.payload.response.data };
        default:
          return state;
      }
    },
  }),
  responses: responsesReducer,
});

export default (state: StoreState | undefined, action: AnyAction) => {
  if (action.type === LOGOUT.SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

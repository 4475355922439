import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { submit } from 'redux-form';

import {
  CREATE_ALLOCATIONS,
  createAllocations,
  getContactLedger,
} from '^/contacts/ledger/actions';
import { mapEntriesToAllocations } from '^/contacts/ledger/helpers';
import LedgerEntryPicker from '^/contacts/ledger/ledger-entry-picker';
import {
  LEDGER_ENTRY_TYPES,
  LedgerEntryType,
  LedgerResponse,
} from '^/contacts/ledger/types';
import { closeModal } from '^/modals/actions';
import WizardModal, {
  WizardModalError,
  WizardModalStage,
} from '^/modals/wizard-modal';
import { StoreState } from '^/types';
import CreateAllocationsForm, {
  AllocationFormValues,
} from './forms/create-allocations-form';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  entry: LedgerResponse;
}

interface StateProps {
  stage: number;
  selectedEntries: ReadonlyArray<LedgerResponse>;
}

export type AllocationModalProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

export enum Stage {
  Select,
  Allocate,
}

class AllocationModal extends React.PureComponent<AllocationModalProps> {
  public render() {
    const { stage, entry } = this.props;

    return (
      <WizardModal
        heading={`Allocate ${LEDGER_ENTRY_TYPES[entry.type]} ${entry.tx_id}`}
      >
        {this.getStage(stage)}
      </WizardModal>
    );
  }

  public getStage = (stage: Stage) => {
    const { contactId, selectedEntries, entry: entry, loading } = this.props;

    switch (stage) {
      case Stage.Select:
        return (
          <WizardModalStage
            heading={`Select entries to allocate this ${
              LEDGER_ENTRY_TYPES[entry.type]
            } to (Step 1 of 2)`}
            next={this.nextStage}
            nextDisabled={selectedEntries.length <= 0}
            nextText={
              selectedEntries.length <= 1
                ? 'Continue'
                : `Continue with ${selectedEntries.length} selected entries`
            }
          >
            <LedgerEntryPicker
              filters={{
                contact_1: contactId,
                type: this.getTypeFilter(),
                outstanding: 'True',
              }}
              selected={selectedEntries}
              onSelectionChanged={this.handleSelectionChanged}
            />
          </WizardModalStage>
        );
      case Stage.Allocate:
        return (
          <WizardModalStage
            heading="Allocate selected entries (Step 2 of 2)"
            back={this.prevStage}
            next={this.nextStage}
            nextText="Create allocations"
            loading={loading.createAllocations}
          >
            <CreateAllocationsForm
              initialValues={{
                entry,
                allocations: mapEntriesToAllocations(selectedEntries, entry),
              }}
              hideButtons
              onSubmit={this.handleSubmitAllocations}
            />
          </WizardModalStage>
        );
      default:
        return <WizardModalError next={this.props.closeModal} />;
    }
  };

  public nextStage = () => {
    switch (this.props.stage) {
      case Stage.Select:
        this.props.setProps({ stage: Stage.Allocate });
        break;
      case Stage.Allocate:
        this.props.submit('CreateAllocationsForm');
        break;
      default:
        break;
    }
  };

  public prevStage = () => {
    switch (this.props.stage) {
      case Stage.Select:
        this.props.closeModal();
        break;
      case Stage.Allocate:
        this.props.setProps({ stage: Stage.Select });
        break;
      default:
        break;
    }
  };

  public getTypeFilter = (): string | undefined => {
    const { entry } = this.props;

    switch (entry.type) {
      case LedgerEntryType.RecordReceipt:
      case LedgerEntryType.CreditNote:
        return LedgerEntryType.Invoice;
      case LedgerEntryType.RecordRefund:
        return [LedgerEntryType.CreditNote, LedgerEntryType.RecordReceipt].join(
          ','
        );
      default:
        return undefined;
    }
  };

  public handleSelectionChanged = (
    selectedEntries: ReadonlyArray<LedgerResponse>
  ) => this.props.setProps({ selectedEntries });

  public handleSubmitAllocations = async (values: AllocationFormValues) => {
    await this.props.createAllocations(values.allocations);

    this.props.getContactLedger(this.props.contactId);

    this.props.closeModal();
  };
}

export const getInitialProps = (): StateProps => ({
  selectedEntries: [],
  stage: Stage.Select,
});

// Disconnected version used for testing
export { AllocationModal as TestableAllocationModal };

export const mapState = (state: StoreState) => ({
  loading: {
    createAllocations: isPending(state.responses, CREATE_ALLOCATIONS),
  },
});

const connector = connect(mapState, {
  getContactLedger,
  createAllocations,
  closeModal,
  submit,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(AllocationModal)
);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

export const STORE_POLL_TIMEOUT = 'STORE_POLL_TIMEOUT';
export const storePollTimeout = (taskId: string, timeout: number) => ({
  type: STORE_POLL_TIMEOUT,
  payload: { taskId, timeout },
});

export const POLL_TASK = makeAsyncActionSet('POLL_TASK');
export const pollTask = (taskId: string) => {
  return (dispatch: Dispatch) => {
    return request(POLL_TASK, `/api/tasks/${taskId}/`, 'GET')(dispatch);
  };
};

export const CLEAR_ALL_TASK_TIMEOUTS = 'CLEAR_ALL_TASK_TIMEOUTS';
export const clearAllTaskTimeouts = () => ({
  type: CLEAR_ALL_TASK_TIMEOUTS,
  payload: {},
});

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import AppButton from '^/common/app-button';
import { getContactName } from '^/common/helper-functions';
import PracticePickerModal from '^/contacts/persons/patients/practice-picker/practice-picker-modal';
import { ContactResponse } from '^/contacts/types';
import { closeModal, openModal } from '^/modals/actions';

interface OwnProps {
  /** Contact to assign practice to */
  contact: ContactResponse;
  /** Current practice of contact */
  linkedPractice?: ContactResponse;
  /** Is loading something */
  loading?: boolean;
}

export type PatientPracticePickerProps = OwnProps &
  ConnectedProps<typeof connector>;

class PatientPracticePicker extends React.PureComponent<
  PatientPracticePickerProps
> {
  public render() {
    const { contact, linkedPractice, loading } = this.props;

    if (loading || !contact || !linkedPractice) {
      return null;
    }

    return (
      <div className="patient-practice-picker">
        {linkedPractice && (
          <Link to={`/practices/${linkedPractice.id}`}>
            <strong>{getContactName(linkedPractice)}</strong>
          </Link>
        )}
        <AppButton link onClick={this.showModal} small>
          {`${linkedPractice ? 'Change' : 'Set'} practice for ${getContactName(
            contact
          )}`}
        </AppButton>
      </div>
    );
  }

  public showModal = () =>
    this.props.openModal(
      <PracticePickerModal
        key={this.props.contact.id}
        onCloseModal={this.props.closeModal}
        patientId={this.props.contact.id}
      />
    );
}

export { PatientPracticePicker as TestablePatientPracticePicker };

const connector = connect(undefined, {
  openModal,
  closeModal,
});

export default connector(PatientPracticePicker);

import { SpacedGroup } from '@dabapps/roe';
import { push } from 'connected-react-router';
import React, { useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import AnimatedTick from '^/common/animated-tick';
import ConfirmationModal from '^/common/confirmation-modal';
import { closeModal, openModal } from '^/modals/actions';
import { validatePaymentDetails } from '^/payment-details/actions';
import { StoreState } from '^/types';
import { createNewPatient } from './actions';
import NewPatientForm from './new-patient-form';
import { NewPatientFormValues } from './types';

export type NewPatientPageProps = ConnectedProps<typeof connector>;

const NewPatientPage: React.FC<NewPatientPageProps> = ({
  selectedPracticeId,
  validatePaymentDetails: validateBankDetails,
  openModal: innerOpenModal,
  closeModal: innerCloseModal,
  submit: innerSubmit,
  push: innerPush,
}) => {
  const handleSuccess = useCallback(() => {
    const handleCloseModal = () => {
      innerCloseModal();
      innerPush(`/`);
    };

    innerOpenModal(
      <ConfirmationModal
        heading={
          <SpacedGroup>
            <AnimatedTick />
            <span> Success!</span>
          </SpacedGroup>
        }
        message={
          <>
            <p>The plan information has been submitted successfully.</p>
            <p>
              Before the patient appears on your system, iPlan will verify all
              of the entered information.
            </p>
            <p>
              If you have any queries, please contact us on 0330 165 9713 or
              email us at{' '}
              <a href="mailto:iplan@agiliosoftware.com">
                iplan@agiliosoftware.com
              </a>
            </p>
          </>
        }
        closeModal={handleCloseModal}
      />
    );
  }, [innerOpenModal, innerCloseModal, innerPush]);

  const handleSubmit = useCallback(
    async (data: Partial<NewPatientFormValues>, dispatch: Dispatch) => {
      data.additional_patients?.forEach(patient => {
        patient.practice = data.practice;
        patient.street_address = data.street_address || '';
        patient.city = data.city;
        patient.county = data.county;
        patient.country = data.country;
        patient.postcode = data.postcode || '';
      });

      if (Boolean(data.account_number && data.sort_code)) {
        validateBankDetails(
          data.account_number || '',
          data.sort_code || ''
        ).then(async response => {
          const validation = response && response.data.Items[0];

          if (validation.IsCorrect && validation.IsDirectDebitCapable) {
            await createNewPatient(data)(dispatch);
            handleSuccess();
          }
        });
      }
    },
    [innerPush]
  );

  const remoteSubmit = useCallback(() => {
    innerSubmit('iplanNewPatientForm');
  }, []);

  const handleConfirm = useCallback(() => {
    innerOpenModal(
      <ConfirmationModal
        heading=""
        action={remoteSubmit}
        actionLabel="Submit"
        message="Are you sure you want to submit?"
        closeModal={innerCloseModal}
      />
    );
  }, [innerOpenModal, innerCloseModal]);

  if (!selectedPracticeId) {
    innerPush('/');
  }

  return (
    <NewPatientForm
      initialValues={{
        practice: selectedPracticeId,
        backdate_months: 0,
      }}
      onSubmit={handleSubmit}
      onConfirm={handleConfirm}
    />
  );
};

export { NewPatientPage as TestableNewPatientParent };

const connector = connect(
  (state: StoreState) => ({
    selectedPracticeId: state.iplan?.selectedPracticeId,
  }),
  {
    openModal,
    closeModal,
    createNewPatient,
    push,
    submit,
    validatePaymentDetails,
  }
);

export default connector(NewPatientPage);

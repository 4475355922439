import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps, Switch } from 'react-router';

import AllowRoute from '^/common/allow-route';
import PageSection from '^/common/page-section/page-section';
import PageContent from '^/page-container/page-content';
import NewPatientPage from './new-patient/new-patient-page';
import PatientDetails from './patient/patient-detail-page';
import PatientsListPage from './patients-list-page';

class PatientsRoutesPage extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <PageContent page="patients">
        <Container>
          <PageSection>
            <Switch>
              <AllowRoute
                exact
                path={`${match.path}/new-patient`}
                component={NewPatientPage}
              />
              <AllowRoute
                exact
                path={`${match.path}/`}
                component={PatientsListPage}
              />
              <AllowRoute
                path={`${match.path}/:contactId/details`}
                component={PatientDetails}
              />
            </Switch>
          </PageSection>
        </Container>
      </PageContent>
    );
  }
}

export { PatientsRoutesPage as TestablePatientsRoutesPage };

export default PatientsRoutesPage;

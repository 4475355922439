import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import Loading from '^/common/loading';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';
import {
  CREATE_TEMPLATE,
  createTemplate,
  GET_TEMPLATE,
  GET_TEMPLATES,
  getTemplates,
  SAVE_TEMPLATE,
  saveTemplate,
} from '../templates/actions';
import { Template } from '../templates/types';
import InspatForm from './inspat-form';

export enum InspatCode {
  INSPAT = 'INSPAT',
  NOVATINSPAT = 'NOVATINSPAT',
}

interface OwnProps {
  code: InspatCode;
}

export type InspatConfigProps = OwnProps & ConnectedProps<typeof connector>;

class InspatConfig extends React.PureComponent<InspatConfigProps> {
  public componentDidMount() {
    const { inspatConfig, code } = this.props;

    if (cachedItemHasExpired(inspatConfig)) {
      this.props.getTemplates(1, 10, undefined, { code });
    }
  }

  public render() {
    const { inspatConfig, code, loading } = this.props;

    const heading =
      code === InspatCode.INSPAT ? 'INSPAT Cover' : 'INSPAT Cover - VAT Exempt';

    if (!inspatConfig) {
      if (loading.templates) {
        return (
          <>
            <h4>{heading}</h4>
            <Loading />
          </>
        );
      }

      return (
        <>
          <h4>{heading}</h4>
          <p>
            Cover not set up properly or discontinued. Please contact an
            administrator.
          </p>
        </>
      );
    }

    return (
      <>
        <h4>{heading}</h4>
        <InspatForm
          form={`${code}Form`}
          actions={[CREATE_TEMPLATE, SAVE_TEMPLATE]}
          initialValues={inspatConfig}
          onSubmit={this.handleSave}
        />
      </>
    );
  }

  public handleSave = (template: Partial<Template>, dispatch: Dispatch) => {
    const { code } = this.props;

    if (!template.id) {
      return createTemplate({
        code,
        description:
          code === InspatCode.INSPAT
            ? 'Insurance - Patient'
            : 'Insurance - Patient - VAT Exempt',
        ...template,
      })(dispatch);
    }

    return saveTemplate(template.id, template)(dispatch);
  };
}

// Disconnected version used for testing
export { InspatConfig as TestableInspatConfig };

export const mapState = (state: StoreState, props: OwnProps) => ({
  inspatConfig: getItemFromCache(props.code, state.inspatConfig),
  loading: {
    templates: anyPending(state.responses, [
      GET_TEMPLATE,
      GET_TEMPLATES,
      CREATE_TEMPLATE,
      SAVE_TEMPLATE,
    ]),
    saving: anyPending(state.responses, [CREATE_TEMPLATE, SAVE_TEMPLATE]),
  },
});

const connector = connect(mapState, {
  getTemplates,
});

export default connector(InspatConfig);

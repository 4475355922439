import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBell,
  faCaretDown,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faClock,
  faCog,
  faComment,
  faExclamationCircle,
  faExternalLinkAlt,
  faInfo,
  faInfoCircle,
  faPaperclip,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/browser';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import LoginRoutes from '^/login';
import LoginSetupSSO from '^/login/login-setup-sso';
import { history, store } from '^/store';
import Routes from './routes/routes';
import PublicRoutes from './routes/routes-public';

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBell,
  faCaretDown,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faClock,
  faCog,
  faComment,
  faExclamationCircle,
  faExternalLinkAlt,
  faInfo,
  faInfoCircle,
  faPaperclip,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash
);

Sentry.init({
  dsn: window.SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/portal" component={PublicRoutes} />
        <Route path="/marketing/sso-setup" component={LoginSetupSSO} />
        <Route path="/login" component={LoginRoutes} />
        <Route component={Routes} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);

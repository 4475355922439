import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { Activity } from '^/activities/types';
import ErrorRenderer from '^/common/error-renderer';
import { getFiles } from '^/contacts/companies/practices/actions';
import RenderUploadField from '^/form-helpers/render-file-upload';

interface OwnProps {
  contactId: string;
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  generalErrorFields?: ReadonlyArray<string>;
  hidePopupCheckbox?: boolean;
  /** If present a cancel button will be shown that will fire this prop. */
  dispatch?: Dispatch;
  onCancel?(): void;
}

export type FilesFormProps = OwnProps &
  InjectedFormProps<Activity, OwnProps> &
  ConnectedProps<typeof connector>;

class FilesForm extends React.Component<FilesFormProps> {
  public render() {
    const {
      handleSubmit,
      contactId,
      actions,
      generalErrorFields = ['non_field_errors'],
    } = this.props;

    const onAttachmentUploaded = () => {
      this.props.getFiles(contactId);
    };

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            allowedFileTypes={
              '.PDF, .DOC, .DOCX, .XLS, .XLSX, .CSV, .TXT, .HTML, .HTM'
            }
            component={RenderUploadField}
            name="attachments"
            contactId={this.props.contactId}
            onAttachmentUploaded={onAttachmentUploaded}
            showAttachments={false}
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={generalErrorFields}
          showStatusErrors
        />
      </Form>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { FilesForm as TestableFilesForm };

const connector = connect(null, { getFiles });

export default reduxForm<Activity, OwnProps>({
  form: 'filesForm',
})(connector(FilesForm));

import { Container, Nav, NavBar, NavItem } from '@dabapps/roe';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';

const NavigationBar = () => {
  return (
    <NavBar className="iplan-nav-bar">
      <Container>
        <Nav>
          <Allow roles={[UserRole.PracticeAdminLevel]}>
            <NavItem>
              <NavLink exact to="/">
                Dashboard
              </NavLink>
            </NavItem>
          </Allow>
          <NavItem>
            <NavLink to="/patients">Patients</NavLink>
          </NavItem>
          <Allow roles={[UserRole.PracticeAdminLevel]}>
            <NavItem>
              <NavLink to="/reports">Reports</NavLink>
            </NavItem>
          </Allow>
          <NavItem>
            <NavLink to="/practice-details">Practice Details</NavLink>
          </NavItem>
        </Nav>
      </Container>
    </NavBar>
  );
};

export default NavigationBar;

import { Container } from '@dabapps/roe';
import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { Loading } from '^/common/loading';

export enum HeaderBarStatusColour {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Pink = 'pink',
}

export interface HeaderBarStatus {
  colour: HeaderBarStatusColour;
  description?: string;
}

export interface OwnProps {
  title: string;
  subtitle?: string | null;
  primaryActions?: ReactNode | ReactNode[];
  secondaryActions?: ReactNode | ReactNode[];
  status?: HeaderBarStatus;
  loading?: boolean;
  transparent?: boolean;
}

export type Props = OwnProps;

export class HeaderBar extends React.PureComponent<Props> {
  public render() {
    const {
      title,
      subtitle,
      primaryActions: primaryActions,
      secondaryActions: secondaryActions,
      children,
      status,
      transparent,
      loading,
    } = this.props;

    return (
      <header className={classnames(['header-bar', { transparent, loading }])}>
        <Container>
          <div className="header-bar-content-wrapper">
            <div className="header-bar-title-wrapper">
              <span className="header-bar-titles">
                <h1>{title}</h1>
                {subtitle && <h3>{subtitle}</h3>}
                {status && (
                  <div
                    title={status.description}
                    className={classnames(['status-icon', status.colour])}
                  />
                )}
              </span>
              {primaryActions && (
                <span className="header-bar-primary-actions">
                  {primaryActions}
                </span>
              )}
            </div>
            <div className="header-bar-content">
              {children && (
                <span className="header-bar-children">{children}</span>
              )}
              {secondaryActions && (
                <span className="header-bar-secondary-actions">
                  {secondaryActions}
                </span>
              )}
            </div>
          </div>
        </Container>
        <div className="loading-overlay">{loading && <Loading />}</div>
      </header>
    );
  }
}

export default HeaderBar;

import { ModalBody } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';

import AppButton from '^/common/app-button';
import SimpleModal, { SimpleModalOwnProps } from './simple-modal';

const WizardModal: React.FC<SimpleModalOwnProps> = ({ children, ...rest }) => {
  return <SimpleModal {...rest}>{children}</SimpleModal>;
};

export interface WizardModalStageProps {
  heading?: string;
  loading?: boolean;
  helpText?: string;
  nextText?: string;
  nextDisabled?: boolean;
  backText?: string;
  next?(): void;
  back?(): void;
}

export const WizardModalStage: React.FC<WizardModalStageProps> = ({
  heading,
  loading,
  helpText,
  next,
  nextDisabled,
  nextText = 'Next',
  back,
  backText = 'Back',
  children,
}) => {
  return (
    <ModalBody>
      <div className={classNames('modal-body-section', { loading })}>
        {heading && <h4>{heading}</h4>}
        {children}
      </div>
      <div className="modal-body-section form-buttons">
        <p className="help-text">{helpText}</p>
        {back && (
          <AppButton onClick={back} disabled={loading}>
            {backText}
          </AppButton>
        )}
        {next && (
          <AppButton
            onClick={next}
            loading={loading}
            disabled={nextDisabled}
            primary
          >
            {nextText}
          </AppButton>
        )}
      </div>
    </ModalBody>
  );
};

export interface WizardModalErrorProps extends WizardModalStageProps {
  error?: string;
  warning?: string;
}

export const WizardModalError: React.FC<WizardModalErrorProps> = ({
  heading = 'Error',
  nextText = 'Close modal',
  error,
  warning,
  ...rest
}) => {
  if (!error && !warning) {
    error = 'Something went wrong, please try again later.';
  }

  return (
    <WizardModalStage heading={heading} nextText="Next" {...rest}>
      {error && <p className="error">{error}</p>}
      {warning && <p className="warning">{warning}</p>}
    </WizardModalStage>
  );
};

export default WizardModal;

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { MouseEvent } from 'react';
import { Dispatch } from 'redux';

import { getUserActionLogoutUrl } from '^/login/single-sign-on';
import { LoginCredentials } from '^/login/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';

export const ACCOUNTS_ENDPOINT = '/api/accounts';

export const LOGIN = makeAsyncActionSet('LOGIN');
export function login(credentials: LoginCredentials) {
  return (dispatch: Dispatch) =>
    request(LOGIN, `${ACCOUNTS_ENDPOINT}/login/`, 'POST', credentials, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch)
      .then(() => getLoggedInUser()(dispatch))
      .catch(throwSubmissionError);
}

export const GET_LOGGED_IN_USER = makeAsyncActionSet('GET_LOGGED_IN_USER');
export function getLoggedInUser() {
  return (dispatch: Dispatch) =>
    request(GET_LOGGED_IN_USER, `${ACCOUNTS_ENDPOINT}/me/`, 'GET')(dispatch);
}

export const LOGOUT = makeAsyncActionSet('LOGOUT');
export function logout(event: MouseEvent<HTMLAnchorElement>) {
  event.preventDefault();
  return (dispatch: Dispatch) =>
    request<any>(
      LOGOUT,
      `${ACCOUNTS_ENDPOINT}/logout/`,
      'POST'
    )(dispatch)
      .then(result => {
        if (result && result.data) {
          window.location.replace(getUserActionLogoutUrl(result.data));
        }
      })
      .catch(throwSubmissionError);
}

import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import { CREATE_CONTACT, createContact } from '^/contacts/actions';
import CompaniesForm from '^/contacts/companies/form';
import { Contact, ContactType } from '^/contacts/types';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';

export type CompaniesCreatePageProps = RouteComponentProps;

/**
 * Page for creating new companies (e.g. DabApps)
 */
class CompaniesCreatePage extends React.PureComponent<
  CompaniesCreatePageProps
> {
  public render() {
    return (
      <PageContent>
        <BreadcrumbBar
          items={[
            { label: 'Records', url: '/contacts' },
            { label: 'New company record' },
          ]}
        />
        <HeaderBar title="New company record" transparent />
        <Container>
          <main>
            <PageSection heading="Company Details">
              <CompaniesForm
                actions={[CREATE_CONTACT]}
                initialValues={{ type: ContactType.Company }}
                onSubmit={this.onSave}
                onCancel={this.onCancel}
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  /**
   * Fires off the action that will save this company.
   * @param {Contact} company - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public onSave = (company: Contact, dispatch: Dispatch) => {
    return createContact({ ...company, type: ContactType.Company })(
      dispatch
    ).then(response => {
      if (response) {
        const contact = response.data;
        return this.props.history.push(`/companies/${contact.id}`);
      }
    });
  };

  /**
   * Cancels the submission of this form. Goes back to the previous page if there is one, or to /contacts if there is no history.
   */
  public onCancel = () => {
    if (this.props.history.length > 2) {
      this.props.history.goBack();
    } else {
      this.props.history.replace('/contacts');
    }
  };
}

/** Disconnected version of the component that is used for testing. */
export { CompaniesCreatePage as TestableCompaniesCreatePage };

export default CompaniesCreatePage;

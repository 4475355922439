export interface Fee {
  amount?: number;
  vat_code?: VatCode;
  vat_inclusive?: boolean;
  nominal_code?: string;
  new_amount?: number | null;
  new_amount_applies?: string | null;
}

export type FeeResponse = Readonly<{
  amount: number;
  vat_code: VatCode;
  vat_inclusive: boolean;
  nominal_code: string;
  new_amount: number | null;
  new_amount_applies: string | null;
}>;

export enum VatCode {
  ZeroRated = 0,
  StandardRate = 1,
  VatExempt = 2,
  VatAt17_5 = 3,
  OutOfScope = 9,
}

export interface VatRate {
  code: VatCode;
  description: string;
  rate: number;
}

export const VatRates: { [code: number]: VatRate } = {
  [VatCode.ZeroRated]: {
    code: VatCode.ZeroRated,
    description: 'Zero Rated',
    rate: 0.0,
  },
  [VatCode.StandardRate]: {
    code: VatCode.StandardRate,
    description: 'Standard Rate',
    rate: 0.2,
  },
  [VatCode.VatExempt]: {
    code: VatCode.VatExempt,
    description: 'VAT Exempt',
    rate: 0.0,
  },
  [VatCode.VatAt17_5]: {
    code: VatCode.VatAt17_5,
    description: 'VAT at 17.5',
    rate: 0.175,
  },
  [VatCode.OutOfScope]: {
    code: VatCode.OutOfScope,
    description: 'Out of Scope',
    rate: 0.0,
  },
};

import { TableHeader } from '@dabapps/roe';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export interface TableHeaderSortProps {
  sortOrder?: 'ASC' | 'DESC' | null;
  onClick(): void;
}

export class TableHeaderSort extends React.PureComponent<TableHeaderSortProps> {
  public getIcon = (sortOrder?: 'ASC' | 'DESC' | null): IconProp => {
    if (sortOrder === 'ASC') {
      return 'sort-up';
    }
    if (sortOrder === 'DESC') {
      return 'sort-down';
    }
    return 'sort';
  };

  public render() {
    const icon = this.getIcon(this.props.sortOrder);
    return (
      <TableHeader className="table-header-sort" onClick={this.props.onClick}>
        {this.props.children}
        <span className="table-header-sort-icon">
          <FontAwesomeIcon icon={icon} />
        </span>
      </TableHeader>
    );
  }
}

export default TableHeaderSort;

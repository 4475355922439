import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending, resetRequestState } from '@dabapps/redux-requests/dist/js';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import PaginatedTable from '^/common/paginated-table';
import {
  CREATE_FEES_BY_AGE,
  createFeesByAge,
  GET_FEES_BY_AGE,
  getFeesByAge,
  SAVE_FEES_BY_AGE,
  saveFeesByAge,
} from '^/plans/actions';
import FeesByAgeForm from '^/plans/edit/fees-by-age-form';
import { FeesByAge, FeesByAgeResponse } from '^/plans/types';
import { StoreState } from '^/types';
import {
  getCurrentPage,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';
import { formatFeeAmount } from '../helpers';

interface OwnProps {
  planId: string;
}

interface StateProps {
  /** Mode of the card, if undefined show list, else show edit form. */
  feeToEdit?: FeesByAgeResponse | null;
}

export type FeesByAgeTableProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class FeesByAgeTable extends React.PureComponent<FeesByAgeTableProps> {
  public componentDidMount() {
    if (
      !this.props.loading &&
      paginatedArrayHasExpired(this.props.ageFees[this.props.planId])
    ) {
      this.props.getFeesByAge(this.props.planId);
    }
  }
  public render() {
    const { feeToEdit, loading, ageFees, planId } = this.props;

    if (feeToEdit === undefined) {
      return (
        <div>
          <PaginatedTable
            changePage={this.changePage}
            paginatedData={ageFees[planId]}
            loading={loading}
          >
            <TableHead>
              <TableRow>
                <TableHeader>Age from</TableHeader>
                <TableHeader>To</TableHeader>
                <TableCell className="align-right">Sub (M)</TableCell>
                <TableCell className="align-right">Sub (A)</TableCell>
                <TableCell className="align-right">Admin (M)</TableCell>
                <TableCell className="align-right">Admin (A)</TableCell>
                <TableCell className="align-right">Joining</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getCurrentPage(ageFees[planId]).map(ageFee => (
                <TableRow key={ageFee.id}>
                  <TableCell>{ageFee.age_from}</TableCell>
                  <TableCell>{ageFee.age_to}</TableCell>
                  <TableCell className="align-right">
                    {formatFeeAmount(ageFee.payment_amount_monthly)}
                  </TableCell>
                  <TableCell className="align-right">
                    {formatFeeAmount(ageFee.payment_amount_annually)}
                  </TableCell>
                  <TableCell className="align-right">
                    {formatFeeAmount(ageFee.admin_fee_monthly)}
                  </TableCell>
                  <TableCell className="align-right">
                    {formatFeeAmount(ageFee.admin_fee_annually)}
                  </TableCell>
                  <TableCell className="align-right">
                    {formatFeeAmount(ageFee.joining_fee)}
                  </TableCell>
                  <Allow
                    roles={[
                      UserRole.AdminLevel,
                      UserRole.FinanceLevel,
                      UserRole.OfficeLevel,
                    ]}
                  >
                    <TableCell>
                      <AppButton
                        onClick={this.showEditMode.bind(this, ageFee)}
                        link
                      >
                        edit
                      </AppButton>
                    </TableCell>
                  </Allow>
                </TableRow>
              ))}
            </TableBody>
          </PaginatedTable>
          <div className="form-buttons">
            <p>Note: fees include 20% VAT</p>
            <Allow
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
            >
              <AppButton onClick={this.showEditMode.bind(this, null)}>
                Add
              </AppButton>
            </Allow>
          </div>
        </div>
      );
    }

    return (
      <FeesByAgeForm
        actions={[feeToEdit ? SAVE_FEES_BY_AGE : CREATE_FEES_BY_AGE]}
        initialValues={feeToEdit || { plan: planId }}
        generalErrorFields={['plan']}
        onSubmit={this.handleSave}
        onCancel={this.showListMode}
      />
    );
  }

  /**
   * Switches this card into edit mode.
   */
  public showEditMode = (feeToEdit: FeesByAgeResponse | null) => {
    this.props.setProps({ feeToEdit });
  };

  /**
   * Switches this card into list mode.
   */
  public showListMode = () => {
    this.props.resetRequestState(CREATE_FEES_BY_AGE);
    this.props.resetRequestState(SAVE_FEES_BY_AGE);
    this.props.setProps({ feeToEdit: undefined });
  };

  public changePage = (page: number, pageSize?: number) =>
    this.props.getFeesByAge(this.props.planId, page, pageSize);

  public handleSave = (feeByAge: FeesByAge, dispatch: Dispatch) => {
    if (!feeByAge.id) {
      return createFeesByAge(feeByAge)(dispatch).then(() => {
        this.props.setProps({ feeToEdit: undefined });
      });
    }

    return saveFeesByAge(
      feeByAge.id,
      feeByAge
    )(dispatch).then(() => {
      this.props.setProps({ feeToEdit: undefined });
    });
  };
}

/** Sets the initial mode to List. */
export const getInitialProps = () => ({
  feeToEdit: undefined,
});

/** Disconnected version of the plan for testing */
export { FeesByAgeTable as TestableFeesByAgeTable };

export const mapState = (store: StoreState) => ({
  ageFees: store.feesByAge,
  loading: anyPending(store.responses, [GET_FEES_BY_AGE]),
});

const connector = connect(mapState, { getFeesByAge, resetRequestState });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(FeesByAgeTable)
);

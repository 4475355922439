import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import classnames from 'classnames';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import { GET_CONTACT_ADDRESSES, getContactAddresses } from '^/address/actions';
import AppButton from '^/common/app-button';
import { formatDate } from '^/common/helper-functions';
import SidebarCard from '^/common/sidebar-card';
import { getAllocations, LEDGER_ENDPOINT } from '^/contacts/ledger/actions';
import {
  BANK,
  LedgerEntryType,
  LedgerResponse,
  PAYMENT_METHODS,
} from '^/contacts/ledger/types';
import { formatFeeAmount, getSubscriptionName } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';
import LedgerAllocationSummary, {
  Direction,
} from './ledger-allocation-summary';
import LedgerItemsSummary from './ledger-items-summary';

interface OwnProps {
  contactId: string;
  ledgerEntries: ReadonlyArray<LedgerResponse>;
  heading: string;
  selectedAddressId?: string;
  clearSelectedLedgerEntries(): void;
  selectLedgerPrintAddress(selectedAddressId: string): void;
}

interface StateProps {
  itemsOpen: boolean;
}

export type LedgerDetailCardProps = OwnProps &
  ConnectedProps<typeof connector> &
  SetPropsInterface<StateProps>;

const entriesToFlip = [
  LedgerEntryType.RecordReceipt,
  LedgerEntryType.CreditNote,
];

class LedgerDetailCard extends React.PureComponent<LedgerDetailCardProps> {
  public componentDidMount() {
    this.props.getContactAddresses(this.props.contactId);
    if (this.props.ledgerEntries.length === 1) {
      this.props.getAllocations(this.props.ledgerEntries[0].detail);
    }
  }

  public componentDidUpdate(prevProps: LedgerDetailCardProps) {
    if (
      this.props.ledgerEntries !== prevProps.ledgerEntries &&
      this.props.ledgerEntries.length === 1
    ) {
      this.props.getAllocations(this.props.ledgerEntries[0].detail);
    }
  }

  public render() {
    const {
      ledgerEntries,
      clearSelectedLedgerEntries,
      selectedAddressId,
      allocationsByDetail,
    } = this.props;

    const ledgerEntry = ledgerEntries.length === 1 ? ledgerEntries[0] : null;
    const allocations =
      ledgerEntry && allocationsByDetail[ledgerEntry.detail]
        ? allocationsByDetail[ledgerEntry.detail]
        : null;

    return (
      <SidebarCard
        className={classnames('ledger-detail-card')}
        heading={this.generateHeading(ledgerEntries)}
        buttons={
          <AppButton
            onClick={clearSelectedLedgerEntries}
            disabled={ledgerEntries.length === 0}
            link
          >
            DESELECT
          </AppButton>
        }
      >
        <div className="sidebar-card-section">
          {ledgerEntry ? (
            <>
              <Table className="detail-table">
                <TableBody>
                  {ledgerEntry.nominal_code &&
                    ledgerEntry.nominal_code.length > 0 && (
                      <TableRow>
                        <TableHeader>Nominal Code:</TableHeader>
                        <TableCell>{ledgerEntry.nominal_code}</TableCell>
                      </TableRow>
                    )}
                  {ledgerEntry.detail_detail.due_date !== null && (
                    <TableRow>
                      <TableHeader>Due Date:</TableHeader>
                      <TableCell>
                        {formatDate(ledgerEntry.detail_detail.due_date)}
                      </TableCell>
                    </TableRow>
                  )}
                  {ledgerEntry.detail_detail.subscription !== null && (
                    <TableRow>
                      <TableHeader>Subscription:</TableHeader>
                      <TableCell>
                        <Link
                          to={`/subscriptions/${ledgerEntry.detail_detail.subscription}`}
                        >
                          {getSubscriptionName(
                            ledgerEntry.detail_detail.subscription_detail,
                            false,
                            ledgerEntry.detail_detail.subscription
                          )}
                        </Link>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableHeader>Goods amount:</TableHeader>
                    <TableCell>
                      {formatFeeAmount(
                        ledgerEntry.amount,
                        '£',
                        '-',
                        entriesToFlip.includes(ledgerEntry.type)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>VAT amount:</TableHeader>
                    <TableCell>
                      {formatFeeAmount(
                        ledgerEntry.vat_amount,
                        '£',
                        '-',
                        entriesToFlip.includes(ledgerEntry.type)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableHeader>Gross amount:</TableHeader>
                    <TableCell>
                      {formatFeeAmount(
                        ledgerEntry.amount + ledgerEntry.vat_amount,
                        '£',
                        '-',
                        entriesToFlip.includes(ledgerEntry.type)
                      )}
                    </TableCell>
                  </TableRow>
                  {ledgerEntry.type === LedgerEntryType.Invoice && (
                    <TableRow>
                      <TableHeader>Outstanding:</TableHeader>
                      <TableCell
                        className={classNames({
                          error: ledgerEntry.detail_detail.outstanding_amount,
                        })}
                      >
                        £
                        {(
                          ledgerEntry.detail_detail.outstanding_amount || 0
                        ).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {(ledgerEntry.type === LedgerEntryType.CreditNote ||
                    ledgerEntry.type === LedgerEntryType.RecordReceipt) && (
                    <TableRow>
                      <TableHeader>Allocated:</TableHeader>
                      <TableCell>
                        £{(ledgerEntry.detail_detail.allocated || 0).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {(ledgerEntry.type === LedgerEntryType.CreditNote ||
                    ledgerEntry.type === LedgerEntryType.RecordReceipt) && (
                    <TableRow>
                      <TableHeader>Unallocated:</TableHeader>
                      <TableCell
                        className={classNames({
                          warning:
                            ledgerEntry.amount +
                            ledgerEntry.vat_amount -
                            (ledgerEntry.detail_detail.allocated || 0),
                        })}
                      >
                        £
                        {(
                          ledgerEntry.amount +
                          ledgerEntry.vat_amount -
                          (ledgerEntry.detail_detail.allocated || 0)
                        ).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )}
                  {ledgerEntry.type === LedgerEntryType.RecordReceipt &&
                    ledgerEntry.payment_method && (
                      <TableRow>
                        <TableHeader>Payment Method:</TableHeader>
                        <TableCell>
                          {PAYMENT_METHODS[ledgerEntry.payment_method]}
                        </TableCell>
                      </TableRow>
                    )}
                  {ledgerEntry.type === LedgerEntryType.RecordReceipt &&
                    ledgerEntry.detail_detail.bank && (
                      <TableRow>
                        <TableHeader>Receipt to:</TableHeader>
                        <TableCell>
                          {BANK[ledgerEntry.detail_detail.bank]}
                        </TableCell>
                      </TableRow>
                    )}
                  {ledgerEntry.detail_detail.comments.length > 0 && (
                    <TableRow>
                      <TableHeader>Comments:</TableHeader>
                      <TableCell>
                        {ledgerEntry.detail_detail.comments}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableHeader>Created By:</TableHeader>
                    <TableCell>
                      {ledgerEntry.detail_detail.created_by ? (
                        <Link
                          className="value"
                          to={`/admin/users/${ledgerEntry.detail_detail.created_by.id}`}
                        >
                          {ledgerEntry.detail_detail.created_by.full_name}
                        </Link>
                      ) : (
                        'System'
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {allocations && (
                <LedgerAllocationSummary
                  allocations={allocationsByDetail[ledgerEntry.detail].filter(
                    allocation => allocation.ledger_to === ledgerEntry.detail
                  )}
                  ledgerEntry={ledgerEntry}
                  direction={Direction.To}
                />
              )}
              {allocations && (
                <LedgerAllocationSummary
                  allocations={allocationsByDetail[ledgerEntry.detail].filter(
                    allocation => allocation.ledger_from === ledgerEntry.detail
                  )}
                  ledgerEntry={ledgerEntry}
                  direction={Direction.From}
                />
              )}
              <LedgerItemsSummary
                ledgerItems={ledgerEntry.detail_detail.ledger_items}
              />
            </>
          ) : (
            <p className="empty-state">{`${ledgerEntries.length} entries selected`}</p>
          )}
        </div>
        <div className="sidebar-card-section print-form">
          <select
            value={selectedAddressId}
            onChange={this.onAddressChange}
            disabled={ledgerEntries.length === 0}
          >
            <option value="">{'Select a print address...'}</option>
            {getAllRetrievedPages(
              this.props.contactAddresses[this.props.contactId]
            ).map(option => (
              <option key={option.id} value={option.id}>
                {`${option.label} (${option.street_address})`}
              </option>
            ))}
          </select>
          <AppButton
            url={`${LEDGER_ENDPOINT}/?ledger_ids=${ledgerEntries
              .map(entry => entry.id)
              .join(',')}&address=${selectedAddressId}&download=true`}
            disabled={ledgerEntries.length === 0 || selectedAddressId === ''}
          >
            Print
          </AppButton>
        </div>
      </SidebarCard>
    );
  }

  public onAddressChange = (event: { target: { value: string } }) => {
    this.props.selectLedgerPrintAddress(event.target.value);
  };

  private generateHeading = (ledgerEntries: ReadonlyArray<LedgerResponse>) => {
    switch (ledgerEntries.length) {
      case 1:
        return `${ledgerEntries[0].tx_id} - ${ledgerEntries[0].type}`;
      default:
        return 'Selected Transactions';
    }
  };
}

export { LedgerDetailCard as TestableLedgerDetailCard };

export const getInitialProps = () => ({
  itemsOpen: false,
});

export const mapState = ({
  responses,
  contactAddresses,
  allocationsByDetail,
}: StoreState) => {
  return {
    contactAddresses,
    loading: anyPending(responses, [GET_CONTACT_ADDRESSES]),
    allocationsByDetail,
  };
};

const connector = connect(mapState, {
  getContactAddresses,
  getAllocations,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(LedgerDetailCard)
);

import { Table, TableBody, TableCell, TableHead, TableRow } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import BACSSubmissionRow from '^/ledger/bacs/bacs-submission-row';
import { StoreState } from '^/types';

// Props that come from the parent component.
interface OwnProps {
  heading?: string;
}

export type PendingBACSTableProps = OwnProps & ConnectedProps<typeof connector>;

class PendingBACSTable extends React.PureComponent<PendingBACSTableProps> {
  public render() {
    const { heading, pendingBACSSubmission } = this.props;

    return (
      <div className="paginated-table">
        {heading && <h4>{heading}</h4>}
        <Table fill>
          <TableHead>
            <TableRow>
              <TableCell>Contact ID</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell className="align-right">Amount</TableCell>
              <TableCell className="align-right">Transaction code</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingBACSSubmission.map(row => (
              <BACSSubmissionRow key={row.contact + row.amount} entry={row} />
            ))}
          </TableBody>
        </Table>
        {!pendingBACSSubmission.length && (
          <div className="empty-state">Add a contact to get started</div>
        )}
      </div>
    );
  }
}

// Disconnected version used for testing
export { PendingBACSTable as TestablePendingBACSTable };

export const mapState = (state: StoreState) => ({
  pendingBACSSubmission: state.pendingBACSSubmission,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export default connector(PendingBACSTable);

import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dispatch } from 'redux';

import AppButton from '^/common/app-button';
import { patchSubscriptionContact } from '^/plans/subscriptions/actions';
import {
  SubscriptionContact,
  SubscriptionContactResponse,
} from '^/plans/subscriptions/types';
import EditModalForm from './edit-modal-form';

// Props that come from the parent component.
interface OwnProps {
  heading?: string;
  subscriptionContact: SubscriptionContactResponse;
  onCloseModal(): void;
}

export type EditModalProps = OwnProps;

class EditModal extends React.PureComponent<EditModalProps> {
  public render() {
    const { heading = 'Subscription', subscriptionContact } = this.props;

    return (
      <Modal onClickOutside={this.props.onCloseModal}>
        <ModalHeader>
          <h2>{heading}</h2>
          <AppButton transparent onClick={this.props.onCloseModal}>
            <FontAwesomeIcon icon="times" />
          </AppButton>
        </ModalHeader>
        {this.props.children}
        <ModalBody>
          <EditModalForm
            onSubmit={this.handleSubmit}
            onCancel={this.props.onCloseModal}
            disableBackdating={subscriptionContact.joining_fee_paid}
            initialValues={{
              start_date: subscriptionContact.start_date,
              backdate_months: subscriptionContact.backdate_months,
            }}
          />
        </ModalBody>
      </Modal>
    );
  }

  public handleSubmit = (
    lapseData: {
      start_date: SubscriptionContact['start_date'];
      backdate_months: SubscriptionContact['backdate_months'];
    },
    dispatch: Dispatch
  ) =>
    patchSubscriptionContact(
      lapseData,
      this.props.subscriptionContact.id,
      this.props.subscriptionContact.subscription,
      this.props.subscriptionContact.contact
    )(dispatch).then(() => this.props.onCloseModal());
}

// Disconnected version used for testing
export { EditModal as TestableEditModal };

export default EditModal;

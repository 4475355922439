import { Column, ContentBox, FormGroup, Row } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { isChecked } from '^/common/validation';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import { StoreState } from '^/types';

export type DentalAgreementProps = ConnectedProps<typeof connector>;

const DentalPlanAgreement = ({
  practiceName,
  firstName,
  lastName,
  streetAddress,
  city,
  county,
  country,
  postcode,
  startDate,
}: DentalAgreementProps) => {
  return (
    <ContentBox>
      <PageSection
        className="dental-plan-agreement"
        heading="DENTAL PLAN AGREEMENT"
      >
        <div className="dental-plan-agreement-fields">
          <div className="agreement-parties">
            <Row className="row-first">
              <Column className="text-bold text-uppercase">Between</Column>
              <Column>{practiceName}</Column>
            </Row>
            <Row>
              <div className="text-bold text-uppercase">
                Agreement Start Date
              </div>
              <div>{moment(startDate).format('DD-MM-YYYY')}</div>
            </Row>
            <Row>
              <span className="text-caption-light">
                Collection will occur on the first working day of each month
              </span>
            </Row>
          </div>
          <Row>
            <Column className="text-bold text-uppercase">And</Column>
            <Column>{`${firstName} ${lastName}`}</Column>
            <Column className="text-bold margin-top-large">Of</Column>
            <Column>{`${streetAddress}`}</Column>
            <Column>{`${city}`}</Column>
            <Column>{`${county}`}</Column>
            <Column>{`${country}`}</Column>
            <Column>{`${postcode}`}</Column>
          </Row>
        </div>
        <span className=" confirm-account-holder">
          <FormGroup>
            <Field
              name="dental_plan_agreement"
              label="I agree to the agreement."
              component={RenderCheckBox}
              validate={[isChecked]}
            />
          </FormGroup>
        </span>
        <span className="data-protection-act-notification">
          CODEplan Ltd. will notify you of your Direct Debit via email and post
          within 10 working days. If you have any queries regarding the Direct
          Debit please contact CODEplan Ltd on 0330 165 9713 or email us at
          iplan@agiliosoftware.com
        </span>
        <span className="data-protection-act">
          Data Protection Act: your data will be kept confidential but we may
          send it confidentially to other companies for processing payments or
          correspondence about your membership. By signing this Agreement you
          are consenting to such use of personal details.
        </span>
      </PageSection>
    </ContentBox>
  );
};

export { DentalPlanAgreement as TestableDentalPlanAgreement };

const selector = formValueSelector('iplanNewPatientForm');

export const mapState = (state: StoreState) => {
  const firstName = selector(state, 'first_name');
  const lastName = selector(state, 'last_name');
  const streetAddress = selector(state, 'street_address');
  const city = selector(state, 'city');
  const county = selector(state, 'county');
  const country = selector(state, 'country');
  const postcode = selector(state, 'postcode');
  const startDate = selector(state, 'start_date');

  return {
    startDate,
    streetAddress: streetAddress || '',
    city: city || '',
    county: county || '',
    country: country || '',
    postcode: postcode || '',
    firstName: firstName || '',
    lastName: lastName || '',
    practiceName: state.loggedInUser?.practices.find(
      ({ id }) => id === state.iplan?.selectedPracticeId
    )?.name,
  };
};

const connector = connect(mapState);

export default connector(DentalPlanAgreement);

import React from 'react';
import { Route, RouteComponentProps } from 'react-router';

import PublicPatientsRoutesPage from '^/public/patients-routes-page';
import PageContainer from '../iplan/page-container/headless-index';

class PublicRoutes extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;
    return (
      <PageContainer>
        <Route path={`${match.path}`} component={PublicPatientsRoutesPage} />
      </PageContainer>
    );
  }
}

export { PublicRoutes as TestablePublicRoutes };
export default PublicRoutes;

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { TableBody, TableHead, TableHeader, TableRow } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import { toggleSortFor } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import {
  GET_PRACTICES,
  getPractices,
} from '^/contacts/companies/practices/actions';
import PracticeRow from '^/contacts/companies/practices/row';
import { FilterList } from '^/filters/types';
import TableHeaderSort from '^/sorts/table-header-sort';
import { SortList } from '^/sorts/types';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';

interface StateProps {
  appliedSorts: SortList;
}

export type PracticesTableProps = ConnectedProps<typeof connector> &
  SetPropsInterface<StateProps>;

class PracticesTable extends React.PureComponent<PracticesTableProps> {
  public componentDidUpdate(prevProps: PracticesTableProps) {
    const { practices, appliedSorts } = this.props;

    if (appliedSorts !== prevProps.appliedSorts) {
      this.props.getPractices(
        practices ? practices.filters : undefined,
        appliedSorts
      );
    }
  }

  public render() {
    const { practices, loading, appliedSorts, setProps } = this.props;

    return (
      <PaginatedTable
        paginatedData={practices}
        changePage={this.changePage}
        loading={loading}
        primary
        title="Search results"
      >
        <TableHead>
          <TableRow>
            <TableHeaderSort
              sortOrder={appliedSorts.combined_name}
              onClick={toggleSortFor.bind(
                this,
                'combined_name',
                appliedSorts,
                setProps
              )}
            >
              Practice
            </TableHeaderSort>
            <Allow
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
            >
              <TableHeader>Patients</TableHeader>
            </Allow>
            <TableHeader>Practice ID</TableHeader>
            <TableHeader>Address</TableHeader>
            <TableHeader>Postcode</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(practices).map(practice => (
            <PracticeRow key={practice.id} practiceResponse={practice} />
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public changePage = (page: number, pageSize?: number, filters?: FilterList) =>
    this.props.getPractices(filters, this.props.appliedSorts, page, pageSize);
}

export { PracticesTable as TestablePracticesTable };

export const getInitialProps = () => ({
  appliedSorts: {},
});

export const mapState = (state: StoreState) => ({
  practices: state.practices,
  loading: anyPending(state.responses, [GET_PRACTICES]),
});

const connector = connect(mapState, { getPractices });

export default withSetProps<StateProps>(getInitialProps)(
  connector(PracticesTable)
);

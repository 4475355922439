import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import RelatedActivities from '^/activities/related-activities';
import { addressActions } from '^/address/actions';
import Addresses from '^/address/addresses-component';
import Collections from '^/collections/collections';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import Sidebar from '^/common/sidebar';
import { GET_CONTACT, SAVE_CONTACT } from '^/contacts/actions';
import CompaniesDetailsCard from '^/contacts/companies/details-card';
import PaymentDetailsSection from '^/contacts/payment-details-section';
import { CategoryType, ContactStatus } from '^/contacts/types';
import SubscriptionsMembershipAddButton from '^/memberships/subscriptions/add-button';
import MembershipSubscriptionsTable from '^/memberships/subscriptions/table';
import { paymentDetailActions } from '^/payment-details/actions';
import { subscriptionActions } from '^/plans/subscriptions/actions';
import { relationshipActions } from '^/relationships/actions';
import RelationshipsDetailCard from '^/relationships/detail-card';
import RelationshipsListCard from '^/relationships/list-card';
import { RelationshipType } from '^/relationships/types';
import { ContactType, StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

export type CompaniesDetailsTabProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

/**
 * Displays all company details using editable cards.
 */
class CompaniesDetailsTab extends React.PureComponent<
  CompaniesDetailsTabProps
> {
  public render() {
    const {
      company,
      match: {
        params: { contactId },
      },
      loading,
    } = this.props;

    const disabled = company?.status === ContactStatus.Archived;
    const isHeadOffice = company?.category === CategoryType.HeadOffice;

    return (
      <Row>
        <Column xs={12} md={7} lg={8}>
          <PageSection>
            <PageSubSection heading="Company details" loading={loading.details}>
              <CompaniesDetailsCard
                companyId={contactId}
                labelPrefix="Company"
                disabled={disabled}
              />
            </PageSubSection>
            <RelationshipsListCard
              heading="Company Owner"
              contactId={contactId}
              filters={{ type: 'Individual,Company,Practice' }}
              label="Owner"
              showBothSides
              types={[RelationshipType.CompanyProvider]}
              detailed
              disabled={disabled}
            />
            <RelationshipsDetailCard
              heading="Main contact"
              contactId={contactId}
              filters={{
                type: [ContactType.Individual, ContactType.Company].join(','),
              }}
              label="Main Contact"
              types={[RelationshipType.CompanyContact]}
              disabled={disabled}
            />
            <PageSubSection heading="Addresses" loading={loading.addresses}>
              <Addresses contactId={contactId} disabled={disabled} />
            </PageSubSection>
            <RelationshipsListCard
              heading="Company members"
              contactId={contactId}
              filters={{ type: ContactType.Individual }}
              label="Company Member"
              showBothSides
              types={[RelationshipType.TeamMemberCompany]}
              disabled={disabled}
            />
            <RelationshipsListCard
              heading="Linked Practices"
              contactId={contactId}
              types={[RelationshipType.LinkedPractice]}
              label="Linked Practice"
              showBothSides
              filters={{ type: 'Practice,Company' }}
              disabled={disabled}
              paginated
            />
            <PaymentDetailsSection
              contact={company}
              disabled={disabled}
              isHeadOffice={isHeadOffice}
            />
          </PageSection>
          <PageSection heading="Memberships" loading={loading.memberships}>
            <MembershipSubscriptionsTable contactId={contactId} />
            <div className="page-section-buttons">
              <SubscriptionsMembershipAddButton
                contactId={contactId}
                disabled={disabled}
                isCompany
              />
            </div>
          </PageSection>
          <Collections
            contactId={contactId}
            disabled={disabled}
            isCompany
            isHeadOffice={isHeadOffice}
          />
        </Column>
        <Sidebar>
          <RelatedActivities contactId={contactId} disabled={disabled} />
        </Sidebar>
      </Row>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { CompaniesDetailsTab as TestableCompaniesDetailsTab };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  company: getItemFromCache(props.match.params.contactId, state.contactsCache),
  loading: {
    addresses: anyPending(state.responses, addressActions),
    details: anyPending(state.responses, [GET_CONTACT, SAVE_CONTACT]),
    memberships: anyPending(state.responses, subscriptionActions),
    paymentDetails: anyPending(state.responses, paymentDetailActions),
    relationships: anyPending(state.responses, relationshipActions),
  },
});

const connector = connect(mapState, {});

export default connector(CompaniesDetailsTab);

import { isPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import AppButton from '^/common/app-button';
import { GET_DASHBOARD, getDashboard } from '^/iplan/actions';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import AccountsOverdue from './charts/accounts-overdue';
import PatientMovements from './charts/patient-movements';
import PatientsGivenNotice from './charts/patients-given-notice';
import PatientsPerDentist from './charts/patients-per-dentist';
import TotalPatients from './charts/total-patients';

export type DashboardPageProps = { practiceId?: string } & ConnectedProps<
  typeof connector
> &
  RouteComponentProps;

class DashboardPage extends React.PureComponent<DashboardPageProps> {
  public componentDidMount() {
    const { practiceId } = this.props;
    if (practiceId) {
      this.props.getDashboard(practiceId);
    }
  }

  public componentDidUpdate(prevProps: DashboardPageProps) {
    const { practiceId } = this.props;
    if (practiceId !== prevProps.practiceId && practiceId) {
      this.props.getDashboard(practiceId);
    }
  }

  public render() {
    const { loading, dashboard, practiceId } = this.props;
    return (
      <PageContent page="dashboard">
        <Container>
          <div className="top-section">
            <AppButton onClick={this.onAddPatient}>+ Add Patient</AppButton>
          </div>
          <div className="graph-row">
            <div className="graph-container">
              <PatientMovements
                data={dashboard?.patient_movements}
                loading={loading}
                practiceId={practiceId}
              />
            </div>
            <div className="graph-container">
              <TotalPatients
                data={dashboard?.total_patients}
                loading={loading}
                practiceId={practiceId}
              />
            </div>
          </div>
          <div className="graph-row">
            <div className="graph-container">
              <AccountsOverdue
                data={dashboard?.accounts_overdue}
                loading={loading}
                practiceId={practiceId}
              />
            </div>
            <div className="graph-container">
              <PatientsGivenNotice
                data={dashboard?.patients_given_notice}
                loading={loading}
                practiceId={practiceId}
              />
            </div>
            <div className="graph-container">
              <PatientsPerDentist
                data={dashboard?.patients_per_dentist}
                loading={loading}
                practiceId={practiceId}
              />
            </div>
          </div>
        </Container>
      </PageContent>
    );
  }

  private onAddPatient = () => this.props.history.push('/patients/new-patient');
}

export { DashboardPage as TestableDashboardPage };

export const mapState = (state: StoreState) => ({
  practiceId: state.iplan?.selectedPracticeId,
  dashboard: state.iplan?.dashboard,
  loading: isPending(state.responses, GET_DASHBOARD),
});

const connector = connect(mapState, { getDashboard });

export default connector(DashboardPage);

import moment from 'moment';
import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { Field } from 'redux-form';

import { getUserName } from '^/admin/users/helpers';
import AppButton from '^/common/app-button';
import TruncatedText from '^/common/truncated-text';
import RenderTextArea from '^/form-helpers/render-text-area';
import { Comment } from './types';

interface OwnProps {
  comment: Comment;
  showMeta?: boolean;
  index?: number;
  editable?: boolean;
  dispatch?: Dispatch;
}

export type ActivityCommentProps = OwnProps;

const ActivityComment: React.FC<ActivityCommentProps> = ({
  comment,
  showMeta = true,
  editable = false,
  index,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [commentText, setCommentText] = useState(comment.text);

  const handleOnChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCommentText(event.target.value);
  };

  const handleOnClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="activity-comment">
      {showMeta && (
        <div className="meta">
          {moment(comment.created).calendar()} -{' '}
          {getUserName(comment.created_by_detail, 'Someone')}{' '}
          {moment(comment.modified) > moment(comment.created) ? '(edited)' : ''}
          :
        </div>
      )}
      {editable ? (
        <>
          {isEditing ? (
            <Field
              component={RenderTextArea}
              name={`comments[${index}].text`}
              large
              value={commentText}
              onChange={handleOnChange}
            />
          ) : (
            <TruncatedText text={commentText} truncatedLength={100} />
          )}
          {!isEditing ? (
            <div className="form-buttons">
              <AppButton
                small
                transparent
                className="edit-comment"
                onClick={handleOnClick}
              >
                edit
              </AppButton>
            </div>
          ) : null}
        </>
      ) : (
        <TruncatedText text={commentText} truncatedLength={100} />
      )}
    </div>
  );
};

export { ActivityComment as TestableActivityComment };

export default ActivityComment;

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import CodeBankDetailsPage from './details-page';

class AdminBank extends React.PureComponent<RouteComponentProps> {
  public render() {
    return (
      <Switch>
        <Route component={CodeBankDetailsPage} />
      </Switch>
    );
  }
}

export default AdminBank;

import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { StoreState } from '^/types';
import { selectActivePractice } from '../actions';

export type PracticeSelectorProps = ConnectedProps<typeof connector>;

class PracticeSelector extends React.PureComponent<PracticeSelectorProps> {
  constructor(props: PracticeSelectorProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount() {
    if (this.props.practices && this.props.practices.length > 0) {
      const selectedPracticeLS = localStorage.getItem(
        'iplan-selected-practice'
      );

      if (!selectedPracticeLS) {
        localStorage.setItem(
          'iplan-selected-practice',
          this.props.practices[0].id
        );
      }

      this.props.selectActivePractice(
        selectedPracticeLS ? selectedPracticeLS : this.props.practices[0].id
      );
    }
  }

  public render() {
    const { practices, selectedPractice } = this.props;

    const hasPractices = Boolean(practices && practices.length > 0);

    return (
      <div className="form-field pristine no-selection">
        <select onChange={this.onChange}>
          {!hasPractices && (
            <option disabled selected>
              No practices available
            </option>
          )}
          {practices?.map(p => (
            <option
              key={p.id}
              value={p.id}
              selected={p.id === selectedPractice}
            >
              {p.name}
            </option>
          ))}
        </select>
      </div>
    );
  }

  public onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    localStorage.setItem('iplan-selected-practice', event.target.value);
    this.props.selectActivePractice(event.target.value);
    this.props.push('/');
  }
}

export { PracticeSelector as TestablePracticeSelector };

export const mapState = (state: StoreState) => ({
  practices: state.loggedInUser?.practices,
  selectedPractice: state.iplan?.selectedPracticeId,
});

const connector = connect(mapState, { selectActivePractice, push });

export default connector(PracticeSelector);

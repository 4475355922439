import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import NotFoundPage from '^/errors/not-found-page';
import CreatePage from './create-page';
import EditPage from './edit-page';

class MembershipSubscriptionsRoutes extends React.PureComponent<
  RouteComponentProps
> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/create`} component={CreatePage} />
        <Route path={`${match.path}/:id/:contactId?`} component={EditPage} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

export default MembershipSubscriptionsRoutes;

import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import IndividualsCreatePage from './create-page';
import IndividualsDetailPage from './detail-page';

class IndividualsRoutes extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.path}/create`}
          component={IndividualsCreatePage}
        />
        <Route
          path={`${match.path}/:contactId`}
          component={IndividualsDetailPage}
        />
        <Route component={this.redirect} />
      </Switch>
    );
  }

  public redirect = () => <Redirect to="/contacts" />;
}

export default IndividualsRoutes;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';

interface OwnProps {
  className?: string;
  destructive?: boolean;
  disabled?: boolean;
  link?: boolean;
  loading?: boolean;
  primary?: boolean;
  secondary?: boolean;
  small?: boolean;
  transparent?: boolean;
  icon?: IconProp;
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  id?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  url?: string | null;
  target?: string;
  onClick?(event?: React.MouseEvent<HTMLButtonElement>): void;
}

export type AppButtonProps = OwnProps;

class AppButton extends React.PureComponent<AppButtonProps> {
  public render() {
    const {
      className = '',
      destructive,
      disabled,
      link,
      loading,
      primary,
      secondary,
      small,
      transparent,
      icon,
      leftIcon,
      rightIcon,
      onClick,
      id,
      type,
      url,
      target,
      children,
    } = this.props;

    return (
      <button
        id={id}
        type={type ? type : 'button'}
        onClick={!disabled && !loading ? onClick : undefined}
        className={classnames([
          'app-button',
          className,
          {
            destructive,
            link,
            loading,
            primary,
            secondary,
            small,
            transparent: transparent || link,
            ['icon-button']: Boolean(icon),
          },
        ])}
        disabled={disabled || loading}
      >
        <div className="app-button-icon app-button-icon-left">
          {leftIcon && <FontAwesomeIcon icon={leftIcon} />}
        </div>
        <div className="app-button-label">
          {icon ? (
            loading ? (
              <FontAwesomeIcon className="spinner" icon="circle-notch" spin />
            ) : (
              <FontAwesomeIcon icon={icon} />
            )
          ) : url && !disabled && !loading ? (
            <a target={target} href={url} className={className}>
              {children}
            </a>
          ) : (
            children
          )}
        </div>
        <div className="app-button-icon app-button-icon-right">
          {!link && !icon && loading && (
            <FontAwesomeIcon className="spinner" icon="circle-notch" spin />
          )}
          {!loading && rightIcon && <FontAwesomeIcon icon={rightIcon} />}
        </div>
      </button>
    );
  }
}

export default AppButton;

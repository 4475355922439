import moment from 'moment';
import { AnyAction } from 'redux';

import {
  CLEAR_CONTACTS,
  CREATE_CONTACT,
  GET_CODE_CONTACT,
  GET_CONTACT,
  GET_CONTACT_CONTACTS,
  GET_CONTACTS,
  GET_PAYER_FOR_CONTACT,
  SAVE_CONTACT,
} from '^/contacts/actions';
import {
  GET_DENTISTS_BY_PRACTICE,
  GET_PRACTICE_FOR_PATIENT,
} from '^/contacts/persons/patients/actions';
import { ContactResponse, UnverifiedPatientResponse } from '^/contacts/types';
import { GET_UNVERIFIED_CONTACT } from '^/iplan/actions';
import { LOGOUT } from '^/login/actions';
import { GET_CONTACT_RELATIONSHIPS } from '^/relationships/actions';
import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { DetailedCache, PaginatedArray, StoreState } from '^/types';
import { CODE_COMPANY_ID } from '^/utils/constants';
import { createCachedItem, paginatedReducer } from '^/utils/pagination-helpers';

export function contacts(
  state: PaginatedArray<ContactResponse> | null = null,
  action: AnyAction
): PaginatedArray<ContactResponse> | null {
  switch (action.type) {
    case GET_CONTACTS.SUCCESS:
      return paginatedReducer(state, action);
    case CLEAR_CONTACTS:
      return null;
    default:
      return state;
  }
}

export function contactContacts(
  state: StoreState['contactContacts'] = {},
  action: AnyAction
): StoreState['contactContacts'] {
  switch (action.type) {
    case GET_CONTACT_CONTACTS.SUCCESS:
      return {
        ...state,
        [action.meta.contact]: paginatedReducer(
          state[action.meta.contact],
          action
        ),
      };
    default:
      return state;
  }
}

export function unverifiedPatientsCache(
  state: DetailedCache<UnverifiedPatientResponse> = {},
  action: AnyAction
): DetailedCache<UnverifiedPatientResponse> {
  switch (action.type) {
    case GET_UNVERIFIED_CONTACT.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
          retrieved: moment(),
        },
      };

    default:
      return state;
  }
}

export function contactsCache(
  state: DetailedCache<ContactResponse> | null = null,
  action: AnyAction
): DetailedCache<ContactResponse> | null {
  switch (action.type) {
    case GET_UNVERIFIED_CONTACT.SUCCESS:
    case GET_CONTACT.SUCCESS:
    case SAVE_CONTACT.SUCCESS:
    case CREATE_CONTACT.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
          retrieved: moment(),
        },
      };
    case GET_PRACTICE_FOR_PATIENT.SUCCESS:
    case GET_CONTACTS.SUCCESS:
      if (!action.payload.data || !action.payload.data.results) {
        return state;
      }

      return action.payload.data.results.reduce(
        (
          newState: DetailedCache<ContactResponse>,
          contact: ContactResponse
        ) => ({
          [contact.id]: createCachedItem(contact),
          ...newState,
        }),
        state
      );
    case GET_CONTACT_RELATIONSHIPS.SUCCESS:
      if (!action.payload.data || !action.payload.data.results) {
        return state;
      }

      return action.payload.data.results.reduce(
        (
          newState: DetailedCache<ContactResponse>,
          relationship: RelationshipResponse
        ) => ({
          [relationship.contact]: createCachedItem(relationship.contact_detail),
          [relationship.related_contact]: createCachedItem(
            relationship.related_contact_detail
          ),
          ...newState,
        }),
        state
      );
    case LOGOUT.SUCCESS:
      return null;

    default:
      return state;
  }
}

export function payerPayeeGroup(
  state: StoreState['payerPayeeGroup'] = {},
  action: AnyAction
): StoreState['payerPayeeGroup'] {
  switch (action.type) {
    case GET_CONTACTS.SUCCESS:
      if (!action.meta.filters || !action.meta.filters.payer_payee_group_for) {
        return state;
      }

      return {
        ...state,
        [action.meta.filters.payer_payee_group_for]: paginatedReducer(
          state[action.meta.filters.payer_payee_group_for],
          action
        ),
      };
    default:
      return state;
  }
}

export function contactPayer(
  state: StoreState['contactPayer'] = {},
  action: AnyAction
): StoreState['contactPayer'] {
  switch (action.type) {
    case GET_PAYER_FOR_CONTACT.SUCCESS:
      if (action.payload.data.results.length < 1) {
        return state;
      }

      const payeeRelationship = action.payload.data.results.find(
        (r: RelationshipResponse) =>
          r.contact === action.meta.contactId &&
          r.relationship === RelationshipType.PayeePayer
      );

      if (payeeRelationship) {
        return {
          ...state,
          [action.meta.contactId]: {
            ...payeeRelationship.related_contact_detail,
            retrieved: moment(),
          },
        };
      }

      return state;
    default:
      return state;
  }
}

export function codeContact(
  state: StoreState['codeContact'] = null,
  action: AnyAction
): StoreState['codeContact'] {
  switch (action.type) {
    case GET_CONTACTS.SUCCESS:
    case GET_CODE_CONTACT.SUCCESS:
      const result = action.payload.data.results.find(
        (contact: ContactResponse) => contact.crm_id === CODE_COMPANY_ID
      );

      if (result) {
        return createCachedItem(result);
      }

      return state;
    case GET_CONTACT.SUCCESS:
      if (action.payload.data.crm_id === CODE_COMPANY_ID) {
        return createCachedItem(action.payload.data);
      }

      return state;
    default:
      return state;
  }
}

export function dentistsByPractice(
  state: StoreState['dentistsByPractice'] = {},
  action: AnyAction
): StoreState['dentistsByPractice'] {
  switch (action.type) {
    case GET_DENTISTS_BY_PRACTICE.SUCCESS:
      return {
        ...state,
        [action.meta.practiceId]: action.payload.data.results,
      };
    default:
      return state;
  }
}

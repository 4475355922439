import { LedgerItem } from '^/contacts/ledger/types';
import { MembershipSubscription } from '^/memberships/subscriptions/types';
import { PlanSubscription } from '^/plans/subscriptions/types';

export enum CollectionStatus {
  Pending = 'Pending',
  Invoiced = 'Invoiced',
  PartiallyAllocated = 'Partially allocated',
  Receipted = 'Receipted',
  Errored = 'Errored',
}

export interface Collection {
  id: string;
  subscription: string;
  readonly subscription_detail?:
    | MembershipSubscription
    | PlanSubscription
    | null;
  total_amount: number;
  total_vat_amount: number;
  collection_date: string;
  readonly status?: CollectionStatus;
  contact: string;
  ledger_items: ReadonlyArray<LedgerItem>;
  start_date: string;
  end_date: string;
  entry_date: string;
  invoiced?: boolean;
}

export type CollectionResponse = Readonly<Required<Collection>>;

import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import CompaniesCreatePage from './create-page';
import CompaniesDetailPage from './detail-page';

class CompaniesRoutes extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/create`} component={CompaniesCreatePage} />
        <Route
          path={`${match.path}/:contactId`}
          component={CompaniesDetailPage}
        />
        <Route component={this.redirect} />
      </Switch>
    );
  }

  public redirect = () => <Redirect to="/contacts" />;
}

export default CompaniesRoutes;

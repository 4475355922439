import { anyPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { ErrorPage } from '^/common/error-page';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import { contactActions } from '^/contacts/actions';
import { getContact } from '^/contacts/actions';
import ContactHeaderBar from '^/contacts/header-bar';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { contactMissingDetail, getItemFromCache } from '^/utils/cache-helpers';
import Details from './patient-details';

export type PatientDetailsPageProps = RouteComponentProps<{
  contactId: string;
}> &
  ConnectedProps<typeof connector>;

class PatientDetailsPage extends React.PureComponent<PatientDetailsPageProps> {
  public componentDidMount() {
    const {
      individual,
      loading,
      match: {
        params: { contactId },
      },
    } = this.props;

    if (!loading.details && contactMissingDetail(individual)) {
      this.props.getContact(contactId, { isPractice: false });
    }
  }

  public render() {
    const {
      individual,
      loading,
      match: {
        params: { contactId },
      },
    } = this.props;

    if (!individual) {
      if (loading.details) {
        return (
          <PageContent>
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Patient not found" />;
    }

    return (
      <PageContent>
        <BreadcrumbBar
          items={[['Patients', '/patients'], getContactName(individual)]}
        />
        <ContactHeaderBar contact={individual} loading={loading.details} />
        <Container>
          <Details contactId={contactId} />
        </Container>
      </PageContent>
    );
  }
}

export { PatientDetailsPage as TestablePatientDetailsPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{
    contactId: string;
  }>
) => ({
  individual: getItemFromCache(
    props.match.params.contactId,
    state.contactsCache
  ),
  loading: {
    details: anyPending(state.responses, contactActions),
  },
});

const connector = connect(mapState, {
  getContact,
});

export default connector(PatientDetailsPage);

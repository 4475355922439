import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { Container } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import { getContactPopups } from '^/activities/actions';
import { ActivityResponse } from '^/activities/types';
import AppButton from '^/common/app-button';
import { StoreState } from '^/types';
import { INTERVAL_MS } from '^/utils/constants';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
}

interface StateProps {
  hidden?: boolean;
}

export type ContactPopupsProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class ContactPopups extends React.PureComponent<ContactPopupsProps> {
  public interval?: number;

  public componentDidMount() {
    this.refresh();

    this.interval = window.setInterval(this.refresh, INTERVAL_MS);
  }

  public componentWillUnmount(): void {
    window.clearInterval(this.interval);
  }

  public componentDidUpdate(prevProps: ContactPopupsProps) {
    const { popups } = this.props;

    if (popups !== prevProps.popups) {
      if (popups && !prevProps.popups) {
        this.props.setProps({ hidden: false });
      } else if (
        popups &&
        prevProps.popups &&
        popups.count > prevProps.popups.count
      ) {
        this.props.setProps({ hidden: false });
      }
    }
  }

  public render() {
    const { popups, hidden, contactId } = this.props;

    const allPopups = getAllRetrievedPages(popups);

    if (!popups || allPopups.length < 1) {
      return (
        <div className="snackbar-wrapper hidden">
          <div className="snackbar hidden" />
        </div>
      );
    }

    const total = popups.count;

    return (
      <div className={classnames('snackbar-wrapper', { hidden })}>
        <div className={classnames('snackbar', { hidden })}>
          <Container className="snackbar-container">
            <div className="snackbar-content">
              {allPopups.map((activity: ActivityResponse) => (
                <Link
                  key={activity.id}
                  to={`/contacts/${contactId}/activities/${activity.id}?filter=Popups`}
                  onClick={this.hide}
                >
                  <div className="snackbar-title">Popup: {activity.title}</div>
                  {activity.comments[0] && (
                    <div>{activity.comments[0].text}</div>
                  )}
                </Link>
              ))}
              {total > allPopups.length && (
                <Link
                  to={`/contacts/${contactId}/activities?filter=Popups`}
                  onClick={this.hide}
                >
                  See {total - allPopups.length} more...
                </Link>
              )}
            </div>
            <div className="snackbar-buttons">
              <AppButton onClick={this.hide} small>
                {allPopups.length === 1 ? 'Hide Popup' : 'Hide Popups'}
              </AppButton>
            </div>
          </Container>
        </div>
      </div>
    );
  }

  public refresh = () => {
    this.props.getContactPopups(this.props.contactId);
  };

  public hide = () => this.props.setProps({ hidden: true });
}

// Disconnected version used for testing
export { ContactPopups as TestableContactPopups };

export const getInitialProps = () => ({});

export const mapState = (state: StoreState, props: OwnProps) => ({
  popups: state.contactPopups[props.contactId],
});

const connector = connect(mapState, { getContactPopups });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(ContactPopups)
);

import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { clearContacts, getContacts } from '^/contacts/actions';
import ContactsFilterForm from '^/contacts/filter-form';
import ContactsTable from '^/contacts/table';
import { CategoryType } from '^/contacts/types';
import { FilterList } from '^/filters/types';
import { StoreState } from '^/types';

export type ContactPatientsTabProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

class ContactPatientsTab extends React.PureComponent<ContactPatientsTabProps> {
  public componentDidMount() {
    this.clear();
  }

  public componentWillUnmount() {
    this.props.clearContacts();
  }

  public render() {
    const {
      contacts,
      match: {
        params: { contactId },
      },
    } = this.props;

    return (
      <Row>
        <Column xs={12} md={4} lg={3}>
          <div className="filter-section">
            <ContactsFilterForm
              initialValues={{
                ...(contacts ? contacts.filters : {}),
                related_contact: contactId,
                category: CategoryType.Patient,
              }}
              onSubmit={this.handleFiltersSubmit}
              onClear={this.clear}
              enableReinitialize
              hideCategory
            />
          </div>
        </Column>
        <Column xs={12} md={8} lg={9}>
          <ContactsTable />
        </Column>
      </Row>
    );
  }

  public handleFiltersSubmit = (filters: FilterList, dispatch: Dispatch) =>
    getContacts(filters)(dispatch);

  private clear = () =>
    this.props.getContacts({
      related_contact: this.props.match.params.contactId,
      category: CategoryType.Patient,
    });
}

export { ContactPatientsTab as TestableContactPatientsTab };

export const mapState = (state: StoreState) => ({
  contacts: state.contacts,
});

const connector = connect(mapState, { clearContacts, getContacts });

export default connector(ContactPatientsTab);

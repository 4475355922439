import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import { Report, ReportCategory, ReportType } from '^/routines/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';

export const REPORTS_ENDPOINT = '/api/reports';
export const EXPORTS_ENDPOINT = '/api/exports';

export const GET_REPORTS_BY_TYPE = makeAsyncActionSet('GET_REPORTS_BY_TYPE');
export const getReportsByType = (
  type: ReportType,
  category: ReportCategory,
  page: number = 1,
  pageSize: number = 5
) => (dispatch: Dispatch) =>
  request(
    GET_REPORTS_BY_TYPE,
    `${REPORTS_ENDPOINT}/${generateQueryString({
      page,
      page_size: pageSize,
      report_type: type,
      report_category: category,
    })}`,
    'GET',
    undefined,
    { metaData: { category, type, page, pageSize }, tag: type }
  )(dispatch);

export const REQUEST_REPORT = makeAsyncActionSet('REQUEST_REPORT');
export const requestReport = (report: Report) => (dispatch: Dispatch) =>
  request(
    REQUEST_REPORT,
    `${REPORTS_ENDPOINT}/`,
    'POST',
    {
      report_type: report.report_type,
      report_category: report.report_category,
      request_data: report.request_data,
      params: report.params,
    },
    { tag: report.report_type, shouldRethrow: rethrowOnAnyError }
  )(dispatch)
    .then(() =>
      getReportsByType(report.report_type, report.report_category)(dispatch)
    )
    .catch(throwSubmissionError);

export const REQUEST_BULK_REPORT = makeAsyncActionSet('REQUEST_BULK_REPORT');
export const requestBulkReport = (report: Report) => (dispatch: Dispatch) =>
  request(
    REQUEST_BULK_REPORT,
    `${REPORTS_ENDPOINT}/bulk-create/`,
    'POST',
    {
      report_type: report.report_type,
      report_category: report.report_category,
      request_data: report.request_data,
      params: report.params,
    },
    { tag: report.report_type, shouldRethrow: rethrowOnAnyError }
  )(dispatch)
    .then(() =>
      getReportsByType(report.report_type, report.report_category)(dispatch)
    )
    .catch(throwSubmissionError);

export const GET_BULK_REPORT_DETAIL = makeAsyncActionSet(
  'GET_BULK_REPORT_DETAIL'
);
export const getBulkReportDetail = (id: string) => (dispatch: Dispatch) =>
  request(
    GET_BULK_REPORT_DETAIL,
    `${REPORTS_ENDPOINT}/bulk-detail/${id}/`,
    'GET',
    {},
    { shouldRethrow: rethrowOnAnyError }
  )(dispatch).catch(throwSubmissionError);

export const GET_BULK_REPORT_IN_PROGRESS = makeAsyncActionSet(
  'GET_BULK_REPORT_IN_PROGRESS'
);
export const getBulkReportInProgress = (type: ReportType) => (
  dispatch: Dispatch
) =>
  request(
    GET_BULK_REPORT_IN_PROGRESS,
    `${REPORTS_ENDPOINT}/bulk-in-progress/${generateQueryString({
      report_type: type,
    })}`,
    'GET',
    undefined,
    {}
  )(dispatch);

export const APPLY_REPORT = makeAsyncActionSet('APPLY_REPORT');
export const applyReport = (report: Report) => (dispatch: Dispatch) =>
  request(
    APPLY_REPORT,
    `${REPORTS_ENDPOINT}/${report.id}/apply`,
    'GET',
    {
      report,
    },
    { tag: report.id }
  )(dispatch).then(() =>
    getReportsByType(report.report_type, report.report_category)(dispatch)
  );

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import RenderInputField from '^/form-helpers/render-input-field';
import { Discount } from '^/plans/types';

/** Props that are passed into this component by the parent. */
interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  generalErrorFields?: ReadonlyArray<string>;
  onCancel?(): void;
}

/** All the props combined. */
export type FamilyDiscountsFormProps = OwnProps &
  InjectedFormProps<Discount, OwnProps>;

/** Form that is used for Plan Details. Can be extended to be used in both create and edit
 * situations
 */
class FamilyDiscountsForm extends React.PureComponent<
  FamilyDiscountsFormProps
> {
  public render() {
    const {
      handleSubmit,
      submitting,
      actions,
      generalErrorFields = [],
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            label="Patients"
            component={RenderInputField}
            name="group_quantity"
            short
            type="number"
          />
          <Field
            label="Discount"
            component={RenderInputField}
            name="discount"
            short
            type="number"
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...generalErrorFields]}
          showStatusErrors
        />
        <Allow
          roles={[
            UserRole.AdminLevel,
            UserRole.FinanceLevel,
            UserRole.OfficeLevel,
          ]}
        >
          <div className="form-buttons">
            {this.props.onCancel && (
              <AppButton type="button" onClick={this.props.onCancel}>
                Cancel
              </AppButton>
            )}
            <AppButton type="submit" loading={submitting} primary>
              Save Changes
            </AppButton>
          </div>
        </Allow>
      </Form>
    );
  }
}

/** Disconnected version of the plan for testing */
export { FamilyDiscountsForm as TestableFamilyDiscountsForm };

export default reduxForm<Discount, OwnProps>({
  form: 'familyDiscountsForm',
})(FamilyDiscountsForm);

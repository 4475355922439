import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import AppButton from '^/common/app-button';
import {
  getContactCommsMethod,
  getContactName,
} from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import { SectionDetail } from '^/common/section-detail';
import SectionDetailList from '^/common/section-detail-list';
import { contactActions, saveContact } from '^/contacts/actions';
import CompaniesForm from '^/contacts/companies/form';
import { Contact, getContactCategoryName } from '^/contacts/types';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

export enum Mode {
  /** Shows a list of properties. */
  List = 'LIST',
  /** Shows an edit form and allows the user to update the company. */
  Edit = 'EDIT',
}

interface OwnProps {
  /** Company ID to retrieve and show details for. */
  companyId: string;
  /** Adds a prefix to labels. */
  labelPrefix: string;
  disabled?: boolean;
  allowEdit?: boolean;
}

interface StateProps {
  /** Mode of the card, can be Edit or List. */
  mode: Mode;
}

export type CompaniesDetailsCardProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

/**
 * Card that shows company details and an edit button which transforms the card into an edit form. Can be used for both practices and companies.
 */
class CompaniesDetailsCard extends React.PureComponent<
  CompaniesDetailsCardProps
> {
  public render() {
    const {
      mode,
      contact,
      loading,
      labelPrefix,
      disabled,
      allowEdit = true,
    } = this.props;

    if (!contact || !contact.company) {
      return loading ? (
        <Loading />
      ) : (
        <div className="empty-state">Not found</div>
      );
    }

    switch (mode) {
      case Mode.Edit:
        return (
          <CompaniesForm
            initialValues={contact}
            onSubmit={this.onSave}
            onCancel={this.showListMode}
            labelPrefix={labelPrefix}
          />
        );
      case Mode.List:
      default:
        return (
          <div className="flex-row">
            <SectionDetailList>
              <SectionDetail label={`${labelPrefix} Name`}>
                {getContactName(contact)}
              </SectionDetail>
              {contact.company.website && (
                <SectionDetail label={`${labelPrefix} Website`}>
                  <a href={`http://${contact.company.website}`}>
                    <FontAwesomeIcon icon="external-link-alt" />{' '}
                    {contact.company.website}
                  </a>
                </SectionDetail>
              )}
              {contact.company.email && (
                <SectionDetail label={`${labelPrefix} Email`}>
                  <a href={`mailto:${contact.company.email}`}>
                    {contact.company.email}
                  </a>
                </SectionDetail>
              )}
              <SectionDetail label="Contact Preferences">
                {getContactCommsMethod(contact)}
              </SectionDetail>
              {contact.category && (
                <SectionDetail label="Category">
                  {getContactCategoryName(contact)}
                </SectionDetail>
              )}
              {contact.company.bacs_prefix && (
                <SectionDetail label="Bacs Prefix">
                  {contact.company.bacs_prefix}
                </SectionDetail>
              )}

              <SectionDetail label="iPlan URL">
                {contact.company.unique_practice_url}
              </SectionDetail>
            </SectionDetailList>
            {allowEdit && (
              <div className="page-subsection-buttons">
                <AppButton
                  onClick={this.showEditMode}
                  small
                  disabled={disabled}
                >
                  {Mode.Edit}
                </AppButton>
              </div>
            )}
          </div>
        );
    }
  }

  /**
   * Switches this card into edit mode.
   */
  public showEditMode = () => this.props.setProps({ mode: Mode.Edit });

  /**
   * Switches this card into list mode.
   */
  public showListMode = () => this.props.setProps({ mode: Mode.List });

  /**
   * Fires off the action that will save this contact. Sets the card to list mode if successful.
   * @param {Contact} contact - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public onSave = (contact: Contact, dispatch: Dispatch) => {
    return saveContact(
      this.props.companyId,
      contact
    )(dispatch).then(() => {
      this.props.setProps({ mode: Mode.List });
    });
  };
}

/** Disconnected version of the component that is used for testing. */
export { CompaniesDetailsCard as TestableCompaniesDetailsCard };

/** Sets the initial mode to List. */
export const getInitialProps = () => ({
  mode: Mode.List,
});

export const mapState = (state: StoreState, props: OwnProps) => ({
  contact: getItemFromCache(props.companyId, state.contactsCache),
  loading: anyPending(state.responses, contactActions),
});

const connector = connect(mapState, {});

/** Details card is wrapped in both redux and setProps. */
export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(CompaniesDetailsCard)
);

import { Column, ContentBox } from '@dabapps/roe';
import React from 'react';

import { MembershipStats } from '^/dashboard/types';
import { numberWithCommas } from '^/utils/commons';

export interface MembershipStatisticsProps {
  membership: MembershipStats;
  formatNumbers?: boolean;
}

export const MembershipStatistics = ({
  membership: { name, total, in_period },
  formatNumbers,
}: MembershipStatisticsProps) => (
  <Column xs={12} sm={6} lg={3} className="display-flex">
    <ContentBox className="membership-statistics">
      <div className="membership-statistics-name">{name}</div>
      <div className="membership-statistics-total">
        {formatNumbers ? `£${numberWithCommas(total)}` : total}
      </div>
      {in_period ? (
        <div className="membership-statistics-period">
          {formatNumbers ? `£${numberWithCommas(in_period)}` : in_period}
        </div>
      ) : (
        undefined
      )}
      {in_period ? (
        <div className="membership-statistics-new-period">
          New in the period
        </div>
      ) : (
        undefined
      )}
    </ContentBox>
  </Column>
);

export default MembershipStatistics;

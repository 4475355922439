import { TableBody, TableCell, TableHead, TableRow } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';

import { formatDate } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import { LedgerResponse } from '^/contacts/ledger/types';
import { PaginatedArray } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import {
  shouldTypeDisplayOutstanding,
  shouldTypeDisplayUnallocated,
  totalAmount,
} from './helpers';

// Props that come from the parent component.
interface OwnProps {
  ledgerEntries?: PaginatedArray<LedgerResponse>;
  loading: boolean;
  selectedLedgerEntries: ReadonlyArray<LedgerResponse>;
  filter?: (entry: LedgerResponse) => boolean;
  onClickRow(entry: LedgerResponse): void;
  changePage(page: number, pageSize?: number): void;
}

export type LedgerTableProps = OwnProps;

class LedgerTable extends React.PureComponent<LedgerTableProps> {
  public render() {
    const {
      ledgerEntries,
      selectedLedgerEntries,
      loading,
      changePage,
      onClickRow,
      filter,
    } = this.props;

    return (
      <PaginatedTable
        paginatedData={ledgerEntries}
        changePage={changePage}
        loading={loading}
      >
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell title="Transaction ID">TX ID</TableCell>
            <TableCell className="align-right">Total</TableCell>
            <TableCell className="align-right">Outstanding</TableCell>
            <TableCell className="align-right">Unallocated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(ledgerEntries)
            .filter(filter ? filter : () => true)
            .map(entry => (
              <TableRow
                className={classnames('selectable', {
                  ['selected']: selectedLedgerEntries.some(
                    x => x.id === entry.id
                  ),
                })}
                onClick={onClickRow.bind(this, entry)}
                key={entry.id}
              >
                <TableCell>{formatDate(entry.entry_date)}</TableCell>
                <TableCell>{entry.type}</TableCell>
                <TableCell>{entry.tx_id}</TableCell>
                <TableCell className="align-right">
                  {totalAmount(entry)}
                </TableCell>
                <TableCell className="align-right">
                  {shouldTypeDisplayOutstanding(entry.type)
                    ? `£${(entry.detail_detail.outstanding_amount || 0).toFixed(
                        2
                      )}`
                    : '-'}
                </TableCell>
                <TableCell className="align-right">
                  {shouldTypeDisplayUnallocated(entry.type)
                    ? `£${(
                        entry.amount +
                        entry.vat_amount -
                        (entry.detail_detail.allocated || 0)
                      ).toFixed(2)}`
                    : '-'}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </PaginatedTable>
    );
  }
}

// Disconnected version used for testing
export { LedgerTable as TestableLedgerTable };

export default LedgerTable;

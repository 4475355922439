import { anyPending } from '@dabapps/redux-requests';
import { ModalBody } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import { getProducts } from '^/admin/products/actions';
import ProductInvoiceForm from '^/collections/product-invoice-form';
import { Loading } from '^/common/loading';
import { LedgerEntryType, ProductInvoice } from '^/contacts/ledger/types';
import { closeModal, openModal } from '^/modals/actions';
import SimpleModal from '^/modals/simple-modal';
import { GET_PLAN_SUBSCRIPTIONS } from '^/plans/subscriptions/actions';
import { StoreState } from '^/types';
import { createProductInvoice, getContactLedger } from '../actions';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
}

export type ProductInvoiceModalProps = OwnProps &
  ConnectedProps<typeof connector>;

class ProductInvoiceModal extends React.PureComponent<
  ProductInvoiceModalProps
> {
  public componentDidMount() {
    this.props.getProducts();
  }

  public render() {
    const { loading } = this.props;

    if (loading) {
      return (
        <SimpleModal heading="Product Invoice">
          <Loading />
        </SimpleModal>
      );
    }

    return (
      <SimpleModal heading="Product Invoice" size="large">
        <ModalBody>
          <ProductInvoiceForm
            initialValues={{
              entry_date: new Date().toISOString(),
            }}
            onSubmit={this.onGenerateInvoice}
            onClickBack={this.props.closeModal}
          />
        </ModalBody>
      </SimpleModal>
    );
  }

  public onGenerateInvoice = (invoice: ProductInvoice, dispatch: Dispatch) =>
    createProductInvoice({
      ...invoice,
      type: LedgerEntryType.Invoice,
      contact_1: this.props.contactId,
    })(dispatch).then(response => {
      if (response && response.status === 201) {
        getContactLedger(this.props.contactId, {})(dispatch);
        this.props.closeModal();
      }
    });
}

// Disconnected version used for testing
export { ProductInvoiceModal as TestableProductInvoiceModal };

export const mapState = (state: StoreState, props: OwnProps) => ({
  loading: anyPending(state.responses, [GET_PLAN_SUBSCRIPTIONS]),
  subscriptions: state.planSubscriptions[props.contactId],
  products: state.products,
});

const connector = connect(mapState, {
  getProducts,
  openModal,
  closeModal,
});

export default connector(ProductInvoiceModal);

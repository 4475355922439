import { isPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { CREATE_LETTER } from '^/admin/letters/actions';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import RenderInputField from '^/form-helpers/render-input-field';
import { StoreState } from '^/types';

const FORM_NAME = 'LetterDetailsForm';

export interface LetterDetailsValues {
  content: string;
  name: string;
  create_activities: boolean;
}

export interface OwnProps {
  disabled: boolean;
}

const selector = formValueSelector(FORM_NAME);

export const mapState = (state: StoreState) => ({
  content: selector(state, 'content'),
  loading: isPending(state.responses, CREATE_LETTER),
});

const connector = connect(mapState);

export type LetterDetailsFormProps = InjectedFormProps<
  LetterDetailsValues,
  OwnProps
> &
  ConnectedProps<typeof connector> &
  OwnProps;

class LetterDetailsForm extends React.PureComponent<LetterDetailsFormProps> {
  public render() {
    const { handleSubmit, submitting, loading, disabled } = this.props;

    return (
      <div className="letter-details-form">
        <Form onSubmit={handleSubmit}>
          <h4>2. Enter letter details</h4>
          <p className="help-text margin-none">
            This is just used for auditing purposes
          </p>
          <FormGroup className="margin-bottom-large margin-top-small">
            <Field
              name="name"
              label="Letter name (optional)"
              component={RenderInputField}
            />
            <ErrorRenderer
              actions={[CREATE_LETTER]}
              fields={['non_field_errors']}
              showStatusErrors
            />
          </FormGroup>

          <div>
            <AppButton
              type="submit"
              loading={submitting || loading}
              className="primary"
              disabled={disabled}
            >
              Generate mailmerge CSV
            </AppButton>
          </div>
        </Form>
        <p className="help-text">
          A CSV file will be generated for you to use in your letter mail merge
          - this may take a few moments to process.
        </p>
      </div>
    );
  }
}

export { LetterDetailsForm as TestableLetterDetailsForm };

export default reduxForm<LetterDetailsValues, OwnProps>({
  form: FORM_NAME,
})(connector(LetterDetailsForm));

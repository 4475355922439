export enum AttachmentStatus {
  Uploading = 'Uploading',
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export interface Attachment {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  readonly key?: string;
  file_name: string;
  file_type?: string;
  status: AttachmentStatus;
}

export type AttachmentResponse = Readonly<{
  id: string;
  created: string;
  modified: string;
  key: string;
  file_name: string;
  file_type: string;
  status: AttachmentStatus;
}>;

import { Dispatch, Middleware } from 'redux';

import { UserResponse } from '^/admin/users/types';

export const loggedInUser: UserResponse | null = window.LOGGED_IN_USER
  ? (window.LOGGED_IN_USER as UserResponse)
  : null;

export const checkForLogoutMiddleware: Middleware = () => (
  next: Dispatch
) => action => {
  if (action.payload && action.payload.response) {
    const response = action.payload.response;
    if (!window.location.pathname.includes('/login')) {
      if (
        response.status === 401 ||
        (response.status === 403 &&
          response.data.detail ===
            'Authentication credentials were not provided.')
      ) {
        window.location.replace(`/login?redirect=${window.location.pathname}`);
      }
    }
  }
  return next(action);
};

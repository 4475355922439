import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import PageSection from '^/common/page-section/page-section';
import FilesTable from '^/contacts/companies/practices/files-table';
import FilesForm from '../files-form';

export type ContactFilesTabProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

class ContactFilesTab extends React.PureComponent<ContactFilesTabProps> {
  public render() {
    return (
      <Row>
        <Column xs={12} md={8} lg={9}>
          <FilesTable contactId={this.props.match.params.contactId} />
        </Column>
        <Column xs={12} md={4} lg={3}>
          <PageSection className="add-files" heading="Add Files">
            <FilesForm contactId={this.props.match.params.contactId} />
          </PageSection>
        </Column>
      </Row>
    );
  }
}

export { ContactFilesTab as TestableContactPatientsTab };

export const mapState = () => ({});

const connector = connect(mapState, {});

export default connector(ContactFilesTab);

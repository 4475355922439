import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';

import { Uuid } from '^/common/types';
import { PUBLIC_REDIRECT_URLS } from '^/public/constants';
import { throwSubmissionError } from '^/utils/action-helpers';

export const PUBLIC_ENDPOINTS = {
  CLINICIAN_DETAILS: (publicId: Uuid, pk: Uuid) =>
    `/api/portal/practices/${publicId}/clinicians/${pk}/`,
  CLINICIANS: (publicId: Uuid) =>
    `/api/portal/practices/${publicId}/clinicians/`,
  CREATE_NEW_PATIENT: () => `/api/portal/practices/patients/create/`,
  LINKED_PRACTICES: (publicId: Uuid) =>
    `/api/portal/practices/${publicId}/linked/`,
  PLAN_DETAILS: (publicId: Uuid, pk: Uuid) =>
    `/api/portal/practices/${publicId}/plans/${pk}/`,
  PLANS: (publicId: Uuid) => `/api/portal/practices/${publicId}/plans/`,
  PRACTICE_DETAILS: (publicId: Uuid) => `/api/portal/practices/${publicId}/`,
  SIGNUP_REDIRECT: () => `/api/portal/practice/`,
};

export const PUBLIC_GET_LINKED_PRACTICES = makeAsyncActionSet(
  'PUBLIC_GET_LINKED_PRACTICES'
);
export function publicGetLinkedPractices(publicId: string) {
  return (dispatch: Dispatch) =>
    request(
      PUBLIC_GET_LINKED_PRACTICES,
      PUBLIC_ENDPOINTS.LINKED_PRACTICES(publicId),
      'GET'
    )(dispatch);
}

export const PUBLIC_GET_PRACTICE_DETAILS = makeAsyncActionSet(
  'PUBLIC_GET_PRACTICE_DETAIL'
);
export function publicGetPracticeDetails(publicId: string) {
  return (dispatch: Dispatch) =>
    request(
      PUBLIC_GET_PRACTICE_DETAILS,
      PUBLIC_ENDPOINTS.PRACTICE_DETAILS(publicId),
      'GET'
    )(dispatch)
      .then(response => {
        if (response === undefined) {
          dispatch(push(PUBLIC_REDIRECT_URLS.ERROR));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(push(PUBLIC_REDIRECT_URLS.ERROR));
        }
      });
}

export const PUBLIC_GET_PRESET_CLINICIAN_DETAILS = makeAsyncActionSet(
  'PUBLIC_GET_PRESET_CLINICIAN_DETAILS'
);
export function publicGetPresetClinicianDetails(publicId: string, pk: string) {
  return (dispatch: Dispatch) =>
    request(
      PUBLIC_GET_PRESET_CLINICIAN_DETAILS,
      PUBLIC_ENDPOINTS.CLINICIAN_DETAILS(publicId, pk),
      'GET'
    )(dispatch)
      .then(response => {
        if (response === undefined) {
          dispatch(push(PUBLIC_REDIRECT_URLS.ERROR));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(push(PUBLIC_REDIRECT_URLS.ERROR));
        }
      });
}

export const PUBLIC_GET_PRESET_PLAN_DETAILS = makeAsyncActionSet(
  'PUBLIC_GET_PRESET_PLAN_DETAILS'
);
export function publicGetPresetPlanDetails(publicId: string, pk: string) {
  return (dispatch: Dispatch) =>
    request(
      PUBLIC_GET_PRESET_PLAN_DETAILS,
      PUBLIC_ENDPOINTS.PLAN_DETAILS(publicId, pk),
      'GET'
    )(dispatch)
      .then(response => {
        if (response === undefined) {
          dispatch(push(PUBLIC_REDIRECT_URLS.ERROR));
        }
      })
      .catch(error => {
        if (error.response) {
          dispatch(push(PUBLIC_REDIRECT_URLS.ERROR));
        }
      });
}

export const PUBLIC_SET_PRESET_SIGNUP = 'PUBLIC_SET_PRESET_SIGNUP';
export function publicSetPresetSignUp(presetSignUp: boolean) {
  return {
    type: PUBLIC_SET_PRESET_SIGNUP,
    payload: {
      presetSignUp,
    },
  };
}

export const PUBLIC_PATIENT_SIGNUP_REDIRECT = makeAsyncActionSet(
  'PUBLIC_PATIENT_SIGNUP_REDIRECT'
);
export function publicPatientSignupRedirect(publicId: string) {
  return (dispatch: Dispatch) =>
    request<any>(
      PUBLIC_PATIENT_SIGNUP_REDIRECT,
      PUBLIC_ENDPOINTS.SIGNUP_REDIRECT(),
      'POST',
      { public_id: publicId }
    )(dispatch)
      .then(response => {
        if (response && response.data) {
          dispatch(push(response.data.url));
        }
      })
      .catch(throwSubmissionError);
}

export const PUBLIC_SELECT_ACTIVE_PRACTICE = 'SELECT_ACTIVE_PUBLIC_PRACTICE';
export function publicSelectActivePractice(publicId: string) {
  return {
    type: PUBLIC_SELECT_ACTIVE_PRACTICE,
    payload: {
      public_id: publicId,
    },
  };
}

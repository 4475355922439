import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import MembershipsCreatePage from './create-page';
import MembershipsEditPage from './edit-page';
import MembershipsListPage from './list-page';

class AdminMemberships extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.path}/create`}
          component={MembershipsCreatePage}
        />
        <Route path={`${match.path}/:id`} component={MembershipsEditPage} />
        <Route component={MembershipsListPage} />
      </Switch>
    );
  }
}

export default AdminMemberships;

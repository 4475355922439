import classnames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import ErrorRenderer from '^/common/error-renderer';
import { Categories, CategoryType } from '^/contacts/types';
import { ContactType } from '^/types';

interface OwnProps {
  contactType?: ContactType;
  /** Label for this field, shown above the dropdown. */
  label?: string;
  /** This field should be short - adds a class that sets a max width. */
  short?: boolean;
  shorter?: boolean;
  shortest?: boolean;
}

export type RenderCategoryPickerProps = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class RenderCategoryPicker extends React.PureComponent<
  RenderCategoryPickerProps
> {
  public render() {
    const {
      input,
      label,
      short,
      shorter,
      shortest,
      meta: { touched, error, warning, dirty, pristine },
      disabled,
      contactType,
    } = this.props;

    const filteredCategories = Categories.filter(category => {
      if (!contactType) {
        return true;
      }

      return (
        category.contactTypes && category.contactTypes.includes(contactType)
      );
    });

    if (filteredCategories.length < 2) {
      return null;
    }

    return (
      <div
        className={classnames([
          'form-field',
          {
            short,
            shorter,
            shortest,
            dirty,
            pristine,
            error: Boolean(touched && error),
            'no-selection': !input.value,
          },
        ])}
      >
        <div className="form-field-label">
          {label && <label>{label}</label>}
        </div>
        <select {...input} disabled={disabled || filteredCategories.length < 2}>
          <option disabled value="">
            Select a category...
          </option>
          {filteredCategories.map(category => (
            <Allow
              key={category.type}
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
              or={category.type !== CategoryType.Patient}
            >
              <option value={category.type}>{category.name}</option>
            </Allow>
          ))}
        </select>
        <div className="form-field-errors">
          {touched && <ErrorRenderer error={[error, warning]} />}
        </div>
      </div>
    );
  }
}

export { RenderCategoryPicker as TestableRenderCategoryPicker };

export default RenderCategoryPicker;

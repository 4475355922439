import { stringify } from 'query-string';

export interface UserLogoutResponse {
  id_token: string;
  open_id_logout_url: string;
}

export const { USE_SSO_AUTH_SERVICE } = window;
export const OPEN_ID_LOGIN_REDIRECT = '/open-id-login-redirect/';
export const DEVELOPMENT_LOGOUT_PATH = '/development-logout/';
export const DEVELOPMENT_LOGIN_PATH = '/development-login/';

export const getSSOAuthRedirectWithNextPath = (nextPath: string) => {
  const queryData = {
    nextUrl: nextPath,
  };
  return `${OPEN_ID_LOGIN_REDIRECT}?${stringify(queryData)}`;
};

export const getUserActionLogoutUrl = (data: UserLogoutResponse) => {
  if (USE_SSO_AUTH_SERVICE) {
    const { id_token, open_id_logout_url } = data;
    if (id_token && open_id_logout_url) {
      return open_id_logout_url;
    } else {
      return OPEN_ID_LOGIN_REDIRECT;
    }
  } else {
    return DEVELOPMENT_LOGOUT_PATH;
  }
};

import { anyPending, AsyncActionSet } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { createPaginatedArray } from '^/__helpers__/pagination-test-helpers';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { REQUEST_INVOICE_SUMMARY } from '^/contacts/ledger/actions';
import { generateSubscriptionDropdownOptions } from '^/contacts/ledger/helpers';
import RenderDropdown from '^/form-helpers/render-dropdown';
import {
  GET_PLAN_SUBSCRIPTIONS,
  getPlanSubscriptions,
} from '^/plans/subscriptions/actions';
import { StoreState } from '^/types';

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  errorFields?: ReadonlyArray<string>;
  contactId: string;
  disabled?: boolean;
  isCompany?: boolean;
}

export type CreateCollectionFormProps = OwnProps &
  InjectedFormProps<{ subscription: string }, OwnProps> &
  ConnectedProps<typeof connector>;

class CreateCollectionForm extends React.Component<CreateCollectionFormProps> {
  public componentDidMount() {
    this.props.getPlanSubscriptions(this.props.contactId, 1, 100);
  }

  public render() {
    const {
      actions = [],
      errorFields = [],
      handleSubmit,
      submitting,
      subscriptions = createPaginatedArray([]),
      loading,
      pristine,
      disabled,
      contactId,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="subscriptions-membership-add-button">
          <Field
            label="Subscription"
            component={RenderDropdown}
            name="subscription"
            options={generateSubscriptionDropdownOptions(
              subscriptions,
              contactId
            )}
          />
          <AppButton
            loading={submitting || loading}
            disabled={loading || pristine || disabled}
            type="submit"
          >
            Create collection
          </AppButton>
        </div>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          showStatusErrors
        />
      </Form>
    );
  }
}

export { CreateCollectionForm as TestableCreateCollectionForm };

export const mapState = (state: StoreState, props: OwnProps) => ({
  loading: anyPending(state.responses, [
    GET_PLAN_SUBSCRIPTIONS,
    REQUEST_INVOICE_SUMMARY,
  ]),
  subscriptions: state.planSubscriptions[props.contactId],
});

const connector = connect(mapState, { getPlanSubscriptions });

export default reduxForm<{ subscription: string }, OwnProps>({
  form: 'CreateCollectionForm',
  initialValues: {},
})(connector(CreateCollectionForm));

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { required } from '^/common/validation';
import { Bank, BANK_OPTIONS } from '^/contacts/ledger/types';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';

interface OwnProps {
  heading?: string;
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  errorFields?: ReadonlyArray<string>;
  disabled?: boolean;
}

export interface PendingBACSEntry {
  bank: Bank;
  entry_date: string;
}

export type PendingBACSFormProps = OwnProps &
  InjectedFormProps<PendingBACSEntry, OwnProps>;

class PendingBACSForm extends React.Component<PendingBACSFormProps> {
  public render() {
    const {
      actions = [],
      errorFields = [],
      handleSubmit,
      submitting,
      heading,
      disabled = false,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit} className="manual-bacs-submission-form">
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          error={[this.props.error]}
          showStatusErrors
        />
        {heading && <h4>{heading}</h4>}
        <FormGroup>
          <Field
            component={RenderDateField}
            name="entry_date"
            shorter
            thin
            label="Receipt date"
            validate={[required]}
          />
          <Field
            component={RenderDropdown}
            name="bank"
            shorter
            thin
            label="Bank"
            type="text"
            options={BANK_OPTIONS}
          />
          <AppButton
            loading={submitting}
            disabled={disabled}
            primary
            type="submit"
          >
            Generate manual BACS file
          </AppButton>
        </FormGroup>
      </Form>
    );
  }
}

export { PendingBACSForm as TestablePendingBACSForm };

export default reduxForm<PendingBACSEntry, OwnProps>({
  form: 'PendingBACSForm',
  initialValues: {
    bank: Bank.CodePlan,
    entry_date: moment().format('YYYY-MM-DD'),
  },
})(PendingBACSForm);

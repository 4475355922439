import { isPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { GET_MEMBERSHIP, getMembership } from '^/admin/memberships/actions';
import { ErrorPage } from '^/common/error-page';
import HeaderBar from '^/common/header-bar';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import { PaymentMethod } from '^/contacts/ledger/types';
import { ContactType } from '^/contacts/types';
import MembershipSubscriptionForm from '^/memberships/subscriptions/form';
import { MembershipResponse } from '^/memberships/types';
import BreadcrumbBar, { BreadcrumbBarItem } from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';
import { API_DATE_FORMAT } from '^/utils/constants';
import { getMembershipName } from '../helpers';
import {
  CREATE_MEMBERSHIP_SUBSCRIPTION,
  createMembershipSubscription,
} from './actions';
import { MembershipSubscription } from './types';

export type MembershipSubscriptionsCreatePageProps = ConnectedProps<
  typeof connector
> &
  RouteComponentProps;

class MembershipSubscriptionsCreatePage extends React.PureComponent<
  MembershipSubscriptionsCreatePageProps
> {
  public componentDidMount(): void {
    const { contactId, contact, membershipId } = this.props;

    if (contactId && cachedItemHasExpired(contact)) {
      this.props.getContact(contactId);
    }

    if (membershipId) {
      this.props.getMembership(membershipId);
    }
  }

  public render() {
    const {
      contactId,
      contact,
      membershipId,
      membership,
      loading,
    } = this.props;

    if (!contactId || !membershipId) {
      return <ErrorPage heading="Unable to load the page" />;
    }

    if (!membership || !contact) {
      if (loading.membership || loading.contact) {
        return (
          <PageContent>
            <BreadcrumbBar items={this.getBreadcrumbItems()} />
            <HeaderBar
              title="Create Membership Subscription"
              transparent
              loading
            />
            <Loading />
          </PageContent>
        );
      }

      return (
        <ErrorPage
          heading={`${!contact ? 'Contact' : 'Membership'} not found`}
        />
      );
    }

    const isHeadOffice =
      contact.type === ContactType.Company && membership.is_billing_container;

    return (
      <PageContent>
        <BreadcrumbBar items={this.getBreadcrumbItems()} />
        <HeaderBar
          title="Create Membership Subscription"
          subtitle={getMembershipName(membership, true)}
          transparent
        >
          <span>
            You are creating a {isHeadOffice ? 'Head Office' : ''} subscription
            to{' '}
            <Link to={`/admin/memberships/${membership.id}`}>
              {getMembershipName(membership, true)}
            </Link>{' '}
            for contact {getContactName(contact)}.
          </span>
        </HeaderBar>
        <Container>
          <PageSection>
            <MembershipSubscriptionForm
              actions={[CREATE_MEMBERSHIP_SUBSCRIPTION]}
              initialValues={this.createSubscriptionFromMembership(membership)}
              membership={membership}
              contact={contact}
              onSubmit={this.handleSave}
              onCancel={this.props.history.goBack}
            />
          </PageSection>
        </Container>
      </PageContent>
    );
  }

  public handleSave = (
    subscription: MembershipSubscription,
    dispatch: Dispatch
  ) =>
    createMembershipSubscription(subscription)(dispatch).then(
      this.props.history.goBack
    );

  private createSubscriptionFromMembership = (
    membership: MembershipResponse
  ): Partial<MembershipSubscription> => {
    const defaultStartDate = moment()
      .startOf('month')
      .add(14, 'days');

    return {
      plan: membership.id,
      start_date: defaultStartDate.format(API_DATE_FORMAT),
      sub_length_months: membership.plan_length_months,
      contacts: [],
      payment_interval_months: membership.payment_interval_months,
      payment_method: PaymentMethod.DirectDebit,
      fee: membership.fee,
      joining_fee: membership.joining_fee,
      override_fees_at_renewal: membership.variable_pricing,
      lapsed: false,
      auto_renew: true,
      last_renewal_date: defaultStartDate.format(API_DATE_FORMAT),
      renewal_date: defaultStartDate
        .add(membership.plan_length_months, 'months')
        .format(API_DATE_FORMAT),
      contact: this.props.contactId,
    };
  };

  private getBreadcrumbItems = (): ReadonlyArray<
    BreadcrumbBarItem | string | [string, string]
  > => {
    const { contact, membership } = this.props;

    if (!contact) {
      return [
        { label: 'Records', url: '/records', loading: true },
        { label: 'Loading...', loading: true },
        getMembershipName(membership, true),
      ];
    }

    return [
      contact.type === ContactType.Practice
        ? ['Practices', '/practices']
        : ['Records', '/records'],
      [getContactName(contact), `/contacts/${contact.id}`],
      getMembershipName(membership, true),
    ];
  };
}

// Disconnected version used for testing
export { MembershipSubscriptionsCreatePage as TestableMembershipSubscriptionsCreatePage };

export const mapState = (state: StoreState, props: RouteComponentProps) => {
  const queryParams = queryString.parse(props.location.search);

  const membershipId = queryParams.membership
    ? String(queryParams.membership)
    : undefined;
  const contactId = queryParams.contact
    ? String(queryParams.contact)
    : undefined;

  return {
    contactId,
    contact: getItemFromCache(contactId, state.contactsCache),
    membershipId,
    membership: getItemFromCache(membershipId, state.membershipCache),
    loading: {
      membership: isPending(state.responses, GET_MEMBERSHIP),
      contact: isPending(state.responses, GET_CONTACT),
    },
  };
};

const connector = connect(mapState, { getContact, getMembership });

export default connector(MembershipSubscriptionsCreatePage);

import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Link } from 'react-router-dom';

import ActivityBrowser from '^/activities/browser';
import { UserRole } from '^/admin/users/types';
import AppButton from '^/common/app-button';
import HeaderBar from '^/common/header-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';

export type ActivitiesListPageProps = RouteComponentProps &
  ConnectedProps<typeof connector>;

/**
 * Page for listing all Activities
 */
class ActivitiesListPage extends React.PureComponent<ActivitiesListPageProps> {
  public render() {
    const { loggedInUser } = this.props;

    const activitiesDownloadLink =
      loggedInUser?.role === UserRole.AdminLevel
        ? `/api/activities/csv/?letter=False`
        : `/api/activities/csv/?letter=False&assigned_to=${loggedInUser?.id}`;

    return (
      <PageContent>
        <HeaderBar
          title={
            loggedInUser && loggedInUser.role === UserRole.AdminLevel
              ? 'Activities'
              : 'My Activities'
          }
          transparent
          primaryActions={[
            <AppButton key="download-csv" url={activitiesDownloadLink}>
              Download as CSV
            </AppButton>,
            <Link key="new-activity" to="/activities/create">
              <AppButton leftIcon="plus" primary>
                New Activity
              </AppButton>
            </Link>,
          ]}
        />
        <Container>
          <Switch>
            <Route
              path={`${this.props.match.path}/:activityId`}
              component={ActivityBrowser}
            />
            <Route component={ActivityBrowser} />
          </Switch>
        </Container>
      </PageContent>
    );
  }
}

export { ActivitiesListPage as TestableActivitiesListPage };

export const mapState = ({ loggedInUser }: StoreState) => ({
  loggedInUser,
});

const connector = connect(mapState, {});

export default connector(ActivitiesListPage);

import React from 'react';

export interface OwnProps {
  options: string[];
  selectedOption: number;
  disabled?: boolean;
  optionSelected(option: string, index: number): void;
}

export type Props = OwnProps;

export class ToggleButtonGroup extends React.PureComponent<Props> {
  public render() {
    const { options, selectedOption, disabled } = this.props;

    return (
      <div className="toggle-button-group">
        {options.map((option, index) => (
          <div key={index} className="toggle-button-group-button">
            <input
              id={`toggle-button-group-button:${option}`}
              name={`toggle-button-group`}
              type="radio"
              onChange={this.optionSelected.bind(this, option, index)}
              className={selectedOption === index ? 'selected' : ''}
              checked={selectedOption === index}
              disabled={disabled}
            />
            <label htmlFor={`toggle-button-group-button:${option}`}>
              {option}
            </label>
          </div>
        ))}
      </div>
    );
  }

  private optionSelected(option: string, index: number) {
    this.props.optionSelected(option, index);
  }
}

export default ToggleButtonGroup;

import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { getContactName } from '^/common/helper-functions';
import { ContactResponse } from '^/contacts/types';
import RenderDropdown, {
  DropdownOptions,
} from '^/form-helpers/render-dropdown';

interface SelectedDentist {
  dentist: string;
}

interface OwnProps {
  dentists: ReadonlyArray<ContactResponse>;
}

export type DentistPickerFormProps = OwnProps &
  InjectedFormProps<SelectedDentist, OwnProps>;

class DentistPickerForm extends React.Component<DentistPickerFormProps> {
  public render() {
    const { dentists, handleSubmit } = this.props;

    const dentistOptions: DropdownOptions = [
      { value: '', label: 'Please choose...' },
      ...dentists.map(dentist => ({
        label: getContactName(dentist, true),
        value: dentist.id,
      })),
    ];

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            component={RenderDropdown}
            options={dentistOptions}
            name="dentist"
            label="Select replacement clinician"
          />
        </FormGroup>
      </Form>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { DentistPickerForm as TestableDentistPickerForm };

export default reduxForm<SelectedDentist, OwnProps>({
  form: 'dentistPickerForm',
})(DentistPickerForm);

import { anyPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { InspatCode } from '^/admin/insurance/inspat-config';
import { GET_TEMPLATE, getTemplate } from '^/admin/templates/actions';
import { ErrorPage } from '^/common/error-page';
import HeaderBar from '^/common/header-bar';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { CREATE_PLAN, createPlan } from '^/plans/actions';
import PlanDetailsForm from '^/plans/edit/plan-details-form';
import { getPlanName } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import { Plan } from './types';

export type PlansCreatePageProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

class PlansCreatePage extends React.PureComponent<PlansCreatePageProps> {
  public componentDidMount(): void {
    const { contactId, templateId } = this.props;

    if (contactId) {
      this.props.getContact(contactId);
    }

    if (templateId) {
      this.props.getTemplate(templateId);
    }
  }

  public render() {
    const { contactId, contact, templateId, template, loading } = this.props;

    if (!contactId || !templateId) {
      return <ErrorPage heading="Unable to load the page" />;
    }

    if (!contact || !template) {
      if (loading) {
        return (
          <PageContent>
            <BreadcrumbBar
              items={[
                ['Practices', '/practices'],
                { label: getContactName(contact), loading: true },
                'New practice plan',
              ]}
            />
            <HeaderBar title="Create Plan" transparent loading />
            <Loading />
          </PageContent>
        );
      }

      return (
        <ErrorPage heading={`${!contact ? 'Contact' : 'Template'} not found`} />
      );
    }

    return (
      <PageContent>
        <BreadcrumbBar
          items={[
            ['Practices', '/practice'],
            [getContactName(contact), `/practices/${contactId}`],
            'New practice plan',
          ]}
        />
        <HeaderBar
          title="Create Plan"
          subtitle={`${getPlanName(template, true)} for ${getContactName(
            contact
          )}`}
          transparent
        />
        <Container>
          <main>
            <PageSection thin>
              <PageSubSection heading="Plan Details" vertical>
                <PlanDetailsForm
                  initialValues={{
                    code: template.code,
                    description: template.description,
                    plan_length_months: template.plan_length_months,
                    practice: contact.id,
                    template: template.id,
                    payment_interval_months: template.payment_interval_months,
                    fee: template.fee,
                    joining_fee: template.joining_fee,
                    admin_fee: template.admin_fee,
                    variable_pricing: template.variable_pricing,
                    template_overrides_plan: template.template_overrides_plan,
                    insprac: false,
                  }}
                  actions={[CREATE_PLAN]}
                  generalErrorFields={['practice', 'template']}
                  onCancel={this.props.history.goBack}
                  onSubmit={this.onSave}
                  practice={contact.id}
                />
              </PageSubSection>
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  public onSave = (plan: Plan, dispatch: Dispatch) =>
    createPlan(plan)(dispatch).then(response => {
      if (response) {
        getPlanName(this.props.template, true) === InspatCode.INSPAT
          ? this.props.history.push(`/practices/${this.props.contactId}`)
          : this.props.history.push(`/plans/${response.data.id}`);
      }
    });
}

export { PlansCreatePage as TestablePlansCreatePage };

export const mapState = (state: StoreState, props: RouteComponentProps) => {
  const queryParams = queryString.parse(props.location.search);

  const contactId = queryParams.contact
    ? String(queryParams.contact)
    : undefined;
  const templateId = queryParams.template
    ? String(queryParams.template)
    : undefined;

  return {
    contactId,
    contact: getItemFromCache(contactId, state.contactsCache),
    templateId,
    template: getItemFromCache(templateId, state.templateCache),
    loading: anyPending(state.responses, [GET_CONTACT, GET_TEMPLATE]),
  };
};

const connector = connect(mapState, { getTemplate, getContact });

export default connector(PlansCreatePage);

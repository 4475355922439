import Container from '@dabapps/roe/dist/js/components/grid/container';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { createTemplate } from '^/admin/templates/actions';
import TemplatesForm from '^/admin/templates/form';
import { Template } from '^/admin/templates/types';
import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import PageContent from '^/page-container/page-content';

export type TemplateCreatePageProps = RouteComponentProps;

class TemplatesCreatePage extends React.PureComponent<TemplateCreatePageProps> {
  public render() {
    return (
      <PageContent>
        <HeaderBar title="Create Plan Template" transparent />
        <Container>
          <main>
            <PageSection>
              <TemplatesForm
                onSubmit={this.handleSave}
                onCancel={this.props.history.goBack}
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  public handleSave = (template: Template, dispatch: Dispatch) =>
    createTemplate(template)(dispatch).then(() => {
      this.props.history.replace(`/admin/templates`);
    });
}

export { TemplatesCreatePage as TestableTemplatesCreatePage };

export default TemplatesCreatePage;

import { makeAsyncActionSet, request } from '@dabapps/redux-requests/dist/js';
import { Dispatch } from 'redux';

import { Activity, ActivityResponse } from '^/activities/types';
import { generateQueryString } from '^/common/helper-functions';
import { generateSorts } from '^/contacts/actions';
import { FilterList } from '^/filters/types';
import { SortList } from '^/sorts/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const ACTIVITIES_ENDPOINT = '/api/activities';

export const GET_ACTIVITIES = makeAsyncActionSet('GET_ACTIVITIES');
export function getActivities(
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  filters: FilterList = {},
  sorting?: SortList,
  assignedUserId?: string,
  tag?: string
) {
  const queryString: string = generateQueryString({
    ...filters,
    ...(assignedUserId && { assigned_to: assignedUserId }),
    ordering: generateSorts(sorting),
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  return (dispatch: Dispatch) => {
    return request(
      GET_ACTIVITIES,
      `${ACTIVITIES_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { filters, page, pageSize }, tag }
    )(dispatch);
  };
}

export const GET_CONTACT_POPUPS = makeAsyncActionSet('GET_CONTACT_POPUPS');
export function getContactPopups(
  contactId: string,
  page: number = 1,
  pageSize: number = 3
) {
  return (dispatch: Dispatch) =>
    request(
      GET_CONTACT_POPUPS,
      `${ACTIVITIES_ENDPOINT}/?associated_contact=${contactId}&completed_next_action=False&popup=True&page=${page}&page_size=${pageSize}`,
      'GET',
      undefined,
      { metaData: { filters: {}, page, pageSize, contactId } }
    )(dispatch);
}

export const GET_ACTIVITY = makeAsyncActionSet('GET_ACTIVITY');
export function getActivity(id: string) {
  return (dispatch: Dispatch) =>
    request(GET_ACTIVITY, `${ACTIVITIES_ENDPOINT}/${id}`, 'GET')(dispatch);
}

export const GET_CONTACT_ACTIVITIES = makeAsyncActionSet(
  'GET_CONTACT_ACTIVITIES'
);
export function getContactActivities(
  contactId: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  filters: FilterList = {},
  sorting?: SortList
) {
  const queryString: string = generateQueryString({
    associated_contact: contactId,
    page: page.toString(),
    page_size: pageSize.toString(),
    ordering: generateSorts(sorting),
    ...filters,
  });
  return (dispatch: Dispatch) =>
    request(
      GET_CONTACT_ACTIVITIES,
      `${ACTIVITIES_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { filters, page, pageSize, contactId } }
    )(dispatch);
}

/** Action set for createActivity. */
export const CREATE_ACTIVITY = makeAsyncActionSet('CREATE_ACTIVITY');

/**
 * Creates an activity.
 * @param {Activity} activity - The new activity
 */
export function createActivity(activity: Activity) {
  return (dispatch: Dispatch) =>
    request<ActivityResponse>(
      CREATE_ACTIVITY,
      `${ACTIVITIES_ENDPOINT}/`,
      'POST',
      activity,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch).catch(throwSubmissionError);
}

/** Action set for saveActivity. */
export const SAVE_ACTIVITY = makeAsyncActionSet('SAVE_ACTIVITY');

/**
 * Saves an activity.
 * @param {Activity} activity - The edited activity
 */
export function saveActivity(id: string, activity: Activity) {
  return (dispatch: Dispatch) =>
    request(SAVE_ACTIVITY, `${ACTIVITIES_ENDPOINT}/${id}/`, 'PATCH', activity, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch).catch(throwSubmissionError);
}

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export class Loading extends React.PureComponent<{ size?: Size }> {
  public render() {
    const { size = '2x' } = this.props;
    return (
      <div className="loader">
        <FontAwesomeIcon icon={faCircleNotch} spin size={size} />
      </div>
    );
  }
}

export default Loading;

type Size =
  | '2x'
  | 'xs'
  | 'lg'
  | 'sm'
  | '1x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'
  | undefined;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { ActivityResponse } from '^/activities/types';
import { getUserName } from '^/admin/users/helpers';
import AttachmentList from '^/attachments/list';
import AppButton from '^/common/app-button';
import { formatDate, getContactName } from '^/common/helper-functions';
import ActivityComment from './activity-comment';

interface OwnProps {
  activity: ActivityResponse;
  hideTitle?: boolean;
  showRelatedContact?: boolean;
  linkToDetail?: boolean;
  disabled?: boolean;
  onEditActivity?(activity: ActivityResponse): void;
}

export type ActivityListItemProps = OwnProps;

class ActivityListItem extends React.Component<ActivityListItemProps> {
  public render() {
    const {
      activity,
      hideTitle,
      onEditActivity,
      showRelatedContact,
      linkToDetail,
      disabled,
    } = this.props;

    const overdue =
      activity.due_date && moment(activity.due_date).isBefore(moment());
    const createdByDisplay = activity.created_by_detail
      ? `by ${getUserName(activity.created_by_detail)}`
      : '';

    return (
      <div className="activity-list-item">
        {!hideTitle && (
          <div className="heading">
            <h4>
              {linkToDetail ? (
                <Link to={this.getLink()}>{activity.title}</Link>
              ) : (
                activity.title
              )}
            </h4>
            {onEditActivity && (
              <AppButton
                small
                onClick={onEditActivity.bind(this, activity)}
                disabled={disabled}
              >
                Edit
              </AppButton>
            )}
          </div>
        )}
        <div className="activity-comments">
          {activity.comments.map(comment => (
            <ActivityComment key={comment.id} comment={comment} showMeta />
          ))}
        </div>
        <AttachmentList attachments={activity.attachments} />

        <div className="flex-row">
          {!activity.popup && (
            <>
              {activity.completed_next_action ? (
                <div className="detail-item complete">COMPLETED</div>
              ) : (
                <div className={classnames(['detail-item', { overdue }])}>
                  Next action:{' '}
                  <span className="value">{formatDate(activity.due_date)}</span>
                </div>
              )}
              {activity.assigned_to_detail && (
                <div className="detail-item">
                  Assigned to:{' '}
                  <Link
                    className="value"
                    to={`/admin/users/${activity.assigned_to}`}
                  >
                    {getUserName(activity.assigned_to_detail)}
                  </Link>
                </div>
              )}
            </>
          )}
          {showRelatedContact && activity.associated_contact && (
            <div className="detail-item">
              Related contact:{' '}
              <Link
                className="value"
                to={`/contacts/${activity.associated_contact}`}
              >
                {getContactName(activity.associated_contact_detail)}
              </Link>
            </div>
          )}
        </div>
        <div className="sub-heading">
          <span className="created">
            Created {formatDate(activity.created)} {createdByDisplay}
          </span>
          {activity.popup && (
            <span title="This activity is a popup">
              <FontAwesomeIcon icon="bell" />
            </span>
          )}
        </div>
      </div>
    );
  }

  public getLink = () => {
    const { activity } = this.props;

    return activity.associated_contact
      ? `/contacts/${activity.associated_contact}/activities/${activity.id}`
      : `/activities/${activity.id}`;
  };
}

/** Disconnected version of the component that is used for testing. */
export { ActivityListItem as TestableActivityListItem };

export default ActivityListItem;

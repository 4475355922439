import { SimpleRecord } from '@dabapps/simple-records';

export interface PersonProfile {
  readonly id?: string;
  title?: string;
  first_name: string;
  last_name: string;
  dob?: string;
  mobile?: string;
  readonly contact?: string;
  communication_method?: PersonProfileCommunicationMethod;
  email?: string;
}

export type PersonProfileResponse = Readonly<{
  id: string;
  title: string;
  first_name: string;
  last_name: string;
  dob: string;
  mobile: string;
  contact: string;
  communication_method: PersonProfileCommunicationMethod;
  email: string;
}>;

export enum PersonProfileCommunicationMethod {
  SMS = 'SMS',
  Email = 'EMAIL',
  Post = 'POST',
}

export const PersonProfileRecord = SimpleRecord<PersonProfileResponse>({
  id: '',
  title: '',
  first_name: '',
  last_name: '',
  dob: '',
  mobile: '',
  contact: '',
  communication_method: PersonProfileCommunicationMethod.Post,
  email: '',
});

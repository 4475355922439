import { Container } from '@dabapps/roe';
import React from 'react';

import NominalCodesTable from '^/admin/bank/table';
import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import PageContent from '^/page-container/page-content';

class CodeBankDetailsPage extends React.PureComponent {
  public render() {
    return (
      <PageContent>
        <HeaderBar title="CODE Bank Details" transparent />
        <Container>
          <main>
            <PageSection>
              <PageSubSection heading="Nominal Codes">
                <NominalCodesTable />
              </PageSubSection>
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }
}

export { CodeBankDetailsPage as TestableCodeBankDetailsPage };

export default CodeBankDetailsPage;

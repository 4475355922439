import { anyPending } from '@dabapps/redux-requests';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { GET_NOMINAL_CODES, getNominalCodes } from '^/admin/bank/actions';
import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';

export type NominalCodesTableProps = ConnectedProps<typeof connector>;

class NominalCodesTable extends React.PureComponent<NominalCodesTableProps> {
  public componentDidMount() {
    if (!this.props.loading) {
      this.props.getNominalCodes();
    }
  }

  public render() {
    const { nominalCodes, loading } = this.props;
    return (
      <PaginatedTable
        paginatedData={nominalCodes}
        loading={loading}
        changePage={this.props.getNominalCodes}
      >
        <TableHead>
          <TableRow>
            <TableHeader>Code</TableHeader>
            <TableHeader>Name</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(nominalCodes).map(nominalCode => (
            <ItemRow key={nominalCode.id} item={nominalCode}>
              <TableCell>{nominalCode.code}</TableCell>
              <TableCell>{nominalCode.name}</TableCell>
            </ItemRow>
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }
}

export { NominalCodesTable as TestableNominalCodesTable };

export const mapState = (state: StoreState) => ({
  nominalCodes: state.nominalCodes ? state.nominalCodes : null,
  loading: anyPending(state.responses, [GET_NOMINAL_CODES]),
});

const connector = connect(mapState, { getNominalCodes });

export default connector(NominalCodesTable);

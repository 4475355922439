import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { isPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { UPLOAD_ATTACHMENT, uploadAttachment } from '^/attachments/actions';
import { getAttachment } from '^/attachments/actions';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

interface OwnProps {
  label?: string;
  disabled?: boolean;
  allowedFileTypes?: string;
  buttonText?: string;
  buttonClass?: string;
  showButtonOnly?: boolean;
  onAttachmentUploaded?: (attachmentURL: string) => void;
}

interface StateProps {
  session: string;
}

export type RenderSingleButtonUploadFieldProps = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class RenderSingleButtonUploadField extends React.PureComponent<
  RenderSingleButtonUploadFieldProps
> {
  public componentDidUpdate(prevProps: RenderSingleButtonUploadFieldProps) {
    const { attachmentsBySession } = this.props;

    if (attachmentsBySession !== prevProps.attachmentsBySession) {
      this.refreshAttachment();
    }
  }

  public render() {
    const {
      buttonText = 'Upload',
      loading,
      attachment,
      disabled,
      allowedFileTypes,
      buttonClass = 'primary',
    } = this.props;
    return (
      <div className="form-field render-file-upload">
        <div className="single-file-upload button-only">
          <input
            type="text"
            value={attachment ? attachment.file_name : ''}
            placeholder="Choose file"
            readOnly
            hidden
          />

          <label className="single-file-upload-label" htmlFor="file-upload">
            <label
              htmlFor="file-upload"
              className={classnames(
                'add-file',
                buttonClass,
                { loading: loading.uploading },
                { disabled }
              )}
            >
              {buttonText}
            </label>
          </label>
        </div>

        <input
          accept={allowedFileTypes}
          id="file-upload"
          type="file"
          onChange={this.handleChange}
          disabled={loading.uploading || disabled}
        />
      </div>
    );
  }

  public handleChange = async (event: {
    target: { files: FileList | null };
  }) => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    const file = event.target.files[0];

    try {
      const attachment = await this.props.uploadAttachment(
        file,
        this.props.session
      );
      if (attachment && this.props.onAttachmentUploaded) {
        this.props.onAttachmentUploaded(attachment.data.url);
      }
    } catch (e) {
      const errorDetail =
        e.errors && e.errors.file ? `${e} - ${e.errors.file}` : e;
      alert(
        'Failed to upload this file. Please try again later. ' + errorDetail
      );
    }
  };

  public refreshAttachment = () => {
    const { attachmentsBySession, session } = this.props;
    const attachments = attachmentsBySession[session] || {};
    const attachmentList = [...Object.keys(attachments)];
    const attachmentId = attachmentList[attachmentList.length - 1];
    this.props.input.onChange(attachmentId);
    this.props.getAttachment(attachmentId);
  };
}

// Disconnected version used for testing
export { RenderSingleButtonUploadField as TestableRenderSingleButtonUploadField };

export const getInitialProps = () => ({
  session: moment().toISOString(),
});

export const mapState = (state: StoreState, props: WrappedFieldProps) => ({
  attachmentsBySession: state.attachmentsBySession,
  loading: {
    uploading: isPending(state.responses, UPLOAD_ATTACHMENT),
  },
  attachment: getItemFromCache(props.input.value, state.attachmentsCache),
});

const connector = connect(mapState, { getAttachment, uploadAttachment });

export default withSetProps<
  StateProps,
  Exclude<RenderSingleButtonUploadFieldProps, StateProps>
>(getInitialProps)(connector(RenderSingleButtonUploadField));

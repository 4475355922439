import { AnyAction } from 'redux';

import { createCachedItem } from '^/__helpers__/pagination-test-helpers';
import { ByContact, DetailedCache, PaginatedArray } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';
import {
  CREATE_MEMBERSHIP_SUBSCRIPTION,
  GET_MEMBERSHIP_SUBSCRIPTION,
  GET_MEMBERSHIP_SUBSCRIPTIONS,
  SAVE_MEMBERSHIP_SUBSCRIPTION,
} from './actions';
import { MembershipSubscriptionResponse } from './types';

export function membershipSubscriptions(
  state: ByContact<PaginatedArray<MembershipSubscriptionResponse>> = {},
  action: AnyAction
): ByContact<PaginatedArray<MembershipSubscriptionResponse>> {
  switch (action.type) {
    case GET_MEMBERSHIP_SUBSCRIPTIONS.SUCCESS:
      const key = action.meta.contact ? action.meta.contact : 'all';

      return {
        ...state,
        [key]: paginatedReducer(state[key], action),
      };
    default:
      return state;
  }
}

export function membershipSubscriptionCache(
  state: DetailedCache<MembershipSubscriptionResponse> = {},
  action: AnyAction
): DetailedCache<MembershipSubscriptionResponse> {
  switch (action.type) {
    case GET_MEMBERSHIP_SUBSCRIPTION.SUCCESS:
    case CREATE_MEMBERSHIP_SUBSCRIPTION.SUCCESS:
    case SAVE_MEMBERSHIP_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: createCachedItem(action.payload.data),
      };
    default:
      return state;
  }
}

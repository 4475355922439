import React from 'react';

import ActivityListItem from '^/activities/list-item';
import { ActivityResponse } from '^/activities/types';
import { PaginatedArray } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';

interface OwnProps {
  activities: PaginatedArray<ActivityResponse> | null;
  showRelatedContact?: boolean;
  linkToDetail?: boolean;
  disabled?: boolean;
  onEditActivity?(activity: ActivityResponse): void;
}

export type ActivityListProps = OwnProps;

class ActivityList extends React.Component<ActivityListProps> {
  public render() {
    const {
      activities,
      onEditActivity,
      showRelatedContact,
      linkToDetail,
      disabled,
    } = this.props;

    return (
      <div className="activity-list">
        {activities && activities.count === 0 && (
          <div className="activity-sidebar empty-state">No Activities</div>
        )}
        {getCurrentPage(activities).map(activity => (
          <ActivityListItem
            key={activity.id}
            activity={activity}
            onEditActivity={onEditActivity}
            showRelatedContact={showRelatedContact}
            linkToDetail={linkToDetail}
            disabled={disabled}
          />
        ))}
      </div>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { ActivityList as TestableActivityList };

export default ActivityList;

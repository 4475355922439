import Container from '@dabapps/roe/dist/js/components/grid/container';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import MembershipsTable from '^/admin/memberships/table';
import AppButton from '^/common/app-button';
import HeaderBar from '^/common/header-bar';
import PageContent from '^/page-container/page-content';

export type MembershipPageProps = RouteComponentProps;

export class MembershipsListPage extends React.PureComponent<
  MembershipPageProps
> {
  public render() {
    return (
      <PageContent>
        <HeaderBar
          title="Memberships"
          primaryActions={[
            <Link key="new" to={`${this.props.match.url}/create`}>
              <AppButton leftIcon="plus" primary>
                New Membership
              </AppButton>
            </Link>,
          ]}
          transparent
        />

        <Container>
          <MembershipsTable />
        </Container>
      </PageContent>
    );
  }
}

export { MembershipsListPage as TestableMembershipsListPage };

export default MembershipsListPage;

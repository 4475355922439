import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import {
  PUBLIC_GET_LINKED_PRACTICES,
  publicGetLinkedPractices,
  publicGetPracticeDetails,
  publicGetPresetClinicianDetails,
  publicGetPresetPlanDetails,
  publicPatientSignupRedirect,
  publicSelectActivePractice,
  publicSetPresetSignUp,
} from './actions';
import PracticeSelector from './common/practice-selector';

export type PublicPatientLandingProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ publicId: string }>;

export interface PublicPatientLandingState {
  selectedPublicId: string;
  presetSignUp: boolean;
  presetPlanId?: string;
  presetClinicianId?: string;
}

class PublicPatientLanding extends React.PureComponent<
  PublicPatientLandingProps,
  PublicPatientLandingState
> {
  constructor(
    props: PublicPatientLandingProps,
    state: PublicPatientLandingState
  ) {
    super(props);
    this.state = state || { selectedPublicId: '', presetSignUp: false };
  }

  public componentDidMount() {
    const publicId = this.props.match.params.publicId;
    this.props.publicGetLinkedPractices(publicId);
    this.props.publicGetPracticeDetails(publicId);

    const queryParams = new URLSearchParams(this.props.location.search);
    const presetPlanId = queryParams.get('plan');
    const presetClinicianId = queryParams.get('clinician');

    if (presetPlanId && presetClinicianId) {
      this.props.publicGetPresetPlanDetails(publicId, presetPlanId);
      this.props.publicGetPresetClinicianDetails(publicId, presetClinicianId);
      this.props.publicSetPresetSignUp(true);

      this.setState(prevState => ({
        ...prevState,
        presetSignUp: true,
      }));
    }
  }

  public componentDidUpdate(prevProps: PublicPatientLandingProps) {
    if (this.state.presetSignUp) {
      const practice = this.props.practiceDetails;
      if (practice) {
        this.setActivePractice(practice.public_id);
      }
    } else {
      if (
        this.props?.practices &&
        this.props.practices !== prevProps.practices
      ) {
        const publicId = this.props.practices?.[0]?.public_id;
        if (publicId) {
          this.setActivePractice(publicId);
        }
      }
    }
  }

  public setActivePractice = (selectedPublicId: string) => {
    this.setState(prevState => ({
      ...prevState,
      selectedPublicId,
    }));
  };

  public patientSignup = () => {
    const publicId = this.state.selectedPublicId;
    const practice = this.props.practiceDetails;

    if (publicId) {
      this.props.publicSelectActivePractice(publicId);
      if (publicId !== practice?.public_id) {
        this.props.publicGetPracticeDetails(publicId);
      }
      this.props.publicPatientSignupRedirect(this.state.selectedPublicId);
    }
  };

  public render() {
    const { practiceDetails } = this.props;
    return this.props.loading ? (
      <Loading />
    ) : (
      <PageContent>
        <div className="card">
          <div className="logo-container">
            <img className="practice-logo" src={practiceDetails?.logo_url} />
          </div>
          <div>
            <p className="custom-landing-page-text">
              {practiceDetails?.custom_text_landing}
            </p>
          </div>
          <div className="practice-selector">
            {this.state.presetSignUp ? (
              <div className="text-large">{practiceDetails?.name}</div>
            ) : (
              <>
                <div className="text">Select practice:</div>
                <PracticeSelector
                  practices={this.props.practices}
                  selectedPublicId={this.state.selectedPublicId}
                  setActivePractice={this.setActivePractice}
                />
              </>
            )}
          </div>
          <AppButton
            className="add-patient-button"
            onClick={this.patientSignup}
          >
            <div className="add-patient-button-div">Sign Up</div>
          </AppButton>
        </div>
      </PageContent>
    );
  }
}

export { PublicPatientLanding as TestablePublicPatientLanding };

export const mapState = (state: StoreState) => ({
  presetClinician: state?.publicPatient?.presetClinician,
  presetPlan: state?.publicPatient?.presetPlan,
  practiceDetails: state?.publicPatient?.practiceDetails,
  practices: state?.publicPatient?.linkedPractices,
  loading: isPending(state.responses, PUBLIC_GET_LINKED_PRACTICES),
});
export const mapDispatch = {
  publicGetLinkedPractices,
  publicGetPresetPlanDetails,
  publicGetPresetClinicianDetails,
  publicGetPracticeDetails,
  publicPatientSignupRedirect,
  publicSelectActivePractice,
  publicSetPresetSignUp,
};
const connector = connect(mapState, mapDispatch);

export default connector(PublicPatientLanding);

import { Container } from '@dabapps/roe';
import React from 'react';
import { Dispatch } from 'redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import { HeaderBar } from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { requestReport } from '^/routines/actions';
import RequestReportForm from '^/routines/report-form';
import ReportList from '^/routines/report-list';
import { Report, ReportCategory, ReportType } from '^/routines/types';

class GenericRoutinesPage extends React.PureComponent {
  public render() {
    return (
      <PageContent>
        <BreadcrumbBar />
        <HeaderBar title="Generic Routines" transparent />
        <Container>
          <PageSection thin>
            <PageSubSection heading="Generate new AUDDIS requests" vertical>
              <p>
                Create AUDDIS mandates for all newly created or changed payment
                details.
              </p>
              <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                <RequestReportForm
                  form={'generateAUDDIS'}
                  initialValues={{
                    report_type: ReportType.NewAuddisRequests,
                    report_category: ReportCategory.Generic,
                  }}
                  onSubmit={this.requestReport}
                />
              </Allow>
              <ReportList
                type={ReportType.NewAuddisRequests}
                category={ReportCategory.Generic}
              />
            </PageSubSection>
            <PageSubSection heading="Fees update report" vertical>
              <p>
                An informational report detailing which fees were updated
                overnight.
              </p>
              <ReportList
                noApplyButtons
                type={ReportType.FeesUpdate}
                category={ReportCategory.Generic}
              />
            </PageSubSection>
            <PageSubSection heading="Automatic lapse report" vertical>
              <p>
                An informational report detailing which subscription contacts
                have been lapsed overnight.
              </p>
              <ReportList
                noApplyButtons
                type={ReportType.SubContactLapseReport}
                category={ReportCategory.Generic}
              />
            </PageSubSection>
          </PageSection>
        </Container>
      </PageContent>
    );
  }

  public requestReport = (report: Report, dispatch: Dispatch) => {
    return requestReport(report)(dispatch);
  };
}

export { GenericRoutinesPage as TestableRoutinesPage };

export default GenericRoutinesPage;

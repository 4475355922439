import moment from 'moment';

import { Collection } from '^/collections/types';
import { DEFAULT_DATE_FORMAT, isObjectEmpty } from '^/common/helper-functions';
import {
  Ledger,
  LedgerAllocation,
  LedgerEntryType,
  LedgerItem,
  LedgerItemType,
  LedgerModalType,
  LedgerResponse,
} from '^/contacts/ledger/types';
import {
  calculateGoodsFromGross,
  calculateGrossFromGoods,
  calculateVatFromGoods,
  calculateVatFromGross,
} from '^/fees/utils';
import { getSubscriptionName } from '^/plans/helpers';
import { isSubscriptionActive } from '^/plans/subscriptions/helpers';
import { PlanSubscription } from '^/plans/subscriptions/types';
import { PaginatedArray } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

export const selectEntry = (
  togglableEntry: LedgerResponse,
  selectedLedgerEntries: ReadonlyArray<LedgerResponse>,
  setProps: (propsArg: {
    [prop: string]: ReadonlyArray<LedgerResponse>;
  }) => void,
  propName: string
) => {
  if (selectedLedgerEntries.some(x => x.id === togglableEntry.id)) {
    setProps({
      [propName]: selectedLedgerEntries.filter(
        entry => entry.id !== togglableEntry.id
      ),
    });
  } else {
    setProps({
      [propName]: [...selectedLedgerEntries, togglableEntry],
    });
  }
};

export function round(value: string | number, places: number = 2): number {
  if (typeof value === 'string') {
    value = Number(value);
  }

  return Number(value.toFixed(places));
}

export function calculateLedgerUnbalance(entry: Ledger): number {
  if (entry.detail_detail.outstanding_amount) {
    return entry.detail_detail.outstanding_amount;
  }

  if (typeof entry.detail_detail.allocated === 'number') {
    return entry.amount + entry.vat_amount - entry.detail_detail.allocated;
  }

  return 0;
}

export function sumAllocationAmounts(
  allocations: ReadonlyArray<LedgerAllocation>
) {
  return allocations.reduce(
    (amount: number, currentValue: LedgerAllocation) =>
      amount + Number(currentValue.amount),
    0
  );
}

export function mapEntriesToAllocations(
  ledgerTo: ReadonlyArray<LedgerResponse>,
  ledgerFrom?: LedgerResponse
): ReadonlyArray<LedgerAllocation> {
  let available = ledgerFrom
    ? round(ledgerFrom.amount + ledgerFrom.vat_amount)
    : null;

  return ledgerTo.map(entry => {
    const outstanding = calculateLedgerUnbalance(entry);

    const amount =
      available !== null ? Math.min(outstanding, available) : outstanding;

    if (available) {
      available = round(Math.max(available - amount, 0));
    }

    return {
      ledger_to: entry.detail_detail.id,
      ledger_from: ledgerFrom?.detail,
      amount: String(round(amount)),
    };
  });
}

export function mergeLedgerEntriesToItems(
  entries: ReadonlyArray<LedgerResponse>,
  contact?: string
): ReadonlyArray<LedgerItem> {
  return entries.reduce(
    (items: ReadonlyArray<LedgerItem>, currentValue: LedgerResponse) => [
      ...items,
      ...currentValue.detail_detail.ledger_items.map(item => ({
        ...item,
        contact: contact || item.contact,
        id: undefined,
        created: undefined,
        modified: undefined,
      })),
    ],
    []
  );
}

export const checkEntriesMatchTypes = (
  selectedEntries: ReadonlyArray<LedgerResponse>,
  types: ReadonlyArray<string>
) => {
  let valid = true;
  selectedEntries.forEach(entry => {
    if (!types.includes(entry.type)) {
      valid = false;
    }
  });
  return valid;
};

export const entriesAllHaveOutstandingAmounts = (
  ledgerEntries: ReadonlyArray<LedgerResponse>
): boolean => {
  return !ledgerEntries.find(
    entry =>
      !entry.detail_detail.outstanding_amount ||
      entry.detail_detail.outstanding_amount <= 0
  );
};

export const entriesAllHaveUnallocatedAmounts = (
  ledgerEntries: ReadonlyArray<LedgerResponse>
): boolean => {
  return !ledgerEntries.find(
    entry => entry.amount - (entry.detail_detail.allocated || 0) <= 0
  );
};

export const entriesAreValidForType = (
  ledgerEntries: ReadonlyArray<LedgerResponse>,
  type: LedgerModalType
) => {
  switch (type) {
    case 'Allocation':
      return (
        checkEntriesMatchTypes(ledgerEntries, [
          LedgerEntryType.RecordReceipt,
          LedgerEntryType.CreditNote,
          LedgerEntryType.RecordRefund,
        ]) &&
        entriesAllHaveUnallocatedAmounts(ledgerEntries) &&
        ledgerEntries.length === 1
      );
    case LedgerEntryType.RecordReceipt:
    case LedgerEntryType.CreditNote:
      return (
        checkEntriesMatchTypes(ledgerEntries, [LedgerEntryType.Invoice]) &&
        entriesAllHaveOutstandingAmounts(ledgerEntries)
      );
    case LedgerEntryType.RecordReject:
    case LedgerEntryType.RecordRefund:
      return (
        checkEntriesMatchTypes(ledgerEntries, [
          LedgerEntryType.RecordReceipt,
          LedgerEntryType.CreditNote,
        ]) && ledgerEntries.length === 1
      );
    case LedgerEntryType.Invoice:
    case 'Product Invoice':
    default:
      return true;
  }
};

export const shouldTypeDisplayOutstanding = (type: LedgerEntryType) => {
  return ![
    LedgerEntryType.RecordReceipt,
    LedgerEntryType.RecordReject,
    LedgerEntryType.RecordRefund,
  ].includes(type);
};

export const shouldTypeDisplayUnallocated = (type: LedgerEntryType) => {
  return ![
    LedgerEntryType.Invoice,
    LedgerEntryType.RecordReject,
    LedgerEntryType.CreditNote,
  ].includes(type);
};

export const itemShouldBeInTotal = (item: LedgerItem) =>
  item.item !== LedgerItemType.AdminFee && item.item !== LedgerItemType.INSPRAC;

export const amountReducer = (total: number, item: LedgerItem) =>
  itemShouldBeInTotal(item) ? total + item.amount : total;

export const deliveryReducer = (total: number, item: LedgerItem) =>
  item.delivery ? total + item.delivery : total;

export const productAmountReducer = (total: number, item: LedgerItem) =>
  item.quantity ? total + item.amount * item.quantity : total + item.amount;

export const productVatReducer = (total: number, item: LedgerItem) =>
  total + calculateProductVat(item);

export const vatReducer = (total: number, item: LedgerItem) =>
  itemShouldBeInTotal(item) ? total + calculateVat(item) : total;

export const calculateProductVat = (ledgerItem: LedgerItem): number => {
  const quantity = ledgerItem.quantity || 1;

  return calculateVatFromGoods(ledgerItem.amount, ledgerItem.vat) * quantity;
};

export const calculateGoods = (ledgerItem: LedgerItem): number => {
  return calculateGoodsFromGross(ledgerItem.gross_amount || 0, ledgerItem.vat);
};

export const calculateVat = (ledgerItem: LedgerItem): number => {
  return ledgerItem.vat_inclusive
    ? calculateVatFromGross(ledgerItem.gross_amount || 0, ledgerItem.vat)
    : calculateVatFromGoods(ledgerItem.amount, ledgerItem.vat);
};

export const calculateGross = (ledgerItem: LedgerItem): number => {
  return calculateGrossFromGoods(ledgerItem.amount, ledgerItem.vat);
};

export const generateSubscriptionDropdownOptions = (
  subscriptions: PaginatedArray<Readonly<Required<PlanSubscription>>>,
  contactId?: string
) => {
  const retrievedSubscriptions = getAllRetrievedPages(
    subscriptions
  ).filter(sub => isSubscriptionActive(sub, contactId));

  if (retrievedSubscriptions.length < 1) {
    return [{ label: 'No subscriptions', value: '' }];
  }

  const subOptions = retrievedSubscriptions
    .filter(
      (sub1, sub1Index) =>
        retrievedSubscriptions.findIndex(sub2 => sub2.id === sub1.id) ===
        sub1Index
    )
    .map(subscription => ({
      label: getSubscriptionName(subscription),
      value: subscription.id,
    }));

  return [{ label: 'Please choose...', value: '' }, ...subOptions];
};

export const generateCollectionDropdownOptions = (
  collections: PaginatedArray<Readonly<Required<Collection>>>
) => {
  if (
    isObjectEmpty(collections) ||
    isObjectEmpty(collections.pages) ||
    collections.pages[1].length === 0
  ) {
    return [{ label: 'No collections', value: '' }];
  }

  const colOptions = getAllRetrievedPages(collections)
    .filter(collection => !collection.invoiced)
    .map(collection => ({
      label: getCollectionName(collection),
      value: collection.id,
    }));

  return [{ label: 'Please choose...', value: '' }, ...colOptions];
};

export function getCollectionName(
  collection?: Collection | null,
  defaultValue: string = '-'
) {
  if (!collection) {
    return defaultValue;
  }

  const subName = collection.subscription_detail?.plan_detail?.code
    ? collection.subscription_detail.plan_detail.code
    : defaultValue;

  return `${subName} (${moment(collection.start_date).format(
    DEFAULT_DATE_FORMAT
  )} - ${moment(collection.end_date).format(DEFAULT_DATE_FORMAT)})`;
}

export const totalAmount = (entry: LedgerResponse | Ledger) => {
  const amount = entry.amount + entry.vat_amount;

  if (
    [LedgerEntryType.RecordReceipt, LedgerEntryType.CreditNote].includes(
      entry.type
    )
  ) {
    const flipped = -amount;
    return `£${flipped.toFixed(2)}`;
  } else {
    return `£${amount.toFixed(2)}`;
  }
};

import moment from 'moment';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { WrappedFieldProps } from 'redux-form';

import { DEFAULT_DATE_TIME_PICKER_FORMAT } from '^/common/helper-functions';
import RenderFormField, { RenderFormFieldProps } from './render-form-field';

interface OwnProps {
  placeholder?: string;
  clearable?: boolean;
}

export type RenderDateTimeFieldProps = OwnProps &
  RenderFormFieldProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

export class RenderDateTimeField extends React.PureComponent<
  RenderDateTimeFieldProps
> {
  public render() {
    const {
      input,
      placeholder = DEFAULT_DATE_TIME_PICKER_FORMAT,
      disabled,
      clearable = true,
    } = this.props;

    return (
      <RenderFormField {...this.props}>
        <ReactDatePicker
          dateFormat={DEFAULT_DATE_TIME_PICKER_FORMAT}
          selected={
            input.value ? moment(input.value, moment.ISO_8601).toDate() : null
          }
          onChange={this.handleChange}
          name={input.name}
          autoComplete="off"
          placeholderText={placeholder}
          showMonthDropdown
          showTimeSelect
          showYearDropdown
          isClearable={!disabled && clearable}
          disabled={disabled}
        />
      </RenderFormField>
    );
  }

  public handleChange = (date: Date | null) =>
    this.props.input.onChange(date && moment(date).toISOString());
}

export { RenderDateTimeField as TestableRenderDateTimeField };

export default RenderDateTimeField;

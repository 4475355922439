import { isPending } from '@dabapps/redux-requests';
import { TableCell, TableHeader, TableRow } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import ConfirmButton from '^/common/confirm-button';
import {
  getContactCommsMethod,
  getContactEmail,
  getContactName,
  getContactPhone,
} from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import { ContactResponse, getContactCategoryName } from '^/contacts/types';
import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import {
  GET_ISOLATED_CONTACT_RELATIONSHIPS,
  getIsolatedContactRelationships,
} from './actions';
import { getIsolatedRelationshipsFromStore } from './helper-functions';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  emptyStateMessage?: string;
  relationships: ReadonlyArray<RelationshipResponse>;
  detailed?: boolean;
  disabled?: boolean;
  paginated?: boolean;
  showBothSides?: boolean;
  linkContactName?: boolean;
  types?: { 0: RelationshipType } & ReadonlyArray<RelationshipType>;
  onRemoveRelationship?(relationship: string): void;
}

export type RelationshipsListProps = OwnProps &
  ConnectedProps<typeof connector>;

class RelationshipsList extends React.PureComponent<RelationshipsListProps> {
  public componentDidMount(): void {
    const { contactId, loading, paginated, types } = this.props;

    if (paginated && types && !loading.relationships) {
      this.props.getIsolatedContactRelationships(
        contactId,
        types,
        undefined,
        1,
        10
      );
    }
  }

  public render() {
    const {
      contactId,
      emptyStateMessage = 'No relationships',
      relationships,
      detailed = false,
      disabled,
      paginated,
      loading,
      showBothSides,
      paginatedRelationships,
      linkContactName = true,
    } = this.props;

    if (paginated) {
      if (!paginatedRelationships?.count) {
        return <div className="empty-state">{emptyStateMessage}</div>;
      }

      return (
        <PaginatedTable
          paginatedData={paginatedRelationships}
          loading={loading.relationships}
          changePage={this.changeRelationshipPage}
          showPaginationBar
        >
          <tbody>
            {getCurrentPage(paginatedRelationships).map(relationship => {
              if (
                !showBothSides &&
                relationship.related_contact !== contactId
              ) {
                return null;
              }

              const contact: ContactResponse = this.getRelevantContact(
                relationship,
                contactId
              );

              const contactName = getContactName(contact, true);

              return (
                <TableRow key={relationship.id}>
                  <TableHeader>
                    {linkContactName ? (
                      <Link to={`/contacts/${contact.id}`}>{contactName}</Link>
                    ) : (
                      contactName
                    )}
                  </TableHeader>
                  <TableCell>
                    {relationship.reverse_relationship ||
                      getContactCategoryName(contact)}
                  </TableCell>
                  {this.props.onRemoveRelationship && (
                    <TableCell>
                      <ConfirmButton
                        onConfirm={this.props.onRemoveRelationship.bind(
                          this,
                          relationship.id
                        )}
                        content="Remove"
                        disabled={disabled}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </tbody>
        </PaginatedTable>
      );
    }

    if (relationships.length < 1) {
      return <div className="empty-state">{emptyStateMessage}</div>;
    }

    if (detailed) {
      return (
        <div className="detailed-list">
          {relationships.map(relationship => {
            const contact: ContactResponse = this.getRelevantContact(
              relationship,
              contactId
            );

            return (
              <div key={relationship.id} className="detailed-list-item">
                <span>
                  <Link to={`/contacts/${contact.id}`}>
                    {getContactName(contact)}
                  </Link>
                  {relationship.reverse_relationship || (
                    <span className="subtitle">
                      {getContactCategoryName(contact)}
                    </span>
                  )}
                </span>
                {this.renderContactDetails(contact)}
                {this.props.onRemoveRelationship && (
                  <ConfirmButton
                    onConfirm={this.props.onRemoveRelationship.bind(
                      this,
                      relationship.id
                    )}
                    content="Remove"
                    disabled={disabled}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <table>
        <tbody>
          {relationships.map(relationship => {
            const contact: ContactResponse = this.getRelevantContact(
              relationship,
              contactId
            );

            return (
              <tr key={relationship.id}>
                <th>
                  <Link to={`/contacts/${contact.id}`}>
                    {getContactName(contact, true)}
                  </Link>
                </th>
                <td>
                  {relationship.reverse_relationship ||
                    getContactCategoryName(contact)}
                </td>
                {this.props.onRemoveRelationship && (
                  <td>
                    <ConfirmButton
                      onConfirm={this.props.onRemoveRelationship.bind(
                        this,
                        relationship.id
                      )}
                      content="Remove"
                      disabled={disabled}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  public changeRelationshipPage = (page: number, pageSize: number) =>
    this.props.getIsolatedContactRelationships(
      this.props.contactId,
      this.props.types || [],
      undefined,
      page,
      pageSize
    );

  public renderContactDetails = (contact: ContactResponse) => {
    const numbers = [getContactPhone(contact), getContactEmail(contact)].filter(
      Boolean
    );

    if (numbers.length < 1) {
      return null;
    }

    return (
      <>
        <div className="numbers">{numbers.join(' • ')}</div>
        <div className="preferences">
          Contact preferences: {getContactCommsMethod(contact)}
        </div>
      </>
    );
  };

  private getRelevantContact(
    relationship: RelationshipResponse,
    subject?: string
  ): ContactResponse {
    return !subject || relationship.related_contact === subject
      ? relationship.contact_detail
      : relationship.related_contact_detail;
  }
}

// Disconnected version used for testing
export { RelationshipsList as TestableRelationshipsList };

export const mapState = (state: StoreState, props: OwnProps) => {
  const type = props.types?.join(',') || '';

  return {
    paginatedRelationships: getIsolatedRelationshipsFromStore(
      state,
      props.contactId,
      type,
      null
    ),
    loading: {
      relationships: isPending(
        state.responses,
        GET_ISOLATED_CONTACT_RELATIONSHIPS,
        type
      ),
    },
  };
};

const connector = connect(mapState, {
  getIsolatedContactRelationships,
});

export default connector(RelationshipsList);

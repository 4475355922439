import { AnyAction } from 'redux';

import { UserResponse } from '^/admin/users/types';
import { GET_LOGGED_IN_USER, LOGIN, LOGOUT } from '^/login/actions';

export function loggedInUser(
  state: UserResponse | null = null,
  action: AnyAction
): UserResponse | null {
  switch (action.type) {
    case GET_LOGGED_IN_USER.SUCCESS:
      return action.payload.data;
    // TODO: Do we need to revisit the LOGIN.SUCCESS action?
    case LOGIN.SUCCESS:
    case LOGOUT.SUCCESS:
      return null;
    default:
      return state;
  }
}

import { TableCell, TableRow } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';

import { Product } from '^/admin/products/types';
import AppButton from '^/common/app-button';
import { parseNumberField } from '^/common/helper-functions';
import { required, requiredZeroPermitted } from '^/common/validation';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderVatCodePicker from '^/form-helpers/render-vat-code-picker';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';
import { calculateVat, itemShouldBeInTotal } from '../../helpers';
import { LedgerItem } from '../../types';

// Props that come from the parent component.
interface OwnProps {
  item: string;
  index: number;
  removeLedgerItem: (index: number) => void;
  contactIds?: ReadonlyArray<string>;
  ledgerItem: LedgerItem;
}

export type ProductLedgerItemFormRowProps = OwnProps &
  ConnectedProps<typeof connector>;

class ProductLedgerItemFormRow extends React.PureComponent<
  ProductLedgerItemFormRowProps
> {
  public componentDidUpdate(prevProps: ProductLedgerItemFormRowProps) {
    const { products, row_ledger_item, dispatch } = this.props;
    const allProducts = getAllRetrievedPages(products);

    if (!row_ledger_item) {
      return;
    }

    if (
      (row_ledger_item.item_code !== prevProps.row_ledger_item?.item_code ||
        row_ledger_item.member !== prevProps.row_ledger_item.member) &&
      allProducts &&
      dispatch
    ) {
      const selectedProduct = allProducts.find(
        product => row_ledger_item.item_code === product.product_code
      );

      dispatch(
        change(
          'generateProductInvoiceForm',
          `${this.props.item}.amount`,
          row_ledger_item.member
            ? selectedProduct?.member_price
            : selectedProduct?.non_member_price || 0
        )
      );

      dispatch(
        change(
          'generateProductInvoiceForm',
          `${this.props.item}.delivery`,
          selectedProduct?.delivery || 0
        )
      );

      dispatch(
        change(
          'generateProductInvoiceForm',
          `${this.props.item}.vat`,
          selectedProduct?.vat || 0
        )
      );

      dispatch(
        change(
          'generateProductInvoiceForm',
          `${this.props.item}.nominal_code`,
          selectedProduct?.nominal_code || ''
        )
      );
    }
  }

  public render() {
    const { item, ledgerItem } = this.props;

    return (
      <TableRow className={classNames('ledger-item-form-row')}>
        <TableCell>
          <Field
            name={`${item}.item_code`}
            component={RenderDropdown}
            options={[
              { label: 'Please choose..', value: '' },
              ...this.generateProductOptions(),
            ]}
            validate={required}
          />
        </TableCell>
        <TableCell>
          <Field name={`${item}.member`} component={RenderCheckBox} />
        </TableCell>
        <TableCell className="align-right">
          <Field
            name={`${item}.amount`}
            component={RenderCurrencyField}
            validate={[required]}
            parse={parseNumberField}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.quantity`}
            type="number"
            component={RenderInputField}
            shorter
            step={1}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.delivery`}
            component={RenderCurrencyField}
            parse={parseNumberField}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.vat`}
            type="text"
            component={RenderVatCodePicker}
            validate={[requiredZeroPermitted]}
            defaultNone
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.nominal_code`}
            type="text"
            component={RenderInputField}
            shortest
          />
        </TableCell>
        <TableCell
          className={classNames('align-right', {
            disabled: !itemShouldBeInTotal(ledgerItem),
          })}
        >
          {formatFeeAmount(calculateVat(ledgerItem))}
        </TableCell>
        <TableCell className="align-right">
          <AppButton
            small
            type="button"
            onClick={this.removeLedgerItem}
            transparent
          >
            <FontAwesomeIcon icon="trash" />
          </AppButton>
        </TableCell>
      </TableRow>
    );
  }

  public removeLedgerItem = () => {
    this.props.removeLedgerItem(this.props.index);
  };

  public generateProductOptions = () =>
    this.props.products
      ? getAllRetrievedPages(this.props.products)
          .filter(product => product.status === 'ACTIVE')
          .map((product: Product) => ({
            label: product.description,
            value: product.product_code,
          }))
      : [];
}

// Disconnected version used for testing
export { ProductLedgerItemFormRow as TestableProductLedgerItemFormRow };

const selector = formValueSelector('generateProductInvoiceForm');

export const mapState = (state: StoreState, props: OwnProps) => ({
  products: state.products,
  row_ledger_item: selector(state, `${props.item}`),
});

const connector = connect(mapState);

export default connector(ProductLedgerItemFormRow);

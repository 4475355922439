import { anyPending } from '@dabapps/redux-requests';
import { isPending } from '@dabapps/redux-requests/dist/js';
import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import { GET_TEMPLATE } from '^/admin/templates/actions';
import { ErrorPage } from '^/common/error-page';
import HeaderBar from '^/common/header-bar';
import { getContactName } from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import Sidebar from '^/common/sidebar';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import {
  deletePlan,
  GET_PLAN,
  getPlan,
  SAVE_PLAN,
  savePlan,
} from '^/plans/actions';
import ClinicianPresetUrlsTable from '^/plans/edit/clinician-preset-urls-table';
import FamilyDiscountsTable from '^/plans/edit/family-discounts-table';
import FeesByAgeTable from '^/plans/edit/fees-by-age-table';
import PlanDetailsForm from '^/plans/edit/plan-details-form';
import { getPlanName } from '^/plans/helpers';
import { getSubscriptionsByPlan } from '^/plans/subscriptions/actions';
import { Plan, PlanStatus } from '^/plans/types';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';
import ArchivePlanCard from './archive-plan-card';

type RouteProps = RouteComponentProps<{ id: string }>;

const selector = formValueSelector('planDetailsForm');

export const mapState = (state: StoreState, props: RouteProps) => {
  const plan = getItemFromCache(props.match.params.id, state.planCache);

  return {
    plan,
    contact: plan
      ? getItemFromCache(plan.practice, state.contactsCache)
      : undefined,
    archiveable: state.archiveablePlans[props.match.params.id],
    variable: selector(state, 'variable_pricing'),
    loading: {
      all: anyPending(state.responses, [
        GET_PLAN,
        GET_CONTACT,
        GET_TEMPLATE,
        SAVE_PLAN,
      ]),
      plan: isPending(state.responses, GET_PLAN),
    },
  };
};

const connector = connect(mapState, {
  getPlan,
  getContact,
  savePlan,
  deletePlan,
  getSubscriptionsByPlan,
});

/** All plan edit page props combined. */
export type PlanEditPageProps = ConnectedProps<typeof connector> & RouteProps;

/** The plan edit page. On page load it gets the relevant Plan from the database,
 * then gets associated Contact and Template using the data from the Plan. The page
 * itself is a series of cards contained information relevant to the plan.
 */
class PlansEditPage extends React.PureComponent<PlanEditPageProps> {
  public componentDidMount() {
    const { plan, match } = this.props;

    if (cachedItemHasExpired(plan)) {
      this.props.getPlan(match.params.id);
    }
    if (!this.props.archiveable) {
      this.props.getSubscriptionsByPlan(match.params.id);
    }
  }

  public componentDidUpdate(prevProps: PlanEditPageProps) {
    if (this.props.plan !== prevProps.plan && this.props.plan?.practice) {
      this.props.getContact(this.props.plan.practice);
    }
  }

  public render() {
    const { plan, contact, variable, loading } = this.props;

    if (!contact || !plan) {
      if (loading.all) {
        return (
          <PageContent>
            <BreadcrumbBar
              items={[
                ['Practices', '/practices'],
                { label: getContactName(contact), loading: true },
                { label: getPlanName(plan, true), loading: true },
              ]}
            />
            <HeaderBar title="Plan" transparent loading />
            <Loading />
          </PageContent>
        );
      }

      return (
        <ErrorPage heading={`${!contact ? 'Contact' : 'Plan'} not found`} />
      );
    }

    return (
      <PageContent>
        <BreadcrumbBar
          items={[
            ['Practices', '/practices'],
            [getContactName(contact), `/practices/${plan.practice}`],
            { label: getPlanName(plan, true), loading: loading.plan },
          ]}
        />
        <HeaderBar
          transparent
          title={getPlanName(plan)}
          subtitle={`${plan.description} for ${getContactName(contact)}${
            plan.status === 'ARCHIVED' ? ' (Archived)' : ''
          }`}
          loading={loading.plan}
        />
        <Container>
          <main>
            <div className="flex-row">
              <div className="flex-column flex-main">
                <PageSection>
                  <PageSubSection
                    heading={`Plan Details`}
                    loading={loading.plan}
                    vertical
                  >
                    {!loading.plan ? (
                      <PlanDetailsForm
                        onSubmit={this.onSave}
                        actions={[SAVE_PLAN]}
                        initialValues={plan}
                        practice={contact.id}
                      />
                    ) : (
                      <Loading />
                    )}
                  </PageSubSection>
                  {!plan.template_overrides_plan && (
                    <PageSubSection heading="Family Discounts" vertical>
                      <FamilyDiscountsTable planId={plan.id} />
                    </PageSubSection>
                  )}
                  {!variable && !plan.template_overrides_plan && (
                    <PageSubSection heading="Fees By Age" vertical>
                      <FeesByAgeTable planId={plan.id} />
                    </PageSubSection>
                  )}
                  {contact.company && plan && (
                    <PageSubSection
                      heading="Clinician Direct Sign-up Links"
                      vertical
                    >
                      <ClinicianPresetUrlsTable
                        practiceId={contact.id}
                        practicePublicId={contact.company.public_id}
                        planId={plan.id}
                      />
                    </PageSubSection>
                  )}
                </PageSection>
              </div>
              <Sidebar>
                <ArchivePlanCard
                  plan={plan}
                  archiveable={this.props.archiveable}
                  onClickArchive={this.onClickArchive}
                  onClickUnarchive={this.onClickUnarchive}
                  onClickDelete={this.onClickDelete}
                />
              </Sidebar>
            </div>
          </main>
        </Container>
      </PageContent>
    );
  }

  /**
   * Fires off the action that will save any changes made to this plan.
   * @param {Plan} plan - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public onSave = (plan: Plan, dispatch: Dispatch) => {
    if (plan.id) {
      return savePlan(
        plan.id,
        plan
      )(dispatch).then(response => {
        if (response) {
          this.props.history.push(`/practices/${plan.practice}`);
        }
      });
    }
  };

  public onClickDelete = async () => {
    if (this.props.plan) {
      await this.props.deletePlan(this.props.plan.id);
      this.props.history.push(
        `/contacts/${this.props.contact ? this.props.contact.id : ''}`
      );
    }
  };

  public editPlanStatus = (status: PlanStatus) => {
    if (this.props.plan) {
      const editedPlan: Plan = {
        ...this.props.plan,
        status,
      };
      this.props.savePlan(this.props.plan.id, editedPlan);
    }
  };

  public onClickUnarchive = () => this.editPlanStatus(PlanStatus.Live);
  public onClickArchive = () => this.editPlanStatus(PlanStatus.Archived);
}

/** Disconnected version of the component that is used for testing. */
export { PlansEditPage as TestablePlanEditPage };

export default connector(PlansEditPage);

import React from 'react';

import SidebarCard, { SidebarCardSection } from '^/common/sidebar-card';
import { getPlanName } from './helpers';
import { PlanResponse } from './types';

// Props that come from the parent component.
interface OwnProps {
  plan: PlanResponse;
}

export type PlanSummaryCardProps = OwnProps;

class PlanSummaryCard extends React.PureComponent<PlanSummaryCardProps> {
  public render() {
    const { plan } = this.props;

    return (
      <SidebarCard heading="Plan info">
        <SidebarCardSection>
          <p>
            {getPlanName(plan)} is a{' '}
            <strong>{plan.variable_pricing ? 'variable' : 'fixed'}</strong>{' '}
            {plan.age_restriction.toLowerCase()} plan{' '}
            <strong>{plan.insprac ? 'with INSPRAC' : 'without INSPRAC'}</strong>
            .
          </p>
        </SidebarCardSection>
      </SidebarCard>
    );
  }
}

// Disconnected version used for testing
export { PlanSummaryCard as TestablePlanSummaryCard };

export default PlanSummaryCard;

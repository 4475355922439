import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Field, FieldArrayFieldsProps } from 'redux-form';

import AppButton from './../common/app-button';
import { parseNumberField } from './../common/helper-functions';
import { requiredZeroPermitted } from './../common/validation';
import RenderCheckBox from './../form-helpers/render-checkbox';
import RenderCurrencyField from './../form-helpers/render-currency-field';
import RenderInputField from './../form-helpers/render-input-field';
import RenderVatCodePicker from './../form-helpers/render-vat-code-picker';
import SubscriptionsMembershipAddButton from './../memberships/subscriptions/add-button';

interface IRemoveFieldButton {
  onRemove: (index: number) => void;
  field: number;
}

const RemoveFieldButton = ({ onRemove, field }: IRemoveFieldButton) => {
  const handlePress = useCallback(() => onRemove(field), [onRemove, field]);

  return (
    <AppButton transparent onClick={handlePress}>
      <FontAwesomeIcon icon="trash" />
    </AppButton>
  );
};

export interface RenderSelectedMembershipsOwnProps {
  fields: FieldArrayFieldsProps<any>;
  contactId: string;
}

const RenderSelectedMemberships: React.FC<RenderSelectedMembershipsOwnProps> = ({
  fields,
  contactId,
}) => {
  const addMembership = useCallback(
    selectedMembership => fields.push(selectedMembership),
    [fields]
  );

  return (
    <>
      <div className="modal-body-section">
        <SubscriptionsMembershipAddButton
          contactId={contactId}
          isCompany
          isHeadOfficeExcluded
          setSelectedMemberships={addMembership}
        />
      </div>
      <Table fill>
        <TableHead>
          <TableRow>
            <TableHeader>Membership</TableHeader>
            <TableHeader>Fees</TableHeader>
            <TableHeader>Nominal Code</TableHeader>
            <TableHeader>VAT code</TableHeader>
            <TableHeader>VAT inc.</TableHeader>
            <TableHeader>Delete</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((membership: string, index: number) => {
            const descriptionValue = fields.get(index).description;
            return (
              <TableRow key={`${membership}.id`}>
                <TableHeader title={descriptionValue}>
                  {descriptionValue}
                </TableHeader>
                <TableCell>
                  <Field
                    name={`${membership}.fee.amount`}
                    component={RenderCurrencyField}
                    validate={[requiredZeroPermitted]}
                    parse={parseNumberField}
                  />
                </TableCell>
                <TableCell>
                  <Field
                    name={`${membership}.fee.nominal_code`}
                    type="text"
                    component={RenderInputField}
                    shortest
                  />
                </TableCell>
                <TableCell>
                  <Field
                    name={`${membership}.fee.vat`}
                    type="text"
                    component={RenderVatCodePicker}
                    validate={[requiredZeroPermitted]}
                    isBillingContainerSubscription
                    defaultNone
                  />
                </TableCell>
                <TableCell>
                  <Field
                    name={`${membership}.fee.vat_inclusive`}
                    component={RenderCheckBox}
                  />
                </TableCell>
                <TableCell>
                  <RemoveFieldButton field={index} onRemove={fields.remove} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export { RenderSelectedMemberships as TestableARenderSelectedMemberships };

export default RenderSelectedMemberships;

import { ResponsesReducerState } from '@dabapps/redux-requests';
import { Modal } from '@dabapps/roe';
import { RouterState } from 'connected-react-router';
import moment, { Moment } from 'moment';
import { Action, AnyAction } from 'redux';
import { FormStateMap } from 'redux-form';
import { ThunkAction } from 'redux-thunk';

import { ActivityResponse } from '^/activities/types';
import { AddressResponse, LoqateFindResponse } from '^/address/types';
import { NominalCodeResponse } from '^/admin/bank/types';
import { TemplateResponse } from '^/admin/templates/types';
import { UserResponse } from '^/admin/users/types';
import { AttachmentResponse } from '^/attachments/types';
import {
  AllocationResponse,
  Ledger,
  LedgerEntryAllocationData,
  LedgerResponse,
} from '^/contacts/ledger/types';
import {
  ContactResponse,
  ContactType,
  FileResponse,
  UnverifiedPatientResponse,
} from '^/contacts/types';
import { MembershipsSummaryResponse } from '^/dashboard/types';
import { FilterList } from '^/filters/types';
import { InsuranceResponse } from '^/insurance/types';
import { MembershipResponse } from '^/memberships/types';
import {
  LoqateValidationError,
  LoqateValidationResponse,
  PaymentDetailsResponse,
} from '^/payment-details/types';
import {
  PlanSubscriptionResponse,
  SubscriptionContact,
} from '^/plans/subscriptions/types';
import {
  DiscountResponse,
  FeesByAgeResponse,
  PlanResponse,
} from '^/plans/types';
import { ContactRelationships } from '^/relationships/reducers';
import { BulkReport, ReportResponse } from '^/routines/types';
import { ProductResponse } from './admin/products/types';
import { CollectionResponse } from './collections/types';
import { IIPlanReducer } from './iplan/reducers';
import { BACSSubmission } from './ledger/types';
import { MembershipSubscriptionResponse } from './memberships/subscriptions/types';
import { PublicPatientReducer } from './public/reducers';

export interface StoreState {
  activities: PaginatedArray<ActivityResponse> | null;
  activityCache: DetailedCache<ActivityResponse>;
  allocationsByDetail: {
    [detailId: string]: ReadonlyArray<AllocationResponse>;
  };
  archiveablePlans: { [planId: string]: boolean };
  attachmentsBySession: {
    [session: string]: { [id: string]: AttachmentResponse };
  };
  attachmentsCache: DetailedCache<AttachmentResponse>;
  bulkReport: BulkReport | null;
  codeContact: CachedItem<ContactResponse> | null;
  collections: ByContact<PaginatedArray<CollectionResponse>>;
  contactActivities: ByContact<PaginatedArray<ActivityResponse>>;
  contactAddresses: ByContact<PaginatedArray<AddressResponse>>;
  contactContacts: ByContact<PaginatedArray<ContactResponse>>;
  contactLedger: ByContact<PaginatedArray<LedgerResponse>>;
  contactPayer: DetailedCache<ContactResponse>;
  contactPlans: ByContact<PaginatedArray<PlanResponse>>;
  contactPopups: ByContact<PaginatedArray<ActivityResponse>>;
  contactRelationships: ContactRelationships;
  contacts: PaginatedArray<ContactResponse> | null;
  contactsCache: DetailedCache<ContactResponse> | null;
  unverifiedPatientsCache: DetailedCache<UnverifiedPatientResponse>;
  dashboardActivityLists: ByActivityListName<PaginatedArray<ActivityResponse>>;
  dentistsByPractice: ByContact<ReadonlyArray<ContactResponse>>;
  discounts: { [planId: string]: PaginatedArray<DiscountResponse> };
  feesByAge: { [planId: string]: PaginatedArray<FeesByAgeResponse> };
  files: PaginatedArray<FileResponse> | null;
  form: FormStateMap;
  inspatConfig: DetailedCache<TemplateResponse>;
  inspracConfig: CachedItem<InsuranceResponse> | null;
  iplan: IIPlanReducer | null;
  isolatedContactRelationships: ContactRelationships;
  ledgerEntries: PaginatedArray<LedgerResponse> | null;
  ledgerEntriesByDetail: DetailedCache<Ledger>;
  ledgerEntryAllocation: LedgerEntryAllocationData | null;
  linkedPaymentDetails: ByContact<PaymentDetailsResponse>;
  loggedInUser: UserResponse | null;
  loqateValidation: ByPaymentDetails<
    LoqateValidationResponse | LoqateValidationError
  >;
  membershipCache: DetailedCache<MembershipResponse>;
  membershipSubscriptionCache: DetailedCache<MembershipSubscriptionResponse>;
  membershipSubscriptions: ByContact<
    PaginatedArray<MembershipSubscriptionResponse>
  >;
  memberships: PaginatedArray<MembershipResponse> | null;
  membershipsSummary: MembershipsSummaryResponse | null;
  modalContactLedger: ByContact<PaginatedArray<LedgerResponse>>;
  modals: ReadonlyArray<React.ReactElement<Modal>>;
  nextCollectionTotals: ByContact<number>;
  nominalCodes: PaginatedArray<NominalCodeResponse> | null;
  paginatedSubscriptionContacts: BySubscription<
    PaginatedArray<Required<SubscriptionContact>>
  >;
  patientPracticeMapping: ByContact<string>;
  patientsByContact: ByContact<PaginatedArray<ContactResponse>>;
  payerPayeeGroup: ByContact<PaginatedArray<ContactResponse>>;
  paymentDetails: ByContact<PaymentDetailsResponse | undefined>;
  paymentDetailsCache: DetailedCache<PaymentDetailsResponse>;
  pendingBACSSubmission: BACSSubmission;
  performerPatientCounts: ByContact<number>;
  planCache: DetailedCache<PlanResponse> | {};
  planSubscriptionCache: DetailedCache<PlanSubscriptionResponse> | {};
  planSubscriptions: ByContact<PaginatedArray<PlanSubscriptionResponse>>;
  practiceInsurance: ByContact<InsuranceResponse>;
  practices: PaginatedArray<ContactResponse> | null;
  products: PaginatedArray<ProductResponse> | null;
  productCache: DetailedCache<ProductResponse>;
  publicPatient: PublicPatientReducer | null;
  reportsByType: {
    [category: string]: { [type: string]: PaginatedArray<ReportResponse> };
  };
  responses: ResponsesReducerState;
  router: RouterState;
  setPropsReducer: { [index: string]: {}; __secretKey: string };
  subscriptionContacts: BySubscription<
    ReadonlyArray<Required<SubscriptionContact>>
  >;
  suggestedAddresses: ReadonlyArray<LoqateFindResponse> | null;
  templateCache: DetailedCache<TemplateResponse>;
  templates: PaginatedArray<TemplateResponse> | null;
  users: PaginatedArray<UserResponse> | null;
  practiceUsers: PaginatedArray<UserResponse> | null;
  usersCache: DetailedCache<UserResponse>;
  practiceUsersCache: DetailedCache<UserResponse>;
  pollTaskTimeouts: { [taskId: string]: number };
}

export type CachedItem<T extends object> = T & { retrieved: Moment };

export interface DetailedCache<T extends object> {
  [id: string]: CachedItem<T> | undefined;
}

export interface PaginatedArray<T> {
  count: number;
  page: number;
  filters?: FilterList;
  pageSize: number;
  retrieved?: moment.Moment;
  pages: {
    [page: number]: ReadonlyArray<T>;
  };
}

export interface ByContact<T> {
  [contactId: string]: T | undefined;
}

export interface BySubscription<T> {
  [subscriptionId: string]: T;
}

// keyed by details to account for contacts with multiple details
export interface ByPaymentDetails<T> {
  [accountNumberPlusSortCode: string]: T;
}

export interface ByActivityListName<T> {
  [name: string]: T | undefined;
}

export interface QueryParams {
  [param: string]: string | number | boolean | undefined;
}

export interface BaseAction<Payload, Meta> extends Action<string> {
  type: string;
  payload: Payload;
  error?: boolean;
  meta?: Meta;
}

export interface ErrorResponse {
  [field: string]: string[];
}

export interface PaginatedResponse<T> {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: ReadonlyArray<T>;
  };
}

export interface PaginatedActionMeta {
  [field: string]: string | number | FilterList | undefined;
  page: number;
  pageSize: number;
  filters?: FilterList;
}

export type PaginatedAction<T> = BaseAction<
  PaginatedResponse<T>,
  PaginatedActionMeta
>;

export type ActionNoPayload = BaseAction<undefined, undefined>;

// tslint:disable-next-line:no-any
export type ThunkAnyAction = ThunkAction<any, any, undefined, any>;

export type ThunkActionOrAction = AnyAction | ThunkAnyAction;

export { ContactType };

// tslint:disable-next-line:no-any
export function isPaginatedActionMeta(meta: any): meta is PaginatedActionMeta {
  return meta && meta.page && meta.pageSize;
}

// tslint:disable-next-line:no-any
export function isPaginatedResponse<T>(
  response: any
): response is PaginatedResponse<T> {
  return (
    response &&
    response.data &&
    typeof response.data.count === 'number' &&
    Array.isArray(response.data.results)
  );
}

import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import { User, UserRole, UserStatus } from '^/admin/users/types';
import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import { ContactResponse } from '^/contacts/types';
import PageContent from '^/page-container/page-content';
import { CREATE_USER, createUser, getUsers } from './actions';
import UserForm from './form';
import { withoutItem } from './helpers';

interface State {
  contacts: ReadonlyArray<ContactResponse>;
}

export type UsersCreatePageProps = RouteComponentProps;

class UsersCreatePage extends React.PureComponent<UsersCreatePageProps, State> {
  public readonly state: State = {
    contacts: [],
  };

  public render() {
    return (
      <PageContent>
        <HeaderBar title="New User" transparent />
        <Container>
          <PageSection>
            <UserForm
              actions={[CREATE_USER]}
              initialValues={{
                status: UserStatus.Active,
                role: UserRole.SalesLevel,
              }}
              onSubmit={this.onSave}
              onCancel={this.props.history.goBack}
              isAdminView
            />
          </PageSection>
        </Container>
      </PageContent>
    );
  }

  public onSave = (user: User, dispatch: Dispatch) => {
    const practices = this.state.contacts.map(each => each.id);
    return createUser({ ...user, practices })(dispatch).then(() => {
      getUsers()(dispatch);
      this.props.history.push('/admin/users');
    });
  };

  public addContact = (contact: ContactResponse) =>
    this.setState({
      contacts: [...withoutItem(this.state.contacts, contact), contact],
    });

  public removeContact = (contact: ContactResponse) =>
    this.setState({
      contacts: withoutItem(this.state.contacts, contact),
    });
}

// Disconnected version used for testing
export { UsersCreatePage as TestableUsersCreatePage };

export default UsersCreatePage;

import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import LoginFailed from '^/login/login-failed';
import LoginPage from '^/login/login-page';

export type LoginRoutesProps = RouteComponentProps;

class LoginRoutes extends React.PureComponent<LoginRoutesProps> {
  public render() {
    const { match } = this.props;

    return (
      <div className="login-container">
        <Switch>
          <Route path={`${match.path}/sso-failure`} component={LoginFailed} />
          <Route component={LoginPage} />
        </Switch>
      </div>
    );
  }
}

// Disconnected version used for testing
export { LoginRoutes as TestableLoginRoutes };

export default LoginRoutes;

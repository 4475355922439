import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { isPending } from '@dabapps/redux-requests';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { submit } from 'redux-form';

import LedgerEntryPicker from '^/contacts/ledger/ledger-entry-picker';
import { closeModal } from '^/modals/actions';
import WizardModal, {
  WizardModalError,
  WizardModalStage,
} from '^/modals/wizard-modal';
import { StoreState } from '^/types';
import {
  CREATE_ALLOCATIONS,
  CREATE_LEDGER_ENTRY,
  createAllocations,
  createLedgerEntry,
  getContactLedger,
} from '../actions';
import { mapEntriesToAllocations, mergeLedgerEntriesToItems } from '../helpers';
import { Ledger, LedgerEntryType, LedgerResponse } from '../types';
import CreateAllocationsForm, {
  AllocationFormValues,
} from './forms/create-allocations-form';
import CreateLedgerEntryForm from './forms/create-ledger-entry-form';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  initialSelectedEntries: ReadonlyArray<LedgerResponse>;
}

// State props that come from redux.
interface StateProps {
  stage: number;
  selectedEntries: ReadonlyArray<LedgerResponse>;
  createdEntry?: LedgerResponse;
}

export enum Stage {
  Select,
  Create,
  Allocate,
}

export type CreateCreditNoteModalProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class CreateCreditNoteModal extends React.PureComponent<
  CreateCreditNoteModalProps
> {
  public render() {
    return (
      <WizardModal heading="Generate credit note" size="large">
        {this.getStage(this.props.stage)}
      </WizardModal>
    );
  }

  public getStage = (stage: number) => {
    const { contactId, selectedEntries, createdEntry, loading } = this.props;

    switch (stage) {
      case Stage.Select:
        return (
          <WizardModalStage
            heading="Select related entries (Stage 1 of 3)"
            helpText="If this credit note is related to other entries, select them
          above to copy ledger items &amp; set up allocation."
            next={this.nextStage}
            nextText={
              selectedEntries.length < 1
                ? 'Skip'
                : selectedEntries.length === 1
                ? `Continue`
                : `Continue with ${selectedEntries.length} selected entries`
            }
          >
            <LedgerEntryPicker
              filters={{
                contact_1: contactId,
                type: LedgerEntryType.Invoice,
                outstanding: 'True',
              }}
              selected={selectedEntries}
              onSelectionChanged={this.handleSelectionChanged}
            />
          </WizardModalStage>
        );
      case Stage.Create:
        return (
          <WizardModalStage
            heading={`Add ledger items (Stage 2 of 3)`}
            helpText="Add or remove items from the selected ledger entries."
            back={this.prevStage}
            next={this.nextStage}
            nextText="Create credit note"
            loading={loading.createLedgerEntry}
          >
            <CreateLedgerEntryForm
              initialValues={{
                type: LedgerEntryType.CreditNote,
                contact_1: contactId,
                entry_date: moment().toISOString(),
                detail_detail: {
                  ledger_items: mergeLedgerEntriesToItems(
                    selectedEntries,
                    contactId
                  ),
                },
              }}
              showBankOptions={false}
              onSubmit={this.handleSubmitCreditNote}
              showItems
              hideButtons
            />
          </WizardModalStage>
        );
      case Stage.Allocate:
        return (
          <WizardModalStage
            heading="Allocate to related entries (Stage 3 of 3)"
            helpText="Optional: allocate this credit note against the previously
          selected ledger entries. This can also be done later."
            next={this.nextStage}
            nextText="Create allocations"
            loading={loading.createAllocations}
          >
            {createdEntry ? (
              <CreateAllocationsForm
                initialValues={{
                  entry: createdEntry,
                  allocations: mapEntriesToAllocations(
                    selectedEntries,
                    createdEntry
                  ),
                }}
                hideButtons
                onSubmit={this.handleSubmitAllocations}
              />
            ) : (
              <p className={'error'}>
                Something went wrong, couldn't find the new ledger entry...
                please close the modal and try again.
              </p>
            )}
          </WizardModalStage>
        );
      default:
        return <WizardModalError next={this.props.closeModal} />;
    }
  };

  public nextStage = async () => {
    switch (this.props.stage) {
      case Stage.Select:
        this.props.setProps({ stage: Stage.Create });
        break;
      case Stage.Create:
        this.props.submit('CreateLedgerEntryForm');
        break;
      case Stage.Allocate:
        this.props.submit('CreateAllocationsForm');
        break;
      default:
        break;
    }
  };

  public prevStage = () => {
    switch (this.props.stage) {
      case Stage.Select:
        this.props.closeModal();
        break;
      case Stage.Create:
        this.props.setProps({ stage: Stage.Select });
        break;
      case Stage.Allocate:
      default:
        break;
    }
  };

  public handleSelectionChanged = (
    selectedEntries: ReadonlyArray<LedgerResponse>
  ) => this.props.setProps({ selectedEntries });

  public handleSubmitCreditNote = async (entry: Ledger) => {
    const response = await this.props.createLedgerEntry(entry);

    this.props.getContactLedger(this.props.contactId);

    if (response && this.props.selectedEntries.length) {
      this.props.setProps({
        stage: Stage.Allocate,
        createdEntry: response.data,
      });
    } else {
      this.props.closeModal();
    }
  };

  public handleSubmitAllocations = async (values: AllocationFormValues) => {
    await this.props.createAllocations(values.allocations);

    this.props.getContactLedger(this.props.contactId);

    this.props.closeModal();
  };
}

// Disconnected version used for testing
export { CreateCreditNoteModal as TestableCreateCreditNoteModal };

export const getInitialProps = (props: OwnProps) => ({
  stage: Stage.Select,
  selectedEntries: props.initialSelectedEntries,
});

export const mapState = (state: StoreState) => ({
  loading: {
    createLedgerEntry: isPending(state.responses, CREATE_LEDGER_ENTRY),
    createAllocations: isPending(state.responses, CREATE_ALLOCATIONS),
  },
});

const mapDispatch = {
  closeModal,
  getContactLedger,
  createLedgerEntry,
  createAllocations,
  submit,
};

const connector = connect(mapState, mapDispatch);

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(CreateCreditNoteModal)
);

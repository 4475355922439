import { anyPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import {
  GET_TEMPLATE,
  getTemplate,
  saveTemplate,
} from '^/admin/templates/actions';
import TemplatesForm from '^/admin/templates/form';
import { Template } from '^/admin/templates/types';
import { ErrorPage } from '^/common/error-page';
import { HeaderBar } from '^/common/header-bar';
import { Loading } from '^/common/loading';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

export type TemplatesEditPageProps = RouteComponentProps<{
  id: string;
  tab?: string;
}> &
  ConnectedProps<typeof connector>;

class TemplatesEditPage extends React.PureComponent<TemplatesEditPageProps> {
  public componentDidMount() {
    const {
      match: { params },
      template,
    } = this.props;

    if (cachedItemHasExpired(template)) {
      this.props.getTemplate(params.id);
    }
  }

  public render() {
    const { template, loading } = this.props;

    if (!template) {
      if (loading.details) {
        return (
          <PageContent>
            <HeaderBar title="Plan Template" transparent loading />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Template not found" />;
    }

    return (
      <PageContent>
        <HeaderBar
          title={template.code}
          subtitle={template.description}
          transparent
        />
        <Container>
          <TemplatesForm
            initialValues={template}
            onSubmit={this.handleSave}
            onCancel={this.props.history.goBack}
          />
        </Container>
      </PageContent>
    );
  }

  public handleSave = (template: Template, dispatch: Dispatch) =>
    saveTemplate(
      this.props.match.params.id,
      template
    )(dispatch).then(() => {
      this.props.history.replace(`/admin/templates`);
    });
}

export { TemplatesEditPage as TestableTemplatesEditPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ id: string; tab?: string }>
) => ({
  template: getItemFromCache(props.match.params.id, state.templateCache),
  loading: {
    details: anyPending(state.responses, [GET_TEMPLATE]),
  },
});

const connector = connect(mapState, { getTemplate });

export default connector(TemplatesEditPage);

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests/dist/js';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { GET_MEMBERSHIPS, getMemberships } from '^/admin/memberships/actions';
import { formatDate, toggleSortFor } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import { MembershipResponse } from '^/memberships/types';
import { formatFeeAmount } from '^/plans/helpers';
import TableHeaderSort from '^/sorts/table-header-sort';
import { SortList } from '^/sorts/types';
import { StoreState } from '^/types';
import {
  getCurrentPage,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

interface StateProps {
  appliedSorts: SortList;
}

export type MembershipsTableProps = SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

export class MembershipsTable extends React.PureComponent<
  MembershipsTableProps
> {
  public componentDidMount() {
    const { loading, memberships } = this.props;

    if (!loading && paginatedArrayHasExpired(memberships)) {
      this.props.getMemberships();
    }
  }

  public componentDidUpdate(prevProps: MembershipsTableProps) {
    if (this.props.appliedSorts !== prevProps.appliedSorts) {
      this.props.getMemberships(
        1,
        this.props.memberships ? this.props.memberships.pageSize : undefined,
        this.props.appliedSorts
      );
    }
  }

  public render() {
    const { memberships, appliedSorts, loading, setProps } = this.props;

    return (
      <PaginatedTable
        changePage={this.changePage}
        paginatedData={memberships}
        loading={loading}
      >
        <TableHead>
          <TableRow>
            <TableHeaderSort
              sortOrder={appliedSorts.code}
              onClick={toggleSortFor.bind(this, 'code', appliedSorts, setProps)}
            >
              Code
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.description}
              onClick={toggleSortFor.bind(
                this,
                'description',
                appliedSorts,
                setProps
              )}
            >
              Name
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.created}
              onClick={toggleSortFor.bind(
                this,
                'created',
                appliedSorts,
                setProps
              )}
            >
              Created
            </TableHeaderSort>
            <TableCell className="align-right">Joining Fee</TableCell>
            <TableCell className="align-right">Subscription Fee</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(memberships).map(membership => (
            <ItemRow
              key={membership.id}
              item={membership}
              onClick={this.handleClick}
            >
              <TableHeader>{membership.code}</TableHeader>
              <TableCell>{membership.description}</TableCell>
              <TableCell>{formatDate(membership.created)}</TableCell>
              <TableCell className="align-right">
                {membership.is_billing_container
                  ? 'N/A'
                  : formatFeeAmount(membership.joining_fee)}
              </TableCell>
              <TableCell className="align-right">
                {membership.is_billing_container
                  ? 'N/A'
                  : formatFeeAmount(membership.fee)}
              </TableCell>
            </ItemRow>
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public handleClick = (membership: MembershipResponse) =>
    this.props.push(`/admin/memberships/${membership.id}`);

  public changePage = (page: number, pageSize?: number) =>
    this.props.getMemberships(page, pageSize);
}

export { MembershipsTable as TestableMembershipsTable };

export const getInitialProps = () => ({
  appliedSorts: {},
});

export const mapState = (state: StoreState) => ({
  memberships: state.memberships,
  loading: anyPending(state.responses, [GET_MEMBERSHIPS]),
});

const connector = connect(mapState, {
  getMemberships,
  push,
});

export default withSetProps<StateProps>(getInitialProps)(
  connector(MembershipsTable)
);

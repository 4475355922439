import React from 'react';
import { Link } from 'react-router-dom';

import { HeaderBar } from '^/common/header-bar';
import PageContent from '^/page-container/page-content';

export const ErrorPage: React.SFC<{ heading?: string }> = ({ heading }) => (
  <PageContent>
    {heading && <HeaderBar title={heading} transparent />}
    <span>
      Content could not be found. Check your URL or <Link to="/">go back</Link>.
    </span>
  </PageContent>
);

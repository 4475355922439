import classNames from 'classnames';
import React from 'react';

export const Stepper = ({
  stepOneActive,
  stepTwoActive,
  stepThreeActive,
}: {
  stepOneActive?: boolean;
  stepTwoActive?: boolean;
  stepThreeActive?: boolean;
}) => {
  return (
    <div className="md-stepper-horizontal orange">
      <div className={classNames(['md-step', { active: stepOneActive }])}>
        <div className="md-step-circle">
          <span />
        </div>
        <div className="md-step-bar-left" />
        <div className="md-step-bar-right" />
      </div>
      <div className={classNames(['md-step', { active: stepTwoActive }])}>
        <div className="md-step-circle">
          <span />
        </div>
        <div className="md-step-bar-left" />
        <div className="md-step-bar-right" />
      </div>
      <div className={classNames(['md-step', { active: stepThreeActive }])}>
        <div className="md-step-circle">
          <span />
        </div>
        <div className="md-step-bar-left" />
        <div className="md-step-bar-right" />
      </div>
    </div>
  );
};

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { formValueSelector, WrappedFieldArrayProps } from 'redux-form';

import AppButton from '^/common/app-button';
import {
  deliveryReducer,
  productAmountReducer,
  productVatReducer,
} from '^/contacts/ledger/helpers';
import ProductLedgerItemFormRow from '^/contacts/ledger/modals/forms/product-ledger-items';
import { LedgerItem } from '^/contacts/ledger/types';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';

const selector = formValueSelector('generateProductInvoiceForm');

export const mapState = (state: StoreState) => ({
  ledger_items: selector(state, 'ledger_items'),
});

const connector = connect(mapState);

export type RenderProductInvoiceItemsProps = WrappedFieldArrayProps<
  LedgerItem
> &
  ConnectedProps<typeof connector>;

export class RenderProductInvoiceItems extends React.Component<
  RenderProductInvoiceItemsProps
> {
  public componentDidMount() {
    this.addLedgerItem();
  }

  public render() {
    const { fields, ledger_items } = this.props;

    const items = ledger_items ? ledger_items : [];
    return (
      <Table fill>
        <TableHead>
          <TableRow>
            <TableHeader title="Product name/code">
              Product name/code
            </TableHeader>
            <TableCell title="Member" className="show">
              Member
            </TableCell>
            <TableCell title="Price (£)" className="align-right">
              Price (£)
            </TableCell>
            <TableCell title="Quantity">Quantity</TableCell>
            <TableCell title="Delivery (£)" className="align-right">
              Delivery (£)
            </TableCell>
            <TableCell title="VAT code">VAT code</TableCell>
            <TableCell title="Nominal code">Nom.</TableCell>
            <TableCell title="VAT amount" className="align-right">
              VAT
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item: string, index: number) => (
            <ProductLedgerItemFormRow
              item={item}
              index={index}
              removeLedgerItem={this.removeLedgerItem}
              key={`ledgerItemRow-${item}`}
              ledgerItem={items[index] ? items[index] : emptyLedgerItem}
            />
          ))}
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell colSpan={8} className="align-right">
              <AppButton type="button" onClick={this.addLedgerItem} link>
                Add product
              </AppButton>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell colSpan={3} className="align-right">
              Goods (ex. VAT):{' '}
              <strong>{formatFeeAmount(this.calculateAmount())}</strong>
            </TableCell>
            <TableCell colSpan={2} className="align-right">
              Delivery (ex. VAT):{' '}
              <strong>{formatFeeAmount(this.calculateDelivery())}</strong>
            </TableCell>
            <TableCell colSpan={3} className="align-right">
              VAT amount:{' '}
              <strong>{formatFeeAmount(this.calculateVATAmount())}</strong>
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell colSpan={8} className="align-right">
              Gross amount:{' '}
              <strong>{formatFeeAmount(this.calculateGrossAmount())}</strong>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  public calculateAmount = () => {
    const { ledger_items = [] } = this.props;

    return ledger_items.reduce(productAmountReducer, 0);
  };

  public calculateDelivery = () => {
    const { ledger_items = [] } = this.props;

    return ledger_items.reduce(deliveryReducer, 0);
  };

  public calculateVATAmount = () => {
    const { ledger_items = [] } = this.props;

    return ledger_items.reduce(productVatReducer, 0);
  };

  public calculateGrossAmount = () =>
    this.calculateAmount() +
    this.calculateVATAmount() +
    this.calculateDelivery();

  public removeLedgerItem = (index: number) => {
    this.props.fields.remove(index);
  };

  public addLedgerItem = () => {
    this.props.fields.push(emptyLedgerItem);
  };
}

const emptyLedgerItem: LedgerItem & { member: boolean } = {
  item: 'Product',
  item_code: '',
  contact: '',
  amount: 0,
  nominal_code: null,
  ledger_detail: null,
  quantity: 1,
  vat: null,
  vat_amount: 0,
  vat_inclusive: false,
  member: true,
};

// Disconnected version used for testing
export { RenderProductInvoiceItems as TestableRenderProductInvoiceItems };

export default connector(RenderProductInvoiceItems);

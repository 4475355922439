import { AnyAction } from 'redux';

import { GET_MEMBERSHIP } from '^/admin/memberships/actions';
import {
  CREATE_TEMPLATE,
  GET_TEMPLATE,
  SAVE_TEMPLATE,
} from '^/admin/templates/actions';
import { TemplateResponse } from '^/admin/templates/types';
import { GET_PLAN } from '^/plans/actions';
import { PlanResponse } from '^/plans/types';
import { DetailedCache } from '^/types';

export function planCache(
  state: DetailedCache<PlanResponse> = {},
  action: AnyAction
): DetailedCache<PlanResponse> {
  switch (action.type) {
    case GET_PLAN.SUCCESS:
    case GET_MEMBERSHIP.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
        },
      };
    default:
      return state;
  }
}

export function templateCache(
  state: DetailedCache<TemplateResponse> = {},
  action: AnyAction
): DetailedCache<TemplateResponse> {
  switch (action.type) {
    case CREATE_TEMPLATE.SUCCESS:
    case SAVE_TEMPLATE.SUCCESS:
    case GET_TEMPLATE.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
        },
      };
    default:
      return state;
  }
}

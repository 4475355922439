import { Container } from '@dabapps/roe';
import React from 'react';

import PageSection from '^/common/page-section/page-section';
import PageContent from '^/page-container/page-content';
import PracticeDetails from './practice-details';

const PracticeDetailsPage = () => {
  return (
    <PageContent page="practice-details">
      <Container>
        <PageSection>
          <PracticeDetails />
        </PageSection>
      </Container>
    </PageContent>
  );
};

export default PracticeDetailsPage;

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { required } from '^/common/validation';
import { CompanyProfileCommunicationMethod } from '^/contacts/companies/types';
import { Contact } from '^/contacts/types';
import RenderCategoryPicker from '^/form-helpers/render-category-picker';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderToggleSwitch from '^/form-helpers/render-toggle-switch';

/** Props that are passed into this component by the parent. */
interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  generalErrorFields?: ReadonlyArray<string>;
  /** Prefix for each label, e.g. '<prefix> Name'. Defaults to 'Company'. */
  labelPrefix?: string;
  /** If present a cancel button will be shown that will fire this prop. */
  onCancel?(): void;
}

/** All company form props combined. */
export type CompaniesFormProps = OwnProps &
  InjectedFormProps<Contact, OwnProps>;

/**
 * Form that is used for both companies and practices. Can be used in both a create and edit context. The prop 'onSubmit' is called when the save button is pressed.
 */
class CompaniesForm extends React.Component<CompaniesFormProps> {
  public render() {
    const {
      actions,
      generalErrorFields = ['non_field_errors'],
      handleSubmit,
      initialValues,
      labelPrefix = 'Company',
      onCancel,
      pristine,
      submitting,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            component={RenderInputField}
            label={`${labelPrefix} Name`}
            name="company.name"
            type="text"
            validate={[required]}
          />
          <Field
            component={RenderInputField}
            label={`${labelPrefix} Website`}
            name="company.website"
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={RenderInputField}
            label={`${labelPrefix} Email`}
            name="company.email"
            type="email"
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={RenderDropdown}
            label="Contact Preferences"
            name="company.communication_method"
            options={[
              { label: 'Please choose..', value: '' },
              {
                label: 'Email',
                value: CompanyProfileCommunicationMethod.Email,
              },
              {
                label: 'Post',
                value: CompanyProfileCommunicationMethod.Post,
              },
              {
                label: 'SMS',
                value: CompanyProfileCommunicationMethod.SMS,
              },
            ]}
            short
          />
          <Field
            component={RenderCategoryPicker}
            contactType={initialValues.type}
            label="Category"
            name="category"
            validate={[required]}
          />
          <Field
            component={RenderInputField}
            label="Bacs Prefix"
            name="company.bacs_prefix"
            type="text"
          />
        </FormGroup>

        <FormGroup>
          {this.props.initialValues &&
            this.props.initialValues.company?.unique_practice_url && (
              <Field
                component={RenderInputField}
                label="iPlan URL"
                name="company.unique_practice_url"
                type="text"
                disabled
              />
            )}

          <Field
            component={RenderToggleSwitch}
            options={[
              { label: 'yes', value: true },
              { label: 'no', value: false },
            ]}
            short
            label="iPlan Active"
            name="company.portal_active"
            defaultValue={false}
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={RenderToggleSwitch}
            options={[
              { label: 'yes', value: true },
              { label: 'no', value: false },
            ]}
            short
            label="Include in bulk report"
            name="company.include_in_bulk_report"
            defaultValue={false}
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={generalErrorFields}
          showStatusErrors
        />
        <div className="form-buttons">
          {onCancel && (
            <AppButton type="button" onClick={onCancel} disabled={submitting}>
              Cancel
            </AppButton>
          )}
          <AppButton
            type="submit"
            disabled={pristine}
            loading={submitting}
            primary
          >
            Save
          </AppButton>
        </div>
      </Form>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { CompaniesForm as TestableCompaniesForm };

export default reduxForm<Contact, OwnProps>({
  form: 'companiesForm',
})(CompaniesForm);

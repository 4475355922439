import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { FilterList } from '^/filters/types';
import {
  clearLedgerEntries,
  GET_LEDGER_ENTRIES,
  getLedgerEntries,
} from '^/ledger/actions';
import { StoreState } from '^/types';
import LedgerTable from './ledger-table';
import { LedgerResponse } from './types';

// Props that come from the parent component.
interface OwnProps {
  filters: FilterList;
  selected: ReadonlyArray<LedgerResponse>;
  onSelectionChanged(selected: ReadonlyArray<LedgerResponse>): void;
}

export type LedgerEntryPickerProps = OwnProps &
  ConnectedProps<typeof connector>;

class LedgerEntryPicker extends React.PureComponent<LedgerEntryPickerProps> {
  public componentDidMount() {
    const { entries, filters } = this.props;

    if (
      !entries ||
      !entries.filters ||
      entries.filters.contact_1 !== filters.contact_1
    ) {
      this.props.clearLedgerEntries();
    }

    this.handleChangePage(1);
  }

  public render() {
    const { entries, selected, loading } = this.props;

    return (
      <LedgerTable
        ledgerEntries={entries}
        loading={loading}
        selectedLedgerEntries={selected}
        onClickRow={this.handleRowClicked}
        changePage={this.handleChangePage}
      />
    );
  }

  public handleRowClicked = (entry: LedgerResponse) => {
    const selectedEntries = this.props.selected.some(e => e.id === entry.id)
      ? this.props.selected.filter(e => e.id !== entry.id)
      : [...this.props.selected, entry];

    this.props.onSelectionChanged(selectedEntries);
  };

  public handleChangePage = (page: number, pageSize?: number | undefined) =>
    this.props.getLedgerEntries(this.props.filters, page, pageSize);
}

// Disconnected version used for testing
export { LedgerEntryPicker as TestableLedgerEntryPicker };

export const mapState = (state: StoreState) => ({
  entries: state.ledgerEntries || undefined,
  loading: isPending(state.responses, GET_LEDGER_ENTRIES),
});

const mapDispatch = {
  getLedgerEntries,
  clearLedgerEntries,
};

const connector = connect(mapState, mapDispatch);

export default connector(LedgerEntryPicker);

import { anyPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import {
  GET_PRACTICE_USERS,
  getPracticeUser,
  getPracticeUsers,
  SAVE_PRACTICE_USER,
  savePracticeUser,
} from '^/admin/users/actions';
import UserForm from '^/admin/users/form';
import { getUserName } from '^/admin/users/helpers';
import { withoutItem } from '^/admin/users/helpers';
import { User } from '^/admin/users/types';
import { ErrorPage } from '^/common/error-page';
import HeaderBar from '^/common/header-bar';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import { ContactResponse } from '^/contacts/types';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { cachedItemHasExpired } from '^/utils/cache-helpers';

interface State {
  contacts: ReadonlyArray<ContactResponse>;
  contactsModified: boolean;
}

export type PracticeUsersEditPageProps = RouteComponentProps<{ id: string }> &
  ConnectedProps<typeof connector>;

class PracticeUsersEditPage extends React.PureComponent<
  PracticeUsersEditPageProps,
  State
> {
  public readonly state: State = {
    contacts: [],
    contactsModified: false,
  };

  public componentDidMount(): void {
    const {
      user,
      match: { params },
    } = this.props;

    if (params.id && cachedItemHasExpired(user)) {
      this.props.getPracticeUser(params.id);
    }

    this.setState({ contacts: user?.practices || [] });
  }

  public componentDidUpdate(prevProps: PracticeUsersEditPageProps): void {
    if (prevProps.user?.practices !== this.props.user?.practices) {
      this.setState({ contacts: this.props.user?.practices || [] });
    }
  }

  public render() {
    const { user, loading } = this.props;
    const { contacts, contactsModified } = this.state;

    if (!user) {
      if (loading.details) {
        return (
          <PageContent>
            <HeaderBar title="Edit User" transparent loading />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="User not found" />;
    }

    return (
      <PageContent>
        <HeaderBar title="Edit User" subtitle={getUserName(user)} transparent />
        <Container>
          <PageSection>
            <UserForm
              actions={[SAVE_PRACTICE_USER]}
              initialValues={{
                ...user,
                practices: user.practices.map(each => each.id),
              }}
              onSubmit={this.onSave}
              onCancel={this.props.history.goBack}
              contacts={contacts}
              addContact={this.addContact}
              removeContact={this.removeContact}
              contactsModified={contactsModified}
            />
          </PageSection>
        </Container>
      </PageContent>
    );
  }

  public onSave = (user: User, dispatch: Dispatch) => {
    const { users } = this.props;

    return savePracticeUser(this.props.match.params.id, {
      ...user,
      practices: this.state.contacts.map(each => each.id),
    })(dispatch).then(() => {
      if (users) {
        getPracticeUsers(users.page, users.pageSize)(dispatch);
      }

      this.props.history.push('/practice-users');
    });
  };

  public addContact = (contact: ContactResponse) =>
    this.setState({
      contactsModified: true,
      contacts: [...withoutItem(this.state.contacts, contact), contact],
    });

  public removeContact = (contact: ContactResponse) =>
    this.setState({
      contactsModified: true,
      contacts: withoutItem(this.state.contacts, contact),
    });
}

// Disconnected version used for testing
export { PracticeUsersEditPage as TestablePracticeUsersEditPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ id: string }>
) => ({
  user: state.practiceUsersCache[props.match.params.id],
  users: state.practiceUsers,
  loading: { details: anyPending(state.responses, [GET_PRACTICE_USERS]) },
});

const connector = connect(mapState, { getPracticeUser });

export default connector(PracticeUsersEditPage);

import { Column, Container, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { createLetter } from '^/admin/letters/actions';
import LettersFilterForm from '^/admin/letters/filter-form';
import LetterDetailsForm, {
  LetterDetailsValues,
} from '^/admin/letters/letter-details-form';
import { getMemberships } from '^/admin/memberships/actions';
import HeaderBar from '^/common/header-bar';
import { clearContacts, getContacts } from '^/contacts/actions';
import ContactsTable from '^/contacts/table';
import { ContactType } from '^/contacts/types';
import { FilterList } from '^/filters/types';
import { MembershipResponse } from '^/memberships/types';
import PageContent from '^/page-container/page-content';
import { getPlanName } from '^/plans/helpers';
import ReportList from '^/routines/report-list';
import { ReportCategory, ReportType } from '^/routines/types';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

export type LettersPageProps = ConnectedProps<typeof connector>;

class LettersPage extends React.PureComponent<LettersPageProps> {
  public componentDidMount() {
    if (paginatedArrayHasExpired(this.props.memberships, 100)) {
      this.props.getMemberships(1, 100);
    }
  }

  public componentWillUnmount() {
    this.props.clearContacts();
  }

  public render() {
    const { contacts } = this.props;

    return (
      <PageContent>
        <HeaderBar title="Letters - Generate CSV for mail-merge" transparent />
        <Container>
          <div className="letters-page-content">
            <Row>
              <Column xs={12} lg={3}>
                <div className="filter-section margin-horizontal-none lg-margin-right-base">
                  <LettersFilterForm
                    onSubmit={this.handleFiltersSubmit}
                    enableReinitialize
                    membershipFields={this.getMembershipFields()}
                  />
                </div>
              </Column>
              <Column xs={12} lg={9}>
                <ContactsTable title="1. Choose/filter contacts for CSV" />
                <LetterDetailsForm
                  disabled={getAllRetrievedPages(contacts).length === 0}
                  onSubmit={this.handleLetterSubmit}
                />
                <ReportList
                  title="3. Wait for CSV to process"
                  type={ReportType.Letters}
                  category={ReportCategory.Generic}
                  reportButtonText="Download"
                  noApplyButtons
                  hideExceptions
                />
              </Column>
            </Row>
          </div>
        </Container>
      </PageContent>
    );
  }

  public handleFiltersSubmit = (filters: FilterList, dispatch: Dispatch) => {
    if (
      filters.type === ContactType.Practice &&
      Object.keys(filters).length <= 1
    ) {
      throw new SubmissionError({
        _error: 'Select at least one membership',
      });
    } else if (
      filters.type === ContactType.Patient &&
      !filters.related_contact
    ) {
      throw new SubmissionError({
        related_contact: 'Required',
      });
    }

    const membership = getAllRetrievedPages(this.props.memberships)
      .filter(membershipResult => filters[membershipResult.id])
      .map(membershipResult => membershipResult.id)
      .join(',');

    const { type, related_contact, is_over_18, is_payer } = filters;

    return getContacts(
      {
        type,
        related_contact,
        is_over_18,
        is_payer,
        membership,
      },
      undefined,
      undefined,
      5
    )(dispatch);
  };

  public handleLetterSubmit = (
    detailsFormData: LetterDetailsValues,
    dispatch: Dispatch
  ) => {
    const contactFilters = this.props.contacts
      ? this.props.contacts.filters
      : {};

    return createLetter({
      ...detailsFormData,
      filters: { ...contactFilters },
    })(dispatch);
  };

  public getMembershipFields = () =>
    getAllRetrievedPages(this.props.memberships).map(
      (membership: MembershipResponse) => ({
        label: getPlanName(membership, true),
        name: membership.id,
      })
    );
}

export { LettersPage as TestableLettersPage };

export const mapState = (state: StoreState) => ({
  memberships: state.memberships,
  contacts: state.contacts,
});

const connector = connect(mapState, { clearContacts, getMemberships });

export default connector(LettersPage);

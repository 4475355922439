import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import { FilterList } from '^/filters/types';
import RenderCategoryPicker from '^/form-helpers/render-category-picker';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderInputField from '^/form-helpers/render-input-field';
import { StoreState } from '^/types';
import { Categories, CategoryType, ContactType } from './types';

// Props that come from the parent component.
interface OwnProps {
  hideCategory?: boolean;
  showBacsRef?: boolean;
  showPendingOnly?: boolean;
  showCompanyName?: boolean;
  onClear?(): void;
}

const selector = formValueSelector('contactsFilterForm');

export const mapState = (state: StoreState) => ({
  category: selector(state, 'category'),
  last_name: selector(state, 'last_name'),
  dob: selector(state, 'dob'),
  company_name: selector(state, 'company_name'),
  show_pending_only: selector(state, 'show_pending_only'),
});

const connector = connect(mapState, {});

export type ContactsFilterFormProps = OwnProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<FilterList, OwnProps>;

class ContactsFilterForm extends React.PureComponent<ContactsFilterFormProps> {
  public componentDidUpdate(prevProps: ContactsFilterFormProps) {
    if (this.props.category !== prevProps.category) {
      this.props.change('last_name', '');
      this.props.change('dob', '');
      this.props.change('company_name', '');
    }
  }

  public render() {
    const {
      handleSubmit,
      initialized,
      dirty,
      submitting,
      hideCategory,
      showBacsRef = true,
      showPendingOnly = false,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="form-header">
          <h4>Filters</h4>
          <AppButton
            id="clear-button"
            link
            disabled={!initialized && !dirty}
            loading={submitting}
            onClick={this.handleClear}
          >
            Clear
          </AppButton>
        </div>
        <CSSTransitionGroup
          component={FormGroup}
          transitionName="shrink"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {!hideCategory && (
            <Field
              label="Category"
              name="category"
              component={RenderCategoryPicker}
            />
          )}
          {this.showPersonFields() && (
            <Field
              label="Surname"
              name="last_name"
              component={RenderInputField}
            />
          )}
          {this.showPersonFields() && (
            <Field
              label="Date of birth"
              name="dob"
              component={RenderDateField}
            />
          )}
          {this.showCompanyFields() && (
            <Field
              label="Company name"
              name="company_name"
              component={RenderInputField}
            />
          )}
          <Field
            label="Postcode"
            name="postcode"
            component={RenderInputField}
          />
          {this.showVerifiedPatientFields() && (
            <Field
              label="Contact ID"
              name="crm_id"
              component={RenderInputField}
              type="number"
            />
          )}
          {this.showVerifiedPatientFields() && showBacsRef && (
            <Field
              label="Bacs Reference"
              name="bacs_reference"
              component={RenderInputField}
            />
          )}
          {this.showVerifiedPatientFields() && (
            <Field
              label="Show archived"
              name="show_archived"
              component={RenderCheckBox}
            />
          )}
          {showPendingOnly && (
            <Field
              label="Show pending only"
              name="show_pending_only"
              component={RenderCheckBox}
            />
          )}
        </CSSTransitionGroup>
        <div className="form-buttons">
          <AppButton type="submit" loading={submitting}>
            Search
          </AppButton>
        </div>
      </Form>
    );
  }

  public handleClear = () => {
    this.props.reset();

    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  private showCompanyFields = () => {
    if (!this.props.showCompanyName) {
      return false;
    }
    const companyCategorySelected =
      this.categoryIsType(this.props.category, ContactType.Company) ||
      this.categoryIsType(this.props.category, ContactType.Practice);

    return companyCategorySelected && !this.props.last_name && !this.props.dob;
  };

  private showPersonFields = () => {
    const personCategorySelected =
      this.categoryIsType(this.props.category, ContactType.Patient) ||
      this.categoryIsType(this.props.category, ContactType.Individual);

    return personCategorySelected && !this.props.company_name;
  };

  private showVerifiedPatientFields = () => {
    return !this.props.show_pending_only;
  };

  private categoryIsType(
    category: CategoryType | undefined,
    contactType: ContactType
  ) {
    const selectedCategory = Categories.find(
      categoryDetail => categoryDetail.type === category
    );

    return (
      !selectedCategory || selectedCategory.contactTypes.includes(contactType)
    );
  }
}

// Disconnected version used for testing
export { ContactsFilterForm as TestableContactsFilterForm };

export default reduxForm<FilterList, OwnProps>({
  form: 'contactsFilterForm',
})(connector(ContactsFilterForm));

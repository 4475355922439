import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import TemplatesCreatePage from './create-page';
import TemplatesEditPage from './edit-page';
import TemplatesListPage from './list-page';

class AdminTemplates extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/create`} component={TemplatesCreatePage} />
        <Route path={`${match.path}/:id`} component={TemplatesEditPage} />
        <Route component={TemplatesListPage} />
      </Switch>
    );
  }
}

export default AdminTemplates;

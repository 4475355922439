import { isPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import { required } from '^/common/validation';
import { BANK_OPTIONS } from '^/contacts/ledger/types';
import PracticeSelect from '^/form-helpers/practice-select';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import {
  GET_REPORTS_BY_TYPE,
  REQUEST_REPORT,
  requestReport,
} from '^/routines/actions';
import { Report, ReportParam, ReportResponse } from '^/routines/types';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

interface OwnProps {
  params?: ReadonlyArray<ReportParam>;
  requiredParams?: ReadonlyArray<ReportParam>;
}

export type RequestReportFormProps = OwnProps &
  InjectedFormProps<Report, OwnProps> &
  ConnectedProps<typeof connector>;

class RequestReportForm extends React.PureComponent<RequestReportFormProps> {
  public render() {
    const {
      bulk_report,
      handleSubmit,
      submitting,
      params = [],
      requiredParams = [],
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup className="form-buttons">
          {params.includes(ReportParam.RunFrom) && (
            <Field
              label="Run from"
              name={`params.${ReportParam.RunFrom}`}
              component={RenderDateField}
              validate={
                requiredParams.includes(ReportParam.RunFrom) ? [required] : []
              }
            />
          )}
          {params.includes(ReportParam.RunTo) && (
            <Field
              label="Run to"
              name={`params.${ReportParam.RunTo}`}
              component={RenderDateField}
              validate={
                requiredParams.includes(ReportParam.RunTo) ? [required] : []
              }
            />
          )}
          {params.includes(ReportParam.ReceiptDate) && (
            <Field
              label="Receipt date"
              name={`params.${ReportParam.ReceiptDate}`}
              component={RenderDateField}
              validate={
                requiredParams.includes(ReportParam.ReceiptDate)
                  ? [required]
                  : []
              }
            />
          )}
          {params.includes(ReportParam.ReceiptTo) && (
            <Field
              label="Receipt to"
              name={`params.${ReportParam.ReceiptTo}`}
              component={RenderDropdown}
              options={BANK_OPTIONS}
              validate={
                requiredParams.includes(ReportParam.ReceiptTo) ? [required] : []
              }
            />
          )}
          {params.includes(ReportParam.PaymentMethod) && (
            <Field
              label="Payment method"
              name={`params.${ReportParam.PaymentMethod}`}
              component={RenderDropdown}
              validate={
                requiredParams.includes(ReportParam.PaymentMethod)
                  ? [required]
                  : []
              }
              options={[
                { label: 'Please choose..', value: '' },
                { label: 'Direct Debit', value: 'Direct Debit' },
                { label: 'Cheque', value: 'Cheque' },
              ]}
            />
          )}
          {params.includes(ReportParam.Month) && (
            <Field
              label="Month"
              name={`params.${ReportParam.Month}`}
              component={RenderDateField}
              pickerProps={{
                dateFormat: 'MMMM yyyy',
                showMonthDropdown: false,
                showYearDropdown: false,
                showMonthYearPicker: true,
              }}
              showMonthYearPicker
              placeholder="Month YYYY"
              clearable={false}
              validate={
                requiredParams.includes(ReportParam.RunTo) ? [required] : []
              }
            />
          )}
          {params.includes(ReportParam.Practice) && !bulk_report && (
            <Field
              label="Practice"
              name={`params.${ReportParam.Practice}`}
              component={PracticeSelect}
              validate={
                requiredParams.includes(ReportParam.Practice) && !bulk_report
                  ? [required]
                  : []
              }
            />
          )}
          {params.includes(ReportParam.BulkReport) && (
            <Field
              label="Bulk report"
              name={`params.${ReportParam.BulkReport}`}
              component={RenderCheckBox}
              className="vertically-aligned"
            />
          )}
          <AppButton type="submit" loading={submitting || this.isRequesting()}>
            Run Report
          </AppButton>
        </FormGroup>
      </Form>
    );
  }

  private isRequesting = (): boolean => {
    const { reports, loading } = this.props;

    if (loading.create) {
      return true;
    }

    const allReports = getAllRetrievedPages(reports);

    if (allReports.length > 0) {
      return (
        allReports[0].status === 'Queued' &&
        this.reportCreatedRecently(allReports[0])
      );
    }

    return false;
  };

  private reportCreatedRecently(report: ReportResponse) {
    return moment(report.created).isAfter(moment().subtract(1, 'minute'));
  }
}

// Disconnected version used for testing
export { RequestReportForm as TestableRequestReportForm };

export const mapState = (
  state: StoreState,
  props: InjectedFormProps<Report, OwnProps>
) => {
  const selector = formValueSelector(props.form);
  return {
    bulk_report: selector(state, 'params.bulk_report'),
    reports:
      props.initialValues.report_type && props.initialValues.report_category
        ? state.reportsByType[props.initialValues.report_category][
            props.initialValues.report_type
          ]
        : undefined,
    loading: {
      create: isPending(
        state.responses,
        REQUEST_REPORT,
        props.initialValues.report_type
          ? props.initialValues.report_type
          : undefined
      ),
      reports: isPending(
        state.responses,
        GET_REPORTS_BY_TYPE,
        props.initialValues.report_type
          ? props.initialValues.report_type
          : undefined
      ),
    },
  };
};

const connector = connect(mapState, { requestReport });

const connectedForm = connector(RequestReportForm);

export default reduxForm<Report, OwnProps>({
  form: 'requestReportForm',
})(connectedForm);

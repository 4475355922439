import { hasSucceeded, resetRequestState } from '@dabapps/redux-requests';
import { ModalBody } from '@dabapps/roe';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import AppButton from '^/common/app-button';
import { closeModal } from '^/modals/actions';
import SimpleModal from '^/modals/simple-modal';
import { clearAllTaskTimeouts, POLL_TASK } from '^/tasks/actions';
import TaskStatus from '^/tasks/task-status';
import { StoreState } from '^/types';
import { IMPORT_PATIENTS, importPatients } from '../../actions';
import ImportPatientsForm, {
  ImportPatientsValues,
} from './import-patients-form';

interface OwnProps {
  practiceId: string;
}

export type ImportPatientsModalProps = ConnectedProps<typeof connector> &
  OwnProps;

export class ImportPatientsModal extends React.PureComponent<
  ImportPatientsModalProps
> {
  constructor(props: ImportPatientsModalProps) {
    super(props);
    this.handleImportPatientsSubmit = this.handleImportPatientsSubmit.bind(
      this
    );
    this.handleStartNewImport = this.handleStartNewImport.bind(this);
  }

  public render() {
    const { importStarted } = this.props;
    return (
      <SimpleModal heading="Import Patients" onClose={this.props.closeModal}>
        <ModalBody>
          <div className="modal-body-section">
            {importStarted ? (
              <>
                <TaskStatus />
                <div className="modal-body-section form-buttons">
                  <AppButton onClick={this.handleStartNewImport}>
                    Start new import
                  </AppButton>
                </div>
              </>
            ) : (
              <ImportPatientsForm onSubmit={this.handleImportPatientsSubmit} />
            )}
            <hr />
            <h2>Resources</h2>
            <a href="/static/CMM Patient Import Tool.pdf" target="_blank">
              Patient import tool help sheet{' '}
              <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            </a>
            <br />
            <a href="/static/patient_import_template.csv" target="_blank">
              Blank CSV template{' '}
              <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
            </a>
          </div>
        </ModalBody>
      </SimpleModal>
    );
  }

  public handleImportPatientsSubmit(
    importPatientsFormData: ImportPatientsValues,
    dispatch: Dispatch
  ) {
    return importPatients(
      this.props.practiceId,
      importPatientsFormData.file
    )(dispatch);
  }

  public handleStartNewImport() {
    this.props.resetRequestState(IMPORT_PATIENTS);
    this.props.resetRequestState(POLL_TASK);
    this.props.clearAllTaskTimeouts();
  }
}

export { ImportPatientsModal as TestableImportPatientsModal };

export const mapState = (store: StoreState) => ({
  importStarted: hasSucceeded(store.responses, IMPORT_PATIENTS),
});

const connector = connect(mapState, {
  closeModal,
  resetRequestState,
  clearAllTaskTimeouts,
});

export default connector(ImportPatientsModal);

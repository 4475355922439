import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import PageSubSection from '^/common/page-section/page-sub-section';
import { required } from '^/common/validation';
import { getContactContacts } from '^/contacts/actions';
import { PAYMENT_METHOD_OPTIONS, PaymentMethod } from '^/contacts/ledger/types';
import { ContactResponse } from '^/contacts/types';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderCorrespondenceDropdown from '^/form-helpers/render-correspondence-dropdown';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import { Membership } from '^/memberships/types';
import PaymentDetailsPicker from '^/payment-details/picker';
import { getPlanName } from '^/plans/helpers';
import SubscriptionContactsTable from '^/plans/subscriptions/subscription-contacts/subscription-contacts-table';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';
import { LapsedReason, MembershipSubscription } from './types';

/** Props that are passed into this component by the parent. */
interface OwnProps {
  contact: ContactResponse;
  membership: Membership;
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  disabledRef?: boolean;
  /** Function to run on clicking cancel button */
  onCancel(): void;
}

const selector = formValueSelector('membershipSubscriptionForm');

export const mapState = (state: StoreState, props: OwnProps) => ({
  showLapseOptions: selector(state, 'lapse'),
  startDate: moment(selector(state, 'start_date')),
  subscriptionPeriod: Number(selector(state, 'sub_length_months')),
  overrideFeesAtRenewal: selector(state, 'override_fees_at_renewal'),
  payer: selector(state, 'payer'),
  plan: selector(state, 'plan'),
  paymentMethod: selector(state, 'payment_method'),
  increasedPaymentAmount: selector(state, 'fee.new_amount'),
  start_date: selector(state, 'start_date'),
  last_renewal_date: selector(state, 'last_renewal_date'),
  renewal_date: selector(state, 'renewal_date'),
  sub_length_months: selector(state, 'sub_length_months'),
  payment_interval_months: selector(state, 'payment_interval_months'),
  contactsCache: state.contactsCache,
  contactContacts: state.contactContacts[props.contact.id],
});

const connector = connect(mapState, { getContactContacts });

/** All subscriptions form props combined. */
export type MembershipSubscriptionFormProps = OwnProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<MembershipSubscription & { lapse: boolean }, OwnProps>;

/**
 * Form that is used for both subscriptions and practices. Can be used in both a create and edit context. The prop 'onSubmit' is called when the save button is pressed.
 */
class MembershipSubscriptionForm extends React.Component<
  MembershipSubscriptionFormProps
> {
  public componentDidMount() {
    this.props.getContactContacts(this.props.contact.id);
  }

  public componentDidUpdate(prevProps: MembershipSubscriptionFormProps) {
    const { increasedPaymentAmount, showLapseOptions } = this.props;

    if (
      increasedPaymentAmount !== prevProps.increasedPaymentAmount &&
      !increasedPaymentAmount
    ) {
      this.props.change('fee.new_amount_applies', null);
    }

    if (
      showLapseOptions !== prevProps.showLapseOptions &&
      showLapseOptions === false
    ) {
      this.props.change('lapsed', false);
      this.props.change('lapsed_date', null);
      this.props.change('lapsed_reason', '');
    }

    if (
      this.props.last_renewal_date !== prevProps.last_renewal_date ||
      this.props.sub_length_months !== prevProps.sub_length_months
    ) {
      this.props.change('renewal_date', this.getRenewalDate());
    }
  }

  public render() {
    const {
      handleSubmit,
      submitting,
      actions,
      initialValues,
      contact,
      membership,
      overrideFeesAtRenewal,
      showLapseOptions: showLapseOptions,
      onCancel,
      paymentMethod,
      contactsCache,
      payer,
      startDate,
      disabledRef,
      contactContacts,
    } = this.props;

    const feesEditable = membership.variable_pricing || overrideFeesAtRenewal;

    const allRelatedContacts = [
      ...getAllRetrievedPages(contactContacts),
      getItemFromCache(payer, contactsCache),
      contact,
    ];

    const uniqueRelatedContacts = Object.values(
      allRelatedContacts.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue?.id || 'other']: currentValue,
        }),
        {}
      )
    );

    const isBillingContainerSubscription = membership.is_billing_container;

    return (
      <Form onSubmit={handleSubmit}>
        <PageSubSection vertical heading={this.generateHeading()}>
          <FormGroup
            className={classNames('spacious', { bordered: showLapseOptions })}
          >
            <Field
              component={RenderInputField}
              name="reference"
              label="Subscription reference"
              placeholder={getPlanName(membership)}
              disabled={disabledRef}
            />
            <Field
              component={RenderDateField}
              name="start_date"
              label="Start date"
              short
            />
            <Field
              component={RenderCheckBox}
              name="lapse"
              label="Lapse this subscription"
              vertical
            />
          </FormGroup>
          {showLapseOptions && (
            <>
              <p className="spacious">
                Please enter a <strong>date</strong> and <strong>reason</strong>{' '}
                for the lapse:
              </p>
              <FormGroup className="spacious">
                <Field
                  component={RenderDateField}
                  name="lapsed_date"
                  label="Lapse date"
                  short
                />
                <Field
                  component={RenderDropdown}
                  name="lapsed_reason"
                  label="Lapse reason"
                  options={[
                    {
                      label: 'Please choose...',
                      value: '',
                    },
                    {
                      label: 'Cancelled',
                      value: LapsedReason.Cancelled,
                    },
                    {
                      label: 'Ceased to trade',
                      value: LapsedReason.CeasedtoTrade,
                    },
                    {
                      label: 'Deceased',
                      value: LapsedReason.Deceased,
                    },
                    {
                      label: 'Fee increase',
                      value: LapsedReason.FeeIncrease,
                    },
                    {
                      label: 'Lapsed',
                      value: LapsedReason.Lapsed,
                    },
                    {
                      label: 'Lapsed for Adjustment',
                      value: LapsedReason.LapsedForAdjustment,
                    },
                    {
                      label: 'Not proceeding',
                      value: LapsedReason.NotProceeding,
                    },
                    {
                      label: 'Not renewed',
                      value: LapsedReason.NotRenewed,
                    },
                    {
                      label: 'Retired',
                      value: LapsedReason.Retired,
                    },
                    {
                      label: 'Settlement agreed',
                      value: LapsedReason.SettlementAgreed,
                    },
                    {
                      label: 'Sold practice',
                      value: LapsedReason.SoldPractice,
                    },
                  ]}
                />
              </FormGroup>
            </>
          )}
        </PageSubSection>
        {initialValues.id &&
          (isBillingContainerSubscription ||
            initialValues.billing_container_subscription) && (
            <SubscriptionContactsTable
              subscriptionId={initialValues.id}
              disabled={showLapseOptions}
              payer_id={payer}
              start_date={startDate}
              isBillingContainerSubscription={isBillingContainerSubscription}
              initialValues={this.props.initialValues}
            />
          )}
        <PageSubSection vertical heading="Fees">
          <FormGroup className="spacious bordered">
            <Field
              component={RenderCheckBox}
              name="override_fees_at_renewal"
              label="Override standard fees at invoice"
              type="checkbox"
              disabled={membership.variable_pricing}
            />
          </FormGroup>
          <FormGroup className="spacious bordered">
            {feesEditable ? (
              <Field
                component={RenderCurrencyField}
                name="fee.amount"
                label="Subscription fee"
                shorter
                type="number"
                stretch
              />
            ) : (
              <div className="form-field shorter">
                <label>Subscription Fee</label>
                <p>£{membership.fee && membership.fee.amount}</p>
              </div>
            )}
            {feesEditable ? (
              <Field
                component={RenderCurrencyField}
                name="joining_fee.amount"
                shorter
                type="number"
                label="Joining fee"
                stretch
              />
            ) : (
              <div className="form-field shorter">
                <label>Joining fee</label>
                <p>
                  £
                  {membership.joining_fee ? membership.joining_fee.amount : '-'}
                </p>
              </div>
            )}
          </FormGroup>
          <FormGroup className="spacious align-labels">
            <Field
              component={RenderInputField}
              name="sub_length_months"
              label="Membership length (months)"
              type="number"
              shorter
            />
            <Field
              component={RenderInputField}
              name="payment_interval_months"
              label="Invoice frequency"
              type="number"
              shorter
            />
          </FormGroup>
        </PageSubSection>
        <PageSubSection vertical heading="Renewal">
          <FormGroup className="spacious">
            <Field
              component={RenderDateField}
              name="last_renewal_date"
              label="Last renewal"
              shorter
              disabled={initialValues.billing_container_subscription}
            />
            <Field
              component={RenderDateField}
              name="renewal_date"
              label="Next renewal"
              shorter
              disabled={initialValues.billing_container_subscription}
            />
            <div className="form-field shorter">
              <label>Next payment due</label>
              <p>{this.getNextInvoiceDate()}</p>
            </div>
            <Field
              component={RenderCheckBox}
              name="auto_renew"
              label="Auto renew at expiry?"
              type="checkbox"
              disabled={initialValues.billing_container_subscription}
            />
          </FormGroup>
        </PageSubSection>
        <PageSubSection vertical heading="Payment Details">
          <FormGroup className="spacious bordered">
            <Field
              component={RenderDropdown}
              name="payment_method"
              label="Payment method"
              options={PAYMENT_METHOD_OPTIONS}
              disabled={Boolean(initialValues.billing_container_subscription)}
            />
          </FormGroup>
          <FormGroup>
            <Field
              component={PaymentDetailsPicker}
              name="payer"
              contacts={[contact.id]}
              validate={[required]}
              showAccount={
                paymentMethod === PaymentMethod.DirectDebit ||
                paymentMethod === PaymentMethod.StandingOrder
              }
              disabled={Boolean(initialValues.billing_container_subscription)}
            />
          </FormGroup>
        </PageSubSection>
        {!isBillingContainerSubscription && (
          <PageSubSection vertical heading="Promotions">
            <FormGroup className="spacious">
              <Field
                component={RenderCurrencyField}
                name="promotional_price"
                label="Promotional Price"
                type="number"
                shorter
                stretch
              />
              <Field
                component={RenderDateField}
                name="promotional_price_start_date"
                label="Promotion start date"
                type="number"
                shorter
              />
              <Field
                component={RenderInputField}
                name="promotional_price_months"
                label="Promotion months"
                type="number"
                shorter
              />
            </FormGroup>
          </PageSubSection>
        )}
        <PageSubSection vertical heading="Correspondence">
          <FormGroup className="correspondence-selector">
            <Field
              label="Reminder to"
              component={RenderCorrespondenceDropdown}
              name="reminder_to"
              contacts={uniqueRelatedContacts}
              disabled={Boolean(initialValues.billing_container_subscription)}
            />
            <Field
              label="Invoice to"
              component={RenderCorrespondenceDropdown}
              name="invoice_to"
              contacts={uniqueRelatedContacts}
              disabled={Boolean(initialValues.billing_container_subscription)}
            />
            <Field
              label="Invoice made out to"
              component={RenderCorrespondenceDropdown}
              name="invoice_made_out_to"
              contacts={uniqueRelatedContacts}
              disabled={Boolean(initialValues.billing_container_subscription)}
            />
          </FormGroup>
        </PageSubSection>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors']}
          showStatusErrors
        />
        <Allow
          roles={[
            UserRole.AdminLevel,
            UserRole.FinanceLevel,
            UserRole.OfficeLevel,
          ]}
        >
          <div className="form-buttons">
            <AppButton onClick={onCancel} disabled={submitting}>
              Cancel
            </AppButton>
            <AppButton primary type="submit" loading={submitting}>
              {initialValues && initialValues.id
                ? 'Verify Changes & Save'
                : 'Create Subscription'}
            </AppButton>
          </div>
        </Allow>
      </Form>
    );
  }

  private generateHeading = () => {
    const { initialValues, membership } = this.props;

    const title =
      membership && membership.code ? membership.code : initialValues.reference;
    const overdue =
      initialValues.overdue_amount === 0
        ? ''
        : `OVERDUE £${initialValues.overdue_amount}`;

    return `${title} - ${overdue}`;
  };

  private getRenewalDate = () => {
    if (!this.props.sub_length_months || !this.props.last_renewal_date) {
      return '';
    }

    const subLengthMonths = Number(this.props.sub_length_months);
    const lastRenewalDate = moment(this.props.last_renewal_date, 'YYYY-MM-DD');

    if (
      isNaN(subLengthMonths) ||
      subLengthMonths < 1 ||
      !lastRenewalDate.isValid() ||
      Number(subLengthMonths) >= 9999
    ) {
      return '';
    }

    return lastRenewalDate.add(subLengthMonths, 'months').format('YYYY-MM-DD');
  };

  private getNextInvoiceDate = () => {
    const payLengthMonths = Number(this.props.payment_interval_months);
    const lastRenewalDate = moment(
      this.props.initialValues.last_invoice_date || this.props.start_date,
      'YYYY-MM-DD'
    );

    if (
      isNaN(payLengthMonths) ||
      payLengthMonths < 1 ||
      !lastRenewalDate.isValid()
    ) {
      return '-';
    }

    return lastRenewalDate.add(payLengthMonths, 'months').format('DD/MM/YYYY');
  };
}

/** Disconnected version of the component that is used for testing. */
export { MembershipSubscriptionForm as TestableMembershipSubscriptionForm };

const ConnectedForm = connector(MembershipSubscriptionForm);

export const validate = (
  values: MembershipSubscription & { lapse?: boolean }
) => {
  const errors: { [field: string]: string } = {};

  if (values.lapse) {
    if (!values.lapsed_date) {
      errors.lapsed_date = 'Required';
    }

    if (!values.lapsed_reason) {
      errors.lapsed_reason = 'Required';
    }
  }

  return errors;
};

export default reduxForm<MembershipSubscription & { lapse: boolean }, OwnProps>(
  {
    form: 'membershipSubscriptionForm',
    validate,
  }
)(ConnectedForm);

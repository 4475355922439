import { isPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { BANK_OPTIONS } from '^/contacts/ledger/types';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderSortCode from '^/form-helpers/render-sort-code';
import {
  paymentDetailActions,
  VALIDATE_PAYMENT_DETAILS,
  validatePaymentDetails,
} from '^/payment-details/actions';
import { BANK_STATUS_OPTIONS } from '^/payment-details/helpers';
import {
  isLoqateValidationError,
  isLoquateValidationResponse,
  PaymentDetails,
} from '^/payment-details/types';
import { StoreState } from '^/types';

interface OwnProps {
  onCancel: () => void;
}

export type PaymentDetailsFormProps = OwnProps &
  InjectedFormProps<PaymentDetails, OwnProps> &
  ConnectedProps<typeof connector>;

const selector = formValueSelector('paymentDetailsForm');

export class PaymentDetailsForm extends React.Component<
  PaymentDetailsFormProps
> {
  public render() {
    const {
      onCancel,
      handleSubmit,
      submitting,
      pristine,
      loqateValidation,
      accountNumber,
      sortCode,
      loading,
    } = this.props;

    const validation = loqateValidation[`${accountNumber}+${sortCode}`];

    const validationErrors = [];

    if (isLoqateValidationError(validation)) {
      validationErrors.push(validation.Cause);
    } else if (isLoquateValidationResponse(validation)) {
      if (!validation.IsCorrect) {
        validationErrors.push('Sort code or account number invalid');
      }

      if (!validation.IsDirectDebitCapable) {
        validationErrors.push('Account is not direct debit capable');
      }
    }

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup className="practice-details-group">
          <Field
            label="Account name"
            name="account_name"
            component={RenderInputField}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="Sort code"
            placeholder="XX-XX-XX"
            name="sort_code"
            component={RenderSortCode}
            type="text"
          />
          <Field
            label="Account number"
            placeholder="XXXXXXXX"
            name="account_number"
            component={RenderInputField}
            type="text"
          />
          <Field
            label="Associated bank"
            name="associated_bank"
            component={RenderDropdown}
            type="dropdown"
            options={BANK_OPTIONS}
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="Current DD status"
            name="bank_status"
            component={RenderDropdown}
            type="dropdown"
            options={BANK_STATUS_OPTIONS}
          />
          <Field
            label="BACS reference"
            name="reference"
            component={RenderInputField}
            type="text"
          />
        </FormGroup>
        <ErrorRenderer
          actions={paymentDetailActions}
          fields={['non_field_errors']}
          error={validationErrors}
          showStatusErrors
        />
        <div className="form-buttons">
          <AppButton type="button" onClick={onCancel}>
            Cancel
          </AppButton>
          {validation && validationErrors.length < 1 ? (
            <AppButton
              type="submit"
              primary
              disabled={pristine}
              loading={submitting}
            >
              Save details
            </AppButton>
          ) : (
            <AppButton onClick={this.onClickValidate} loading={loading}>
              Validate
            </AppButton>
          )}
        </div>
      </Form>
    );
  }

  public onClickValidate = () =>
    this.props.validatePaymentDetails(
      this.props.accountNumber,
      this.props.sortCode
    );
}

export { PaymentDetailsForm as TestablePaymentDetailsForm };

export const mapState = (state: StoreState) => ({
  loqateValidation: state.loqateValidation,
  accountNumber: selector(state, 'account_number'),
  sortCode: selector(state, 'sort_code'),
  loading: isPending(state.responses, VALIDATE_PAYMENT_DETAILS),
});

const connector = connect(mapState, { validatePaymentDetails });

export default reduxForm<PaymentDetails, OwnProps>({
  form: 'paymentDetailsForm',
  destroyOnUnmount: true,
})(connector(PaymentDetailsForm));

import { isPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { GET_USERS, getUsers } from '^/admin/users/actions';
import { getUserName } from '^/admin/users/helpers';
import { UserResponse, UserStatus } from '^/admin/users/types';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  label?: string;
}

export type RenderUserPickerProps = OwnProps &
  ConnectedProps<typeof connector> &
  React.InputHTMLAttributes<HTMLSelectElement> &
  WrappedFieldProps;

class RenderUserPicker extends React.PureComponent<RenderUserPickerProps> {
  public componentDidMount(): void {
    this.props.getUsers(1, 10, undefined, true);
  }

  public render() {
    const { label, users, input, loading } = this.props;

    return (
      <div
        className={classnames('form-field', {
          'no-selection': !input || !input.value,
        })}
      >
        <div className="form-field-label">
          {label && <label>{label}</label>}
        </div>
        <select disabled={loading} {...input}>
          <option value="">
            {loading ? 'Loading...' : 'Please choose...'}
          </option>
          {getAllRetrievedPages(users)
            .filter((user: UserResponse) => user.status === UserStatus.Active)
            .map((user: UserResponse) => (
              <option key={user.id} value={user.id}>
                {getUserName(user)}
              </option>
            ))}
        </select>
      </div>
    );
  }
}

// Disconnected version used for testing
export { RenderUserPicker as TestableRenderUserPicker };

export const mapState = (state: StoreState) => ({
  loading: isPending(state.responses, GET_USERS),
  users: state.users,
});

const connector = connect(mapState, { getUsers });

export default connector(RenderUserPicker);

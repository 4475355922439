import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  getFormSyncErrors,
  InjectedFormProps,
  isValid,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import PageContent from '^/page-container/page-content';
import { paymentDetailActions } from '^/payment-details/actions';
import {
  isLoqateValidationError,
  isLoquateValidationResponse,
} from '^/payment-details/types';
import { StoreState } from '^/types';
import { PatientSignupFormValues } from '../types';
import {
  getPracticeClinicians as getPracticeCliniciansImport,
  getPracticePlans as getPracticePlansImport,
  PUBLIC_CREATE_NEW_PATIENT,
} from './actions';
import PatientAddSection from './section/patient-add';
import PatientConfirmationSection from './section/patient-confirmation';
import PatientDetailsSection from './section/patient-details';

export interface OwnProps {
  onConfirm: () => void;
}

export type PatientSignupFormProps = OwnProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<Partial<PatientSignupFormValues>>;

export interface PatientSignupFormCustomCSSProperties
  extends React.CSSProperties {
  '--primaryColor'?: string;
  '--secondaryColor'?: string;
}

const selector = formValueSelector('publicPatientSignupForm');

class PatientSignupForm extends React.PureComponent<PatientSignupFormProps> {
  public componentDidUpdate(prevProps: PatientSignupFormProps) {
    if (this.props !== prevProps && this.props.selectedPublicId) {
      this.props.getPracticePlans(this.props.selectedPublicId);
      this.props.getPracticeClinicians(this.props.selectedPublicId);
      this.props.change('recaptcha_token', this.props.recaptchaToken);
    }
  }

  public render() {
    const { okToSubmit, handleSubmit, onConfirm, submitting } = this.props;
    const {
      loqateValidation,
      accountNumber,
      sortCode,
      practiceDetails,
    } = this.props;

    const validation = loqateValidation[`${accountNumber}+${sortCode}`];

    const validationErrors = [];

    if (isLoqateValidationError(validation)) {
      validationErrors.push(validation.Cause);
    } else if (isLoquateValidationResponse(validation)) {
      if (!validation.IsCorrect) {
        validationErrors.push('Sort code or account number invalid');
      }

      if (!validation.IsDirectDebitCapable) {
        validationErrors.push('Account is not direct debit capable');
      }
    }

    const CSSStylingProperties: PatientSignupFormCustomCSSProperties = {
      ...(this.props.practiceDetails?.primary_colour && {
        '--primaryColor': this.props.practiceDetails?.primary_colour,
      }),
      ...(this.props.practiceDetails?.secondary_colour && {
        '--secondaryColor': this.props.practiceDetails?.secondary_colour,
      }),
    };

    return (
      <PageContent page="patient-signup">
        <Form
          className="patient-signup-form"
          onSubmit={handleSubmit}
          style={CSSStylingProperties}
        >
          <div className="logo-container">
            <img className="practice-logo" src={practiceDetails?.logo_url} />
            <p className="custom-text">{practiceDetails?.custom_text_form}</p>
          </div>
          <PatientDetailsSection />
          <PatientAddSection />
          <PatientConfirmationSection />
          <Field name="recaptcha_token" component="input" type="text" hidden />
          <div className="patient-signup-submit">
            <AppButton
              disabled={!okToSubmit}
              type="button"
              onClick={onConfirm}
              loading={submitting}
            >
              Submit
            </AppButton>
          </div>
          <ErrorRenderer
            actions={[...paymentDetailActions, PUBLIC_CREATE_NEW_PATIENT]}
            fields={['non_field_errors']}
            error={validationErrors}
            showStatusErrors
            centered
          />
        </Form>
      </PageContent>
    );
  }
}

export { PatientSignupForm as TestablePatientSignupForm };

export const mapState = (state: StoreState) => {
  const errors: any = getFormSyncErrors('publicPatientSignupForm')(state);
  const isSectionOneValid =
    !errors.title &&
    !errors.first_name &&
    !errors.last_name &&
    !errors.dob &&
    !errors.email &&
    !errors.mobile &&
    !errors.postcode &&
    !errors.street_address &&
    !errors.city &&
    !errors.county &&
    !errors.country &&
    !errors.account_name &&
    !errors.sort_code &&
    !errors.account_number;
  const isStartDateEntered = !errors.start_date;
  return {
    loqateValidation: state.loqateValidation,
    accountNumber: selector(state, 'account_number'),
    sortCode: selector(state, 'sort_code'),
    isSectionOneValid,
    selectedPublicId: state.publicPatient?.selectedPublicId,
    recaptchaToken: state.publicPatient?.recaptchaToken,
    practiceDetails: state.publicPatient?.practiceDetails,
    okToSubmit:
      isValid('publicPatientSignupForm')(state) &&
      !isPending(state.responses, PUBLIC_CREATE_NEW_PATIENT),
    isStartDateEntered,
  };
};

const connector = connect(mapState, {
  getPracticeClinicians: getPracticeCliniciansImport,
  getPracticePlans: getPracticePlansImport,
});

export default reduxForm<Partial<PatientSignupFormValues>, OwnProps>({
  form: 'publicPatientSignupForm',
  enableReinitialize: true,
})(connector(PatientSignupForm));

import React from 'react';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import { Uuid } from '^/common/types';
import { AccountsOverdue as AccountsOverdueType, Dashboard } from '../types';

export interface AccountsOverdueProps {
  data?: Dashboard['accounts_overdue'];
  loading?: boolean;
  practiceId?: Uuid;
}

export const AccountsOverdueItem = ({
  item,
}: {
  index: number;
  item: AccountsOverdueType;
}) => {
  return (
    <div className="account-overdue-item">
      <div className="account-overdue-item-value">{item.value}</div>
      <div className="account-overdue-item-patients">Patients</div>
      <div className="account-overdue-item-days">{`${item.days.from}-${item.days.to} days`}</div>
    </div>
  );
};

class AccountsOverdue extends React.PureComponent<AccountsOverdueProps> {
  public render() {
    const { data, loading, practiceId } = { ...this.props };
    if (loading) {
      return <Loading />;
    } else if (data) {
      return (
        <>
          <h4>Accounts Overdue</h4>
          <div className="chart-container">
            <div className="accounts-overdue">
              {data.map((item, index) => (
                <AccountsOverdueItem key={index} item={item} index={index} />
              ))}
            </div>
          </div>
          <AppButton
            className="iplan-app-button"
            url={`/api/iplan/dashboard/exports/accounts-overdue?practice=${practiceId}`}
          >
            {`Download data >>`}
          </AppButton>
        </>
      );
    }
    return null;
  }
}

export { AccountsOverdue as TestableAccountsOverdue };

export default AccountsOverdue;

import { anyPending } from '@dabapps/redux-requests';
import { Column, Container, Row } from '@dabapps/roe';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { ErrorPage } from '^/common/error-page';
import HeaderBar from '^/common/header-bar';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import Sidebar from '^/common/sidebar';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import { PaymentMethod } from '^/contacts/ledger/types';
import BreadcrumbBar, { BreadcrumbBarItem } from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { GET_PLAN, getPlan } from '^/plans/actions';
import { getPlanName } from '^/plans/helpers';
import {
  CREATE_PLAN_SUBSCRIPTION,
  createPlanSubscription,
} from '^/plans/subscriptions/actions';
import PlanSubscriptionForm from '^/plans/subscriptions/form';
import PlanSummaryCard from '^/plans/summary-card';
import { PlanResponse } from '^/plans/types';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';
import { API_DATE_FORMAT } from '^/utils/constants';
import { PlanSubscription } from './types';

export type SubscriptionCreatePageProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ id: string }>;

class SubscriptionCreatePage extends React.PureComponent<
  SubscriptionCreatePageProps
> {
  public componentDidMount() {
    const { planId, patientId, patient, practiceId, practice } = this.props;

    if (patientId && cachedItemHasExpired(patient)) {
      this.props.getContact(patientId);
    }

    if (practiceId && cachedItemHasExpired(practice)) {
      this.props.getContact(practiceId);
    }

    if (planId) {
      this.props.getPlan(planId);
    }
  }

  public render() {
    const { planId, plan, patientId, patient, practice, loading } = this.props;

    if (!planId || !patientId) {
      return <ErrorPage heading="Unable to load the page" />;
    }

    if (!plan || !patient) {
      if (loading.plan) {
        return (
          <PageContent>
            <BreadcrumbBar items={this.getBreadcrumbItems()} />
            <HeaderBar title="Create Plan Subscription" transparent loading />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading={`${!plan ? 'Plan' : 'Patient'} not found`} />;
    }

    return (
      <PageContent>
        <BreadcrumbBar items={this.getBreadcrumbItems()} />
        <HeaderBar
          title="Create Plan Subscription"
          subtitle={getPlanName(plan)}
          transparent
          loading={loading.plan || loading.contact}
        >
          <span>
            You are creating a subscription to{' '}
            <Link to={`/plans/${plan.id}`}>{getPlanName(plan)}</Link> at
            practice {getContactName(practice)} for patient{' '}
            {getContactName(patient)}.
          </span>
        </HeaderBar>
        <Container>
          {' '}
          <Row>
            <Column xs={12} md={7} lg={8}>
              <PageSection>
                <PlanSubscriptionForm
                  actions={[CREATE_PLAN_SUBSCRIPTION]}
                  initialValues={this.createSubscriptionFromPlan(plan)}
                  plan={plan}
                  contacts={[patient.id]}
                  onSubmit={this.handleSave}
                  onCancel={this.props.history.goBack}
                  prevContact={patient.id}
                />
              </PageSection>
            </Column>
            <Sidebar>{plan && <PlanSummaryCard plan={plan} />}</Sidebar>
          </Row>
        </Container>
      </PageContent>
    );
  }

  public handleSave = (subscription: PlanSubscription, dispatch: Dispatch) =>
    createPlanSubscription(subscription)(dispatch).then(
      this.props.history.goBack
    );

  private createSubscriptionFromPlan = (
    plan: PlanResponse
  ): Partial<PlanSubscription> => {
    const defaultStartDate = moment()
      .add(1, 'month')
      .startOf('month');

    return {
      plan: plan.id,
      start_date: defaultStartDate.format(API_DATE_FORMAT),
      sub_length_months: plan.plan_length_months,
      contacts: [],
      payment_interval_months: plan.payment_interval_months,
      payment_method: PaymentMethod.DirectDebit,
      fee: plan.fee,
      joining_fee: plan.joining_fee,
      admin_fee: plan.admin_fee,
      insprac: plan.insprac,
      override_fees_at_renewal: plan.variable_pricing,
      lapsed: false,
      auto_renew: true,
      last_renewal_date: defaultStartDate.format(API_DATE_FORMAT),
      renewal_date: defaultStartDate
        .add(plan.plan_length_months, 'months')
        .format(API_DATE_FORMAT),
      contact: this.props.patientId,
    };
  };

  private getBreadcrumbItems = (): ReadonlyArray<
    BreadcrumbBarItem | [string, string] | string
  > => {
    const { patient, practice, plan, loading } = this.props;

    return [
      ['Practices', '/practices'],
      {
        label: getContactName(practice),
        url: practice ? `/practices/${practice.id}` : undefined,
        loading: loading.contact,
      },
      {
        label: getContactName(patient),
        url: patient ? `/contacts/${patient.id}` : undefined,
        loading: loading.contact,
      },
      {
        label: getPlanName(plan),
        loading: loading.plan,
      },
    ];
  };
}

export { SubscriptionCreatePage as TestableSubscriptionsCreatePage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ id: string }>
) => {
  const queryParams = queryString.parse(props.location.search);

  const planId = queryParams.plan ? String(queryParams.plan) : undefined;
  const patientId = queryParams.patient
    ? String(queryParams.patient)
    : undefined;
  const practiceId = queryParams.practice
    ? String(queryParams.practice)
    : undefined;

  return {
    planId,
    plan: getItemFromCache(planId, state.planCache),
    patientId,
    patient: getItemFromCache(patientId, state.contactsCache),
    practiceId,
    practice: getItemFromCache(practiceId, state.contactsCache),
    loading: {
      subscription: anyPending(state.responses, [CREATE_PLAN_SUBSCRIPTION]),
      plan: anyPending(state.responses, [GET_PLAN]),
      contact: anyPending(state.responses, [GET_CONTACT]),
    },
  };
};

const connector = connect(mapState, { getPlan, getContact });

export default connector(SubscriptionCreatePage);

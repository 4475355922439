import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import { required, requiredZeroOrMore } from '^/common/validation';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderInputField from '^/form-helpers/render-input-field';
import { SubscriptionContact } from '^/plans/subscriptions/types';

/** Props that are passed into this component by the parent. */
interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. */
  generalErrorFields?: ReadonlyArray<string>;
  disableBackdating: boolean;
  /** Function to run on clicking cancel button */
  onCancel(): void;
}

/** All subscriptions form props combined. */
export type EditModalFormProps = OwnProps &
  InjectedFormProps<
    {
      start_date: SubscriptionContact['start_date'];
      backdate_months: SubscriptionContact['backdate_months'];
    },
    OwnProps
  >;

/**
 * Form that is used for both subscriptions and practices. Can be used in both a create and edit context. The prop 'onSubmit' is called when the save button is pressed.
 */
class EditModalForm extends React.Component<EditModalFormProps> {
  public render() {
    const {
      handleSubmit,
      submitting,
      onCancel,
      disableBackdating,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="modal-body-section">
          <FormGroup>
            <Field
              component={RenderDateField}
              name="start_date"
              label="Start date"
              type="number"
              validate={required}
            />
            <Field
              component={RenderInputField}
              name="backdate_months"
              label="Backdate (months)"
              validate={requiredZeroOrMore}
              type="number"
              disabled={disableBackdating}
              step="1"
            />
          </FormGroup>
          {disableBackdating && (
            <div className="errors">
              <p className="warning">
                This contact already has a collection or invoice for their
                joining fee, so you cannot backdate their subscription.
              </p>
            </div>
          )}
        </div>
        <div className="modal-body-section form-buttons">
          <AppButton onClick={onCancel} disabled={submitting}>
            Cancel
          </AppButton>
          <AppButton primary type="submit" loading={submitting}>
            Save changes
          </AppButton>
        </div>
      </Form>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { EditModalForm as TestableEditModalForm };

export default reduxForm<
  {
    start_date: SubscriptionContact['start_date'];
    backdate_months: SubscriptionContact['backdate_months'];
  },
  OwnProps
>({
  form: 'EditModalForm',
})(EditModalForm);

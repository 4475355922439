import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import { generateSorts } from '^/contacts/actions';
import { FilterList } from '^/filters/types';
import { SortList } from '^/sorts/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';
import { Product } from './types';

export const PRODUCTS_ENDPOINT = '/api/products';

export const GET_PRODUCTS = makeAsyncActionSet('GET_PRODUCTS');
export function getProducts(
  filters?: FilterList,
  sorting?: SortList,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString: string = generateQueryString({
    ...filters,
    ordering: generateSorts(sorting),
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  return (dispatch: Dispatch) =>
    request(
      GET_PRODUCTS,
      `${PRODUCTS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize } }
    )(dispatch);
}

export const GET_PRODUCT = makeAsyncActionSet('GET_PRODUCT');
export function getProduct(productId: string) {
  return (dispatch: Dispatch) => {
    request(
      GET_PRODUCT,
      `${PRODUCTS_ENDPOINT}/${productId}/`,
      'GET',
      undefined,
      {
        metaData: { productId },
      }
    )(dispatch);
  };
}

export const DELETE_PRODUCT = makeAsyncActionSet('DELETE_PRODUCT');
export function deleteProduct(productId: string) {
  return (dispatch: Dispatch) =>
    request(
      DELETE_PRODUCT,
      `${PRODUCTS_ENDPOINT}/${productId}/`,
      'DELETE',
      undefined,
      {
        metaData: { productId },
      }
    )(dispatch);
}

export const CREATE_PRODUCT = makeAsyncActionSet('CREATE_PRODUCT');
export function createProduct(product: Product) {
  return (dispatch: Dispatch) =>
    request(CREATE_PRODUCT, `${PRODUCTS_ENDPOINT}/`, 'POST', product, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch).catch(throwSubmissionError);
}

export const SAVE_PRODUCT = makeAsyncActionSet('SAVE_PRODUCT');
export function saveProduct(id: string, product: Product) {
  return (dispatch: Dispatch) =>
    request(SAVE_PRODUCT, `${PRODUCTS_ENDPOINT}/${id}/`, 'PUT', product, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch).catch(throwSubmissionError);
}

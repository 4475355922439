import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import { required } from '^/common/validation';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import {
  LapedReasonDropdownOptions,
  LapedReasonDropdownOptionsCombinedMembership,
  SubscriptionContact,
} from '^/plans/subscriptions/types';

/** Props that are passed into this component by the parent. */
interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. */
  generalErrorFields?: ReadonlyArray<string>;
  isParentBillingContainerSubscription?: boolean;
  /** Function to run on clicking cancel button */
  onCancel(): void;
}

/** All subscriptions form props combined. */
export type LapseModalFormProps = OwnProps &
  InjectedFormProps<
    {
      lapsed_date: SubscriptionContact['lapsed_date'];
      lapsed_reason: SubscriptionContact['lapsed_reason'];
    },
    OwnProps
  >;

/**
 * Form that is used for both subscriptions and practices. Can be used in both a create and edit context. The prop 'onSubmit' is called when the save button is pressed.
 */
class LapseModalForm extends React.Component<LapseModalFormProps> {
  public render() {
    const {
      handleSubmit,
      submitting,
      isParentBillingContainerSubscription,
      onCancel,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="modal-body-section">
          <FormGroup>
            <Field
              component={RenderDateField}
              name="lapsed_date"
              label="Lapse date"
              type="number"
              validate={required}
            />
            <Field
              component={RenderDropdown}
              name="lapsed_reason"
              label="Lapse reason"
              options={
                isParentBillingContainerSubscription
                  ? LapedReasonDropdownOptionsCombinedMembership
                  : LapedReasonDropdownOptions
              }
              validate={required}
            />
          </FormGroup>
          <div className="errors">
            <p className="warning">
              Please note that once lapsed, you cannot 'unlapse' and the patient
              will have to be readded to the subscription group again.
            </p>
          </div>
        </div>
        <div className="modal-body-section form-buttons">
          <AppButton onClick={onCancel} disabled={submitting}>
            Cancel
          </AppButton>
          <AppButton type="submit" loading={submitting} destructive>
            Lapse
          </AppButton>
        </div>
      </Form>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { LapseModalForm as TestableLapseModalForm };

export default reduxForm<
  {
    lapsed_date: SubscriptionContact['lapsed_date'];
    lapsed_reason: SubscriptionContact['lapsed_reason'];
  },
  OwnProps
>({
  form: 'lapseModalForm',
})(LapseModalForm);

import classnames from 'classnames';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';

interface ToggleSwitchOption {
  label: string;
  value: string | boolean | number;
}

interface OwnProps {
  options?: ReadonlyArray<ToggleSwitchOption>;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  short?: boolean;
  shorter?: boolean;
  shortest?: boolean;
}

export type RenderToggleSwitchProps = OwnProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

const defaultOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

class RenderToggleSwitch extends React.PureComponent<RenderToggleSwitchProps> {
  public render() {
    const {
      input,
      label,
      meta: { touched, error, warning, dirty, pristine },
      options = defaultOptions,
      disabled,
      short,
      shorter,
      shortest,
      defaultValue,
    } = this.props;

    return (
      <div
        id={`form-field-${input.name}`}
        className={classnames([
          'form-field',
          'render-toggle-switch',
          {
            short,
            shorter,
            shortest,
            dirty,
            pristine,
            selected: this.props.input.value,
            error: Boolean(error),
          },
        ])}
      >
        {label && <label>{label}</label>}

        <div className={classnames('toggle-switch', { disabled })}>
          {options.map(option => (
            <div
              key={`${input.name}:${String(option.value)}`}
              className="toggle-switch-button"
            >
              <input
                id={`${input.name}:${String(option.value)}`}
                name={input.name}
                checked={
                  input.value === option.value ||
                  (input.value === undefined && option.value === defaultValue)
                }
                type="radio"
                disabled={disabled}
                onChange={this.setValue.bind(this, option.value)}
                value={String(option.value)}
              />
              <label htmlFor={`${input.name}:${String(option.value)}`}>
                {option.label}
              </label>
            </div>
          ))}
        </div>

        {touched && <ErrorRenderer error={[error, warning]} />}
      </div>
    );
  }

  public setValue = (value: string | boolean | number) => {
    this.props.input.onChange(value);
  };
}

export { RenderToggleSwitch as TestableRenderToggleSwitch };

export default RenderToggleSwitch;

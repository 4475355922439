import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { GET_USERS, getUsers } from '^/admin/users/actions';
import ResendInviteModal from '^/admin/users/resend-invite-modal';
import { UserResponse } from '^/admin/users/types';
import AppButton from '^/common/app-button';
import { formatDate, toggleSortFor } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import { FilterList } from '^/filters/types';
import { openModal } from '^/modals/actions';
import TableHeaderSort from '^/sorts/table-header-sort';
import { SortList } from '^/sorts/types';
import { StoreState } from '^/types';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';
import {
  getCurrentPage,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

interface StateProps {
  appliedSorts: SortList;
}

interface OwnProps {
  filters: FilterList;
}

export type UsersTableProps = ConnectedProps<typeof connector> &
  SetPropsInterface<StateProps> &
  OwnProps;

class UsersTable extends React.PureComponent<UsersTableProps> {
  constructor(props: UsersTableProps) {
    super(props);
    this.getUsers = this.getUsers.bind(this);
  }

  public componentDidMount() {
    const { loading, users, appliedSorts, filters } = this.props;

    if (!loading && paginatedArrayHasExpired(users)) {
      this.getUsers(1, users?.pageSize, appliedSorts, undefined, filters);
    }
  }

  public getUsers(
    page: number = 1,
    pageSize: number = DEFAULT_PAGE_SIZE,
    sorting?: SortList,
    getAllPages: boolean = false,
    filters: FilterList = {}
  ) {
    this.props.getUsers(page, pageSize, sorting, getAllPages, filters);
  }

  public componentDidUpdate(prevProps: UsersTableProps) {
    if (
      this.props.appliedSorts !== prevProps.appliedSorts ||
      this.props.filters !== prevProps.filters
    ) {
      this.getUsers(
        1,
        this.props.users ? this.props.users.pageSize : undefined,
        this.props.appliedSorts,
        undefined,
        this.props.filters
      );
    }
  }

  public render() {
    const { users, loading, appliedSorts, setProps } = this.props;

    return (
      <PaginatedTable
        paginatedData={users}
        changePage={this.getUsers}
        loading={loading}
        showPaginationBar
      >
        <TableHead>
          <TableRow>
            <TableHeaderSort
              sortOrder={appliedSorts.first_name}
              onClick={toggleSortFor.bind(
                this,
                'first_name',
                appliedSorts,
                setProps
              )}
            >
              First Name
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.last_name}
              onClick={toggleSortFor.bind(
                this,
                'last_name',
                appliedSorts,
                setProps
              )}
            >
              Surname
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.role}
              onClick={toggleSortFor.bind(this, 'role', appliedSorts, setProps)}
            >
              Role
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.email}
              onClick={toggleSortFor.bind(
                this,
                'email',
                appliedSorts,
                setProps
              )}
            >
              Email
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.last_login}
              onClick={toggleSortFor.bind(
                this,
                'last_login',
                appliedSorts,
                setProps
              )}
            >
              Last logged in
            </TableHeaderSort>
            <TableHeaderSort
              sortOrder={appliedSorts.created}
              onClick={toggleSortFor.bind(
                this,
                'created',
                appliedSorts,
                setProps
              )}
            >
              Created
            </TableHeaderSort>
            <TableHeader>Status</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(users).map(user => (
            <ItemRow key={user.id} item={user} onClick={this.handleClick}>
              <TableCell>{user.first_name}</TableCell>
              <TableCell>{user.last_name}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{formatDate(user.last_login)}</TableCell>
              <TableCell>{formatDate(user.created)}</TableCell>
              <TableCell>
                {user.status === 'Invited' ? (
                  <AppButton
                    small
                    onClick={this.resendUserInvite.bind(this, user)}
                  >
                    Resend
                  </AppButton>
                ) : (
                  user.status
                )}
              </TableCell>
            </ItemRow>
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public handleClick = (user: UserResponse) => {
    this.props.push(`/admin/users/${user.id}`);
  };
  public resendUserInvite = (
    user: UserResponse,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.openModal(<ResendInviteModal userId={user.id} />);
  };
}

export { UsersTable as TestableUsersTable };

export const getInitialProps = () => ({
  appliedSorts: {},
});

export const mapState = ({ users, responses }: StoreState) => ({
  users,
  loading: anyPending(responses, [GET_USERS]),
});

const connector = connect(mapState, { getUsers, openModal, push });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(UsersTable)
);

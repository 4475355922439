import { AnyAction } from 'redux';

import {
  GET_CONTACT_RELATIONSHIPS,
  GET_ISOLATED_CONTACT_RELATIONSHIPS,
} from '^/relationships/actions';
import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { ByContact, PaginatedArray } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

export type ContactRelationships = ByContact<{
  [type: string]: PaginatedArray<RelationshipResponse>;
}>;

export function contactRelationships(
  state: ContactRelationships = {},
  action: AnyAction
): ContactRelationships {
  switch (action.type) {
    case GET_CONTACT_RELATIONSHIPS.SUCCESS:
      if (action.meta.practice) {
        return state;
      }

      const type = action.meta.type || 'all';

      const contactState = state[action.meta.contact] || {};

      return {
        ...state,
        [action.meta.contact]: {
          ...state[action.meta.contact],
          [type]: paginatedReducer(contactState[type], action),
        },
      };
    default:
      return state;
  }
}

export function isolatedContactRelationships(
  state: ContactRelationships = {},
  action: AnyAction
): ContactRelationships {
  switch (action.type) {
    case GET_ISOLATED_CONTACT_RELATIONSHIPS.SUCCESS:
      if (action.meta.practice) {
        return state;
      }

      const type = action.meta.type || 'all';

      const contactState = state[action.meta.contact] || {};

      const data = {
        ...state,
        [action.meta.contact]: {
          ...state[action.meta.contact],
          [type]: paginatedReducer(contactState[type], action),
        },
      };
      return data;
    default:
      return state;
  }
}

export function performerPatientCounts(
  state: ByContact<number> = {},
  action: AnyAction
): ByContact<number> {
  switch (action.type) {
    case GET_CONTACT_RELATIONSHIPS.SUCCESS:
      if (action.meta.type === RelationshipType.PatientPerformer) {
        const key = `${action.meta.practice}${action.meta.contact}`;

        return {
          ...state,
          [key]: action.payload.data.count,
        };
      }

      return state;
    default:
      return state;
  }
}

import { isPending } from '@dabapps/redux-requests';
import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Form,
  formValueSelector,
  getFormSyncErrors,
  InjectedFormProps,
  isValid,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import PageContent from '^/page-container/page-content';
import { paymentDetailActions } from '^/payment-details/actions';
import {
  isLoqateValidationError,
  isLoquateValidationResponse,
} from '^/payment-details/types';
import { StoreState } from '^/types';
import {
  CREATE_NEW_PATIENT,
  getPracticeClinicians as getPracticeCliniciansImport,
  getPracticePlans as getPracticePlansImport,
} from './actions';
import PatientAddSection from './section/patient-add';
import PatientConfirmationSection from './section/patient-confirmation';
import PatientDetailsSection from './section/patient-details';
import { NewPatientFormValues } from './types';

export interface OwnProps {
  onConfirm: () => void;
}

export type NewPatientFormProps = OwnProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<Partial<NewPatientFormValues>>;

const selector = formValueSelector('iplanNewPatientForm');
class NewPatientForm extends React.PureComponent<NewPatientFormProps> {
  public componentDidUpdate(prevProps: NewPatientFormProps) {
    if (this.props !== prevProps && this.props.selectedPracticeId) {
      this.props.getPracticePlans(this.props.selectedPracticeId);
      this.props.getPracticeClinicians(this.props.selectedPracticeId);
    }
  }

  public render() {
    const { okToSubmit, handleSubmit, onConfirm, submitting } = this.props;
    const { loqateValidation, accountNumber, sortCode } = this.props;

    const validation = loqateValidation[`${accountNumber}+${sortCode}`];

    const validationErrors = [];

    if (isLoqateValidationError(validation)) {
      validationErrors.push(validation.Cause);
    } else if (isLoquateValidationResponse(validation)) {
      if (!validation.IsCorrect) {
        validationErrors.push('Sort code or account number invalid');
      }

      if (!validation.IsDirectDebitCapable) {
        validationErrors.push('Account is not direct debit capable');
      }
    }
    return (
      <PageContent page="new-patient">
        <Container>
          <Form className="new-patient-form" onSubmit={handleSubmit}>
            <PatientDetailsSection />
            <PatientAddSection />
            <PatientConfirmationSection />
            <div className="new-patient-submit">
              <AppButton
                disabled={!okToSubmit}
                type="button"
                onClick={onConfirm}
                loading={submitting}
              >
                Save & Submit
              </AppButton>
            </div>
            <ErrorRenderer
              actions={[...paymentDetailActions, CREATE_NEW_PATIENT]}
              fields={['non_field_errors']}
              error={validationErrors}
              showStatusErrors
              centered
            />
          </Form>
        </Container>
      </PageContent>
    );
  }
}

export { NewPatientForm as TestableNewPatientForm };

export const mapState = (state: StoreState) => {
  const errors: any = getFormSyncErrors('iplanNewPatientForm')(state);
  const isSectionOneValid =
    !errors.title &&
    !errors.first_name &&
    !errors.last_name &&
    !errors.dob &&
    !errors.email &&
    !errors.mobile &&
    !errors.postcode &&
    !errors.street_address &&
    !errors.city &&
    !errors.county &&
    !errors.country &&
    !errors.account_name &&
    !errors.sort_code &&
    !errors.account_number;
  const isStartDateEntered = !errors.start_date;
  return {
    loqateValidation: state.loqateValidation,
    accountNumber: selector(state, 'account_number'),
    sortCode: selector(state, 'sort_code'),
    isSectionOneValid,
    selectedPracticeId: state.iplan?.selectedPracticeId,
    okToSubmit:
      isValid('iplanNewPatientForm')(state) &&
      !isPending(state.responses, CREATE_NEW_PATIENT),
    isStartDateEntered,
  };
};

const connector = connect(mapState, {
  getPracticeClinicians: getPracticeCliniciansImport,
  getPracticePlans: getPracticePlansImport,
});

export default reduxForm<Partial<NewPatientFormValues>, OwnProps>({
  form: 'iplanNewPatientForm',
  enableReinitialize: true,
})(connector(NewPatientForm));

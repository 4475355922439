import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { UserRole } from '^/admin/users/types';
import { generateQueryString } from '^/common/helper-functions';
import { Contact, ContactResponse, ContactStatus } from '^/contacts/types';
import { FilterList } from '^/filters/types';
import { RELATIONSHIPS_ENDPOINT } from '^/relationships/actions';
import { SortList } from '^/sorts/types';
import { rethrowOnAnyError } from '^/utils/action-helpers';
import { CODE_COMPANY_ID, DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const CONTACTS_ENDPOINT = '/api/contacts';
export const IPLAN_CONTACTS_PRACTICE_ENDPOINT = '/api/iplan/contacts/';
export const IPLAN_CONTACTS_PATIENT_ENDPOINT = '/api/iplan/contacts/patients';

export const GET_CONTACTS = makeAsyncActionSet('GET_CONTACTS');
export function getContacts(
  filters?: FilterList,
  sorting?: SortList,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  userRole?: UserRole
) {
  const queryString: string = generateQueryString({
    ...filters,
    ordering: generateSorts(sorting),
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  let iPlanEndpoint = IPLAN_CONTACTS_PATIENT_ENDPOINT;
  if (filters && filters.show_pending_only) {
    iPlanEndpoint += `/unverified/`;
  }
  return (dispatch: Dispatch) => {
    return request<any>(
      GET_CONTACTS,
      `${
        userRole === UserRole.PracticeAdminLevel ||
        userRole === UserRole.PracticeUserLevel
          ? iPlanEndpoint
          : CONTACTS_ENDPOINT
      }/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize, filters } }
    )(dispatch);
  };
}

export const GET_CONTACT_CONTACTS = makeAsyncActionSet('GET_CONTACTS');
export function getContactContacts(
  contact: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  return (dispatch: Dispatch) =>
    request(
      GET_CONTACT_CONTACTS,
      `${CONTACTS_ENDPOINT}/?related_contact=${contact}&relationship=CompanyProvider,CompanyContact,LinkedPractice,LinkedCompany`,
      'GET',
      undefined,
      { metaData: { contact, page, pageSize } }
    )(dispatch);
}

const getContactUrl = (
  contactId: string,
  contactType?: { isPractice: boolean }
) => {
  if (contactType) {
    return contactType.isPractice
      ? `${IPLAN_CONTACTS_PRACTICE_ENDPOINT}/${contactId}/`
      : `${IPLAN_CONTACTS_PATIENT_ENDPOINT}/${contactId}/`;
  }
  return `${CONTACTS_ENDPOINT}/${contactId}/`;
};

export const GET_CONTACT = makeAsyncActionSet('GET_CONTACT');
export function getContact(
  contactId: string,
  contactType?: { isPractice: boolean }
) {
  return (dispatch: Dispatch) =>
    request<ContactResponse>(
      GET_CONTACT,
      getContactUrl(contactId, contactType),
      'GET',
      undefined,
      { metaData: { contactId } }
    )(dispatch);
}

export const GET_PAYER_FOR_CONTACT = makeAsyncActionSet(
  'GET_PAYER_FOR_CONTACT'
);
export function getPayerForContact(contactId: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_PAYER_FOR_CONTACT,
      `${RELATIONSHIPS_ENDPOINT}/?contact=${contactId}&relationship=PayeePayer`,
      'GET',
      undefined,
      { metaData: { contactId } }
    )(dispatch);
}

export const GET_CODE_CONTACT = makeAsyncActionSet('GET_CODE_CONTACT');
export function getCodeContact() {
  return (dispatch: Dispatch) =>
    request(
      GET_CODE_CONTACT,
      `${CONTACTS_ENDPOINT}/?crm_id=${CODE_COMPANY_ID}&page=1&page_size=1`,
      'GET'
    )(dispatch);
}

/** ActionSet for createContact. */
export const CREATE_CONTACT = makeAsyncActionSet('CREATE_CONTACT');

/**
 * Creates a new contact. Sends a POST request to /api/contacts.
 * @param {Contact} contact - The new contact
 */
export function createContact(contact: Contact) {
  return (dispatch: Dispatch) => {
    return request<ContactResponse>(
      CREATE_CONTACT,
      `${CONTACTS_ENDPOINT}/`,
      'POST',
      contact,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch).catch(errors => {
      throw new SubmissionError(errors.response.data);
    });
  };
}

/** ActionSet for saveContact. */
export const SAVE_CONTACT = makeAsyncActionSet('SAVE_CONTACT');

/**
 * Updates an existing contact. Sends a PUT request to /api/contacts/{contactId}.
 * @param {string} contactId - The contact id to replace
 * @param {Contact} contact - The updated contact
 */
export function saveContact(contactId: string, contact: Contact) {
  return (dispatch: Dispatch) => {
    return request(
      SAVE_CONTACT,
      `${CONTACTS_ENDPOINT}/${contactId}/`,
      'PUT',
      contact,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch)
      .then(() => getContact(contactId)(dispatch))
      .catch(errors => {
        throw new SubmissionError(errors.response.data);
      });
  };
}

export const toggleContactStatus = (contact: ContactResponse) => {
  const toggledContact = {
    ...contact,
    status:
      contact.status !== ContactStatus.Archived
        ? ContactStatus.Archived
        : ContactStatus.Live,
  };
  return (dispatch: Dispatch) =>
    saveContact(contact.id, toggledContact)(dispatch);
};

export function generateSorts(sortList?: SortList): string | undefined {
  if (!sortList) {
    return undefined;
  }
  const sorts: string[] = Object.keys(sortList).filter(key => sortList[key]);
  const sortsWithDirection: string[] = sorts.map(sortParam => {
    const instance = sortList[sortParam];
    return instance === 'DESC' ? '-' + sortParam : sortParam;
  });
  return sortsWithDirection.length >= 1
    ? sortsWithDirection.join(',')
    : undefined;
}

export const CLEAR_CONTACTS = 'CLEAR_CONTACTS';
export const clearContacts = () => ({
  type: CLEAR_CONTACTS,
});

export const contactActions = [GET_CONTACT, GET_CONTACTS];

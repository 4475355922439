import { Membership } from './types';

export function getMembershipName(
  plan?: Membership,
  withDescription?: boolean,
  defaultValue: string = '-'
) {
  if (!plan) {
    return defaultValue;
  }

  if (withDescription && plan.description) {
    return `${plan.code} (${plan.description})`;
  }

  return plan.code;
}

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { generateQueryString } from '^/common/helper-functions';
import { Discount, FeesByAge, Plan } from '^/plans/types';
import { rethrowOnAnyError } from '^/utils/action-helpers';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const PLANS_ENDPOINT = '/api/plans';
export const DISCOUNTS_ENDPOINT = '/api/discounts';
export const FEES_BY_AGE_ENDPOINT = '/api/fees/';

export const GET_CONTACT_PLANS = makeAsyncActionSet('GET_CONTACT_PLANS');
export function getContactPlans(
  contactId: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE,
  patientId: string = '',
  showInspatIfHasSub: boolean = false
) {
  const queryParameters: { [param: string]: string | undefined } = {
    page: page.toString(),
    page_size: pageSize.toString(),
    practice: contactId.toString(),
    contact: patientId.toString(),
    show_inspat_if_has_sub: showInspatIfHasSub.toString(),
    archived: 'true',
  };

  const queryString: string = generateQueryString(queryParameters);
  return (dispatch: Dispatch) =>
    request<any>(
      GET_CONTACT_PLANS,
      `${PLANS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { contactId, page, pageSize } }
    )(dispatch);
}

export const GET_PLAN = makeAsyncActionSet('GET_PLAN');
export function getPlan(planId: string) {
  return (dispatch: Dispatch) =>
    request(GET_PLAN, `${PLANS_ENDPOINT}/${planId}/`, 'GET', undefined, {
      metaData: { planId },
    })(dispatch);
}

export const CREATE_PLAN = makeAsyncActionSet('CREATE_PLAN');
export function createPlan(plan: Plan) {
  return (dispatch: Dispatch) => {
    return request<any>(CREATE_PLAN, `${PLANS_ENDPOINT}/`, 'POST', plan, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch)
      .then(response => {
        if (response && response.data) {
          getContactPlans(response.data.practice)(dispatch);
        }

        return response;
      })
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

export const SAVE_PLAN = makeAsyncActionSet('SAVE_PLAN');
export function savePlan(planId: string, plan: Plan) {
  return (dispatch: Dispatch) => {
    return request(SAVE_PLAN, `${PLANS_ENDPOINT}/${planId}/`, 'PATCH', plan, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch)
      .then(() => getPlan(planId)(dispatch))
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

export const DELETE_PLAN = makeAsyncActionSet('DELETE_PLAN');
export function deletePlan(planId: string) {
  return (dispatch: Dispatch) => {
    return request(
      DELETE_PLAN,
      `${PLANS_ENDPOINT}/${planId}/`,
      'DELETE',
      undefined,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch)
      .then(() => getPlan(planId)(dispatch))
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

export const SAVE_DISCOUNT = makeAsyncActionSet('SAVE_DISCOUNT');
export function saveDiscount(discountId: string, discount: Discount) {
  return (dispatch: Dispatch) => {
    return request(
      SAVE_DISCOUNT,
      `${DISCOUNTS_ENDPOINT}/${discountId}/`,
      'PATCH',
      discount,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch)
      .then(response => {
        getDiscounts(discount.plan)(dispatch);
        return response;
      })
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

export const GET_DISCOUNTS = makeAsyncActionSet('GET_DISCOUNTS');
export function getDiscounts(
  planId: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryParameters: { [param: string]: string | undefined } = {
    page: page.toString(),
    page_size: pageSize.toString(),
    plan: planId.toString(),
  };

  const queryString: string = generateQueryString(queryParameters);
  return (dispatch: Dispatch) => {
    request(
      GET_DISCOUNTS,
      `${DISCOUNTS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      {
        metaData: { planId, page, pageSize },
      }
    )(dispatch);
  };
}

export const CREATE_DISCOUNT = makeAsyncActionSet('CREATE_DISCOUNT');
export function createDiscount(discount: Discount) {
  return (dispatch: Dispatch) => {
    return request(
      CREATE_DISCOUNT,
      `${DISCOUNTS_ENDPOINT}/`,
      'POST',
      discount,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch)
      .then(response => {
        getDiscounts(discount.plan)(dispatch);
        return response;
      })
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

export const GET_FEES_BY_AGE = makeAsyncActionSet('GET_FEES_BY_AGE');
export function getFeesByAge(
  planId: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryParameters: { [param: string]: string | undefined } = {
    page: page.toString(),
    page_size: pageSize.toString(),
    plan: planId.toString(),
  };

  const queryString: string = generateQueryString(queryParameters);
  return (dispatch: Dispatch) => {
    request(
      GET_FEES_BY_AGE,
      `${FEES_BY_AGE_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      {
        metaData: { planId, page, pageSize },
      }
    )(dispatch);
  };
}

export const SAVE_FEES_BY_AGE = makeAsyncActionSet('SAVE_FEES_BY_AGE');
export function saveFeesByAge(feeId: string, fee: FeesByAge) {
  return (dispatch: Dispatch) => {
    return request(
      SAVE_FEES_BY_AGE,
      `${FEES_BY_AGE_ENDPOINT}/${feeId}/`,
      'PATCH',
      fee,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch)
      .then(response => {
        getFeesByAge(fee.plan)(dispatch);
        return response;
      })
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

export const CREATE_FEES_BY_AGE = makeAsyncActionSet('CREATE_FEES_BY_AGE');
export function createFeesByAge(fee: FeesByAge) {
  return (dispatch: Dispatch) => {
    return request(
      CREATE_FEES_BY_AGE,
      `${FEES_BY_AGE_ENDPOINT}/`,
      'POST',
      fee,
      {
        shouldRethrow: rethrowOnAnyError,
      }
    )(dispatch)
      .then(response => {
        getFeesByAge(fee.plan)(dispatch);
        return response;
      })
      .catch(errors => {
        if (errors.response.data) {
          throw new SubmissionError(errors.response.data);
        }
      });
  };
}

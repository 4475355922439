import { isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import { isRoleAgilioType } from '^/admin/users/helpers';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import { ContactResponse } from '^/contacts/types';
import {
  GET_CONTACT_RELATIONSHIPS,
  getContactRelationships,
} from '^/relationships/actions';
import { getRelationshipsFromStore } from '^/relationships/helper-functions';
import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  practiceId: string;
  practicePublicId: string;
  planId: string;
}

export type ClinicianPresetUrlsTableProps = OwnProps &
  ConnectedProps<typeof connector>;

class ClinicianPresetUrlsTable extends React.PureComponent<
  ClinicianPresetUrlsTableProps
> {
  public componentDidMount(): void {
    const { practiceId, relationships, loading } = this.props;

    if (!loading.relationships && paginatedArrayHasExpired(relationships)) {
      this.props.getContactRelationships(practiceId, [
        RelationshipType.PerformerPractice,
      ]);
    }
  }

  public render() {
    const { practiceId, relationships, loading } = this.props;

    if (loading.relationships) {
      return <Loading />;
    }

    const flattenedRelationships = getAllRetrievedPages(relationships);

    return (
      <div className="relationships list-card">
        <table>
          <tbody>
            {flattenedRelationships.length > 0 ? (
              flattenedRelationships.map(relationship => {
                const clinician: ContactResponse = this.getRelevantContact(
                  relationship,
                  practiceId
                );
                const presetSignUpUrl = this.getPreselectedSignupURL(
                  clinician.id
                );
                const clinicianName = getContactName(clinician, true);

                return (
                  <tr key={relationship.id}>
                    <th>
                      {isRoleAgilioType(this.props.userRole) ? (
                        <Link to={`/contacts/${clinician.id}`}>
                          {clinicianName}
                        </Link>
                      ) : (
                        clinicianName
                      )}
                    </th>
                    <td>
                      <a href={presetSignUpUrl}>{presetSignUpUrl}</a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div className="empty-state">No clinicians</div>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  private getRelevantContact(
    relationship: RelationshipResponse,
    practiceId: string
  ): ContactResponse {
    return relationship.related_contact === practiceId
      ? relationship.contact_detail
      : relationship.related_contact_detail;
  }

  private getPreselectedSignupURL(clinicianId: string): string {
    const { practicePublicId, planId } = this.props;
    return `${window.SITE_URL}/portal/${practicePublicId}?plan=${planId}&clinician=${clinicianId}`;
  }
}

// Disconnected version used for testing
export { ClinicianPresetUrlsTable as TestableClinicianPresetUrlsTable };

export const mapState = (state: StoreState, props: OwnProps) => ({
  userRole: state.loggedInUser!.role,
  relationships: getRelationshipsFromStore(
    state,
    props.practiceId,
    RelationshipType.PerformerPractice,
    null
  ),
  loading: {
    relationships: isPending(
      state.responses,
      GET_CONTACT_RELATIONSHIPS,
      RelationshipType.PerformerPractice
    ),
  },
});

const connector = connect(mapState, {
  getContactRelationships,
});

export default connector(ClinicianPresetUrlsTable);

import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import PracticeSelect from '^/form-helpers/practice-select';
import RenderDateField from '^/form-helpers/render-date-field';
import ReportTypeSelect from '^/routines/report-type-select';
import { StoreState } from '^/types';
import {
  CSV_REPORT_TYPE_REQUIRE_DATE,
  CSV_REPORT_TYPE_REQUIRE_MONTH,
} from './constants';

const FORM_NAME = 'RoutinesCSVReportForm';

export interface CSVReportFormOptions {
  reportType?: string;
  practiceFilter?: string;
  start_date?: string;
  end_date?: string;
  month?: string;
  showPracticeSelector?: boolean;
  showDates?: boolean;
}

const selector = formValueSelector(FORM_NAME);

export const mapState = (store: StoreState) => {
  const reportType: string | undefined = selector(store, 'reportType');

  return {
    disabled: !Boolean(reportType),
    requiresDate: CSV_REPORT_TYPE_REQUIRE_DATE.includes(reportType || ''),
    requiresMonth: CSV_REPORT_TYPE_REQUIRE_MONTH.includes(reportType || ''),
  };
};

const connector = connect(mapState);
export type CSVReportFormProps = InjectedFormProps &
  CSVReportFormOptions &
  ConnectedProps<typeof connector>;

export const required = (value: string | undefined) =>
  value ? undefined : 'This field is required';

class CSVReportForm extends React.PureComponent<CSVReportFormProps> {
  public render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <FormGroup className="routine-reports align-items-end">
          <Field
            name="reportType"
            label="Report type"
            component={ReportTypeSelect}
          />
          {this.props.showPracticeSelector && (
            <Field
              name="practiceFilter"
              label="Practice (optional)"
              component={PracticeSelect}
            />
          )}
          {this.props.requiresDate && (
            <>
              <Field
                name="start_date"
                label="Run from:"
                component={RenderDateField}
                validate={required}
              />
              <Field
                name="end_date"
                label="Run to:"
                component={RenderDateField}
                validate={required}
              />
            </>
          )}
          {this.props.requiresMonth && (
            <Field
              label="Month"
              name="month"
              component={RenderDateField}
              pickerProps={{
                dateFormat: 'MMMM yyyy',
                showMonthDropdown: false,
                showYearDropdown: false,
                showMonthYearPicker: true,
              }}
              showMonthYearPicker
              placeholder="Month YYYY"
              clearable={false}
              validate={required}
            />
          )}
          <div className="margin-small">
            <AppButton type="submit" disabled={this.props.disabled}>
              GENERATE REPORT
            </AppButton>
          </div>
        </FormGroup>
      </Form>
    );
  }
}

export { CSVReportForm as TestableCSVReportForm };

export default reduxForm<{}, CSVReportFormOptions>({
  form: FORM_NAME,
})(connector(CSVReportForm));

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import HeaderBar from '^/common/header-bar';
import PageContent from '^/page-container/page-content';

export type NotFoundPageProps = RouteComponentProps;

class NotFoundPage extends React.PureComponent<NotFoundPageProps> {
  public render() {
    return (
      <PageContent>
        <HeaderBar title="Page not found" transparent>
          <span>
            This page couldn't be found. Check your URL or{' '}
            <Link to="/">go back</Link>.
          </span>
        </HeaderBar>
      </PageContent>
    );
  }
}

export default NotFoundPage;

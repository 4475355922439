import { TableCell, TableRow } from '@dabapps/roe';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AppButton from '^/common/app-button';
import { getContactName } from '^/common/helper-functions';
import { getContact } from '^/contacts/actions';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import { removeContactFromBACS } from '../actions';
import { BACSSubmissionEntry } from '../types';

// Props that come from the parent component.
interface OwnProps {
  entry: BACSSubmissionEntry;
}

export type BACSSubmissionRowProps = OwnProps &
  ConnectedProps<typeof connector>;

class BACSSubmissionRow extends React.PureComponent<BACSSubmissionRowProps> {
  public componentDidMount() {
    const { entry } = this.props;

    this.props.getContact(entry.contact);
  }

  public render() {
    const { entry, contact } = this.props;
    return (
      <TableRow>
        <TableCell>{contact?.crm_id || '…'}</TableCell>
        <TableCell>{getContactName(contact)}</TableCell>
        <TableCell className="align-right">
          {formatFeeAmount(entry.amount)}
        </TableCell>
        <TableCell className="align-right">{entry.code}</TableCell>
        <TableCell>
          <AppButton icon={faTrash} small onClick={this.handleDeleteClicked} />
        </TableCell>
      </TableRow>
    );
  }

  public handleDeleteClicked = () => {
    this.props.removeContactFromBACS(this.props.entry);
  };
}

// Disconnected version used for testing
export { BACSSubmissionRow as TestableBACSSubmissionRow };

export const mapState = (state: StoreState, props: OwnProps) => ({
  contact: getItemFromCache(props.entry.contact, state.contactsCache),
});

const mapDispatch = {
  getContact,
  removeContactFromBACS,
};

const connector = connect(mapState, mapDispatch);

export default connector(BACSSubmissionRow);

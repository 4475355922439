import { Column, Container, Row } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import AppButton from '^/common/app-button';
import RecentlyViewedContactsTable from '^/common/recently-viewed/recently-viewed-contacts';
import { clearContacts, getContacts } from '^/contacts/actions';
import ContactsFilterForm from '^/contacts/filter-form';
import ContactsTable from '^/contacts/table';
import { CategoryType, ContactResponse } from '^/contacts/types';
import { FilterList } from '^/filters/types';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';

export type PatientsListPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

class PatientsListPage extends React.PureComponent<PatientsListPageProps> {
  public componentWillUnmount() {
    this.props.clearContacts();
  }

  public render() {
    const { contacts, location } = this.props;
    const filters = queryString.parse(location.search);
    return (
      <PageContent>
        <Container>
          <Row>
            <Column xs={12} lg={3}>
              <div className="filter-section">
                <ContactsFilterForm
                  showBacsRef={false}
                  showPendingOnly
                  hideCategory
                  showCompanyName={false}
                  initialValues={filters}
                  onSubmit={this.handleFiltersSubmit}
                  onClear={this.clear}
                  enableReinitialize
                />
              </div>
            </Column>
            <Column xs={12} lg={9}>
              {contacts ? (
                <ContactsTable
                  showCategory={false}
                  showClinician
                  onSelect={this.onSelect}
                  actions={
                    <AppButton onClick={this.onAddPatient}>
                      Add Patient
                    </AppButton>
                  }
                />
              ) : (
                <RecentlyViewedContactsTable
                  showCategory={false}
                  showClinician
                  onSelect={this.onSelect}
                  actions={
                    <AppButton onClick={this.onAddPatient}>
                      Add Patient
                    </AppButton>
                  }
                />
              )}
            </Column>
          </Row>
        </Container>
      </PageContent>
    );
  }

  public handleFiltersSubmit = (filters: FilterList, dispatch: Dispatch) => {
    filters.practice = this.props.selectedPractice;
    getContacts(
      filters,
      undefined,
      undefined,
      undefined,
      this.props.userRole
    )(dispatch);
  };

  private onSelect = (contact: ContactResponse) => {
    this.props.history.push(`patients/${contact.id}/details`);
  };

  private onAddPatient = () => this.props.history.push('/patients/new-patient');

  private clear = () => {
    this.props.getContacts(
      {
        related_contact: this.props.selectedPractice,
        category: CategoryType.Patient,
      },
      undefined,
      undefined,
      undefined,
      this.props.userRole
    );
  };
}

export { PatientsListPage as TestablePatientsListPage };

export const mapState = (state: StoreState) => ({
  userRole: state.loggedInUser?.role,
  contacts: state.contacts,
  selectedPractice: state.iplan?.selectedPracticeId,
});

const connector = connect(mapState, { clearContacts, getContacts });

export default connector(PatientsListPage);

import React from 'react';

import AppButton, { AppButtonProps } from './app-button';

// Props that come from the parent component.
interface OwnProps<T> extends Omit<AppButtonProps, 'onClick'> {
  item: T;
  onClick(item: T): void;
}

export type ItemButtonProps<T> = OwnProps<T>;

class ItemButton<T> extends React.PureComponent<ItemButtonProps<T>> {
  public render() {
    const props = {
      ...this.props,
      onClick: this.handleClick,
    };

    return <AppButton {...props}>{this.props.children}</AppButton>;
  }

  public handleClick = () => this.props.onClick(this.props.item);
}

// Disconnected version used for testing
export { ItemButton as TestableItemButton };

export default ItemButton;

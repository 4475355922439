import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import AppButton from '^/common/app-button';
import HeaderBar from '^/common/header-bar';
import { generateParamsSummary } from '^/common/helper-functions';
import PageContent from '^/page-container/page-content';
import { getBulkReportDetail } from '^/routines/actions';
import { StoreState } from '^/types';
import { BulkReport } from './types';

interface OwnProps {
  bulkReportId?: string;
  bulkReport: BulkReport | null;
}
type RouteProps = RouteComponentProps<{ id: string }>;

export type BulkReportPageProps = OwnProps &
  RouteProps &
  ConnectedProps<typeof connector>;

class BulkReportPage extends React.PureComponent<BulkReportPageProps> {
  public componentDidMount() {
    const { bulkReportId } = this.props;
    if (bulkReportId) {
      this.props.getBulkReportDetail(bulkReportId);
    }
  }

  public render() {
    const { bulkReportId, bulkReport } = this.props;

    return (
      <PageContent>
        <HeaderBar title="Bulk Report Download" />
        <Container>
          {bulkReport && (
            <>
              <h3>
                {bulkReport.report_type} -{' '}
                {generateParamsSummary(bulkReport.params)}
              </h3>
              {bulkReport.zipped_reports ? (
                <AppButton url={`/api/reports/${bulkReportId}/bulk-report/`}>
                  Download reports
                </AppButton>
              ) : (
                <div>No zip file was created.</div>
              )}
              <br />
              {bulkReport.failed_companies &&
              bulkReport.failed_companies.length > 0 ? (
                <>
                  <p>
                    A report was generated for all but the following practices{' '}
                    <em>
                      (Go to <Link to="/routines/reports/">reports</Link> to try
                      and generate them again one by one):
                    </em>
                  </p>
                  <ul>
                    {bulkReport.failed_companies.map(companyName => (
                      <li key={companyName}>{companyName}</li>
                    ))}
                  </ul>
                </>
              ) : (
                <div>All reports were successfully generated</div>
              )}
            </>
          )}
        </Container>
      </PageContent>
    );
  }
}

export { BulkReportPage as TestableBulkReportPage };

export const mapState = (state: StoreState, props: RouteProps) => ({
  bulkReport: state.bulkReport,
  bulkReportId: props.match.params.id,
});

const connector = connect(mapState, { getBulkReportDetail });

export default connector(BulkReportPage);

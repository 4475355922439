import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import { ContactResponse, ContactStatus } from '^/contacts/types';
import { closeModal, openModal } from '^/modals/actions';
import ArchiveContactModal from './archive-contact-modal';

// Props that come from the parent component.
interface OwnProps {
  contact: ContactResponse;
}

export type ArchiveContactProps = OwnProps & ConnectedProps<typeof connector>;

class ArchiveContact extends React.PureComponent<ArchiveContactProps> {
  public render() {
    const { contact } = this.props;

    return (
      <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
        <AppButton key="archive" onClick={this.showModal} small>
          {contact.status !== ContactStatus.Archived ? 'Archive' : 'Unarchive'}
        </AppButton>
      </Allow>
    );
  }

  public showModal = () => {
    const { contact } = this.props;

    this.props.openModal(
      <ArchiveContactModal
        contact={contact}
        action={
          contact.status !== ContactStatus.Archived ? 'Archive' : 'Unarchive'
        }
        closeModal={this.props.closeModal}
      />
    );
  };
}

// Disconnected version used for testing
export { ArchiveContact as TestableArchiveContact };

const connector = connect(undefined, {
  openModal,
  closeModal,
});

export default connector(ArchiveContact);

import React from 'react';
import useSWR from 'swr';

import fetcher from '^/common/fetcher';
import PageSubSection from '^/common/page-section/page-sub-section';
import { ContactResponse, getContactCategoryName } from '^/contacts/types';

const CliniciansCard: React.FC = () => {
  const { data, isValidating } = useSWR<ReadonlyArray<ContactResponse>>(
    '/api/iplan/clinicians/',
    fetcher
  );

  const clinicians = data || [];

  return (
    <PageSubSection heading="Clinicians" loading={isValidating}>
      <div className="relationships list-card">
        <table>
          <tbody>
            {clinicians.map(clinician => {
              return (
                <tr key={clinician.id}>
                  <th>{clinician.name}</th>
                  <td>{getContactCategoryName(clinician)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </PageSubSection>
  );
};

export default CliniciansCard;

import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dispatch } from 'redux';

import AppButton from '^/common/app-button';
import { patchSubscriptionContact } from '^/plans/subscriptions/actions';
import { SubscriptionContact } from '^/plans/subscriptions/types';
import LapseModalForm from './lapse-modal-form';

// Props that come from the parent component.
interface OwnProps {
  heading?: string;
  subscriptionContact: Required<SubscriptionContact>;
  isParentBillingContainerSubscription?: boolean;
  onCloseModal(): void;
}

export type LapseModalProps = OwnProps;

class LapseModal extends React.PureComponent<LapseModalProps> {
  public render() {
    const {
      heading = 'Lapse Subscription',
      subscriptionContact,
      isParentBillingContainerSubscription,
    } = this.props;

    return (
      <Modal onClickOutside={this.props.onCloseModal}>
        <ModalHeader>
          <h2>{heading}</h2>
          <AppButton transparent onClick={this.props.onCloseModal}>
            <FontAwesomeIcon icon="times" />
          </AppButton>
        </ModalHeader>
        {this.props.children}
        <ModalBody>
          <LapseModalForm
            onSubmit={this.handleSubmit}
            onCancel={this.props.onCloseModal}
            isParentBillingContainerSubscription={
              isParentBillingContainerSubscription
            }
            initialValues={{
              lapsed_reason: subscriptionContact.lapsed_reason,
              lapsed_date: subscriptionContact.lapsed_date,
            }}
          />
        </ModalBody>
      </Modal>
    );
  }

  public handleSubmit = (
    lapseData: {
      lapsed_date: SubscriptionContact['lapsed_date'];
      lapsed_reason: SubscriptionContact['lapsed_reason'];
    },
    dispatch: Dispatch
  ) =>
    patchSubscriptionContact(
      lapseData,
      this.props.subscriptionContact.id,
      this.props.subscriptionContact.subscription,
      this.props.subscriptionContact.contact
    )(dispatch).then(() => {
      this.props.onCloseModal();
      window.location.reload();
    });
}

// Disconnected version used for testing
export { LapseModal as TestableLapseModal };

export default LapseModal;

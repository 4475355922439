import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import AppButton from '^/common/app-button';
import ConfirmationModal from '^/common/confirmation-modal';
import { formatDate } from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import { SectionDetail } from '^/common/section-detail';
import SectionDetailList from '^/common/section-detail-list';
import {
  calculateGrossFromGoods,
  calculateVatFromGoods,
  formatVatRate,
} from '^/fees/utils';
import {
  createPracticeInsurance,
  deletePracticeInsurance,
  GET_PRACTICE_INSURANCE,
  getPracticeInsurance,
  savePracticeInsurance,
} from '^/insurance/actions';
import PracticeInsuranceForm from '^/insurance/practice-insurance-form';
import { Insurance } from '^/insurance/types';
import { closeModal, openModal } from '^/modals/actions';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';

export enum InspracMode {
  List = 'LIST',
  Edit = 'EDIT',
}

interface OwnProps {
  contactId: string;
  disabled?: boolean;
}

interface StateProps {
  mode?: InspracMode;
}

export type InspracProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class InspracCard extends React.PureComponent<InspracProps> {
  public componentDidMount() {
    const { practiceInsurance, contactId } = this.props;

    if (!practiceInsurance) {
      this.props.getPracticeInsurance(contactId);
    }
  }

  public render() {
    const { practiceInsurance, mode, isLoading, disabled } = this.props;

    if (isLoading && !practiceInsurance) {
      return <Loading />;
    }

    if (mode === InspracMode.List && !practiceInsurance) {
      return (
        <div className="flex-row">
          <div className="empty-state">Not configured</div>
          <div className="page-subsection-buttons">
            <AppButton small onClick={this.setEditMode} disabled={disabled}>
              Add
            </AppButton>
          </div>
        </div>
      );
    }

    if (mode === InspracMode.List && practiceInsurance) {
      const goods = practiceInsurance.monthly_fee?.amount;
      const code = practiceInsurance.monthly_fee?.vat_code;
      const vat = goods ? calculateVatFromGoods(goods, code) : null;
      const gross = goods ? calculateGrossFromGoods(goods, code) : null;

      return (
        <div className="flex-row">
          <SectionDetailList>
            <SectionDetail label="Total chargeable">
              {formatFeeAmount(gross)}
            </SectionDetail>
            <SectionDetail label="Goods amount">
              {formatFeeAmount(goods)}
            </SectionDetail>
            <SectionDetail label="VAT code">
              {formatVatRate(code)}
            </SectionDetail>
            <SectionDetail label="VAT amount">
              {formatFeeAmount(vat)}
            </SectionDetail>
            <SectionDetail label="Increase Amount">
              {formatFeeAmount(practiceInsurance.monthly_fee?.new_amount)}
            </SectionDetail>
            <SectionDetail label="Increase Date">
              {formatDate(practiceInsurance.monthly_fee?.new_amount_applies)}
            </SectionDetail>
          </SectionDetailList>
          <div className="page-subsection-buttons">
            <AppButton small onClick={this.setEditMode} disabled={disabled}>
              Edit
            </AppButton>
            <AppButton onClick={this.onRemove} small disabled={disabled}>
              Remove
            </AppButton>
          </div>
        </div>
      );
    }

    if (mode === InspracMode.Edit) {
      return (
        <PracticeInsuranceForm
          onSubmit={this.handleSaveInsurance}
          initialValues={practiceInsurance}
          onCancel={this.setListMode}
        />
      );
    }
  }

  public onRemove = () => {
    const { practiceInsurance } = this.props;

    if (practiceInsurance) {
      const deletePracticeInsuranceForId = () =>
        this.props
          .deletePracticeInsurance(practiceInsurance)
          .then(() => this.setListMode());

      this.props.openModal(
        <ConfirmationModal
          heading="Are you sure?"
          message="INSPRAC details will be removed from this practice"
          action={deletePracticeInsuranceForId}
          closeModal={this.props.closeModal}
          actionLabel="Delete"
        />
      );
    }
  };

  /**
   * Fires off the action that will save this Insurance record
   * @param {Insurance} insprac - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public handleSaveInsurance = (insprac: Insurance, dispatch: Dispatch) => {
    if (insprac.id) {
      return savePracticeInsurance(
        insprac.id,
        insprac
      )(dispatch).then(this.setListMode);
    }

    return createPracticeInsurance({
      ...insprac,
      practice: this.props.contactId,
    })(dispatch).then(this.setListMode);
  };

  public setEditMode = () => {
    this.props.setProps({
      mode: InspracMode.Edit,
    });
  };

  public setListMode = () => {
    this.props.setProps({
      mode: InspracMode.List,
    });
  };
}

export { InspracCard as TestableInspracCard };

export const getInitialProps = () => ({
  mode: InspracMode.List,
});

export const mapState = (store: StoreState, props: OwnProps) => ({
  practiceInsurance: store.practiceInsurance[props.contactId],
  isLoading: anyPending(store.responses, [GET_PRACTICE_INSURANCE]),
});

const connector = connect(mapState, {
  getPracticeInsurance,
  openModal,
  closeModal,
  deletePracticeInsurance,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(InspracCard)
);

import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import PatientsCreatePage from './create-page';
import PatientsDetailPage from './detail-page';
import UnverifiedPatientsDetailPage from './unverified-detail-page';

class PatientsRoutes extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/create`} component={PatientsCreatePage} />
        <Route
          path={`${match.path}/unverified/:contactId`}
          component={UnverifiedPatientsDetailPage}
        />
        <Route
          path={`${match.path}/:contactId`}
          component={PatientsDetailPage}
        />
        <Route component={this.redirect} />
      </Switch>
    );
  }

  public redirect = () => <Redirect to="/contacts" />;
}

export default PatientsRoutes;

import { AnyAction } from 'redux';

import { GET_MEMBERSHIPS_SUMMARY } from '^/dashboard/actions';
import { MembershipsSummaryResponse } from '^/dashboard/types';

export function membershipsSummary(
  state: MembershipsSummaryResponse | null = null,
  action: AnyAction
): MembershipsSummaryResponse | null {
  switch (action.type) {
    case GET_MEMBERSHIPS_SUMMARY.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}

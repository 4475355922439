import { Container } from '@dabapps/roe';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import PageSection from '^/common/page-section/page-section';
import PageContent from '^/page-container/page-content';
import { uuidRegex } from '^/utils/commons';
import PublicErrorPage from './common/error-page';
import PortalHeader from './common/portal-header';
import PublicPatientLanding from './patient-landing-page';
import PublicPatientSignupPage from './patient-signup/patient-signup-page';
import PublicPatientSignupPageSuccess from './patient-signup/patient-signup-page-success';

class PublicPatientsRoutesPage extends React.PureComponent<
  RouteComponentProps
> {
  public render() {
    const { match } = this.props;
    const renderRedirect = () => <Redirect to={`${match.path}/404`} />;

    return (
      <PageContent>
        <PortalHeader />
        <Container className="portal">
          <PageSection>
            <Switch>
              <Route
                exact
                path={`${match.path}/:publicId(${uuidRegex})`}
                component={PublicPatientLanding}
              />
              <Route
                exact
                path={`${match.path}/patient/signup`}
                component={PublicPatientSignupPage}
              />
              <Route
                exact
                path={`${match.path}/patient/signup/success`}
                component={PublicPatientSignupPageSuccess}
              />
              <Route
                exact
                path={`${match.path}/404`}
                component={PublicErrorPage}
              />
              <Route render={renderRedirect} />
            </Switch>
          </PageSection>
        </Container>
      </PageContent>
    );
  }
}

export { PublicPatientsRoutesPage as TestablePublicPatientsRoutesPage };

export default PublicPatientsRoutesPage;

import { AnyAction } from 'redux';

import { GET_PRACTICE_FOR_PATIENT } from '^/contacts/persons/patients/actions';
import { CREATE_RELATIONSHIP } from '^/relationships/actions';
import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';

export function patientPracticeMapping(
  state: StoreState['patientPracticeMapping'] = {},
  action: AnyAction
): StoreState['patientPracticeMapping'] {
  switch (action.type) {
    case GET_PRACTICE_FOR_PATIENT.SUCCESS:
      const result =
        action.payload.data &&
        action.payload.data.results &&
        action.payload.data.results[0];

      if (!result) {
        return state;
      }

      return {
        ...state,
        [action.meta.patientId]: result.id,
      };
    case CREATE_RELATIONSHIP.SUCCESS:
      const relationshipResponse: RelationshipResponse = action.payload.data;
      if (
        relationshipResponse &&
        relationshipResponse.relationship === RelationshipType.PatientPractice
      ) {
        return {
          ...state,
          [relationshipResponse.contact]: relationshipResponse.related_contact,
        };
      }
      return state;
    default:
      return state;
  }
}

import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { isChecked } from '^/common/validation';
import RenderCheckBox from '^/form-helpers/render-checkbox';

const Declaration = () => {
  return (
    <PageSection>
      <span className="confirm-account-holder">
        <FormGroup>
          In order to set up a Direct Debit Instruction online you must be the
          account holder of a personal bank or building society account. If you
          are NOT the only required signatory on your account you can access the
          Direct Debit PDF form here.
        </FormGroup>
        <FormGroup>
          <Field
            name="direct_debit_declaration"
            label="Please tick to confirm you are the account holder and sole person required to authorise debits on the account."
            component={RenderCheckBox}
            validate={[isChecked]}
          />
        </FormGroup>
      </span>
    </PageSection>
  );
};

export { Declaration as TestableDeclaration };

export default Declaration;

import { ContactResponse } from '^/contacts/types';

export enum UserRole {
  AdminLevel = 'Admin Level',
  FinanceLevel = 'Finance Level',
  OfficeLevel = 'Office Level',
  SalesLevel = 'Sales Level',
  PracticeAdminLevel = 'Practice Admin Level',
  PracticeUserLevel = 'Practice User Level',
  PracticePatientLevel = 'Practice Patient Level',
}

export interface InitialUser {
  first_name?: string;
  last_name?: string;
  email?: string;
  role?: UserRole;
}

export enum UserStatus {
  Active = 'Active',
  Invited = 'Invited',
  Locked = 'Locked',
  Archived = 'Archived',
}

export interface User {
  readonly status?: UserStatus;
  first_name?: string;
  last_name?: string;
  readonly full_name?: string;
  readonly id?: string;
  email: string;
  phone?: string | null;
  role: UserRole;
  readonly created?: string;
  readonly modified?: string;
  readonly is_superuser?: boolean;
  readonly last_login?: string;
  practices?: ReadonlyArray<string>;
}

export type UserResponse = Readonly<{
  status: UserStatus;
  first_name: string;
  last_name: string;
  full_name: string;
  id: string;
  email: string;
  phone: string | null;
  role: UserRole;
  created: string;
  modified: string;
  is_superuser: boolean;
  last_login: string;
  practices: ReadonlyArray<ContactResponse>;
}>;

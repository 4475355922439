import { isPending } from '@dabapps/redux-requests';
import {
  Modal,
  ModalBody,
  ModalHeader,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';

import CollectionForm from '^/collections/collection-form';
import AppButton from '^/common/app-button';
import { formatDate } from '^/common/helper-functions';
import PaginatedTable from '^/common/paginated-table';
import ItemRow from '^/common/paginated-table/item-row';
import { closeModal, openModal } from '^/modals/actions';
import {
  formatFeeAmount,
  getPlanName,
  getSubscriptionName,
} from '^/plans/helpers';
import { StoreState } from '^/types';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';
import { getCurrentPage } from '^/utils/pagination-helpers';
import {
  GET_COLLECTIONS,
  getCollection,
  getCollections,
  saveCollection,
} from './actions';
import DeleteCollectionButton from './delete-collection-button';
import { Collection, CollectionResponse, CollectionStatus } from './types';

// Props that come from the parent component.
interface OwnProps {
  contact: string;
  isHeadOffice?: boolean;
}

export type CollectionsTableProps = OwnProps & ConnectedProps<typeof connector>;

class CollectionsTable extends React.PureComponent<CollectionsTableProps> {
  public componentDidMount() {
    this.changePage();
  }

  public render() {
    const { collections, loading } = this.props;

    return (
      <PaginatedTable
        paginatedData={collections}
        changePage={this.changePage}
        loading={loading}
      >
        <TableHead>
          <TableRow>
            <TableCell>Subscription</TableCell>
            <TableCell className="align-right">Total (inc. fees)</TableCell>
            <TableCell className="align-right">Collection date</TableCell>
            <TableCell className="align-right">Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPage(collections).map(collection => (
            <ItemRow
              key={collection.id}
              item={collection}
              onClick={this.handleRowClicked}
            >
              <TableHeader
                title={getPlanName(
                  collection.subscription_detail
                    ? collection.subscription_detail.plan_detail
                    : null
                )}
              >
                {getSubscriptionName(collection.subscription_detail)}
              </TableHeader>
              <TableCell className="align-right">
                {formatFeeAmount(
                  collection.total_amount + collection.total_vat_amount
                )}
              </TableCell>
              <TableCell className="align-right">
                {formatDate(collection.collection_date)}
              </TableCell>
              <TableCell
                className={classNames('align-right', {
                  error: this.shouldError(collection),
                })}
              >
                {collection.status}
              </TableCell>
              <TableCell
                className={classNames('align-center', {
                  error: this.shouldError(collection),
                })}
              >
                {this.renderStatusIcon(collection)}
              </TableCell>
            </ItemRow>
          ))}
        </TableBody>
      </PaginatedTable>
    );
  }

  public onEditCollection = (collection: Collection, dispatch: Dispatch) =>
    saveCollection(collection)(dispatch).then(response => {
      if (response && response.status < 400) {
        this.props.closeModal();
        getCollections(collection.contact)(dispatch);
      }
    });

  public handleRowClicked = (collection: Collection) => {
    this.props.openModal(
      <Modal
        key="collection-modal"
        onClickOutside={this.props.closeModal}
        large
      >
        <ModalHeader>
          <h2>Edit collection</h2>
          <AppButton transparent onClick={this.props.closeModal}>
            <FontAwesomeIcon icon="times" />
          </AppButton>
        </ModalHeader>
        <ModalBody>
          <CollectionForm
            initialValues={collection}
            onSubmit={this.onEditCollection}
            onClickBack={this.props.closeModal}
            subscriptionId={collection.subscription}
            disabled={collection.invoiced}
            actionMsg="Save changes"
          />
        </ModalBody>
      </Modal>
    );
  };

  private changePage = (
    page: number = 1,
    pageSize: number = DEFAULT_PAGE_SIZE
  ) => {
    this.props.getCollections(
      this.props.contact,
      page,
      pageSize,
      this.props.isHeadOffice
    );
  };

  private renderStatusIcon = (collection: CollectionResponse) => {
    switch (collection.status) {
      case CollectionStatus.Pending:
        return <DeleteCollectionButton collection={collection} />;
      case CollectionStatus.Invoiced:
        return <FontAwesomeIcon className="warning" icon="clock" />;
      case CollectionStatus.PartiallyAllocated:
        return (
          <FontAwesomeIcon className="warning" icon="exclamation-circle" />
        );
      case CollectionStatus.Receipted:
        return <FontAwesomeIcon className="success" icon="check-circle" />;
      default:
        return <FontAwesomeIcon className="error" icon="exclamation-circle" />;
    }
  };

  private shouldError = (collection: Collection) => {
    switch (collection.status) {
      case CollectionStatus.Pending:
        return moment(collection.collection_date).isBefore(moment());
      case CollectionStatus.Invoiced:
      case CollectionStatus.PartiallyAllocated:
      case CollectionStatus.Receipted:
        return false;
      default:
        return true;
    }
  };
}

// Disconnected version used for testing
export { CollectionsTable as TestableCollectionsTable };

export const mapState = (state: StoreState, props: OwnProps) => ({
  collections: state.collections[props.contact],
  loading: isPending(state.responses, GET_COLLECTIONS),
});

const connector = connect(mapState, {
  closeModal,
  openModal,
  getCollections,
  getCollection,
});

export default connector(CollectionsTable);

export enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

export const taskInProgress = (response: any) =>
  response?.data?.status &&
  ![TaskStatus.COMPLETE, TaskStatus.FAILED].includes(response.data.status);

export const taskComplete = (response: any) =>
  response?.data?.status === TaskStatus.COMPLETE;

export const taskFailed = (response: any) =>
  response?.data?.status === TaskStatus.FAILED;

import { isPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { required } from '^/common/validation';
import { ContactResponse } from '^/contacts/types';
import RenderDropdown from '^/form-helpers/render-dropdown';
import { GET_PRACTICE_PLANS } from '^/iplan/patients/new-patient/actions';
import { StoreState } from '^/types';
interface OwnProps {
  clinicians?: Array<Readonly<Required<ContactResponse>>>;
}

export type ClinicianProps = OwnProps & ConnectedProps<typeof connector>;

const Clinician = ({ clinicians, loading }: ClinicianProps) => {
  const getOptions = () => {
    const options = clinicians?.map(p => ({
      label: p.name,
      value: p.id,
    }));
    if (options) {
      return [{ label: 'please choose...', value: '' }].concat(options);
    }
  };
  return (
    <PageSection className="new-patient-section" heading="Clinician Details">
      {!loading && clinicians && (
        <FormGroup>
          <Field
            label="Name"
            className="required"
            name="clinician"
            component={RenderDropdown}
            options={!loading && getOptions()}
            type="text"
            validate={[required]}
          />
        </FormGroup>
      )}
    </PageSection>
  );
};

export { Clinician as TestableClinician };

export const mapState = (state: StoreState) => ({
  clinicians: state.iplan?.newPatientForm?.clinicians,
  loading: isPending(state.responses, GET_PRACTICE_PLANS),
});

const connector = connect(mapState);

export default connector(Clinician);

import { hasFailed } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Loading from '^/common/loading';
import { StoreState } from '^/types';
import { POLL_TASK } from './actions';
import TaskErrors from './task-errors';
import { taskComplete, taskFailed } from './utils';

export type TaskStatusProps = ConnectedProps<typeof connector>;

export class TaskStatus extends React.PureComponent<TaskStatusProps> {
  public render() {
    const {
      response,
      pollingHasFailed,
      taskHasFailed,
      taskIsComplete,
    } = this.props;
    if (pollingHasFailed) {
      return (
        <div className="error">
          <p>
            <b>An error has occurred getting the task status</b>
          </p>
          <p>
            The task may be in progress, have succeeded or have failed. Please
            check the records to see if task has been executed, either of the
            two scenarios will have happened if the task has been executed:
          </p>
          <ol>
            <li>
              <p>
                All of the changes will be made if all the data is present and
                valid
              </p>
            </li>
            <li>
              <p>
                If there are any errors in the data then no changes will be made
                to the database even if it is partially valid
              </p>
            </li>
          </ol>
        </div>
      );
    }
    if (taskHasFailed) {
      return (
        <div>
          <p className="error">
            <b>Task failed: some errors have occurred</b>
          </p>
          <TaskErrors errors={(response as any).data.data.errors} />
        </div>
      );
    }
    if (taskIsComplete) {
      return (
        <div>
          <p>Task complete</p>
        </div>
      );
    }
    return <Loading />;
  }
}

export { TaskStatus as TestableTaskStatus };

export const mapState = (store: StoreState) => {
  const response = store.responses[POLL_TASK.REQUEST]
    ? store.responses[POLL_TASK.REQUEST]['']
    : undefined;
  return {
    response,
    pollingHasFailed: hasFailed(store.responses, POLL_TASK),
    taskHasFailed: taskFailed(response?.data),
    taskIsComplete: taskComplete(response?.data),
  };
};

const connector = connect(mapState, {});

export default connector(TaskStatus);

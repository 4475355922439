import { PaymentMethod } from '^/contacts/ledger/types';
import { Fee } from '^/fees/types';
import { Plan } from '../types';

export interface PlanSubscription {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  status?: PlanSubscriptionStatus;
  reference: string;
  start_date: string;
  sub_length_months?: number;
  plan: string;
  readonly plan_detail?: Plan | null;
  contacts: ReadonlyArray<SubscriptionContact>;
  payer: string;
  payment_method?: PaymentMethod;
  payment_details?: string | null;
  lapsed?: boolean;
  lapsed_reason?: LapsedReason | null;
  lapsed_date?: string | null;
  payment_interval_months?: 1;
  fee?: Fee;
  joining_fee?: Fee;
  admin_fee?: Fee | null;
  readonly calculated_fees?: {
    fee?: Fee;
    joining_fee?: Fee;
    admin_fee?: Fee | null;
    discount?: Fee | null;
  };
  override_fees_at_renewal?: boolean;
  readonly renewal_date?: string;
  readonly last_renewal_date?: string | null;
  auto_renew: boolean;
  promotional_price?: number;
  promotional_price_months?: number;
  reminder_to?: string | null;
  invoice_to?: string | null;
  invoice_made_out_to?: string | null;
  insprac: boolean;
  suspended?: boolean;
  readonly outstanding_amount?: number;
  readonly last_invoice_date?: string | null;
  readonly next_payment_due?: string;
  readonly overdue_amount?: number;
  contact?: string;
  is_billing_container: boolean;
}

export type PlanSubscriptionResponse = Readonly<Required<PlanSubscription>>;

export interface SubscriptionContact {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  contact: string;
  subscription: string;
  lapsed?: boolean;
  lapsed_reason?: LapsedReason | LapsedReasonCombinedMembership | null | '';
  lapsed_date?: string | null;
  backdate_months?: number;
  start_date?: string;
  readonly joining_fee_paid?: boolean;
  practice?: string;
  membership?: string;
  contact_id?: string;
}

export type SubscriptionContactResponse = Readonly<
  Required<SubscriptionContact>
>;

export enum PlanSubscriptionStatus {
  Live = 'LIVE',
  Archived = 'ARCHIVED',
}

export enum LapsedReasonCombinedMembership {
  Cancelled = 'CAN',
  CeasedtoTrade = 'CTT',
  Deceased = 'DEC',
  FeeIncrease = 'FEE',
  TransferredToICAPP = 'ICA',
  Lapsed = 'LAP',
  LapsedForAdjustment = 'LPL',
  NotProceeding = 'NPR',
  NotRenewed = 'REN',
  Retired = 'RET',
  SettlementAgreed = 'SET',
  SoldPractice = 'SOL',
}

export const LapedReasonDropdownOptionsCombinedMembership = [
  {
    label: 'Please choose...',
    value: '',
  },
  {
    label: 'Cancelled',
    value: LapsedReasonCombinedMembership.Cancelled,
  },
  {
    label: 'Ceased to trade',
    value: LapsedReasonCombinedMembership.CeasedtoTrade,
  },
  {
    label: 'Deceased',
    value: LapsedReasonCombinedMembership.Deceased,
  },
  {
    label: 'Fee increase',
    value: LapsedReasonCombinedMembership.FeeIncrease,
  },
  {
    label: 'Lapsed',
    value: LapsedReasonCombinedMembership.Lapsed,
  },
  {
    label: 'Lapsed for Adjustment',
    value: LapsedReasonCombinedMembership.LapsedForAdjustment,
  },
  {
    label: 'Not proceeding',
    value: LapsedReasonCombinedMembership.NotProceeding,
  },
  {
    label: 'Not renewed',
    value: LapsedReasonCombinedMembership.NotRenewed,
  },
  {
    label: 'Retired',
    value: LapsedReasonCombinedMembership.Retired,
  },
  {
    label: 'Settlement agreed',
    value: LapsedReasonCombinedMembership.SettlementAgreed,
  },
  {
    label: 'Sold practice',
    value: LapsedReasonCombinedMembership.SoldPractice,
  },
];

export enum LapsedReason {
  CancelledWithoutNotice = 'CNN',
  CancelledByPractice = 'CPN',
  CancelledWithNotice = 'CWN',
  Deceased = 'DEC',
  PlanComplete = 'FIN',
  LapsedForAdjustment = 'LPL',
  NotStartedRefunded = 'NCR',
  NotStartedFailed = 'NDF',
  DiscountCancelled = 'NoD',
  NonStarter = 'NSC',
  PaymentStoppedChqBounced = 'PSC',
  PaymentStoppedDDCancelled = 'PSD',
  NotRenewed = 'REN',
}

export const LapedReasonDropdownOptions = [
  { label: 'Please choose..', value: '' },
  {
    label: 'Cancelled Without Notice',
    value: LapsedReason.CancelledWithoutNotice,
  },
  {
    label: 'Cancelled By Practice',
    value: LapsedReason.CancelledByPractice,
  },
  {
    label: 'Cancelled With Notice',
    value: LapsedReason.CancelledWithNotice,
  },
  { label: 'Deceased', value: LapsedReason.Deceased },
  {
    label: 'Plan Complete',
    value: LapsedReason.PlanComplete,
  },
  {
    label: 'Lapsed For Adjustment',
    value: LapsedReason.LapsedForAdjustment,
  },
  {
    label: 'Not Started Refunded',
    value: LapsedReason.NotStartedRefunded,
  },
  {
    label: 'Not Started Failed',
    value: LapsedReason.NotStartedFailed,
  },
  {
    label: 'Discount Cancelled',
    value: LapsedReason.DiscountCancelled,
  },
  { label: 'Non Starter', value: LapsedReason.NonStarter },
  {
    label: 'Payment Stopped Chq Bounced',
    value: LapsedReason.PaymentStoppedChqBounced,
  },
  {
    label: 'Payment Stopped DD Cancelled',
    value: LapsedReason.PaymentStoppedDDCancelled,
  },
  { label: 'Not Renewed', value: LapsedReason.NotRenewed },
];

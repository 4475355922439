import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import RelatedActivities from '^/activities/related-activities';
import { addressActions } from '^/address/actions';
import Addresses from '^/address/addresses-component';
import { AddressLabels } from '^/address/types';
import Collections from '^/collections/collections';
import { Loading } from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import Sidebar from '^/common/sidebar';
import { contactActions, getContact } from '^/contacts/actions';
import PaymentDetailsSection from '^/contacts/payment-details-section';
import PersonsDetailsCard from '^/contacts/persons/details-card';
import { patientActions } from '^/contacts/persons/patients/actions';
import { ContactStatus } from '^/contacts/types';
import { paymentDetailActions } from '^/payment-details/actions';
import { subscriptionActions } from '^/plans/subscriptions/actions';
import SubscriptionAddButton from '^/plans/subscriptions/add-button';
import PlanSubscriptionsTable from '^/plans/subscriptions/table';
import { relationshipActions } from '^/relationships/actions';
import RelationshipsDetailCard from '^/relationships/detail-card';
import { RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

export type PatientsDetailsTabProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

class PatientDetailsTab extends React.PureComponent<PatientsDetailsTabProps> {
  public componentDidMount() {
    const {
      patient,
      match: {
        params: { contactId },
      },
      loading,
    } = this.props;

    if (!loading.details && cachedItemHasExpired(patient)) {
      this.props.getContact(contactId);
    }
  }

  public render() {
    const {
      patient,
      match: {
        params: { contactId },
      },
      loading,
      linkedPractice,
    } = this.props;

    if (!patient && loading.details) {
      return <Loading />;
    }
    const disabled = this.props.patient?.status === ContactStatus.Archived;

    return (
      <Row>
        <Column xs={12} md={7} lg={8}>
          <PageSection>
            <PageSubSection heading="Contact details" loading={loading.details}>
              <PersonsDetailsCard personId={contactId} disabled={disabled} />
            </PageSubSection>
            <PageSubSection heading="Addresses" loading={loading.addresses}>
              <Addresses
                contactId={contactId}
                defaultLabel={AddressLabels.Home}
                disabled={disabled}
              />
            </PageSubSection>
            {linkedPractice && (
              <RelationshipsDetailCard
                heading="Clinician"
                contactId={contactId}
                label="Clinician"
                types={[RelationshipType.PatientPerformer]}
                hideRecents
                filters={{
                  relationship: RelationshipType.PerformerPractice,
                  related_contact: linkedPractice.id,
                  category: 'DEN',
                }}
                disabled={disabled}
              />
            )}
            <PaymentDetailsSection contact={patient} disabled={disabled} />
          </PageSection>
          <PageSection heading="Dental Plan/Insurance">
            <PlanSubscriptionsTable contactId={contactId} />
            <div className="page-section-buttons">
              {linkedPractice ? (
                <SubscriptionAddButton
                  contactId={linkedPractice.id}
                  patientId={contactId}
                  disabled={disabled}
                />
              ) : (
                <div className="empty-state">
                  Link this patient to a practice to see available plans
                </div>
              )}
            </div>
          </PageSection>
          <Collections contactId={contactId} disabled={disabled} />
        </Column>
        <Sidebar>
          <RelatedActivities contactId={contactId} disabled={disabled} />
        </Sidebar>
      </Row>
    );
  }
}

export { PatientDetailsTab as TestablePatientDetailsTab };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  patient: getItemFromCache(props.match.params.contactId, state.contactsCache),
  linkedPractice: getItemFromCache(
    state.patientPracticeMapping[props.match.params.contactId],
    state.contactsCache
  ),
  loading: {
    details: anyPending(state.responses, contactActions),
    addresses: anyPending(state.responses, addressActions),
    relationships: anyPending(state.responses, relationshipActions),
    linkedPractice: anyPending(state.responses, patientActions),
    subscriptions: anyPending(state.responses, subscriptionActions),
    paymentDetails: anyPending(state.responses, paymentDetailActions),
  },
});

const connector = connect(mapState, { getContact });

export default connector(PatientDetailsTab);

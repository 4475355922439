import { AnyAction } from 'redux';

import {
  GET_ALLOCATIONS,
  GET_CONTACT_LEDGER,
  GET_CONTACT_LEDGER_ENTRIES,
  GET_MODAL_CONTACT_LEDGER,
  UPDATE_LEDGER_ENTRY_ALLOCATION,
} from '^/contacts/ledger/actions';
import {
  AllocationResponse,
  Ledger,
  LedgerEntryAllocationData,
  LedgerResponse,
} from '^/contacts/ledger/types';
import { GET_LEDGER_ENTRIES } from '^/ledger/actions';
import {
  BaseAction,
  DetailedCache,
  PaginatedAction,
  StoreState,
} from '^/types';
import { createCachedItem, paginatedReducer } from '^/utils/pagination-helpers';

export function contactLedger(
  state: StoreState['contactLedger'] = {},
  action: AnyAction
): StoreState['contactLedger'] {
  switch (action.type) {
    case GET_CONTACT_LEDGER.SUCCESS:
      return {
        ...state,
        [action.meta.contactId]: paginatedReducer(
          state[action.meta.contactId],
          action
        ),
      };
    default:
      return state;
  }
}

export function modalContactLedger(
  state: StoreState['modalContactLedger'] = {},
  action: AnyAction
): StoreState['modalContactLedger'] {
  switch (action.type) {
    case GET_MODAL_CONTACT_LEDGER.SUCCESS:
      return {
        ...state,
        [action.meta.contactId]: paginatedReducer(
          state[action.meta.contactId],
          action
        ),
      };
    default:
      return state;
  }
}

export function ledgerEntriesByDetail(
  state: StoreState['ledgerEntriesByDetail'] = {},
  action: AnyAction
): StoreState['ledgerEntriesByDetail'] {
  switch (action.type) {
    case GET_LEDGER_ENTRIES.SUCCESS:
    case GET_CONTACT_LEDGER_ENTRIES.SUCCESS:
      const entries = action.payload.data.results.reduce(
        (result: DetailedCache<Ledger>, entry: LedgerResponse) => {
          if (entry.amount > 0) {
            result[entry.detail_detail.id] = createCachedItem(entry);
          }

          return result;
        },
        {}
      );

      return { ...state, ...entries };
    default:
      return state;
  }
}

export function ledgerEntryAllocation(
  state: LedgerEntryAllocationData | null = null,
  action: BaseAction<LedgerEntryAllocationData, undefined>
): LedgerEntryAllocationData | null {
  switch (action.type) {
    case UPDATE_LEDGER_ENTRY_ALLOCATION:
      return action.payload;
    default:
      return state;
  }
}

export function allocationsByDetail(
  state: StoreState['allocationsByDetail'] = {},
  action: PaginatedAction<AllocationResponse>
): StoreState['allocationsByDetail'] {
  switch (action.type) {
    case GET_ALLOCATIONS.SUCCESS:
      const key =
        typeof action.meta?.collection === 'string'
          ? action.meta.collection
          : 'generic';

      return {
        ...state,
        [key]: action.payload.data.results,
      };
    default:
      return state;
  }
}

import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';

export function filterByType(
  relationships: ReadonlyArray<RelationshipResponse>,
  relationshipTypes: ReadonlyArray<RelationshipType>
): ReadonlyArray<RelationshipResponse> {
  return relationships.filter(relationship =>
    relationshipTypes.includes(relationship.relationship)
  );
}

export function firstRelationshipOfType(
  relationships: ReadonlyArray<RelationshipResponse>,
  relationshipType: RelationshipType
): RelationshipResponse | null {
  const filteredRelationships = filterByType(relationships, [relationshipType]);

  return filteredRelationships.length > 0 ? filteredRelationships[0] : null;
}

export function getRelationshipsFromStore<DefaultValueType>(
  store: StoreState,
  contact: string,
  type: RelationshipType | string,
  defaultValue: DefaultValueType
) {
  const contactRelationship = store.contactRelationships[contact];

  if (!contactRelationship || !contactRelationship[type]) {
    return defaultValue;
  }

  return contactRelationship[type];
}

export function getIsolatedRelationshipsFromStore<DefaultValueType>(
  store: StoreState,
  contact: string,
  type: RelationshipType | string,
  defaultValue: DefaultValueType
) {
  const contactRelationship = store.isolatedContactRelationships[contact];
  if (!contactRelationship || !contactRelationship[type]) {
    return defaultValue;
  }

  return contactRelationship[type];
}

import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  GET_ACTIVITIES,
  getActivities,
  getContactActivities,
} from '^/activities/actions';
import ActivityDetailCard from '^/activities/detail-card';
import ActivitiesTable from '^/activities/table';
import { ActivityResponse } from '^/activities/types';
import { GET_USERS, getUsers } from '^/admin/users/actions';
import { UserRole } from '^/admin/users/types';
import FilterBar from '^/common/filter-bar';
import Sidebar from '^/common/sidebar';
import { FilterList } from '^/filters/types';
import { getLoggedInUser } from '^/login/actions';
import { StoreState } from '^/types';
import { API_DATE_FORMAT } from '^/utils/constants';
import { paginatedArrayHasExpired } from '^/utils/pagination-helpers';

const ACTIVITY_FILTERS: { [filter: string]: FilterList } = {
  All: { popup: 'False', completed_next_action: 'False' },
  Today: {
    start_date: moment()
      .startOf('day')
      .format(API_DATE_FORMAT),
    end_date: moment()
      .startOf('day')
      .add(1, 'day')
      .format(API_DATE_FORMAT),
    popup: 'False',
    completed_next_action: 'False',
  },
  'This week': {
    start_date: moment()
      .startOf('week')
      .format(API_DATE_FORMAT),
    end_date: moment()
      .startOf('week')
      .add(1, 'week')
      .format(API_DATE_FORMAT),
    popup: 'False',
    completed_next_action: 'False',
  },
  'This month': {
    start_date: moment()
      .startOf('month')
      .format(API_DATE_FORMAT),
    end_date: moment()
      .endOf('month')
      .add(1, 'day')
      .format(API_DATE_FORMAT),
    popup: 'False',
    completed_next_action: 'False',
  },
  Overdue: {
    end_date: moment().format(API_DATE_FORMAT),
    completed_next_action: 'False',
    popup: 'False',
  },
  Upcoming: {
    start_date: moment().format(API_DATE_FORMAT),
    end_date: moment()
      .add(1, 'week')
      .format(API_DATE_FORMAT),
    popup: 'False',
    completed_next_action: 'False',
  },
  Popups: {
    popup: 'True',
    completed_next_action: 'False',
  },
  Completed: {
    completed_next_action: 'True',
  },
};

export type ActivityBrowserProps = RouteComponentProps<{
  contactId?: string;
  activityId?: string;
}> &
  ConnectedProps<typeof connector>;

/**
 * Page for listing all Activities
 */
class ActivityBrowser extends React.PureComponent<ActivityBrowserProps> {
  public componentDidMount() {
    const { users, loggedInUser } = this.props;

    if (paginatedArrayHasExpired(users)) {
      this.props.getUsers();
    }

    if (!loggedInUser || !loggedInUser.id) {
      this.props.getLoggedInUser();
    }
  }

  public render() {
    const { assignedUserId, match, filter, loading } = this.props;

    return (
      <Row>
        <Row>
          <FilterBar
            filter={filter}
            url={match.url}
            filters={ACTIVITY_FILTERS}
            loading={loading}
          />
        </Row>
        <Row>
          <Column
            xs={12}
            md={match.params.activityId ? 7 : 12}
            lg={match.params.activityId ? 8 : 12}
          >
            <ActivitiesTable
              filters={ACTIVITY_FILTERS[filter]}
              selectedActivityId={match.params.activityId}
              selectActivity={this.selectActivity}
              associatedContactId={match.params.contactId}
              assignedUserId={assignedUserId}
            />
          </Column>
          {match.params.activityId && (
            <Sidebar>
              <ActivityDetailCard
                activityId={match.params.activityId}
                showRelatedContact={!match.params.contactId}
                assignedUserId={assignedUserId}
              />
            </Sidebar>
          )}
        </Row>
      </Row>
    );
  }

  public selectActivity = (selectedActivity: ActivityResponse) => {
    const { match, location } = this.props;

    if (match.params.activityId) {
      this.props.history.push(`./${selectedActivity.id}${location.search}`);
    } else {
      this.props.history.push(
        `${match.url}/${selectedActivity.id}${location.search}`
      );
    }
  };
}

/** Disconnected version of the component that is used for testing. */
export { ActivityBrowser as TestableActivityBrowser };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId?: string; activityId?: string }>
) => {
  const queryParams = queryString.parse(props.location.search);

  return {
    filter: queryParams.filter ? String(queryParams.filter) : 'All',
    users: state.users,
    activities: props.match.params.contactId
      ? state.contactActivities[props.match.params.contactId]
      : state.activities,
    loading: anyPending(state.responses, [GET_ACTIVITIES, GET_USERS]),
    loggedInUser: state.loggedInUser,
    assignedUserId:
      state.loggedInUser &&
      state.loggedInUser.id &&
      state.loggedInUser.role !== UserRole.AdminLevel
        ? state.loggedInUser.id
        : undefined,
  };
};

const connector = connect(mapState, {
  getUsers,
  getActivities,
  getLoggedInUser,
  getContactActivities,
});

export default connector(ActivityBrowser);

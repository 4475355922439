import { Container, Nav, NavBar, NavItem } from '@dabapps/roe';
import React from 'react';
import {
  NavLink,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';

import Allow from '^/common/allow';
import { UserRole } from '../admin/users/types';
import AllowRoute from '../common/allow-route';
import GenericRoutinesPage from './generic-page';
import MembershipsRoutinesPage from './memberships-page';
import PlansRoutinesPage from './plans-page';
import ReportsRoutinesPage from './reports-page';

export type AdminRoutesProps = RouteComponentProps;

class RoutinesRoutes extends React.PureComponent<AdminRoutesProps> {
  public render() {
    const { match } = this.props;

    return (
      <>
        <NavBar className="secondary">
          <Container>
            <Nav>
              <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                <NavItem>
                  <NavLink exact to={`${match.url}`}>
                    Generic
                  </NavLink>
                </NavItem>
              </Allow>
              <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                <NavItem>
                  <NavLink to={`${match.url}/plans`}>Plans</NavLink>
                </NavItem>
              </Allow>
              <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                <NavItem>
                  <NavLink to={`${match.url}/memberships`}>Memberships</NavLink>
                </NavItem>
              </Allow>
              <NavItem>
                <NavLink to={`${match.url}/reports`}>Reports</NavLink>
              </NavItem>
            </Nav>
          </Container>
        </NavBar>

        <Switch>
          <AllowRoute
            roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}
            redirect={`${match.url}/reports`}
            exact
            path={`${match.path}`}
            component={GenericRoutinesPage}
          />
          <AllowRoute
            roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}
            exact
            path={`${match.path}/plans`}
            component={PlansRoutinesPage}
          />
          <AllowRoute
            roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}
            exact
            path={`${match.path}/memberships`}
            component={MembershipsRoutinesPage}
          />
          <Route
            path={`${match.path}/reports`}
            component={ReportsRoutinesPage}
          />
          <Route component={this.redirect} />
        </Switch>
      </>
    );
  }

  public redirect = () => <Redirect to={`${this.props.match.url}`} />;
}

export default RoutinesRoutes;

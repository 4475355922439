import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar } from 'react-chartjs-2';

import AppButton from '^/common/app-button';
import Loading from '^/common/loading';
import { Uuid } from '^/common/types';
import { Dashboard } from '../types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const getLabels = (data: Dashboard['patients_given_notice']): string[] => {
  return data.map(item =>
    new Date(item.month).toLocaleString('default', { month: 'short' })
  );
};

const getValues = (data: Dashboard['patients_given_notice']): number[] => {
  return data.map(item => item.value);
};

export interface PatientsGivenNoticeProps {
  data?: Dashboard['patients_given_notice'];
  loading?: boolean;
  practiceId?: Uuid;
}

class PatientsGivenNotice extends React.PureComponent<
  PatientsGivenNoticeProps
> {
  public render() {
    const { data, loading, practiceId } = { ...this.props };
    const options: any = {
      responsive: true,
      maintainAspectRatio: false,
      categoryPercentage: 0.5,
      barPercentage: 0.6,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'top',
          font: {
            weight: 'bold',
          },
        },
      },
    };
    if (data) {
      options.scales = {
        y: {
          suggestedMax: Math.max(...data!.map(o => o.value)) + 10,
        },
      };
    }
    if (loading) {
      return <Loading />;
    } else if (data) {
      const chartData = {
        labels: getLabels(data),
        datasets: [
          {
            data: getValues(data),
            backgroundColor: ['#FFA500', '#F15930', '#00B68A'],
          },
        ],
      };
      return (
        <>
          <h4>Patients Given Notice</h4>
          <div className="chart-container">
            <Bar options={options as any} data={chartData} />
          </div>
          <AppButton
            className="iplan-app-button"
            url={`/api/iplan/dashboard/exports/patients-given-notice?practice=${practiceId}`}
          >
            {`Download data >>`}
          </AppButton>
        </>
      );
    }
    return null;
  }
}

export { PatientsGivenNotice as TestablePatientsGivenNotice };

export default PatientsGivenNotice;

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { Activity } from '^/activities/types';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { required } from '^/common/validation';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderUploadField from '^/form-helpers/render-file-upload';
import RenderInputField from '^/form-helpers/render-input-field';
import RenderTextArea from '^/form-helpers/render-text-area';
import RenderUserPicker from '^/form-helpers/render-user-picker';
import { StoreState } from '^/types';
import ActivityComment from './activity-comment';

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  generalErrorFields?: ReadonlyArray<string>;
  hidePopupCheckbox?: boolean;
  /** If present a cancel button will be shown that will fire this prop. */
  dispatch?: Dispatch;
  onCancel?(): void;
}

const selector = formValueSelector('activityForm');

export type ActivityFormProps = OwnProps &
  InjectedFormProps<Activity, OwnProps> &
  ConnectedProps<typeof connector>;

class ActivityForm extends React.Component<ActivityFormProps> {
  public render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      actions,
      popup,
      generalErrorFields = ['non_field_errors'],
      onCancel,
      hidePopupCheckbox,
      initialValues,
    } = this.props;

    return (
      <Form className="activity-form" onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            component={RenderInputField}
            label="Title"
            name="title"
            validate={[required]}
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={RenderTextArea}
            label="Add comment"
            name="text"
            large
          />
        </FormGroup>
        {initialValues?.comments && (
          <div className="activity-comments">
            {initialValues.comments.map((comment, index) => (
              <ActivityComment
                key={comment.id}
                comment={comment}
                index={index}
                editable
                dispatch={this.props.dispatch}
              />
            ))}
          </div>
        )}
        {!popup && (
          <FormGroup>
            <Field
              component={RenderUserPicker}
              label="Assigned to"
              name="assigned_to"
            />
            <Field
              component={RenderDateField}
              label="Due date"
              name="due_date"
              type="date"
            />
          </FormGroup>
        )}
        <FormGroup>
          {!hidePopupCheckbox && (
            <Field
              component={RenderCheckBox}
              label="Popup"
              name="popup"
              type="checkbox"
            />
          )}
          {!popup && (
            <Field
              component={RenderCheckBox}
              label="Letter"
              name="letter"
              type="checkbox"
            />
          )}
          <Field
            component={RenderCheckBox}
            label={popup ? 'Hide popup' : 'Completed next action'}
            name="completed_next_action"
            type="checkbox"
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="Attach Files"
            component={RenderUploadField}
            name="attachments"
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={generalErrorFields}
          showStatusErrors
        />
        <div className="form-buttons">
          {onCancel && (
            <AppButton type="button" onClick={onCancel} disabled={submitting}>
              Cancel
            </AppButton>
          )}
          <AppButton
            primary
            type="submit"
            loading={submitting}
            disabled={pristine}
          >
            Save
          </AppButton>
        </div>
      </Form>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { ActivityForm as TestableActivityForm };

export const mapState = (state: StoreState) => ({
  popup: selector(state, 'popup'),
});

const connector = connect(mapState, {});

export default reduxForm<Activity, OwnProps>({
  form: 'activityForm',
})(connector(ActivityForm));

import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import { CREATE_CONTACT, createContact } from '^/contacts/actions';
import CompaniesForm from '^/contacts/companies/form';
import { CategoryType, Contact, ContactType } from '^/contacts/types';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';

export type PracticesCreatePageProps = RouteComponentProps;

/**
 * Page for creating new practices.
 */
class PracticesCreatePage extends React.PureComponent<
  PracticesCreatePageProps
> {
  public render() {
    return (
      <PageContent>
        <BreadcrumbBar
          items={[['Practices', '/practices'], 'New practice record']}
        />
        <HeaderBar title="New practice record" transparent />
        <Container className="content-wrapper">
          <main>
            <PageSection heading="Practice Details">
              <CompaniesForm
                actions={[CREATE_CONTACT]}
                onSubmit={this.onSave}
                onCancel={this.onCancel}
                labelPrefix="Practice"
                initialValues={{
                  type: ContactType.Practice,
                  category: CategoryType.DentalPractice,
                }}
              />
            </PageSection>
          </main>
        </Container>
      </PageContent>
    );
  }

  /**
   * Fires off the action that will save this practice.
   * @param {Contact} practice - form fields to be submitted
   * @param {Dispatch} dispatch - redux dispatch method that comes from reduxForm
   */
  public onSave = (practice: Contact, dispatch: Dispatch) => {
    return createContact({ ...practice, type: ContactType.Practice })(
      dispatch
    ).then(response => {
      if (response) {
        const contact = response.data;
        return this.props.history.push(`/practices/${contact.id}`);
      }
    });
  };

  /**
   * Cancels the submission of this form. Goes back to the previous page if there is one, or to /practices if there is no history.
   */
  public onCancel = () => {
    if (this.props.history.length > 2) {
      this.props.history.goBack();
    } else {
      this.props.history.replace('/practices');
    }
  };
}

/** Disconnected version of the component that is used for testing. */
export { PracticesCreatePage as TestablePracticesCreatePage };

export default PracticesCreatePage;

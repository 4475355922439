import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  FieldArray,
  Form,
  FormErrors,
  formValueSelector,
  FormWarnings,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { required } from '^/common/validation';
import { clearContacts, getContacts } from '^/contacts/actions';
import RenderAllocations, {
  RenderAllocationsOwnProps,
} from '^/form-helpers/render-allocations';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderDateTimeField from '^/form-helpers/render-date-time-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderLedgerItems, {
  RenderLedgerItemsOwnProps,
} from '^/form-helpers/render-ledger-items';
import RenderTextArea from '^/form-helpers/render-text-area';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';
import { round, sumAllocationAmounts } from '../../helpers';
import { BANK_OPTIONS, Ledger, PAYMENT_METHOD_OPTIONS } from '../../types';

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  errorFields?: ReadonlyArray<string>;
  showItems?: boolean;
  hideButtons?: boolean;
  saveButtonText?: string;
  showBankOptions?: boolean;
  /** Function to run on clicking cancel button */
  onCancel?(): void;
}

export type CreateLedgerEntryFormProps = OwnProps &
  InjectedFormProps<Ledger, OwnProps> &
  ConnectedProps<typeof connector>;

class CreateLedgerEntryForm extends React.Component<
  CreateLedgerEntryFormProps
> {
  public componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues.contact_1) {
      this.props.getContacts({
        payer_payee_group_for: initialValues.contact_1,
      });
    }
  }

  public componentWillUnmount() {
    this.props.clearContacts();
  }

  public render() {
    const {
      actions = [],
      errorFields = [],
      handleSubmit,
      initialValues,
      onCancel,
      submitting,
      showItems,
      hideButtons,
      error,
      warning,
      saveButtonText = 'Save',
      amount,
      payeeGroup,
      showBankOptions = true,
    } = this.props;

    if (!initialValues.contact_1) {
      return <p className="error">Form must be initialized with contacts.</p>;
    }

    const contacts = payeeGroup
      ? getAllRetrievedPages(payeeGroup).map(c => ({
          contact: c.id,
        }))
      : [{ contact: initialValues.contact_1 }];

    return (
      <Form onSubmit={handleSubmit}>
        {showItems && (
          <FieldArray<RenderLedgerItemsOwnProps>
            name="detail_detail.ledger_items"
            component={RenderLedgerItems}
            contacts={contacts}
          />
        )}
        {initialValues.allocations && initialValues.allocations.length > 0 && (
          <FieldArray<RenderAllocationsOwnProps>
            name="allocations"
            component={RenderAllocations}
            limit={round(amount || 0)}
          />
        )}
        <FormGroup>
          {initialValues.amount !== undefined && (
            <Field
              label="Total amount"
              name="amount"
              component={RenderCurrencyField}
              stretch
            />
          )}
          {initialValues.payment_method !== undefined && (
            <Field
              label="Payment method"
              name="payment_method"
              component={RenderDropdown}
              options={PAYMENT_METHOD_OPTIONS}
            />
          )}
          {showBankOptions && (
            <Field
              label="Bank"
              name="detail_detail.bank"
              component={RenderDropdown}
              options={BANK_OPTIONS}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Field
            label="Entry date"
            name="entry_date"
            component={RenderDateTimeField}
            validate={required}
          />
          <Field
            label="Description"
            name="comments"
            type="text"
            component={RenderTextArea}
            stretch
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={['non_field_errors', ...errorFields]}
          error={error}
          warning={warning}
          showStatusErrors
        />
        {!hideButtons && (
          <div className="form-buttons">
            {onCancel && (
              <AppButton disabled={submitting} onClick={onCancel} type="button">
                Cancel
              </AppButton>
            )}
            <AppButton loading={submitting} primary type="submit">
              {saveButtonText}
            </AppButton>
          </div>
        )}
      </Form>
    );
  }
}

export { CreateLedgerEntryForm as TestableCreateLedgerEntryForm };

export const mapState = (
  state: StoreState,
  props: InjectedFormProps<Ledger, OwnProps>
) => ({
  payeeGroup: props.initialValues.contact_1
    ? state.payerPayeeGroup[props.initialValues.contact_1]
    : null,
  amount: formValueSelector(props.form)(state, 'amount'),
});

const connector = connect(mapState, {
  getContacts,
  clearContacts,
});

export function validate(values: Partial<Ledger>): FormErrors<Ledger> {
  const errors: FormErrors<Ledger> = {};

  if (values.allocations && values.allocations.length > 0) {
    const allocated = sumAllocationAmounts(values.allocations);
    const total = values.amount || 0;

    if (round(allocated) > round(total)) {
      errors._error = `Over allocated - reduce allocations or increase total amount.`;
    }
  }

  return errors;
}

export function warn(
  values: Partial<Ledger>
): FormWarnings<Ledger> & { _warning?: string } {
  const warnings: FormWarnings<Ledger> & {
    _warning?: string;
  } = {};

  if (values.allocations && values.allocations.length > 0) {
    const allocated = sumAllocationAmounts(values.allocations);
    const total = values.amount || 0;

    if (round(allocated) < round(total)) {
      warnings._warning = `Under allocated - you can still allocate up to ${formatFeeAmount(
        total - allocated
      )}`;
    }
  }

  return warnings;
}

export default reduxForm<Ledger, OwnProps>({
  form: 'CreateLedgerEntryForm',
  initialValues: {},
  validate,
  warn,
})(connector(CreateLedgerEntryForm));

import { Container } from '@dabapps/roe';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import TemplatesTable from '^/admin/templates/table';
import AppButton from '^/common/app-button';
import HeaderBar from '^/common/header-bar';
import PageContent from '^/page-container/page-content';

class TemplatesListPage extends React.PureComponent<RouteComponentProps> {
  public render() {
    return (
      <PageContent>
        <HeaderBar
          title="Plans"
          primaryActions={[
            <Link key="new" to={`${this.props.match.url}/create`}>
              <AppButton key="new" leftIcon="plus" primary>
                New Plan
              </AppButton>
            </Link>,
          ]}
          transparent
        />
        <Container>
          <TemplatesTable />
        </Container>
      </PageContent>
    );
  }
}

export { TemplatesListPage as TestableTemplatesListPage };

export default TemplatesListPage;

import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ConfirmationModal from '^/common/confirmation-modal';
import { Loading } from '^/common/loading';
import SectionDetailList from '^/common/section-detail-list';
import { GET_CONTACT } from '^/contacts/actions';
import { BANK, Bank } from '^/contacts/ledger/types';
import { closeModal, openModal } from '^/modals/actions';
import {
  createPaymentDetails,
  deletePaymentDetails,
  editPaymentDetails,
  getPaymentDetailsByContact,
} from '^/payment-details/actions';
import PaymentDetailsForm from '^/payment-details/form';
import { BANK_STATUS, formatSortCode } from '^/payment-details/helpers';
import {
  BankStatusTypes,
  PaymentDetails,
  PaymentDetailsResponse,
} from '^/payment-details/types';
import { StoreState } from '^/types';

export enum DetailsMode {
  List = 'LIST',
  Edit = 'EDIT',
  Add = 'ADD',
  Hidden = 'HIDDEN',
}

interface OwnProps {
  contactId: string;
  contactName: string;
  contactCrmId: string;
  disabled?: boolean;
}

interface StateProps {
  mode?: DetailsMode;
}

export type PaymentDetailsProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class PaymentDetailsCard extends React.PureComponent<PaymentDetailsProps> {
  public componentDidMount() {
    this.props.getPaymentDetailsByContact(this.props.contactId);
  }

  public componentDidUpdate(prevProps: PaymentDetailsProps) {
    const { paymentDetails, contactId, setProps } = this.props;

    if (paymentDetails !== prevProps.paymentDetails) {
      paymentDetails[contactId]
        ? setProps({ mode: DetailsMode.Hidden })
        : setProps({ mode: DetailsMode.Add });
    }
  }

  public render() {
    const {
      mode,
      isLoading,
      contactId,
      paymentDetails,
      contactName,
      contactCrmId,
      disabled,
    } = this.props;

    const detailsToEdit = paymentDetails[contactId];

    const details = this.parsePaymentDetails(detailsToEdit);
    const hasDetails = details && details.length > 0;

    if (isLoading) {
      return <Loading />;
    }

    switch (mode) {
      case DetailsMode.Edit:
        return (
          <PaymentDetailsForm
            initialValues={detailsToEdit}
            onSubmit={this.onSubmitEdit.bind(
              this,
              detailsToEdit ? detailsToEdit.id : ''
            )}
            onCancel={this.setMode.bind(this, DetailsMode.List)}
          />
        );
      case DetailsMode.Add:
        return (
          <PaymentDetailsForm
            initialValues={{
              ...detailsToEdit,
              account_name: contactName,
              reference: contactCrmId,
              associated_bank: Bank.ClientAccount,
            }}
            onSubmit={this.onSubmitCreate}
            onCancel={this.setMode.bind(this, DetailsMode.List)}
          />
        );
      case DetailsMode.Hidden:
        return (
          <div>
            <div className="margin-bottom-small">{contactName}</div>
            <AppButton
              className="hidden-details"
              link
              onClick={this.setMode.bind(this, DetailsMode.List)}
            >
              Show payment details...
            </AppButton>
          </div>
        );
      case DetailsMode.List:
      default:
        return (
          <div className="flex-row">
            <div className="flex-column">
              <SectionDetailList
                details={details}
                emptyStateMessage="No details"
              />
              {hasDetails && (
                <div className="hide-button">
                  <AppButton
                    link
                    onClick={this.setMode.bind(this, DetailsMode.Hidden)}
                  >
                    Hide payment details...
                  </AppButton>
                </div>
              )}
            </div>
            <div className="page-subsection-buttons">
              <Allow
                roles={[
                  UserRole.AdminLevel,
                  UserRole.FinanceLevel,
                  UserRole.OfficeLevel,
                ]}
              >
                <AppButton
                  onClick={this.setMode.bind(
                    this,
                    hasDetails ? DetailsMode.Edit : DetailsMode.Add
                  )}
                  small
                  disabled={disabled}
                >
                  {hasDetails ? DetailsMode.Edit : DetailsMode.Add}
                </AppButton>
                {hasDetails && (
                  <AppButton onClick={this.onRemove} small disabled={disabled}>
                    Remove
                  </AppButton>
                )}
              </Allow>
            </div>
          </div>
        );
    }
  }

  public onSubmitCreate = (values: PaymentDetails) => {
    return this.props
      .createPaymentDetails(this.props.contactId, values)
      .then(() => this.setMode(DetailsMode.List));
  };

  public onSubmitEdit = (
    paymentId: string,
    values: PaymentDetails,
    dispatch: Dispatch
  ) => {
    const contactPaymentDetails = this.props.paymentDetails[values.contact];

    if (contactPaymentDetails) {
      const staleBank = contactPaymentDetails.associated_bank;

      if (values.associated_bank !== staleBank) {
        values.bank_status = BankStatusTypes.SetupNewInstruction;
        this.props.openModal(
          <ConfirmationModal
            heading="Are you sure?"
            message={`Changing from ${staleBank} to ${values.associated_bank}
          will reset the bank status to 'Setup New Instruction (0N)'.
          A new mandate will be created overnight for processing,
          and you will not be able to collect any payments until this has been processed by the bank.`}
            action={this.editDetails.bind(this, values, dispatch, true)}
            closeModal={this.props.closeModal}
            actionLabel="Confirm"
          />
        );
      } else {
        this.props.editPaymentDetails(paymentId, values);
        this.setMode(DetailsMode.List);
      }
    }
  };

  public editDetails = (
    details: PaymentDetails,
    dispatch: Dispatch,
    close?: boolean
  ) =>
    editPaymentDetails(
      details.id,
      details
    )(dispatch).then(response => {
      if (response) {
        if (close) {
          this.props.closeModal();
        }
        return this.setMode(DetailsMode.List);
      }
    });

  public onRemove = () => {
    const { contactId, paymentDetails } = this.props;
    const paymentDetailsForContact = paymentDetails[contactId];

    if (paymentDetailsForContact) {
      const paymentId = paymentDetailsForContact.id;

      const deletePaymentDetailsForId = () =>
        this.props
          .deletePaymentDetails(paymentId)
          .then(() => this.setMode(DetailsMode.List));

      this.props.openModal(
        <ConfirmationModal
          heading="Are you sure?"
          message="Any direct debits associated with this account will no longer be collected. You will have to add new payment details and submit a new AUDDIS mandate before any payments can be taken for this contact, and any payees associated with them."
          action={deletePaymentDetailsForId}
          closeModal={this.props.closeModal}
          actionLabel="Delete"
        />
      );
    }
  };

  public setMode = (mode: DetailsMode) => {
    this.props.setProps({ mode });
  };

  private parsePaymentDetails = (
    paymentDetails: PaymentDetailsResponse | undefined
  ): ReadonlyArray<{ label: string; content: string }> | null =>
    paymentDetails
      ? [
          {
            label: 'Account name',
            content: paymentDetails.account_name,
          },
          {
            label: 'Sort code',
            content: formatSortCode(paymentDetails.sort_code),
          },
          {
            label: 'Account number',
            content: paymentDetails.account_number,
          },
          {
            label: 'Associated bank',
            content: BANK[paymentDetails.associated_bank],
          },
          {
            label: 'Current DD status',
            content: BANK_STATUS[paymentDetails.bank_status],
          },
          {
            label: 'BACS Reference',
            content: paymentDetails.reference,
          },
          {
            label: 'First collection date',
            content: paymentDetails.first_collection_date
              ? paymentDetails.first_collection_date.toString()
              : '',
          },
        ]
      : null;
}

export { PaymentDetailsCard as TestablePaymentDetailsCard };

export const getInitialProps = () => ({
  mode: DetailsMode.List,
});

export const mapState = ({
  responses,
  contactsCache,
  paymentDetails,
}: StoreState) => ({
  contactsCache,
  isLoading: anyPending(responses, [GET_CONTACT]),
  paymentDetails,
});

const connector = connect(mapState, {
  submit,
  openModal,
  closeModal,
  createPaymentDetails,
  getPaymentDetailsByContact,
  editPaymentDetails,
  deletePaymentDetails,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(PaymentDetailsCard)
);

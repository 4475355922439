import classnames from 'classnames';
import React from 'react';

interface OwnProps {
  page?: string;
}

export type PageContentProps = OwnProps;

class PageContent extends React.PureComponent<PageContentProps> {
  public render() {
    return (
      <div className={classnames('page-content', this.props.page)}>
        {this.props.children}
      </div>
    );
  }
}

// Disconnected version used for testing
export { PageContent as TestablePageContent };

export default PageContent;

import { TableCell, TableHeader } from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';

import { formatDate } from '^/common/helper-functions';
import ItemRow from '^/common/paginated-table/item-row';
import { ProductResponse } from './types';

export interface ProductRowProps {
  productResponse: ProductResponse;
  push: typeof push;
}

export class ProductRow extends React.PureComponent<ProductRowProps> {
  public render() {
    const { productResponse } = this.props;

    return (
      <ItemRow item={productResponse} onClick={this.navigateToProduct}>
        <TableHeader>{productResponse.product_code}</TableHeader>
        <TableCell>{productResponse.description}</TableCell>
        <TableCell>{productResponse.nominal_code}</TableCell>
        <TableCell>{productResponse.member_price}</TableCell>
        <TableCell>{productResponse.delivery}</TableCell>
        <TableCell>{productResponse.vat}</TableCell>
        <TableCell>{productResponse.status}</TableCell>
        <TableCell>{formatDate(productResponse.created)}</TableCell>
      </ItemRow>
    );
  }

  public navigateToProduct = (product: ProductResponse) =>
    this.props.push(`/admin/products/${product.id}`);
}

export { ProductRow as TestableProductRow };

export default connect(undefined, { push })(ProductRow);

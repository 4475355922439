import { makeAsyncActionSet, request } from '@dabapps/redux-requests/dist/js';
import { Dispatch } from 'redux';

import { Insurance } from '^/insurance/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';

export const INSURANCE_ENDPOINT = '/api/insurance/';

export const GET_PRACTICE_INSURANCE = makeAsyncActionSet(
  'GET_PRACTICE_INSURANCE'
);
export function getPracticeInsurance(contactId: string) {
  return (dispatch: Dispatch) =>
    request(
      GET_PRACTICE_INSURANCE,
      `${INSURANCE_ENDPOINT}?practice=${contactId}`,
      'GET',
      undefined,
      { metaData: { contactId, page: 1, pageSize: 100 } }
    )(dispatch);
}

export const CREATE_PRACTICE_INSURANCE = makeAsyncActionSet(
  'CREATE_PRACTICE_INSURANCE'
);
export function createPracticeInsurance(insprac: Insurance) {
  return (dispatch: Dispatch) =>
    request(
      CREATE_PRACTICE_INSURANCE,
      `${INSURANCE_ENDPOINT}`,
      'POST',
      insprac,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch).catch(throwSubmissionError);
}

export const SAVE_PRACTICE_INSURANCE = makeAsyncActionSet(
  'SAVE_PRACTICE_INSURANCE'
);
export function savePracticeInsurance(id: string, insprac: Insurance) {
  return (dispatch: Dispatch) =>
    request(
      SAVE_PRACTICE_INSURANCE,
      `${INSURANCE_ENDPOINT}${id}/`,
      'PUT',
      insprac,
      { shouldRethrow: rethrowOnAnyError }
    )(dispatch).catch(throwSubmissionError);
}

export const DELETE_PRACTICE_INSURANCE = makeAsyncActionSet(
  'DELETE_PRACTICE_INSURANCE'
);
export function deletePracticeInsurance(practiceInsurance: Insurance) {
  return (dispatch: Dispatch) =>
    request(
      DELETE_PRACTICE_INSURANCE,
      `${INSURANCE_ENDPOINT}${practiceInsurance.id}/`,
      'DELETE',
      {
        shouldRethrow: rethrowOnAnyError,
      },
      { metaData: { practiceId: practiceInsurance.practice } }
    )(dispatch).catch(throwSubmissionError);
}

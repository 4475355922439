import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@dabapps/roe';
import React, { SyntheticEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { change, formValueSelector, WrappedFieldArrayProps } from 'redux-form';

import AppButton from '^/common/app-button';
import { amountReducer, vatReducer } from '^/contacts/ledger/helpers';
import LedgerItemFormRow from '^/contacts/ledger/modals/forms/ledger-item-form-row';
import { LedgerItem, LedgerItemType } from '^/contacts/ledger/types';
import { formatFeeAmount } from '^/plans/helpers';
import { StoreState } from '^/types';

export interface LedgerItemsContactOption {
  contact: string;
  lapsed?: boolean;
}

export interface RenderLedgerItemsOwnProps {
  contacts: ReadonlyArray<LedgerItemsContactOption>;
  readonly?: boolean;
  feeToNominalMapping?: { [feeType: string]: string | undefined };
  isBillingContainerSubscription?: boolean;
  dispatch?: Dispatch;
}

export type RenderLedgerItemsProps = WrappedFieldArrayProps<LedgerItem> &
  RenderLedgerItemsOwnProps &
  ConnectedProps<typeof connector>;

export class RenderLedgerItems extends React.Component<RenderLedgerItemsProps> {
  public render() {
    const {
      fields,
      contacts,
      ledger_items,
      readonly,
      feeToNominalMapping = {},
      meta,
      isBillingContainerSubscription = false,
    } = this.props;

    const items: LedgerItem[] = ledger_items ? ledger_items : [];
    const isLedgerItemsArrayPopulated = items.length > 0;

    const LEDGER_ITEM_MEMBERSHIP_OPTIONS = isLedgerItemsArrayPopulated
      ? [...new Set(items.map(item => item.membership!))]
      : [];

    const LEDGER_ITEM_PRACTICE_OPTIONS = isLedgerItemsArrayPopulated
      ? [...new Set(items.map(item => item.practice!))]
      : [];

    return (
      <Table fill>
        <TableHead>
          <TableRow>
            <TableHeader>Item name</TableHeader>
            <TableCell>Contact</TableCell>
            <TableCell title="Nominal code">Nom. code</TableCell>
            <TableCell title="VAT code">VAT code</TableCell>
            <TableCell>VAT Inc.</TableCell>
            <TableCell
              className="align-right"
              title="Goods amount (excluding VAT)"
            >
              Goods
            </TableCell>
            <TableCell className="align-right" title="VAT amount">
              VAT
            </TableCell>
            <TableCell
              className="align-right"
              title="Gross amount (including VAT)"
            >
              Gross
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item: string, index: number) => (
            <LedgerItemFormRow
              readonly={readonly}
              item={item}
              index={index}
              removeLedgerItem={this.removeLedgerItem}
              key={`ledgerItemRow-${item}`}
              contacts={contacts}
              feeToNominalMapping={feeToNominalMapping}
              ledgerItem={items[index] ? items[index] : emptyLedgerItem}
              meta={meta}
              isBillingContainerSubscription={isBillingContainerSubscription}
              ledgerItemMembershipOptions={
                isBillingContainerSubscription
                  ? LEDGER_ITEM_MEMBERSHIP_OPTIONS
                  : undefined
              }
              ledgerItemPracticeOptions={
                isBillingContainerSubscription
                  ? LEDGER_ITEM_PRACTICE_OPTIONS
                  : undefined
              }
              // @ts-ignore
              setContactIDFromPractice={this.setContactIDFromPractice}
            />
          ))}
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell colSpan={5} className="align-right">
              Totals:
            </TableCell>
            <TableCell className="align-right">
              <p>
                <strong>{formatFeeAmount(this.calculateAmount())}</strong>
              </p>
            </TableCell>
            <TableCell className="align-right">
              <p>
                <strong>{formatFeeAmount(this.calculateVATAmount())}</strong>
              </p>
            </TableCell>
            <TableCell className="align-right">
              <p>
                <strong>{formatFeeAmount(this.calculateGrossAmount())}</strong>
              </p>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell colSpan={8} className="align-right">
              <AppButton
                type="button"
                onClick={this.addLedgerItem}
                link
                disabled={readonly}
              >
                Add item
              </AppButton>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  public calculateAmount = () => {
    const { ledger_items = [] } = this.props;

    return ledger_items.reduce(amountReducer, 0);
  };

  public calculateVATAmount = () => {
    const { ledger_items = [] } = this.props;

    return ledger_items.reduce(vatReducer, 0);
  };

  public calculateGrossAmount = () => {
    return this.calculateAmount() + this.calculateVATAmount();
  };

  public removeLedgerItem = (index: number) => {
    this.props.fields.remove(index);
  };

  public addLedgerItem = () => {
    this.props.fields.push(
      this.props.isBillingContainerSubscription
        ? emptyPracticeLedgerItem
        : emptyLedgerItem
    );
  };

  public setContactIDFromPractice = (
    _event: SyntheticEvent,
    newValue: string,
    _previousValue: string,
    name: string
  ) => {
    const fieldRow = `${name.split('.')[0]}.contact`;
    const items: LedgerItem[] = this.props.ledger_items;
    const practices = [
      ...new Set(
        items.map(item => {
          return { name: item.practice, contactId: item.contact };
        })
      ),
    ];

    const matchingPractice = practices.find(element => {
      if (element.name === newValue) {
        return element.contactId;
      }
    });

    if (matchingPractice) {
      this.props.change(
        'generateInvoiceForm',
        fieldRow,
        matchingPractice.contactId!
      );
    }
  };
}

const emptyPracticeLedgerItem: LedgerItem = {
  membership: LedgerItemType.Other,
  item: LedgerItemType.Other,
  amount: 0,
  contact: '',
  practice: '',
  nominal_code: null,
  ledger_detail: null,
  quantity: 1,
  vat: null,
  vat_amount: 0,
  vat_inclusive: false,
};

const emptyLedgerItem: LedgerItem = {
  item: LedgerItemType.Other,
  amount: 0,
  contact: '',
  nominal_code: null,
  ledger_detail: null,
  quantity: 1,
  vat: null,
  vat_amount: 0,
  vat_inclusive: false,
};

// Disconnected version used for testing
export { RenderLedgerItems as TestableRenderLedgerItems };

export const mapState = (
  state: StoreState,
  props: WrappedFieldArrayProps<LedgerItem>
) => {
  const selector = formValueSelector(props.meta.form);

  return {
    ledger_items: selector(state, props.fields.name),
  };
};

const connector = connect(mapState, { change });

export default connector(RenderLedgerItems);

import { TableRow } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';

// Props that come from the parent component.
interface OwnProps<T> {
  item: T;
  onClick?(item: T): void;
}

export type ItemRowProps<T> = OwnProps<T>;

class ItemRow<T> extends React.PureComponent<ItemRowProps<T>> {
  public render() {
    return (
      <TableRow
        className={classnames({ selectable: this.props.onClick })}
        onClick={this.props.onClick && this.handleClick}
      >
        {this.props.children}
      </TableRow>
    );
  }

  public handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.item);
    }
  };
}

// Disconnected version used for testing
export { ItemRow as TestableItemRow };

export default ItemRow;

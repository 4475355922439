import React from 'react';

import { PublicPracticeList } from '../types';

export interface PracticeSelectorProps {
  practices?: PublicPracticeList;
  selectedPublicId?: string;
  setActivePractice: (selectedPublicId: string) => void;
}

class PracticeSelector extends React.PureComponent<PracticeSelectorProps> {
  public render() {
    const { practices, selectedPublicId } = this.props;
    const hasPractices = Boolean(practices && practices.length > 0);

    return (
      <div className="form-field pristine no-selection">
        <select onChange={this.onSelectChange} value={selectedPublicId}>
          {!hasPractices && <option disabled>No practices available</option>}
          {practices?.map(p => (
            <option key={p.public_id} value={p.public_id}>
              {p.name}
            </option>
          ))}
        </select>
      </div>
    );
  }

  public onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setActivePractice(event.target.value);
  };
}

export { PracticeSelector as TestablePracticeSelector };

export default PracticeSelector;

import { InitialUser, User, UserRole } from '^/admin/users/types';

export function getUserName(
  user?: User | InitialUser | null,
  defaultValue: string = '-'
): string {
  if (!user) {
    return defaultValue;
  }

  const fullName = [user.first_name, user.last_name].filter(Boolean).join(' ');

  return fullName || user.email || defaultValue;
}

export function getShortUserName(
  user?: User | InitialUser | null,
  defaultValue: string = '-'
): string {
  if (!user) {
    return defaultValue;
  }

  return user.first_name || user.last_name || user.email || defaultValue;
}

export function withoutItem<T extends { id: string }>(
  items: ReadonlyArray<T>,
  item: T
) {
  return items.filter(each => each.id !== item.id);
}

export function isRoleAgilioType(role: UserRole | undefined): boolean {
  if (role === undefined) {
    return false;
  }

  const agilioRoles = [
    UserRole.AdminLevel,
    UserRole.FinanceLevel,
    UserRole.OfficeLevel,
    UserRole.SalesLevel,
  ];
  return agilioRoles.includes(role);
}

export function isRolePracticeType(role: UserRole | undefined): boolean {
  if (role === undefined) {
    return false;
  }

  const practiceRoles = [
    UserRole.PracticeAdminLevel,
    UserRole.PracticeUserLevel,
    UserRole.PracticePatientLevel,
  ];
  return practiceRoles.includes(role);
}

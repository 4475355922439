import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, Middleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import {
  checkForLogoutMiddleware,
  loggedInUser,
} from '^/middleware/check-for-logout';
import pollTasksMiddleware from '^/middleware/poll-tasks';
import rootReducer from '^/reducers';
import { StoreState } from '^/types';

export const history = createBrowserHistory();

const middleware: Array<Middleware<{}, StoreState>> = [
  routerMiddleware(history),
  thunk,
  checkForLogoutMiddleware,
  pollTasksMiddleware,
];

export const store = createStore(
  rootReducer,
  { loggedInUser },
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

import { anyPending } from '@dabapps/redux-requests';
import { isPending } from '@dabapps/redux-requests/dist/js';
import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { ErrorPage } from '^/common/error-page';
import HeaderBar from '^/common/header-bar';
import { getContactName } from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import { GET_PLAN, getPlan } from '^/iplan/actions';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import ClinicianPresetUrlsTable from '^/plans/edit/clinician-preset-urls-table';
import { getPlanName } from '^/plans/helpers';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

type RouteProps = RouteComponentProps<{ id: string }>;

export const mapState = (state: StoreState, props: RouteProps) => {
  const plan = getItemFromCache(props.match.params.id, state.planCache);

  return {
    plan,
    contact: plan
      ? getItemFromCache(plan.practice, state.contactsCache)
      : undefined,
    loading: {
      all: anyPending(state.responses, [GET_CONTACT, GET_PLAN]),
      plan: isPending(state.responses, GET_PLAN),
    },
  };
};

const connector = connect(mapState, {
  getPlan,
  getContact,
});

export type PlanDetailsPageProps = ConnectedProps<typeof connector> &
  RouteProps;

class PlanDetailsPage extends React.PureComponent<PlanDetailsPageProps> {
  public componentDidMount() {
    const { plan, match } = this.props;

    if (cachedItemHasExpired(plan)) {
      this.props.getPlan(match.params.id);
    }
  }

  public componentDidUpdate(prevProps: PlanDetailsPageProps) {
    if (this.props.plan !== prevProps.plan && this.props.plan?.practice) {
      this.props.getContact(this.props.plan.practice);
    }
  }

  public render() {
    const { plan, contact, loading } = this.props;

    if (!contact || !plan) {
      if (loading.all) {
        return (
          <PageContent>
            <BreadcrumbBar
              items={[
                ['Practice Details', '/practice-details'],
                { label: getPlanName(plan, true), loading: true },
              ]}
            />
            <HeaderBar title="Plan" transparent loading />
            <Loading />
          </PageContent>
        );
      }

      return (
        <ErrorPage heading={`${!contact ? 'Contact' : 'Plan'} not found`} />
      );
    }

    return (
      <PageContent>
        <BreadcrumbBar
          items={[
            ['Practice Details', '/practice-details'],
            { label: getPlanName(plan, true), loading: loading.plan },
          ]}
        />
        <HeaderBar
          transparent
          title={getPlanName(plan)}
          subtitle={`${plan.description} for ${getContactName(contact)}${
            plan.status === 'ARCHIVED' ? ' (Archived)' : ''
          }`}
          loading={loading.plan}
        />
        <Container>
          <main>
            <div className="flex-row">
              <div className="flex-column flex-main">
                <PageSection>
                  {contact.company && plan && (
                    <PageSubSection
                      heading="Clinician Direct Sign-up Links"
                      vertical
                    >
                      <ClinicianPresetUrlsTable
                        practiceId={contact.id}
                        practicePublicId={contact.company.public_id}
                        planId={plan.id}
                      />
                    </PageSubSection>
                  )}
                </PageSection>
              </div>
            </div>
          </main>
        </Container>
      </PageContent>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { PlanDetailsPage as TestablePlanEditPage };

export default connector(PlanDetailsPage);

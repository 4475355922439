import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import { Modal, ModalBody, ModalHeader } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { submit } from 'redux-form';

import AppButton from '^/common/app-button';
import { getContactName } from '^/common/helper-functions';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import {
  GET_DENTISTS_BY_PRACTICE,
  getDentistsByPractice,
} from '^/contacts/persons/patients/actions';
import DentistPickerForm from '^/contacts/persons/patients/practice-picker/dentist-picker';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

// Props that come from the parent component.
interface OwnProps {
  practiceId: string;
  dentistId: string;
  onConfirmTransfer(originalContact: string, newContact: string): void;
  onCloseModal(): void;
}

// State props that come from redux.
interface StateProps {
  newDentistId?: string;
}

export type RelationshipTransferModalProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class RelationshipTransferModal extends React.PureComponent<
  RelationshipTransferModalProps
> {
  public componentDidMount(): void {
    this.props.getDentistsByPractice(this.props.practiceId);
    this.props.getContact(this.props.dentistId);
  }

  public componentDidUpdate(prevProps: RelationshipTransferModalProps): void {
    if (
      this.props.newDentistId &&
      this.props.newDentistId !== prevProps.newDentistId
    ) {
      this.props.getContact(this.props.newDentistId);
    }
  }

  public render() {
    const {
      dentistsByPractice,
      practiceId,
      practice,
      loading,
      patientCount,
      newDentistId,
      dentistId,
      contactsCache,
    } = this.props;

    const practiceName = getContactName(practice);
    const dentistName = getContactName(
      getItemFromCache(dentistId, contactsCache)
    );
    const newDentistName = getContactName(
      getItemFromCache(newDentistId, contactsCache)
    );

    const practiceDentists = dentistsByPractice[practiceId] || [];
    const dentists = practiceDentists.filter(
      dentist => dentistId !== dentist.id
    );

    return (
      <Modal onClickOutside={this.props.onCloseModal}>
        <ModalHeader>
          <h2>{`Change dentist for ${patientCount} patients`}</h2>
          <AppButton transparent onClick={this.props.onCloseModal}>
            <FontAwesomeIcon icon="times" />
          </AppButton>
        </ModalHeader>
        <ModalBody className={classnames({ loading })}>
          {!newDentistId ? (
            <>
              <div className="modal-body-section">
                <DentistPickerForm
                  dentists={dentists}
                  onSubmit={this.dentistSelected}
                />
              </div>
              <div className="modal-body-section form-buttons">
                <AppButton primary onClick={this.selectDentist}>
                  Change
                </AppButton>
              </div>
            </>
          ) : (
            <>
              <div className="modal-body-section">
                <p>
                  Are you sure you want to change the dentist for {practiceName}{' '}
                  from <b>{dentistName}</b> to <b>{newDentistName}</b>
                </p>
                <p className="error">
                  This will change the dentist for {patientCount} patients
                </p>
              </div>
              <div className="modal-body-section form-buttons">
                <AppButton onClick={this.props.onCloseModal}>
                  No - Cancel
                </AppButton>
                <AppButton
                  primary
                  onClick={this.onSubmit.bind(this, newDentistId)}
                >
                  Yes - Change the dentist for {patientCount} patients
                </AppButton>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }

  public selectDentist = () => {
    this.props.submit('dentistPickerForm');
  };

  public dentistSelected = (selectedDentist: { dentist: string }) => {
    this.props.setProps({ newDentistId: selectedDentist.dentist });
  };

  public onSubmit = (newDentistId: string) => {
    this.props.onConfirmTransfer(this.props.dentistId, newDentistId);
  };
}

// Disconnected version used for testing
export { RelationshipTransferModal as TestableRelationshipTransferModal };

export const getInitialProps = (): StateProps => ({});

export const mapState = (state: StoreState, props: OwnProps) => ({
  dentistsByPractice: state.dentistsByPractice,
  loading: anyPending(state.responses, [GET_DENTISTS_BY_PRACTICE, GET_CONTACT]),
  patientCount:
    state.performerPatientCounts[`${props.practiceId}${props.dentistId}`],
  contactsCache: state.contactsCache,
  practice: getItemFromCache(props.practiceId, state.contactsCache),
});

const connector = connect(mapState, {
  getDentistsByPractice,
  getContact,
  submit,
});

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(RelationshipTransferModal)
);

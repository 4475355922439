import classNames from 'classnames';
import React from 'react';

class AppButtonGroup extends React.PureComponent {
  public render() {
    return (
      <div className={classNames('app-button-group')}>
        {this.props.children}
      </div>
    );
  }
}

// Disconnected version used for testing
export { AppButtonGroup as TestableAppButtonGroup };

export default AppButtonGroup;

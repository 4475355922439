import { ReactSelectOption } from '^/form-helpers/render-react-select';

export const numberWithCommas = (x: number): string => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export function isSingularReactSelectOption(
  value: ReactSelectOption | readonly ReactSelectOption[]
): value is ReactSelectOption {
  return !Array.isArray(value);
}

export const uuidRegex =
  '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { calculateGoodsFromGross, calculateGrossFromGoods } from '^/fees/utils';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderCurrencyField from '^/form-helpers/render-currency-field';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderVatCodePicker from '^/form-helpers/render-vat-code-picker';
import { Insurance } from '^/insurance/types';
import { StoreState } from '^/types';

interface OwnProps {
  onCancel(): void;
}

const selector = formValueSelector('practiceInsuranceForm');

export type PracticeInsuranceFormProps = OwnProps &
  InjectedFormProps<Insurance, OwnProps> &
  ConnectedProps<typeof connector>;

class PracticeInsuranceForm extends React.PureComponent<
  PracticeInsuranceFormProps
> {
  public componentDidMount() {
    const { amount, vat_code } = this.props;

    this.props.change(
      'monthly_fee.gross_amount',
      calculateGrossFromGoods(Number(amount), vat_code)
    );
  }

  public componentDidUpdate(prevProps: PracticeInsuranceFormProps) {
    const { amount, gross_amount, vat_inclusive, vat_code } = this.props;

    if (
      (prevProps.gross_amount !== gross_amount ||
        prevProps.vat_code !== vat_code) &&
      vat_inclusive
    ) {
      this.props.change(
        `monthly_fee.amount`,
        calculateGoodsFromGross(Number(gross_amount), vat_code)
      );
    }

    if (
      (prevProps.amount !== amount || prevProps.vat_code !== vat_code) &&
      !vat_inclusive
    ) {
      this.props.change(
        'monthly_fee.gross_amount',
        calculateGrossFromGoods(Number(amount), vat_code)
      );
    }
  }

  public render() {
    const { handleSubmit, vat_inclusive } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            label="Goods amount"
            name="monthly_fee.amount"
            component={RenderCurrencyField}
            type="text"
            stretch
            disabled={vat_inclusive}
          />
          <Field
            label="Gross amount"
            name="monthly_fee.gross_amount"
            component={RenderCurrencyField}
            type="text"
            stretch
            disabled={!vat_inclusive}
          />
          <Field
            label="VAT inclusive"
            name="monthly_fee.vat_inclusive"
            vertical
            component={RenderCheckBox}
          />
        </FormGroup>
        <FormGroup>
          <Field
            label="VAT Code"
            name="monthly_fee.vat_code"
            component={RenderVatCodePicker}
            type="text"
            short
          />
          <Field
            label="Increase amount"
            name="monthly_fee.new_amount"
            component={RenderCurrencyField}
            stretch
            type="text"
          />
          <Field
            label="Increase date"
            name="monthly_fee.new_amount_applies"
            component={RenderDateField}
            type="text"
            short
          />
        </FormGroup>
        <ErrorRenderer fields={['non_field_errors']} showStatusErrors />
        <div className="form-buttons">
          <AppButton type="button" onClick={this.props.onCancel}>
            Cancel
          </AppButton>
          <AppButton type="submit" primary>
            Save
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { PracticeInsuranceForm as TestablePracticeInsuranceForm };

export const mapState = (state: StoreState) => ({
  gross_amount: selector(state, 'monthly_fee.gross_amount'),
  amount: selector(state, 'monthly_fee.amount'),
  vat_inclusive: selector(state, 'monthly_fee.vat_inclusive'),
  vat_code: selector(state, 'monthly_fee.vat_code'),
});

const connector = connect(mapState, {});

export default reduxForm<Insurance, OwnProps>({
  form: 'practiceInsuranceForm',
})(connector(PracticeInsuranceForm));

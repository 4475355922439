import { Container, Nav, NavBar, NavItem } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import SettingsButton from '^/login/settings-button';
import AppSwitcher from '^/navigation/app-switcher';
import { StoreState } from '^/types';

export type NavigationBarProps = ConnectedProps<typeof connector>;

export const config = {
  icon: '/static/app-switcher-icon.svg',
  agilio: {
    icon: '/static/android-chrome-192x192.png',
  },
  iComply: {
    icon: '/static/icomply.png',
    url: window.APP_SWITCHER_ICOMPLY_URL,
    readmore: 'https://agiliosoftware.com/dental/icomply/',
  },
  iLearn: {
    icon: '/static/ilearn.png',
    url: window.APP_SWITCHER_ILEARN_URL,
    readmore: 'https://agiliosoftware.com/dental/ilearn/',
  },
  iService: {
    icon: '/static/iservice.png',
    url: window.APP_SWITCHER_ISERVICE_URL,
    readmore: 'https://agiliosoftware.com/dental/iservice/',
  },
  iTeam: {
    icon: '/static/iteam.png',
    url: window.APP_SWITCHER_ITEAM_URL,
    readmore: 'https://agiliosoftware.com/dental/iteam/',
  },
  iShop: {
    icon: '/static/ishop.png',
    url: window.APP_SWITCHER_ISHOP_URL,
    readmore: '',
  },
};

export class NavigationBar extends React.PureComponent<NavigationBarProps> {
  public render() {
    const { loggedInUser } = this.props;

    const showAdmin =
      loggedInUser &&
      (loggedInUser.role === UserRole.AdminLevel || loggedInUser.is_superuser);

    const { USE_APP_SWITCHER } = window;

    return (
      <NavBar>
        <Container>
          <Nav>
            {USE_APP_SWITCHER && (
              <NavItem className="app-switcher">
                <AppSwitcher config={config} />
              </NavItem>
            )}
            <NavItem className="logo">
              <NavLink to="/" exact>
                <img src="/static/agilio-iplan-logo.svg" />
              </NavLink>
            </NavItem>
          </Nav>
          <Nav>
            <NavItem>
              <NavLink to="/contacts">Records</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/practices">Practices</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/ledger">Ledger</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/activities">
                {showAdmin ? 'Activities' : 'My Activities'}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/routines">Routines</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/letters">Letters</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/practice-users">Users</NavLink>
            </NavItem>
          </Nav>
          {loggedInUser && (
            <Nav className="nav-right">
              <Allow roles={[UserRole.AdminLevel]}>
                <NavItem>
                  <NavLink to="/admin">Admin</NavLink>
                </NavItem>
              </Allow>
              <NavItem className="login">
                <SettingsButton />
              </NavItem>
            </Nav>
          )}
        </Container>
      </NavBar>
    );
  }
}

export const mapState = (state: StoreState) => ({
  loggedInUser: state.loggedInUser,
});

const connector = connect(mapState, {});

export default connector(NavigationBar);

import { JSONObject } from '@dabapps/simple-records';

import { User } from '^/admin/users/types';

export enum ReportParam {
  BulkReport = 'bulk_report',
  Category = 'category',
  Practice = 'practice',
  ReceiptTo = 'bank',
  Month = 'month',
  RunFrom = 'start_date',
  RunTo = 'end_date',
  PaymentMethod = 'payment_method',
  ReceiptDate = 'entry_date',
}

export interface Report {
  readonly id?: string;
  readonly modified?: string;
  readonly created?: string;
  readonly created_by?: string;
  readonly created_by_detail?: User | null;
  readonly started?: string | null;
  readonly completed?: string | null;
  report_type: ReportType;
  report_category: ReportCategory;
  readonly report?: string | null;
  readonly errors?: string | null;
  readonly apply_file?: string | null;
  readonly apply_errors?: string | null;
  readonly status?: ReportStatus;
  readonly status_detail?: string;
  description?: string;
  params?: JSONObject;
  requiredParams?: JSONObject;
  request_data?: object;
}

export type ReportResponse = Readonly<Required<Omit<Report, 'request_data'>>>;

export enum ReportCategory {
  Generic = 'Generic',
  Plan = 'Plans',
  Membership = 'Memberships',
}

export enum ReportType {
  InvoiceReport = 'Invoice report',
  Invoices = 'Invoices',
  NewAuddisRequests = 'New AUDDIS requests',
  FirstBacsMandates = 'First BACS mandates',
  ManualBacsRun = 'Manual BACS run',
  BacsRun = 'BACS run',
  LapseReport = 'Lapse report',
  RenewalReport = 'Renewal report',
  FeesUpdate = 'Fees update',
  SubContactLapseReport = 'SubContact Lapse',
  SAGEInvoicesCredits = 'SAGE Invoices and Credits',
  SAGEReceiptsRefunds = 'SAGE Receipts and Refunds',
  Letters = 'Letters',
  DebtorReport = 'Debtor Report',
  PracticeReport = 'Practice Report',
  CurrentPatientsAndIncomeReport = 'Current Patients and Income',
}

export enum ReportStatus {
  Queued = 'Queued',
  ErrorInRequest = 'Error in request',
  Preparing = 'Preparing',
  Uploading = 'Uploading',
  Completed = 'Completed',
  CompletedWithErrors = 'Completed with errors',
  Failed = 'Failed',
  Stalled = 'Stalled',
  Applying = 'Applying',
  Applied = 'Applied',
  AppliedWithErrors = 'Applied with errors',
  ApplyFailed = 'Apply failed',
}

export interface BulkReportParams {
  readonly month?: string;
  readonly start_date?: string;
  readonly end_date?: string;
}

export interface BulkReport {
  readonly id: string;
  readonly failed_companies: ReadonlyArray<string> | null;
  readonly params: BulkReportParams;
  readonly report_type: ReportType;
  readonly zipped_reports: string | null;
}

import { Column, Container, Row } from '@dabapps/roe';
import React from 'react';

import HeaderBar from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import PageSubSection, {
  PageSubSectionBody,
} from '^/common/page-section/page-sub-section';
import PageContent from '^/page-container/page-content';
import InspatConfig, { InspatCode } from './inspat-config';
import InspracConfig from './insprac-config';
import TaxConfig from './tax-config';

class InsurancePage extends React.PureComponent {
  public render() {
    return (
      <PageContent>
        <HeaderBar title="Cover Configuration" transparent />
        <Container>
          <Row>
            <Column xs={12} lg={10}>
              <PageSection>
                <TaxConfig />
                <PageSubSection>
                  <PageSubSectionBody>
                    <InspatConfig code={InspatCode.INSPAT} />
                  </PageSubSectionBody>
                  <PageSubSectionBody>
                    <InspracConfig />
                  </PageSubSectionBody>
                </PageSubSection>
              </PageSection>
            </Column>
          </Row>
        </Container>
      </PageContent>
    );
  }
}

// Disconnected version used for testing
export { InsurancePage as TestableInsurancePage };

export default InsurancePage;

import { anyPending } from '@dabapps/redux-requests';
import { Container, Tab, Tabs } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import ActivitiesListComponent from '^/activities/browser';
import ContactPopups from '^/activities/popups';
import AppButton from '^/common/app-button';
import { ErrorPage } from '^/common/error-page';
import { getContactName } from '^/common/helper-functions';
import { Loading } from '^/common/loading';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import ArchiveContact from '^/contacts/archive-contact';
import ContactHeaderBar from '^/contacts/header-bar';
import LedgerTab from '^/contacts/ledger/ledger-tab';
import {
  getPracticeForPatient,
  patientActions,
} from '^/contacts/persons/patients/actions';
import PatientPracticePicker from '^/contacts/persons/patients/practice-picker';
import PatientDetailsTab from '^/contacts/persons/patients/tabs/details-tab';
import BreadcrumbBar, { BreadcrumbBarItem } from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import {
  cachedItemHasExpired,
  contactMissingDetail,
  getItemFromCache,
} from '^/utils/cache-helpers';

export type PatientsDetailPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps<{ contactId: string }>;

class PatientsDetailPage extends React.PureComponent<PatientsDetailPageProps> {
  public componentDidMount() {
    const {
      patient,
      linkedPractice,
      match: { params },
    } = this.props;

    if (contactMissingDetail(patient)) {
      this.props.getContact(params.contactId);
    }

    if (cachedItemHasExpired(linkedPractice)) {
      this.props.getPracticeForPatient(params.contactId);
    }
  }

  public componentDidUpdate(prevProps: PatientsDetailPageProps) {
    const { linkedPractice } = this.props;

    if (
      linkedPractice !== prevProps.linkedPractice &&
      cachedItemHasExpired(linkedPractice)
    ) {
      this.props.getPracticeForPatient(this.props.match.params.contactId);
    }
  }

  public render() {
    const {
      patient,
      linkedPractice,
      loading,
      match: {
        params: { contactId: contactId },
      },
    } = this.props;

    if (!patient) {
      if (loading.details) {
        return (
          <PageContent>
            <BreadcrumbBar
              items={[['Practices', '/practices'], 'Loading...', 'Loading...']}
            />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Patient not found" />;
    }

    return (
      <PageContent>
        <BreadcrumbBar items={this.getBreadcrumbItems()} />
        <ContactHeaderBar
          contact={patient}
          primaryActions={
            <PatientPracticePicker
              contact={patient}
              linkedPractice={linkedPractice}
              loading={loading.details && loading.linkedPractice}
            />
          }
          secondaryActions={[
            <AppButton
              key="export-activities"
              url={`/api/activities/csv/?associated_contact=${patient.id}`}
              small
            >
              Export Patient Activities
            </AppButton>,
            <AppButton
              key="export-contact"
              url={`/api/letters/export-contact/${patient.id}/`}
              small
            >
              Export Patient for Letters
            </AppButton>,
            <ArchiveContact key="archive" contact={patient} />,
            ,
          ]}
          loading={loading.details}
        />
        <Container>
          <Tabs>
            <Tab>
              <NavLink to={`${this.props.match.url}/details`}>Details</NavLink>
            </Tab>
            <Tab>
              <NavLink to={`${this.props.match.url}/ledger`}>Ledger</NavLink>
            </Tab>
            <Tab>
              <NavLink to={`${this.props.match.url}/activities`}>
                Record Activities
              </NavLink>
            </Tab>
          </Tabs>

          <Switch>
            <Route
              path={`${this.props.match.path}/details`}
              component={PatientDetailsTab}
            />
            <Route
              path={`${this.props.match.path}/ledger`}
              component={LedgerTab}
            />
            <Route
              path={`${this.props.match.path}/activities/:activityId`}
              component={ActivitiesListComponent}
            />
            <Route
              path={`${this.props.match.path}/activities`}
              component={ActivitiesListComponent}
            />
            <Route component={this.redirect} />
          </Switch>
        </Container>

        <ContactPopups contactId={contactId} />
      </PageContent>
    );
  }

  public redirect = () => <Redirect to={`${this.props.match.url}/details`} />;

  private getBreadcrumbItems = (): ReadonlyArray<
    BreadcrumbBarItem | [string, string] | string
  > => {
    const { loading, linkedPractice, patient } = this.props;

    if (loading.linkedPractice || linkedPractice) {
      return [
        ['Practices', '/practices'],
        {
          label: getContactName(
            linkedPractice,
            undefined,
            loading.linkedPractice ? 'Loading...' : 'Unknown Practice'
          ),
          url: linkedPractice ? `/practices/${linkedPractice.id}` : undefined,
          loading: loading.linkedPractice,
        },
        getContactName(patient),
      ];
    }

    return [['Records', '/contacts'], getContactName(patient)];
  };
}

export { PatientsDetailPage as TestablePatientsDetailPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{ contactId: string }>
) => ({
  patient: getItemFromCache(props.match.params.contactId, state.contactsCache),
  linkedPractice: getItemFromCache(
    state.patientPracticeMapping[props.match.params.contactId],
    state.contactsCache
  ),
  loading: {
    details: anyPending(state.responses, [GET_CONTACT]),
    linkedPractice: anyPending(state.responses, patientActions),
  },
});

const connector = connect(mapState, {
  getContact,
  getPracticeForPatient,
});

export default connector(PatientsDetailPage);

import { UserResponse } from '^/admin/users/types';
import { VatCode } from '^/fees/types';
import { MembershipSubscriptionResponse } from '^/memberships/subscriptions/types';
import { PlanSubscriptionResponse } from '^/plans/subscriptions/types';
import { ContactResponse } from '../types';

export enum LedgerEntryType {
  Invoice = 'INV',
  RecordReceipt = 'REC',
  CreditNote = 'CR',
  RecordReject = 'REJ',
  RecordRefund = 'REF',
}

export const LEDGER_ENTRY_TYPES = {
  [LedgerEntryType.Invoice]: 'Invoice',
  [LedgerEntryType.RecordReceipt]: 'Receipt',
  [LedgerEntryType.CreditNote]: 'Credit note',
  [LedgerEntryType.RecordReject]: 'Reject',
  [LedgerEntryType.RecordRefund]: 'Refund',
};

export type LedgerModalType =
  | LedgerEntryType
  | 'Allocation'
  | 'Product Invoice';

export type LedgerFilters = Readonly<{
  /** Startdate for filters */
  startDate?: Date;
  /** Enddate for filters */
  endDate?: Date;
  /** Transaction type for filters */
  type?: string;
  /** Show outstanding entries only */
  outstanding?: boolean;
  /** Show unallocated entries only */
  unallocated?: boolean;
  /** Filter by transaction ID */
  tx_id?: string;
}>;

export interface LedgerAllocation {
  ledger_to: string;
  ledger_from?: string;
  amount: string;
  outstanding?: number;
}

export enum PaymentMethod {
  DirectDebit = 'Direct Debit',
  Cheque = 'Cheque',
  Cash = 'Cash',
  CreditCard = 'Credit Card',
  StandingOrder = 'S/Order',
  DebitCard = 'Debit Card',
}

export const PAYMENT_METHODS = {
  [PaymentMethod.DirectDebit]: 'Direct Debit',
  [PaymentMethod.StandingOrder]: 'Standing Order',
  [PaymentMethod.Cash]: 'Cash',
  [PaymentMethod.Cheque]: 'Cheque',
  [PaymentMethod.CreditCard]: 'Credit Card',
  [PaymentMethod.DebitCard]: 'Debit Card',
};

export const PAYMENT_METHOD_OPTIONS = [
  {
    label: PAYMENT_METHODS[PaymentMethod.DirectDebit],
    value: PaymentMethod.DirectDebit,
  },
  {
    label: PAYMENT_METHODS[PaymentMethod.StandingOrder],
    value: PaymentMethod.StandingOrder,
  },
  {
    label: PAYMENT_METHODS[PaymentMethod.Cash],
    value: PaymentMethod.Cash,
  },
  {
    label: PAYMENT_METHODS[PaymentMethod.Cheque],
    value: PaymentMethod.Cheque,
  },
  {
    label: PAYMENT_METHODS[PaymentMethod.CreditCard],
    value: PaymentMethod.CreditCard,
  },
  {
    label: PAYMENT_METHODS[PaymentMethod.DebitCard],
    value: PaymentMethod.DebitCard,
  },
];

export enum Bank {
  Code = 'CODE',
  CodePlan = 'CODEPLAN',
  CodePayPal = 'CODE_PAYPAL',
  ClientAccount = 'CLIENT_ACCOUNT',
}

export const BANK: { [bank: string]: string } = {
  [Bank.Code]: 'Code',
  [Bank.CodePlan]: 'CodePlan',
  [Bank.CodePayPal]: 'CODE PayPal',
  [Bank.ClientAccount]: 'Client Account',
};

export const BANK_OPTIONS = Object.entries(BANK).map(value => ({
  value: value[0],
  label: value[1],
}));

export interface LedgerItem {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  item?: LedgerItemType | string;
  item_code?: string | null;
  contact?: string;
  amount: number;
  vat_amount?: number | null;
  gross_amount?: number;
  nominal_code?: string | null;
  quantity?: number;
  ledger_detail?: string | null;
  vat?: VatCode | null;
  delivery?: number;
  vat_inclusive?: boolean;
  membership?: string;
  practice?: string;
  readonly item_display?: string;
}

export type LedgerItemResponse = Readonly<Required<LedgerItem>>;

export enum LedgerItemType {
  AdminFee = 'Admin Fee',
  INSPRAC = 'INSPRAC',
  SubscriptionFee = 'Subscription Fee',
  JoiningFee = 'Joining Fee',
  Other = 'Other',
  FamilyDiscount = 'Family Discount',
  Promotion = 'Promotional Price',
  Product = 'Product',
}

export interface RejectData {
  type: LedgerEntryType;
  contact_1: string;
  amount: string;
  detail?: string;
  detail_detail?: LedgerDetail;
  entry_date: string;
  allocations: ReadonlyArray<Allocation>;
  vat_amount: number;
}

export interface Ledger {
  readonly id?: string;
  readonly created?: string;
  type: LedgerEntryType;
  allocations?: ReadonlyArray<LedgerAllocation>;
  amount: number;
  nominal_code?: string | null;
  vat_amount: number;
  contact_1: string;
  readonly contact_1_detail?: ContactResponse;
  contact_2?: string;
  readonly contact_2_detail?: ContactResponse;
  detail: string;
  detail_detail: LedgerDetail;
  entry_date: string;
  readonly tx_id?: string;
  payment_method?: PaymentMethod;
}

export interface LedgerEntryAllocationData {
  type: LedgerEntryType;
  allocations?: ReadonlyArray<LedgerAllocation>;
  contact_1: string;
  amount?: string;
  vat_amount: number;
  detail_detail?: LedgerDetail;
  entry_date?: string;
  payment_method?: string;
}

export type LedgerResponse = Readonly<
  Required<Omit<Ledger, 'detail_detail'>>
> & {
  detail_detail: Required<LedgerDetailResponse>;
};

export interface LedgerDetail {
  accounts_reference?: string;
  tx_id?: number | null;
  readonly allocated?: number | null;
  bank?: Bank | null;
  collection_date?: string | null;
  comments?: string;
  contact?: string | null;
  readonly created?: string;
  due_date?: string | null;
  end_date?: string | null;
  entry_type?: LedgerEntryType | null;
  fee_collected_from?: string | null;
  readonly id?: string;
  invoiced?: boolean;
  readonly modified?: string;
  readonly outstanding_amount?: number | null;
  source?: string;
  start_date?: string | null;
  readonly status?: string;
  subscription?: string | null;
  total_amount?: number;
  total_vat_amount?: number;
  values_overriden?: boolean;
  vat?: VatCode | null;
  vat_nominal_collected_from?: string | null;

  items?: ReadonlyArray<LedgerItem>;
  ledger_items?: ReadonlyArray<LedgerItem>;
  readonly created_by?: UserResponse | null;
  readonly subscription_detail?:
    | PlanSubscriptionResponse
    | MembershipSubscriptionResponse
    | null;
}

// TODO: Fix this type definition by resolving issue where items != ledger_items backend
export type LedgerDetailNoItems = Omit<LedgerDetail, 'items' | 'ledger_items'>;
export type LedgerDetailResponse = Readonly<Required<LedgerDetailNoItems>> & {
  ledger_items: ReadonlyArray<LedgerItemResponse>;
};

export interface Allocation {
  amount: number;
  comments?: string;
  readonly created?: string;
  readonly id?: string;
  readonly is_reversed?: boolean;
  ledger_from: string;
  readonly ledger_from_detail?: LedgerDetailResponse;
  ledger_to?: string;
  readonly ledger_to_detail?: LedgerDetailResponse;
  readonly modified?: string;
}

export type AllocationResponse = Readonly<Required<Allocation>>;

export interface ProductInvoice {
  readonly id?: string;
  readonly created?: string;
  type: LedgerEntryType;
  amount: number;
  vat_amount: number;
  contact_1: string;
  entry_date: string;
  payment_method?: string;
  ledger_items: ReadonlyArray<LedgerItem>;
  comments?: string;
}
export type ProductInvoiceResponse = Readonly<Required<ProductInvoice>>;

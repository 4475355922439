import { anyPending } from '@dabapps/redux-requests';
import { ModalBody } from '@dabapps/roe';
import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RESEND_INVITE, resendInvite } from '^/admin/users/actions';
import SimpleModal from '^/modals/simple-modal';
import { StoreState } from '^/types';

interface State {
  responseMsg: string;
  sending: boolean;
}

interface OwnProps {
  userId: string;
}

export type ResendInviteModalProps = ConnectedProps<typeof connector> &
  OwnProps;

export class ResendInviteModal extends React.PureComponent<
  ResendInviteModalProps,
  State
> {
  constructor(props: ResendInviteModalProps) {
    super(props);
    this.state = {
      responseMsg: '',
      sending: true,
    };
  }

  public componentDidMount() {
    this.props
      .resendInvite(this.props.userId)
      .then((response: void | AxiosResponse<any>) => {
        if (response && response.data) {
          this.setState({ responseMsg: response.data });
        }
      })
      .catch((error: AxiosError) => {
        if (error && error.response) {
          this.setState({ responseMsg: error.response.data });
        }
      });
  }

  public render() {
    const { sending } = this.props;
    const { responseMsg } = this.state;

    return (
      <SimpleModal heading="Resend Invite">
        <ModalBody>
          <div className="modal-body-section">
            <p>{sending ? 'Sending...' : responseMsg}</p>
          </div>
        </ModalBody>
      </SimpleModal>
    );
  }
}

export { ResendInviteModal as TestableResendInviteModal };

const mapState = (store: StoreState) => ({
  sending: anyPending(store.responses, [RESEND_INVITE]),
});
const mapDispatch = {
  resendInvite,
};
const connector = connect(mapState, mapDispatch);
export default connector(ResendInviteModal);

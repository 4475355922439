import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { StoreState } from '^/types';
import { UserRole } from '../admin/users/types';
import { CMMRoutes } from './routes-cmm';
import { IPlanRoutes } from './routes-iplan';
import { IPlanPatientRoutes } from './routes-iplan-patient';
import { IPlanUserRoutes } from './routes-iplan-user';

export const mapState = (state: StoreState) => ({
  loggedInUser: state.loggedInUser,
});

const connector = connect(mapState);

export type RoutesProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

class Routes extends React.PureComponent<RoutesProps> {
  public render() {
    const { loggedInUser, location } = this.props;

    if (!loggedInUser) {
      return (
        <Redirect
          to={`/login?redirect=${location.pathname}${location.search}`}
        />
      );
    }

    if (loggedInUser.role === UserRole.PracticeAdminLevel) {
      return <IPlanRoutes />;
    } else if (loggedInUser.role === UserRole.PracticeUserLevel) {
      return <IPlanUserRoutes />;
    } else if (loggedInUser.role === UserRole.PracticePatientLevel) {
      return <IPlanPatientRoutes />;
    }
    return <CMMRoutes />;
  }
}

// Disconnected version used for testing
export { Routes as TestableRoutes };

export default connector(Routes);

import { anyPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  FieldArray,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import { CREATE_COLLECTION, SAVE_COLLECTION } from '^/collections/actions';
import { Collection } from '^/collections/types';
import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { required } from '^/common/validation';
import { GET_CONTACT, getContact } from '^/contacts/actions';
import { CREATE_INVOICE } from '^/contacts/ledger/actions';
import { LedgerItemType } from '^/contacts/ledger/types';
import RenderDateField from '^/form-helpers/render-date-field';
import { RenderDateTimeField } from '^/form-helpers/render-date-time-field';
import RenderLedgerItems, {
  RenderLedgerItemsOwnProps,
} from '^/form-helpers/render-ledger-items';
import { RenderTextArea } from '^/form-helpers/render-text-area';
import { getPlanSubscription } from '^/plans/subscriptions/actions';
import { StoreState } from '^/types';
import { cachedItemHasExpired, getItemFromCache } from '^/utils/cache-helpers';

interface OwnProps {
  onClickBack: () => void;
  subscriptionId: string | null | undefined;
  invoiceMode?: boolean;
  actionMsg?: string;
  disabled?: boolean;
}

const selector = formValueSelector('generateInvoiceForm');

export const mapState = (state: StoreState) => ({
  detail: selector(state, 'detail'),
  contactsCache: state.contactsCache,
  planSubscriptionCache: state.planSubscriptionCache,
  loading: anyPending(state.responses, [
    CREATE_COLLECTION,
    SAVE_COLLECTION,
    GET_CONTACT,
    CREATE_INVOICE,
  ]),
});

const connector = connect(mapState, { getPlanSubscription, getContact });

export type CollectionFormProps = InjectedFormProps<Collection, OwnProps> &
  OwnProps &
  ConnectedProps<typeof connector>;

export class CollectionForm extends React.PureComponent<CollectionFormProps> {
  public componentDidMount(): void {
    const { planSubscriptionCache, contactsCache, subscriptionId } = this.props;

    const subscription = getItemFromCache(
      subscriptionId,
      planSubscriptionCache
    );

    if (subscription) {
      subscription.contacts.forEach(contact => {
        if (
          contactsCache &&
          contact.id &&
          cachedItemHasExpired(contactsCache[contact.id])
        ) {
          this.props.getContact(contact.id);
        }
      });
    } else if (subscriptionId) {
      this.props.getPlanSubscription(subscriptionId);
    }
  }

  public componentDidUpdate(prevProps: CollectionFormProps) {
    const { planSubscriptionCache, contactsCache, subscriptionId } = this.props;

    if (prevProps.planSubscriptionCache !== planSubscriptionCache) {
      const subscription = getItemFromCache(
        subscriptionId,
        planSubscriptionCache
      );

      if (subscription) {
        subscription.contacts.forEach(contact => {
          if (
            contact.contact &&
            contactsCache &&
            cachedItemHasExpired(contactsCache[contact.contact])
          ) {
            this.props.getContact(contact.contact);
          }
        });
      }
    }
  }

  public render() {
    const {
      handleSubmit,
      onClickBack,
      error,
      warning,
      subscriptionId,
      planSubscriptionCache,
      loading,
      invoiceMode,
      actionMsg,
      disabled,
    } = this.props;

    const subscription = getItemFromCache(
      subscriptionId,
      planSubscriptionCache
    );

    const feeToNominalMapping = {
      [LedgerItemType.SubscriptionFee]:
        subscription?.calculated_fees?.fee?.nominal_code,
      [LedgerItemType.JoiningFee]:
        subscription?.calculated_fees?.joining_fee?.nominal_code,
      [LedgerItemType.AdminFee]:
        subscription?.calculated_fees?.admin_fee?.nominal_code,
      [LedgerItemType.FamilyDiscount]: '1105',
      [LedgerItemType.Promotion]:
        subscription?.calculated_fees?.fee?.nominal_code,
      [LedgerItemType.INSPRAC]: '4506', // always 4506, until the next time it isn't
    };

    return (
      <Form onSubmit={handleSubmit}>
        <div className="modal-body-section">
          <FieldArray<RenderLedgerItemsOwnProps>
            name="ledger_items"
            component={RenderLedgerItems}
            contacts={subscription ? subscription.contacts : []}
            feeToNominalMapping={feeToNominalMapping}
            readonly={disabled || invoiceMode}
            isBillingContainerSubscription={
              subscription?.plan_detail?.is_billing_container
            }
          />
          <FormGroup>
            <Field
              label="Billing period start"
              name="start_date"
              component={RenderDateField}
              validate={[required]}
              disabled={disabled || invoiceMode}
            />
            <Field
              label="Billing period end"
              name="end_date"
              component={RenderDateField}
              validate={[required]}
              disabled={disabled || invoiceMode}
            />
            <Field
              label="Collection date"
              name="collection_date"
              component={RenderDateField}
              validate={[required]}
              disabled={disabled || invoiceMode}
            />
          </FormGroup>
          <FormGroup>
            {invoiceMode && (
              <Field
                label="Entry date"
                name="entry_date"
                component={RenderDateTimeField}
                validate={required}
              />
            )}
            <Field
              label="Comments"
              name="comments"
              type="text"
              component={RenderTextArea}
              disabled={disabled || invoiceMode}
              stretch
            />
          </FormGroup>
          <ErrorRenderer
            actions={[CREATE_COLLECTION, SAVE_COLLECTION]}
            fields={[
              'non_field_errors',
              'amount',
              'vat_amount',
              'ledger_items',
            ]}
            showStatusErrors
            error={[error, warning]}
          />
        </div>
        <div className="modal-body-section form-buttons">
          <AppButton onClick={onClickBack}>Cancel</AppButton>
          <AppButton
            type="submit"
            primary
            loading={loading}
            disabled={disabled}
          >
            {actionMsg
              ? actionMsg
              : invoiceMode
              ? 'Generate invoice'
              : 'Generate collection'}
          </AppButton>
        </div>
      </Form>
    );
  }
}

// Disconnected version used for testing
export { CollectionForm as TestableCollectionForm };

export default reduxForm<Collection, OwnProps>({
  form: 'generateInvoiceForm',
})(connector(CollectionForm));

import { anyPending } from '@dabapps/redux-requests';
import { Container, Tab, Tabs } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import ActivitiesListComponent from '^/activities/browser';
import ContactPopups from '^/activities/popups';
import { UserRole } from '^/admin/users/types';
import AllowRoute from '^/common/allow-route';
import AppButton from '^/common/app-button';
import { ErrorPage } from '^/common/error-page';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import { contactActions, getContact } from '^/contacts/actions';
import ArchiveContact from '^/contacts/archive-contact';
import { getPatientsByContact } from '^/contacts/companies/practices/actions';
import ContactPatientsTab from '^/contacts/companies/practices/tabs/patients-tab';
import ContactHeaderBar from '^/contacts/header-bar';
import LedgerTab from '^/contacts/ledger/ledger-tab';
import IndividualsDetailsTab from '^/contacts/persons/individuals/tabs/details-tab';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import { contactMissingDetail, getItemFromCache } from '^/utils/cache-helpers';
import { CODE_COMPANY_ID } from '^/utils/constants';

export type IndividualsDetailPageProps = RouteComponentProps<{
  contactId: string;
}> &
  ConnectedProps<typeof connector>;

/**
 * Displays all individuals details. Uses tabs to show and hide information.
 */
class IndividualsDetailPage extends React.PureComponent<
  IndividualsDetailPageProps
> {
  public componentDidMount() {
    const {
      individual,
      loading,
      match: { params },
    } = this.props;

    if (!loading.details && contactMissingDetail(individual)) {
      this.props.getContact(params.contactId);
    }

    this.props.getPatientsByContact(
      params.contactId,
      1,
      RelationshipType.PatientPerformer
    );
  }

  public render() {
    const {
      individual,
      loading,
      match: {
        params: { contactId: contactId },
      },
      patientsByContact,
    } = this.props;

    if (!individual) {
      if (loading.details) {
        return (
          <PageContent>
            <BreadcrumbBar items={[['Records', '/contacts'], 'Loading...']} />
            <Loading />
          </PageContent>
        );
      }

      return <ErrorPage heading="Individual not found" />;
    }

    const contactPatients = patientsByContact[contactId];

    return (
      <PageContent>
        <BreadcrumbBar
          items={[['Records', '/contacts'], getContactName(individual)]}
        />
        <ContactHeaderBar
          contact={individual}
          secondaryActions={[
            <AppButton
              key="export-activities"
              url={`/api/activities/csv/?associated_contact=${individual.id}`}
              small
            >
              Export Individual Activities
            </AppButton>,
            <ArchiveContact key="archive" contact={individual} />,
          ]}
          loading={loading.details}
        />
        <Container>
          <Tabs>
            <Tab>
              <NavLink to={`${this.props.match.url}/details`}>Details</NavLink>
            </Tab>
            {contactPatients && contactPatients.count > 0 && (
              <Tab>
                <NavLink to={`${this.props.match.url}/patients`}>
                  Patients
                </NavLink>
              </Tab>
            )}
            {individual.crm_id !== CODE_COMPANY_ID && (
              <Tab>
                <NavLink to={`${this.props.match.url}/ledger`}>Ledger</NavLink>
              </Tab>
            )}
            <Tab>
              <NavLink to={`${this.props.match.url}/activities`}>
                Record Activities
              </NavLink>
            </Tab>
          </Tabs>

          <Switch>
            <Route
              path={`${this.props.match.path}/details`}
              component={IndividualsDetailsTab}
            />
            <AllowRoute
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
              redirect={`${this.props.match.url}/details`}
              path={`${this.props.match.path}/patients`}
              component={ContactPatientsTab}
            />
            <Route
              path={`${this.props.match.path}/ledger`}
              component={LedgerTab}
            />
            <Route
              path={`${this.props.match.path}/activities/:activityId`}
              component={ActivitiesListComponent}
            />
            <Route
              path={`${this.props.match.path}/activities`}
              component={ActivitiesListComponent}
            />
            <Route component={this.redirect} />
          </Switch>
        </Container>

        <ContactPopups contactId={contactId} />
      </PageContent>
    );
  }

  public redirect = () => <Redirect to={`${this.props.match.url}/details`} />;
}

/** Disconnected version of the component that is used for testing. */
export { IndividualsDetailPage as TestableIndividualsDetailPage };

export const mapState = (
  state: StoreState,
  props: RouteComponentProps<{
    contactId: string;
  }>
) => ({
  individual: getItemFromCache(
    props.match.params.contactId,
    state.contactsCache
  ),
  loading: {
    details: anyPending(state.responses, contactActions),
  },
  patientsByContact: state.patientsByContact,
});

const connector = connect(mapState, {
  getContact,
  getPatientsByContact,
});

export default connector(IndividualsDetailPage);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { generateQueryString } from '^/common/helper-functions';
import { CONTACTS_ENDPOINT, generateSorts } from '^/contacts/actions';
import { ContactResponse, FileResponse } from '^/contacts/types';
import { FilterList } from '^/filters/types';
import { RelationshipType } from '^/relationships/types';
import { SortList } from '^/sorts/types';
import { ContactType } from '^/types';
import { DEFAULT_PAGE_SIZE } from '^/utils/constants';

export const PRACTICES_ENDPOINT = '/api/contacts';

export const GET_PRACTICES = makeAsyncActionSet('GET_PRACTICES');
export function getPractices(
  filters?: FilterList,
  sorting?: SortList,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString = generateQueryString({
    ...filters,
    ordering: generateSorts(sorting),
    type: ContactType.Practice,
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  return (dispatch: Dispatch) => {
    return request(
      GET_PRACTICES,
      `${PRACTICES_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize, filters } }
    )(dispatch);
  };
}

export const GET_PATIENTS_BY_CONTACT = makeAsyncActionSet(
  'GET_PATIENTS_BY_CONTACT'
);
export function getPatientsByContact(
  contactId: ContactResponse['id'],
  page: number = 1,
  relationship = RelationshipType.PatientPractice,
  filters?: FilterList,
  sorting?: SortList,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString = generateQueryString({
    ...filters,
    ordering: generateSorts(sorting),
    related_contact: contactId,
    relationship,
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  return (dispatch: Dispatch) => {
    return request(
      GET_PATIENTS_BY_CONTACT,
      `${CONTACTS_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize, contactId } }
    )(dispatch);
  };
}

export const FILES_ENDPOINT = '/api/attachments/practice/';

export const GET_FILES = makeAsyncActionSet('GET_FILES');
export function getFiles(
  contact: string,
  page: number = 1,
  pageSize: number = DEFAULT_PAGE_SIZE
) {
  const queryString = generateQueryString({
    contact,
    page: page.toString(),
    page_size: pageSize.toString(),
  });

  return (dispatch: Dispatch) => {
    return request(
      GET_FILES,
      `${FILES_ENDPOINT}/${queryString}`,
      'GET',
      undefined,
      { metaData: { page, pageSize } }
    )(dispatch);
  };
}

export const DELETE_FILE = makeAsyncActionSet('DELETE_FILE');
export function deleteFile(file: FileResponse) {
  return (dispatch: Dispatch) => {
    return request(
      DELETE_FILE,
      `${FILES_ENDPOINT}/${file.id}/remove/`,
      'POST'
    )(dispatch);
  };
}

export const LAPSE_PRACTICE_SUBSCRIPTIONS = makeAsyncActionSet(
  'LAPSE_PRACTICE_SUBSCRIPTIONS'
);
export function lapsePracticeSubscriptions(practiceId: string) {
  return (dispatch: Dispatch) => {
    return request(
      LAPSE_PRACTICE_SUBSCRIPTIONS,
      `${PRACTICES_ENDPOINT}/lapse-practice-subscriptions/${practiceId}/`,
      'POST'
    )(dispatch);
  };
}

export const IMPORT_PATIENTS = makeAsyncActionSet('IMPORT_PATIENTS');
export function importPatients(practiceId: string, attachmentId: string) {
  return (dispatch: Dispatch) => {
    return request(
      IMPORT_PATIENTS,
      `${PRACTICES_ENDPOINT}/import-patients/${practiceId}/`,
      'POST',
      { attachment_id: attachmentId }
    )(dispatch);
  };
}

import { Button } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { StoreState } from '^/types';

export type PortalHeaderProps = ConnectedProps<typeof connector>;

class PortalHeader extends React.PureComponent<PortalHeaderProps> {
  public render() {
    const url = this.props?.practiceDetails?.website;
    const navigateToPracticeSite = () => url && window.location.assign(url);

    return (
      <div className="portal-header">
        <div className="portal-header-container">
          <img
            className="portal-header-company-logo"
            src="/static/agilio-iplan-logo.svg"
            alt="Agilio iplan logo"
          />
          {url && (
            <Button
              className="portal-header-back-button"
              onClick={navigateToPracticeSite}
            >
              Practice Website
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export const mapState = (state: StoreState) => ({
  practiceDetails: state?.publicPatient?.practiceDetails,
});

const connector = connect(mapState);

export default connector(PortalHeader);

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';

import { UserRole } from '^/admin/users/types';
import RenderReactSelect from '^/form-helpers/render-react-select';
import {
  CSV_REPORT_TYPE_OPTIONS,
  IPLAN_CSV_REPORT_TYPE_OPTIONS,
} from '^/routines/constants';
import { StoreState } from '^/types';

class ReportTypeSelect extends React.PureComponent<
  WrappedFieldProps & ConnectedProps<typeof connector>
> {
  public render() {
    const options = this.props.iplanUser
      ? IPLAN_CSV_REPORT_TYPE_OPTIONS
      : CSV_REPORT_TYPE_OPTIONS;
    const selectedOption =
      options.find(option => option.value === this.props.input.value) || null;
    return (
      <RenderReactSelect
        {...this.props}
        options={options}
        placeholder="Please choose"
        selectedOption={selectedOption}
      />
    );
  }
}

export { ReportTypeSelect as TestableReportTypeSelect };

export const mapState = (state: StoreState) => ({
  iplanUser: state.loggedInUser?.role === UserRole.PracticeAdminLevel,
});

const connector = connect(mapState, {});

export default connector(ReportTypeSelect);

import moment from 'moment';

import { CachedItem, PaginatedArray } from '^/types';

export function createPaginatedArray<T>(
  array: ReadonlyArray<T>,
  meta: Partial<PaginatedArray<T>> = {}
): PaginatedArray<T> {
  return {
    count: array.length,
    page: 1,
    pageSize: 10,
    retrieved: moment(),
    ...meta,
    pages: {
      [1]: array,
    },
  };
}

export function createCachedItem<T extends object>(item: T): CachedItem<T> {
  return Object.assign({ retrieved: moment() }, item);
}

import { AsyncActionSet } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import ErrorRenderer from '^/common/error-renderer';
import { required } from '^/common/validation';
import { PersonProfileCommunicationMethod } from '^/contacts/persons/types';
import { Contact, ContactType } from '^/contacts/types';
import RenderCategoryPicker from '^/form-helpers/render-category-picker';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropdown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';

interface OwnProps {
  /** Actions that this form is going to hit, in the event of a failure, generalErrorFields will be extracted and shown at the bottom of the form. */
  actions?: ReadonlyArray<AsyncActionSet>;
  /** Fields that the API will return to give us general errors. Defaults to DRF's standard 'non_field_errors'. */
  generalErrorFields?: ReadonlyArray<string>;
  /** If present a cancel button will be shown that will fire this prop. */
  onCancel?(): void;
}

export type PersonsFormProps = OwnProps & InjectedFormProps<Contact, OwnProps>;

/**
 * Form that is used for both patients and individuals. Can be used in both a create and edit context. The prop 'onSubmit' is called when the save button is pressed.
 */
class PersonsForm extends React.PureComponent<PersonsFormProps> {
  public render() {
    const {
      actions,
      generalErrorFields = ['non_field_errors'],
      handleSubmit,
      initialValues,
      onCancel,
      pristine,
      submitting,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            component={RenderInputField}
            label="Title"
            name="person.title"
            placeholder="Mr/Mrs/Ms"
            shortest
            type="text"
          />
          <Field
            component={RenderInputField}
            label="First Name"
            name="person.first_name"
            type="text"
            validate={[required]}
          />
          <Field
            component={RenderInputField}
            label="Surname"
            name="person.last_name"
            type="text"
            validate={[required]}
          />
          <Field
            component={RenderDateField}
            label="Date of birth"
            name="person.dob"
            type="date"
            validate={
              initialValues.type === ContactType.Patient ? required : undefined
            }
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={RenderInputField}
            label="Mobile"
            name="person.mobile"
            type="text"
          />
          <Field
            component={RenderInputField}
            label="Email"
            name="person.email"
            type="email"
          />
        </FormGroup>
        <FormGroup>
          <Field
            component={RenderDropdown}
            label="Contact preferences"
            name="person.communication_method"
            placeholder="SMS"
            options={[
              { label: 'Please choose..', value: '' },
              {
                label: 'Email',
                value: PersonProfileCommunicationMethod.Email,
              },
              {
                label: 'Post',
                value: PersonProfileCommunicationMethod.Post,
              },
              {
                label: 'SMS',
                value: PersonProfileCommunicationMethod.SMS,
              },
            ]}
            short
          />
          <Field
            component={RenderCategoryPicker}
            contactType={initialValues.type}
            label="Category"
            name="category"
            validate={[required]}
          />
        </FormGroup>
        <ErrorRenderer
          actions={actions}
          fields={generalErrorFields}
          showStatusErrors
        />
        <div className="form-buttons">
          {onCancel && (
            <AppButton type="button" onClick={onCancel} disabled={submitting}>
              Cancel
            </AppButton>
          )}
          <AppButton
            type="submit"
            disabled={pristine}
            loading={submitting}
            primary
          >
            Save
          </AppButton>
        </div>
      </Form>
    );
  }
}

/** Disconnected version of the component that is used for testing. */
export { PersonsForm as TestablePersonsForm };

export default reduxForm<Contact, OwnProps>({
  form: 'personsForm',
})(PersonsForm);

import { AnyAction } from 'redux';

import {
  CLEAR_ADDRESSES,
  GET_CONTACT_ADDRESSES,
  LOOKUP_ADDRESS,
} from '^/address/actions';
import { LoqateFindResponse, LoqateFindResponseRecord } from '^/address/types';
import { StoreState } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

export function suggestedAddresses(
  state: ReadonlyArray<LoqateFindResponse> | null = null,
  action: AnyAction
): ReadonlyArray<LoqateFindResponse> | null {
  switch (action.type) {
    case LOOKUP_ADDRESS.SUCCESS:
      return action.payload.data.Items.map(LoqateFindResponseRecord);
    case LOOKUP_ADDRESS.FAILURE:
      return null;
    case CLEAR_ADDRESSES:
      return null;
    default:
      return state;
  }
}

export function contactAddresses(
  state: StoreState['contactAddresses'] = {},
  action: AnyAction
): StoreState['contactAddresses'] {
  switch (action.type) {
    case GET_CONTACT_ADDRESSES.SUCCESS:
      return {
        ...state,
        [action.meta.contactId]: paginatedReducer(
          state[action.meta.contactId],
          action
        ),
      };
    default:
      return state;
  }
}

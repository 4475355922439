import { SetPropsInterface, withSetProps } from '@dabapps/react-set-props';
import { anyPending } from '@dabapps/redux-requests';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import { GET_CONTACT_PLANS, getContactPlans } from '^/plans/actions';
import { PlanStatus } from '^/plans/types';
import { StoreState } from '^/types';
import { getAllRetrievedPages } from '^/utils/pagination-helpers';
import { getPlanName } from '../helpers';

// Props that come from the parent component.
interface OwnProps {
  contactId: string;
  patientId: string;
  disabled?: boolean;
}

// State props that come from redux.
interface StateProps {
  selectedPlan?: string;
}

export type SubscriptionAddButtonProps = OwnProps &
  SetPropsInterface<StateProps> &
  ConnectedProps<typeof connector>;

class SubscriptionAddButton extends React.PureComponent<
  SubscriptionAddButtonProps
> {
  public componentDidMount(): void {
    this.props.getContactPlans(
      this.props.contactId,
      1,
      100,
      this.props.patientId,
      true
    );
  }

  public render() {
    const { contactPlans, selectedPlan, loading, disabled } = this.props;

    return (
      <Allow
        roles={[
          UserRole.AdminLevel,
          UserRole.FinanceLevel,
          UserRole.OfficeLevel,
        ]}
      >
        <div
          className={classnames('subscriptions-membership-add-button', {
            ['no-selection']: !selectedPlan,
          })}
        >
          <div className="form-field short">
            <label>Plan</label>
            <select value={selectedPlan} onChange={this.onPlanChange}>
              <option value="">
                {loading ? 'Loading...' : 'Please choose...'}
              </option>
              {getAllRetrievedPages(contactPlans[this.props.contactId])
                .filter(plan => plan.status !== PlanStatus.Archived)
                .map(plan => (
                  <option key={plan.id} value={plan.id}>
                    {getPlanName(plan)}
                  </option>
                ))}
            </select>
          </div>
          <AppButton
            disabled={!selectedPlan || disabled}
            loading={loading}
            onClick={this.onClickAdd}
          >
            Add Plan Subscription
          </AppButton>
        </div>
      </Allow>
    );
  }

  public onPlanChange = (event: { target: { value: string } }) => {
    this.props.setProps({ selectedPlan: event.target.value });
  };

  public onClickAdd = () => {
    const { patientId, selectedPlan, contactId } = this.props;

    this.props.push(
      `/subscriptions/create?patient=${patientId}&plan=${selectedPlan}&practice=${contactId}`
    );
  };
}

// Disconnected version used for testing
export { SubscriptionAddButton as TestableSubscriptionAddButton };

export const getInitialProps = () => ({
  selectedPlan: '',
});

export const mapState = (state: StoreState) => ({
  contactPlans: state.contactPlans,
  loading: anyPending(state.responses, [GET_CONTACT_PLANS]),
});

const connector = connect(mapState, { getContactPlans, push });

export default withSetProps<StateProps, OwnProps>(getInitialProps)(
  connector(SubscriptionAddButton)
);

import { Container } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { Dispatch } from 'redux';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import { HeaderBar } from '^/common/header-bar';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import { Bank } from '^/contacts/ledger/types';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { requestReport } from '^/routines/actions';
import RequestReportForm from '^/routines/report-form';
import ReportList from '^/routines/report-list';
import {
  Report,
  ReportCategory,
  ReportParam,
  ReportType,
} from '^/routines/types';

interface OwnProps {
  requestReport: typeof requestReport;
}

class PlansRoutinesPage extends React.PureComponent<OwnProps> {
  public render() {
    return (
      <PageContent>
        <BreadcrumbBar />
        <HeaderBar title="Plan Routines" transparent />
        <Container>
          <PageSection thin>
            <PageSubSection heading="Generate invoices" vertical>
              <p>Generate invoices for all unbilled plan subscriptions.</p>
              <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                <RequestReportForm
                  form={'generatePlanInvoices'}
                  initialValues={{
                    report_type: ReportType.Invoices,
                    report_category: ReportCategory.Plan,
                  }}
                  params={[ReportParam.RunFrom, ReportParam.RunTo]}
                  onSubmit={this.requestReport}
                />
              </Allow>
              <ReportList
                type={ReportType.Invoices}
                category={ReportCategory.Plan}
              />
            </PageSubSection>
            <PageSubSection heading="Perform BACS run" vertical>
              <p>
                Create BACS mandates for all billed plan subscriptions. Includes
                initial BACS requests and regular requests.
              </p>
              <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                <RequestReportForm
                  form={'generatePlanBACS'}
                  initialValues={{
                    report_type: ReportType.BacsRun,
                    report_category: ReportCategory.Plan,
                    params: {
                      [ReportParam.ReceiptTo]: Bank.CodePlan,
                      [ReportParam.ReceiptDate]: moment().format('YYYY-MM-DD'),
                    },
                  }}
                  params={[ReportParam.ReceiptTo, ReportParam.ReceiptDate]}
                  onSubmit={this.requestReport}
                />
              </Allow>
              <ReportList
                type={ReportType.BacsRun}
                category={ReportCategory.Plan}
              />
            </PageSubSection>
            <PageSubSection
              heading="Generate plan subscription renewal report"
              vertical
            >
              <p>
                Generate a report detailing annual plan subscriptions that are
                approaching renewal.
              </p>
              <Allow roles={[UserRole.AdminLevel, UserRole.FinanceLevel]}>
                <RequestReportForm
                  form={'generatePlanRenewals'}
                  initialValues={{
                    report_type: ReportType.RenewalReport,
                    report_category: ReportCategory.Plan,
                  }}
                  params={[
                    ReportParam.RunFrom,
                    ReportParam.RunTo,
                    ReportParam.PaymentMethod,
                  ]}
                  requiredParams={[ReportParam.PaymentMethod]}
                  onSubmit={this.requestReport}
                />
              </Allow>
              <ReportList
                type={ReportType.RenewalReport}
                category={ReportCategory.Plan}
                noApplyButtons
              />
            </PageSubSection>
          </PageSection>
        </Container>
      </PageContent>
    );
  }

  public requestReport = (report: Report, dispatch: Dispatch) => {
    return requestReport(report)(dispatch);
  };
}

export { PlansRoutinesPage as TestableRoutinesPage };

export default PlansRoutinesPage;

import moment from 'moment';
import { AnyAction } from 'redux';

import {
  GET_ATTACHMENT,
  SAVE_ATTACHMENT,
  UPLOAD_ATTACHMENT,
} from '^/attachments/actions';
import { AttachmentResponse, AttachmentStatus } from '^/attachments/types';
import { DetailedCache } from '^/types';

export function attachmentsCache(
  state: DetailedCache<AttachmentResponse> = {},
  action: AnyAction
) {
  switch (action.type) {
    case UPLOAD_ATTACHMENT.SUCCESS:
      const url = action.payload.request.responseURL;
      const urlParts = url.split('/');
      const id = urlParts[urlParts.length - 2];

      return {
        ...state,
        [id]: {
          id,
          file_name: action.meta.file_name,
          file_type: action.meta.file_type,
          status: AttachmentStatus.Uploading,
          retrieved: moment(),
        },
      };
    case SAVE_ATTACHMENT.SUCCESS:
    case GET_ATTACHMENT.SUCCESS:
      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
          retrieved: moment(),
        },
      };
    default:
      return state;
  }
}

export function attachmentsBySession(
  state: {
    [session: string]: { [id: string]: AttachmentResponse };
  } = {},
  action: AnyAction
): { [session: string]: { [id: string]: AttachmentResponse } } {
  switch (action.type) {
    case UPLOAD_ATTACHMENT.SUCCESS:
      if (!action.meta.session) {
        return state;
      }

      const url = action.payload.request.responseURL;
      const urlParts = url.split('/');
      const id = urlParts[urlParts.length - 2];

      return {
        ...state,
        [action.meta.session]: {
          ...state[action.meta.session],
          [id]: {
            id,
            file_name: action.meta.file_name,
            file_type: action.meta.file_type,
            status: AttachmentStatus.Uploading,
          },
        },
      };
    case SAVE_ATTACHMENT.SUCCESS:
    case GET_ATTACHMENT.SUCCESS:
      if (!action.meta.session) {
        return state;
      }

      return {
        ...state,
        [action.meta.session]: {
          ...state[action.meta.session],
          [action.payload.data.id]: action.payload.data,
        },
      };
    default:
      return state;
  }
}

import { AnyAction } from 'redux';

import { CLEAR_CONTACTS } from '^/contacts/actions';
import {
  DELETE_FILE,
  GET_FILES,
  GET_PATIENTS_BY_CONTACT,
  GET_PRACTICES,
} from '^/contacts/companies/practices/actions';
import { ContactResponse, FileResponse } from '^/contacts/types';
import { LOGOUT } from '^/login/actions';
import { PaginatedArray, StoreState } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';

export function practices(
  state: PaginatedArray<ContactResponse> | null = null,
  action: AnyAction
): PaginatedArray<ContactResponse> | null {
  switch (action.type) {
    case GET_PRACTICES.SUCCESS:
      return paginatedReducer(state, action);
    case CLEAR_CONTACTS:
    case LOGOUT.SUCCESS:
      return null;
    default:
      return state;
  }
}

export function patientsByContact(
  state: StoreState['patientsByContact'] = {},
  action: AnyAction
): StoreState['patientsByContact'] {
  switch (action.type) {
    case GET_PATIENTS_BY_CONTACT.SUCCESS:
      return {
        ...state,
        [action.meta.contactId]: paginatedReducer(
          state[action.meta.contactId],
          action
        ),
      };
    default:
      return state;
  }
}

export function files(
  state: PaginatedArray<FileResponse> | null = null,
  action: AnyAction
): PaginatedArray<FileResponse> | null {
  switch (action.type) {
    case GET_FILES.SUCCESS:
      return paginatedReducer(state, action);
    case DELETE_FILE.SUCCESS:
      const pages = state?.pages;
      if (pages && state) {
        for (const page in pages) {
          if (pages[page]) {
            const items = [...pages[page]];
            const index = items.findIndex(p => p.id === action.payload.data.id);
            if (index > -1) {
              items.splice(index, 1);
              pages[page] = items;
            }
          }
        }
        return { ...state, ...state.pages };
      }
      return state;
    default:
      return state;
  }
}

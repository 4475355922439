import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import PracticeUsersListPage from './practice-list-page';
import PracticeUsersCreatePage from './practice-users-create-page';
import PracticeUsersEditPage from './practice-users-edit-page';

class PracticeUsersRoutes extends React.PureComponent<RouteComponentProps> {
  public render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route
          path={`${match.path}/create`}
          component={PracticeUsersCreatePage}
        />
        <Route path={`${match.path}/:id`} component={PracticeUsersEditPage} />
        <Route component={PracticeUsersListPage} />
      </Switch>
    );
  }
}

export default PracticeUsersRoutes;

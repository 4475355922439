import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { Dispatch } from 'redux';

import { LetterDetailsValues } from '^/admin/letters/letter-details-form';
import { FilterList } from '^/filters/types';
import { getReportsByType } from '^/routines/actions';
import { ReportCategory, ReportType } from '^/routines/types';
import {
  rethrowOnAnyError,
  throwSubmissionError,
} from '^/utils/action-helpers';

export const LETTERS_ENDPOINT = '/api/letters';

export interface CreateLetterData extends LetterDetailsValues {
  filters: FilterList;
}

export const CREATE_LETTER = makeAsyncActionSet('CREATE_LETTER');
export function createLetter(data: CreateLetterData) {
  return (dispatch: Dispatch) =>
    request(CREATE_LETTER, `${LETTERS_ENDPOINT}/create/`, 'POST', data, {
      shouldRethrow: rethrowOnAnyError,
    })(dispatch)
      .then(response => {
        getReportsByType(ReportType.Letters, ReportCategory.Generic)(dispatch);
        return response;
      })
      .catch(throwSubmissionError);
}

import { Column, Container, Row } from '@dabapps/roe';
import queryString from 'query-string';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import AppButton from '^/common/app-button';
import HeaderBar from '^/common/header-bar';
import { generateQueryString } from '^/common/helper-functions';
import RecentlyViewedPracticesTable from '^/common/recently-viewed/recently-viewed-practices';
import { clearContacts } from '^/contacts/actions';
import PracticesFilterForm from '^/contacts/companies/practices/filter-form';
import PracticeTable from '^/contacts/companies/practices/table';
import { FilterList } from '^/filters/types';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { getPractices } from './actions';

export type PracticesListPageProps = ConnectedProps<typeof connector> &
  RouteComponentProps;

class PracticesListPage extends React.PureComponent<PracticesListPageProps> {
  public componentDidMount() {
    const { location } = this.props;

    if (location.search) {
      const filters = queryString.parse(location.search);

      this.props.getPractices(filters);
    }
  }

  public componentDidUpdate(prevProps: PracticesListPageProps) {
    const { location } = this.props;

    const filters = queryString.parse(location.search);

    if (
      location.search !== '' &&
      location.search !== prevProps.location.search
    ) {
      this.props.getPractices(filters);
    }

    if (!location.search) {
      this.props.clearContacts();
    }
  }

  public render() {
    const { practices, location } = this.props;

    const filters = queryString.parse(location.search);

    return (
      <PageContent>
        <HeaderBar
          title="Practices"
          primaryActions={[
            <a key="export-practices" href="/api/exports/practices/">
              <AppButton>Export Practices for Letters</AppButton>
            </a>,
            <Link key="new-practice" to={`${this.props.match.url}/create`}>
              <AppButton leftIcon="plus" primary>
                New Practice
              </AppButton>
            </Link>,
          ]}
        />
        <Container>
          <Row>
            <Column xs={12} lg={3}>
              <div className="filter-section">
                <PracticesFilterForm
                  initialValues={filters}
                  onSubmit={this.handleFilterSubmit}
                  onClear={this.handleFilterClear}
                  enableReinitialize
                />
              </div>
            </Column>
            <Column xs={12} lg={9}>
              {practices ? <PracticeTable /> : <RecentlyViewedPracticesTable />}
            </Column>
          </Row>
        </Container>
      </PageContent>
    );
  }

  public handleFilterSubmit = (filters: FilterList) => {
    const query = generateQueryString(filters);

    this.props.history.push(`${this.props.match.url}${query}`);
  };

  public handleFilterClear = () => {
    this.props.history.push(`${this.props.match.url}`);
  };
}

export { PracticesListPage as TestablePracticesListPage };

export const mapState = (state: StoreState) => ({
  practices: state.practices,
});

const connector = connect(mapState, { clearContacts, getPractices });

export default connector(PracticesListPage);

import { anyPending, isPending } from '@dabapps/redux-requests';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';

import { UserRole } from '^/admin/users/types';
import Allow from '^/common/allow';
import AppButton from '^/common/app-button';
import ConfirmButton from '^/common/confirm-button';
import ConfirmationModal from '^/common/confirmation-modal';
import { getContactName } from '^/common/helper-functions';
import Loading from '^/common/loading';
import PageSubSection from '^/common/page-section/page-sub-section';
import {
  ContactResponse,
  ContactType,
  getContactCategoryName,
} from '^/contacts/types';
import { closeModal, openModal } from '^/modals/actions';
import {
  createRelationship,
  deleteRelationship,
  GET_CONTACT_RELATIONSHIPS,
  getContactRelationships,
  relationshipActions,
  transferRelationship,
} from '^/relationships/actions';
import RelationshipsAddModal from '^/relationships/add-modal';
import {
  filterByType,
  getRelationshipsFromStore,
} from '^/relationships/helper-functions';
import RelationshipTransferModal from '^/relationships/transfer-modal';
import { RelationshipResponse, RelationshipType } from '^/relationships/types';
import { StoreState } from '^/types';
import {
  getAllRetrievedPages,
  paginatedArrayHasExpired,
} from '^/utils/pagination-helpers';

// Props that come from the parent component.
interface OwnProps {
  practiceId: string;
  disabled?: boolean;
}

export type PracticePerformersCardProps = OwnProps &
  ConnectedProps<typeof connector>;

class PracticePerformersCard extends React.PureComponent<
  PracticePerformersCardProps
> {
  public componentDidMount(): void {
    const { practiceId, relationships, loading } = this.props;

    if (!loading.relationships && paginatedArrayHasExpired(relationships)) {
      this.props.getContactRelationships(practiceId, [
        RelationshipType.PerformerPractice,
      ]);
    }

    this.refreshPatientCounts();
  }

  public componentDidUpdate(prevProps: PracticePerformersCardProps) {
    if (prevProps.relationships !== this.props.relationships) {
      this.refreshPatientCounts();
    }
  }

  public render() {
    const { practiceId, relationships, loading, disabled } = this.props;

    if (loading.relationships) {
      return (
        <PageSubSection heading="Clinicians" loading>
          <Loading />
        </PageSubSection>
      );
    }

    const filteredRelationships = filterByType(
      getAllRetrievedPages(relationships),
      [RelationshipType.PerformerPractice]
    );

    return (
      <PageSubSection
        heading="Clinicians"
        loading={loading.updating || loading.relationships}
      >
        <div className="relationships list-card">
          <table>
            <tbody>
              {filteredRelationships.length > 0 ? (
                filteredRelationships.map(relationship => {
                  const contact: ContactResponse = this.getRelevantContact(
                    relationship,
                    practiceId
                  );

                  return (
                    <tr key={relationship.id}>
                      <th>
                        <Link to={`/contacts/${contact.id}`}>
                          {getContactName(contact, true)}
                        </Link>
                      </th>
                      <td>
                        {relationship.reverse_relationship ||
                          getContactCategoryName(contact)}
                      </td>
                      <Allow
                        roles={[
                          UserRole.AdminLevel,
                          UserRole.FinanceLevel,
                          UserRole.OfficeLevel,
                        ]}
                      >
                        <td>{this.removeOrTransferButton(relationship)}</td>
                      </Allow>
                    </tr>
                  );
                })
              ) : (
                <div className="empty-state">No clinicians</div>
              )}
            </tbody>
          </table>
          <div className="page-subsection-buttons">
            <Allow
              roles={[
                UserRole.AdminLevel,
                UserRole.FinanceLevel,
                UserRole.OfficeLevel,
              ]}
            >
              <AppButton onClick={this.showAddModal} small disabled={disabled}>
                Add
              </AppButton>
            </Allow>
          </div>
        </div>
      </PageSubSection>
    );
  }

  public showAddModal = () => {
    const filteredRelationships = filterByType(
      getAllRetrievedPages(this.props.relationships),
      [RelationshipType.PerformerPractice]
    );

    return this.props.openModal(
      <RelationshipsAddModal
        key={1}
        {...this.props}
        label="Clinician"
        types={[RelationshipType.PerformerPractice]}
        hideContacts={filteredRelationships.map(
          relationship => relationship.contact
        )}
        showCategories
        filters={{
          type: ContactType.Individual,
        }}
        onCloseModal={this.props.closeModal}
        onContactSelected={this.addRelationship}
      />
    );
  };

  public showTransferModal = (contactId: string) =>
    this.props.openModal(
      <RelationshipTransferModal
        dentistId={contactId}
        practiceId={this.props.practiceId}
        onCloseModal={this.props.closeModal}
        onConfirmTransfer={this.transferPatients}
      />
    );

  public addRelationship = async (
    relatedContact: ContactResponse,
    relationshipType: RelationshipType
  ) => {
    try {
      await this.props.createRelationship(
        relatedContact.id,
        this.props.practiceId,
        relationshipType
      );

      await this.props.getContactRelationships(this.props.practiceId, [
        RelationshipType.PerformerPractice,
      ]);
      this.props.closeModal();
    } catch {
      return;
    }
  };

  public removeRelationship = async (relationship: string) => {
    try {
      await this.props.deleteRelationship(relationship);
    } catch (e) {
      this.props.openModal(
        <ConfirmationModal
          heading={'Error transferring patients'}
          message={e.response?.data.non_field_errors || e.message}
          closeModal={this.props.closeModal}
        />
      );
    }

    await this.props.getContactRelationships(this.props.practiceId, [
      RelationshipType.PerformerPractice,
    ]);
  };

  public transferPatients = async (
    originalContact: string,
    newContact: string
  ) => {
    try {
      await this.props.transferRelationship(
        originalContact,
        newContact,
        this.props.practiceId,
        RelationshipType.PatientPerformer
      );
    } catch (e) {
      this.props.openModal(
        <ConfirmationModal
          heading={'Error transferring patients'}
          message={e.response?.data.non_field_errors || e.message}
          closeModal={this.props.closeModal}
        />
      );
    }

    this.refreshPatientCounts();
    this.props.closeModal();
  };

  private refreshPatientCounts = () => {
    getAllRetrievedPages(this.props.relationships)
      .filter(
        relationship =>
          relationship.relationship === RelationshipType.PerformerPractice
      )
      .forEach(relationship => {
        this.props.getContactRelationships(
          relationship.contact,
          [RelationshipType.PatientPerformer],
          this.props.practiceId,
          1,
          1
        );
      });
  };

  private removeOrTransferButton = (relationship: RelationshipResponse) => {
    const { practiceId, performerPatientsCount } = this.props;

    const patientCount =
      performerPatientsCount[`${practiceId}${relationship.contact}`] || 0;

    const performerRelationships = getAllRetrievedPages(
      this.props.relationships
    ).filter(
      relation => relation.relationship === RelationshipType.PerformerPractice
    );

    if (isNaN(patientCount)) {
      return null;
    }

    if (patientCount <= 0) {
      return (
        <ConfirmButton
          onConfirm={this.removeRelationship.bind(this, relationship.id)}
          content="Remove"
        />
      );
    }

    return (
      <AppButton
        onClick={this.showTransferModal.bind(this, relationship.contact)}
        link
        disabled={performerRelationships.length <= 1}
      >
        transfer {patientCount > 1 ? patientCount + ' patients' : 'patient'}
      </AppButton>
    );
  };

  private getRelevantContact(
    relationship: RelationshipResponse,
    subject: string
  ): ContactResponse {
    return relationship.related_contact === subject
      ? relationship.contact_detail
      : relationship.related_contact_detail;
  }
}

// Disconnected version used for testing
export { PracticePerformersCard as TestablePracticePerformersCard };

export const mapState = (state: StoreState, props: OwnProps) => ({
  relationships: getRelationshipsFromStore(
    state,
    props.practiceId,
    RelationshipType.PerformerPractice,
    null
  ),
  performerPatientsCount: state.performerPatientCounts,
  loading: {
    relationships: isPending(
      state.responses,
      GET_CONTACT_RELATIONSHIPS,
      RelationshipType.PerformerPractice
    ),
    updating: anyPending(state.responses, relationshipActions),
  },
});

const connector = connect(mapState, {
  getContactRelationships,
  createRelationship,
  deleteRelationship,
  transferRelationship,
  openModal,
  closeModal,
});

export default connector(PracticePerformersCard);

import { Column, ContentBox, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { formValueSelector } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import { StoreState } from '^/types';

export type BankInstructionProps = ConnectedProps<typeof connector>;

const BankInstruction = ({
  accountHolder,
  sortCode,
  accountNumber,
}: BankInstructionProps) => {
  return (
    <ContentBox>
      <PageSection className="bank-instructions">
        <PageSubSection>
          <Row>
            <Column xs={12} md={8}>
              <div className="heading">
                Instruction to your bank or building society to pay by Direct
                Debit
              </div>
              <Row className="margin-bottom-large">
                Check your details are correct
              </Row>
              <Row>
                <Column>{accountHolder}</Column>
              </Row>
              <Row>
                <Column>{`Branch sort code: ${sortCode}`}</Column>
                <Column>{`Bank account number: ${accountNumber}`}</Column>
                <Column>SUN: 688109</Column>
              </Row>
              <Row className="margin-top-large">
                Payments for this Direct Debit will appear on your bank
                statement as CODEplan Ltd.
              </Row>
            </Column>
            <Column xs={12} md={4}>
              <Row className="">
                <img className="logo" src="/static/direct-debit-logo.svg" />
              </Row>
            </Column>
          </Row>
        </PageSubSection>
      </PageSection>
      <PageSection className="bank-instructions">
        <PageSubSection>
          <h4>Instruction to your bank or building society:</h4>
          Please pay the CODEplan Ltd Direct Debits from the account detailed in
          the instruction subject to the safeguards assured by the Direct Debit
          Guarantee.
          <div>
            I understand that this instruction may remain with CODEplan Ltd and
            if so details will be passed electronically to my bank/building
            society.
          </div>
        </PageSubSection>
        <PageSubSection className="text-align-center">
          Banks and building societies may not accept Direct Debit instructions
          for some types of accounts
        </PageSubSection>
      </PageSection>
      <PageSection className="bank-instructions">
        <PageSubSection>
          <h4>THE DIRECT DEBIT GUARANTEE</h4>
          <ul>
            <li>
              This Guarantee is offered by all banks and building societies that
              accept instructions to pay Direct Debits
            </li>
            <li>
              If there are any changes to the amount, date or frequency of your
              Direct Debit CODEplan Ltd will notify you within 5 working days in
              advance of your account being debited or as otherwise agreed. If
              you request CODEplan Ltd to collect a payment, confirmation of the
              amount and date will be given to you at the time of the request
            </li>
            <li>
              If an error is made in the payment of your Direct Debit by
              CODEplan Ltd. or your bank or building society, you are entitled
              to a full and immediate refund of the amount paid from your bank
              or building society – if you receive a refund you are not entitled
              to, you must pay it back when CODEplan Ltd asks you to
            </li>
            <li>
              You can cancel a Direct Debit at any time by simply contacting
              your bank or building society. Written confirmation may be
              required. Please also notify us.
            </li>
          </ul>
        </PageSubSection>
      </PageSection>
    </ContentBox>
  );
};

export { BankInstruction as TestableBankInstruction };

const selector = formValueSelector('iplanNewPatientForm');

export const mapState = (state: StoreState) => {
  const accountHolder = selector(state, 'account_name');
  const sortCode = selector(state, 'sort_code');
  const accountNumber = selector(state, 'account_number');
  return {
    accountHolder: accountHolder || '',
    sortCode: sortCode || '',
    accountNumber: accountNumber || '',
  };
};

const connector = connect(mapState);

export default connector(BankInstruction);

import { Collapse } from '@dabapps/roe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';

import PageSection from '^/common/page-section/page-section';
import { StoreState } from '^/types';
import BankInstruction from '../form/bank-instruction';
import Confirmation from '../form/confirmation';
import DentalPlanAgreement from '../form/dental-plan-agreement';
import { Stepper } from '../stepper';

export type PatientConfirmationSectionProps = ConnectedProps<typeof connector>;

const PatientConfirmationSection = ({
  isSectionOneValid,
  isStartDateEntered,
}: PatientConfirmationSectionProps) => {
  const [isContactDetailsPanelOpen, setIsContactDetailsPanelOpen] = useState(
    (isSectionOneValid && isStartDateEntered) === true
  );
  const onClickToggleCollapse = () =>
    setIsContactDetailsPanelOpen(!isContactDetailsPanelOpen);

  useEffect(() => {
    (isSectionOneValid && isStartDateEntered) === true
      ? setIsContactDetailsPanelOpen(true)
      : setIsContactDetailsPanelOpen(false);
  }, [isSectionOneValid, isStartDateEntered]);

  return (
    <PageSection className="patient-signup-title">
      <div className="collapse-header" onClick={onClickToggleCollapse}>
        <h4>Confirmation</h4>
        <FontAwesomeIcon
          icon={isContactDetailsPanelOpen ? 'chevron-up' : 'chevron-down'}
        />
      </div>
      <Collapse
        open={isContactDetailsPanelOpen}
        fadeOut
        fadeColor="#FFF"
        fadeHeight={50}
        animationDuration={200}
      >
        {isSectionOneValid && isStartDateEntered && (
          <PageSection
            className="patient-signup-confirmation"
            heading="Direct Debit Mandate"
          >
            <DentalPlanAgreement />
            <BankInstruction />
            <Confirmation />
          </PageSection>
        )}

        {!isSectionOneValid && (
          <PageSection heading="Please make sure all fields in the first section are completed." />
        )}
        {!isStartDateEntered && isSectionOneValid && (
          <PageSection heading="Please make sure you have selected a First Payment Date for the Plan." />
        )}
        <Stepper stepOneActive stepTwoActive stepThreeActive />
      </Collapse>
    </PageSection>
  );
};

export const mapState = (state: StoreState) => {
  const errors: any = getFormSyncErrors('publicPatientSignupForm')(state);
  const isSectionOneValid =
    !errors.title &&
    !errors.first_name &&
    !errors.last_name &&
    !errors.dob &&
    !errors.email &&
    !errors.mobile &&
    !errors.postcode &&
    !errors.account_name &&
    !errors.account_number &&
    !errors.sort_code &&
    !errors.direct_debit_declaration;

  const isStartDateEntered = !errors.start_date;
  return {
    isSectionOneValid,
    isStartDateEntered,
  };
};

export { PatientConfirmationSection as TestablePatientConfirmationSection };

const connector = connect(mapState, {});

export default connector(PatientConfirmationSection);

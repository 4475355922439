import { AnyAction } from 'redux';

import { StoreState } from '^/types';
import { paginatedReducer } from '^/utils/pagination-helpers';
import { GET_COLLECTIONS } from './actions';

export function collections(
  state: StoreState['collections'] = {},
  action: AnyAction
): StoreState['collections'] {
  switch (action.type) {
    case GET_COLLECTIONS.SUCCESS:
      return {
        ...state,
        [action.meta.contact]: paginatedReducer(
          state[action.meta.contact],
          action
        ),
      };
    default:
      return state;
  }
}

export function nextCollectionTotals(
  state: StoreState['nextCollectionTotals'] = {},
  action: AnyAction
): StoreState['nextCollectionTotals'] {
  switch (action.type) {
    case GET_COLLECTIONS.SUCCESS:
      if (action.meta.page > 1 || action.payload.data.results.length < 1) {
        return state;
      }

      const nextCollection = action.payload.data.results[0];

      return {
        ...state,
        [action.meta.contact]:
          nextCollection.total_amount + nextCollection.total_vat_amount,
      };
    default:
      return state;
  }
}

import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import RenderFormField, { RenderFormFieldProps } from './render-form-field';

interface OwnProps {
  disabled?: boolean;
  placeholder?: string;
}

export type RenderInputFieldProps = OwnProps &
  RenderFormFieldProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  WrappedFieldProps;

class RenderInputField extends React.PureComponent<RenderInputFieldProps> {
  public render() {
    const { input, type, placeholder, disabled, step } = this.props;
    return (
      <RenderFormField {...this.props}>
        <input
          {...input}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          step={step}
        />
      </RenderFormField>
    );
  }
}

export default RenderInputField;
